import React, { useEffect, useRef, useState, useMemo, useCallback, forwardRef, useImperativeHandle } from 'react'
import { Box, Snackbar } from '@mui/material'
import { AgGridReact } from 'ag-grid-react'
import useAxiosGzip from 'components/common/hooks/useAxiosGzip'
import SearchBar from 'components/common/SearchBar'
import { getItemFromLS, saveItemToLS } from 'utils/localStorage'
import { appColors } from 'utils'
import Alert from '@mui/material/Alert'
import MenuButton from 'components/common/MenuButton'
import ConfirmDialog from 'components/common/ConfirmDialog'
import { sortColDefs, CustomLoadingOverlay, relativeTimeMins, getStringId } from 'components/common/AgGridUtils'
import { Icon as Iconify } from '@iconify/react'
import { debounce } from 'lodash'
import useUnits, { UNITS_FOR } from 'components/common/hooks/useUnits'
import { numberWithCommasDecimals } from 'utils/stringFunctions'
import useInterval from 'components/common/hooks/useInterval'
import AntiCollisionDashboardWellGrid from 'components/AntiCollisionDashboard/AntiCollisionDashboardWellGrid'
import AntiCollisionDashboardErrorsModal from 'components/AntiCollisionDashboard/AntiCollisionDashboardErrorsModal'
import useObjectsOfInterest from 'components/common/hooks/useObjectsOfInterest'
import { useRecoilValue } from 'recoil'
import { onlyShowFollowingWellsAtom } from 'atoms'
import GlobalPrefs from 'components/NavBar/GlobalPrefs'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import AntiCollisionDashboardSettings from 'components/AntiCollisionDashboard/Settings/AntiCollisionDashboardSettingsModal'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

export const CRITICAL_COLOR = '#f44336'
export const ALERT_COLOR = '#FFA500'
export const NO_ALERT_COLOR = ''

const UPDATE_RATE = 30000

export const ALERT_DISTANCES = {
  leaseLines: [
    { min: 501, max: 999999, color: NO_ALERT_COLOR },
    { min: 201, max: 500, color: ALERT_COLOR },
    { min: -99999, max: 200, color: CRITICAL_COLOR },
  ],
  wellPlan: [
    { min: -99999, max: 50, color: NO_ALERT_COLOR },
    { min: 51, max: 100, color: ALERT_COLOR },
    { min: 101, max: 999999, color: CRITICAL_COLOR },
  ],
  c2c: [
    { min: 501, max: 999999, color: NO_ALERT_COLOR },
    { min: 201, max: 500, color: ALERT_COLOR },
    { min: -99999, max: 200, color: CRITICAL_COLOR },
  ],
  sf: [
    { min: 4.1, max: 999999, color: NO_ALERT_COLOR },
    { min: 2, max: 4, color: ALERT_COLOR },
    { min: -99999, max: 2, color: CRITICAL_COLOR },
  ],
}

const getTimeDurationColor = (timeSinceMins) => {
  if (timeSinceMins === null || timeSinceMins === undefined) return 'tomato'
  if (typeof timeSinceMins === 'string' && timeSinceMins === '') return 'tomato'
  if (typeof timeSinceMins === 'string') timeSinceMins = parseFloat(timeSinceMins)

  if (timeSinceMins > 240) return 'tomato'
  if (timeSinceMins > 60) return 'orange'
  return 'green'
}

const TimeElapsedCounter = ({ data }) => {
  const [elapsedTime, setElapsedTime] = useState(0)

  useEffect(() => {
    const getUpdateTime = () => {
      let currentTime = new Date().toISOString()
      if (!Array.isArray(data.current) || data.current.length === 0) return currentTime
      return data.current[0].updateTime
    }

    const interval = setInterval(() => {
      const currentTime = new Date(Date.now())
      const timestamp = new Date(getUpdateTime()).getTime()
      const secondsElapsed = Math.floor((currentTime - timestamp) / 1000)
      setElapsedTime(secondsElapsed)
    }, 1000)

    return () => clearInterval(interval)
  }, [data])

  return (
    <Box sx={{ width: '200px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Box sx={{ color: '#FFFF' }}>Last Update:</Box>
      <Box
        sx={{
          marginLeft: '5px',
          color: elapsedTime < 120 ? '#66FF00' : elapsedTime < 300 ? ALERT_COLOR : CRITICAL_COLOR,
        }}>
        {` ${elapsedTime}s`}
      </Box>
    </Box>
  )
}

const AntiCollisionDashboardGrid = ({ setFilteredData, showChart, setShowChart }) => {
  const _isMounted = useRef(false)
  const { getAgGridTheme, searchBarStyle } = useInnovaTheme()
  const gridApi = useRef(null)
  const [isLoading, setLoading] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [resetCols, setResetCols] = useState(false)
  const acData = useRef(null)
  const [showErrorsModal, setShowErrorsModal] = useState(false)
  const [confirm, setConfirm] = useState({ show: false, title: '' })
  const [status, setStatus] = useState({ show: false, severity: 'info', message: '' })
  const { getUnitsText } = useUnits()
  const containerRef = useRef(null)
  const selectedWellErrors = useRef([])
  const { isObjectOfInterest, hasObjectsOfInterest } = useObjectsOfInterest()
  const onlyShowFollowing = useRecoilValue(onlyShowFollowingWellsAtom)
  const settings = useRef({ alerts: ALERT_DISTANCES })
  const [showSettingsModal, setShowSettingsModal] = useState(false)
  const initialFilters = useRef(getItemFromLS('acDashboardGridFilters', 'filters' ||null))

  const getAntiCollisionData = useAxiosGzip({
    url: '/antiCollision/cache/getAntiCollisionResultsGz',
  })

  const getAntiCollisionSettings = useInnovaAxios({
    url: '/antiCollision/cache/getAntiCollisionDashboardSettings',
  })

  const updateAntiCollisionSettings = useInnovaAxios({
    url: '/antiCollision/cache/updateAntiCollisionDashboardSettings',
  })

  const getAlertColor = useCallback((value, levels) => {
    if (!value) return ''
    if (!Array.isArray(levels)) return ''
    if (typeof value === 'string') value = parseFloat(value)
    if (typeof value !== 'number') return ''

    for (let i = 0; i < levels.length; i++) {
      if (value >= levels[i].min && value <= levels[i].max) {
        return levels[i].color
      }
    }

    return ''
  }, [])

  const getTextColor = useCallback((color) => {
    if (!color) return '#FFFF'
    if (color === NO_ALERT_COLOR) return '#FFFF'
    if (color === CRITICAL_COLOR || color === ALERT_COLOR) return '#FFFF'
    return '#112A46'
  }, [])

  useEffect(() => {
    _isMounted.current = true
    fetchAntiCollisionSettings()
    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (gridApi.current) {
      gridApi.current.onFilterChanged()
    }
  }, [onlyShowFollowing])

  const debounceSetQuickFilter = debounce((text) => {
    if (gridApi.current) {
      gridApi.current.setGridOption('quickFilterText', text)
    }
  }, 300)

  useEffect(() => {
    debounceSetQuickFilter(searchText)
  }, [searchText]) // eslint-disable-line react-hooks/exhaustive-deps

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.wellName)
    }
  }, [])

  const fetchAntiCollisionSettings = async () => {
    const res = await getAntiCollisionSettings()
    if (!_isMounted.current) return

    if (res?.error) {
      setStatus({ show: true, severity: 'error', message: `${res?.error?.response?.data?.error}` })
      return
    }

    if (res?.data) settings.current = res.data
  }

  const fetchAntiCollisionData = async () => {
    if (isLoading) return
    if (!_isMounted.current) return
    setLoading(true)

    const res = await getAntiCollisionData()
    if (!_isMounted.current) return
    setLoading(false)

    if (res?.error) {
      setStatus({ show: true, severity: 'error', message: `${res?.error}` })
      return
    }

    if (!Array.isArray(res?.data)) res.data = []
    if (!Array.isArray(acData.current)) acData.current = []

    if (gridApi.current) {
      let rowsToAdd = []
      let rowsToDelete = []
      let rowsToUpdate = []

      for (let i = 0; i < res.data.length; i++) {
        let index = acData.current.findIndex((elem) => elem.wellName === res.data[i].wellName)
        if (index < 0) rowsToAdd.push(res.data[i])
        if (index >= 0) rowsToUpdate.push(res.data[i])
      }

      for (let i = 0; i < acData.current.length; i++) {
        let index = res.data.findIndex((elem) => elem.wellName === acData.current[i].wellName)
        if (index < 0) rowsToDelete.push(acData.current[i])
      }

      gridApi.current.applyTransaction({
        remove: rowsToDelete,
        add: rowsToAdd,
        update: rowsToUpdate,
      })

      setTimeout(() => {
        gridApi.current?.autoSizeAllColumns()
        gridApi.current.onFilterChanged()
        gridApi.current.applyColumnState({
          state: [{ colId: 'svyOffsetC2c', sort: 'asc' }],
        })
      }, 100)
    }

    acData.current = res.data
  }

  useInterval(() => {
    fetchAntiCollisionData()
  }, UPDATE_RATE)

  const onGridReady = (params) => {
    gridApi.current = params.api

    fetchAntiCollisionData()

    const colLayout = getItemFromLS('acDashboardGrid', 'colLayout')
    if (colLayout) {
      gridApi.current.applyColumnState({state: colLayout})
    }
  }

  const gridOptions = {
    suppressScrollOnNewData: true,
    suppressRowClickSelection: true,
    sideBar: {
      toolPanels: [
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        },
      ],
      defaultToolPanel: '',
      position: 'left',
    },
    onDragStopped: (event) => {
      saveColumnState()
    },
    onColumnVisible: (event) => {
      saveColumnState()
    },
    onSortChanged: (event) => {
      saveColumnState()
    },
    loadingOverlayComponent: CustomLoadingOverlay,
  }

  const onFirstDataRendered = (params) => {
    if (gridApi.current) gridApi.current.onFilterChanged()
    autoSizeColumns()

    if (initialFilters.current) {
      gridApi.current.setFilterModel(initialFilters.current)
    }
  }

  const onFilterChanged = (params) => {
    if (!gridApi.current) return

    let filteredNodes = []
    params.api.forEachNodeAfterFilter((node) => {
      if (!node.data) return
      filteredNodes.push(node.data)
    })

    if(_isMounted.current && setFilteredData)
      setFilteredData(filteredNodes)

    saveFilterState()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const saveColumnState = () => {
    if (gridApi.current) {
      const colLayout = gridApi.current.getColumnState()
      if (colLayout) saveItemToLS('acDashboardGrid', 'colLayout', colLayout)
    }
  }

  const saveFilterState = () => {
    if (gridApi.current) {
      const filters = gridApi.current.getFilterModel()
      if (filters) saveItemToLS('acDashboardGridFilters', 'filters', filters)
    }
  }

  const getContextMenuItems = (params) => {
    return [
      {
        name: 'Reset columns',
        disabled: false,
        action: () => {
          gridApi.current.resetColumnState()
          saveItemToLS('acDashboardGrid', 'colLayout', null)
          setResetCols(!resetCols)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Reset filters',
        disabled: false,
        action: () => {
          gridApi.current.setFilterModel(null)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      'copy',
      {
        name: 'Export',
        disabled: false,
        action: () => {
          gridApi.current.exportDataAsExcel({
            fileName: 'AcDashboardData.xlsx',
            sheetName: 'AcDashboardData',
          })
        },
        icon: '<span class="iconify" data-icon="icomoon-free:file-excel" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'View Errors',
        disabled: false,
        action: () => {
          selectedWellErrors.current = Array.isArray(params.node.data.errors) ? params.node.data.errors : []
          setShowErrorsModal(true)
        },
        icon: '<span class="iconify" data-icon="bx:error" data-width="20" style="color:#f44336"></span>',
        cssClasses: ['leftAlign'],
      },
    ]
  }

  const leftAlignCell = useMemo(
    () => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'start',
    }),
    [],
  )

  const centerAlignCell = useMemo(
    () => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }),
    [],
  )

  const defaultColDef = useMemo(() => {
    return {
      headerClass: 'header-no-padding',
      resizable: true,
      sortable: true,
      editable: false,
      autoHeight: true,
      cellStyle: centerAlignCell,
      enableCellChangeFlash: true,
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
    }
  }, [centerAlignCell])

  const getClosestApproachColDef = useCallback(
    (type) => {
      const getIndex = (params) => {
        if (type === 'svy') return 0

        if (!Array.isArray(params.data?.closestApproach)) return 0
        return params.data?.closestApproach.length - 1
      }

      return {
        colId: type,
        headerName: type === 'svy' ? 'Survey' : 'Projection',
        marryChildren: true,
        groupId: type,
        children: [
          {
            colId: `${type}Depth`,
            headerName: `Depth (${getUnitsText(UNITS_FOR.Depth)})`,
            cellStyle: centerAlignCell,
            valueGetter: (params) => {
              if (!Array.isArray(params.data?.closestApproach)) return ''
              return params.data?.closestApproach[getIndex(params)]?.md
            },
            valueFormatter: (params) => numberWithCommasDecimals(params.value, 2),
          },
          {
            colId: `${type}Inc`,
            headerName: `Inc`,
            cellStyle: centerAlignCell,
            columnGroupShow: 'open',
            valueGetter: (params) => {
              if (!Array.isArray(params.data?.closestApproach)) return ''
              return params.data?.closestApproach[getIndex(params)]?.inc
            },
            valueFormatter: (params) => numberWithCommasDecimals(params.value, 2),
          },
          {
            colId: `${type}Azi`,
            headerName: `Azi`,
            cellStyle: centerAlignCell,
            columnGroupShow: 'open',
            valueGetter: (params) => {
              if (!Array.isArray(params.data?.closestApproach)) return ''
              return params.data?.closestApproach[getIndex(params)]?.azi
            },
            valueFormatter: (params) => numberWithCommasDecimals(params.value, 2),
          },
          {
            colId: `${type}OffsetC2c`,
            headerName: `C2C`,
            cellStyle: (params) => {
              let backColor = getAlertColor(params.value, settings.current?.alerts?.c2c)
              let textColor = getTextColor(backColor)
              return { ...centerAlignCell, backgroundColor: backColor, color: textColor }
            },
            valueGetter: (params) => {
              if (!Array.isArray(params.data?.closestApproach)) return ''
              if (params.data?.closestApproach[getIndex(params)]?.closestOffset.c2c < 0) return ''
              return params.data?.closestApproach[getIndex(params)]?.closestOffset.c2c
            },
            valueFormatter: (params) => numberWithCommasDecimals(params.value, 2),
          },
          {
            colId: `${type}OffsetC2cName`,
            headerName: `Offset C2C`,
            cellStyle: leftAlignCell,
            columnGroupShow: 'open',
            valueGetter: (params) => {
              if (!Array.isArray(params.data?.closestApproach)) return ''
              return params.data?.closestApproach[getIndex(params)]?.closestOffset.wellNameC2c
            },
          },
          {
            colId: `${type}OffsetSf`,
            headerName: `SF`,
            columnGroupShow: type === 'svy' ? '' : 'open',
            cellStyle: (params) => {
              let backColor = getAlertColor(params.value, settings.current?.alerts?.sf)
              let textColor = getTextColor(backColor)
              return { ...centerAlignCell, backgroundColor: backColor, color: textColor }
            },
            valueGetter: (params) => {
              if (!Array.isArray(params.data?.closestApproach)) return ''
              if (params.data?.closestApproach[getIndex(params)]?.closestOffset.sf < 0) return ''
              return params.data?.closestApproach[getIndex(params)]?.closestOffset.sf
            },
            valueFormatter: (params) => numberWithCommasDecimals(params.value, 3),
          },
          {
            colId: `${type}OffsetSfName`,
            headerName: `Offset SF`,
            cellStyle: leftAlignCell,
            columnGroupShow: 'open',
            valueGetter: (params) => {
              if (!Array.isArray(params.data?.closestApproach)) return ''
              return params.data?.closestApproach[getIndex(params)]?.closestOffset.wellNameSf
            },
          },
          {
            colId: `${type}LeaseLineC2c`,
            headerName: `Lease Line`,
            columnGroupShow: type === 'svy' ? '' : 'open',
            cellStyle: (params) => {
              let backColor = getAlertColor(params.value, settings.current?.alerts?.leaseLines)
              let textColor = getTextColor(backColor)
              return { ...centerAlignCell, backgroundColor: backColor, color: textColor }
            },
            valueGetter: (params) => {
              if (!Array.isArray(params.data?.closestApproach)) return ''
              if (params.data?.closestApproach[getIndex(params)]?.closestLeaseLine.c2c < 0) return ''
              return params.data?.closestApproach[getIndex(params)]?.closestLeaseLine.c2c
            },
            valueFormatter: (params) => numberWithCommasDecimals(params.value, 2),
          },
          {
            colId: `${type}LeaseLineName`,
            headerName: `Lease Line`,
            cellStyle: leftAlignCell,
            columnGroupShow: 'open',
            valueGetter: (params) => {
              if (!Array.isArray(params.data?.closestApproach)) return ''
              return params.data?.closestApproach[getIndex(params)]?.closestLeaseLine.wellNameC2c
            },
          },
          {
            colId: `${type}WellPlanC2c`,
            headerName: `WellPlan`,
            columnGroupShow: type === 'svy' ? '' : 'open',
            cellStyle: (params) => {
              let backColor = getAlertColor(params.value, settings.current?.alerts?.wellPlan)
              let textColor = getTextColor(backColor)
              return { ...centerAlignCell, backgroundColor: backColor, color: textColor }
            },
            valueGetter: (params) => {
              if (!Array.isArray(params.data?.closestApproach)) return ''
              if (params.data?.closestApproach[getIndex(params)]?.wellPlan.c2c < 0) return ''
              return params.data?.closestApproach[getIndex(params)]?.wellPlan.c2c
            },
            valueFormatter: (params) => numberWithCommasDecimals(params.value, 2),
          },
        ],
      }
    },
    [centerAlignCell, leftAlignCell, getUnitsText, getAlertColor, getTextColor],
  )

  const getMarkerSize = useCallback((color, value) => {
    if (value <= -9999) return 0
    return color === '#66FF00' ? 3 : color === CRITICAL_COLOR ? 7 : 5
  }, [])

  const markerFormatterSf = useCallback(
    (params) => {
      let color = getAlertColor(params.yValue, settings.current?.alerts?.sf)
      if (color === NO_ALERT_COLOR) color = '#66FF00'

      return {
        size: getMarkerSize(color, params.yValue),
        fill: color,
        stroke: color,
      }
    },
    [getAlertColor, getMarkerSize],
  )

  const markerFormatterC2c = useCallback(
    (params) => {
      let color = getAlertColor(params.yValue, settings.current?.alerts?.c2c)
      if (color === NO_ALERT_COLOR) color = '#66FF00'

      return {
        size: getMarkerSize(color, params.yValue),
        fill: color,
        stroke: color,
      }
    },
    [getAlertColor, getMarkerSize],
  )

  const markerFormatterWellPlan = useCallback(
    (params) => {
      let color = getAlertColor(params.yValue, settings.current?.alerts?.wellPlan)
      if (color === NO_ALERT_COLOR) color = '#66FF00'

      return {
        size: getMarkerSize(color, params.yValue),
        fill: color,
        stroke: color,
      }
    },
    [getAlertColor, getMarkerSize],
  )

  const markerFormatterLeaseLine = useCallback(
    (params) => {
      let color = getAlertColor(params.yValue, settings.current?.alerts?.leaseLines)
      if (color === NO_ALERT_COLOR) color = '#66FF00'

      return {
        size: getMarkerSize(color, params.yValue),
        fill: color,
        stroke: color,
      }
    },
    [getAlertColor, getMarkerSize],
  )

  const sparkLineParams = useCallback(
    (alertType) => {
      return {
        sparklineOptions: {
          type: 'area',
          highlightStyle: {
            fill: 'rgb(143,185,77)',
            size: 10,
          },
          axis: {
            strokeWidth: 0,
          },
          marker: {
            formatter:
              alertType === 'sf'
                ? markerFormatterSf
                : alertType === 'wellPlan'
                ? markerFormatterWellPlan
                : alertType === 'leaseLine'
                ? markerFormatterLeaseLine
                : markerFormatterC2c,
          },
          tooltip: {
            renderer: (params) => {
              return {
                backgroundColor: 'black',
                opacity: 0.7,
                color: 'white',
              }
            },
          },
        },
      }
    },
    [markerFormatterSf, markerFormatterC2c, markerFormatterWellPlan, markerFormatterLeaseLine],
  )

  const relativeTimeFormatter = useCallback(relativeTimeMins, [])

  let columnDefs = useMemo(
    () => [
      {
        headerName: '',
        cellRenderer: 'agGroupCellRenderer',
        width: 35,
        cellStyle: centerAlignCell,
        cellClass: 'grid-detail-icon',
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
        filter: null,
        pinned: 'left',
        lockPosition: 'left',
      },
      {
        headerName: 'Fav',
        width: 100,
        colId: 'favouite',
        pinned: 'left',
        lockPosition: 'left',
        hide: !hasObjectsOfInterest(),
        cellStyle: centerAlignCell,
        valueGetter: (params) => isObjectOfInterest(params.data?.rig, params.data?.operator, params.data?.district),
        cellRenderer: (params) => {
          if (!isObjectOfInterest(params.data?.rig, params.data?.operator, params.data?.district)) return null
          return <Iconify icon='emojione:star' width='25' height='25' />
        },
      },
      {
        colId: 'jobDetails',
        headerName: 'Job Details',
        marryChildren: true,
        groupId: 'jobDetails',
        children: [
          {
            field: 'jobNum',
            colId: 'jobNum',
            headerName: 'Job#',
            cellStyle: centerAlignCell,
          },
          {
            field: 'operator',
            colId: 'operator',
            headerName: 'Operator',
            cellStyle: leftAlignCell,
          },
          {
            field: 'wellName',
            colId: 'wellName',
            headerName: 'Well Name',
            cellStyle: leftAlignCell,
          },
          {
            field: 'rig',
            colId: 'rig',
            headerName: 'Rig',
            cellStyle: leftAlignCell,
          },
          {
            field: 'state',
            colId: 'state',
            headerName: 'State',
            cellStyle: leftAlignCell,
            columnGroupShow: 'open',
          },
          {
            field: 'county',
            colId: 'county',
            headerName: 'County',
            cellStyle: leftAlignCell,
            columnGroupShow: 'open',
          },
          {
            field: 'ddCoordinator',
            colId: 'ddCoordinator',
            headerName: 'Coordinator',
            cellStyle: leftAlignCell,
            columnGroupShow: 'open',
          },
          {
            field: 'wellStatus',
            colId: 'wellStatus',
            headerName: 'Well Status',
            cellStyle: (params) => {
              return { ...centerAlignCell, color: params.value === 'Active' ? '#66FF00' : 'red' }
            },
            columnGroupShow: 'open',
          },
          {
            headerName: 'DEX',
            field: 'icdsMinsSinceUpdate',
            colId: 'icdsUpdate',
            columnGroupShow: 'open',
            valueGetter: (params) => {
              if (!params.data) return 99999999
              if (!params.data?.hasOwnProperty('icdsMinsSinceUpdate')) return 99999999
              if (params.data?.icdsMinsSinceUpdate === null || params.data?.icdsMinsSinceUpdate === undefined) {
                return 99999999
              }
              if (typeof params.data?.icdsMinsSinceUpdate === 'string' && params.data?.icdsMinsSinceUpdate === '') {
                return 99999999
              }

              return parseFloat(params.data?.icdsMinsSinceUpdate)
            },
            cellStyle: (params) => {
              return {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: getTimeDurationColor(params.data?.icdsMinsSinceUpdate),
              }
            },
            valueFormatter: (params) => relativeTimeFormatter(params.data?.icdsMinsSinceUpdate),
          },
          {
            field: 'numLeaseLines',
            colId: 'numLeaseLines',
            headerName: '#Leaselines',
            cellStyle: centerAlignCell,
            columnGroupShow: 'open',
            valueFormatter: (params) => numberWithCommasDecimals(params.value, 0),
          },
          {
            colId: 'numOffsets',
            headerName: '#Offsets',
            cellStyle: (params) => {
              return { ...centerAlignCell, color: params.value === 0 ? 'tomato' : '#ffff' }
            },
            columnGroupShow: 'open',
            valueGetter: (params) => {
              if (!Array.isArray(params.data?.offsetWellResults)) return 0
              return params.data?.offsetWellResults.length - params.data?.numLeaseLines
            },
            valueFormatter: (params) => numberWithCommasDecimals(params.value, 0),
          },
          {
            colId: 'numErrors',
            headerName: '#Errors',
            columnGroupShow: 'open',
            valueGetter: (params) => {
              if (!Array.isArray(params.data?.errors)) return 0
              return params.data?.errors.length
            },
            cellStyle: (params) => {
              return { ...centerAlignCell, color: params.value > 0 ? 'orange' : '#66FF00' }
            },
            valueFormatter: (params) => numberWithCommasDecimals(params.value, 0),
          },
        ],
      },
      getClosestApproachColDef('svy'),
      getClosestApproachColDef('proj'),
      {
        colId: 'c2cOffsetTrend',
        headerName: 'Offset C2C',
        width: 150,
        valueGetter: (params) => {
          if (!Array.isArray(params.data?.closestApproach)) return []
          return params.data?.closestApproach.map((elem) => {
            return elem.closestOffset.c2c
          })
        },
        cellRenderer: 'agSparklineCellRenderer',
        cellRendererParams: sparkLineParams('c2c'),
      },
      {
        colId: 'sfOffsetTrend',
        headerName: 'Offset SF',
        width: 150,
        valueGetter: (params) => {
          if (!Array.isArray(params.data?.closestApproach)) return []
          return params.data?.closestApproach.map((elem) => {
            return elem.closestOffset.sf
          })
        },
        cellRenderer: 'agSparklineCellRenderer',
        cellRendererParams: sparkLineParams('sf'),
      },
      {
        colId: 'leaseLineTrend',
        headerName: 'Lease Line',
        width: 150,
        valueGetter: (params) => {
          if (!Array.isArray(params.data?.closestApproach)) return []
          return params.data?.closestApproach.map((elem) => {
            return elem.closestLeaseLine.c2c
          })
        },
        cellRenderer: 'agSparklineCellRenderer',
        cellRendererParams: sparkLineParams('leaseLine'),
      },
      {
        colId: 'wellPlanTrend',
        headerName: 'Well Plan',
        width: 150,
        valueGetter: (params) => {
          if (!Array.isArray(params.data?.closestApproach)) return []
          return params.data?.closestApproach.map((elem) => {
            return elem.wellPlan.c2c
          })
        },
        cellRenderer: 'agSparklineCellRenderer',
        cellRendererParams: sparkLineParams('wellPlan'),
      },
    ],
    [
      centerAlignCell,
      leftAlignCell,
      getClosestApproachColDef,
      sparkLineParams,
      hasObjectsOfInterest,
      isObjectOfInterest,
      relativeTimeFormatter,
    ],
  )

  const handleCloseStatus = () => {
    setStatus({ show: false, severity: 'info', message: '' })
  }

  const isExternalFilterPresent = useCallback(() => {
    return onlyShowFollowing
  }, [onlyShowFollowing])

  const doesExternalFilterPass = useCallback(
    (node) => {
      if (node.data) {
        return isObjectOfInterest(node.data.rig, node.data.operator, node.data.district)
      }
      return true
    },
    [isObjectOfInterest],
  )

  const DetailCellRenderer = forwardRef((params, ref) => {
    const sortOffsets = (a, b) => {
      if (!Array.isArray(a.acResults) || a.acResults.length === 0) return -1
      if (!Array.isArray(b.acResults) || b.acResults.length === 0) return -1
      let c2cA = a.acResults[0].c2c
      let c2cB = b.acResults[0].c2c
      if (c2cA < c2cB) return -1
      if (c2cA > c2cB) return 1

      return 0
    }

    useImperativeHandle(ref, () => {
      return {
        refresh() {
          const detailGrid = gridApi.current.getDetailGridInfo(`AC_DASH_WELL_GRID-${params.data?.wellName}`)
          if (!detailGrid) return true

          if (!Array.isArray(acData.current)) return true
          let rowIndex = acData.current.findIndex((row) => row.wellName === params.data?.wellName)
          if (rowIndex < 0) return true
          if (!Array.isArray(acData.current[rowIndex]?.offsetWellResults)) return true

          detailGrid.api?.setGridOption('rowData', acData.current[rowIndex]?.offsetWellResults.sort(sortOffsets))
          return true
        },
      }
    })

    let height = 200
    if (containerRef.current) {
      height = containerRef.current.clientHeight * 0.65
    }

    return (
      <Box sx={{ height: height }}>
        <AntiCollisionDashboardWellGrid
          principalPlanName={params.data?.principalPlanName}
          alertDistances={settings.current?.alerts}
          masterGridApi={gridApi.current}
          wellName={params.data?.wellName}
          data={Array.isArray(params.data?.offsetWellResults) ? params.data?.offsetWellResults.sort(sortOffsets) : []}
        />
      </Box>
    )
  })

  const onUpdateSettings = async (newSettings) => {
    setShowSettingsModal(false)

    if (!newSettings) return
    settings.current = newSettings

    let res = await updateAntiCollisionSettings({ settings: JSON.stringify(newSettings) })
    if (!_isMounted.current) return

    if (res?.error) {
      setStatus({ show: true, severity: 'error', message: `${res?.error?.response?.data?.error}` })
      return
    }

    setStatus({ show: true, severity: 'success', message: 'Settings updated successfully' })
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        height: '100%',
        width: `100%`,
      }}>
      {confirm.show ? (
        <ConfirmDialog
          title={confirm?.title}
          open={confirm?.show}
          setOpen={() => setConfirm({ show: false })}
          onConfirm={() => {}}>
          {confirm?.text}
        </ConfirmDialog>
      ) : null}
      {showErrorsModal ? (
        <AntiCollisionDashboardErrorsModal
          data={selectedWellErrors.current}
          onClose={() => setShowErrorsModal(false)}
        />
      ) : null}
      {showSettingsModal ? (
        <AntiCollisionDashboardSettings
          settings={settings.current}
          onClose={() => setShowSettingsModal(false)}
          onApply={onUpdateSettings}
        />
      ) : null}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
        }}>
        <TimeElapsedCounter data={acData} />
        <SearchBar
          value={searchText}
          onChange={(newSearchTerm) => setSearchText(newSearchTerm)}
          onCancelSearch={() => setSearchText('')}
          style={searchBarStyle()}
        />

        <GlobalPrefs isMultiDb={false} showAllWellsButton={false} />
      </Box>
      <Box sx={{ display: 'flex', width: '100%', height: '100%' }}>
        <div ref={containerRef} className={getAgGridTheme()} style={{ width: '100%', height: '100%' }}>
          <AgGridReact
            loading={isLoading}
            maintainColumnOrder={true}
            columnDefs={sortColDefs(columnDefs, 'acDashboardGrid')}
            defaultColDef={defaultColDef}
            getRowId={getRowId}
            animateRows={true}
            enableBrowserTooltips={true}
            gridOptions={gridOptions}
            headerHeight={30}
            onGridReady={onGridReady}
            onFirstDataRendered={onFirstDataRendered}
            getContextMenuItems={getContextMenuItems}
            onFilterChanged={onFilterChanged}
            masterDetail={true}
            detailCellRenderer={DetailCellRenderer}
            detailRowAutoHeight={true}
            isExternalFilterPresent={isExternalFilterPresent}
            doesExternalFilterPass={doesExternalFilterPass}
          />
        </div>
      </Box>
      <Box
        sx={{
          backgroundColor: 'transparent',
          margin: '4px',
          padding: '12px',
          position: 'fixed',
          bottom: '20px',
          right: '20px',
          zIndex: 2,
        }}>
        <MenuButton
          actions={[
            {
              icon: (
                <Iconify icon='ic:round-refresh' style={{ color: appColors.itemTextColor, width: 28, height: 28 }} />
              ),
              name: 'Refresh data',
              onClick: () => fetchAntiCollisionData(),
            },
            {
              icon: (
                <Iconify
                  icon={showChart ? 'bxs:hide' : 'bxs:show'}
                  style={{ color: appColors.itemTextColor, width: 28, height: 28 }}
                />
              ),
              name: showChart ? 'Hide Charts' : 'Show Charts',
              onClick: () => setShowChart(!showChart),
            },
            {
              icon: (
                <Iconify icon={'ic:round-settings'} style={{ color: appColors.itemTextColor, width: 28, height: 28 }} />
              ),
              name: 'Settings',
              onClick: () => setShowSettingsModal(true),
            },
          ]}
        />
      </Box>
      {status?.show ? (
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={status?.show}
          autoHideDuration={2000}
          onClose={handleCloseStatus}>
          <Alert onClose={handleCloseStatus} severity={status.severity} elevation={4} variant='filled'>
            {status.message}
          </Alert>
        </Snackbar>
      ) : null}
    </Box>
  )
}

export default AntiCollisionDashboardGrid
