import { blankFilterLists } from 'components/common/filterLists'
import { atom, selector } from 'recoil'
import { saveItemToLS, getItemFromLS } from 'utils/localStorage'
import { filterDefs } from 'components/Analytics/AnalyticsPage/FilterDefs'
import cloneDeep from 'lodash/cloneDeep'

export const themeAtom = atom({
  key: 'themeKey',
  default: getItemFromLS('theme', 'theme') || 'dark',
})

export const themeSelector = selector({
  key: 'themeSelectorKey',
  get: ({ get }) => {
    return get(themeAtom)
  },
  set: ({ set }, newValue) => {
    if (newValue && newValue.length > 0) {
      saveItemToLS('theme', 'theme', newValue)
    }
    set(themeAtom, newValue)
  },
})

export const refreshSurveyProgramGridAtom = atom({
  key: 'refreshSurveyProgramGridKey',
  default: false,
})

export const defaultToolOrder = {
  toolOrderId: -1,
  jobNumber: '',
  rig: '',
}

export const onlyShowFollowingWellsAtom = atom({
  key: 'onlyShowFolowingWellsKey',
  default: false,
})

export const currentToolOrderAtom = atom({
  key: 'currentToolOrderAtomKey',
  default: defaultToolOrder,
})

export const footagePerRigAtom = atom({
  key: 'footagePerRigAtomKey',
  default: [],
})

export const wellListAtom = atom({
  key: 'wellListAtomKey',
  default: [],
})

export const wellListLoadingAtom = atom({
  key: 'wellListLoadingAtomKey',
  default: false,
})

export const wellListLoadingSelector = selector({
  key: 'wellListLoadingSelectorKey',
  get: ({ get }) => {
    const curLoading = get(wellListLoadingAtom)
    return curLoading
  },
  set: ({ set }, newValue) => {
    set(wellListLoadingAtom, newValue)
  },
})

export const wellDataAtom = atom({
  key: 'wellDataAtom',
  default: null,
})

export const dateBaseTreeUpdateNameAtom = atom({
  key: 'dateBaseTreeUpdateNameAtomKey',
  default: null,
})

export const dateBaseTreeSelectedPageAtom = atom({
  key: 'dateBaseTreeSelectedPageAtomKey',
  default: null,
})

export const wellDataSelector = selector({
  key: 'wellDataSelector',
  get: ({ get }) => {
    const wellData = get(wellDataAtom)
    return wellData
  },
  set: ({ set }, newValue) => {
    set(wellDataAtom, newValue)
  },
})

export const currentWellAtom = atom({
  key: 'currentWellAtomKey',
  default: '',
})

export const currentEngBhaHeadersAtom = atom({
  key: 'currentEngBhaHeadersAtomKey',
  default: [],
})

export const currentSelEngBhaHeadersAtom = atom({
  key: 'currentSelEngBhaHeadersAtomKey',
  default: [],
})

export const orgIconsAtom = atom({
  key: 'orgIconsAtomKey',
  default: [],
})

export const currentWellSelector = selector({
  key: 'currentWellSelectorKey',
  get: ({ get }) => {
    const currWell = get(currentWellAtom)
    return currWell
  },
  set: ({ set }, newValue) => {
    if (newValue && newValue.length > 0) {
      saveItemToLS('currentWell', 'currentWell', newValue)
    }
    set(currentWellAtom, newValue)
  },
})

export const currentEngCaseAtom = atom({
  key: 'currentEngCaseAtomKey',
  default: '',
  get: ({ get }) => {
    const curEngCase = get(currentEngCaseAtom)
    return curEngCase
  },
  set: ({ set }, newValue) => {
    set(currentEngCaseAtom, newValue)
  },
})

export const defaultWellDetails = {
  actualWell: '',
  wellStatus: '',
  operator: '',
  rig: '',
  jobNum: '',
  lat: 29.7604,
  lng: -95.3698,
}

export const currentWellDetailsAtom = atom({
  key: 'currentWellDetailsAtomKey',
  default: defaultWellDetails,
})

export const currentWellDetailsSelector = selector({
  key: 'currentWellDetailsSelectorKey',
  get: ({ get }) => {
    const currWellDetails = get(currentWellDetailsAtom)
    return currWellDetails
  },
  set: ({ set }, newValue) => {
    if (newValue && newValue.actualWell?.length > 0) {
      saveItemToLS('currentWellDetails', 'currentWellDetails', newValue)
    }
    set(currentWellDetailsAtom, newValue)
  },
})

export const wellsMapRigSelectorAtom = atom({
  key: 'wellsMapRigSelectorAtomKey',
  default: { 'Active': true, 'Upcoming': false, 'Standby': false },
})

export const wellsMapLayersSelectorAtom = atom({
  key: 'wellsMapLayerSelectorAtomKey',
  default: {
    'Shale Plays': true,
    'Conventional Plays': false,
    'States': false,
    'Counties': false,
    'Well Paths': false,
  },
})

export const analyticsPickListAtom = atom({
  key: 'analyticsPickListKey',
  default: false,
})

export const currentBhaAtom = atom({
  key: 'currentBhaKey',
  default: {
    defaultBhaAtom: null,
    bhaNumForPdf: null,
    bhaDescription: null,
    pdfDocData: null,
    pdfFileName: null,
  },
})

export const currentAwsRegionAtom = atom({
  key: 'currentAwsRegionKey',
  default: 'us-east-2',
})

export const userPrefsAtom = atom({
  key: 'userPrefsAtomKey',
  default: '',
})

export const userUserRoleAtom = atom({
  key: 'userRoleAtomKey',
  default: {},
})

export const userDatabaseAccessAtom = atom({
  key: 'userDatabaseAccessAtomKey',
  default: { userName: '', organizations: [] },
})

export const userDatabaseAccessSelector = selector({
  key: 'userDatabaseAccessSelectorKey',
  get: ({ get }) => {
    const currRole = get(userDatabaseAccessAtom)
    return currRole
  },
  set: ({ set }, newValue) => {
    set(userDatabaseAccessAtom, newValue)
  },
})

export const userRoleSelector = selector({
  key: 'userRoleSelectorKey',
  get: ({ get }) => {
    const currRole = get(userUserRoleAtom)
    return currRole
  },
  set: ({ set }, newValue) => {
    set(userUserRoleAtom, newValue)
  },
})

export const actionBarWidthAtom = atom({
  key: 'actionBarWidthAtomKey',
  default: 60,
})

export const refetchWellDataAtom = atom({
  key: 'refetchWellDataAtomKey',
  default: false,
})

export const wellViewsOpenAtom = atom({
  key: 'wellViewsOpenAtomKey',
  default: false,
})

export const currentPageAtom = atom({
  key: 'currentPageAtomKey',
  default: '',
})

export const focusedSurveyAtom = atom({
  key: 'focusedSurveyAtomKey',
  default: null,
})

export const focusedSurveySelector = selector({
  key: 'focusedSurveyAtomSelectorKey',
  get: ({ get }) => {
    const currData = get(focusedSurveyAtom)
    return currData
  },
  set: ({ set }, newValue) => {
    set(focusedSurveyAtom, newValue)
  },
})

export const analyticsSearchParamsAtom = atom({
  key: 'analyticsSearchParamsAtomKey',
  default: {
    operator: [],
    state: [],
    county: [],
    rig: [],
    wellStatus: [],
    jobNum: [],
    directionalCompany: [],
    formation: [],
    district: [],
    holeSize: [],
    isLih: '',
    phase: [],
    dateFrom: '',
    dateTo: '',
  },
})

export const analyticsPhaseFilterAtom = atom({
  key: 'analyticsPhaseFilterAtomKey',
  default: '',
})

export const multiWellDashboardSelectedWells = atom({
  key: 'multiWellDashboardSelectedWellsKey',
  default: [],
})

export const analyticsSelectedWells = atom({
  key: 'analyticsSelectedWellsKey',
  default: [],
})

export const refreshWellList = atom({
  key: 'wellUpdatedAtomKey',
  default: '',
})

export const analyticsChartIndexAtom = atom({
  key: 'analyticsChartIndexAtomKey',
  default: 'map',
})

export const analyticsMapMountedAtom = atom({
  key: 'analyticsMapMountedAtomKey',
  default: false,
})

export const engineeringDashboardSelectedChartAtom = atom({
  key: 'engineeringDashboardSelectedChartAtomKey',
  default: 'pumpPressure',
})

export const engineeringDashLayoutAtom = atom({
  key: 'engineeringDashLayoutAtomKey',
  default: [
    { size: '20%', visible: true },
    { size: '70%', visible: true },
  ],
})

export const shippingTicketLayoutAtom = atom({
  key: 'shippingTicketLayoutKey',
  default: [
    { size: '40%', visible: true },
    { size: '50%', visible: true },
  ],
})

export const bidLayoutAtom = atom({
  key: 'bidLayoutKey',
  default: [
    { size: '40%', visible: true },
    { size: '50%', visible: true },
  ],
})

export const bidAddressesLayoutAtom = atom({
  key: 'bidAddressesLayoutKey',
  default: [
    { size: '50%', visible: true },
    { size: '50%', visible: true },
    { size: '50%', visible: true },
  ],
})

export const shippingTicketAddressesLayoutAtom = atom({
  key: 'shippingTicketAddressesLayoutKey',
  default: [
    { size: '50%', visible: true },
    { size: '50%', visible: true },
    { size: '50%', visible: true },
  ],
})

export const operatorPropertiesLayoutAtom = atom({
  key: 'operatorPropertiesLayoutKey',
  default: [
    { size: '60%', visible: true },
    { size: '30%', visible: true },
    { size: '70%', visible: true },
    { size: '50%', visible: true },
  ],
})

export const targetsLayoutAtom = atom({
  key: 'targetsLayoutAtomKey',
  default: [
    { size: '30%', visible: true },
    { size: '50%', visible: true },
  ],
})

export const templatesLayoutAtom = atom({
  key: 'templatesLayoutAtomKey',
  default: [
    { size: '30%', visible: true },
    { size: '50%', visible: true },
  ],
})

export const selectedTargetAtom = atom({
  key: 'selectedTargetAtomKey',
  default: null,
})

export const selectedTemplateAtom = atom({
  key: 'selectedTemplateAtomKey',
  default: null,
})

export const fieldPropertiesLayoutAtom = atom({
  key: 'fieldPropertiesLayoutKey',
  default: [
    { size: '60%', visible: true },
    { size: '50%', visible: true },
  ],
})

export const facilityPropertiesLayoutAtom = atom({
  key: 'facilityPropertiesLayoutKey',
  default: [
    { size: '50%', visible: true },
    { size: '50%', visible: true },
  ],
})

export const wellPropertiesLayoutAtom = atom({
  key: 'wellPropertiesLayoutKey',
  default: [
    { size: '65%', visible: true },
    { size: '65%', visible: true },
    { size: '50%', visible: true },
  ],
})

export const plannedWellPropertiesLayoutAtom = atom({
  key: 'plannedWellPropertiesLayoutAtomKey',
  default: [
    { size: '20%', visible: true },
    { size: '50%', visible: true },
    { size: '35%', visible: true },
    { size: '20%', visible: true },
  ],
})

export const actualWellPropertiesLayoutAtom = atom({
  key: 'actualWellPropertiesLayoutAtomKey',
  default: [
    { size: '20%', visible: true },
    { size: '50%', visible: true },
    { size: '35%', visible: true },
    { size: '20%', visible: true },
  ],
})

export const surveyPropertiesLayoutAtom = atom({
  key: 'surveyPropertiesLayoutKey',
  default: [{ size: '50%', visible: true }],
})

export const pipeTallyLayoutAtom = atom({
  key: 'pipeTallLayoutKey',
  default: [{ size: '50%', visible: true }],
})

export const personnelUtilizationLayoutAtom = atom({
  key: 'personnelUtilizationLayoutKey',
  default: [{ size: '30%', visible: true }],
})

export const databaseTreeLayoutAtom = atom({
  key: 'databaseTreeLayouKey',
  default: [{ size: '30%', visible: true }],
})

export const soloCloudLayoutAtom = atom({
  key: 'soloCloudLayoutKey',
  default: [
    { size: '50%', visible: true },
    { size: '50%', visible: true },
  ],
})

export const assetSearchLayoutAtom = atom({
  key: 'assetSearchAtomKey',
  default: [
    { size: '40%', visible: true },
    { size: '50%', visible: true },
  ],
})

export const bhaTendencyAnalysisParamsAtom = atom({
  key: 'bhaTendencyAnalysisParamsAtomKey',
  default: {
    startInc: 0,
    endInc: 90,
    startWob: 5,
    endWob: 40,
    incRotating: false,
    tendencyVariable: 'WOB',
    startMotorBend: 0.5,
    endMotorBend: 2.12,
  },
})

export const bhaCriticalRPMAnalysisParamsAtom = atom({
  key: 'bhaCriticalRPMAnalysisParamsAtomKey',
  default: { startInc: 0, endInc: 90, startWob: 0, endWob: 40, vibrationVariable: 'WOB' },
})

export const selectedEngineeringCaseAtom = atom({
  key: 'selectedEngineeringCaseAtomKey',
  default: null,
})

export const surgeAndSwabMinMaxEmw = atom({
  key: 'surgeAndSwabMinMaxEmwKey',
  default: null,
})

export const selectedAssetSearchAtom = atom({
  key: 'selectedAssetSearchAtomKey',
  default: null,
})

export const selectedEngineeringWellboreAtom = atom({
  key: 'selectedEngineeringWellboreAtomKey',
  default: -1,
})

export const analyticsFilterDefsAtom = atom({
  key: 'analyticsFilterDefsAtomKey',
  default: cloneDeep(filterDefs),
})

export const analyticsRadiusFilterAtom = atom({
  key: 'analyticsRadiusFilterAtomKey',
  default: {
    isActive: false,
    radius: 40233.6,
    center: { lat: 29.7604, lng: -95.3698 },
  },
})

export const filterListsAtom = atom({
  key: 'filterListsAtomKey',
  default: blankFilterLists,
})

export const userUpdatedAtom = atom({
  key: 'userUpdatedAtomKey',
  default: '',
})

export const currentIcdsOrgAtom = atom({
  key: 'currentIcdsOrgAtomKey',
  default: '',
})

export const currentInfrastructureEc2Atom = atom({
  key: 'currentInfrastructureEc2Key',
  default: [],
})

export const currentInfrastructureEc2Selector = selector({
  key: 'currentInfrastructureEc2SelectorKey',
  get: ({ get }) => {
    const currData = get(currentInfrastructureEc2Atom)
    return currData
  },
  set: ({ set }, newValue) => {
    set(currentInfrastructureEc2Atom, newValue)
  },
})

export const currentInfrastructureAcDashAtom = atom({
  key: 'currentInfrastructureAcDashKey',
  default: {},
})

export const currentInfrastructureAcDashSelector = selector({
  key: 'currentInfrastructureAcDashSelectorKey',
  get: ({ get }) => {
    const currData = get(currentInfrastructureAcDashAtom)
    return currData
  },
  set: ({ set }, newValue) => {
    set(currentInfrastructureAcDashAtom, newValue)
  },
})

export const currentInfrastructureRdsAtom = atom({
  key: 'currentInfrastructureRdsKey',
  default: {},
})

export const currentInfrastructureRdsSelector = selector({
  key: 'currentInfrastructureRdsSelectorKey',
  get: ({ get }) => {
    const currData = get(currentInfrastructureRdsAtom)
    return currData
  },
  set: ({ set }, newValue) => {
    set(currentInfrastructureRdsAtom, newValue)
  },
})

export const currentInfrastructureIcdsAtom = atom({
  key: 'currentInfrastructureIcdsKey',
  default: [],
})

export const currentInfrastructureIcdsSelector = selector({
  key: 'currentInfrastructureIcdsSelectorKey',
  get: ({ get }) => {
    const currData = get(currentInfrastructureIcdsAtom)
    return currData
  },
  set: ({ set }, newValue) => {
    set(currentInfrastructureIcdsAtom, newValue)
  },
})

export const currentInfrastructureIcdsVersionAtom = atom({
  key: 'currentInfrastructureIcdsVersionKey',
  default: '',
})

export const currentInfrastructureIcdsVersionSelector = selector({
  key: 'currentInfrastructureIcdsVersionSelectorKey',
  get: ({ get }) => {
    const currData = get(currentInfrastructureIcdsVersionAtom)
    return currData
  },
  set: ({ set }, newValue) => {
    set(currentInfrastructureIcdsVersionAtom, newValue)
  },
})

export const currentInfrastructureLogAtom = atom({
  key: 'currentInfrastructureLogKey',
  default: [],
})

export const currentInfrastructureLogSelector = selector({
  key: 'currentInfrastructureLogSelectorKey',
  get: ({ get }) => {
    const currData = get(currentInfrastructureLogAtom)
    return currData
  },
  set: ({ set }, newValue) => {
    set(currentInfrastructureLogAtom, newValue)
  },
})

export const currentInfrastructurePendingLogsAtom = atom({
  key: 'currentInfrastructurePendingLogsKey',
  default: [],
})

export const currentInfrastructurePendingLogsSelector = selector({
  key: 'currentInfrastructurePendingLogsSelectorKey',
  get: ({ get }) => {
    const currData = get(currentInfrastructurePendingLogsAtom)
    return currData
  },
  set: ({ set }, newValue) => {
    set(currentInfrastructurePendingLogsAtom, newValue)
  },
})

export const currentInfrastructureAcqCacheAtom = atom({
  key: 'currentInfrastructureAcqCacheKey',
  default: { wellDataCache: null, wellReadCache: null },
})

export const currentInfrastructureAcqCacheSelector = selector({
  key: 'currentInfrastructureAcqCacheSelectorKey',
  get: ({ get }) => {
    const currData = get(currentInfrastructureAcqCacheAtom)
    return currData
  },
  set: ({ set }, newValue) => {
    set(currentInfrastructureAcqCacheAtom, newValue)
  },
})

export const currentInfrastructureChartRangeAtom = atom({
  key: 'currentInfrastructureChartRangeKey',
  default: false,
})

export const unitSetMapAtom = atom({
  key: 'unitSetMap',
  default: {},
})

export const unitSetMapSelector = selector({
  key: 'unitSetMapSelector',
  get: ({ get }) => {
    const currVal = get(unitSetMapAtom)
    return currVal
  },
  set: ({ set }, newValue) => {
    set(unitSetMapAtom, newValue)
  },
})

export const unitSetListAtom = atom({
  key: 'unitSetList',
  default: {},
})

export const unitSetListSelector = selector({
  key: 'unitSetListSelector',
  get: ({ get }) => {
    const currVal = get(unitSetListAtom)
    return currVal
  },
  set: ({ set }, newValue) => {
    set(unitSetListAtom, newValue)
  },
})

export const objInterestAtom = atom({
  key: 'objInterestAtom',
  default: { districts: null, operators: null, rigs: null },
})

export const objInterestSelector = selector({
  key: 'objInterestSelector',
  get: ({ get }) => {
    const currVal = get(objInterestAtom)
    return currVal
  },
  set: ({ set }, newValue) => {
    set(objInterestAtom, newValue)
  },
})

export const selectedWallplotElementAtom = atom({
  key: 'selectedWallplotElementAtom',
  default: null,
})

export const selectedWallplotElementSelector = selector({
  key: 'selectedWallplotElementSelector',
  get: ({ get }) => {
    const currVal = get(selectedWallplotElementAtom)
    return currVal
  },
  set: ({ set }, newValue) => {
    set(selectedWallplotElementAtom, newValue)
  },
})
