import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Alert, Box, Snackbar } from '@mui/material'
import WpToolbar from 'components/WellPages/WallplotComposer/WpToolbar'
import Viewport from 'components/WellPages/WallplotComposer/Viewport/Viewport'
import useUnits, { UNITS_FOR } from 'components/common/hooks/useUnits'
import { useReactToPrint } from 'react-to-print'
import { cloneDeep } from 'lodash'
import { ELEMENT_TYPES as elementType } from 'components/WellPages/WallplotComposer/Elements/elementDefs'
import { getTextElementDefaults } from 'components/WellPages/WallplotComposer/Elements/TextElement'
import { getImageElementDefaults } from 'components/WellPages/WallplotComposer/Elements/ImageElement'

import useOrgIcons from 'components/common/hooks/useOrgIcons'
import useOperatorLogos, { LOGO_PRIMARY, LOGO_SECONDARY } from 'components/common/hooks/useOperatorLogos'
import { getTableElementDefaults } from 'components/WellPages/WallplotComposer/Elements/TableElement'
import { getChartElementDefaults } from 'components/WellPages/WallplotComposer/Elements/ChartElements/ChartElement'
import BlankImage from 'assets/NoImage.png'
import LoadWpcTemplateModal from 'components/WellPages/WallplotComposer/Templates/LoadWpcTemplateModal'
import SaveWpcTemplateModal from 'components/WellPages/WallplotComposer/Templates/SaveWpcTemplateModal'
import NewLayoutCheckModal from './Templates/NewLayoutCheckModal'
import useWallPlotComposerTemplates from 'components/common/hooks/WallPlotComposer/useWallPlotComposerTemplates'
import {
  LABEL_CATEGORIES as labelCategories,
  getPositionValues,
  getLabelContent,
  getLabelColor,
  getHasTether,
  getLabelSize,
  getLabelBold,
  extractLabelUids,
  generateDepthLabels,
} from 'components/WellPages/WallplotComposer/Elements/ChartElements/labelDefinitions'

const userToBase = (value, units) => {
  if (units === 'm') return value / 2.54
  return value
}

const baseToUser = (value, units) => {
  if (units === 'm') return value * 2.54
  return value
}

const UNDO_STACK_SIZE = 50

function isEqual(obj1, obj2, ignoreList = []) {
  // Helper function to check if a property should be ignored
  function shouldIgnore(prop) {
    return ignoreList.includes(prop)
  }

  // Helper function to compare two values deeply
  function deepCompare(val1, val2) {
    if (typeof val1 !== typeof val2) return false

    if (typeof val1 === 'object' && val1 !== null && val2 !== null) {
      if (Array.isArray(val1) !== Array.isArray(val2)) return false

      if (Array.isArray(val1)) {
        if (val1.length !== val2.length) return false
        for (let i = 0; i < val1.length; i++) {
          if (!deepCompare(val1[i], val2[i])) return false
        }
        return true
      } else {
        const keys1 = Object.keys(val1).filter((key) => !shouldIgnore(key))
        const keys2 = Object.keys(val2).filter((key) => !shouldIgnore(key))

        if (keys1.length !== keys2.length) return false

        for (let key of keys1) {
          if (!keys2.includes(key) || !deepCompare(val1[key], val2[key])) return false
        }
        return true
      }
    } else {
      return val1 === val2
    }
  }

  return deepCompare(obj1, obj2)
}

const defaultWpcTemplate = (pageLayout) => {
  return {
    uid: -1,
    name: 'Untitled',
    actualWell: '',
    elements: [],
    pageLayout: {
      orientation: 'portrait',
      size: 'letter',
      width: 8.5,
      height: 11,
      marginTop: 0.25,
      marginBottom: 0.25,
      marginLeft: 0.25,
      marginRight: 0.25,
      gridSpacing: 0.5,
      hasUnits: false,
    },
    config: { showRulers: true, showGrid: false, showMargins: true, snapToGrid: false },
  }
}

const WallplotComposer = ({ wellData }) => {
  const _isMounted = useRef(false)
  const viewportRef = useRef(null)
  const elements = useRef([])
  const [status, setStatus] = useState({ show: false, severity: 'info', message: '' })
  const templateRef = useRef(defaultWpcTemplate())
  const [config, setConfig] = useState({ showRulers: true, showGrid: false, showMargins: true, snapToGrid: false })
  const [pageLayout, setPageLayout] = useState({
    orientation: 'portrait',
    size: 'letter',
    width: 8.5,
    height: 11,
    marginTop: 0.25,
    marginBottom: 0.25,
    marginLeft: 0.25,
    marginRight: 0.25,
    gridSpacing: 0.5,
    hasUnits: false,
  })
  const selectedUids = useRef([])
  const { getUnitsText } = useUnits()

  const undoStack = useRef([[]])
  const undoIndex = useRef(0)
  const [rerender, setRerender] = useState(false)

  const [showLoadModal, setShowLoadModal] = useState(false)
  const [showDuplModal, setShowDuplModal] = useState(false)
  const [showSaveModal, setShowSaveModal] = useState(false)
  const [showNewLayoutModal, setShowNewLayoutModal] = useState(false)
  const saveAsRef = useRef(false)

  const { addWallPlot, updateWallPlot, setWpWellName } = useWallPlotComposerTemplates(wellData?.wellName)

  const wellDataRef = useRef(wellData)

  const printDomRef = useRef(null)
  const handlePrint = useReactToPrint({
    documentTitle: 'Print This Document',
    onBeforeGetContent: () => {
      const pageRef = viewportRef.current.getPageRef()
      if (pageRef && pageRef.current) {
        pageRef.current.prepPrinting()
      }
    },
    onAfterPrint: () => {
      const pageRef = viewportRef.current.getPageRef()
      if (pageRef && pageRef.current) {
        pageRef.current.donePrinting()
      }
    },
    removeAfterPrint: false,
    content: () => {
      return printDomRef.current
    },
  })

  const getUnits = useCallback(() => {
    return getUnitsText(UNITS_FOR.Depth)
  }, [getUnitsText])

  const { getCurrentOrgIcon } = useOrgIcons()
  const {
    getLogo,
    loading: logosLoading,
    refreshLogos,
  } = useOperatorLogos(wellDataRef.current?.actualWellData?.operator)

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
    }
  }, [])

  useEffect(() => {
    if (_isMounted.current) {
      if (wellData) {
        wellDataRef.current = wellData
        setWpWellName(wellData?.wellName)
        refreshLogos(wellData.actualWellData.operator)
      }
    }
  }, [wellData]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (_isMounted.current) {
      if (!logosLoading) {
        elements.current.forEach((el) => {
          if (el.type === elementType.imageOrgLogo) {
            el.data.imageSrc = getCurrentOrgIcon()
          }
          if (el.type === elementType.imageOperLogo) {
            el.data.imageSrc = getLogo(LOGO_PRIMARY)
            if (el.data.imageSrc === 'data:image/*;base64,') el.data.imageSrc = BlankImage
          }
          if (el.type === elementType.imageOperLogo2) {
            el.data.imageSrc = getLogo(LOGO_SECONDARY)
            if (el.data.imageSrc === 'data:image/*;base64,') el.data.imageSrc = BlankImage
          }
        })
      }
    }
  }, [logosLoading]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (_isMounted.current) {
      const units = getUnits()
      if (units && !pageLayout.hasUnits) {
        setPageLayout((oldLayout) => {
          return {
            ...oldLayout,
            width: baseToUser(oldLayout.width, units),
            height: baseToUser(oldLayout.height, units),
            hasUnits: true,
          }
        })
      }
    }
  }, [getUnits, pageLayout.hasUnits])

  const labelIdExists = (catType, uid) => {
    switch (catType) {
      case labelCategories.surveyPts:
        return wellDataRef.current?.surveys?.find((pt) => `${wellData.actualWellData.actualWellName}-${pt.md}` === uid)
      case labelCategories.wellNames:
        if (uid === wellDataRef.current?.actualWellData?.actualWellName) return true
        // if offsets, and the uid is an offset wellname, return true
        break
      case labelCategories.casing:
        return wellDataRef.current?.actualWellData?.casing?.find((c) => `${c.Type}-${c.MD}` === uid)
      case labelCategories.annotations:
        return wellDataRef.current?.actualWellData?.annotations?.find(
          (a) => `${wellData.actualWellData.actualWellName}-${a.md}` === uid,
        )
      case labelCategories.targets:
        return wellDataRef.current?.targets?.find(
          (t) => `${wellData.actualWellData.actualWellName}|${t.targetName}` === uid,
        )
      case labelCategories.lithologies:
        return wellDataRef.current?.actualWellData?.lithologies?.find(
          (l) => `${wellData.actualWellData.actualWellName}-${l.tvd}` === uid,
        )
      case labelCategories.offsetWellNames:
        return wellDataRef.current?.offsetWells?.find((o) => `${o.offsetWellbore}` === uid)
      case labelCategories.offsetSurveyPts:
        const labelUidData = extractLabelUids(uid)
        const wellName = labelUidData[0]
        const md = parseFloat(labelUidData[1])
        let offsetWell = wellData.offsetWells.find((well) => well.offsetWellbore === wellName)
        if (offsetWell) {
          const svyPt = offsetWell.surveyData.find((survey) => survey.md === md)
          if (svyPt) {
            return true
          }
        }
        return wellDataRef.current?.surveys?.find((pt) => `${wellData.actualWellData.actualWellName}-${pt.md}` === uid)
      default:
        console.warn('[Innova] unknown label id category', catType)
        return false
    }
    return false
  }

  const addElement = (eleType, eleSubType) => {
    let newElement = {}

    switch (eleType) {
      case elementType.text:
        newElement = {
          ...getTextElementDefaults({ pageLayout, zIndex: 1000 + elements.current.length }),
          type: eleType,
        }
        break
      case elementType.image:
        newElement = {
          ...getImageElementDefaults({ pageLayout, zIndex: 1000 + elements.current.length }),
          type: eleType,
        }
        newElement.imageSettings.imageType = 'image'
        break
      case elementType.imageOrgLogo:
        newElement = {
          ...getImageElementDefaults({ pageLayout, zIndex: 1000 + elements.current.length }),
          type: eleType,
        }
        newElement.data.imageSrc = getCurrentOrgIcon()
        newElement.imageSettings.imageType = 'asset'
        break
      case elementType.imageOperLogo:
        newElement = {
          ...getImageElementDefaults({ pageLayout, zIndex: 1000 + elements.current.length }),
          type: eleType,
        }
        newElement.data.imageSrc = getLogo(LOGO_PRIMARY)
        if (newElement.data.imageSrc === 'data:image/*;base64,') newElement.data.imageSrc = BlankImage
        newElement.imageSettings.imageType = 'asset'
        break
      case elementType.imageOperLogo2:
        newElement = {
          ...getImageElementDefaults({ pageLayout, zIndex: 1000 + elements.current.length }),
          type: eleType,
        }
        newElement.data.imageSrc = getLogo(LOGO_SECONDARY)
        if (newElement.data.imageSrc === 'data:image/*;base64,') newElement.data.imageSrc = BlankImage
        newElement.imageSettings.imageType = 'asset'
        break
      case elementType.table:
        newElement = {
          ...getTableElementDefaults({ pageLayout, zIndex: 1000 + elements.current.length }),
          type: eleType,
        }
        newElement.tableSettings.tableType = eleSubType
        // newElement.data.wellData = wellDataRef.current
        break
      case elementType.chart:
        newElement = {
          ...getChartElementDefaults({ pageLayout, zIndex: 1000 + elements.current.length }),
          type: eleType,
        }
        newElement.chartSettings.chartType = eleSubType
        // newElement.data.wellData = wellDataRef.current
        break
      default:
        break
    }

    let viewPortTopLeft = viewportRef.current.getElementAddPosition()
    newElement.left = viewPortTopLeft.x
    newElement.top = viewPortTopLeft.y

    elements.current.push(newElement)
    pushUndo()
    selectedUids.current = [newElement.uid]
    setRerender(!rerender)
  }

  const onDeleteElements = () => {
    deleteElements()
  }

  const deleteElements = () => {
    if (Array.isArray(selectedUids.current) && selectedUids.current.length > 0) {
      selectedUids.current.forEach((uid) => {
        let index = elements.current.findIndex((el) => el.uid === uid)
        if (index < 0) return
        elements.current.splice(index, 1)
      })
      pushUndo()
    }

    selectedUids.current = []
    setRerender(!rerender)
  }

  const updateElement = (uid, action, elementProps) => {
    const index = elements.current.findIndex((el) => el.uid === uid)
    if (index !== -1) {
      if (action === 'resize') {
        if (elementProps.top) elements.current[index].top = elementProps.top
        if (elementProps.left) elements.current[index].left = elementProps.left
        elements.current[index].width = elementProps.width
        elements.current[index].height = elementProps.height
      }

      if (action === 'drag') {
        elements.current[index].top = elementProps.top
        elements.current[index].left = elementProps.left
      }

      if (action === 'rotate') {
        elements.current[index].rotate = elementProps.rotate
      }

      if (action === 'zindex') {
        elements.current[index] = { ...elements.current[index], ...elementProps }
      }

      if (action === 'update') {
        elements.current[index] = { ...elements.current[index], ...elementProps }
        //Dont set the re render state here!!!!!
      }

      if (action === 'updateSettings') {
        elements.current[index] = { ...elements.current[index], ...elementProps }
      }
      pushUndo()
    }
  }

  const getElement = (uid) => {
    return elements.current.find((el) => el.uid === uid)
  }

  const moveElement = (uid, dLeft, dTop) => {
    const index = elements.current.findIndex((el) => el.uid === uid)
    if (index < 0) return
    elements.current[index].left += dLeft
    elements.current[index].top += dTop
    pushUndo()
  }

  const onShowProperties = () => {
    // prob needs to call into the viewport--leave this here for the wpToolbar button...
    viewportRef.current?.showElementProperties()
  }

  const pushUndo = () => {
    // if the undoIndex is not at the end of the stack, remove all the redo states, we're
    // adding a new change, so remove states that were undone and add the new state to the stack
    if (undoIndex.current !== undoStack.current.length - 1) {
      undoStack.current.splice(undoIndex.current + 1, undoStack.current.length - undoIndex.current - 1)
    }

    if (undoStack.current.length >= UNDO_STACK_SIZE) {
      undoStack.current.shift()
    }
    const lastState = cloneDeep(elements.current)

    if (undoStack.current.length > 0 && isEqual(lastState, undoStack.current[undoStack.current.length - 1], ['uid'])) {
      // if the object hasn't changed, don't push it onto the stack -- an example, trying to resize and
      // the snap-to-grid doesn't allow the resize to happen, so the object doesn't change
      return
    }

    undoStack.current.push([...lastState])
    undoIndex.current = undoStack.current.length - 1
  }

  const onUndo = () => {
    if (undoStack.current.length === 0) return
    if (viewportRef.current) viewportRef.current.clearSelection()

    //Not sure why the time out is needed but if it isnt here an object wont move / resize correctly
    setTimeout(() => {
      undoIndex.current--
      if (undoIndex.current < 0) undoIndex.current = 0 // can't go back past the first state in the buffer
      elements.current = cloneDeep(undoStack.current[undoIndex.current])
      setRerender(!rerender)
    }, 50)
  }

  const onRedo = () => {
    if (undoIndex.current >= undoStack.current.length - 1) return
    if (viewportRef.current) viewportRef.current.clearSelection()

    setTimeout(() => {
      undoIndex.current++
      const newState = cloneDeep(undoStack.current[undoIndex.current])
      elements.current = newState
      if (undoIndex.current > undoStack.current.length - 1) undoIndex.current = undoStack.current.length - 1

      setRerender(!rerender)
    }, 50)
  }

  const onNewLayout = () => {
    if (undoStack.current.length > 1) {
      setShowNewLayoutModal(true)
      return
    }
    newLayout()
  }

  const onNewLayoutCheck = (saveLayout) => {
    setShowNewLayoutModal(false)
    if (saveLayout) {
      onSaveLayout()
    } else {
      newLayout()
    }
  }

  const newLayout = () => {
    undoIndex.current = 0
    undoStack.current = [[]]
    elements.current = []
    templateRef.current = defaultWpcTemplate(pageLayout)
    setConfig(templateRef.current.config)
    setPageLayout(templateRef.current.pageLayout)
    selectedUids.current = []
    setRerender(!rerender)
  }

  // need to handle save as to force a new template name

  const onSaveLayout = (useSaveAs = false) => {
    const units = getUnits()
    templateRef.current = {
      ...templateRef.current,
      actualWell: wellDataRef.current.wellName,
      elements: elements.current.map((el) => {
        let elCopy = cloneDeep(el)
        return {
          ...elCopy,
          left: userToBase(elCopy.left, units),
          top: userToBase(elCopy.top, units),
          width: userToBase(elCopy.width, units),
          height: userToBase(elCopy.height, units),
        }
      }),
      pageLayout: {
        ...pageLayout,
        width: userToBase(pageLayout.width, units),
        height: userToBase(pageLayout.height, units),
      },
      config: config,
    }

    // if a saveAs, need a new UID, and a new name via the save Modal
    if (useSaveAs) {
      saveAsRef.current = true
      // templateRef.current.uid = -1
      // templateRef.current.name = 'Untitled'
      setShowSaveModal(true)
      return
    }

    if (templateRef.current.name === 'Untitled') {
      setShowSaveModal(true)
    } else {
      // just save, no need to show the save modal
      saveLayout(templateRef.current)
    }
  }

  const saveLayout = async (data) => {
    if (!data) return

    //const handleSave = async (data) => {
    // const data = getGridData()
    if (!data.hasOwnProperty('uid')) return

    templateRef.current.uid = data.uid
    templateRef.current.name = data.name
    templateRef.current.wellName = wellDataRef.current.wellName

    // clone the template ref, since the label positions are converted to strings
    const templatePayload = cloneDeep(templateRef.current)
    for (let i = 0; i < templatePayload.elements.length; i++) {
      if (templatePayload.elements[i].type !== elementType.chart) continue
      if (!templatePayload.elements[i].chartSettings.labels.labelData) continue
      for (let j = 0; j < templatePayload.elements[i].chartSettings.labels.labelData.length; j++) {
        if (templatePayload.elements[i].chartSettings.labels.labelData[j].hasOwnProperty('position')) {
          templatePayload.elements[i].chartSettings.labels.labelData[j].position = JSON.stringify(
            templatePayload.elements[i].chartSettings.labels.labelData[j].position,
          )
          // stringify the font size since it could be 16 or '16px', for example
          templatePayload.elements[i].chartSettings.labels.labelData[j].style.font.size = JSON.stringify(
            templatePayload.elements[i].chartSettings.labels.labelData[j].style.font.size,
          )
        }
      }
    }

    let res = null

    if (saveAsRef.current || templatePayload.uid === -1) {
      res = await addWallPlot({
        templateData: templatePayload,
      })
      if (res && Array.isArray(res) && res.length > 0) {
        templateRef.current = cloneDeep(res[0]) // mostly to capture the UID, is this was a new template
        saveAsRef.current = false // reset the saveAs flag
      }
    } else {
      res = await updateWallPlot({
        uid: data.uid,
        templateData: templatePayload,
      })
    }

    if (!_isMounted.current) return

    // clear the undo buffer
    undoIndex.current = 0
    undoStack.current = [[]]

    // if saveAs, close the modal
    if (showSaveModal) setShowSaveModal(false)
  }

  const onLoadLayout = () => {
    setShowLoadModal(true)
  }

  const onDuplicateLayout = () => {
    setShowDuplModal(true)
  }

  const duplicateLayout = (newTemplate) => {
    if (!newTemplate) return
    setShowDuplModal(false)
    newTemplate.uid = -1
    newTemplate.name = 'Untitled'
    newTemplate.actualWell = wellDataRef.current.wellName
    loadLayout(newTemplate)
  }

  const loadLayout = (newTemplate) => {
    if (!newTemplate) return
    templateRef.current = { ...newTemplate }
    setShowLoadModal(false)
    const units = getUnits()
    elements.current = templateRef.current.elements.map((el) => {
      return {
        ...el,
        left: baseToUser(el.left, units),
        top: baseToUser(el.top, units),
        width: baseToUser(el.width, units),
        height: baseToUser(el.height, units),
      }
    })
    // load images
    elements.current = elements.current.map((el) => {
      if (el.type === elementType.imageOrgLogo) {
        return { ...el, data: { ...el.data, imageSrc: getCurrentOrgIcon() } }
      }
      if (el.type === elementType.imageOperLogo) {
        let imageSrc = getLogo(LOGO_PRIMARY)
        if (imageSrc === 'data:image/*;base64,') imageSrc = BlankImage
        return { ...el, data: { ...el.data, imageSrc: imageSrc } }
      }
      if (el.type === elementType.imageOperLogo2) {
        let imageSrc = getLogo(LOGO_SECONDARY)
        if (imageSrc === 'data:image/*;base64,') imageSrc = BlankImage
        return { ...el, data: { ...el.data, imageSrc: imageSrc } }
      }
      // create annotations from saved label definitions
      if (el.type === elementType.chart) {
        if (el.chartSettings.labels.labelData) {
          for (let j = 0; j < el.chartSettings.labels.labelData.length; j++) {
            // first, parse any settings that would have been stringified
            if (el.chartSettings.labels.labelData[j].hasOwnProperty('position')) {
              el.chartSettings.labels.labelData[j].position = JSON.parse(el.chartSettings.labels.labelData[j].position)
              el.chartSettings.labels.labelData[j].style.font.size = JSON.parse(
                el.chartSettings.labels.labelData[j].style.font.size,
              )
            }
            // next create the annotation properties
          }
          let labelData = []

          // if surveyPts exist, create the labels, as they can be interpolated and can't be directly searched in wellData
          let depthLabels = []
          if (el.chartSettings.labels.categories.find((cat) => cat.category === labelCategories.surveyPts)) {
            depthLabels = generateDepthLabels(
              labelCategories.surveyPts,
              wellDataRef.current,
              el.chartSettings.labels.categoryOptions,
            )
          }

          el.chartSettings.labels.labelData.forEach((label) => {
            // does the label exist in the current dataset?
            if (label.catType === labelCategories.surveyPts) {
              const depthLabel = depthLabels.find(
                (dl) => `${wellData.actualWellData.actualWellName}-${dl.md}` === label.uid,
              )
              if (depthLabel) {
                const posValues = getPositionValues(label.catType, label.uid, wellDataRef.current, el.chartSettings)
                labelData.push({
                  ...label,
                  type: 'label',
                  drawTime: 'afterDatasetsDraw',
                  color: getLabelColor(el.chartSettings.labels.categories, label),
                  callout: {
                    display: getHasTether(el.chartSettings.labels.categories, label),
                    borderColor: label.style.borderColor,
                  },
                  content: depthLabel.label,
                  font: {
                    size: getLabelSize(el.chartSettings.labels.categories, label),
                    weight: getLabelBold(el.chartSettings.labels.categories, label) ? 'bold' : 'normal',
                  },
                  xValue: posValues.xValue,
                  yValue: posValues.yValue,
                })
              }
              return
            }

            if (labelIdExists(label.catType, label.uid)) {
              const posValues = getPositionValues(label.catType, label.uid, wellDataRef.current, el.chartSettings)
              labelData.push({
                ...label,
                type: 'label',
                drawTime: 'afterDatasetsDraw',
                // backgroundColor: label.style.backgroundColor,
                color: getLabelColor(el.chartSettings.labels.categories, label), //label.style?.color ?  label.style.color :
                callout: {
                  display: getHasTether(el.chartSettings.labels.categories, label),
                  borderColor: label.style.borderColor,
                },
                content: getLabelContent(label, wellDataRef.current, el.chartSettings.chartType),
                font: {
                  size: getLabelSize(el.chartSettings.labels.categories, label),
                  weight: getLabelBold(el.chartSettings.labels.categories, label) ? 'bold' : 'normal',
                },
                xValue: posValues.xValue,
                yValue: posValues.yValue,
              })
            }
          })
          el.chartSettings.labels.labelData = labelData
        }
      }
      return el
    })
    setConfig(templateRef.current.config)
    setPageLayout({
      ...templateRef.current.pageLayout,
      width: baseToUser(templateRef.current.pageLayout.width, units),
      height: baseToUser(templateRef.current.pageLayout.height, units),
    })
    selectedUids.current = []
    if (elements.current?.length > 0) selectedUids.current = [elements.current[0].uid]
    setRerender(!rerender)
  }

  const commitPageLayout = (newPartialLayout) => {
    // create temporary layout with new values over old values, to have a complete object
    let newLayout = { ...pageLayout, ...newPartialLayout }

    if (newLayout.orientation !== pageLayout.orientation) {
      newLayout.width = pageLayout.height
      newLayout.height = pageLayout.width
    }

    if (newLayout.size !== pageLayout.size) {
      if (newLayout.orientation === 'portrait') {
        newLayout.width = newPartialLayout.width
        newLayout.height = newPartialLayout.height
      } else {
        newLayout.width = newPartialLayout.height
        newLayout.height = newPartialLayout.width
      }
    }

    if (newLayout.gridSpacing !== pageLayout.gridSpacing) {
      newLayout.gridSpacing = newPartialLayout.gridSpacing
    }

    // adjust the elements to honor the page layout (margin, size, orientation, snap-to-grid)
    elements.current.forEach((el) => {
      if (!el.rotate || (el.rotate && el.rotate % 360 === 0)) {
        if (el.left < newLayout.marginLeft) el.left = newLayout.marginLeft
        if (el.top < newLayout.marginTop) el.top = newLayout.marginTop

        if (el.width > newLayout.width - newLayout.marginRight - newLayout.marginLeft)
          el.width = newLayout.width - newLayout.marginRight - newLayout.marginLeft
        if (el.height > newLayout.height - newLayout.marginBottom - newLayout.marginTop)
          el.height = newLayout.height - newLayout.marginBottom - newLayout.marginTop

        // if the element bottom is below the new bottom margin, adjust the height
        const bottomOverflow = el.top + el.height - (newLayout.height - newLayout.marginBottom)
        if (bottomOverflow > 0) {
          // first, try to move the element up
          if (el.top - bottomOverflow >= newLayout.marginTop) {
            el.top = el.top - bottomOverflow
          } else {
            // if it won't fit, adjust the height
            el.height = newLayout.height - newLayout.marginBottom - el.top
          }
        }

        // if the element right is beyond the new right margin, adjust the width
        const rightOverflow = el.left + el.width - (newLayout.width - newLayout.marginRight)
        if (rightOverflow > 0) {
          // first, try to move the element left
          if (el.left - rightOverflow >= newLayout.marginLeft) {
            el.left = el.left - rightOverflow
          } else {
            // if it won't fit, adjust the width
            el.width = newLayout.width - newLayout.marginRight - el.left
          }
        }
      }
    })

    setPageLayout(newLayout)
  }

  const onKeyDown = (e) => {
    if (e.key === 'z' && e.ctrlKey) {
      onUndo()
      return
    }
    if (e.key === 'y' && e.ctrlKey) {
      onRedo()
      return
    }
    if (!Array.isArray(selectedUids.current) || selectedUids.current.length <= 0) return
    if (e.key === 'Delete') {
      deleteElements(selectedUids.current)
      return
    }
  }

  const onPrint = (e) => {
    let pageRef = viewportRef.current.getPageRef()
    if (!pageRef || !pageRef.current) {
      setStatus({ show: true, severity: 'error', message: 'Cannot Print, Viewport is not available' })
      return
    }
    printDomRef.current = pageRef.current.getPageRef().current
    if (!printDomRef.current) {
      setStatus({ show: true, severity: 'error', message: 'Cannot Print, Page is not available' })
      return
    }

    handlePrint()
  }

  const handleCloseStatus = () => {
    setStatus({ show: false, severity: 'info', message: '' })
  }

  const handleCancelSave = () => {
    saveAsRef.current = false
    setShowSaveModal(false)
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        overflow: 'hidden',
        ':focus-visible': { outline: 'none' },
      }}
      tabIndex={-1}
      onKeyDown={onKeyDown}>
      {showLoadModal && (
        <LoadWpcTemplateModal
          isLoad={true}
          onClose={() => setShowLoadModal(false)}
          onApply={loadLayout}
          wellName={wellDataRef.current.wellName}
        />
      )}
      {showDuplModal && (
        <LoadWpcTemplateModal
          isLoadoad={false}
          onClose={() => setShowDuplModal(false)}
          onApply={duplicateLayout}
          wellName={wellDataRef.current.wellName}
        />
      )}
      {showSaveModal && (
        <SaveWpcTemplateModal
          onClose={handleCancelSave}
          onApply={saveLayout}
          template={templateRef.current}
          wellName={wellDataRef.current.wellName}
        />
      )}
      {showNewLayoutModal && (
        <NewLayoutCheckModal onClose={() => setShowNewLayoutModal(false)} onApply={onNewLayoutCheck} />
      )}
      <WpToolbar
        selectedUids={selectedUids.current}
        units={getUnits()}
        actions={{
          addElement,
          onDeleteElements,
          onSaveLayout,
          onLoadLayout,
          setConfig,
          commitPageLayout,
          setZoom: (zoom) => viewportRef.current?.setZoom(zoom, pageLayout),
          setZIndex: viewportRef.current?.setZIndex,
          onPrint,
          onShowProperties,
          onUndo,
          onRedo,
          onNewLayout,
          onDuplicateLayout,
        }}
        config={config}
        pageLayout={pageLayout}
      />
      <Viewport
        ref={viewportRef}
        actions={{
          addElement,
          deleteElements,
          updateElement,
          getElement,
          moveElement,
          onShowProperties,
        }}
        elements={elements}
        data={wellData}
        units={getUnits()}
        selectedUids={selectedUids}
        config={config}
        pageLayout={pageLayout}
      />
      {status?.show ? (
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={status?.show}
          autoHideDuration={3000}
          onClose={handleCloseStatus}>
          <Alert onClose={handleCloseStatus} severity={status.severity} elevation={4} variant='filled'>
            {status.message}
          </Alert>
        </Snackbar>
      ) : null}
    </Box>
  )
}

export default WallplotComposer
