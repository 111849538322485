import useInterval from 'components/common/hooks/useInterval'
import { useState, useEffect, useRef } from 'react'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import { useRecoilValue, useRecoilState } from 'recoil'
import { currentWellAtom, refetchWellDataAtom } from 'atoms'
import useWellData from 'components/common/hooks/useWellData'


function useSoloCloud() {
  const currentWell = useRecoilValue(currentWellAtom)
  const [targetLine, setTargetLine] = useState(null)
  const [targetLineChanged, setTargetLineChanged] = useState(false)
  const targetLineRef = useRef(null)
  const [corridorChanged, setCorridorChanged] = useState(false)
  const targetLineConfigRef = useRef(null)
  const isUpdating = useRef(false)
  const [updatingSoloCloud, setUpdating] = useState(false)
  const [config, setConfig] = useState(null)
  const [configTargetLineUuid, setConfigTargetLineUuid] = useState('')
  const [refetchWellData, setRefetchWellData] = useRecoilState(refetchWellDataAtom)
  const [soloCloudErrors, setSoloCloudErrors] = useState('')
  const [soloCloudWarnings, setSoloCloudWarnings] = useState('')
  const [soloCloudSuccess, setSoloCloudSuccess] = useState('')
  const {
    wellData,
    updateWellDataPlan,
    refreshWellData,
  } = useWellData(currentWell)

  const getStarredObjects = useInnovaAxios({
    url: '/solocloud/starredObjects',
  })

  const getTargetLines = useInnovaAxios({
    url: '/solocloud/targetLines',
  })

  const getTargetLineData = useInnovaAxios({
    url: '/solocloud/targetLineData',
  })

  const createOffsetPlan = useInnovaAxios({
    url: '/plannnedWell/createOffsetPlan',
  })

  const getConfig = useInnovaAxios({
    url: '/solocloud/getConfig',
  })

  useEffect(() => {
    fecthConfig()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const fecthConfig = async () => {
    setSoloCloudErrors('')
    setSoloCloudWarnings('')
    setSoloCloudSuccess('')
    if (!currentWell) return

    let resConfig = await getConfig({ wellName: currentWell })

    if (resConfig?.error) {
      return
    }

    if (!resConfig?.data) return
    if (resConfig.data.uuid === 0) return

    setConfig(resConfig.data)

    if (resConfig?.data?.targetLineUuid !== configTargetLineUuid) {
      if (resConfig?.data?.targetLineUuid === '') {
        setConfigTargetLineUuid('')
        targetLineConfigRef.current = ''
      }
      if (resConfig?.data?.targetLineUuid !== '') {
        let restTargetLineConfig = await getTargetLineData({
          wellName: currentWell,
          targetLineUuid: resConfig?.data?.targetLineUuid,
        })

        if (restTargetLineConfig?.error) {
        }

        if (!restTargetLineConfig?.error) {
          if (Array.isArray(restTargetLineConfig.data.content) & (restTargetLineConfig.data.content.length !== 0)) {
            targetLineConfigRef.current = restTargetLineConfig.data.content[0]
            setConfigTargetLineUuid(resConfig.data.targetLineUuid)
          }
        }
      }
    }

    if (resConfig.data.hasOwnProperty('autoCheck')) {
      if (resConfig.data.autoCheck || targetLine === null) {
        getSoloCloudTargetLines()
      }
    }
  }

  const getSoloCloudTargetLines = async () => {
    if (!currentWell) return
    if (!config) return
    if (typeof config.projectUuid !== 'string') return
    if (config.projectUuid === '') return
    if (typeof config.lateralUuid !== 'string') return
    if (config.lateralUuid === '') return

    let resStarred = await getStarredObjects({ wellName: currentWell })
    if (resStarred?.error) {
      return
    }

    let targetLineUuid = resStarred?.data?.target_line

    let resTargetLines = await getTargetLines({ wellName: currentWell })
    if (resTargetLines?.error) {
      if (config.autoCheck) setSoloCloudErrors('target lines not found')
      return
    }

    if (!Array.isArray(resTargetLines?.data?.content)) return
    if (targetLine === null) {
      if (targetLineUuid === '') {
        let resTargetLineData = await getTargetLineData({
          wellName: currentWell,
          targetLineUuid: resTargetLines.data.content[0].uuid,
        })

        if (resTargetLineData?.error) {
          if (config.autoCheck) setSoloCloudErrors('target line not found')
          return
        }

        if (!Array.isArray(resTargetLineData.data.content) || resTargetLineData.data.content.length === 0) return

        setTargetLine(resTargetLineData.data.content[0])
        targetLineRef.current = resTargetLineData.data.content[0]
        return
      }
      for (let i = 0; i < resTargetLines.data.content.length; i++) {
        if (resTargetLines.data.content[i].uuid === targetLineUuid) {
          let resTargetLineData = await getTargetLineData({
            wellName: currentWell,
            targetLineUuid: resTargetLines.data.content[i].uuid,
          })

          if (resTargetLineData?.error) {
            if (config.autoCheck) setSoloCloudErrors('target line not found')
            return
          }

          if (!Array.isArray(resTargetLineData.data.content) || resTargetLineData.data.content.length === 0) return

          setTargetLine(resTargetLineData.data.content[0])
          targetLineRef.current = resTargetLineData.data.content[0]
          return
        }
      }
    }

    let newTargetLine = null
    if (targetLineUuid === '') {
      newTargetLine = resTargetLines.data.content[0]
    } else {
      for (let i = 0; i < resTargetLines.data.content.length; i++) {
        if (resTargetLines.data.content[i].uuid === targetLineUuid) {
          let resTargetLineData = await getTargetLineData({
            wellName: currentWell,
            targetLineUuid: resTargetLines.data.content[i].uuid,
          })
          if (resTargetLineData?.error) {
            if (config.autoCheck) setSoloCloudErrors('target line not found')
            return
          }
          newTargetLine = resTargetLineData.data.content[i]
          break
        }
      }
    }

    if (!isTargetLineEqual(targetLine, newTargetLine)) {
      setTargetLine(newTargetLine)
      setTargetLineChanged(true)
    }
  }

  const isTargetLineEqual = (oldTargetLine, newTargetLine) => {
    setCorridorChanged(false)
    if (!oldTargetLine || !newTargetLine) return true

    let deltaTvd = Math.abs(oldTargetLine.tvd_vs.val - newTargetLine.tvd_vs.val)
    let deltaDip = Math.abs(oldTargetLine.inclination.val - newTargetLine.inclination.val)
    let deltaCorridorTop = Math.abs(
      getTopTgtThickness(
        oldTargetLine.target_tvd.val,
        oldTargetLine.target_top_corridor_tvd.val,
        oldTargetLine.origin_tvd.val,
        oldTargetLine.origin_top_corridor_tvd.val,
      ) -
      getTopTgtThickness(
        newTargetLine.target_tvd.val,
        newTargetLine.target_top_corridor_tvd.val,
        newTargetLine.origin_tvd.val,
        newTargetLine.origin_top_corridor_tvd.val,
      ),
    )
    let deltaCorridorBottom = Math.abs(
      getBtmTgtThickness(
        oldTargetLine.target_tvd.val,
        oldTargetLine.target_base_corridor_tvd.val,
        oldTargetLine.origin_tvd.val,
        oldTargetLine.origin_base_corridor_tvd.val,
      ) -
      getBtmTgtThickness(
        newTargetLine.target_tvd.val,
        newTargetLine.target_base_corridor_tvd.val,
        newTargetLine.origin_tvd.val,
        newTargetLine.origin_base_corridor_tvd.val,
      ),
    )
    if (deltaTvd < 0.1 && deltaDip < 0.1 && deltaCorridorTop < 0.1 && deltaCorridorBottom < 0.1) return true

    if (oldTargetLine?.uuid === newTargetLine?.uuid) {
      if (deltaCorridorTop > 0.1 || deltaCorridorBottom > 0.1) {
        setCorridorChanged(true)
        return false
      }
      return true
    }

    return false
  }

  const getTopTgtThickness = (targetTvd, targetTopCorridorTvd, originTvd, originTopCorridorTvd) => {
    let thickness = 0
    if (Math.abs(targetTopCorridorTvd > 0)) {
      thickness = Math.abs(targetTvd - targetTopCorridorTvd)
    }
    if (Math.abs(originTopCorridorTvd > 0)) {
      thickness = Math.abs(originTvd - originTopCorridorTvd)
    }
    if (thickness < 0) thickness = 0
    return thickness
  }

  const getBtmTgtThickness = (targetTvd, targetBaseCorridorTvd, originTvd, originBaseCorridorTvd) => {
    let thickness = 0
    if (Math.abs(targetBaseCorridorTvd > 0)) {
      thickness = Math.abs(targetTvd - targetBaseCorridorTvd)
    }
    if (Math.abs(originBaseCorridorTvd > 0)) {
      thickness = Math.abs(originTvd - originBaseCorridorTvd)
    }
    if (thickness < 0) thickness = 0
    return thickness
  }

  const handleAcceptTargetLine = (accepted, newTargetLine) => {
    if (accepted) {
      setTargetLine(newTargetLine)
      targetLineRef.current = newTargetLine
      handleCreateOffsetPlan()
    }
    setTargetLineChanged(false)
  }

  const handleCreateOffsetPlan = async () => {
    if (!targetLineRef.current) return
    if (!currentWell) return
    if (!wellData?.wellPlan?.offsetWellbore) return
    isUpdating.current = true
    setUpdating(true)

    let params = {
      wellName: currentWell,
      getSoloCloudConfig: true,
      targetLine: targetLineRef.current,
      isCorridorOnlyChange: corridorChanged,
    }

    let res = await createOffsetPlan({ params: JSON.stringify(params), planName: wellData?.wellPlan?.offsetWellbore })

    isUpdating.current = false
    setUpdating(false)

    if (res?.error) {
      if (config.autoCheck) setSoloCloudErrors(res?.error?.response?.data?.error ? res?.error?.response?.data?.error : '')
      return
    }

    updateWellDataPlan(res.data?.name)
    refreshWellData()

    setSoloCloudWarnings(res?.data?.warnings ? res?.data?.warnings : '')
    setSoloCloudSuccess('Offset plan created successfully')

    setRefetchWellData(!refetchWellData)

  }

  const getNewTargetLine = () => {
    if (!targetLine) return

    let up = Math.abs(parseFloat(targetLine.origin_tvd.val - targetLine.origin_top_corridor_tvd.val))
    let down = Math.abs(parseFloat(targetLine.origin_tvd.val - targetLine.origin_base_corridor_tvd.val))

    return { ...targetLine, tvd_down: down, tvd_up: up }
  }

  const getConfigTargetLine = () => {
    if (!targetLineConfigRef.current) return

    return targetLineConfigRef.current
  }

  useInterval(() => {
    if (!isUpdating.current && !targetLineChanged) {
      fecthConfig()
    }
  }, 30000)

  //clear warnings, errors and success messages after 5 seconds
  useInterval(() => {
    if (!isUpdating.current && !targetLineChanged) {
      setSoloCloudErrors('')
      setSoloCloudWarnings('')
      setSoloCloudSuccess('')
    }
  }, 5000)

  const getSoloCloudWarnings = () => {
    return soloCloudWarnings
  }

  const getSoloCloudErrors = () => {
    return soloCloudErrors
  }

  const getSoloCloudSuccess = () => {
    return soloCloudSuccess
  }

  return {
    handleAcceptTargetLine,
    targetLineChanged,
    getNewTargetLine,
    getConfigTargetLine,
    updatingSoloCloud,
    getSoloCloudWarnings,
    getSoloCloudErrors,
    getSoloCloudSuccess,
  }
}

export default useSoloCloud
