import React, { useEffect, useRef, useState, useMemo, useCallback } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import * as XLSX from '@sheet/core'
import { AgGridReact } from 'ag-grid-react'
import { array2pipestr } from 'utils'
import { analyticsSelectedWells, currentPageAtom, analyticsFilterDefsAtom } from 'atoms'
import { PAGE_KEYS } from 'components/ActionBar/pageDefs'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import { Box } from '@mui/material'
import { GetActivityColor, getStatusAvatar } from 'components/common/activitySelector'
import Avatar from '@mui/material/Avatar'
import { unescapeHtml } from '../../../utils/htmlSymbolHandling'
import useUnits, { UNITS_FOR } from 'components/common/hooks/useUnits'
import { round } from 'utils/numberFunctions'
import { styled } from '@mui/styles'
import MenuButton from 'components/common/MenuButton'
import { Icon as Iconify } from '@iconify/react'
import { numberWithCommas } from 'utils/stringFunctions'
import { saveItemToLS } from 'utils/localStorage'
import { appColors } from 'utils'
import {
  sortColDefs,
  CustomLoadingOverlay,
  dateComparator,
  isDateLessThan,
  getStringId,
} from 'components/common/AgGridUtils'
import useAxiosGzip from 'components/common/hooks/useAxiosGzip'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const StyledMenuIcon = styled(Box)(({ theme }) => ({
  backgroundColor: 'transparent',
  margin: '4px',
  padding: '12px',
  position: 'absolute',
  bottom: theme.spacing(2),
  right: theme.spacing(2),
  zIndex: 2,
}))

const MotorKpiGrid = () => {
  const gridApi = useRef(null)
  const _isMounted = useRef(false)
  const [groupRows, setGroupRows] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const selectedWells = useRecoilValue(analyticsSelectedWells)
  const setActivePage = useSetRecoilState(currentPageAtom)
  const recoilFilterDefs = useRecoilValue(analyticsFilterDefsAtom)
  const { getUnitsText } = useUnits()
  const [resetCols, setResetCols] = useState(false)
  const { getAgGridTheme } = useInnovaTheme()

  const getKpiData = useAxiosGzip({
    url: '/kpi/motorKpi',
    timeout: 600000,
  })

  const getPhaseKpiData = useInnovaAxios({
    url: '/kpi/phaseKpi',
    timeout: 600000,
  })

  const getSelectedValues = (param) => {
    if (!param) return ''
    if (!Array.isArray(recoilFilterDefs)) return ''
    for (let i = 0; i < recoilFilterDefs.length; i++) {
      if (recoilFilterDefs[i].text !== param) continue
      if (!recoilFilterDefs[i].isActive) return ''
      if (Array.isArray(recoilFilterDefs[i].value)) {
        if (recoilFilterDefs[i].value.length === 0) return ''
        if (recoilFilterDefs[i].value.length === 1) return recoilFilterDefs[i].value[0]
        return recoilFilterDefs[i].value.join('|')
      }

      return recoilFilterDefs[i].value
    }
  }

  useEffect(() => {
    _isMounted.current = true
    setActivePage(PAGE_KEYS.motorKpiKey)
    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetchData()
  }, [selectedWells, recoilFilterDefs]) // eslint-disable-line react-hooks/exhaustive-deps

  const dateTimeFormatter = (value) => {
    if (!value) return ''
    if (typeof value !== 'string') return ''
    if (value === '') return ''

    if(value.length >= 16) {
      value = value.slice(0, 16)
    }

    if (isDateLessThan(value, '1990-01-01')) return ''
    value = value.replace(/Z/g, '')

    return new Date(Date.parse(value)).toLocaleDateString('default', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    })
  }

  const fetchData = async () => {
    if (isLoading) return
    if (!_isMounted.current) return
    if (!Array.isArray(selectedWells)) {
      setData([])
      return
    }

    if (selectedWells.length < 1) {
      setData([])
      return
    }

    const payload = {
      wellList: array2pipestr(selectedWells),
      holeSize: getSelectedValues('Hole Size'),
      isLih: getSelectedValues('LIH') === 'Yes' ? true : '',
      phase: getSelectedValues('Phase'),
      failure: getSelectedValues('Failure'),
      motorSn: getSelectedValues('Motor SN'),
      motorMake: getSelectedValues('Motor Make'),
      bitMake: getSelectedValues('Bit Make'),
      motorLobes: getSelectedValues('Motor Lobes'),
      motorStages: getSelectedValues('Motor Stages'),
      serialNum: getSelectedValues('Serial #'),
      getTvds: getSelectedValues('Get TVD') === 'Yes' ? true : false,
      rentalJobs: getSelectedValues('Rental jobs'),
      assemblyType: getSelectedValues('Assembly Type'),
    }

    setLoading(true)
    const dataResponse = await getKpiData(payload)
    if (_isMounted.current) {
      setLoading(false)
      setData(dataResponse?.data ? dataResponse.data : [])
    }
  }

  const saveColumnState = () => {
    if (gridApi.current) {
      const colLayout = gridApi.current.getColumnState()
      if (colLayout) saveItemToLS('motorKpiGrid', 'colLayout', colLayout)
    }
  }

  const tooltipRenderer = (params) => {
    const { yValue, xValue } = params
    return `<div class='grid-sparkline-tooltip'>
                <div class='grid-sparkline-tooltip-title'>
                    <div>${xValue}: ${yValue.toFixed(2)}</div>
                </div>
            </div>`
  }

  const getHighestValue = (first, second) => {
    let largestAmount = 0
    if (data?.length <= 0) return 1
    data.forEach((item) => {
      if (item[first] > largestAmount) {
        largestAmount = item[first]
      }
      if (item[second] > largestAmount) {
        largestAmount = item[second]
      }
    })

    return largestAmount
  }

  const columnFormatter = (params) => {
    const { first } = params

    return {
      fill: first ? appColors.slideColor : appColors.rotateColor,
    }
  }

  const threeValColumnFormatter = (params) => {
    const { first, last } = params

    return {
      fill: first ? appColors.slideColor : last ? appColors.avgColor : appColors.rotateColor,
    }
  }

  const onXlsxExport = () => {
    let motorWS = XLSX.utils.json_to_sheet(data)
    let wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, motorWS, 'Motor Kpi')
    XLSX.writeFile(wb, 'motorKpi.xlsx')
  }

  const defaultColDef = {
    resizable: true,
    sortable: true,
    autoHeight: false,
    editable: false,
  }

  const centerAlignCell = () => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  })

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.uid)
    }
  }, [])

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const onFirstDataRendered = (params) => {
    expandAllNodes('expand')
    autoSizeColumns()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const expandAllNodes = (action) => {
    if (typeof action !== 'string') return
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    if (action === 'expand') gridApi.current.expandAll()
    if (action !== 'expand') gridApi.current.collapseAll()
  }

  const gridOptions = {
    sideBar: {
      toolPanels: [
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        },
      ],
      defaultToolPanel: '',
      position: 'left',
    },
    onDragStopped: (event) => {
      saveColumnState()
    },
    onColumnVisible: (event) => {
      saveColumnState()
    },
    loadingOverlayComponent: CustomLoadingOverlay,
  }

  const dateFilterComparator = useCallback(dateComparator, [])

  const columnDefs = useMemo(
    () => [
      {
        headerName: '',
        colId: 'multiplePhases',
        pinned: 'left',
        lockPosition: 'left',
        sortable: false,
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
        cellRenderer: 'agGroupCellRenderer',
        width: 50,
        cellStyle: {
          justifyContent: 'center',
        },
      },
      {
        headerName: '',
        width: 100,
        colId: 'avatar',
        pinned: 'left',
        cellRenderer: (params) => {
          return (
            <Box
              sx={{
                margin: 'auto',
                width: 35,
                height: 35,
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: GetActivityColor(params?.data?.wellStatus),
                borderRadius: '50%',
              }}>
              {params?.data.hasOwnProperty('wellStatus') ? (
                <Avatar
                  alt={params?.data?.wellStatus}
                  src={getStatusAvatar(params?.data?.wellStatus)}
                  style={{ width: 35, height: 35 }}
                />
              ) : null}
            </Box>
          )
        },
      },
      {
        headerName: 'Operator',
        field: 'operator',
        colId: 'operator',
        rowGroup: groupRows,
        hide: groupRows,
        minWidth: 200,
        maxWidth: 400,
        filter: 'agSetColumnFilter',
        filterParams: {
          excelMode: 'windows',
        },
        valueGetter: (params) => {
          return unescapeHtml(params.data?.operator)
        },
      },
      {
        headerName: 'Parent Well',
        field: 'parentWell',
        colId: 'parentWell',
        minWidth: 200,
        maxWidth: 400,
        filter: 'agSetColumnFilter',
        filterParams: {
          excelMode: 'windows',
        },
      },
      {
        headerName: 'Well',
        field: 'well',
        colId: 'well',
        rowGroup: groupRows,
        hide: groupRows,
        minWidth: 300,
        maxWidth: 400,
        filter: 'agSetColumnFilter',
        filterParams: {
          excelMode: 'windows',
        },
      },
      {
        headerName: 'Job #',
        field: 'jobNum',
        colId: 'jobNum',
        cellStyle: centerAlignCell,
        filter: 'agSetColumnFilter',
        filterParams: {
          excelMode: 'windows',
        },
      },
      {
        headerName: 'Rig',
        field: 'rig',
        colId: 'rig',
        cellStyle: centerAlignCell,
        filter: 'agSetColumnFilter',
        filterParams: {
          excelMode: 'windows',
        },
        valueGetter: (params) => {
          return unescapeHtml(params.data?.rig)
        },
      },
      {
        headerName: 'State',
        field: 'state',
        colId: 'state',
        cellStyle: centerAlignCell,
        filter: 'agSetColumnFilter',
        filterParams: {
          excelMode: 'windows',
        },
      },
      {
        headerName: 'County',
        field: 'county',
        colId: 'county',
        cellStyle: centerAlignCell,
        filter: 'agSetColumnFilter',
        filterParams: {
          excelMode: 'windows',
        },
      },
      {
        headerName: 'Status',
        field: 'status',
        colId: 'status',
        filter: 'agSetColumnFilter',
        filterParams: {
          excelMode: 'windows',
        },
        cellRenderer: (params) => {
          return (
            <span
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: GetActivityColor(params.data?.wellStatus),
              }}>
              <i className='fa fa-check' aria-hidden='true'></i>
              {params.data?.wellStatus}
            </span>
          )
        },
      },
      {
        headerName: `Latitude`,
        field: 'lat',
        colId: 'latituide',
        cellStyle: centerAlignCell,
        valueGetter: (params) => {
          return round(params.data?.lat, 6)
        },
      },
      {
        headerName: `Longitude`,
        field: 'long',
        colId: 'longitude',
        cellStyle: centerAlignCell,
        valueGetter: (params) => {
          return round(params.data?.long, 6)
        },
      },
      {
        headerName: `BHA#`,
        field: 'bhaNumRep',
        colId: 'bhaNumRep',
        cellStyle: centerAlignCell,
        valueGetter: (params) => {
          if (typeof params.data?.bhaNumRep !== 'number') return params.data?.bha
          if (params.data?.bhaNumRep < 0) return params.data?.bha
          return params.data?.bhaNumRep
        },
      },
      {
        headerName: 'BHA Description',
        field: 'bhaDesc',
        colId: 'bhaDesc',
        filter: 'agSetColumnFilter',
        minWidth: 200,
        filterParams: {
          excelMode: 'windows',
        },
        valueGetter: (params) => {
          if (typeof params.data?.bhaDesc !== 'string') return ''
          if (params.data?.bhaDesc.trim() === '') return ''
          if (params.data?.bhaDesc.trim() === '-1') return ''
          return unescapeHtml(params.data?.bhaDesc.trim())
        },
      },
      {
        headerName: 'Date In',
        field: 'dateTimeIn',
        colId: 'dateTimeIn',
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: dateFilterComparator,
        },
        minWidth: 120,
      },
      {
        headerName: 'Date Out',
        field: 'dateTimeOut',
        colId: 'dateTimeOut',
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: dateFilterComparator,
        },
        minWidth: 120,
        valueFormatter: (params) => {
          return dateTimeFormatter(params.data?.dateTimeOut)
        },
      },
      {
        headerName: 'BHA 6O (PU)',
        field: 'bhaPickUpTimeStamp',
        colId: 'bhaPickUp',
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: dateFilterComparator,
        },
        minWidth: 120,
        valueFormatter: (params) => {
          return dateTimeFormatter(params.data?.bhaPickUpTimeStamp)
        },
      },
      {
        headerName: 'BHA 6P (LD)',
        field: 'bhaLayDownTimeStamp',
        colId: 'bhaLayDown',
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: dateFilterComparator,
        },
        minWidth: 120,
        valueFormatter: (params) => {
          return dateTimeFormatter(params.data?.bhaLayDownTimeStamp)
        },
      },
      {
        headerName: `Depth In (${getUnitsText(UNITS_FOR.Depth)})`,
        field: 'depthIn',
        colId: 'depthIn',
        cellStyle: centerAlignCell,
        valueFormatter: (params) => {
          return numberWithCommas(round(params.data?.depthIn, 2))
        },
      },
      {
        headerName: `Depth Out (${getUnitsText(UNITS_FOR.Depth)})`,
        field: 'depthOut',
        colId: 'depthOut',
        cellStyle: centerAlignCell,
        valueGetter: (params) => {
          return numberWithCommas(round(params.data?.depthOut, 2))
        },
      },
      {
        headerName: `TVD In (${getUnitsText(UNITS_FOR.Depth)})`,
        field: 'tvdStart',
        colId: 'tvdStart',
        cellStyle: centerAlignCell,
        valueFormatter: (params) => {
          return numberWithCommas(round(params.data?.tvdStart, 2))
        },
      },
      {
        headerName: `TVD Out (${getUnitsText(UNITS_FOR.Depth)})`,
        field: 'tvdEnd',
        colId: 'tvdEnd',
        cellStyle: centerAlignCell,
        valueFormatter: (params) => {
          return numberWithCommas(round(params.data?.tvdEnd, 2))
        },
      },
      {
        headerName: `Total Drilled (${getUnitsText(UNITS_FOR.Depth)})`,
        field: 'totalDrilled',
        colId: 'totalDrilled',
        cellStyle: centerAlignCell,
        valueFormatter: (params) => {
          return numberWithCommas(round(params.data?.totalDrilled, 2))
        },
      },
      {
        headerName: `Footage (${getUnitsText(UNITS_FOR.Depth)})`,
        colId: 'footage',
        autoHeight: true,
        sortable: false,
        width: 140,
        filterParams: {
          excelMode: 'windows',
        },
        cellRenderer: 'agSparklineCellRenderer',
        cellRendererParams: {
          sparklineOptions: {
            type: 'bar',
            paddingOuter: 0.25,
            tooltip: {
              xOffset: 20,
              yOffset: -20,
              renderer: tooltipRenderer,
            },
            formatter: columnFormatter,
            label: {
              enabled: true,
              placement: 'outsideEnd',
              color: 'white',
              fontSize: 9,
            },
            stroke: '#91cc75',
            highlightStyle: {
              fill: '#fac858',
            },
            valueAxisDomain: [0, getHighestValue('slideDrilled', 'rotateDrilled')],

            padding: {
              top: 0,
              bottom: 0,
              right: 30,
            },
            axis: {
              strokeWidth: 0,
            },
          },
        },
        valueGetter: (params) => {
          return [
            ['Slide', params.data?.slideDrilled],
            ['Rotate', params.data?.rotateDrilled],
          ]
        },
      },
      {
        headerName: `BRT (Hrs)`,
        field: 'brtHrs',
        colId: 'brtHrs',
        cellStyle: centerAlignCell,
        valueFormatter: (params) => {
          return numberWithCommas(round(params.data?.brtHrs, 2))
        },
      },
      {
        headerName: `Drilling (Hrs)`,
        field: 'drillingHrs',
        colId: 'drillingHrs',
        cellStyle: centerAlignCell,
        valueFormatter: (params) => {
          return numberWithCommas(round(params.data?.drillingHrs, 2))
        },
      },
      {
        headerName: `Total D&C (Hrs)`,
        field: 'totalDrillingCircHrs',
        colId: 'totallDandC',
        cellStyle: centerAlignCell,
        valueFormatter: (params) => {
          return numberWithCommas(round(params.data?.totalDrillingCircHrs, 2))
        },
      },
      {
        headerName: `Drilling Hrs`,
        colId: 'drilgHrs',
        autoHeight: true,
        sortable: false,
        width: 140,
        filterParams: {
          excelMode: 'windows',
        },
        cellRenderer: 'agSparklineCellRenderer',
        cellRendererParams: {
          sparklineOptions: {
            type: 'bar',
            paddingOuter: 0.25,
            tooltip: {
              xOffset: 20,
              yOffset: -20,
              renderer: tooltipRenderer,
            },
            formatter: columnFormatter,
            label: {
              enabled: true,
              placement: 'outsideEnd',
              color: 'white',
              fontSize: 9,
            },
            stroke: '#91cc75',
            highlightStyle: {
              fill: '#fac858',
            },
            valueAxisDomain: [0, getHighestValue('slideHrs', 'rotateHrs')],

            padding: {
              top: 0,
              bottom: 0,
              right: 30,
            },
            axis: {
              strokeWidth: 0,
            },
          },
        },
        valueGetter: (params) => {
          return [
            ['Slide', params.data?.slideHrs],
            ['Rotate', params.data?.rotateHrs],
          ]
        },
      },
      {
        headerName: `Off Btm Circ (Hrs)`,
        field: 'circHrs',
        colId: 'circHrs',
        cellStyle: centerAlignCell,
        valueFormatter: (params) => {
          return numberWithCommas(round(params.data?.circHrs, 2))
        },
      },
      {
        headerName: `ROP (${getUnitsText(UNITS_FOR.Depth)}/hr)`,
        colId: 'rop',
        autoHeight: true,
        sortable: false,
        width: 140,
        filterParams: {
          excelMode: 'windows',
        },
        cellRenderer: 'agSparklineCellRenderer',
        cellRendererParams: {
          sparklineOptions: {
            paddingOuter: 0.5,
            type: 'bar',
            tooltip: {
              xOffset: 20,
              yOffset: -20,
              renderer: tooltipRenderer,
            },
            formatter: threeValColumnFormatter,
            label: {
              enabled: true,
              placement: 'outsideEnd',
              color: 'white',
              fontSize: 9,
            },
            stroke: '#91cc75',
            highlightStyle: {
              fill: '#fac858',
            },
            valueAxisDomain: [0, getHighestValue('slideRop', 'rotateRop')],
            padding: {
              top: 0,
              bottom: 0,
              right: 30,
            },
            axis: {
              strokeWidth: 0,
            },
          },
        },
        valueGetter: (params) => {
          return [
            ['Slide ROP', params.data?.slideRop],
            ['Rotate ROP', params.data?.rotateRop],
            ['Avg ROP', params.data?.avgRop],
          ]
        },
      },
      {
        headerName: 'Motor Desc',
        field: 'motorDescription',
        colId: 'motorDesc',
        cellStyle: centerAlignCell,
        minWidth: 200,
        filter: 'agSetColumnFilter',
        filterParams: {
          excelMode: 'windows',
        },
        valueGetter: (params) => {
          if (typeof params.data?.motorDescription !== 'string') return ''
          if (params.data?.motorDescription.trim() === '') return ''
          if (params.data?.motorDescription.trim() === '-1') return ''
          return unescapeHtml(params.data?.motorDescription.trim())
        },
      },
      {
        headerName: 'Motor SN',
        field: 'motorSn',
        colId: 'motorSn',
        cellStyle: centerAlignCell,
        filter: 'agSetColumnFilter',
        filterParams: {
          excelMode: 'windows',
        },
      },
      {
        headerName: `Motor OD (${getUnitsText(UNITS_FOR.Diameter)})`,
        field: 'motorOd',
        colId: 'motorOd',
        cellStyle: centerAlignCell,
        filter: 'agSetColumnFilter',
        filterParams: {
          excelMode: 'windows',
        },
        valueFormatter: (params) => {
          return params.data?.motorOd > 0 ? round(params.data?.motorOd, 3) : ''
        },
      },
      {
        headerName: 'Motor Bend',
        field: 'bendAngle',
        colId: 'bendAngle',
        cellStyle: centerAlignCell,
        filter: 'agSetColumnFilter',
        filterParams: {
          excelMode: 'windows',
        },
        valueFormatter: (params) => {
          return params.data?.bendAngle > 0 ? round(params.data?.bendAngle, 3) : ''
        },
      },
      {
        headerName: 'Bit 2 Bend',
        field: 'bit2bend',
        colId: 'bit2bend',
        cellStyle: centerAlignCell,
        filter: 'agSetColumnFilter',
        filterParams: {
          excelMode: 'windows',
        },
        valueFormatter: (params) => {
          return params.data?.bit2bend > 0 ? round(params.data?.bit2bend, 2) : ''
        },
      },
      {
        headerName: `Kickpad OD (${getUnitsText(UNITS_FOR.Diameter)})`,
        field: 'motorKickpadOd',
        colId: 'kickPadOd',
        cellStyle: centerAlignCell,
        filter: 'agSetColumnFilter',
        filterParams: {
          excelMode: 'windows',
        },
        valueFormatter: (params) => {
          return params.data?.motorKickpadOd > 0 ? round(params.data?.motorKickpadOd, 3) : ''
        },
      },
      {
        headerName: `Sleeve OD (${getUnitsText(UNITS_FOR.Diameter)})`,
        field: 'motorSleeveOd',
        colId: 'sleeveOd',
        cellStyle: centerAlignCell,
        filter: 'agSetColumnFilter',
        filterParams: {
          excelMode: 'windows',
        },
        valueFormatter: (params) => {
          return params.data?.motorSleeveOd > 0 ? round(params.data?.motorSleeveOd, 3) : ''
        },
      },
      {
        headerName: 'Rev Per Gal',
        field: 'revPerGal',
        colId: 'revPerGal',
        cellStyle: centerAlignCell,
        filter: 'agSetColumnFilter',
        filterParams: {
          excelMode: 'windows',
        },
        valueFormatter: (params) => {
          return params.data?.revPerGal > 0 ? round(params.data?.revPerGal, 4) : ''
        },
      },
      {
        headerName: 'Motor Make',
        field: 'motorMake',
        colId: 'motorMake',
        cellStyle: centerAlignCell,
        filter: 'agSetColumnFilter',
        filterParams: {
          excelMode: 'windows',
        },
      },
      {
        headerName: 'Motor Model',
        field: 'motorModel',
        colId: 'motorModel',
        cellStyle: centerAlignCell,
        filter: 'agSetColumnFilter',
        filterParams: {
          excelMode: 'windows',
        },
      },
      {
        headerName: 'Motor Config',
        colId: 'motorConfig',
        cellStyle: centerAlignCell,
        filter: 'agSetColumnFilter',
        filterParams: {
          excelMode: 'windows',
        },
        valueGetter: (params) => {
          return `${params.data?.motorLobes} ${params.data?.motorStages}`
        },
      },
      {
        headerName: 'Bearing Type',
        field: 'motorBearingType',
        colId: 'motorBearingType',
        cellStyle: centerAlignCell,
        filter: 'agSetColumnFilter',
        filterParams: {
          excelMode: 'windows',
        },
      },
      {
        headerName: 'Stator Vendor',
        field: 'statorVendor',
        colId: 'statorVendor',
        cellStyle: centerAlignCell,
        filter: 'agSetColumnFilter',
        filterParams: {
          excelMode: 'windows',
        },
      },
      {
        headerName: 'Stator Type',
        field: 'statorType',
        colId: 'statorType',
        cellStyle: centerAlignCell,
        filter: 'agSetColumnFilter',
        filterParams: {
          excelMode: 'windows',
        },
      },
      {
        headerName: 'Stator Fit',
        field: 'statorFit',
        colId: 'statorFit',
        cellStyle: centerAlignCell,
        filter: 'agSetColumnFilter',
        filterParams: {
          excelMode: 'windows',
        },
      },
      {
        headerName: 'Motor Yield',
        field: 'motorYield',
        colId: 'motorYield',
        cellStyle: centerAlignCell,
      },
      {
        headerName: 'Motor Rerun',
        field: 'reRun',
        colId: 'reRun',
        cellStyle: centerAlignCell,
        filter: 'agSetColumnFilter',
        filterParams: {
          excelMode: 'windows',
        },
      },
      {
        headerName: 'Motor Direct Bill',
        field: 'directBill',
        colId: 'directBill',
        cellStyle: centerAlignCell,
        filter: 'agSetColumnFilter',
        filterParams: {
          excelMode: 'windows',
        },
      },
      {
        headerName: 'Plan DLS',
        field: 'plannedDls',
        colId: 'plannedDls',
        cellStyle: centerAlignCell,
        valueFormatter: (params) => {
          return params.data?.plannedDls > 0 ? round(params.data?.plannedDls, 2) : ''
        },
      },
      {
        headerName: 'Actual DLS',
        field: 'actDls',
        colId: 'actDls',
        cellStyle: centerAlignCell,
        valueFormatter: (params) => {
          return params.data?.actDls > 0 ? round(params.data?.actDls, 2) : ''
        },
      },
      {
        headerName: 'Max DLS',
        field: 'maxDls',
        colId: 'maxDls',
        cellStyle: centerAlignCell,
        valueFormatter: (params) => {
          return params.data?.maxDls > 0 ? round(params.data?.maxDls, 2) : ''
        },
      },
      {
        headerName: 'Bit Make',
        field: 'bitMake',
        colId: 'bitMake',
        cellStyle: centerAlignCell,
        filter: 'agSetColumnFilter',
        filterParams: {
          excelMode: 'windows',
        },
      },
      {
        headerName: 'Bit Model',
        field: 'bitModel',
        colId: 'bitModel',
        cellStyle: centerAlignCell,
        filter: 'agSetColumnFilter',
        filterParams: {
          excelMode: 'windows',
        },
      },
      {
        headerName: `Bit OD (${getUnitsText(UNITS_FOR.Diameter)})`,
        field: 'bitOd',
        colId: 'bitOd',
        cellStyle: centerAlignCell,
        filter: 'agSetColumnFilter',
        filterParams: {
          excelMode: 'windows',
        },
        valueGetter: (params) => {
          return round(params.data?.bitOd, 3)
        },
      },
      {
        headerName: 'Bit #',
        field: 'bitNum',
        colId: 'bitNum',
        cellStyle: centerAlignCell,
        filter: 'agSetColumnFilter',
        filterParams: {
          excelMode: 'windows',
        },
      },
      {
        headerName: 'TFA',
        field: 'tfa',
        colId: 'tfa',
        cellStyle: centerAlignCell,
        filter: 'agSetColumnFilter',
        filterParams: {
          excelMode: 'windows',
        },
      },
      {
        headerName: 'Grade In',
        field: 'gradeIn',
        colId: 'gradeIn',
        cellStyle: centerAlignCell,
        filter: 'agSetColumnFilter',
        filterParams: {
          excelMode: 'windows',
        },
      },
      {
        headerName: 'Grade Out',
        field: 'gradeOut',
        colId: 'gradeOut',
        cellStyle: centerAlignCell,
        filter: 'agSetColumnFilter',
        filterParams: {
          excelMode: 'windows',
        },
      },
      {
        headerName: 'RSS Make',
        field: 'rssMake',
        colId: 'rssMake',
        valueGetter: (params) => {
          return params.data?.rssMake || ''
        },
      },
      {
        headerName: 'RSS Model',
        field: 'rssModel',
        colId: 'rssModel',
        valueGetter: (params) => {
          return params.data?.rssModel || ''
        },
      },
      {
        headerName: 'RSS SN',
        field: 'rssSn',
        colId: 'rssSn',
        valueGetter: (params) => {
          return params.data?.rssSn || ''
        },
      },
      {
        headerName: 'RSS Desc.',
        field: 'rssDescription',
        colId: 'rssDesc',
        maxWidth: 400,
        valueGetter: (params) => {
          return params.data?.rssDescription || ''
        },
      },
      {
        headerName: 'DD Coordinator',
        field: 'ddCoordinator',
        colId: 'ddCoord',
        cellStyle: centerAlignCell,
        filter: 'agSetColumnFilter',
        filterParams: {
          excelMode: 'windows',
        },
      },
      {
        headerName: 'MWD Coordinator',
        field: 'mwdCoordinator',
        colId: 'mwdCoordinator',
        cellStyle: centerAlignCell,
        filter: 'agSetColumnFilter',
        filterParams: {
          excelMode: 'windows',
        },
      },
      {
        headerName: 'Lead DD',
        field: 'leadDd',
        colId: 'leadDd',
        cellStyle: centerAlignCell,
        filter: 'agSetColumnFilter',
        filterParams: {
          excelMode: 'windows',
        },
      },
      {
        headerName: 'Company Man',
        field: 'coMan',
        colId: 'coMan',
        cellStyle: centerAlignCell,
        filter: 'agSetColumnFilter',
        filterParams: {
          excelMode: 'windows',
        },
      },
      {
        headerName: 'Formation',
        field: 'formation',
        colId: 'formation',
        cellStyle: centerAlignCell,
        filter: 'agSetColumnFilter',
        filterParams: {
          excelMode: 'windows',
        },
      },
      {
        headerName: 'Phases',
        field: 'phases',
        colId: 'phases',
        cellStyle: centerAlignCell,
        filter: 'agSetColumnFilter',
        filterParams: {
          excelMode: 'windows',
        },
      },
      {
        headerName: 'Comments',
        field: 'comments',
        colId: 'comments',
        maxWidth: 400,
        valueGetter: (params) => {
          if (params.data?.comments === '0') return ''
          return params.data?.comments || ''
        },
      },
      {
        headerName: 'Additional Comments',
        field: 'additionalComments',
        colId: 'additionalComments',
        maxWidth: 400,
      },
      {
        headerName: 'Reason POOH',
        field: 'reasonPooh',
        colId: 'reasonPooh',
        maxWidth: 400,
      },
      {
        headerName: 'Surface Findings',
        field: 'surfaceFindings',
        colId: 'surfaceFindings',
        maxWidth: 400,
        valueGetter: (params) => {
          return params.data?.surfaceFindings || ''
        },
      },
      {
        headerName: 'Troubleshooting',
        field: 'troubleshooting',
        colId: 'troubleshooting',
        maxWidth: 400,
        valueGetter: (params) => {
          return params.data?.troubleshooting || ''
        },
      },
      {
        headerName: 'Evaluation',
        field: 'evaluation',
        colId: 'evaluation',
        maxWidth: 400,
        valueGetter: (params) => {
          return params.data?.evaluation || ''
        },
      },
      {
        headerName: 'Shop Findings',
        field: 'shopFindings',
        colId: 'shopFindings',
        maxWidth: 400,
        valueGetter: (params) => {
          return params.data?.shopFindings || ''
        },
      },
      {
        headerName: 'LIH',
        field: 'lih',
        colId: 'lih',
        filter: 'agSetColumnFilter',
        cellStyle: centerAlignCell,
        filterParams: {
          excelMode: 'windows',
        },
        cellRenderer: 'agCheckboxCellRenderer',
      },
      {
        headerName: 'Trip For Failure',
        field: 'tripForFailure',
        colId: 'tripForFailure',
        filter: 'agSetColumnFilter',
        cellStyle: centerAlignCell,
        filterParams: {
          excelMode: 'windows',
        },
        cellRenderer: 'agCheckboxCellRenderer',
      },
      {
        headerName: 'Motor Failure',
        field: 'motorFailure',
        colId: 'motorFail',
        filter: 'agSetColumnFilter',
        cellStyle: centerAlignCell,
        filterParams: {
          excelMode: 'windows',
        },
        cellRenderer: 'agCheckboxCellRenderer',
      },
      {
        headerName: 'MWD Failure',
        field: 'mwdFailure',
        colId: 'mwdFailure',
        filter: 'agSetColumnFilter',
        cellStyle: centerAlignCell,
        filterParams: {
          excelMode: 'windows',
        },
        cellRenderer: 'agCheckboxCellRenderer',
      },
      {
        headerName: 'RSS Failure',
        field: 'rssFailure',
        colId: 'rssFailure',
        filter: 'agSetColumnFilter',
        cellStyle: centerAlignCell,
        filterParams: {
          excelMode: 'windows',
        },
        cellRenderer: 'agCheckboxCellRenderer',
      },
      {
        headerName: 'Catas. Failure',
        field: 'catastrophicFailure',
        colId: 'catastrophicFailure',
        filter: 'agSetColumnFilter',
        cellStyle: centerAlignCell,
        filterParams: {
          excelMode: 'windows',
        },
        cellRenderer: 'agCheckboxCellRenderer',
      },
      {
        headerName: 'Unplanned Trip',
        field: 'unplannedTrip',
        colId: 'unplannedTrip',
        filter: 'agSetColumnFilter',
        cellStyle: centerAlignCell,
        filterParams: {
          excelMode: 'windows',
        },
        cellRenderer: 'agCheckboxCellRenderer',
      },
      {
        headerName: 'MWD Present',
        field: 'isMwdPresent',
        colId: 'isMwdPresent',
        filter: 'agSetColumnFilter',
        cellStyle: centerAlignCell,
        filterParams: {
          excelMode: 'windows',
        },
        cellRenderer: 'agCheckboxCellRenderer',
      },
      {
        headerName: 'Motor Present',
        field: 'isMotorPresent',
        colId: 'isMotorPresent',
        filter: 'agSetColumnFilter',
        cellStyle: centerAlignCell,
        filterParams: {
          excelMode: 'windows',
        },
        cellRenderer: 'agCheckboxCellRenderer',
      },
      {
        headerName: 'RSS Present',
        field: 'isRssPresent',
        colId: 'isRssPresent',
        filter: 'agSetColumnFilter',
        cellStyle: centerAlignCell,
        filterParams: {
          excelMode: 'windows',
        },
        cellRenderer: 'agCheckboxCellRenderer',
      },
      {
        headerName: 'In Hole',
        field: 'inHole',
        colId: 'inHole',
        filter: 'agSetColumnFilter',
        cellStyle: centerAlignCell,
        filterParams: {
          excelMode: 'windows',
        },
        cellRenderer: 'agCheckboxCellRenderer',
      },
      {
        headerName: 'Has Daily Activity',
        field: 'hasDailyActivities',
        colId: 'hasDailyActivities',
        filter: 'agSetColumnFilter',
        cellStyle: centerAlignCell,
        filterParams: {
          excelMode: 'windows',
        },
        cellRenderer: 'agCheckboxCellRenderer',
      },
      {
        headerName: 'Mud Type',
        colId: 'mudType',
        field: 'fluidType',
        cellStyle: centerAlignCell,
        filter: 'agSetColumnFilter',
        filterParams: {
          excelMode: 'windows',
        },
      },
      {
        headerName: `Mud Weight (${getUnitsText(UNITS_FOR.Mud)})`,
        colId: 'medWeight',
        field: 'mudWeight',
        cellStyle: centerAlignCell,
      },
      {
        headerName: 'Solids (%)',
        field: 'solids',
        colId: 'solids',
        cellStyle: centerAlignCell,
      },
      {
        headerName: 'Chlorides',
        field: 'chlorides',
        colId: 'chlorides',
        cellStyle: centerAlignCell,
      },
      {
        headerName: 'Oil/Water',
        field: 'oilWaterRatio',
        colId: 'oilWaterRatio',
        cellStyle: centerAlignCell,
      },
      {
        headerName: 'PV',
        field: 'pv',
        colId: 'pv',
        cellStyle: centerAlignCell,
      },
      {
        headerName: 'YP',
        field: 'yp',
        colId: 'yp',
        cellStyle: centerAlignCell,
      },
      {
        headerName: `Mud Temp (${getUnitsText(UNITS_FOR.Temperature)})`,
        field: 'fluidTemp',
        colId: 'mudTemp',
        cellStyle: centerAlignCell,
      },
      {
        headerName: 'NPT Hrs',
        field: 'npt',
        colId: 'nptHrs',
        valueGetter: (params) => {
          return params.data?.npt || ''
        },
        cellRenderer: (params) => {
          return (
            <span
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: params.data?.npt > 0 ? '#ff0000' : '#66FF00',
              }}>
              <i className='fa fa-check' aria-hidden='true'></i>
              {params.data?.npt || ''}
            </span>
          )
        },
      },
      {
        headerName: 'Hrs Fail',
        field: 'hrsToFail',
        colId: 'hrsToFail',
        valueGetter: (params) => {
          return params.data?.hrsToFail || ''
        },
        cellRenderer: (params) => {
          return (
            <span
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: params.data?.hrsToFail > 0 ? '#ff0000' : '#66FF00',
              }}>
              <i className='fa fa-check' aria-hidden='true'></i>
              {params.data?.hrsToFail || ''}
            </span>
          )
        },
      },
      {
        headerName: '#Stalls',
        field: 'numStalls',
        colId: 'numStalls',
        valueGetter: (params) => {
          return params.data?.numStalls || ''
        },
        cellRenderer: (params) => {
          return (
            <span
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: params.data?.numStalls > 0 ? '#ff0000' : '#66FF00',
              }}>
              <i className='fa fa-check' aria-hidden='true'></i>
              {params.data?.numStalls || ''}
            </span>
          )
        },
      },
      {
        headerName: 'Wash Ream (Hrs)',
        field: 'washReamTime',
        colId: 'washReamTime',
        cellStyle: centerAlignCell,
      },
      {
        headerName: 'Dry Ream (Hrs)',
        field: 'dryReamTime',
        colId: 'dryReamTime',
        cellStyle: centerAlignCell,
      },
      {
        headerName: `Min Temp (${getUnitsText(UNITS_FOR.Temperature)})`,
        field: 'minTemp',
        colId: 'minTemp',
        cellStyle: centerAlignCell,
        valueGetter: (params) => {
          return round(params.data?.minTemp, 2)
        },
      },
      {
        headerName: `Avg Temp (${getUnitsText(UNITS_FOR.Temperature)})`,
        field: 'avgTemp',
        colId: 'avgTemp',
        cellStyle: centerAlignCell,
        valueGetter: (params) => {
          return round(params.data?.avgTemp, 2)
        },
      },
      {
        headerName: `Max Temp (${getUnitsText(UNITS_FOR.Temperature)})`,
        field: 'maxTemp',
        colId: 'maxTemp',
        cellStyle: centerAlignCell,
        valueGetter: (params) => {
          return round(params.data?.maxTemp, 2)
        },
      },
      {
        headerName: `Off Btm Press (${getUnitsText(UNITS_FOR.Pressure)})`,
        field: 'offBtmPress',
        colId: 'offBtmPRess',
        cellStyle: centerAlignCell,
        valueFormatter: (params) => {
          return numberWithCommas(round(params.data?.offBtmPress, 2))
        },
      },
      {
        headerName: `On Btm Press (${getUnitsText(UNITS_FOR.Pressure)})`,
        field: 'onBtmPress',
        colId: 'onBtmPress',
        cellStyle: centerAlignCell,
        valueFormatter: (params) => {
          return numberWithCommas(round(params.data?.onBtmPress, 2))
        },
      },
      {
        headerName: `Diff Press (${getUnitsText(UNITS_FOR.Pressure)})`,
        field: 'diffPress',
        colId: 'diffPress',
        cellStyle: centerAlignCell,
        valueFormatter: (params) => {
          return numberWithCommas(round(params.data?.diffPress, 2))
        },
      },
      {
        headerName: `Max Diff Press (${getUnitsText(UNITS_FOR.Pressure)})`,
        field: 'maxDiffP',
        colId: 'maxDiffP',
        cellStyle: centerAlignCell,
        valueFormatter: (params) => {
          return numberWithCommas(round(params.data?.maxDiffP, 2))
        },
      },
      {
        headerName: `Max Flow (${getUnitsText(UNITS_FOR.FlowRate)})`,
        field: 'maxFlowRate',
        colId: 'maxFlow',
        cellStyle: centerAlignCell,
        valueFormatter: (params) => {
          return numberWithCommas(round(params.data?.maxFlowRate, 2))
        },
      },
      {
        headerName: `Flow (${getUnitsText(UNITS_FOR.FlowRate)})`,
        field: 'flowRate',
        colId: 'flowRate',
        cellStyle: centerAlignCell,
        valueFormatter: (params) => {
          return numberWithCommas(round(params.data?.flowRate, 2))
        },
      },
      {
        headerName: `Off Btm Tq (${getUnitsText(UNITS_FOR.Torque)})`,
        field: 'offBtmTq',
        colId: 'offBtmTq',
        cellStyle: centerAlignCell,
        valueFormatter: (params) => {
          return numberWithCommas(round(params.data?.offBtmTq, 2))
        },
      },
      {
        headerName: `On Btm Tq (${getUnitsText(UNITS_FOR.Torque)})`,
        field: 'onBtmTq',
        colId: 'onBtmTq',
        cellStyle: centerAlignCell,
        valueFormatter: (params) => {
          return numberWithCommas(round(params.data?.onBtmTq, 2))
        },
      },
      {
        headerName: `Max Tq (${getUnitsText(UNITS_FOR.Torque)})`,
        field: 'maxTorque',
        colId: 'maxTq',
        cellStyle: centerAlignCell,
        valueFormatter: (params) => {
          return numberWithCommas(round(params.data?.maxTorque, 2))
        },
      },
      {
        headerName: `WOB (${getUnitsText(UNITS_FOR.Weight)})`,
        field: 'wob',
        colId: 'wob',
        cellStyle: centerAlignCell,
        valueFormatter: (params) => {
          return numberWithCommas(round(params.data?.wob, 2))
        },
      },
      {
        headerName: `PU (${getUnitsText(UNITS_FOR.Weight)})`,
        field: 'pu',
        colId: 'pu',
        cellStyle: centerAlignCell,
        valueFormatter: (params) => {
          return numberWithCommas(round(params.data?.pu, 2))
        },
      },
      {
        headerName: `ROT (${getUnitsText(UNITS_FOR.Weight)})`,
        field: 'rot',
        colId: 'rot',
        cellStyle: centerAlignCell,
        valueFormatter: (params) => {
          return numberWithCommas(round(params.data?.rot, 2))
        },
      },
      {
        headerName: `SO (${getUnitsText(UNITS_FOR.Weight)})`,
        field: 'so',
        colId: 'so',
        cellStyle: centerAlignCell,
        valueFormatter: (params) => {
          return numberWithCommas(round(params.data?.so, 2))
        },
      },
      {
        headerName: 'RPM',
        field: 'rpm',
        colId: 'rpm',
        cellStyle: centerAlignCell,
        valueFormatter: (params) => {
          return numberWithCommas(round(params.data?.rpm, 2))
        },
      },
      {
        headerName: 'MWD Run#',
        field: 'mwdNum',
        colId: 'mwdRunNum',
        cellStyle: centerAlignCell,
        valueGetter: (params) => {
          return params.data?.mwdNum || ''
        },
      },
      {
        headerName: 'MWD Make',
        field: 'mwdMake',
        colId: 'mwdMake',
        cellStyle: centerAlignCell,
        valueGetter: (params) => {
          return params.data?.mwdMake || ''
        },
      },
      {
        headerName: 'MWD Model',
        field: 'mwdModel',
        colId: 'mwdModel',
        cellStyle: centerAlignCell,
        valueGetter: (params) => {
          return params.data?.mwdModel || ''
        },
      },
      {
        headerName: 'MWD Type',
        field: 'mwdType',
        colId: 'mwdType',
        cellStyle: centerAlignCell,
        valueGetter: (params) => {
          return params.data?.mwdType || ''
        },
      },
      {
        headerName: 'Pulser Type',
        field: 'pulserType',
        colId: 'pulserType',
        cellStyle: centerAlignCell,
        valueGetter: (params) => {
          return params.data?.pulserType || ''
        },
      },
      {
        headerName: 'Pulser SN',
        field: 'pulserSn',
        colId: 'pulserSn',
        cellStyle: centerAlignCell,
        valueGetter: (params) => {
          return params.data?.pulserSn || ''
        },
      },
      {
        headerName: 'Shock Tool SN',
        field: 'shockToolSn',
        colId: 'shockToolSn',
        cellStyle: centerAlignCell,
        valueGetter: (params) => {
          return params.data?.shockToolSn || ''
        },
      },
      {
        headerName: 'Pulser Driver SN',
        field: 'pulserDriverSn',
        colId: 'pulserDriverSn',
        cellStyle: centerAlignCell,
        valueGetter: (params) => {
          return params.data?.pulserDriverSn || ''
        },
      },
      {
        headerName: 'Battery 1 SN',
        field: 'battery1Sn',
        colId: 'batter1sn',
        cellStyle: centerAlignCell,
        valueGetter: (params) => {
          return params.data?.battery1Sn || ''
        },
      },
      {
        headerName: 'Battery 2 SN',
        field: 'battery2Sn',
        colId: 'battery2sn',
        cellStyle: centerAlignCell,
        valueGetter: (params) => {
          return params.data?.battery2Sn || ''
        },
      },
      {
        headerName: 'Battery 3 SN',
        field: 'battery3Sn',
        colId: 'battery3sn',
        cellStyle: centerAlignCell,
        valueGetter: (params) => {
          return params.data?.battery3Sn || ''
        },
      },
      {
        headerName: 'Gamma SN',
        field: 'gammaSn',
        colId: 'gammaSn',
        cellStyle: centerAlignCell,
        valueGetter: (params) => {
          return params.data?.gammaSn || ''
        },
      },
      {
        headerName: 'Directional SN',
        field: 'directionalSn',
        colId: 'directionalSn',
        cellStyle: centerAlignCell,
        valueGetter: (params) => {
          return params.data?.directionalSn || ''
        },
      },
      {
        headerName: 'EM SN',
        field: 'emSn',
        colId: 'emSn',
        cellStyle: centerAlignCell,
        valueGetter: (params) => {
          return params.data?.emSn || ''
        },
      },
      {
        headerName: 'MWD Plugin',
        field: 'mwdPlugIn',
        colId: 'mwdPlugIn',
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: dateFilterComparator,
        },
        minWidth: 120,
        valueFormatter: (params) => {
          return dateTimeFormatter(params.data?.mwdPlugIn)
        },
      },
      {
        headerName: 'MWD Plugout',
        field: 'mwdPlugOut',
        colId: 'mwdPlugOut',
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: dateFilterComparator,
        },
        minWidth: 120,
        valueFormatter: (params) => {
          return dateTimeFormatter(params.data?.mwdPlugOut)
        },
      },
    ],
    [groupRows, getUnitsText], // eslint-disable-line react-hooks/exhaustive-deps
  )

  const getContextMenuItems = (params) => {
    return [
      {
        name: 'Reset columns',
        disabled: false,
        action: () => {
          gridApi.current.resetColumnState()
          saveItemToLS('motorKpiGrid', 'colLayout', null)
          setResetCols(!resetCols)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Reset filters',
        disabled: false,
        action: () => {
          gridApi.current.setFilterModel(null)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      'copy',
      {
        name: 'Export',
        disabled: false,
        action: () => {
          gridApi.current.exportDataAsExcel({
            fileName: 'MotorKpi.xlsx',
            sheetName: 'MotorKpi',
          })
        },
        icon: '<span class="iconify" data-icon="icomoon-free:file-excel" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
    ]
  }

  const getStatsByPhase = async (wellName, bhaNum, phases, successCallBack) => {
    const payload = {
      wellList: wellName,
      bhaNum: bhaNum,
      phase: phases.join('|'),
    }

    const response = await getPhaseKpiData(payload)
    successCallBack(response?.error ? [] : response.data)
  }

  const detailCellRendererParams = useMemo(
    () => {
      return {
        detailGridOptions: {
          columnDefs: [
            {
              headerName: 'Phase',
              field: 'phase',
              colId: 'phase',
              cellStyle: centerAlignCell,
              filter: 'agSetColumnFilter',
              filterParams: {
                excelMode: 'windows',
              },
            },
            {
              headerName: 'Start Date',
              field: 'startDateTime',
              colId: 'startDateTime',
              minWidth: 120,
              filter: 'agDateColumnFilter',
              filterParams: {
                comparator: dateFilterComparator,
              },
              valueFormatter: (params) => {
                return dateTimeFormatter(params.data?.startDateTime)
              },
            },
            {
              headerName: 'End Date',
              field: 'endDateTime',
              colId: 'endDateTime',
              minWidth: 120,
              filter: 'agDateColumnFilter',
              filterParams: {
                comparator: dateFilterComparator,
              },
              valueFormatter: (params) => {
                return dateTimeFormatter(params.data?.endDateTime)
              },
            },
            {
              headerName: `Start Depth (${getUnitsText(UNITS_FOR.Depth)})`,
              field: 'startDepth',
              colId: 'startDepth',
              cellStyle: centerAlignCell,
              valueFormatter: (params) => {
                return numberWithCommas(round(params.data?.startDepth, 2))
              },
            },
            {
              headerName: `End Depth (${getUnitsText(UNITS_FOR.Depth)})`,
              field: 'endDepth',
              colId: 'endDepth',
              cellStyle: centerAlignCell,
              valueFormatter: (params) => {
                return numberWithCommas(round(params.data?.endDepth, 2))
              },
            },
            {
              headerName: `Avg Drilled (${getUnitsText(UNITS_FOR.Depth)}/Day)`,
              field: 'avgDrilledPerDay',
              colId: 'avgDrilledPerDay',
              cellStyle: centerAlignCell,
              valueFormatter: (params) => {
                return numberWithCommas(round(params.data?.avgDrilledPerDay, 2))
              },
            },
            {
              headerName: `Total Drilled (${getUnitsText(UNITS_FOR.Depth)})`,
              field: 'totalDrilled',
              colId: 'totalDrilled',
              cellStyle: centerAlignCell,
              valueFormatter: (params) => {
                return numberWithCommas(round(params.data?.totalDrilled, 2))
              },
            },
            {
              headerName: `Footage (${getUnitsText(UNITS_FOR.Depth)})`,
              colId: 'footage',
              autoHeight: true,
              sortable: false,
              width: 140,
              filterParams: {
                excelMode: 'windows',
              },
              cellRenderer: 'agSparklineCellRenderer',
              cellRendererParams: {
                sparklineOptions: {
                  type: 'bar',
                  paddingOuter: 0.25,
                  tooltip: {
                    xOffset: 20,
                    yOffset: -20,
                    renderer: tooltipRenderer,
                  },
                  formatter: columnFormatter,
                  label: {
                    enabled: true,
                    placement: 'outsideEnd',
                    color: 'white',
                    fontSize: 9,
                  },
                  stroke: '#91cc75',
                  highlightStyle: {
                    fill: '#fac858',
                  },
                  valueAxisDomain: [0, getHighestValue('slideDrilled', 'rotDrilled')],

                  padding: {
                    top: 0,
                    bottom: 0,
                    right: 30,
                  },
                  axis: {
                    strokeWidth: 0,
                  },
                },
              },
              valueGetter: (params) => {
                return [
                  ['Slide', params.data?.slideDrilled],
                  ['Rotate', params.data?.rotDrilled],
                ]
              },
            },
            {
              headerName: `Drilling (Hrs)`,
              field: 'drillingHrs',
              colId: 'drillingHrs',
              cellStyle: centerAlignCell,
              valueFormatter: (params) => {
                return numberWithCommas(round(params.data?.drillingHrs, 2))
              },
            },
            {
              headerName: `Total D&C (Hrs)`,
              field: 'drillingAndCircHrs',
              colId: 'drillingAndCircHrs',
              cellStyle: centerAlignCell,
              valueFormatter: (params) => {
                return numberWithCommas(round(params.data?.drillingAndCircHrs, 2))
              },
            },
            {
              headerName: `Drilling Hrs`,
              colId: 'footage',
              autoHeight: true,
              sortable: false,
              width: 140,
              filterParams: {
                excelMode: 'windows',
              },
              cellRenderer: 'agSparklineCellRenderer',
              cellRendererParams: {
                sparklineOptions: {
                  type: 'bar',
                  paddingOuter: 0.25,
                  tooltip: {
                    xOffset: 20,
                    yOffset: -20,
                    renderer: tooltipRenderer,
                  },
                  formatter: columnFormatter,
                  label: {
                    enabled: true,
                    placement: 'outsideEnd',
                    color: 'white',
                    fontSize: 9,
                  },
                  stroke: '#91cc75',
                  highlightStyle: {
                    fill: '#fac858',
                  },
                  valueAxisDomain: [0, getHighestValue('slideHrs', 'rotHrs')],
                  padding: {
                    top: 0,
                    bottom: 0,
                    right: 30,
                  },
                  axis: {
                    strokeWidth: 0,
                  },
                },
              },
              valueGetter: (params) => {
                return [
                  ['Slide', params.data?.slideHrs],
                  ['Rotate', params.data?.rotHrs],
                ]
              },
            },
            {
              headerName: `ROP (${getUnitsText(UNITS_FOR.Depth)}/hr)`,
              colId: 'rop',
              autoHeight: true,
              sortable: false,
              width: 140,
              filterParams: {
                excelMode: 'windows',
              },
              cellRenderer: 'agSparklineCellRenderer',
              cellRendererParams: {
                sparklineOptions: {
                  paddingOuter: 0.5,
                  type: 'bar',
                  tooltip: {
                    xOffset: 20,
                    yOffset: -20,
                    renderer: tooltipRenderer,
                  },
                  formatter: threeValColumnFormatter,
                  label: {
                    enabled: true,
                    placement: 'outsideEnd',
                    color: 'white',
                    fontSize: 9,
                  },
                  stroke: '#91cc75',
                  highlightStyle: {
                    fill: '#fac858',
                  },
                  valueAxisDomain: [0, getHighestValue('slideRop', 'rotRop')],
                  padding: {
                    top: 0,
                    bottom: 0,
                    right: 30,
                  },
                  axis: {
                    strokeWidth: 0,
                  },
                },
              },
              valueGetter: (params) => {
                return [
                  ['Slide ROP', params.data?.slideRop],
                  ['Rotate ROP', params.data?.rotRop],
                  ['Avg ROP', params.data?.avgRop],
                ]
              },
            },
            {
              headerName: `Slide/Rot %`,
              colId: 'footage',
              autoHeight: true,
              sortable: false,
              width: 140,
              filterParams: {
                excelMode: 'windows',
              },
              cellRenderer: 'agSparklineCellRenderer',
              cellRendererParams: {
                sparklineOptions: {
                  type: 'bar',
                  paddingOuter: 0.25,
                  tooltip: {
                    xOffset: 20,
                    yOffset: -20,
                    renderer: tooltipRenderer,
                  },
                  formatter: columnFormatter,
                  label: {
                    enabled: true,
                    placement: 'outsideEnd',
                    color: 'white',
                    fontSize: 9,
                  },
                  stroke: '#91cc75',
                  highlightStyle: {
                    fill: '#fac858',
                  },
                  valueAxisDomain: [0, getHighestValue('slidePerc', 'rotPerc')],

                  padding: {
                    top: 0,
                    bottom: 0,
                    right: 30,
                  },
                  axis: {
                    strokeWidth: 0,
                  },
                },
              },
              valueGetter: (params) => {
                return [
                  ['Slide', params.data?.slidePerc],
                  ['Rotate', params.data?.rotPerc],
                ]
              },
            },
            {
              headerName: `Max Temp (${getUnitsText(UNITS_FOR.Temperature)})`,
              field: 'maxBht',
              colId: 'maxBht',
              cellStyle: centerAlignCell,
              valueFormatter: (params) => {
                return numberWithCommas(round(params.data?.maxBht, 2))
              },
            },
          ],
          defaultColDef: {
            suppressHeaderMenuButton: true,
            suppressHeaderFilterButton: true,
            sortable: false,
            resizable: true,
            editable: false,
          },
        },
        getDetailRowData: (params) => {
          getStatsByPhase(params?.data?.well, params?.data?.bha, params?.data?.multiplePhases, params.successCallback)
        },
      }
    },
    [getUnitsText], // eslint-disable-line react-hooks/exhaustive-deps
  )

  const isRowMaster = useMemo(() => {
    return (data) => {
      if (!Array.isArray(data?.multiplePhases)) return false
      return data ? data?.multiplePhases?.length > 1 : false
    }
  }, [])

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', overflow: 'hidden', width: '100%', height: '100%' }}>
      <Box sx={{ width: '100%', height: '100%' }}>
        <div className={getAgGridTheme()} style={{ width: '100%', height: '100%' }}>
          <AgGridReact
            rowData={data}
            loading={isLoading}
            columnDefs={sortColDefs(columnDefs, 'motorKpiGrid')}
            defaultColDef={defaultColDef}
            getRowId={getRowId}
            animateRows={true}
            rowSelection='multiple'
            enableRangeSelection='true'
            enableBrowserTooltips={true}
            gridOptions={gridOptions}
            onGridReady={onGridReady}
            onFirstDataRendered={onFirstDataRendered}
            groupDisplayType={'groupRows'}
            groupRowsSticky={true}
            getContextMenuItems={getContextMenuItems}
            masterDetail={true}
            detailCellRendererParams={detailCellRendererParams}
            isRowMaster={isRowMaster}
            headerHeight={30}
          />
        </div>
      </Box>
      <StyledMenuIcon>
        <MenuButton
          actions={[
            {
              icon: <Iconify icon='file-icons:microsoft-excel' color={'green'} style={{ width: 28, height: 28 }} />,
              name: 'Export to XLSX',
              onClick: onXlsxExport,
            },
            {
              icon: <Iconify icon='mdi:arrow-vertical-collapse' style={{ width: 28, height: 28 }} />,
              name: 'Collapse All',
              onClick: () => {
                expandAllNodes('collapse')
              },
            },
            {
              icon: <Iconify icon='material-symbols:expand' style={{ width: 28, height: 28 }} />,
              name: 'Expand All',
              onClick: () => {
                expandAllNodes('expand')
              },
            },
            {
              icon: <Iconify icon='mdi:format-list-group' style={{ width: 28, height: 28 }} />,
              name: groupRows ? 'Ungroup Rows' : 'Group Rows',
              onClick: () => {
                setGroupRows(!groupRows)
              },
            },
          ]}
        />
      </StyledMenuIcon>
    </Box>
  )
}

export default MotorKpiGrid
