import { round } from 'utils/numberFunctions'
import { Scatter } from 'react-chartjs-2'
import { appColors, chartSeriesColors } from 'utils'
import { createPhaseLegend } from 'components/common/depthVtimeFunctions'
import Box from '@mui/material/Box'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const getOrCreateLegendList = (chart, id) => {
  const legendContainer = document.getElementById(id)
  let listContainer = legendContainer.querySelector('ul')

  if (!listContainer) {
    listContainer = document.createElement('ul')
    listContainer.style.display = 'flex'
    listContainer.style.flexDirection = 'row'
    listContainer.style.margin = 0
    listContainer.style.padding = 0

    legendContainer.appendChild(listContainer)
  }

  return listContainer
}

const WellDepthTimeChart = ({
  title,
  wellData,
  rawData,
  yAxisTitle,
  xAxisTitle,
  slideRotData,
  showSlideRotData = false,
  phaseFilter = '',
  style,
  witsData = [],
  showHoleDepth = false,
  showBitDepth = false,
}) => {
  const { getBackColor, theme } = useInnovaTheme()
  const BAR_WIDTH = 0.25
  const segColor = (ctx, defaultColor) => {
    return ctx.p0.raw.color ? ctx.p0.raw.color : defaultColor
  }

  const legendPlugIn = {
    id: 'htmlLegend',
    afterUpdate: (chart, args, options) => {
      const ul = getOrCreateLegendList(chart, options.containerID)

      // Remove old legend items
      while (ul.firstChild) {
        ul.firstChild.remove()
      }

      options.legendItems.forEach((item) => {
        const li = document.createElement('li')
        li.style.alignItems = 'center'
        li.style.display = 'flex'
        li.style.flexDirection = 'row'
        li.style.marginLeft = '10px'
        li.style.justifyContent = 'center'

        // Color box
        const boxSpan = document.createElement('span')
        boxSpan.style.background = item.color
        boxSpan.style.borderColor = item.color
        boxSpan.style.borderWidth = '1px'
        boxSpan.style.display = 'inline-block'
        boxSpan.style.height = '20px'
        boxSpan.style.marginRight = '10px'
        boxSpan.style.width = '20px'

        // Text
        const textContainer = document.createElement('p')
        textContainer.style.color = appColors.headerTextColor
        textContainer.style.margin = 0
        textContainer.style.padding = 0
        textContainer.style.textDecoration = item.hidden ? 'line-through' : ''

        const text = document.createTextNode(item.label)
        textContainer.appendChild(text)

        li.appendChild(boxSpan)
        li.appendChild(textContainer)
        ul.appendChild(li)
      })
    },
  }

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    animation: false,
    plugins: {
      GradientPlugIn: {
        showGradient: true,
        theme: theme,
      },
      annotation: {},
      legend: {
        position: 'bottom',
        display: false,
      },
      title: {
        display: title?.length > 0,
        text: title,
        color: appColors.itemTextColor,
        font: {
          size: 20,
        },
      },
      tooltip: {
        mode: 'nearest',
        intersect: false,
        callbacks: {
          title: function (tooltipItem) {
            if (!Array.isArray(tooltipItem)) return ''

            return tooltipItem[0].dataset.label
          },
          label: function (context) {
            if (!Array.isArray(rawData) || rawData.length === 0) return ''
            if (context.dataset.isWits) {
              return [
                ` ${+context.parsed.x.toFixed(2)} ${xAxisTitle}`,
                ` ${context.parsed.y} ${yAxisTitle}`,
                ` BHA# ${
                  witsData[context.dataIndex].bha >= 0
                    ? witsData[context.dataIndex].bhaNumRep + ': ' + witsData[context.dataIndex].bhaDesc
                    : 'None'
                }`,
                ` Phase: ${witsData[context.dataIndex].phase}`,
                ` Activity: ${witsData[context.dataIndex].activity}`,
              ]
            }

            if (rawData[0].depthVDays[context.dataIndex]) {
              if (context.raw.isBarData) {
                return [
                  ` ${+context.parsed.x.toFixed(2)} ${xAxisTitle}`,
                  ` ${round(context.parsed.y, 2)} ${yAxisTitle}`,
                  ` Activity: ${context.raw.activity}`,
                ]
              }
              return [
                ` ${+context.parsed.x.toFixed(2)} ${xAxisTitle}`,
                ` ${context.parsed.y} ${yAxisTitle}`,
                ` BHA# ${
                  rawData[0].depthVDays[context.dataIndex].bha >= 0
                    ? rawData[0].depthVDays[context.dataIndex].bhaNumRep +
                      ': ' +
                      rawData[0].depthVDays[context.dataIndex].bhaDesc
                    : 'None'
                }`,
                ` Phase: ${rawData[0].depthVDays[context.dataIndex].phase}`,
                ` Activity: ${rawData[0].depthVDays[context.dataIndex].activity}`,
              ]
            } else {
              return [` ${+context.parsed.x.toFixed(2)} ${xAxisTitle}`, ` ${context.parsed.y} ${yAxisTitle}`]
            }
          },
        },
        // filter the tooltip list to only show one point when they are very close together
        filter: function (tooltipItem, index) {
          if (index > 0) return false
          return true
        },
      },
      hover: {
        mode: 'nearest',
        intersect: false,
      },
      zoom: {
        pan: {
          enabled: true,
          mode: 'xy',
        },
        limits: {
          x: {
            min: 'original',
            max: 'original',
          },
          y: {
            min: 'original',
            max: 'original',
          },
        },
        zoom: {
          wheel: {
            enabled: true,
          },
          pinch: {
            enabled: true,
          },
          mode: 'xy',
        },
      },
      htmlLegend: {
        containerID: 'chart-legend',
        legendItems: createPhaseLegend(Array.isArray(rawData) && rawData?.length > 0 ? rawData[0].depthVDays : []),
      },
    },
    scales: {
      x: {
        stacked: true,
        beginAtZero: true,
        title: {
          display: true,
          text: xAxisTitle,
          color: 'white',
        },
        grid: {
          color: '#404040',
        },
        ticks: {
          color: 'white',
        },
      },
      y: {
        beginAtZero: true,
        reverse: true,
        title: {
          display: true,
          text: yAxisTitle,
          color: 'white',
        },
        grid: {
          color: '#404040',
        },
        ticks: {
          color: 'white',
        },
      },
    },
    interaction: {
      intersect: false,
      mode: 'index',
    },
  }

  const getDvTLineData = () => {
    let chartData = wellData.map((series, index) => {
      return {
        type: 'line',
        label: series.name,
        data: series.dataVals,
        borderColor: chartSeriesColors[index],
        backgroundColor: chartSeriesColors[index],
        borderWidth: 3,
        lineTension: 0.8,
        pointRadius: 0,
        pointHoverRadius: 0,
        segment: {
          borderColor: (ctx) => segColor(ctx, chartSeriesColors[index]),
          borderWidth: 3,
        },
      }
    })

    if (!showSlideRotData) return chartData
    if (!slideRotData) return chartData
    if (!Array.isArray(slideRotData)) return chartData

    let filteredData = []
    for (let i = 0; i < slideRotData.length; i++) {
      if (phaseFilter === '') filteredData.push({ ...slideRotData[i] })
      if (phaseFilter !== '' && slideRotData[i].phase === phaseFilter) filteredData.push({ ...slideRotData[i] })
    }

    filteredData.sort((firstEl, secondEl) => {
      if (firstEl.date === secondEl.date) {
        return parseInt(firstEl.sequenceNo) > parseInt(secondEl.sequenceNo)
      }

      return new Date(Date.parse(firstEl.date)).getTime() > new Date(Date.parse(secondEl.date)).getTime() ? 1 : -1
    })

    let dayTotals = []
    filteredData.forEach((activity) => {
      let cl = activity.endDepth - activity.startDepth
      if (cl < 0) cl = 0
      let isSlide = activity.activity === '2J' ? true : false
      let index = dayTotals.findIndex((day) => day.recordId === activity.recordId)
      if (index < 0) {
        let startDateMs = new Date(Date.parse(filteredData[0].date + 'T00:00:01')).getTime()
        let newDateMs = new Date(Date.parse(activity.date + 'T00:00:01')).getTime()
        const timeDifferenceMs = Math.abs(newDateMs - startDateMs)
        const differenceInDays = Math.ceil(timeDifferenceMs / (1000 * 60 * 60 * 24))

        dayTotals.push({
          recordId: activity.recordId,
          day: differenceInDays,
          date: activity.date,
          totalSlide: isSlide ? cl : 0,
          totalRotate: isSlide ? 0 : cl,
        })
      }
      if (index >= 0) {
        dayTotals[index].totalSlide += isSlide ? cl : 0
        dayTotals[index].totalRotate += isSlide ? 0 : cl
      }
    })

    for (let i = 0; i < dayTotals.length; i++) {
      chartData.push({
        type: 'bar',
        barPercentage: BAR_WIDTH,
        order: 1,
        label: `Day:${dayTotals[i].day} slide`,
        yAxisID: 'barData',
        backgroundColor: 'rgb(245, 155, 0, 0.6)',
        data: [{ activity: '2J', isBarData: true, x: dayTotals[i].day, y: dayTotals[i].totalSlide }],
      })

      chartData.push({
        type: 'bar',
        barPercentage: BAR_WIDTH,
        order: 2,
        label: `Day:${dayTotals[i].day} rotate`,
        yAxisID: 'barData',
        backgroundColor: 'rgb(89, 255, 0, 0.6)',
        data: [{ activity: '2K', isBarData: true, x: dayTotals[i].day, y: dayTotals[i].totalRotate }],
      })
    }

    if (showBitDepth || showHoleDepth) {
      let bitDepths = []
      let holeDepths = []
      for (let j = 0; j < witsData.length; j++) {
        bitDepths.push({
          x: witsData[j].days,
          y: witsData[j].bitDepth,
          color: witsData[j].color,
        })
        holeDepths.push({
          x: witsData[j].days,
          y: witsData[j].holeDepth,
          color: witsData[j].color,
        })
      }
      if (showBitDepth) {
        chartData.push({
          type: 'scatter',
          label: 'Bit Depth',
          data: bitDepths,
          borderColor: [...bitDepths.map((datum) => datum.color)],
          backgroundColor: [...bitDepths.map((datum) => datum.color)],
          borderWidth: 1,
          pointRadius: 3,
          pointHoverRadius: 4,
          pointStyle: 'rect',
          isWits: true,
        })
      }
      if (showHoleDepth) {
        chartData.push({
          type: 'scatter',
          label: 'Hole Depth',
          data: holeDepths,
          borderColor: [...holeDepths.map((datum) => datum.color)],
          backgroundColor: [...holeDepths.map((datum) => datum.color)],
          borderWidth: 1,
          pointRadius: 3,
          pointHoverRadius: 4,
          isWits: true,
        })
      }
    }
    return chartData
  }

  let annotations = {}
  if (Array.isArray(rawData) && rawData?.length > 0) {
    for (let i = 0; i < rawData[0].depthVDays.length; i++) {
      if (!annotations[rawData[0].depthVDays[i].bhaNumRep] && rawData[0].depthVDays[i].bha >= 0) {
        annotations[rawData[0].depthVDays[i].bhaNumRep] = {
          type: 'line',
          mode: 'vertical',
          xMin: rawData[0].depthVDays[i].days,
          xMax: rawData[0].depthVDays[i].days,
          borderWidth: 2,
          borderColor: appColors.chartNeonBlue,
          label: {
            content: `BHA ${rawData[0].depthVDays[i].bhaNumRep}`,
            enabled: true,
            position: 'start',
            textAlign: 'start',
            color: appColors.chartNeonBlue,
            backgroundColor: 'transparent',
            xAdjust: 15,
            rotation: -90,
            font: {
              size: 14,
            },
          },
        }
      }
    }
  }

  options.plugins.annotation.annotations = annotations
  if (slideRotData) {
    options.scales.barData = {
      stacked: true,
      beginAtZero: true,
      reverse: false,
      position: 'right',
      title: {
        display: true,
        text: yAxisTitle.replace('Depth', 'Drilled'),
        color: appColors.headerTextColor,
      },
      grid: {
        display: false,
      },
    }
  }

  return (
    <Box
      sx={{
        backgroundColor: getBackColor(),
        padding: '5px',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        overflow: 'hidden',
        ...(style || null),
      }}>
      <Box sx={{ display: 'flex', width: '100%', height: 'calc(100% - 42px)', paddingBottom: '5px' }}>
        <Scatter options={options} data={{ datasets: getDvTLineData() }} plugins={[legendPlugIn]} />
      </Box>
      <Box
        id='chart-legend'
        sx={{
          display: 'flex',
          width: '100%',
          flexDirection: 'row',
          justifyContent: 'center',
          height: '32px',
          marginBottom: '10px',
        }}
      />
    </Box>
  )
}

export default WellDepthTimeChart
