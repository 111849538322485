// eslint-disable-next-line no-unused-vars
import React, { useEffect, useRef, useState } from 'react'

import { useSetRecoilState, useRecoilValue } from 'recoil'
import useInnovaAuth from 'components/common/hooks/useInnovaAuth'
import {
  wellListAtom,
  wellListLoadingAtom,
  refreshWellList,
  filterListsAtom,
  userUpdatedAtom,
  currentWellSelector,
  currentWellDetailsSelector,
} from 'atoms'

import { getFilterLists, blankFilterLists } from 'components/common/filterLists'
import useChat from './common/hooks/useChat'
import useOrgIcons from './common/hooks/useOrgIcons'
import useWellData from './common/hooks/useWellData'
import { getItemFromLS } from 'utils/localStorage'
import useMapMarkers from './common/hooks/useMapMarkers'
import useWeatherBackgrounds from './common/hooks/useWeatherBackgrounds'
import useInnovaAxios from './common/hooks/useInnovaAxios'
import useAxiosGzip from './common/hooks/useAxiosGzip'
import useUnits from './common/hooks/useUnits'
import useObjectsOfInterest from './common/hooks/useObjectsOfInterest'
import useUserDatabaseAccess from './common/hooks/useUserDatabaseAccess'

const RecoilData = () => {
  const _isMounted = useRef()
  const { refreshWellData } = useWellData() // eslint-disable-line no-empty-pattern
  const setWellList = useSetRecoilState(wellListAtom)
  const setWellListLoading = useSetRecoilState(wellListLoadingAtom)
  const setFilterLists = useSetRecoilState(filterListsAtom)
  const fetchingFilterList = useRef(false)
  const fetchingWellData = useRef(false)
  const wellRefresh = useRecoilValue(refreshWellList)
  const userUpdated = useRecoilValue(userUpdatedAtom)
  const setCurrentWell = useSetRecoilState(currentWellSelector)
  const setCurrentWellDetails = useSetRecoilState(currentWellDetailsSelector)
  const { user, isAuthenticated } = useInnovaAuth()
  const [,] = useChat() // init chat
  // eslint-disable-next-line no-empty-pattern
  const {} = useOrgIcons() // init icon assets
  // eslint-disable-next-line no-empty-pattern
  const {} = useMapMarkers()
  // eslint-disable-next-line no-empty-pattern
  const {} = useWeatherBackgrounds()
  // eslint-disable-next-line no-empty-pattern
  const {} = useUnits()
  // eslint-disable-next-line no-empty-pattern
  const {} = useObjectsOfInterest()
  // eslint-disable-next-line no-empty-pattern
  const {} = useUserDatabaseAccess(true)

  const fetchWells = useAxiosGzip({
    url: '/wellsGz',
    timeout: 120000,
  })

  const fetchFilterLists = useInnovaAxios({
    url: '/filterLists',
  })

  useEffect(() => {
    if (!_isMounted.current) return
    if (!isAuthenticated) return
    if (!user?.name) return
    if (user.name === '') return
    getWellList()
    getFilterListData()
    refreshWellData()
  }, [user, isAuthenticated]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!_isMounted.current) return
    getWellList()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!_isMounted.current) return
    if (wellRefresh?.length <= 0) return
    getWellList()
  }, [wellRefresh]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!_isMounted.current) return
    if (userUpdated?.length <= 0) return
    getFilterListData()
  }, [userUpdated]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    _isMounted.current = true
    const storedCurrentWell = getItemFromLS('currentWell', 'currentWell')
    if (storedCurrentWell) {
      setCurrentWell(storedCurrentWell)
    }

    const storedCurrentWellDetails = getItemFromLS('currentWellDetails', 'currentWellDetails')
    if (storedCurrentWellDetails) {
      setCurrentWellDetails(storedCurrentWellDetails)
    }

    if (isAuthenticated && user?.name?.length > 1) {
      getWellList()
      getFilterListData()
    }

    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const getWellList = async () => {
    if (fetchingWellData.current) return

    fetchingWellData.current = true
    setWellListLoading(true)

    const res = await fetchWells({
      getFootage: true,
    })

    setWellListLoading(false)
    fetchingWellData.current = false

    if (!_isMounted.current) return
    if (res?.error) return

    setWellList(Array.isArray(res.data) ? res.data : [])
  }

  const getFilterListData = async () => {
    if (fetchingFilterList.current) return

    fetchingFilterList.current = true
    const res = await fetchFilterLists()
    fetchingFilterList.current = false

    if (!_isMounted.current) return
    if (res?.error) return

    setFilterLists(res.data ? getFilterLists(res.data) : blankFilterLists)
  }

  return <></>
}

export default RecoilData
