import React, { useRef, useEffect, useState, useMemo } from 'react'
import { currentPageAtom, actionBarWidthAtom } from 'atoms'
import { useSetRecoilState, useRecoilValue } from 'recoil'
import { PAGE_KEYS } from 'components/ActionBar/pageDefs'
import { Box, Tooltip, IconButton, Snackbar } from '@mui/material'
import { AgGridReact } from 'ag-grid-react'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import SearchBar from 'components/common/SearchBar'
import { saveItemToLS } from 'utils/localStorage'
import { Icon as Iconify } from '@iconify/react'
import Alert from '@mui/material/Alert'
import MenuButton from 'components/common/MenuButton'
import RefreshIcon from '@mui/icons-material/Refresh'
import { sortColDefs, htmlSymbolHandling, CustomLoadingOverlay, getStringId } from 'components/common/AgGridUtils'
import { unescapeHtml } from 'utils/htmlSymbolHandling'
import { debounce } from 'lodash'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'
import cloneDeep from 'lodash/cloneDeep'

const ShippingAddressesPage = () => {
  const setActivePage = useSetRecoilState(currentPageAtom)
  const leftPos = useRecoilValue(actionBarWidthAtom)
  const _isMounted = useRef(false)
  const gridApi = useRef(null)
  const { getAgGridTheme, searchBarStyle } = useInnovaTheme()
  const isAdding = useRef(false)
  const isUpdating = useRef(false)
  const isDeleting = useRef(false)
  const [isLoading, setLoading] = useState(false)
  const [addressData, setAddressData] = useState([])
  const [searchText, setSearchText] = useState('')
  const inputRow = useRef({})
  const [resetCols, setResetCols] = useState(false)
  const [status, setStatus] = useState({ show: false, severity: 'info', message: '' })

  const getShippingAddresses = useInnovaAxios({
    url: '/shippingAddresses/getAddresses',
  })

  const addShippingAddress = useInnovaAxios({
    url: '/shippingAddresses/addAddresses',
  })

  const updateAddress = useInnovaAxios({
    url: '/shippingAddresses/updateAddress',
  })

  const deleteAddress = useInnovaAxios({
    url: '/shippingAddresses/deleteAddress',
  })

  useEffect(() => {
    _isMounted.current = true
    setActivePage(PAGE_KEYS.shippingAddressesPageKey)
    fetchAddresses()
    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const debounceSetQuickFilter = debounce((text) => {
    if (gridApi.current) {
      gridApi.current.setGridOption('quickFilterText', text)
    }
  }, 300)

  useEffect(() => {
    debounceSetQuickFilter(searchText)
  }, [searchText]) // eslint-disable-line react-hooks/exhaustive-deps

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.recordId)
    }
  }, [])

  const fetchAddresses = async () => {
    if (isLoading) return
    setLoading(true)
    const response = await getShippingAddresses()

    if (response?.error) return
    if (!_isMounted.current) return

    setAddressData(Array.isArray(response.data) ? response.data : [])
    setLoading(false)
  }

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const gridOptions = {
    pinnedBottomRowData: [inputRow.current],
    onCellEditingStopped: (event) => {
      if (event.node?.rowPinned === 'bottom' && isPinnedRowDataCompleted()) {
        handleAddRow([event.node.data])
      }
      if (event.node?.rowPinned !== 'bottom') {
        handleUpdate([event.data])
      }
    },
    getRowStyle: ({ node }) => (node?.rowPinned ? { fontWeight: 'bold', fontStyle: 'italic' } : 0),
    suppressRowClickSelection: true,
    sideBar: {
      toolPanels: [
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        },
      ],
      defaultToolPanel: '',
      position: 'left',
    },
    onDragStopped: () => {
      saveColumnState()
    },
    onColumnVisible: () => {
      saveColumnState()
    },
    loadingOverlayComponent: CustomLoadingOverlay,
  }

  function createPinnedCellPlaceholder({ colDef }) {
    if (
      colDef.field !== 'company' &&
      colDef.field !== 'address' &&
      colDef.field !== 'contact' &&
      colDef.field !== 'email' &&
      colDef.field !== 'phone'
    )
      return ''
    return colDef.field[0].toUpperCase() + colDef.field.slice(1) + '...'
  }

  function isEmptyPinnedCell({ node, value }) {
    return (node?.rowPinned === 'bottom' && value == null) || (node?.rowPinned === 'bottom' && value === '')
  }

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: true,
      editable: true,
      autoHeight: true,
      headerClass: 'header-no-padding',
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
      valueFormatter: (params) => (isEmptyPinnedCell(params) ? createPinnedCellPlaceholder(params) : undefined),
    }
  }, [])

  const onFirstDataRendered = (params) => {
    if (gridApi.current) {
      gridApi.current.onFilterChanged()
    }

    autoSizeColumns()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const saveColumnState = () => {
    if (!gridApi.current) return

    const colLayout = gridApi.current.getColumnState()
    if (colLayout) saveItemToLS('shippingAddressesGrid', 'colLayout', colLayout)
  }

  const getContextMenuItems = (params) => {
    return [
      {
        name: 'Reset columns',
        disabled: false,
        action: () => {
          gridApi.current.resetColumnState()
          saveItemToLS('shippingAddressesGrid', 'colLayout', null)
          setResetCols(!resetCols)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style = "color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Reset filters',
        disabled: false,
        action: () => {
          gridApi.current.setFilterModel(null)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style = "color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      'copy',
      {
        name: 'Export',
        disabled: false,
        action: () => {
          gridApi.current.exportDataAsExcel({
            fileName: 'ShippingAddresses.xlsx',
            sheetName: 'Addresses',
          })
        },
        icon: '<span class="iconify" data-icon="icomoon-free:file-excel" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
    ]
  }

  function isPinnedRowDataCompleted() {
    return columnDefs.every((def) => {
      if (def.field === 'actions') return true
      if (def.field === 'active') return true

      return inputRow.current[def.field]
    })
  }

  const handleAddRow = async (data) => {
    if (!Array.isArray(data)) return
    if (isAdding.current) return
    for (let i = 0; i < data.length; i++) {
      data[i].active = true
      data[i] = htmlSymbolHandling(data[i])
    }
    isAdding.current = true
    let res = await addShippingAddress({ shippingAddresses: JSON.stringify(data) })
    isAdding.current = false

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })
      inputRow.current = {}
      if (gridApi.current) gridApi.current.setGridOption('pinnedBottomRowData', [inputRow.current])
      return
    }

    if (!res?.error) {
      let shippingAddressesToAdd = []
      for (let i = 0; i < data.length; i++) {
        shippingAddressesToAdd.push(data[i])
      }
      gridApi.current.applyTransaction({
        add: shippingAddressesToAdd,
      })
    }

    inputRow.current = {}
    if (gridApi.current) {
      gridApi.current.setGridOption('pinnedBottomRowData', [inputRow.current])
    }
  }

  const handleUpdate = async (data) => {
    if (!Array.isArray(data)) return
    if (data.length === 0) return

    for (let i = 0; i < data.length; i++) {
      data[i] = htmlSymbolHandling(data[i])
    }

    if (isUpdating.current) return
    isUpdating.current = true
    let res = await updateAddress({ shippingAddresses: JSON.stringify(data) })
    isUpdating.current = false

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })
      return
    }

    if (!res?.error) {
      gridApi.current.applyTransaction({
        update: data,
      })
    }
  }

  const handleDelete = async (data) => {
    if (!data) return
    if (!data.hasOwnProperty('recordId')) return

    if (isDeleting.current) return
    isDeleting.current = true
    let res = await deleteAddress(data)
    isDeleting.current = false

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })
      return
    }

    if (!res?.error) {
      gridApi.current.applyTransaction({
        remove: [data],
      })
    }
  }

  const actionIconRenderer = (params) => {
    return (
      <React.Fragment>
        <Box style={{ display: 'flex', flexDirection: 'row' }}>
          <Tooltip
            title={params.node?.rowPinned !== 'bottom' ? 'Delete' : 'Add'}
            placement='left'
            componentsProps={{
              tooltip: {
                sx: {
                  backgroundColor: 'rgb(19,62,96)',
                  fontSize: '12px',
                  fontFamily: 'Roboto',
                },
              },
            }}>
            <IconButton
              style={{ padding: '5px', alignItems: 'center', justifyContent: 'center' }}
              onClick={() =>
                params.node?.rowPinned !== 'bottom' ? handleDelete(params.data) : handleAddRow([params.node.data])
              }
              size='large'>
              {params.node?.rowPinned !== 'bottom' ? (
                <Iconify color='red' icon='fa-regular:trash-alt' fontSize={16} />
              ) : (
                <Iconify color='green' icon='fluent:add-12-filled' fontSize={16} />
              )}
            </IconButton>
          </Tooltip>
          {params.node?.rowPinned !== 'bottom' ? (
            <Box style={{ textAlign: 'right', paddingLeft: '8px' }}>{params.node.rowIndex + 1}</Box>
          ) : null}
        </Box>
      </React.Fragment>
    )
  }

  let columnDefs = [
    {
      field: 'actions',
      colId: 'actions',
      width: 86,
      headerName: '',
      editable: false,
      filter: null,
      sortable: false,
      suppressHeaderMenuButton: true,
      suppressHeaderFilterButton: true,
      pinned: 'left',
      lockPosition: 'left',
      cellStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      cellRenderer: actionIconRenderer,
    },
    {
      field: 'company',
      colId: 'company',
      headerName: 'Company Name',
      valueGetter: (params) => unescapeHtml(params.data?.company),
    },
    {
      field: 'address',
      colId: 'address',
      headerName: 'Address',
      valueGetter: (params) => unescapeHtml(params.data?.address),
    },
    {
      field: 'contact',
      colId: 'contact',
      headerName: 'Contact Name',
      valueGetter: (params) => unescapeHtml(params.data?.contact),
    },
    {
      field: 'email',
      colId: 'email',
      headerName: 'Email',
      valueGetter: (params) => unescapeHtml(params.data?.email),
    },
    {
      field: 'phone',
      colId: 'phone',
      headerName: 'Phone Number',
      valueGetter: (params) => unescapeHtml(params.data?.phone),
    },
  ]

  const handleCloseStatus = () => {
    setStatus({ show: false, severity: 'info', message: '' })
  }

  const processDataFromClipboard = (params) => {
    if (!params.hasOwnProperty('data') && !Array.isArray(params.data) && params.data?.length < 1) {
      return null
    }
    let data = params.data?.map((dataRow) => {
      return dataRow.slice()
    })

    // delete the empty last row in the clipboard data, if one exists
    const emptyLastRow = data[data.length - 1][0] === '' && data[data.length - 1].length === 1
    if (emptyLastRow) {
      data.splice(data.length - 1, 1)
    }

    for (let i = 0; i < data.length; i += 1) {
      for (let j = 0; j < data[i].length; j++) {
        if (typeof data[i][j] === 'string') continue
        data[i][j] = data[i][j].replace(',', '')
      }
    }

    // make sure there's data to process after we remove the typical empty row
    if (data.length < 1) return null

    const lastIndex = gridApi.current.getDisplayedRowCount() - 1
    const focusedCell = gridApi.current.getFocusedCell()

    const rowsToUpdate = []
    let curRowIndex = focusedCell.rowIndex
    if (focusedCell?.rowPinned !== 'bottom') {
      for (let i = 0; i < data.length; i++) {
        const row = data[i].splice(0,1)[0]
        let rowData = row.split(',')
        if (curRowIndex > lastIndex) break
        if (!Array.isArray(rowData)) continue

        let rigNameData = cloneDeep(gridApi.current.getDisplayedRowAtIndex(curRowIndex))
        let currentColumn = focusedCell.column

        for (let j = 0; j < rowData.length; j++) {
          if (!currentColumn) continue
          rigNameData.data[currentColumn.colDef.field] = rowData[j]
          currentColumn = gridApi.current.getDisplayedColAfter(currentColumn)
        }

        rowsToUpdate.push(cloneDeep(rigNameData.data))
        curRowIndex++
      }
    }

    const rowsToAdd = []
    let startPasteIndex = focusedCell?.rowPinned === 'bottom' ? lastIndex + 1 : focusedCell.rowIndex
    if (startPasteIndex + data.length - 1 > lastIndex) {
      const resultLastIndex = startPasteIndex + (data.length - 1)
      const numRowsToAdd = resultLastIndex - lastIndex

      let index = data.length - 1
      for (let i = 0; i < numRowsToAdd; i++) {
        const row = data.slice(index, index + 1)[0]
        index--

        if (!Array.isArray(row)) continue
        if (row[0].split(',').length < 2) continue
        const company = row[0].split(',')[0]
        const address = row[0].split(',')[1]
        let contact = ''
        if (row[0].split(',').length > 2) {
          contact = row[0].split(',')[2]
        }
        let email = ''
        if (row[0].split(',').length > 3) {
          email = row[0].split(',')[3]
        }
        let phone = ''
        if (row[0].split(',').length > 4) {
          phone = row[0].split(',')[4]
        }

        rowsToAdd.push({ company: company, address: address, contact: contact, email: email, phone: phone })
      }
      rowsToAdd.reverse()
    }

    if (rowsToUpdate.length > 0) {
      handleUpdate(rowsToUpdate)
    }

    if (rowsToAdd.length > 0) {
      handleAddRow(rowsToAdd)
    }

    if (focusedCell?.rowPinned === 'bottom') {
      inputRow.current = {}
      if (gridApi.current) gridApi.current.setGridOption('pinnedBottomRowData', [inputRow.current])
    }

    return null
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItmes: 'center',
        flexDirection: 'column',
        height: 'calc(100vh - 64px)',
        marginLeft: `${leftPos}px`,
        width: `calc(100%-${leftPos}px`,
        maxWidth: `calc(100%-${leftPos}px)`,
        maxHeight: `calc(100vh - 64px)`,
      }}>
      <Box
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
          width: '100%',
        }}>
        <SearchBar
          value={searchText}
          onChange={(newSearchTerm) => setSearchText(newSearchTerm)}
          onCancelSearch={() => setSearchText('')}
          style={searchBarStyle()}
        />
      </Box>
      <Box sx={{ display: 'flex', width: '100%', height: '100%' }}>
        <div className={getAgGridTheme()} style={{ width: '100%', height: '100%' }}>
          <AgGridReact
            loading={isLoading}
            rowData={addressData}
            columnDefs={sortColDefs(columnDefs, 'shippingAddressesGrid')}
            defaultColDef={defaultColDef}
            getRowId={getRowId}
            animateRows={true}
            enableBrowserTooltips={true}
            gridOptions={gridOptions}
            headerHeight={30}
            onGridReady={onGridReady}
            onFirstDataRendered={onFirstDataRendered}
            getContextMenuItems={getContextMenuItems}
            processDataFromClipboard={processDataFromClipboard}
          />
        </div>
      </Box>
      <Box
        sx={{
          backgroundColor: 'transparent',
          margin: '4px',
          padding: '12px',
          position: 'fixed',
          bottom: '20px',
          right: '20px',
          zIndex: 2,
        }}>
        <MenuButton
          actions={[
            {
              icon: <RefreshIcon />,
              name: 'Refresh',
              onClick: () => {
                fetchAddresses()
              },
            },
          ]}
        />
      </Box>
      {status?.show ? (
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={status?.show}
          autoHideDuration={2000}
          onClose={handleCloseStatus}>
          <Alert onClose={handleCloseStatus} severity={status.severity} elevation={4} variant='filled'>
            {status.message}
          </Alert>
        </Snackbar>
      ) : null}
    </Box>
  )
}

export default ShippingAddressesPage
