import React, { useEffect, useRef, useMemo, useState, useCallback } from 'react'
import { Box, Avatar, Snackbar } from '@mui/material'
import { AgGridReact } from 'ag-grid-react'
import { appColors } from 'utils'
import ConfirmDialog from 'components/common/ConfirmDialog'
import Alert from '@mui/material/Alert'
import SearchBar from 'components/common/SearchBar'
import DropDownPicker from 'components/common/DropDownPicker'
import {
  sortColDefs,
  relativeTime,
  isValidDate,
  CustomLoadingOverlay,
  dateComparator,
  isDateLessThan,
  getStringId,
} from 'components/common/AgGridUtils'
import useAxiosGzip from 'components/common/hooks/useAxiosGzip'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import TextField from '@mui/material/TextField'
import { saveItemToLS, getItemFromLS } from 'utils/localStorage'
import useOrgIcons from 'components/common/hooks/useOrgIcons'
import MenuButton from 'components/common/MenuButton'
import RefreshIcon from '@mui/icons-material/Refresh'
import { Icon as Iconify } from '@iconify/react'
import * as XLSX from '@sheet/core'
import { styled } from '@mui/styles'
import { chartSeriesColors } from 'utils'
import { getRandomColor } from 'utils/colorFunctions'
import { debounce } from 'lodash'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const defaultColDef = {
  resizable: true,
  sortable: true,
  autoHeight: true,
  wrapText: false,
  editable: false,
  filter: 'agSetColumnFilter',
  filterParams: {
    excelMode: 'windows',
  },
}

const StyledMenuIcon = styled(Box)(({ theme }) => ({
  backgroundColor: 'transparent',
  margin: '4px',
  padding: '12px',
  position: 'fixed',
  bottom: theme.spacing(2),
  right: theme.spacing(2),
  zIndex: 2,
}))

const StyledListIcon = styled(Iconify)({
  color: '#C0C0C0',
  fontSize: '24px',
})

const getInitialGroupRows = () => {
  let storedValue = getItemFromLS('wellSeekerUsersGroupRows', 'groupRows')
  if (storedValue === null || storedValue === undefined) return true
  return storedValue
}

const WellSeekerUsersGrid = ({
  isLoading,
  setIsLoading,
  isLoadingStats,
  setIsLoadingStats,
  summarizedData,
  setSummarizedData,
  showChart,
  setShowChart,
}) => {
  const _isMounted = useRef(false)
  const gridApi = useRef(null)
  const [confirm, setConfirm] = useState({ show: false, title: '' })
  const [groupRows, setGroupRows] = useState(getInitialGroupRows())
  const [userData, setUserData] = useState([])
  const [usageStats, setUsageStats] = useState([])
  const [dateRange, setDateRange] = useState(() => {
    let dates = {
      dropDownText: 'Last 30 Days',
      dateTo: new Date(Date.now()).toISOString().substring(0, 10),
      dateFrom: '',
    }
    let dt = new Date(Date.now())
    dt.setDate(dt.getDate() - 30)
    dates.dateFrom = dt.toISOString().substring(0, 10)
    return dates
  })
  const [searchText, setSearchText] = useState('')
  const { getAgGridTheme, searchBarStyle, getBackColor, getTextColor } = useInnovaTheme()
  const [resetCols, setResetCols] = useState(false)
  const [status, setStatus] = useState({ show: false, severity: 'info', message: '' })
  const { getCompanyIcon } = useOrgIcons()

  const getWellSeekerUsers = useAxiosGzip({
    url: '/wellSeekerUsers/usageData',
  })

  const toggleLicenseActive = useInnovaAxios({
    url: '/wellSeekerUsers/licenseActivation',
  })

  const getUsageSummary = useInnovaAxios({
    url: '/wellSeekerUsers/usageStats',
  })

  const centerAlignCell = () => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    sortable: true,
  })

  const fetchUsageStats = async () => {
    if (isLoadingStats) return

    if (_isMounted.current) setIsLoadingStats(true)
    const res = await getUsageSummary({
      dateTo: dateRange.dateTo,
      dateFrom: dateRange.dateFrom,
    })

    if (!_isMounted.current) return
    setIsLoadingStats(false)

    if (res.error) {
      setStatus({ show: true, severity: 'error', message: `${res?.error?.response?.data?.error}` })
      return
    }

    setUsageStats(Array.isArray(res.data) ? res.data : [])
  }

  const fetchUsageData = async () => {
    if (isLoading) return

    if (_isMounted.current) setIsLoading(true)
    const res = await getWellSeekerUsers()
    if (_isMounted.current) setIsLoading(false)

    if (!_isMounted.current) return

    if (res.error) {
      setStatus({ show: true, severity: 'error', message: `${res?.error?.response?.data?.error}` })
      return
    }

    if (!Array.isArray(res.data)) return
    let sortedUsageData = [...res.data]
    sortedUsageData.sort((firstEl, secondEl) => {
      if (firstEl.daysSinceActive < secondEl.daysSinceActive) return -1
      if (firstEl.daysSinceActive > secondEl.daysSinceActive) return 1
      return 0
    })

    if (_isMounted.current) setUserData(sortedUsageData)
  }

  useEffect(() => {
    _isMounted.current = true
    fetchUsageData()
    fetchUsageStats()
    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const debounceSetQuickFilter = debounce((text) => {
    if (gridApi.current) {
      gridApi.current.setGridOption('quickFilterText', text)
    }
  }, 300)

  useEffect(() => {
    debounceSetQuickFilter(searchText)
  }, [searchText]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (gridApi.current) {
      gridApi.current.onFilterChanged()
    }

    if (new Date(Date.parse(dateRange.dateTo)).getTime() < new Date(Date.parse(dateRange.dateFrom)).getTime()) {
      if (_isMounted.current) setStatus({ show: true, severity: 'warning', message: 'Date to less than date from' })
    }

    fetchUsageStats()
  }, [dateRange]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (gridApi.current) {
      gridApi.current.onFilterChanged()
    }
  }, [usageStats]) // eslint-disable-line react-hooks/exhaustive-deps

  const dateFilterComparator = useCallback(dateComparator, [])

  const onClickChangeStatus = async () => {
    if (isLoading) return
    if (!confirm?.data) return
    if (_isMounted.current) setIsLoading(true)

    let isActive = confirm.data?.active !== 'FALSE'

    const res = await toggleLicenseActive({
      computerName: confirm.data.computerName,
      computerCode: confirm.data.computerCode,
      companyId: confirm.data.companyID,
      deactivate: isActive, //If license is active then deactivate
    })

    if (!_isMounted.current) return

    setIsLoading(false)
    if (res.error) {
      setStatus({ show: true, severity: 'error', message: `${res?.error?.response?.data?.error}` })
      return
    }

    confirm.data.active = confirm.data.active === 'FALSE' ? '' : 'FALSE'

    if (gridApi.current) {
      gridApi.current.applyTransaction({
        update: [confirm.data],
      })
    }

    setStatus({
      show: true,
      severity: 'success',
      message: `${confirm.data.computerName} ${isActive ? 'deactivated' : 'activated'}`,
    })
  }

  const onClickToggleActivate = (data) => {
    let isActive = data?.active !== 'FALSE'

    setConfirm({
      show: true,
      title: 'Change User Status',
      text: `Are you sure you want to ${isActive ? 'deactivate' : 'activate'} ${data?.computerName}?`,
      data: data,
    })
  }

  const relativeTimeFormatter = useCallback(relativeTime, [])

  const dateTimeFormatter = useCallback((value) => {
    if (!value) return ''
    if (typeof value !== 'string') return ''
    if (value === '') return ''
    if (isDateLessThan(value, '1990-01-01')) return ''
    value = value.replace(/Z/g, '')
    return new Date(Date.parse(value)).toLocaleDateString('default', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    })
  }, [])

  const columnDefs = useMemo(
    () => [
      {
        field: 'icon',
        colId: 'icon',
        headerName: '',
        width: 100,
        hide: groupRows,
        resizable: false,
        editable: false,
        filter: null,
        sortable: false,
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
        pinned: 'left',
        lockPosition: 'left',
        cellRenderer: (params) => {
          if (!params) return null
          if (!params.data) return null
          if (typeof params?.data?.companyID !== 'string') return null

          return (
            <Avatar
              alt={params?.data?.companyID}
              sx={{ width: '30px', height: '30px', marginLeft: '1px' }}
              src={getCompanyIcon(params?.data?.companyID)}
            />
          )
        },
      },
      {
        field: 'companyID',
        colId: 'companyID',
        headerName: 'Company ID',
        hide: groupRows,
        rowGroup: groupRows,
        pinned: 'left',
        lockPosition: 'left',
        cellStyle: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'start',
        },
      },
      {
        field: 'computerName',
        colId: 'computerName',
        headerName: 'Comp Name',
        pinned: 'left',
        lockPosition: 'left',
        cellStyle: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'start',
        },
      },
      {
        field: 'computerCode',
        colId: 'computerCode',
        headerName: 'Comp Code',
        cellStyle: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'start',
        },
      },
      {
        field: 'dateTime',
        colId: 'dateTime',
        headerName: 'Last Active',
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: dateFilterComparator,
        },
        valueFormatter: (params) =>
          `${dateTimeFormatter(params.data?.dateTime)} [${relativeTimeFormatter(
            params.data?.daysSinceActive * 24 * 60,
          )}]`,
      },
      {
        field: 'active',
        colId: 'active',
        headerName: 'Active',
        editable: true,
        cellStyle: centerAlignCell,
        cellRenderer: 'agCheckboxCellRenderer',
        valueGetter: (params) => {
          if (params?.data?.active === 'FALSE') return false
          return true
        },
        valueSetter: (params) => {
          onClickToggleActivate(params.data)
        },
      },
      {
        field: 'ipAddress',
        colId: 'ipAddress',
        headerName: 'IP',
        cellStyle: centerAlignCell,
      },
      {
        field: 'companyName',
        colId: 'companyName',
        headerName: 'Company',
        cellStyle: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'start',
        },
      },
      {
        field: 'userName',
        colId: 'userName',
        headerName: 'User Name',
        cellStyle: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'start',
        },
      },
      {
        field: 'wsVersion',
        colId: 'wsVersion',
        headerName: 'Version',
        cellStyle: centerAlignCell,
      },
      {
        field: 'country',
        colId: 'country',
        headerName: 'Country',
        cellStyle: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'start',
        },
      },
      {
        field: 'regionName',
        colId: 'regionName',
        headerName: 'Region',
        cellStyle: centerAlignCell,
      },
      {
        field: 'city',
        colId: 'city',
        headerName: 'City',
        cellStyle: centerAlignCell,
      },
      {
        field: 'org',
        colId: 'org',
        headerName: 'ISP',
        cellStyle: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'start',
        },
      },
    ],
    [getCompanyIcon, groupRows, dateTimeFormatter, dateFilterComparator], // eslint-disable-line react-hooks/exhaustive-deps
  )

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.uid)
    }
  }, [])

  const onFilterChanged = (params) => {
    if (!gridApi.current) return

    let filteredNodes = []
    params.api.forEachNodeAfterFilter((node) => {
      if (node.data) filteredNodes.push(node.data)
    })

    setSummarizedData({
      summarizedData: summarizeData(filteredNodes),
      summarizeVersionData: summarizeVersionData(filteredNodes),
      mapData: summarizeMapData(filteredNodes),
    })
  }

  const summarizeMapData = (filteredNodes) => {
    if (!Array.isArray(filteredNodes)) return []
    return filteredNodes.filter((user) => user?.lat !== 0 && user?.lon !== 0 && user?.daysSinceActive <= 1)
  }

  const saveColumnState = () => {
    if (gridApi.current) {
      const colLayout = gridApi.current.getColumnState()
      if (colLayout) saveItemToLS('wellSeekerUsersGrid', 'colLayout', colLayout)
    }
  }

  const gridOptions = {
    // onCellEditingStopped: (event) => {
    //   if (event?.rowPinned === 'bottom') return null
    //   if (event?.valueChanged) {
    //     if (event?.colDef?.field === 'active') {
    //       onClickToggleActivate(event)
    //     }
    //   }
    // },
    suppressRowClickSelection: true,
    sideBar: {
      toolPanels: [
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        },
      ],
      defaultToolPanel: '',
      position: 'left',
    },
    onDragStopped: (event) => {
      saveColumnState()
    },
    onColumnVisible: (event) => {
      saveColumnState()
    },
    onExpandOrCollapseAll: () => {
      autoSizeColumns()
    },
    loadingOverlayComponent: CustomLoadingOverlay,
  }

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const onFirstDataRendered = (params) => {
    if (gridApi.current) {
      gridApi.current.onFilterChanged()
    }

    expandAllNodes('expand')
    autoSizeColumns()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    setTimeout(() => {
      gridApi.current?.autoSizeAllColumns()
    }, 100)
  }

  const handleCloseStatus = () => {
    setStatus({ show: false, severity: 'info', message: '' })
  }

  const expandAllNodes = (action) => {
    if (typeof action !== 'string') return
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    if (action === 'expand') gridApi.current.expandAll()
    if (action !== 'expand') gridApi.current.collapseAll()
  }

  const GroupRowInnerRenderer = (props) => {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          padding: '10px',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <Box
          sx={{
            margin: 'auto',
            width: 35,
            height: 35,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'lightgrey',
            borderRadius: '50%',
          }}>
          <Avatar
            alt={props.node.key}
            sx={{ width: '30px', height: '30px', marginLeft: '1px' }}
            src={getCompanyIcon(props.node.key)}
          />
        </Box>
        <Box
          sx={{
            marginLeft: '10px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}>
          {props.node.key}
        </Box>
      </Box>
    )
  }

  const groupRowRendererParams = {
    innerRenderer: GroupRowInnerRenderer,
    checkbox: false,
  }

  const getContextMenuItems = (params) => {
    return [
      {
        name: 'Reset columns',
        disabled: false,
        action: () => {
          gridApi.current.resetColumnState()
          saveItemToLS('wellSeekerUsersGrid', 'colLayout', null)
          setResetCols(!resetCols)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Reset filters',
        disabled: false,
        action: () => {
          gridApi.current.setFilterModel(null)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: groupRows ? 'Ungroup Rows' : 'Group Rows',
        disabled: false,
        action: () => {
          gridApi.current.resetColumnState()
          saveItemToLS('wellSeekerUsersGrid', 'colLayout', null)
          saveItemToLS('wellSeekerUsersGroupRows', 'groupRows', !groupRows)
          setGroupRows(!groupRows)
        },
        icon: '<span class="iconify" data-icon="mdi:format-list-group" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Expand all',
        disabled: false,
        action: () => {
          expandAllNodes('expand')
        },
        icon: '<span class="iconify" data-icon="material-symbols:expand" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Collapse all',
        disabled: false,
        action: () => {
          expandAllNodes('collapse')
        },
        icon: '<span class="iconify" data-icon="pajamas:collapse" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      'copy',
      {
        name: 'Export',
        disabled: false,
        action: () => {
          gridApi.current.exportDataAsExcel({
            fileName: 'WellSeekerUsers.xlsx',
            sheetName: 'Users',
          })
        },
        icon: '<span class="iconify" data-icon="icomoon-free:file-excel" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
    ]
  }

  const getDateFromAndToFilters = (dateFilter) => {
    let dateTo = new Date(Date.now())
    let dateFrom = new Date(Date.parse('1900-01-01'))

    if (typeof dateFilter === 'string' && dateFilter !== 'All') {
      if (dateFilter === 'Current Month') {
        dateFrom = new Date(Date.now())
        dateFrom.setDate(1)
      }

      if (dateFilter === 'Previous Month') {
        dateFrom = new Date(Date.now())
        dateFrom.setMonth(dateFrom.getMonth() - 1)
        dateFrom.setDate(1)

        let daysInMonth = new Date(dateFrom.getFullYear(), dateFrom.getMonth() + 1, 0).getDate()
        dateTo = new Date(Date.parse(dateFrom.toISOString()))
        dateTo.setDate(daysInMonth)
        dateTo.setYear(dateFrom.getFullYear())
      }

      if (dateFilter === 'Last 30 Days') {
        dateFrom = new Date(Date.now())
        dateFrom.setDate(dateFrom.getDate() - 30)
      }

      if (dateFilter === 'Last 60 Days') {
        dateFrom = new Date(Date.now())
        dateFrom.setDate(dateFrom.getDate() - 60)
      }

      if (dateFilter === 'Last 6 Months') {
        dateFrom = new Date(Date.now())
        dateFrom.setMonth(dateFrom.getMonth() - 6)
      }

      if (dateFilter === 'Current Year') {
        dateFrom = new Date(Date.parse(`${dateTo.getFullYear()}-01-01T00:01:00`))
      }

      if (dateFilter === 'Last Year') {
        dateFrom = new Date(Date.parse(`${dateTo.getFullYear() - 1}-01-01T00:01:00`))
        dateTo = new Date(Date.parse(`${dateTo.getFullYear()}-01-01T00:01:00`))
      }
    }

    return { dateTo: dateTo.toISOString().substring(0, 10), dateFrom: dateFrom.toISOString().substring(0, 10) }
  }

  const isExternalFilterPresent = useCallback(() => {
    return dateRange.dropDownText !== 'All'
  }, [dateRange])

  const doesExternalFilterPass = useCallback(
    (node) => {
      if (node.data) {
        if (!isValidDate(node.data.dateTime)) return false

        let dateFrom = new Date(Date.parse(dateRange.dateFrom.substring(0, 10) + 'T00:00:01'))
        let dateTo = new Date(Date.parse(dateRange.dateTo.substring(0, 10) + 'T23:59:59'))

        let dt = new Date(Date.parse(node.data.dateTime)).getTime()
        if (dt < dateFrom.getTime() || dt > dateTo.getTime()) return false
      }
      return true
    },
    [dateRange],
  )

  const getWellSeekerVersionNumber = (versionIn) => {
    if (typeof versionIn !== 'string') return ''
    const splitVersion = versionIn.split('-')
    if (splitVersion.length <= 0) return ''
    let versionOut = splitVersion[0].trim()
    return versionOut
  }

  const serializeWellSeekerVersionNumber = (versionIn) => {
    let versionNumTrimmed = getWellSeekerVersionNumber(versionIn)
    if (versionNumTrimmed === '') return 0
    const splitVer = versionNumTrimmed.split('.')

    let returnVer = 0
    let curMultiple = 1000000000
    for (let i = 0; i < splitVer.length; i++) {
      returnVer += curMultiple * parseFloat(splitVer[i].trim())
      curMultiple /= 1000
    }

    return returnVer
  }

  const summarizeVersionData = (filteredData) => {
    let output = {
      title: '',
      colors: [],
      values: [],
      companyValues: [],
      barLabels: [],
      seriesLabels: [],
      plottingMethods: [],
    }

    if (!Array.isArray(filteredData)) return output
    if (filteredData.length === 0) return output

    output.barLabels = [
      ...new Set(filteredData.map((item) => (typeof item?.companyID === 'string' ? item?.companyID : ''))),
    ].sort()
    output.barLabels = output.barLabels.filter((item) => item !== '')
    output.seriesLabels = [...new Set(filteredData.map((item) => getWellSeekerVersionNumber(item?.wsVersion)))]
    output.seriesLabels = output.seriesLabels.filter((item) => item !== '')
    output.seriesLabels.sort((firstEl, secondEl) => {
      let serialFirstEl = serializeWellSeekerVersionNumber(firstEl)
      let serialSecondEl = serializeWellSeekerVersionNumber(secondEl)
      if (serialFirstEl > serialSecondEl) return 1
      if (serialFirstEl < serialSecondEl) return -1
      return 0
    })

    output.seriesLabels.forEach((_, i) => {
      if (i < chartSeriesColors.length) output.colors.push(chartSeriesColors[i])
      if (i >= chartSeriesColors.length) output.colors.push(getRandomColor())
    })

    let newDataValue = new Array(output.seriesLabels.length).fill(0)
    output.barLabels.forEach((_, i) => {
      output.values.push([...newDataValue])
    })

    filteredData.forEach((user) => {
      let barIndex = output.barLabels.findIndex((element) => {
        if (typeof user?.companyID !== 'string') return false
        return element === user?.companyID.toUpperCase()
      })

      let seriesIndex = output.seriesLabels.findIndex(
        (element) => element === getWellSeekerVersionNumber(user?.wsVersion),
      )

      if (barIndex >= 0 && seriesIndex >= 0) output.values[barIndex][seriesIndex]++
    })

    return output
  }

  const summarizeData = (filteredData) => {
    let output = {
      title: '',
      colors: [],
      values: [],
      companyValues: [],
      barLabels: [],
      seriesLabels: [],
      plottingMethods: [],
      excelLabels: [],
      minMaxAvgLabels: [],
      minMaxAvg: {},
    }

    if (!Array.isArray(filteredData)) return output
    if (!Array.isArray(usageStats)) return output
    if (usageStats.length === 0) return output

    output.seriesLabels = [...new Set(filteredData.map((item) => item?.companyID))]
    output.barLabels = [...new Set(usageStats.map((item) => item?.dateTime))]

    for (let i = 0; i < output.seriesLabels.length; i++) {
      output.colors.push(i < chartSeriesColors.length ? chartSeriesColors[i] : getRandomColor())
    }

    for (let i = 0; i < output.barLabels.length; i++) {
      output.values.push(new Array(output.seriesLabels.length).fill(0))
    }

    output.barLabels.reverse()

    for (let i = 0; i < usageStats.length; i++) {
      let seriesIndex = output.seriesLabels.findIndex((element) => element === usageStats[i]?.companyID)
      if (seriesIndex < 0) continue
      let barIndex = output.barLabels.findIndex((element) => element === usageStats[i]?.dateTime)
      if (barIndex < 0) continue

      output.values[barIndex][seriesIndex] = parseInt(usageStats[i].count)
    }

    output.barLabels.forEach((label, i) => {
      output.excelLabels.push(output.barLabels[i])
      const splitDate = label.split('-')
      if (Array.isArray(splitDate) && splitDate.length > 2) {
        output.barLabels[i] = parseInt(splitDate[1]).toString() + '/' + parseInt(splitDate[2]).toString()
      }
    })

    let minMaxAvg = {
      min: 0,
      avg: 0,
      max: 0,
      numDays: 0,
      dailyTotal: 0,
      dayTotals: [],
    }

    output.values.forEach((dayValues) => {
      minMaxAvg.numDays++
      minMaxAvg.dayTotals.push(dayValues.reduce((accumulator, currentValue) => accumulator + currentValue, 0))
    })

    for (let i = 0; i < output.seriesLabels.length; i++) {
      let companyData = []
      for (let j = 0; j < output.values.length; j++) {
        companyData.push(output.values[j][i])
      }
      output.companyValues.push(companyData)
    }

    output.companyValues.forEach((company) => {
      let minMaxAvgItem = {
        min: 0,
        avg: 0,
        max: 0,
        numDays: 0,
        dailyTotal: 0,
      }
      minMaxAvgItem.min = Math.min(...company)
      minMaxAvgItem.max = Math.max(...company)
      minMaxAvgItem.avg = Math.round(company.reduce((a, b) => a + b, 0) / company.length)
      output.minMaxAvgLabels.push(minMaxAvgItem)
    })

    minMaxAvg.min = Math.min(...minMaxAvg.dayTotals)
    minMaxAvg.max = Math.max(...minMaxAvg.dayTotals)
    minMaxAvg.avg = minMaxAvg.dayTotals.reduce((a, b) => a + b, 0)
    minMaxAvg.avg = minMaxAvg.numDays > 0 && minMaxAvg.avg > 0 && Math.round(minMaxAvg.avg / minMaxAvg.numDays)
    minMaxAvg.dailyTotal = minMaxAvg.dayTotals.length > 0 ? minMaxAvg.dayTotals[minMaxAvg.dayTotals.length - 1] : 0
    output.minMaxAvg = minMaxAvg
    output.title = `Min: ${minMaxAvg.min}, Avg: ${minMaxAvg.avg}, Max: ${minMaxAvg.max}, Today: ${minMaxAvg.dailyTotal}`
    return output
  }

  const onXlsxExportUsageTracking = () => {
    if (!summarizedData.summarizedData) return
    if (!summarizedData.summarizedData.values) return
    let data = summarizedData.summarizedData

    let headers = []
    headers.push('#')
    headers.push('COMPANY')
    headers.push('MIN')
    headers.push('AVG')
    headers.push('MAX')

    headers.push('')
    summarizedData.summarizedData.excelLabels.forEach((element) => {
      headers.push(element)
    })

    let ws = XLSX.utils.aoa_to_sheet([headers], {
      origin: 'A1',
    })

    let companyData = []
    for (let i = 0; i < data.companyValues.length; i++) {
      let usageRow = {
        companyName: data.minMaxAvgLabels[i] ? data.seriesLabels[i] : '',
        min: data.minMaxAvgLabels[i] ? data.minMaxAvgLabels[i].min : 0,
        avg: data.minMaxAvgLabels[i] ? data.minMaxAvgLabels[i].avg : 0,
        max: data.minMaxAvgLabels[i] ? data.minMaxAvgLabels[i].max : 0,
        usageData: [...data.companyValues[i]],
      }

      companyData.push(usageRow)
    }

    companyData.sort((firstEl, secondEl) => (firstEl.avg < secondEl.avg ? 1 : -1))

    let usageExport = []
    for (let i = 0; i < companyData.length; i++) {
      let cpdRow = []
      cpdRow.push(i + 1)
      cpdRow.push(companyData[i].companyName)
      cpdRow.push(companyData[i].min)
      cpdRow.push(companyData[i].avg)
      cpdRow.push(companyData[i].max)
      cpdRow.push('')

      cpdRow = cpdRow.concat(companyData[i].usageData)
      usageExport.push(cpdRow)
    }

    usageExport.push([])
    const totalRow = ['', 'TOTAL', data.minMaxAvg.min, data.minMaxAvg.max, data.minMaxAvg.avg, ''].concat(
      data.minMaxAvg.dayTotals,
    )

    usageExport.push(totalRow)
    XLSX.utils.sheet_add_aoa(ws, usageExport, { origin: 'A2' })

    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, 'Usage Tracking')
    XLSX.writeFile(wb, 'Usage Tracking.xlsx')
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%' }}>
      <Box
        sx={{
          backgroundColor: getBackColor(),
          padding: '2px',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        {confirm.show ? (
          <ConfirmDialog
            title={confirm?.title}
            open={confirm?.show}
            setOpen={() => setConfirm({ show: false })}
            onConfirm={() => onClickChangeStatus()}>
            {confirm?.text}
          </ConfirmDialog>
        ) : null}
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Box sx={{ flex: 0.25, marginLeft: '0px', marginRight: '10px' }}>
            <DatePicker
              views={['day', 'month', 'year']}
              label={'Date From'}
              disableMaskedInput
              value={dateRange.dateFrom + 'T00:01:00'}
              onChange={(date) => {
                let dt = new Date(Date.parse(date))
                let newDates = { ...dateRange }
                newDates.dateFrom = dt.toISOString().substring(0, 10)
                newDates.dropDownText = 'User Defined'
                setDateRange(newDates)
              }}
              renderInput={(params) => (
                <TextField
                  sx={{
                    margin: '0px',
                    width: '100%',
                    height: '100%',
                    borderRadius: '0px',
                    svg: { color: getTextColor() },
                  }}
                  variant='standard'
                  {...params}
                  helperText={null}
                />
              )}
            />
          </Box>
          <Box sx={{ flex: 0.25, marginLeft: '10px', marginRight: '10px' }}>
            <DatePicker
              views={['day', 'month', 'year']}
              label={'Date To'}
              disableMaskedInput
              value={dateRange.dateTo + 'T00:01:00'}
              onChange={(date) => {
                let dt = new Date(Date.parse(date))
                let newDates = { ...dateRange }
                newDates.dateTo = dt.toISOString().substring(0, 10)
                newDates.dropDownText = 'User Defined'
                setDateRange(newDates)
              }}
              renderInput={(params) => (
                <TextField
                  sx={{
                    margin: '0px',
                    width: '100%',
                    height: '100%',
                    borderRadius: '0px',
                    svg: { color: getTextColor() },
                  }}
                  variant='standard'
                  {...params}
                  helperText={null}
                />
              )}
              sx={{ width: '150px' }}
            />
          </Box>
        </LocalizationProvider>
        <Box sx={{ flex: 0.25, marginLeft: '10px', marginRight: '10px' }}>
          <DropDownPicker
            listOptions={[
              { label: 'All', value: 'All' },
              { label: 'Current Month', value: 'Current Month' },
              { label: 'Previous Month', value: 'Previous Month' },
              { label: 'Last 30 Days', value: 'Last 30 Days' },
              { label: 'Last 60 Days', value: 'Last 60 Days' },
              { label: 'Last 6 Months', value: 'Last 6 Months' },
              { label: 'Current Year', value: 'Current Year' },
              { label: 'Last Year', value: 'Last Year' },
              { label: 'User Defined', value: 'User Defined' },
            ]}
            value={dateRange.dropDownText}
            onChange={(newValue) => {
              const { dateFrom, dateTo } = getDateFromAndToFilters(newValue)
              let newDates = { ...dateRange }

              newDates.dateFrom = dateFrom
              newDates.dateTo = dateTo
              newDates.dropDownText = newValue

              setDateRange(newDates)
            }}
          />
        </Box>
        <SearchBar
          value={searchText}
          onChange={(newSearchTerm) => setSearchText(newSearchTerm)}
          onCancelSearch={() => setSearchText('')}
          style={searchBarStyle()}
        />
      </Box>
      <div className={getAgGridTheme()} style={{ width: '100%', height: '100%' }}>
        <AgGridReact
          rowData={userData}
          loading={isLoading || isLoadingStats}
          columnDefs={sortColDefs(columnDefs, 'wellSeekerUsersGrid')}
          defaultColDef={defaultColDef}
          getRowId={getRowId}
          animateRows={true}
          enableBrowserTooltips={true}
          gridOptions={gridOptions}
          onGridReady={onGridReady}
          onFirstDataRendered={onFirstDataRendered}
          onFilterChanged={onFilterChanged}
          getContextMenuItems={getContextMenuItems}
          isExternalFilterPresent={isExternalFilterPresent}
          doesExternalFilterPass={doesExternalFilterPass}
          headerHeight={30}
          groupDisplayType={'groupRows'}
          groupRowsSticky={true}
          groupRowRendererParams={groupRowRendererParams}
        />
      </div>
      {status?.show ? (
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={status?.show}
          autoHideDuration={2000}
          onClose={handleCloseStatus}>
          <Alert onClose={handleCloseStatus} severity={status.severity} elevation={4} variant='filled'>
            {status.message}
          </Alert>
        </Snackbar>
      ) : null}
      <StyledMenuIcon>
        <MenuButton
          actions={[
            {
              icon: <StyledListIcon icon='file-icons:microsoft-excel' color={'green'} />,
              name: 'Export usage tracking',
              onClick: () => {
                onXlsxExportUsageTracking()
              },
            },
            {
              icon: <RefreshIcon />,
              name: 'Refresh',
              onClick: () => {
                fetchUsageData()
                fetchUsageStats()
              },
            },
            {
              icon: (
                <Iconify
                  icon={showChart ? 'bxs:hide' : 'bxs:show'}
                  style={{ color: appColors.itemTextColor, width: 28, height: 28 }}
                />
              ),
              name: showChart ? 'Hide Charts' : 'Show Charts',
              onClick: () => setShowChart(!showChart),
            },
          ]}
        />
      </StyledMenuIcon>
    </Box>
  )
}

export default WellSeekerUsersGrid
