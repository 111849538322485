import { useEffect, useState, useRef } from 'react'
import useInnovaAxios from './useInnovaAxios'
import cloneDeep from 'lodash/cloneDeep'

const useUpdateWellInfo = () => {
  const _isMounted = useRef(false)
  const [isLoading, setIsLoading] = useState(false)
  const [hasDistricts, setHasDistricts] = useState(false)
  const [hasCountrys, setHasCountrys] = useState(false)
  const isLoadingRef = useRef(false)
  const isUpdatingRef = useRef(false)
  const dropDowns = useRef({})

  useEffect(() => {
    _isMounted.current = true
    getDropDowns()
    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const fetchDropDowns = useInnovaAxios({
    url: '/well/wellInfo/getWellInfoDropDowns',
  })

  const fetchWellInfo = useInnovaAxios({
    url: '/well/wellInfo/getWellInfo',
  })

  const updateWellInfo = useInnovaAxios({
    url: '/well/wellInfo/updateWellInfo',
  })

  async function getDropDowns() {
    if (isLoadingRef.current) return

    isLoadingRef.current = true
    setIsLoading(true)
    const response = await fetchDropDowns()
    isLoadingRef.current = true
    setIsLoading(true)

    if (response.error) return
    if (!response.data) return
    dropDowns.current = cloneDeep(response.data)
    if (dropDowns.current.hasOwnProperty('district') && dropDowns.current.district?.length > 0) {
      setHasDistricts(true)
    }

    if (dropDowns.current.hasOwnProperty('states') && dropDowns.current.states?.length === 0) {
      setHasCountrys(true)
    }
  }

  const getDropDownList = (param, state) => {
    if (typeof param !== 'string') return []
    if (param === '') return []

    if (param === 'countys') {
      if (!dropDowns.current.hasOwnProperty('states')) return []
      if (!Array.isArray(dropDowns.current.states)) return []
      if (typeof state !== 'string') return []
      let index = dropDowns.current.states.findIndex((st) => st.stateName === state)
      if (index < 0) return []
      if (!Array.isArray(dropDowns.current.states[index].countys)) return []
      return cloneDeep(dropDowns.current.states[index].countys)
    }

    if (param === 'states') {
      if (!dropDowns.current.hasOwnProperty('states')) return []
      if (!Array.isArray(dropDowns.current.states)) return []

      if (dropDowns.current.states.length === 0) return Array.isArray(dropDowns.current?.countrys) ? dropDowns.current.countrys : []

      let statesDropDown = []
      for (let i = 0; i < dropDowns.current.states.length; i++) {
        statesDropDown.push(dropDowns.current.states[i].stateName)
      }

      return statesDropDown
    }

    if (!dropDowns.current.hasOwnProperty(param)) return []
    if (!Array.isArray(dropDowns.current[param])) return []
    return cloneDeep(dropDowns.current[param])
  }

  const handleUpdate = async (data) => {
    if (!data || !data.hasOwnProperty('actualWell')) return { isError: true, message: 'data not valid' }
    if (isUpdatingRef.current) return { isError: true, message: 'update in progress' }
    isUpdatingRef.current = true

    let getResponse = await fetchWellInfo({ wellName: data.actualWell })
    if (getResponse.error || !getResponse.data) {
      isUpdatingRef.current = false
      return { isError: true, message: getResponse?.error?.response?.data?.error }
    }

    let wellData = cloneDeep(getResponse.data)
    wellData.rig = data.rig
    wellData.wellStatus = data.wellStatus
    wellData.state = data.state
    wellData.county = data.county
    wellData.ddCoordinator = data.ddCoordinator
    wellData.mwdCoordinator = data.mwdCoordinator
    wellData.jobNum = data.jobNum
    wellData.district = data.district
    wellData.description = data.description

    //This happens if there are no daily reports for the well
    if (!wellData.wellName) {
      wellData.wellName = data.actualWell
    }

    let updateResponse = await updateWellInfo({ ...wellData })
    isUpdatingRef.current = false

    if (updateResponse.error || !updateResponse.data) {
      return { isError: true, message: updateResponse?.error?.response?.data?.error }
    }

    return { isError: false, message: '' }
  }

  return {
    getDropDownList,
    isLoading,
    handleUpdate,
    hasCountrys,
    hasDistricts,
  }
}

export default useUpdateWellInfo
