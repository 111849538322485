import React, { useMemo, useRef, useState, useCallback, useEffect } from 'react'
import { PAGE_KEYS } from 'components/ActionBar/pageDefs'
import { currentPageAtom, currentWellAtom, actionBarWidthAtom } from 'atoms'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import useToolOrders from 'components/common/hooks/useToolOrders'
import { styled } from '@mui/styles'
import MenuButton from 'components/common/MenuButton'
import RefreshIcon from '@mui/icons-material/Refresh'
import AddIcon from '@mui/icons-material/Add'
import { wellListAtom } from 'atoms'
import useUnits, { UNITS_FOR } from 'components/common/hooks/useUnits'
import { Snackbar, Alert, Box, Tooltip, IconButton } from '@mui/material'
import { AgGridReact } from 'ag-grid-react'
import {
  htmlSymbolHandling,
  sortColDefs,
  relativeTime,
  dateComparator,
  isDateLessThan,
  CustomLoadingOverlay,
  getStringId,
} from 'components/common/AgGridUtils'
import { saveItemToLS } from 'utils/localStorage'
import { unescapeHtml } from 'utils/htmlSymbolHandling'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import { Icon as Iconify } from '@iconify/react'
import ConfirmDialog from 'components/common/ConfirmDialog'
import { cloneDeep } from 'lodash'
import ToolOrderDetailsGrid from './ToolOrderDetailsGrid'
import { getUserNameFromEmail } from 'utils/chatFunctions'
import useUpdateWellInfo from 'components/common/hooks/useUpdateWellInfo'
import { debounce } from 'lodash'
import SearchBar from 'components/common/SearchBar'
import { getDeltaIsoDays } from 'utils/dateTimeFunctions'
import ToolOrdersDropDownsModal from './ToolOrdersDropDownsModal'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const StyledMenuIcon = styled(Box)(({ theme }) => ({
  backgroundColor: 'transparent',
  margin: '4px',
  padding: '12px',
  position: 'fixed',
  bottom: theme.spacing(2),
  right: theme.spacing(2),
  zIndex: 2,
}))

const ToolOrdersPage = () => {
  const _isMounted = useRef(false)
  const setActivePage = useSetRecoilState(currentPageAtom)
  const {
    loading: isLoading,
    fetchToolOrders: refreshToolOrdersData,
    getToolOrdersData,
    getToolOrderDropDowns,
    getToolDetailDropDowns,
    fetchToolDetailsDropDown,
  } = useToolOrders()
  const currentWell = useRecoilValue(currentWellAtom)
  const [toolOrdersData, setToolOrdersData] = useState([])
  const leftPos = useRecoilValue(actionBarWidthAtom)
  const gridApi = useRef(null)
  const [resetCols, setResetCols] = useState(false)
  const isAdding = useRef(false)
  const [status, setStatus] = useState({ show: false, severity: 'info', message: '' })
  const isUpdating = useRef(false)
  const { getUnitsText } = useUnits()
  const selectedComponent = useRef({})
  const [confirm, setConfirm] = useState({ show: false, title: '' })
  const isDeleting = useRef(false)
  const wellsList = useRecoilValue(wellListAtom)
  const wellsListRef = useRef()
  const containerRef = useRef(null)
  const [searchText, setSearchText] = useState('')
  const [showEditDropDownsModal, setShowEditDropDownsModal] = useState(false)
  const { getDropDownList } = useUpdateWellInfo()
  const { getAgGridTheme, searchBarStyle } = useInnovaTheme()

  const addToolOrder = useInnovaAxios({
    url: '/toolOrders/addToolOrder',
  })

  const updateToolOrder = useInnovaAxios({
    url: '/toolOrders/updateToolOrder',
  })

  const deleteToolOrder = useInnovaAxios({
    url: '/toolOrders/deleteToolOrder',
  })

  useEffect(() => {
    _isMounted.current = true
    setActivePage(PAGE_KEYS.toolOrdersKey)
    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (_isMounted.current) {
      wellsListRef.current = wellsList
    }
  }, [wellsList])

  useEffect(() => {
    if (!_isMounted.current || !currentWell) return
    setToolOrdersData(getToolOrdersData())
  }, [isLoading, currentWell]) // eslint-disable-line react-hooks/exhaustive-deps

  const getMenuItems = () => {
    return [
      {
        icon: <RefreshIcon />,
        name: 'Refresh',
        onClick: () => {
          refreshToolOrdersData()
        },
      },
      {
        icon: <AddIcon />,
        name: 'Add',
        onClick: () => {
          handleAddRow()
        },
      },
      {
        icon: <Iconify icon='mdi:form-dropdown' fontSize={24} />,
        name: 'Edit drop down lists',
        onClick: () => {
          setShowEditDropDownsModal(true)
        },
      },
    ]
  }

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.toolOrderId)
    }
  }, [])

  const centerAlignCell = () => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  })

  const leftAlignCell = () => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
  })

  const handleAddRow = async () => {
    if (isAdding.current) return
    if (!gridApi.current) return
    if (!wellsListRef.current) return

    isAdding.current = true
    let res = await addToolOrder(htmlSymbolHandling({ status: 'Draft' }))
    isAdding.current = false

    if (!_isMounted.current) return

    if (res?.error && _isMounted.current) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })
      return
    }

    if (!res.data) return
    gridApi.current?.applyTransaction({
      add: [res.data],
      addIndex: 0,
    })
  }

  const handleUpdate = useCallback(
    async (data) => {
      if (!data) return
      if (!gridApi.current) return
      if (!data.hasOwnProperty('toolOrderId')) return
      if (data?.toolOrderId < 0) return
      if (isUpdating.current) return

      isUpdating.current = true
      let res = await updateToolOrder(htmlSymbolHandling(data))
      isUpdating.current = false

      if (!_isMounted.current) return

      if (res?.error) {
        setStatus({
          show: true,
          severity: 'error',
          message: `${res?.error?.response?.data?.error}`,
        })
        return
      }

      if (!res.data) return
      gridApi.current.applyTransaction({
        update: [res.data],
      })

      autoSizeColumns()
    },
    [updateToolOrder],
  )

  const defaultColDef = useMemo(() => {
    return {
      suppressKeyboardEvent: (params) => {
        if (!params.editing) {
          let isBackspaceKey = params.event.keyCode === 8
          let isDeleteKey = params.event.keyCode === 46

          if (isBackspaceKey || isDeleteKey) {
            const focusedCell = params.api.getFocusedCell()
            if (!focusedCell?.column?.colDef.editable) return false
            params.data[focusedCell?.column?.colDef?.field] = ''
            handleUpdate(cloneDeep(params.data))
          }
        }
        return false
      },
      resizable: true,
      sortable: true,
      editable: (params) => {
        if (params?.node?.rowPinned === 'bottom') {
          return params?.colDef?.field === 'actualWell' ? true : false
        }
        return true
      },
      suppressHeaderMenuButton: false,
      cellStyle: centerAlignCell,
      headerClass: 'header-no-padding',
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
    }
  }, [handleUpdate])

  const gridOptions = {
    suppressRowClickSelection: true,
    sideBar: {
      toolPanels: [
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        },
      ],
      defaultToolPanel: '',
      position: 'left',
    },
    onColumnVisible: (params) => {
      saveColumnState()
    },
    onDragStopped: (params) => {
      saveColumnState()
    },
    onCellEditingStopped: (params) => {
      if (params?.newValue === params?.oldValue) return
      handleUpdate(params.data)
    },
    loadingOverlayComponent: CustomLoadingOverlay,
  }

  const saveColumnState = () => {
    if (gridApi.current) {
      const colLayout = gridApi.current?.getColumnState()
      if (colLayout) saveItemToLS('toolOrdersGrid', 'colLayout', colLayout)
    }
  }

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const onFirstDataRendered = (params) => {
    autoSizeColumns()
  }

  const autoSizeColumns = () => {
    if (!gridApi.current) return
    gridApi.current?.autoSizeAllColumns()
  }

  const getContextMenuItems = (params) => {
    return [
      {
        name: 'Reset columns',
        disabled: false,
        action: () => {
          gridApi.current.resetColumnState()
          saveItemToLS('toolOrdersGrid', 'colLayout', null)
          setResetCols(!resetCols)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Reset filters',
        disabled: false,
        action: () => {
          gridApi.current.setFilterModel(null)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      'copy',
      {
        name: 'Export',
        disabled: false,
        action: () => {
          gridApi.current.exportDataAsExcel({
            fileName: 'ToolOrders.xlsx',
            sheetName: 'ToolOrders',
          })
        },
        icon: '<span class="iconify" data-icon="icomoon-free:file-excel" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
    ]
  }

  const dateTimeFormatter = useCallback((value) => {
    if (!value) return ''
    if (typeof value !== 'string') return ''
    if (value === '') return ''
    if (isDateLessThan(value, '1990-01-01')) return ''
    return new Date(Date.parse(value.slice(0, 10) + 'T00:00:00')).toLocaleDateString('default', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    })
  }, [])

  const onClickDelete = (data) => {
    selectedComponent.current = cloneDeep(data)
    setConfirm({
      show: true,
      title: 'Delete Tool Order',
      text: `Are you sure you want to delete ${data?.actualWell === '' ? '' : data?.actualWell}?`,
    })
  }

  const debounceSetQuickFilter = debounce((text) => {
    if (gridApi.current) {
      gridApi.current.setGridOption('quickFilterText', text)
    }
  }, 300)

  useEffect(() => {
    debounceSetQuickFilter(searchText)
  }, [searchText]) // eslint-disable-line react-hooks/exhaustive-deps

  const dateFilterComparator = useCallback(dateComparator, [])

  const actionIconRenderer = useCallback((params) => {
    return (
      <Box style={{ display: 'flex', flexDirection: 'row' }}>
        <Tooltip
          title={params?.node?.rowPinned === 'bottom' ? 'Add' : 'Delete'}
          placement='left'
          componentsProps={{
            tooltip: {
              sx: {
                backgroundColor: 'rgb(19,62,96)',
                fontSize: '12px',
                fontFamily: 'Roboto',
              },
            },
          }}>
          <IconButton
            style={{ padding: '5px', alignItems: 'center', justifyContent: 'center' }}
            onClick={() => {
              onClickDelete(params?.data)
            }}
            size='large'>
            <Iconify color='red' icon='fa-regular:trash-alt' fontSize={16} />
          </IconButton>
        </Tooltip>
      </Box>
    )
  }, [])

  const relativeTimeFormatter = useCallback(relativeTime, [])

  const columnDefs = useMemo(
    () => [
      {
        headerName: '',
        field: 'toolDetail',
        colId: 'toolDetail',
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
        sortable: false,
        editable: false,
        filter: null,
        pinned: 'left',
        lockPosition: 'left',
        cellRenderer: 'agGroupCellRenderer',
        width: 50,
      },
      {
        headerName: '',
        field: 'actions',
        colId: 'actions',
        width: 70,
        pinned: 'left',
        lockPosition: 'left',
        editable: false,
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
        resizable: false,
        filter: null,
        cellStyle: leftAlignCell,
        cellRenderer: actionIconRenderer,
      },
      {
        headerName: 'Operator',
        field: 'operatorName',
        colId: 'operatorName',
        editable: (params) => {
          if (!params?.data?.actualWell) return true
          if (params?.data?.actualWell === '') return true
          return false
        },
        cellStyle: leftAlignCell,
      },
      {
        headerName: 'Well',
        colId: 'actualWell',
        field: 'actualWell',
        cellEditor: 'agRichSelectCellEditor',
        cellEditorPopup: true,
        pinned: 'left',
        lockPosition: 'left',
        cellStyle: leftAlignCell,
        cellEditorParams: (params) => {
          return { values: Array.isArray(wellsList) ? wellsList.map((well) => well.actualWell) : [] }
        },
        valueSetter: (params) => {
          let newData = cloneDeep(params.data)
          newData.actualWell = params.newValue

          if (Array.isArray(wellsListRef.current)) {
            let wellData = wellsListRef.current.find((well) => well?.actualWell === newData?.actualWell)
            newData.operatorName = wellData ? wellData.operator : ''
            newData.rig = wellData ? wellData.rig : ''
            newData.jobNum = wellData ? wellData?.jobNum : ''
            newData.state = wellData ? wellData?.state : ''
            newData.county = wellData ? wellData?.county : ''
            newData.ddCoordinator = wellData ? wellData?.ddCoordinator : ''
            newData.mwdCoordinator = wellData ? wellData?.mwdCoordinator : ''
          }

          handleUpdate(newData)
        },
      },
      {
        headerName: 'Job #',
        colId: 'jobNum',
        field: 'jobNum',
        pinned: 'left',
        lockPosition: 'left',
        editable: (params) => {
          if (!params?.data?.actualWell) return true
          if (params?.data?.actualWell === '') return true
          return false
        },
        cellStyle: leftAlignCell,
      },
      {
        headerName: 'Rig',
        colId: 'rig',
        field: 'rig',
        editable: false,
        cellStyle: leftAlignCell,
      },

      {
        headerName: 'State',
        colId: 'state',
        field: 'state',
        editable: false,
        cellStyle: leftAlignCell,
      },
      {
        headerName: 'County',
        colId: 'county',
        field: 'county',
        editable: false,
        cellStyle: leftAlignCell,
      },
      {
        headerName: 'Status',
        field: 'status',
        colId: 'status',
        cellEditor: 'agSelectCellEditor',
        cellStyle: (params) => {
          let color = '#59FF00CF'
          if (params?.data?.status === 'Draft') color = '#429CEB'
          if (params?.data?.status === 'Submitted') color = '#FF00FF'
          if (params?.data?.status === 'In Progress') color = '#F59B00'
          if (params?.data?.status === 'Complete' || params?.data?.status === 'Finished') color = '#59FF00CF'
          if (params?.data?.status === 'Shipped') color = '#FFFFFF'
          if (params?.data?.status === 'Request from rig') color = '#FF0000'

          return {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'start',
            color: color,
          }
        },
        cellEditorParams: (params) => {
          const { status } = getToolOrderDropDowns()
          return { values: Array.isArray(status) ? status : [] }
        },
      },
      {
        headerName: 'DD Coordinator',
        colId: 'ddCoordinator',
        field: 'ddCoordinator',
        editable: (params) => {
          if (!params?.data?.actualWell) return true
          if (params?.data?.actualWell === '') return true
          return false
        },
        cellStyle: leftAlignCell,
        cellEditor: 'agRichSelectCellEditor',
        cellEditorPopup: true,
        cellEditorParams: (params) => {
          return { values: getDropDownList('ddCoordinators').sort() }
        },
      },
      {
        headerName: 'MWD Coordinator',
        field: 'mwdCoordinator',
        colId: 'mwdCoordinator',
        editable: (params) => {
          if (!params?.data?.actualWell) return true
          if (params?.data?.actualWell === '') return true
          return false
        },
        cellStyle: leftAlignCell,
        cellEditor: 'agRichSelectCellEditor',
        cellEditorPopup: true,
        cellEditorParams: (params) => {
          return { values: getDropDownList('mwdCoordinators').sort() }
        },
      },
      {
        headerName: `Hole Size (${getUnitsText(UNITS_FOR.Diameter)})`,
        colId: 'holeSize',
        field: 'holeSize',
        valueFormatter: (params) => {
          return !params?.value ? '' : params.value
        },
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 2000,
          precision: 3,
        },
      },
      { headerName: 'Mud Type', colId: 'mudType', field: 'mudType' },
      {
        headerName: `Mud Weight (${getUnitsText(UNITS_FOR.MudWeight)})`,
        colId: 'mudWeight',
        field: 'mudWeight',
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 1000,
          precision: 2,
        },
        valueFormatter: (params) => {
          return !params.value ? '' : params.value
        },
      },
      {
        headerName: `Min Temp (${getUnitsText(UNITS_FOR.Temperature)})`,
        colId: 'minTemp',
        field: 'minTemp',
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 100000,
          precision: 2,
        },
        valueFormatter: (params) => {
          return !params.value ? '' : params.value
        },
      },
      {
        headerName: `Max Temp (${getUnitsText(UNITS_FOR.Temperature)})`,
        colId: 'maxTemp',
        field: 'maxTemp',
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 100000,
          precision: 2,
        },
        valueFormatter: (params) => {
          return !params.value ? '' : params.value
        },
      },
      {
        headerName: 'DP Cnx',
        colId: 'drillPipeConnection',
        field: 'drillPipeConnection',
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: (params) => {
          let dropDowns = getToolDetailDropDowns('CONNECTION_TYPE')
          return { values: Array.isArray(dropDowns) ? dropDowns : [] }
        },
      },
      {
        headerName: 'Created By',
        colId: 'createdBy',
        field: 'createdBy',
        editable: false,
        valueFormatter: (params) => getUserNameFromEmail(params.value),
      },
      {
        headerName: 'Modified By',
        colId: 'modifiedBy',
        field: 'modifiedBy',
        editable: false,
        valueFormatter: (params) => getUserNameFromEmail(params.value),
      },
      {
        headerName: 'Date Created',
        colId: 'dateCreated',
        field: 'dateCreated',
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: dateFilterComparator,
        },
        editable: false,
        valueFormatter: (params) => dateTimeFormatter(params?.value),
      },
      {
        headerName: 'Date Modified',
        colId: 'dateModified',
        field: 'dateModified',
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: dateFilterComparator,
        },
        editable: false,
        valueFormatter: (params) => dateTimeFormatter(params?.value),
      },
      {
        headerName: 'Needed By',
        colId: 'dateNeeded',
        field: 'dateNeeded',
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: dateFilterComparator,
        },
        cellEditor: 'agDateStringCellEditor',
        cellEditorParams: {
          min: '1950-01-01',
          max: '2200-01-01',
        },
        valueFormatter: (params) => dateTimeFormatter(params?.value),
        valueGetter: (params) => {
          if (!params.value) return ''
        },
      },
      {
        headerName: 'Needed In',
        colId: 'neededDayCount',
        editable: false,
        filter: null,
        valueFormatter: (params) => {
          return relativeTimeFormatter(params.data?.dateNeeded)
        },
        cellStyle: (params) => {
          let dueDays = getDeltaIsoDays(new Date(Date.now()).toISOString(), params?.data?.dateNeeded)
          let color = '#00ff00'
          if (dueDays <= 7) color = 'orange'
          if (dueDays <= 0) color = '#ff0000'

          return {
            color: color,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }
        },
      },
      {
        headerName: 'Comments',
        colId: 'comments',
        field: 'comments',
        filter: null,
        sortable: false,
        cellEditor: 'agLargeTextCellEditor',
        cellEditorPopup: true,
        cellEditorParams: {
          maxLength: 2000,
          rows: 10,
          cols: 50,
        },
        valueGetter: (params) => unescapeHtml(params?.data?.comments),
        cellStyle: leftAlignCell,
      },
      {
        headerName: 'Tool Comments',
        colId: 'toolComments',
        field: 'toolComments',
        filter: null,
        sortable: false,
        valueGetter: (params) => unescapeHtml(params?.data?.toolComments),
        cellEditor: 'agLargeTextCellEditor',
        cellEditorPopup: true,
        cellEditorParams: {
          maxLength: 2000,
          rows: 10,
          cols: 50,
        },
        cellStyle: leftAlignCell,
      },
      {
        headerName: 'Motor Comments',
        colId: 'motorComments',
        field: 'motorComments',
        filter: null,
        sortable: false,
        valueGetter: (params) => unescapeHtml(params?.data?.motorComments),
        cellEditor: 'agLargeTextCellEditor',
        cellEditorPopup: true,
        cellEditorParams: {
          maxLength: 2000,
          rows: 10,
          cols: 50,
        },
        cellStyle: leftAlignCell,
      },
    ],
    [
      dateTimeFormatter,
      dateFilterComparator,
      getToolOrderDropDowns,
      getDropDownList,
      getUnitsText,
      handleUpdate,
      relativeTimeFormatter,
      wellsList,
      getToolDetailDropDowns,
      actionIconRenderer,
    ],
  )

  const handleCloseStatus = () => {
    setStatus({ show: false, severity: 'info', message: '' })
  }

  const handleDelete = async () => {
    if (!selectedComponent.current) return
    if (!selectedComponent.current.hasOwnProperty('toolOrderId')) return
    if (typeof selectedComponent.current?.toolOrderId !== 'number') return
    if (selectedComponent.current?.toolOrderId < 0) return
    if (isDeleting.current) return
    if (!gridApi.current) return

    isDeleting.current = true
    let res = await deleteToolOrder({ toolOrderId: selectedComponent.current.toolOrderId })
    isDeleting.current = false

    if (!_isMounted.current) return

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })
    }

    if (!res?.error) {
      gridApi.current.applyTransaction({
        remove: [selectedComponent.current],
      })
    }

    selectedComponent.current = null
    return
  }

  const DetailCellRenderer = (params) => {
    let height = 200
    if (containerRef.current) height = containerRef.current.clientHeight * 0.5

    return (
      <Box sx={{ height: height }}>
        <ToolOrderDetailsGrid
          toolOrderId={params?.data?.toolOrderId}
          getDropDowns={getToolDetailDropDowns}
          setStatus={setStatus}
        />
      </Box>
    )
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        height: 'calc(100vh - 64px)',
        marginLeft: `${leftPos}px`,
        width: `calc(100%-${leftPos}px)`,
        maxWidth: `calc(100%-${leftPos}px)`,
        maxHeight: 'calc(100vh - 64px)',
      }}>
      {showEditDropDownsModal ? (
        <ToolOrdersDropDownsModal
          setOpen={() => {
            fetchToolDetailsDropDown()
            setShowEditDropDownsModal(false)
          }}
        />
      ) : null}
      {confirm.show ? (
        <ConfirmDialog
          title={confirm?.title}
          open={confirm?.show}
          setOpen={() => setConfirm({ show: false })}
          onConfirm={() => handleDelete()}>
          {confirm?.text}
        </ConfirmDialog>
      ) : null}
      <Box
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
          width: '100%',
        }}>
        <SearchBar
          value={searchText}
          onChange={(newSearchTerm) => setSearchText(newSearchTerm)}
          onCancelSearch={() => setSearchText('')}
          style={searchBarStyle()}
        />
      </Box>
      <div
        ref={containerRef}
        style={{ height: '100%', width: '100%', overflowX: 'visible' }}
        className={getAgGridTheme()}>
        <AgGridReact
          rowData={toolOrdersData}
          loading={isLoading}
          headerHeight={30}
          animateRows={true}
          getRowId={getRowId}
          defaultColDef={defaultColDef}
          gridOptions={gridOptions}
          onGridReady={onGridReady}
          onFirstDataRendered={onFirstDataRendered}
          getContextMenuItems={getContextMenuItems}
          columnDefs={sortColDefs(columnDefs, 'toolOrdersGrid')}
          masterDetail={true}
          detailCellRenderer={DetailCellRenderer}
          detailRowAutoHeight={true}
        />
      </div>
      {status?.show ? (
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={status?.show}
          autoHideDuration={2000}
          onClose={handleCloseStatus}>
          <Alert onClose={handleCloseStatus} severity={status.severity} elevation={4} variant='filled'>
            {status.message}
          </Alert>
        </Snackbar>
      ) : null}
      <StyledMenuIcon>
        <MenuButton actions={getMenuItems()} />
      </StyledMenuIcon>
    </Box>
  )
}

export default ToolOrdersPage
