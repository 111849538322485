import { useEffect, useState, useRef } from 'react'

import useInnovaAxios from '../useInnovaAxios'
import { useRecoilValue } from 'recoil'
import { currentWellAtom, currentBhaAtom, currentSelEngBhaHeadersAtom } from 'atoms'
import { normalize } from 'utils/numberFunctions.js'
import { lerpColor } from 'utils/colorFunctions'

const useWitsDrillingData = () => {
  const _isMounted = useRef(true)
  const witsData = useRef({})
  const isLoading = useRef(false)
  const [loading, setLoading] = useState(false)
  const currentWell = useRecoilValue(currentWellAtom)
  const currentWellRef = useRef(currentWell)
  const currentBha = useRecoilValue(currentBhaAtom)
  const currentSelBha = useRecoilValue(currentSelEngBhaHeadersAtom)

  const getData = useInnovaAxios({
    url: '/engineering/getWitsDrillingData',
  })

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
    }
  }, [])

  useEffect(() => {
    currentWellRef.current = currentWell
    fetchWitsDrillingData()
  }, [currentWell]) // eslint-disable-line react-hooks/exhaustive-deps

  const fetchWitsDrillingData = async () => {
    if (typeof currentWellRef.current !== 'string') return
    if (currentWellRef.current === '') return
    if (isLoading.current) return
    isLoading.current = true
    if (_isMounted.current) setLoading(true)

    const response = await getData({ wellName: currentWellRef.current })

    if (_isMounted.current) {
      setLoading(false)
      witsData.current = response?.data ? response.data : null
    }

    isLoading.current = false
  }

  const interpChartColor = (pallete, index, maxSeries) => {
    let bluePalette = ['#006cd1', '#00deff']
    let redPalette = ['#fa6e7a', '#fa000c']
    let greenPalette = ['#93fab4', '#8ee800']
    let yellowPalette = ['#fffb05', '#ffa505']
    let purplePalette = ['#efbbff', '#630063']

    let startColor = bluePalette[0]
    let endColor = bluePalette[1]
    if (pallete === 'green') {
      startColor = greenPalette[0]
      endColor = greenPalette[1]
    }

    if (pallete === 'red') {
      startColor = redPalette[0]
      endColor = redPalette[1]
    }

    if (pallete === 'purple') {
      startColor = purplePalette[0]
      endColor = purplePalette[1]
    }

    if (pallete === 'yellow') {
      startColor = yellowPalette[0]
      endColor = yellowPalette[1]
    }

    let ratio = normalize(index, 0, maxSeries)
    return lerpColor(startColor, endColor, ratio)
  }

  function adjustHexOpacity(hexColor, alpha) {
    // Remove the leading '#' if present
    const sanitizedColor = hexColor.replace('#', '')

    // Convert the hex color to RGBA
    const red = parseInt(sanitizedColor.substring(0, 2), 16)
    const green = parseInt(sanitizedColor.substring(2, 4), 16)
    const blue = parseInt(sanitizedColor.substring(4, 6), 16)

    // Convert the alpha value from a range of 0-100 to a range of 0-255
    const alphaValue = Math.round((alpha / 100) * 255)

    // Convert RGBA values to a new hex string
    const updatedHex = `#${((1 << 24) + (red << 16) + (green << 8) + blue).toString(16).slice(1)}${alphaValue.toString(
      16,
    )}`

    return updatedHex
  }

  const getTagsForChart = (chartType) => {
    if (chartType === null || chartType === undefined) return []
    if (typeof chartType !== 'string') return []
    if (chartType === 'pumpPressure') return ['onBtmPress', 'offBtmPress']
    if (chartType === 'drillersTorques') return ['onBtmTq', 'offBtmTq']
    if (chartType === 'drillersHookloads') return ['puWt', 'rotWt', 'soWt', 'tripOutWt', 'tripInWt']
    if (chartType === 'criticalRpmChart') return ['rpm', 'wob']
    return []
  }

  const getSeriesDetails = (tag) => {
    if (tag === null || tag === undefined) return { title: 'unknown', color: 'red' }
    if (typeof tag !== 'string') return { title: 'unknown', color: 'red' }
    if (tag === 'offBtmPress') return { title: 'Off Btm Press (WITS)', color: 'red' }
    if (tag === 'onBtmPress') return { title: 'On Btm Press (WITS)', color: 'blue' }
    if (tag === 'puWt') return { title: 'P/U Weight (WITS)', color: 'blue' }
    if (tag === 'soWt') return { title: 'S/O Weight (WITS)', color: 'red' }
    if (tag === 'rotWt') return { title: 'Rot Weight (WITS)', color: 'green' }
    if (tag === 'onBtmTq') return { title: 'On Btm Torque (WITS)', color: 'red' }
    if (tag === 'offBtmTq') return { title: 'Off Btm Torque (WITS)', color: 'blue' }
    if (tag === 'tripOutWt') return { title: 'Trip Out Weight (WITS)', color: 'yellow' }
    if (tag === 'tripInWt') return { title: 'Trip In Weight (WITS)', color: 'purple' }
    if (tag === 'rpm') return { title: 'RPM (WITS)', color: 'green' }
    if (tag === 'wob') return { title: 'WOB (WITS)', color: 'blue' }
    return { title: 'unknown', color: 'red' }
  }

  const getSeriesData = (series, depthLimits) => {
    let output = []
    if (!depthLimits) depthLimits = { startDepth: 0, endDepth: 100000 }
    for (let i = 0; i < series.length; i++) {
      if (!isDataPtBhaSelected(series[i].bhaNum)) continue
      if (series[i].depth < depthLimits.startDepth) continue
      if (series[i].depth > depthLimits.endDepth) continue
      output.push({
        x: series[i].value,
        y: series[i].depth,
      })
    }

    return output
  }

  const isDataPtBhaSelected = (bhaNum) => {
    if (bhaNum === null || bhaNum === undefined) return false
    if (typeof bhaNum === 'string') bhaNum = parseInt(bhaNum)
    if (bhaNum === currentBha.bhaNum) return true
    if (Array.isArray(currentSelBha)) {
      if (currentSelBha.findIndex((bha) => bha === bhaNum) >= 0) return true
    }

    return false
  }

  const getWitsDrillingData = (chartType, depthLimits) => {
    let chartData = []

    if (chartType === null || chartType === undefined) return chartData
    if (typeof chartType !== 'string') return chartData
    let tags = getTagsForChart(chartType)
    if (!Array.isArray(tags)) return chartData
    if (tags.length === 0) return chartData

    if (!witsData.current) return chartData

    for (let i = 0; i < tags.length; i++) {
      if (!witsData.current.hasOwnProperty(tags[i])) continue
      if (!Array.isArray(witsData.current[tags[i]])) continue

      const { title, color } = getSeriesDetails(tags[i])

      chartData.push({
        label: title,
        borderColor: interpChartColor(color, i, 5),
        backgroundColor: interpChartColor(color, i, 5),
        pointBorderColor: adjustHexOpacity(interpChartColor(color, i, 5), 50),
        pointBackgroundColor: adjustHexOpacity(interpChartColor(color, i, 5), 50),
        showLine: false,
        fill: false,
        pointRadius: 3,
        pointHoverRadius: 5,
        pointStyle: 'circle',
        data: getSeriesData(witsData.current[tags[i]], depthLimits),
      })
    }

    return chartData
  }

  return {
    loading,
    getWitsDrillingData,
    fetchWitsDrillingData,
  }
}

export default useWitsDrillingData
