import React, { useRef, useMemo, useState, useEffect } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { Tooltip, Box, IconButton, Snackbar, Alert } from '@mui/material'
import { Icon as Iconify } from '@iconify/react'
import useUnits, { UNITS_FOR } from 'components/common/hooks/useUnits'
import { saveItemToLS } from 'utils/localStorage'
import cloneDeep from 'lodash/cloneDeep'
import { unescapeHtml } from 'utils/htmlSymbolHandling'
import { getComponentColor } from 'components/common/drillStringFunctions'
import { numberWithCommasDecimals, numberWithCommas } from 'utils/stringFunctions'
import ConfirmDialog from 'components/common/ConfirmDialog'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import { currentWellSelector } from 'atoms'
import { useRecoilValue } from 'recoil'
import { sortColDefs, htmlSymbolHandling, CustomLoadingOverlay, getStringId } from 'components/common/AgGridUtils'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const InventoryGrid = ({ loading: isLoading, data, setFilteredData, getDropDowns, onXlsxExport, importTools }) => {
  const _isMounted = useRef(false)
  const gridApi = useRef(null)
  const currentWell = useRecoilValue(currentWellSelector)
  const currentWellRef = useRef(currentWell)
  const { getUnitsText } = useUnits()
  const inputRow = useRef({})
  const pastedRows = useRef([])
  const [resetCols, setResetCols] = useState(false)
  const [confirm, setConfirm] = useState({ show: false, title: '' })
  const [status, setStatus] = useState({ show: false, severity: 'info', message: '' })
  const { getAgGridTheme } = useInnovaTheme()

  const deleteMultiInventoryItem = useInnovaAxios({
    url: '/well/toolInventory/deleteMultiInventoryItems',
  })

  const addInventoryItem = useInnovaAxios({
    url: '/well/toolInventory/addInventoryItem',
  })

  const updateInventoryItem = useInnovaAxios({
    url: '/well/toolInventory/updateMultiInventoryItem',
  })

  const addMultiInventoryItem = useInnovaAxios({
    url: '/well/toolInventory/addMultiInventoryItem',
  })

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
    }
  }, [])

  useEffect(() => {
    currentWellRef.current = currentWell
  }, [currentWell]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!gridApi.current) return
    gridApi.current.onFilterChanged()
  }, [data]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleDelete = async (items) => {
    if (!Array.isArray(items)) return
    if (items.length === 0) return
    let message = `Are you sure you want to delete ${unescapeHtml(items[0].description)}?`
    if (items.length > 1) {
      message = `Are you sure you want to delete ${items.length} items?`
    }

    setConfirm({
      show: true,
      title: 'Delete Inventory Item',
      text: message,
      params: items,
      isDelete: true,
    })
  }

  const confirmDelete = async () => {
    if (!Array.isArray(confirm.params)) return
    if (confirm.params.length === 0) return

    let uids = []
    for (let i = 0; i < confirm.params.length; i++) {
      uids.push(confirm.params[i].uid)
    }

    const deleteResponse = await deleteMultiInventoryItem({
      wellName: confirm.params[0].actualwell,
      uids: JSON.stringify(uids),
    })

    if (deleteResponse?.error) {
      setStatus({ show: true, severity: 'error', message: deleteResponse?.error?.response?.data?.error })
      return
    }

    if (gridApi.current)
      gridApi.current.applyTransaction({
        remove: uids.map((uid) => {
          return { uid: uid }
        }),
      })
    updateFilteredData()
  }

  const handleAddRowComplete = () => {
    inputRow.current = {}
    if (gridApi.current) gridApi.current.setGridOption('pinnedBottomRowData', [inputRow.current])
  }

  const handleAddRow = async (params) => {
    if (!isPinnedRowDataCompleted()) return
    let escapedData = htmlSymbolHandling(params.data)
    escapedData.wellName = currentWellRef.current

    let response = await addInventoryItem(htmlSymbolHandling(escapedData))
    if (response?.error) {
      setStatus({ show: true, severity: 'error', message: response?.error?.response?.data?.error })
      return
    }

    let newData = cloneDeep(params.data)
    newData.uid = parseInt(response.data?.uid)
    if (!newData.hasOwnProperty('serialNum')) newData.serialNum = ''
    newData.actualwell = currentWellRef.current
    newData.dateOn = ''
    newData.dateOff = ''
    newData.od = 0
    newData.id = 0
    newData.length = 0
    newData.connectionTop = ''
    newData.connectionBottom = ''
    newData.location = ''
    newData.basket = ''
    newData.onLocation = true
    newData.weight = 0
    newData.comments = ''
    newData.readyForBackload = false
    newData.failed = false
    newData.inHole = false
    newData.make = ''
    newData.model = ''
    newData.dirty = false
    newData.prevToolHours = 0
    newData.toolHoursWell = 0
    newData.totalToolHours = 0

    gridApi.current.applyTransaction({
      add: [newData],
    })

    handleAddRowComplete()
    updateFilteredData()
  }

  //Import function to allow access from the parent page should convert to forward ref
  importTools.current = async (data) => {
    if (!Array.isArray(data)) return
    if (data.length === 0) return

    let exisitingTools = []
    gridApi.current.forEachNode((node) => {
      exisitingTools.push(node.data)
    })

    let toolsToImport = []
    for (let i = 0; i < data.length; i++) {
      data[i] = htmlSymbolHandling(data[i])
      data[i].actualwell = currentWellRef.current

      let toolExists = false
      for (let j = 0; j < exisitingTools.length; j++) {
        if (
          exisitingTools[j].serialNum === data[i].serialNum &&
          exisitingTools[j].description === data[i].description
        ) {
          toolExists = true
          break
        }
      }

      if (!toolExists) toolsToImport.push(cloneDeep(data[i]))
    }

    if (toolsToImport.length === 0) return

    let response = await addMultiInventoryItem({
      tools: JSON.stringify(toolsToImport),
      wellName: currentWellRef.current,
    })
    if (response?.error) {
      setStatus({ show: true, severity: 'error', message: response?.error?.response?.data?.error })
      return
    }

    if (gridApi.current && Array.isArray(response.data)) {
      gridApi.current.applyTransaction({
        add: response.data,
      })
    }

    updateFilteredData()
  }

  const updateFilteredData = () => {
    let filteredNodes = []
    gridApi.current.forEachNodeAfterFilter((node) => {
      filteredNodes.push(node.data)
    })

    if (_isMounted.current) setFilteredData(filteredNodes)
  }

  const handleUpdate = async (data) => {
    if (!Array.isArray(data)) return
    if (data.length === 0) return

    for (let i = 0; i < data.length; i++) {
      if (data[i].dateOn?.length > 0) data[i].onLocation = true
      if (data[i].dateOn?.length > 0 && data[i].dateOff?.length > 0) {
        data[i].readyForBackload = false
        data[i].inHole = false
        data[i].onLocation = false
      }

      let prevToolHours = parseFloat(data[i].prevToolHours)
      if (isNaN(prevToolHours)) prevToolHours = 0

      data[i].totalToolHours = parseFloat(prevToolHours) + parseFloat(data[i].toolHoursWell)
      if (data[i].totalToolHours > 0) data[i].dirty = true

      data[i].od = parseFloat(data[i].od)
      data[i].id = parseFloat(data[i].id)
      data[i].length = parseFloat(data[i].length)
      data[i].weight = parseFloat(data[i].weight)
      data[i].prevToolHours = prevToolHours
      data[i].toolHoursWell = parseFloat(data[i].toolHoursWell)
      data[i].totalToolHours = parseFloat(data[i].totalToolHours)
      data[i].sequenceNo = parseInt(data[i].sequenceNo)

      data[i] = htmlSymbolHandling(data[i])
    }

    let response = await updateInventoryItem({ invData: JSON.stringify(data), wellName: currentWellRef.current })
    if (response?.error) {
      setStatus({ show: true, severity: 'error', message: response?.error?.response?.data?.error })
      return
    }

    gridApi.current.applyTransaction({
      update: data,
    })
    updateFilteredData()
  }

  const centerAlignCell = () => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  })

  const actionIconRenderer = (params) => {
    return (
      <React.Fragment>
        <Box style={{ display: 'flex', flexDirection: 'row' }}>
          <Tooltip
            title={params.node?.rowPinned !== 'bottom' ? 'Delete' : 'Add'}
            placement='left'
            componentsProps={{
              tooltip: {
                sx: {
                  backgroundColor: 'rgb(19,62,96)',
                  fontSize: '12px',
                  fontFamily: 'Roboto',
                },
              },
            }}>
            <IconButton
              sx={{ padding: '5px', alignItems: 'center', justifyContent: 'center' }}
              onClick={() => (params.node?.rowPinned !== 'bottom' ? handleDelete([params.data]) : handleAddRow(params))}
              size='large'>
              {params.node?.rowPinned !== 'bottom' ? (
                <Iconify icon='fa-regular:trash-alt' color={'#C00000'} fontSize={'16px'} />
              ) : (
                <Iconify icon='fluent:add-12-filled' color={'green'} fontSize={'16px'} />
              )}
            </IconButton>
          </Tooltip>
          {params.node?.rowPinned !== 'bottom' ? (
            <Box style={{ textAlign: 'right', paddingLeft: '8px' }}>{params.node.rowIndex + 1}</Box>
          ) : null}
        </Box>
      </React.Fragment>
    )
  }

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.uid)
    }
  }, [])

  function createPinnedCellPlaceholder({ colDef }) {
    if (colDef.field === 'description' || colDef.field === 'toolType' || colDef.field === 'serialNum') {
      return colDef.field[0].toUpperCase() + colDef.field.slice(1) + '...'
    }

    return ''
  }

  function isEmptyPinnedCell({ node, value }) {
    return (node?.rowPinned === 'bottom' && value == null) || (node?.rowPinned === 'bottom' && value === '')
  }

  function isPinnedRowDataCompleted() {
    return inputRow.current.hasOwnProperty('toolType') && inputRow.current.hasOwnProperty('description')
  }

  function clearCells(start, end, columns, gridApi) {
    let itemsToUpdate = []

    for (let i = start; i <= end; i++) {
      let data = gridApi.rowModel.rowsToDisplay[i].data
      columns.forEach((column) => {
        data[column] = ''
      })
      itemsToUpdate.push(data)
    }

    handleUpdate(itemsToUpdate)
  }

  const deleteMultipleRows = () => {
    const selectedRows = gridApi.current.getSelectedRows()
    if (!Array.isArray(selectedRows) || selectedRows.length < 2) return false

    let uids = []
    for (let i = 0; i < selectedRows.length; i++) {
      uids.push(selectedRows[i].uid)
    }

    handleDelete(selectedRows)
    return true
  }

  const defaultColDef = useMemo(() => {
    return {
      suppressKeyboardEvent: (params) => {
        if (!params.editing) {
          let isBackspaceKey = params.event.keyCode === 8
          let isDeleteKey = params.event.keyCode === 46

          if (isDeleteKey) {
            let multiRowsDeleted = deleteMultipleRows()
            if (multiRowsDeleted) return true
          }

          if (isBackspaceKey || isDeleteKey) {
            params.api.getCellRanges().forEach((range) => {
              let colIds = range.columns.map((col) => col.colId)
              let startRowIndex = Math.min(range.startRow.rowIndex, range.endRow.rowIndex)
              let endRowIndex = Math.max(range.startRow.rowIndex, range.endRow.rowIndex)
              clearCells(startRowIndex, endRowIndex, colIds, params.api)
            })

            return true
          }
        }
        return false
      },
      resizable: true,
      sortable: true,
      autoHeight: true,
      editable: true,
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
      valueFormatter: (params) => (isEmptyPinnedCell(params) ? createPinnedCellPlaceholder(params) : undefined),
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const saveColumnState = () => {
    if (gridApi.current) {
      const colLayout = gridApi.current.getColumnState()
      if (colLayout) saveItemToLS('inventoryGrid', 'colLayout', colLayout)
    }
  }

  const getContextMenuItems = (params) => {
    return [
      {
        name: 'Reset columns',
        disabled: false,
        action: () => {
          gridApi.current.resetColumnState()
          saveItemToLS('inventoryGrid', 'colLayout', null)
          setResetCols(!resetCols)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Reset filters',
        disabled: false,
        action: () => {
          gridApi.current.setFilterModel(null)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      'copy',
      {
        name: 'Export',
        action: () => {
          onXlsxExport()
        },
        icon: '<span class="iconify" data-icon="icomoon-free:file-excel" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
    ]
  }

  const getToolTypeDropDowns = () => {
    const { toolTypes } = getDropDowns()
    if (!Array.isArray(toolTypes)) return []

    let output = []
    for (let i = 0; i < toolTypes.length; i++) {
      output.push(toolTypes[i].label)
    }

    return output
  }

  const getConnectionsDropDowns = () => {
    const { connectionTypes } = getDropDowns()
    if (!Array.isArray(connectionTypes)) return []

    let output = []
    for (let i = 0; i < connectionTypes.length; i++) {
      output.push(connectionTypes[i].label)
    }

    return output
  }

  const columnDefs = [
    {
      field: 'actions',
      colId: 'actions',
      width: 86,
      headerName: '',
      editable: false,
      sortable: false,
      filter: null,
      suppressHeaderMenuButton: true,
      suppressHeaderFilterButton: true,
      cellRendererSelector: () => {
        return {
          component: actionIconRenderer,
        }
      },
      cellStyle: {
        display: 'flex',
        justifyContent: 'center',
        paddingLeft: '4px !important',
        paddingRight: '4px !important',
        borderRight: '1px solid gray !important',
      },
      pinned: 'left',
      lockPosition: 'left',
    },
    {
      headerName: 'SN',
      colId: 'serialNum',
      field: 'serialNum',
      pinned: 'left',
      cellStyle: centerAlignCell,
      valueGetter: (params) => unescapeHtml(params.data?.serialNum),
    },
    {
      headerName: 'Description',
      colId: 'description',
      field: 'description',
      pinned: 'left',
      cellStyle: { alignItem: 'end' },
      valueFormatter: (params) => {
        return isEmptyPinnedCell(params) ? createPinnedCellPlaceholder(params) : unescapeHtml(params.data?.description)
      },
      valueGetter: (params) => unescapeHtml(params.data?.description),
    },
    {
      headerName: 'Tool Type',
      colId: 'toolType',
      field: 'toolType',
      pinned: 'left',
      cellStyle: (params) => {
        return {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: getComponentColor(params.value),
        }
      },
      cellEditor: 'agRichSelectCellEditor',
      cellEditorPopup: true,
      cellEditorParams: {
        values: [...getToolTypeDropDowns()],
      },
      valueSetter: (params) => {
        let data = { ...params.data }
        data.toolType = params.newValue
        if (params.node?.rowPinned === 'bottom') {
          inputRow.current[params.colDef.field] = params.newValue
        }
        if (params.node?.rowPinned !== 'bottom') params.api.applyTransaction({ update: [data] })
        return true
      },
    },
    {
      headerName: `OD (${getUnitsText(UNITS_FOR.Diameter)})`,
      colId: 'od',
      field: 'od',
      editable: (params) => params.node?.rowPinned !== 'bottom',
      cellStyle: centerAlignCell,
      valueFormatter: (params) => {
        if (params.node?.rowPinned === 'bottom') return ''
        if (params.value === 0) return ''
        return `${numberWithCommasDecimals(params.data?.od, 3)}`
      },
      valueSetter: (params) => {
        if (/^(?:[1-4]?\d{0,2}(?:\.\d{1,2})?|500(?:\.0{1,2})?)$/.test(params.newValue) || params.newValue === '') {
          let data = { ...params.data }
          data.od = params.newValue === '' ? 0 : parseFloat(params.newValue)
          if (data.od < data.id) return true
          if (params.node?.rowPinned !== 'bottom') params.api.applyTransaction({ update: [data] })
          if (params.node?.rowPinned === 'bottom') {
            inputRow.current[params.colDef.field] = params.newValue
          }
        }
        return true
      },
    },
    {
      headerName: `ID (${getUnitsText(UNITS_FOR.Diameter)})`,
      colId: 'id',
      field: 'id',
      editable: (params) => params.node?.rowPinned !== 'bottom',
      cellStyle: centerAlignCell,
      valueFormatter: (params) => {
        if (params.node?.rowPinned === 'bottom') return ''
        if (params.value === 0) return ''
        return `${numberWithCommasDecimals(params.data?.id, 3)}`
      },
      valueSetter: (params) => {
        if (/^(?:[1-4]?\d{0,2}(?:\.\d{1,2})?|500(?:\.0{1,2})?)$/.test(params.newValue) || params.newValue === '') {
          let data = { ...params.data }
          data.id = params.newValue === '' ? 0 : parseFloat(params.newValue)
          if (data.id > data.od) return true
          if (params.node?.rowPinned !== 'bottom') params.api.applyTransaction({ update: [data] })
          if (params.node?.rowPinned === 'bottom') {
            inputRow.current[params.colDef.field] = params.newValue
          }
        }
        return true
      },
    },
    {
      headerName: `Length (${getUnitsText(UNITS_FOR.Depth)})`,
      colId: 'length',
      field: 'length',
      cellStyle: centerAlignCell,
      editable: (params) => params.node?.rowPinned !== 'bottom',
      valueFormatter: (params) => {
        if (params.node?.rowPinned === 'bottom') return ''
        if (params.value === 0) return ''
        return `${numberWithCommasDecimals(params.data?.length, 2)}`
      },
      cellEditor: 'agNumberCellEditor',
      cellEditorParams: {
        min: 0,
        max: 100000,
        precision: 2,
      },
    },
    {
      headerName: 'Cnx Top',
      colId: 'connectionTop',
      field: 'connectionTop',
      cellStyle: centerAlignCell,
      editable: (params) => params.node?.rowPinned !== 'bottom',
      valueGetter: (params) => unescapeHtml(params.data?.connectionTop),
      cellEditor: 'agRichSelectCellEditor',
      cellEditorPopup: true,
      cellEditorParams: {
        values: [...getConnectionsDropDowns()],
      },
    },
    {
      headerName: 'Cnx Bottom',
      colId: 'connectionBottom',
      field: 'connectionBottom',
      cellStyle: centerAlignCell,
      editable: (params) => params.node?.rowPinned !== 'bottom',
      valueGetter: (params) => unescapeHtml(params.data?.connectionBottom),
      cellEditor: 'agRichSelectCellEditor',
      cellEditorPopup: true,
      cellEditorParams: {
        values: [...getConnectionsDropDowns()],
      },
    },
    {
      headerName: 'Basket',
      colId: 'basket',
      field: 'basket',
      editable: (params) => params.node?.rowPinned !== 'bottom',
      cellStyle: centerAlignCell,
      valueGetter: (params) => unescapeHtml(params.data?.basket),
    },
    {
      headerName: 'Location',
      colId: 'location',
      field: 'location',
      editable: (params) => params.node?.rowPinned !== 'bottom',
      cellStyle: centerAlignCell,
      valueGetter: (params) => unescapeHtml(params.data?.location),
    },
    {
      headerName: `Weight (${getUnitsText(UNITS_FOR.Weight) === 'klbs' ? 'lbs' : 'kg'})`,
      colId: 'weight',
      field: 'weight',
      editable: (params) => params.node?.rowPinned !== 'bottom',
      cellStyle: centerAlignCell,
      valueFormatter: (params) => {
        if (params.value === 0) return ''
        return `${numberWithCommas(params.data?.weight)}`
      },
      cellEditor: 'agNumberCellEditor',
      cellEditorParams: {
        min: 0,
        max: 100000,
        precision: 2,
      },
    },
    {
      headerName: 'Date on Location',
      colId: 'dateOn',
      field: 'dateOn',
      sortable: false,
      cellDataType: false,
      editable: (params) => params.node?.rowPinned !== 'bottom',
      cellStyle: centerAlignCell,
      valueSetter: (params) => {
        if (
          /^(0[1-9]|1\d|2\d|3[01])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/.test(params.newValue) ||
          params.newValue === ''
        ) {
          let data = { ...params.data }
          data.dateOn = params.newValue
          if (params.node?.rowPinned !== 'bottom') params.api.applyTransaction({ update: [data] })
          if (params.node?.rowPinned === 'bottom') {
            inputRow.current[params.colDef.field] = params.newValue
          }
        }
        return true
      },
    },
    {
      headerName: 'Date off Location',
      colId: 'dateOff',
      field: 'dateOff',
      cellDataType: false,
      sortable: false,
      editable: (params) => params.node?.rowPinned !== 'bottom',
      cellStyle: centerAlignCell,
      valueSetter: (params) => {
        if (
          /^(0[1-9]|1\d|2\d|3[01])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/.test(params.newValue) ||
          params.newValue === ''
        ) {
          let data = { ...params.data }
          data.dateOff = params.newValue
          if (params.node?.rowPinned !== 'bottom') params.api.applyTransaction({ update: [data] })
          if (params.node?.rowPinned === 'bottom') {
            inputRow.current[params.colDef.field] = params.newValue
          }
        }
        return true
      },
    },
    {
      headerName: 'On Location',
      colId: 'onLocation',
      field: 'onLocation',
      editable: true,
      cellStyle: centerAlignCell,
      cellRenderer: 'agCheckboxCellRenderer',
      valueGetter: (params) => params.data?.dateOn?.length > 0 && params.data?.dateOff?.length === 0,
    },
    {
      headerName: 'Failed',
      colId: 'failed',
      field: 'failed',
      editable: true,
      cellStyle: centerAlignCell,
      cellRenderer: 'agCheckboxCellRenderer',
    },
    {
      headerName: 'Ready for Backload',
      colId: 'readyForBackload',
      field: 'readyForBackload',
      editable: true,
      cellStyle: centerAlignCell,
      cellRenderer: 'agCheckboxCellRenderer',
    },
    {
      headerName: 'In Hole',
      colId: 'inHole',
      field: 'inHole',
      editable: false,
      cellStyle: centerAlignCell,
      cellRenderer: 'agCheckboxCellRenderer',
    },
    {
      headerName: 'Comments',
      colId: 'comments',
      field: 'comments',
      cellStyle: { alignItems: 'start' },
      editable: (params) => params.node?.rowPinned !== 'bottom',
      valueFormatter: (params) => unescapeHtml(params.data?.comments),
      valueGetter: (params) => unescapeHtml(params.data?.comments),
    },
    {
      headerName: 'Make',
      colId: 'make',
      field: 'make',
      editable: (params) => params.node?.rowPinned !== 'bottom',
      cellStyle: centerAlignCell,
      valueGetter: (params) => unescapeHtml(params.data?.make),
    },
    {
      headerName: 'Model',
      colId: 'model',
      field: 'model',
      editable: (params) => params.node?.rowPinned !== 'bottom',
      cellStyle: centerAlignCell,
      valueGetter: (params) => unescapeHtml(params.data?.model),
    },
    {
      headerName: 'Dirty',
      colId: 'dirty',
      field: 'dirty',
      valueGetter: (params) => params.data?.totalToolHours > 0,
      editable: false,
      cellStyle: centerAlignCell,
      cellRenderer: 'agCheckboxCellRenderer',
    },
    {
      headerName: 'Prev D&C Hrs',
      colId: 'prevToolHours',
      field: 'prevToolHours',
      editable: (params) => params.node?.rowPinned !== 'bottom',
      cellStyle: centerAlignCell,
      valueFormatter: (params) => {
        if (params.node?.rowPinned === 'bottom') return ''
        if (params.value === 0) return ''
        return `${numberWithCommasDecimals(params.data?.prevToolHours, 2)}`
      },
      cellEditor: 'agNumberCellEditor',
      cellEditorParams: {
        min: 0,
        max: 100000,
        precision: 2,
      },
    },
    {
      headerName: 'Total D&C Hrs',
      colId: 'totalToolHours',
      field: 'totalToolHours',
      editable: false,
      cellStyle: centerAlignCell,
      valueFormatter: (params) => {
        if (params.node?.rowPinned === 'bottom') return ''
        if (params.value === 0) return ''
        return `${numberWithCommasDecimals(params.data?.totalToolHours, 2)}`
      },
    },
  ]

  const gridOptions = {
    detailRowHeight: 200,
    sideBar: {
      toolPanels: [
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        },
      ],
      defaultToolPanel: '',
      position: 'left',
    },
    pinnedBottomRowData: [inputRow.current],
    onCellEditingStopped: (event) => {
      if (event.node?.rowPinned === 'bottom') {
        gridApi.current.setGridOption('pinnedBottomRowData', [inputRow.current])
        handleAddRow(event)
      }

      if (event.node?.rowPinned !== 'bottom') handleUpdate([event.data])
    },
    onDragStopped: (event) => {
      saveColumnState()
    },
    onColumnVisible: (event) => {
      saveColumnState()
    },
    loadingOverlayComponent: CustomLoadingOverlay,
    getRowStyle: ({ node }) => (node?.rowPinned ? { fontWeight: 'bold', fontStyle: 'italic' } : 0),
  }

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const onFilterChanged = (params) => {
    if (!gridApi.current) return

    let filteredNodes = []
    params.api.forEachNodeAfterFilter((node) => {
      filteredNodes.push(node.data)
    })

    if (_isMounted.current) setFilteredData(filteredNodes)
  }

  const onFirstDataRendered = (params) => {
    if (gridApi.current) gridApi.current.onFilterChanged()

    autoSizeColumns()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const handleCloseStatus = () => {
    setStatus({ show: false, severity: 'info', message: '' })
  }

  const processDataFromClipboard = (params) => {
    if (!params.hasOwnProperty('data') && !Array.isArray(params.data) && params.data?.length < 1) {
      return null
    }
    let data = params.data?.map((dataRow) => {
      return dataRow.slice()
    })

    // delete the empty last row in the clipboard data, if one exists
    const emptyLastRow = data[data.length - 1][0] === '' && data[data.length - 1].length === 1
    if (emptyLastRow) {
      data.splice(data.length - 1, 1)
    }
    // make sure there's data to process after we remove the typical empty row
    if (data.length < 1) return null

    const focusedCell = params.api.getFocusedCell()
    const focusedIndex = focusedCell.rowIndex

    pastedRows.current = []
    gridApi.current?.forEachNodeAfterFilterAndSort((rowNode, index) => {
      if (index >= focusedIndex && index <= focusedIndex + data.length - 1) {
        pastedRows.current.push(rowNode.data)
      }
    })

    return data
  }

  function onPasteEnd(params) {
    if (!Array.isArray(pastedRows.current)) return
    if (pastedRows.current.length === 0) return
    handleUpdate(cloneDeep(pastedRows.current))
    pastedRows.current = []
  }

  return (
    <React.Fragment>
      {confirm.show ? (
        <ConfirmDialog
          title={confirm?.title}
          open={confirm?.show}
          setOpen={() => setConfirm({ show: false })}
          onConfirm={() => confirmDelete()}>
          {confirm?.text}
        </ConfirmDialog>
      ) : null}
      <div className={getAgGridTheme()} style={{ width: '100%', height: '100%' }}>
        <AgGridReact
          loading={isLoading}
          rowData={data}
          columnDefs={sortColDefs(columnDefs, 'inventoryGrid')}
          defaultColDef={defaultColDef}
          getRowId={getRowId}
          animateRows={true}
          rowSelection='multiple'
          enableRangeSelection='true'
          enableBrowserTooltips={true}
          groupDisplayType={'groupRows'}
          onGridReady={onGridReady}
          groupRowsSticky={true}
          gridOptions={gridOptions}
          getContextMenuItems={getContextMenuItems}
          onFirstDataRendered={onFirstDataRendered}
          onFilterChanged={onFilterChanged}
          processDataFromClipboard={processDataFromClipboard}
          onPasteEnd={onPasteEnd}
          headerHeight={30}
        />
      </div>
      {status?.show ? (
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={status?.show}
          autoHideDuration={2000}
          onClose={handleCloseStatus}>
          <Alert onClose={handleCloseStatus} severity={status.severity} elevation={4} variant='filled'>
            {status.message}
          </Alert>
        </Snackbar>
      ) : null}
    </React.Fragment>
  )
}

export default InventoryGrid
