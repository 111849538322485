import React, { useState } from 'react'
import InputModal from 'components/common/InputModal'
import { CircularProgress } from '@mui/material'
import * as yup from 'yup'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import useAddInstantWellDropDowns from 'components/common/hooks/useAddInstantWellDropDown'

const AddInstantWellModal = ({ onClose, open, refresh }) => {
  const [isAdding, setIsAdding] = useState(false)
  const [instantWellDropDowns, isLoading, hasCountrys, hasDistricts] = useAddInstantWellDropDowns(true)

  const addInstantActualWell = useInnovaAxios({
    url: '/well/addInstantActualWell',
  })

  const formValidationSchema = yup.object({
    operator: yup.string().required(),
    field: yup.string().required(),
    facility: yup.string().required(),
    well: yup.string().required(),
    northRef: yup.string().required(),
    latitude: yup
      .string()
      .matches(/^[-+]?([0-9]+\.?[0-9]*|\.[0-9]+)$/, 'Only numeric values allowed')
      .nullable(),
    longitude: yup
      .string()
      .matches(/^[-+]?([0-9]+\.?[0-9]*|\.[0-9]+)$/, 'Only numeric values allowed')
      .nullable(),
    vsAzi: yup
      .string()
      .matches(/^[+]?([0-9]+\.?[0-9]*|\.[0-9]+)$/, 'Only numeric values allowed')
      .required(),
    elevation: yup
      .string()
      .matches(/^[-+]?([0-9]+\.?[0-9]*|\.[0-9]+)$/, 'Only numeric values allowed')
      .required(),
  })

  const submitNewInstantWell = async (data) => {
    if (isAdding) return
    setIsAdding(true)

    const response = await addInstantActualWell({
      ...data,
      actualWell: data.well,
    })

    setIsAdding(false)

    if (response.error) {
      console.error(response.error)
    }

    if (refresh) refresh(Date.now().toString())
    onClose()
  }

  let formData = [
    {
      tag: 'operator',
      value: 'New Operator',
      text: 'Operator',
      inputType: 'text',
      filterProperty: 'newOperator',
      filterValue: 'true',
    },
    {
      tag: 'operator',
      value: '',
      text: 'Operator',
      inputType: 'dropdown',
      dropDownValues: Array.isArray(instantWellDropDowns.operators) ? instantWellDropDowns?.operators : [],
      useLabel: true,
      filterProperty: 'newOperator',
      filterValue: 'false',
    },
    {
      tag: 'newOperator',
      value: false,
      text: 'Add new Operator',
      inputType: 'boolean',
    },
    {
      tag: 'field',
      value: 'New Field',
      text: 'Field (County)',
      inputType: 'text',
      filterProperty: 'newField',
      filterValue: 'true',
    },
    {
      tag: 'field',
      value: '',
      text: 'Field (County)',
      inputType: 'dropdown',
      dropDownValues: Array.isArray(instantWellDropDowns.fields) ? instantWellDropDowns?.fields : [],
      useLabel: true,
      filterProperty: 'newField',
      filterValue: 'false',
      filterField: 'operator',
    },
    {
      tag: 'newField',
      value: false,
      text: 'Add new field',
      inputType: 'boolean',
    },
    {
      tag: 'facility',
      value: 'New Facility',
      text: 'Facility (Pad)',
      inputType: 'text',
      filterProperty: 'newFaciltiy',
      filterValue: 'true',
    },
    {
      tag: 'facility',
      value: '',
      text: 'Facility (Pad)',
      inputType: 'dropdown',
      dropDownValues: Array.isArray(instantWellDropDowns.facilitys) ? instantWellDropDowns?.facilitys : [],
      useLabel: true,
      filterProperty: 'newFaciltiy',
      filterValue: 'false',
      filterField: 'field',
    },
    {
      tag: 'newFaciltiy',
      value: false,
      text: 'Add new facility',
      inputType: 'boolean',
    },
    {
      tag: 'well',
      value: 'New Well',
      text: 'Well',
      inputType: 'text',
    },
    {
      tag: 'northRef',
      value: 'GRID',
      text: 'North Ref',
      inputType: 'dropdown',
      dropDownValues: Array.isArray(instantWellDropDowns.northRef) ? instantWellDropDowns?.northRef : [],
      useLabel: true,
      filterProperty: 'newField',
      filterValue: 'true',
    },
    {
      tag: 'elevation',
      value: '0',
      inputType: 'text',
      text: 'Elevation RTE->MSL',
    },
    {
      tag: 'vsAzi',
      value: '0',
      inputType: 'text',
      text: 'Vertical Section Azimuth',
    },
    {
      tag: 'latitude',
      value: '29.0',
      inputType: 'text',
      text: 'Latitude (decimal degrees)',
    },
    {
      tag: 'longitude',
      value: '-95',
      inputType: 'text',
      text: 'Longitude (decimal degrees)',
    },
    {
      tag: 'jobNum',
      value: '',
      inputType: 'text',
      text: 'Job #',
    },
    {
      tag: 'rig',
      value: '',
      text: 'Rig',
      inputType:
        Array.isArray(instantWellDropDowns.rigNames) && instantWellDropDowns.rigNames.length > 0 ? 'dropdown' : 'text',
      dropDownValues: instantWellDropDowns.rigNames,
      useLabel: true,
    },
    {
      tag: 'district',
      value: '',
      text: 'District',
      inputType: hasDistricts ? 'dropdown' : '-',
      dropDownValues: Array.isArray(instantWellDropDowns.district) ? instantWellDropDowns.district : [],
      useLabel: true,
    },
    {
      tag: 'state',
      value: '',
      text: hasCountrys ? 'Country' : 'State',
      inputType: 'dropdown',
      dropDownValues:
        !hasCountrys && Array.isArray(instantWellDropDowns.statesList)
          ? instantWellDropDowns.statesList
          : Array.isArray(instantWellDropDowns.countrys)
          ? instantWellDropDowns.countrys
          : [],
      useLabel: true,
    },
    {
      tag: 'county',
      value: '',
      text: 'County',
      inputType: hasCountrys ? '-' : 'dropdown',
      dropDownValues: Array.isArray(instantWellDropDowns.countyList) ? instantWellDropDowns.countyList : [],
      useLabel: true,
      filterField: 'state',
    },
    {
      tag: 'description',
      value: '',
      inputType: hasCountrys ? 'text' : '-',
      text: 'Description',
    },
  ]

  return (
    <React.Fragment>
      {!isLoading && !isAdding ? (
        <InputModal
          open={open}
          onClose={onClose}
          title={'Add new well'}
          formData={formData}
          submitForm={submitNewInstantWell}
          cancelFunction={onClose}
          validationSchema={formValidationSchema}
        />
      ) : (
        <CircularProgress
          style={{
            position: 'absolute',
            height: '100px',
            width: '100px',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 999999,
          }}
        />
      )}
    </React.Fragment>
  )
}

export default AddInstantWellModal
