import React, { useEffect, useRef, useState } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import * as XLSX from '@sheet/core'
import { analyticsFilterDefsAtom, analyticsSelectedWells, currentPageAtom } from 'atoms'
import { getXlsxColumnLetter, array2pipestr } from 'utils'
import useUnits, { UNITS_FOR } from 'components/common/hooks/useUnits'
import { createChartDataMultiWell, getDistinctPropList } from 'components/common/depthVtimeFunctions'
import { PAGE_KEYS } from 'components/ActionBar/pageDefs'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import { Box } from '@mui/material'
import { getParameter } from '../AnalyticsPage/FilterDefs'
import CircularProgress from '@mui/material/CircularProgress'
import { AnalyticsChartButton } from './AnalyticsBarChart'
import { getRandomColor } from 'utils/colorFunctions'
import './chart.css'
import InputModal from 'components/common/InputModal'
import * as yup from 'yup'
import { numberWithCommasDecimals } from 'utils/stringFunctions'
import { Scatter } from 'react-chartjs-2'
import { appColors, chartSeriesColors } from 'utils'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

export const DepthVDaysLineChart = ({
  title,
  wellData,
  yAxisTitle,
  xAxisTitle,
  isLoading,
  onXlsxExport,
  removeFlatTime,
  removeFlatTimeActive,
  removeFlatTimeEndActive,
  removeFlatTimeStartActive,
  removeFlatTimeStart,
  removeFlatTimeEnd,
  showColorModal,
  showFilterModal,
  showLegend,
  setShowLegend,
}) => {
  const { theme } = useInnovaTheme()

  const segColor = (ctx, defaultColor) => {
    return ctx.p0.raw.color ? ctx.p0.raw.color : defaultColor
  }

  if (!wellData) return null

  const legendHover = (event, item, legend) => {
    legend.chart.data.datasets.forEach((dataset, index) => {
      if (item.datasetIndex === index) {
        dataset.borderWidth = 5
        dataset.segment.borderWidth = 5
      } else {
        dataset.borderWidth = 1
        dataset.segment.borderWidth = 1
      }
    })
    legend.chart.update()
  }

  const legendLeave = (event, item, legend) => {
    legend.chart.data.datasets.forEach((dataset, index) => {
      dataset.borderWidth = 3
      dataset.segment.borderWidth = 3
    })
    legend.chart.update()
  }

  const findWellIndex = (wellName) => {
    if (!wellName) return -1
    if (typeof wellName !== 'string') return -1
    if (!wellData) return -1
    if (!Array.isArray(wellData)) return -1
    return wellData.findIndex((element) => element.name === wellName)
  }

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      GradientPlugIn: {
        showGradient: true,
        theme: theme,
      },
      legend: {
        position: 'top',
        align: 'end',
        onHover: legendHover,
        onLeave: legendLeave,
        display: showLegend,
      },
      title: {
        display: true,
        text: title,
        color: appColors.itemTextColor,
        font: {
          size: 20,
        },
      },
      tooltip: {
        mode: 'nearest',
        intersect: false,
        callbacks: {
          title: function (tooltipItem) {
            return tooltipItem[0].dataset.label
          },
          label: function (context) {
            let wellIndex = findWellIndex(context.dataset.label)
            if (wellIndex < 0) return ''
            if (wellData[wellIndex].dataVals[context.dataIndex]) {
              return [
                ` ${numberWithCommasDecimals(+context.parsed.x, 2)} ${xAxisTitle}`,
                ` ${context.parsed.y} ${yAxisTitle}`,
                ` BHA# ${
                  wellData[wellIndex].dataVals[context.dataIndex].bhaNumRep >= 0
                    ? wellData[wellIndex].dataVals[context.dataIndex].bhaNumRep
                    : 'None'
                }`,
                ` Phase: ${wellData[wellIndex].dataVals[context.dataIndex].phase}`,
                ` Activity: ${wellData[wellIndex].dataVals[context.dataIndex].activity}`,
                ` Hole Size: ${numberWithCommasDecimals(wellData[wellIndex].dataVals[context.dataIndex]?.holeSize, 3)}`,
              ]
            } else {
              return [
                ` ${numberWithCommasDecimals(context.parsed.x, 2)} ${xAxisTitle}`,
                ` ${context.parsed.y} ${yAxisTitle}`,
              ]
            }
          },
        },
        // filter the tooltip list to only show one point when they are very close together
        filter: function (tooltipItem, index) {
          if (index > 0) return false
          return true
        },
      },
      hover: {
        mode: 'nearest',
        intersect: false,
      },
      zoom: {
        pan: {
          enabled: true,
          mode: 'xy',
        },
        limits: {
          x: {
            min: 'original',
            max: 'original',
          },
          y: {
            min: 'original',
            max: 'original',
          },
        },
        zoom: {
          wheel: {
            enabled: true,
          },
          pinch: {
            enabled: true,
          },
          mode: 'xy',
        },
      },
    },
    scales: {
      x: {
        beginAtZero: true,
        title: {
          display: true,
          text: xAxisTitle,
          color: appColors.headerTextColor,
        },
        grid: {
          color: '#404040',
        },
      },
      y: {
        beginAtZero: true,
        reverse: true,
        title: {
          display: true,
          text: yAxisTitle,
          color: appColors.headerTextColor,
        },
        grid: {
          color: '#404040',
        },
      },
    },
    interaction: {
      intersect: false,
      mode: 'nearest',
    },
    layout: {
      padding: {
        top: showLegend ? 0 : 30,
        right: 20,
      },
    },
  }

  const chartJsData = {
    datasets: wellData.map((series, index) => {
      let col = index < chartSeriesColors.length ? chartSeriesColors[index] : getRandomColor()
      return {
        type: 'line',
        label: series.name,
        data: series.dataVals,
        borderColor: col,
        backgroundColor: col,
        borderWidth: 3,
        pointRadius: 0,
        lineTension: 0.8,
        pointHoverRadius: 0,
        segment: {
          borderColor: (ctx) => segColor(ctx, col),
          borderWidth: 3,
        },
      }
    }),
  }

  return (
    <Box sx={{ backgroundColor: appColors.itemBackColor, width: '100%', height: '100%', overflow: 'hidden' }}>
      <Scatter options={options} data={chartJsData} />
      {isLoading ? (
        <CircularProgress
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        />
      ) : null}
      <Box
        sx={{
          position: 'absolute',
          left: 0,
          top: 0,
          marginLeft: '5px',
          marginTop: '10px',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        {onXlsxExport ? (
          <AnalyticsChartButton
            group={true}
            onClick={onXlsxExport}
            icon={'file-icons:microsoft-excel'}
            toolTipText={'Export to Excel'}
            color={'green'}
          />
        ) : null}
        {removeFlatTimeStart ? (
          <AnalyticsChartButton
            group={true}
            onClick={removeFlatTimeStart}
            icon={'octicon:move-to-start-24'}
            toolTipText={'Remove flat time at start'}
            color={removeFlatTimeStartActive ? appColors.itemTextColor : appColors.headerTextColor}
          />
        ) : null}
        {removeFlatTime ? (
          <AnalyticsChartButton
            group={true}
            onClick={removeFlatTime}
            icon={'material-symbols:trending-flat-rounded'}
            toolTipText={'Remove all flat time'}
            color={removeFlatTimeActive ? appColors.itemTextColor : appColors.headerTextColor}
          />
        ) : null}
        {removeFlatTimeEnd ? (
          <AnalyticsChartButton
            group={true}
            onClick={removeFlatTimeEnd}
            icon={'octicon:move-to-end-24'}
            toolTipText={'Remove flat time at end'}
            color={removeFlatTimeEndActive ? appColors.itemTextColor : appColors.headerTextColor}
          />
        ) : null}
        {showColorModal ? (
          <AnalyticsChartButton
            group={true}
            onClick={showColorModal}
            icon={'ic:baseline-color-lens'}
            toolTipText={'Color Filter'}
            color={appColors.itemTextColor}
          />
        ) : null}
        {showFilterModal ? (
          <AnalyticsChartButton
            group={true}
            onClick={showFilterModal}
            icon={'icon-park-outline:filter'}
            toolTipText={'Data Filter'}
            color={appColors.itemTextColor}
          />
        ) : null}
        {setShowLegend ? (
          <AnalyticsChartButton
            group={true}
            onClick={setShowLegend}
            icon={'gis:map-legend'}
            toolTipText={'Toggle legend'}
            color={showLegend ? appColors.itemTextColor : appColors.headerTextColor}
          />
        ) : null}
      </Box>
    </Box>
  )
}

const ColorModal = ({ data, submitFunction, cancelFunction }) => {
  const formValidationSchema = yup.object({})
  if (!data || data === '') data = 'none'

  let formData = [
    {
      tag: 'colorSelector',
      value: data,
      text: 'Parameter',
      inputType: 'dropdown',
      dropDownValues: [
        { label: 'None', value: 'none' },
        { label: 'Activity', value: 'activity' },
        { label: 'BHA', value: 'bha' },
        { label: 'Hole Size', value: 'holeSize' },
        { label: 'Phase', value: 'phase' },
      ],
      useLabel: true,
    },
  ]

  return (
    <InputModal
      open={true}
      onClose={cancelFunction}
      title={'Select color parameter'}
      formData={formData}
      submitForm={submitFunction}
      cancelFunction={cancelFunction}
      validationSchema={formValidationSchema}
    />
  )
}

const getPickListObjArray = (prop, chartData) => {
  if (!prop) return []
  if (typeof prop !== 'string') return []
  if (prop === 'none') return []
  if (!Array.isArray(chartData)) return []

  let pickListVals = getDistinctPropList(chartData, prop)
  if (!Array.isArray(pickListVals)) return []

  let newObjArray = []
  pickListVals.forEach((item) => {
    newObjArray.push({
      id: item.value,
      desc: item.label,
    })
  })

  return newObjArray
}

const getPickListValArray = (objArray, type, chartData) => {
  if (!Array.isArray(objArray)) return []

  let valDescArray = []
  if (type === 'bha' || type === 'activity' || type === 'phase') {
    //Required becasue bha / Activity description is not the same as the value
    valDescArray = getPickListObjArray(type, chartData)
  }

  let newObjArray = []
  objArray.forEach((item) => {
    newObjArray.push({
      label: valDescArray?.length > 0 ? valDescArray.find((x) => x.id === item)?.desc : item,
      desc: item,
    })
  })

  return newObjArray
}

const FilterModal = ({ data, submitFunction, cancelFunction, chartData }) => {
  const formValidationSchema = yup.object({})

  if (!data) {
    data = {
      filterParam: 'none',
      filterValues: [],
    }
  }

  if (typeof data?.filterParam !== 'string') data.filterParam = 'none'
  if (!Array.isArray(data?.filterValues)) data.filterValues = []

  let formData = [
    {
      tag: 'filterParam',
      value: data.filterParam,
      text: 'Parameter',
      inputType: 'dropdown',
      dropDownValues: [
        { label: 'None', value: 'none' },
        { label: 'Activity', value: 'activity' },
        { label: 'BHA', value: 'bha' },
        { label: 'Hole Size', value: 'holeSize' },
        { label: 'Phase', value: 'phase' },
      ],
      useLabel: true,
    },
    {
      tag: 'filterValuesActivity',
      value: getPickListValArray(data.filterValues, 'activity', chartData),
      text: 'Activities',
      inputType: 'picklist',
      pickListValues: getPickListObjArray('activity', chartData),
      useLabel: true,
      filterProperty: 'filterParam',
      filterValue: 'activity',
    },
    {
      tag: 'filterValuesBha',
      value: getPickListValArray(data.filterValues, 'bha', chartData),
      text: 'BHA',
      inputType: 'picklist',
      pickListValues: getPickListObjArray('bha', chartData),
      useLabel: true,
      filterProperty: 'filterParam',
      filterValue: 'bha',
    },
    {
      tag: 'filterValuesHoleSize',
      value: getPickListValArray(data.filterValues),
      text: 'Hole Sizes',
      inputType: 'picklist',
      pickListValues: getPickListObjArray('holeSize', chartData),
      useLabel: true,
      filterProperty: 'filterParam',
      filterValue: 'holeSize',
    },
    {
      tag: 'filterValuesPhase',
      value: getPickListValArray(data.filterValues),
      text: 'Phases',
      inputType: 'picklist',
      pickListValues: getPickListObjArray('phase', chartData),
      useLabel: true,
      filterProperty: 'filterParam',
      filterValue: 'phase',
    },
  ]

  return (
    <InputModal
      open={true}
      onClose={cancelFunction}
      title={'Select filter parameter'}
      formData={formData}
      submitForm={submitFunction}
      cancelFunction={cancelFunction}
      validationSchema={formValidationSchema}
    />
  )
}

const DepthVsDaysChart = () => {
  const _isMounted = useRef(false)

  const [data, setData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const setActivePage = useSetRecoilState(currentPageAtom)
  const selectedWells = useRecoilValue(analyticsSelectedWells)
  const searchParams = useRecoilValue(analyticsFilterDefsAtom)
  const [removeFlatTime, setRemoveFlatTime] = useState(false)
  const [removeFlatTimeStart, setRemoveFlatTimeStart] = useState(false)
  const [removeFlatTimeEnd, setRemoveFlatTimeEnd] = useState(false)
  const [showColorModal, setShowColorModal] = useState(false)
  const [showFilterModal, setShowFilterModal] = useState(false)
  const [colorBySelector, setColorBySelector] = useState('')
  const [filterByParams, setFilterByParams] = useState(null)
  const [showLegend, setShowLegend] = useState(false)

  const { getUnitsText } = useUnits()

  const getKpiData = useInnovaAxios({
    url: '/kpi/depthVdays',
  })

  useEffect(() => {
    _isMounted.current = true
    setActivePage(PAGE_KEYS.depthVDaysKey)

    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetchData()
  }, [selectedWells]) // eslint-disable-line react-hooks/exhaustive-deps

  const isPhaseFilterActive = (phase) => {
    if (Array.isArray(phase) && phase.length > 0) return true
    return false
  }

  const fetchData = async () => {
    if (isLoading) return
    if (!_isMounted.current) return
    if (!Array.isArray(selectedWells)) {
      setData([])
      return
    }

    if (selectedWells.length < 1) {
      setData([])
      return
    }

    let payload = {
      wellList: array2pipestr(selectedWells),
      phaseFilter: '',
    }

    let phaseFilter = getParameter('Phase', searchParams)
    if (isPhaseFilterActive(phaseFilter)) {
      payload.phaseFilter = phaseFilter
    }

    setIsLoading(true)
    const res = await getKpiData(payload)

    if (_isMounted.current) {
      setData(res?.data ? res.data : [])
      setIsLoading(false)
    }
  }

  const toggleFlatTime = () => {
    setRemoveFlatTime(!removeFlatTime)
  }

  const onXlsxExport = () => {
    if (!Array.isArray(data)) return
    let ws = undefined
    let colIndex = 0

    let filteredData = createChartDataMultiWell(
      data,
      removeFlatTime,
      removeFlatTimeStart,
      removeFlatTimeEnd,
      filterByParams,
      colorBySelector,
    )

    filteredData.forEach((well) => {
      if (!ws) {
        ws = XLSX.utils.aoa_to_sheet([[well.name]], { origin: 'A1' })
      } else {
        XLSX.utils.sheet_add_aoa(ws, [[well.name]], { origin: `${getXlsxColumnLetter(colIndex)}1` })
      }

      XLSX.utils.sheet_add_aoa(
        ws,
        [['Days', `Depth (${getUnitsText(UNITS_FOR.Depth)})`, 'BHA', 'Phase', 'Activity', 'Hole Size']],
        {
          origin: `${getXlsxColumnLetter(colIndex)}2`,
        },
      )

      XLSX.utils.sheet_add_aoa(
        ws,
        well.dataVals.map((item) => [
          item.x,
          item.y,
          item.bha > 0 ? item.bha : '',
          item.phase,
          item.activity,
          item.holeSize,
        ]),
        { origin: `${getXlsxColumnLetter(colIndex)}3` },
      )
      colIndex += 6
    })

    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, 'Depth v Days')

    let wsTable = XLSX.utils.aoa_to_sheet(
      [['Well Name', 'Days', `Depth (${getUnitsText(UNITS_FOR.Depth)})`, 'Phase', 'Activity', 'BHA', 'Hole Size']],
      {
        origin: 'A1',
      },
    )

    let tableData = []
    for (let i = 0; i < filteredData.length; i++) {
      if (!filteredData[i].hasOwnProperty('dataVals')) continue
      if (!Array.isArray(filteredData[i].dataVals)) continue
      if (filteredData[i].dataVals.length === 0) continue

      for (let j = 0; j < filteredData[i].dataVals.length; j++) {
        tableData.push([
          filteredData[i].name,
          filteredData[i].dataVals[j].x,
          filteredData[i].dataVals[j].y,
          filteredData[i].dataVals[j].phase,
          filteredData[i].dataVals[j].activity,
          filteredData[i].dataVals[j].bha > 0 ? filteredData[i].dataVals[j].bha : '',
          filteredData[i].dataVals[j].holeSize,
        ])
      }
    }

    XLSX.utils.sheet_add_aoa(wsTable, tableData, { origin: 'A2' })
    XLSX.utils.book_append_sheet(wb, wsTable, 'Depth v Days Table')
    XLSX.writeFile(wb, 'depthVsDays.xlsx')
  }

  const getTitle = () => {
    let phaseFilter = getParameter('Phase', searchParams)
    let title = 'Depth V Days'
    if (!phaseFilter) return title
    if (!Array.isArray(phaseFilter)) return title
    if (phaseFilter.length === 0) return title
    return `${title} - ${phaseFilter.join(',')}`
  }

  const toggleFlatTimeStart = () => {
    setRemoveFlatTimeStart(!removeFlatTimeStart)
  }

  const toggleFlatTimeEnd = () => {
    setRemoveFlatTimeEnd(!removeFlatTimeEnd)
  }

  const toggleShowLegend = () => {
    setShowLegend(!showLegend)
  }

  return (
    <React.Fragment>
      {showColorModal ? (
        <ColorModal
          data={colorBySelector}
          cancelFunction={() => setShowColorModal(false)}
          submitFunction={(data) => {
            setColorBySelector(data.colorSelector !== 'none' ? data.colorSelector : '')
            setShowColorModal(false)
          }}
        />
      ) : null}
      {showFilterModal ? (
        <FilterModal
          data={filterByParams}
          chartData={data}
          cancelFunction={() => setShowFilterModal(false)}
          submitFunction={(data) => {
            setShowFilterModal(false)
            if (data.filterParam === 'none') {
              setFilterByParams(null)
              return
            }

            let filterValues = []
            if (data.filterParam === 'activity') {
              filterValues = data.filterValuesActivity
            }

            if (data.filterParam === 'bha') {
              filterValues = data.filterValuesBha
            }

            if (data.filterParam === 'holeSize') {
              filterValues = data.filterValuesHoleSize
            }

            if (data.filterParam === 'phase') {
              filterValues = data.filterValuesPhase
            }

            setFilterByParams({ filterParam: data.filterParam, filterValues: filterValues })
            setShowFilterModal(false)
          }}
        />
      ) : null}
      <DepthVDaysLineChart
        showLegend={showLegend}
        setShowLegend={toggleShowLegend}
        removeFlatTimeEndActive={removeFlatTimeEnd}
        removeFlatTimeStartActive={removeFlatTimeStart}
        removeFlatTimeActive={removeFlatTime}
        removeFlatTimeStart={toggleFlatTimeStart}
        removeFlatTimeEnd={toggleFlatTimeEnd}
        removeFlatTime={toggleFlatTime}
        showColorModal={() => setShowColorModal(true)}
        showFilterModal={() => setShowFilterModal(true)}
        onXlsxExport={onXlsxExport}
        isLoading={isLoading}
        title={getTitle()}
        xAxisTitle={'Days'}
        yAxisTitle={`Depth (${getUnitsText(UNITS_FOR.Depth)})`}
        wellData={createChartDataMultiWell(
          data,
          removeFlatTime,
          removeFlatTimeStart,
          removeFlatTimeEnd,
          filterByParams,
          colorBySelector,
        )}
      />
    </React.Fragment>
  )
}

export default DepthVsDaysChart
