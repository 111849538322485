import React, { useMemo, useRef } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { numberWithCommasDecimals } from 'utils/stringFunctions'
import { useRecoilState } from 'recoil'
import { bhaCriticalRPMAnalysisParamsAtom } from 'atoms'
import { getStringId } from 'components/common/AgGridUtils'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const BhaCriticalRPMParamsEditorGrid = () => {
  const gridApi = useRef(null)
  const { getAgGridTheme } = useInnovaTheme()
  const [curParams, setCurParams] = useRecoilState(bhaCriticalRPMAnalysisParamsAtom)
  const gridDataRef = useRef([
    {
      label: `Variable`,
      value: '',
      id: 0,
      type: 'dropDown',
      tag: 'vibrationVariable',
      dropDownValues: ['WOB', 'INC'],
      visible: true,
    },
    { label: `Start WOB`, value: '', id: 1, type: 'number', tag: 'startWob', min: 0, max: 100, visible: true },
    { label: `End WOB`, value: '', id: 2, type: 'number', tag: `endWob`, min: 0, max: 100, visible: true },
    {
      label: `Start INC`,
      value: '',
      id: 3,
      type: 'number',
      tag: `startInc`,
      min: 0.0,
      max: 90.0,
      visible: false,
    },
    {
      label: `End INC`,
      value: '',
      id: 4,
      type: 'number',
      tag: `endInc`,
      min: 0.0,
      max: 90.0,
      visible: false,
    },
  ])

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: false,
      autoHeight: true,
      editable: false,
      suppressHeaderMenuButton: false,
      headerClass: 'header-no-padding',
    }
  }, [])

  const columnDefs = [
    {
      headerName: '',
      field: 'label',
      colId: 'label',
      minWidth: 150,
      cellStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'end',
      },
    },
    {
      headerName: '',
      field: 'value',
      colId: 'value',
      minWidth: 350,
      editable: true,
      cellDataType: false,
      cellStyle: (params) => {
        return { display: 'flex', alignItems: 'center', justifyContent: 'start' }
      },
      cellEditorSelector: (params) => {
        if (params.data?.type === 'dropDown') {
          return {
            component: 'agSelectCellEditor',
            params: {
              values: params.data?.dropDownValues,
            },
          }
        }

        if (params.data?.type === 'number') {
          return {
            component: 'agTextCellEditor',
            params: {
              precision: 3,
              min: params.data?.min,
              max: params.data?.max,
            },
          }
        }

        if (params.data?.type === 'bool') {
          return {
            component: 'agCheckboxCellEditor',
          }
        }

        return {
          component: 'agTextCellEditor',
        }
      },
      valueSetter: (params) => {
        if (params.oldValue === params.newValue) return

        let gridData = []
        gridApi.current.forEachNode((node) => {
          if (node.data) gridData.push(node.data)
        })

        if (params.data?.type === 'bool' || params.data?.type === 'dropDown') {
          let index = gridData.findIndex((data) => data.id === params.data?.id)
          if (index >= 0) {
            gridData[index].value = params.newValue
          }
        }

        if (params?.data?.type === 'number') {
          let index = gridData.findIndex((data) => data.id === params.data?.id)
          if (params.newValue === null || params.newValue === undefined) params.newValue = 0
          if (index >= 0) gridData[index].value = parseFloat(params.newValue)
        }
      },
      cellRendererSelector: (params) => {
        if (params.data?.type === 'bool') {
          return {
            component: 'agCheckboxCellRenderer',
          }
        }
        return null
      },
      valueFormatter: (params) => {
        if (params?.data?.type === 'number') {
          return `${numberWithCommasDecimals(params?.data?.value, 2)}`
        }
        return params?.data?.value
      },
    },
  ]

  const getRowData = () => {
    let rowData = []
    for (let i = 0; i < gridDataRef.current.length; i++) {
      if (gridDataRef.current[i].visible) rowData.push(gridDataRef.current[i])
    }

    return rowData
  }

  const updateGridData = (newParams) => {
    if (!newParams) return
    for (let i = 0; i < gridDataRef.current.length; i++) {
      if (gridDataRef.current[i].tag === '') continue
      if (!newParams.hasOwnProperty(gridDataRef.current[i].tag)) continue
      gridDataRef.current[i].value = newParams[gridDataRef.current[i].tag]
    }

    if (newParams.vibrationVariable === 'WOB') {
      gridDataRef.current[1].visible = true
      gridDataRef.current[2].visible = true
      gridDataRef.current[3].visible = false
      gridDataRef.current[4].visible = false
    }

    if (newParams.vibrationVariable === 'INC') {
      gridDataRef.current[1].visible = false
      gridDataRef.current[2].visible = false
      gridDataRef.current[3].visible = true
      gridDataRef.current[4].visible = true
    }
  }

  const onGridReady = (params) => {
    gridApi.current = params.api

    updateGridData(curParams)
    gridApi.current.setGridOption('rowData', getRowData())
  }

  const gridOptions = {
    suppressRowClickSelection: true,
    onCellEditingStopped: (event) => {
      let newParams = {}
      if (Array.isArray(gridDataRef.current)) {
        gridDataRef.current.forEach((data, index) => {
          if (event?.data?.tag === data.tag) {
            newParams[data.tag] = event.data.value
            if (event.data.type === 'number') newParams[data.tag] = parseFloat(event.data.value)
            gridDataRef.current[index].value = event.data.value
          }

          newParams[data.tag] = data.value
        })
      }

      setCurParams(newParams)
      updateGridData(newParams)
      gridApi.current.setGridOption('rowData', getRowData())
    },
  }

  const onFirstDataRendered = (params) => {
    if (gridApi.current) autoSizeColumns()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.id)
    }
  }, [])

  return (
    <div style={{ height: '100%', width: '100%' }} className={getAgGridTheme()}>
      <AgGridReact
        className='ag-grid-theme-dark'
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        gridOptions={gridOptions}
        onGridReady={onGridReady}
        headerHeight={0}
        onFirstDataRendered={onFirstDataRendered}
        getRowId={getRowId}
      />
    </div>
  )
}

export default BhaCriticalRPMParamsEditorGrid
