import React, { useEffect, useRef, useState } from 'react'
import {
  currentPageAtom,
  actionBarWidthAtom,
  wellListAtom,
  analyticsRadiusFilterAtom,
  analyticsChartIndexAtom,
  analyticsMapMountedAtom,
} from 'atoms'

import { useSetRecoilState, useRecoilValue } from 'recoil'
import { PAGE_KEYS } from 'components/ActionBar/pageDefs'
import { getItemFromLS, saveItemToLS } from 'utils/localStorage'
import AnalyticsSearchGrid from './AnalyticsSearchGrid'
import MapView from 'components/common/MapView/MapView'
import AnalyticsResultsGrid from './AnalyticsResultsGrid'
import AnalyticsSearchDonut from './AnalyticsSearchDonut'
import SplitPane from 'components/common/SplitPane'
import SlideRotateFootageChart from '../Charts/SlideRotateFootageChart'
import SlideRotateRopChart from '../Charts/SlideRotateRopChart'
import SlideRotateHoursChart from '../Charts/SlideRotateHoursChart'
import CostPerOperatorChart from '../Charts/CostPerOperatorChart'
import CostPerFootChart from '../Charts/CostPerFootChart'
import CostPerWellChart from '../Charts/CostPerWellChart'
import CostPerDayChart from '../Charts/CostPerDayChart'
import DepthVsDaysChart from '../Charts/DepthVsDaysChart'
import RigDaysChart from '../Charts/RigDaysChart'
import BhaResultsCharts from '../Charts/BhaResultsCharts'
import MotorYieldChart from '../Charts/MotorYield/MotorYieldChart'
import ParameterComparisonChart from '../Charts/ParameterComparison/ParameterComparisonChart'
import ParametersRoadmapGrid from '../KpiGrids/ParametersRoadmapGrid'
import ConnectionAnalsysisWellGrid from '../KpiGrids/ConnectionAnalysisWell'
import ConnectionAnalsysisRigGrid from '../KpiGrids/ConnectionAnalysisRig'
import WellRankingKpiGrid from '../KpiGrids/WellRanking/WellRankingKpiGrid'
import MotorKpiGrid from '../KpiGrids/MotorKpiGrid'
import HoleSizeKpiGrid from '../KpiGrids/HoleSizeKpiGrid'
import DaysKpiGrid from '../KpiGrids/DaysKpiGrid'
import WellKpiGrid from '../KpiGrids/WellKpiGrid'
import BhaGroupedbyPhase from '../KpiGrids/BhaGroupedByPhaseKpiGrid'
import PhaseKpiGrid from '../KpiGrids/PhaseKpiGrid'
import TopTenKpiChart from '../Charts/TopTenKpiChart'
import FootageParamChart from '../Charts/FootageRopByParamChart'
import ActivitySummaryChart from '../Charts/ActivitySummaryChart'
import ActivityKpiGrid from '../KpiGrids/ActivityKpiGrid'
import InventoryKpiGrid from '../KpiGrids/InventoryKpiGrid'
import DirectionalScoreCardKpiGrid from '../KpiGrids/DirectionalScoreCard/DirectionalScoreCardKpiGrid'
import TowerComparisonKpiGrid from '../KpiGrids/TowerComparison/TowerComparisonKpiGrid'

import cloneDeep from 'lodash/cloneDeep'
import 'components/common/SplitPane/splitPaneResizer.css'

const AnalyticsPage = () => {
  const _isMounted = useRef(false)
  const setActivePage = useSetRecoilState(currentPageAtom)
  const leftPos = useRecoilValue(actionBarWidthAtom)
  const wellData = useRecoilValue(wellListAtom)
  const [selectedWells, setSelectedWells] = useState([]) //[{id: 0, actualWell: 'xxxx', operator:'xxxx', ..... }, {......}] this is what the map uses to display
  const [filteredWellNames, setFilteredWellNames] = useState([]) //['Well 1', 'Well 2', .....] result from /wellSearch
  const [filteredWells, setFilteredWells] = useState([]) //[{id: 0, actualWell: 'xxxx', operator:'xxxx', ..... }, {......}] this is the wellData run through the filteredWellNames used by the grid
  const radiusFilter = useRecoilValue(analyticsRadiusFilterAtom)
  const pageIndex = useRecoilValue(analyticsChartIndexAtom)
  const setAnalyticsMapMounted = useSetRecoilState(analyticsMapMountedAtom)

  const getPaneVisible = (index) => {
    let pageLayout = getItemFromLS('mapSearchPageLayout', 'pageLayout')
    if (!Array.isArray(pageLayout)) return true
    if (index >= pageLayout.length) return true
    return pageLayout[index].visible
  }

  const [showFilterControls, setShowFilterControls] = useState(getPaneVisible(0))
  const [showGrid, setShowGrid] = useState(getPaneVisible(1))

  useEffect(() => {
    _isMounted.current = true
    setAnalyticsMapMounted(true)
    return () => {
      setAnalyticsMapMounted(false)
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  function degreesToRadians(degrees) {
    return degrees * (Math.PI / 180)
  }

  function haversineDistance(p1, p2) {
    if (!p1 || !p2) return 0
    if (!p1.hasOwnProperty('lat') || !p1.hasOwnProperty('lng')) return 0
    if (!p2.hasOwnProperty('lat') || !p2.hasOwnProperty('lng')) return 0
    if (typeof p1.lat !== 'number' || typeof p1.lng !== 'number') return 0
    if (typeof p2.lat !== 'number' || typeof p2.lng !== 'number') return 0

    const earthRadiusKm = 6371.0

    const dLat = degreesToRadians(p2.lat - p1.lat)
    const dLon = degreesToRadians(p2.lng - p1.lng)

    const lat1Radians = degreesToRadians(p1.lat)
    const lat2Radians = degreesToRadians(p2.lat)

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1Radians) * Math.cos(lat2Radians)

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
    return earthRadiusKm * c * 1000
  }

  const ApplyWellFilter = () => {
    let radiusFilterActive = false
    if (radiusFilter && radiusFilter.hasOwnProperty('isActive') && radiusFilter.isActive) radiusFilterActive = true
    if (!Array.isArray(wellData)) return []
    if (!Array.isArray(filteredWellNames)) return wellData
    if (filteredWellNames.length === 0 && !radiusFilterActive) return wellData
    let wells = []

    for (let i = 0; i < wellData.length; i++) {
      let wellIndex = filteredWellNames.findIndex((well) => well === wellData[i].actualWell)
      if (filteredWellNames.length === 0) wellIndex = 0

      let inRange = true
      if (radiusFilterActive) {
        let dist = haversineDistance(radiusFilter.center, { lat: wellData[i].latitude, lng: wellData[i].longitude })
        if (dist > radiusFilter.radius) inRange = false
      }

      if (wellIndex >= 0 && inRange) wells.push({ ...wellData[i] })
    }

    return wells
  }

  useEffect(() => {
    if (_isMounted.current) {
      setFilteredWells(ApplyWellFilter(wellData))
    }
  }, [wellData, filteredWellNames, radiusFilter]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setActivePage(PAGE_KEYS.mapSearchKpiKey)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const getInitialPaneSize = (index) => {
    let pageLayout = getItemFromLS('mapSearchPageLayout', 'pageLayout')

    if (!Array.isArray(pageLayout)) return index === 0 ? '20%' : '70%'
    if (index >= pageLayout.length) return index === 0 ? '20%' : '70%'
    return pageLayout[index].size
  }

  const onSetPaneVisible = (index, value) => {
    let newLayout = [
      { size: '20%', visible: true },
      { size: '70%', visible: true },
      { size: '70%', visible: true },
    ]

    let pageLayout = getItemFromLS('mapSearchPageLayout', 'pageLayout')
    if (Array.isArray(pageLayout)) newLayout = cloneDeep(pageLayout)
    if (index < newLayout.length) {
      newLayout[index].visible = value
    }

    saveItemToLS('mapSearchPageLayout', 'pageLayout', cloneDeep(newLayout))
  }

  const onDragFinished = (newSize, index) => {
    let newLayout = [
      { size: '20%', visible: true },
      { size: '70%', visible: true },
      { size: '70%', visible: true },
    ]

    let pageLayout = getItemFromLS('mapSearchPageLayout', 'pageLayout')
    if (Array.isArray(pageLayout) && pageLayout.length >= 3) newLayout = cloneDeep(pageLayout)
    newLayout[index].size = newSize
    saveItemToLS('mapSearchPageLayout', 'pageLayout', cloneDeep(newLayout))
  }

  const showHideFilterPane = () => {
    onSetPaneVisible(0, !showFilterControls)
    setShowFilterControls(!showFilterControls)
  }

  const showHideGridPane = () => {
    onSetPaneVisible(1, !showGrid)
    setShowGrid(!showGrid)
  }

  const getSelectedWells = () => {
    if (!Array.isArray(selectedWells)) return []
    if (!Array.isArray(filteredWells)) return []

    let visibleWells = []
    for (let i = 0; i < selectedWells.length; i++) {
      let filteredWellsIndex = filteredWells.findIndex((well) => well.id === selectedWells[i].id)
      if (filteredWellsIndex >= 0) {
        visibleWells.push(selectedWells[i])
      }
    }

    return visibleWells
  }

  return (
    <SplitPane
      split='vertical'
      onDragFinished={(params) => onDragFinished(params, 0)}
      defaultSize={getInitialPaneSize(0)}
      size={showFilterControls ? getInitialPaneSize(0) : '25px'}
      allowResize={showFilterControls}
      style={{
        height: 'calc(100vh - 64px)',
        marginLeft: leftPos,
        width: `calc(100%-${leftPos}px`,
        maxWidth: `calc(100%-${leftPos}px)`,
      }}>
      <SplitPane
        split='horizontal'
        defaultSize={getInitialPaneSize(1)}
        onDragFinished={(params) => onDragFinished(params, 1)}>
        <AnalyticsSearchGrid
          setFilteredWellNames={setFilteredWellNames}
          showHideFilterPane={showHideFilterPane}
          showFilterControls={showFilterControls}
        />
        <AnalyticsSearchDonut
          wellData={filteredWells}
          selectedWells={selectedWells}
          showFilterControls={showFilterControls}
        />
      </SplitPane>
      <SplitPane
        split='horizontal'
        size={showGrid ? getInitialPaneSize(2) : 'calc(100% - 25px)'}
        allowResize={showGrid}
        defaultSize={getInitialPaneSize(2)}
        onDragFinished={(params) => onDragFinished(params, 2)}>
        <>
          <MapView
            wells={getSelectedWells()}
            visible={pageIndex === 'map'}
            pageKey={PAGE_KEYS.mapSearchKpiKey}
            showSearchCircle={true}
          />
          {pageIndex === 'slideRotateFootage' ? <SlideRotateFootageChart /> : null}
          {pageIndex === 'slideRotateRop' ? <SlideRotateRopChart /> : null}
          {pageIndex === 'slideRotateHours' ? <SlideRotateHoursChart /> : null}
          {pageIndex === 'costPerOperator' ? <CostPerOperatorChart /> : null}
          {pageIndex === 'costPerWell' ? <CostPerWellChart /> : null}
          {pageIndex === 'costPerDay' ? <CostPerDayChart /> : null}
          {pageIndex === 'activitySummary' ? <ActivitySummaryChart /> : null}
          {pageIndex === 'depthVDays' ? <DepthVsDaysChart /> : null}
          {pageIndex === 'rigDays' ? <RigDaysChart /> : null}
          {pageIndex === 'bhaResults' ? <BhaResultsCharts /> : null}
          {pageIndex === 'motorYield' ? <MotorYieldChart /> : null}
          {pageIndex === 'paramComparison' ? <ParameterComparisonChart /> : null}
          {pageIndex === 'parametersRoadmap' ? <ParametersRoadmapGrid /> : null}
          {pageIndex === 'connectionAnalysisWell' ? <ConnectionAnalsysisWellGrid /> : null}
          {pageIndex === 'connectionAnalysisRig' ? <ConnectionAnalsysisRigGrid /> : null}
          {pageIndex === 'motorKpi' ? <MotorKpiGrid /> : null}
          {pageIndex === 'activityKpi' ? <ActivityKpiGrid /> : null}
          {pageIndex === 'inventoryKpi' ? <InventoryKpiGrid /> : null}
          {pageIndex === 'towerComparisonKpi' ? <TowerComparisonKpiGrid /> : null}
          {pageIndex === 'motorPerPhaseKpi' ? <BhaGroupedbyPhase /> : null}
          {pageIndex === 'wellRanking' ? <WellRankingKpiGrid /> : null}
          {pageIndex === 'holeSizeKpi' ? <HoleSizeKpiGrid /> : null}
          {pageIndex === 'daysKpi' ? <DaysKpiGrid /> : null}
          {pageIndex === 'phaseKpi' ? <PhaseKpiGrid /> : null}
          {pageIndex === 'wellKpi' ? <WellKpiGrid /> : null}
          {pageIndex === 'directionalScoreCard' ? <DirectionalScoreCardKpiGrid /> : null}
          {pageIndex === 'costPerFt' ? <CostPerFootChart /> : null}
          {pageIndex === 'footageRopByMotor' ? <FootageParamChart url={'/kpi/footagePerMotorMake'} /> : null}
          {pageIndex === 'footageRopByBit' ? <FootageParamChart url={'/kpi/footagePerBitMake'} /> : null}
          {pageIndex === 'footageRopByFormation' ? <FootageParamChart url={'/kpi/footagePerFormation'} /> : null}
          {pageIndex === 'footageRopByHoleSize' ? <FootageParamChart url={'/kpi/footagePerHoleSize'} /> : null}
          {pageIndex === 'footageRopByDirCo' ? <FootageParamChart url={'/kpi/footagePerDirCo'} /> : null}
          {pageIndex === 'footageRopByRig' ? <FootageParamChart url={'/kpi/footagePerRigNoJobDetails'} /> : null}

          {pageIndex === 'fastestSectionKpi' ? (
            <TopTenKpiChart
              url={'/kpi/minHours'}
              dataParam={'totalHours'}
              title={'Fastest Well'}
              pageKey={PAGE_KEYS.fastestSectionKpiKey}
              label={'Hrs'}
            />
          ) : null}
          {pageIndex === 'longestSectionKpi' ? (
            <TopTenKpiChart
              url={'/kpi/maxFootage'}
              dataParam={'footage'}
              title={'Longest Well'}
              pageKey={PAGE_KEYS.longestSectionKpiKey}
              label={'Drilled'}
            />
          ) : null}
          {pageIndex === 'maxDrilledPerDayKpi' ? (
            <TopTenKpiChart
              url={'/kpi/maxFootagePerDay'}
              dataParam={'dailyFootage'}
              title={'Max drilled per day'}
              pageKey={PAGE_KEYS.maxDrilledPerDayKpiKey}
              label={'Drilled'}
            />
          ) : null}
          {pageIndex === 'maxRopKpi' ? (
            <TopTenKpiChart
              url={'/kpi/maxROP'}
              dataParam={'rop'}
              title={'Max ROP'}
              pageKey={PAGE_KEYS.maxRopKpiKey}
              label={'ROP'}
            />
          ) : null}
        </>
        <AnalyticsResultsGrid
          wellData={filteredWells}
          setSelectedWells={setSelectedWells}
          showHideGridPane={showHideGridPane}
          showGrid={showGrid}
        />
      </SplitPane>
    </SplitPane>
  )
}

export default AnalyticsPage
