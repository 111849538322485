import { useEffect, useState, useRef } from 'react'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import { currentWellAtom, userUserRoleAtom } from 'atoms'
import { useRecoilValue } from 'recoil'
import { cloneDeep } from 'lodash'
import { removeSpecialSymbols, numberWithCommasDecimals } from 'utils/stringFunctions'
import useOrgIcons from './useOrgIcons'
import PdfDocument from 'components/common/PDFGen/PdfDocument'
import { unescapeHtml } from 'utils/htmlSymbolHandling'
import * as XLSX from '@sheet/core'
import useUnits, { UNITS_FOR } from 'components/common/hooks/useUnits'
import { numberToString } from 'utils/numberFunctions'
import {
  getStyles,
} from 'components/common/ExcelReports/ExcelReportFunctions'

function usePipeTallys(wellName) {
  const _isMounted = useRef(false)
  const [isLoading, setIsLoading] = useState(false)
  const isDeleting = useRef(false)
  const isUpdating = useRef(false)
  const isAdding = useRef(false)
  const currentWell = useRecoilValue(currentWellAtom)
  const currentWellRef = useRef(wellName ? wellName : currentWell)
  const wellInfo = useRef(null)
  const pipeTallyData = useRef(null)
  const { getCurrentOrgIcon } = useOrgIcons()
  const { getUnitsText } = useUnits()
  const userRole = useRecoilValue(userUserRoleAtom)

  const getWellInfo = useInnovaAxios({
    url: '/well/wellInfo/getWellInfo',
  })

  const getPipeTallys = useInnovaAxios({
    url: '/pipeTally/getTallys',
  })

  const addPipeTally = useInnovaAxios({
    url: '/pipeTally/addTally',
  })

  const deletePipeTally = useInnovaAxios({
    url: '/pipeTally/deleteTallys',
  })

  const updatePipeTally = useInnovaAxios({
    url: '/pipeTally/updateTallys',
  })

  const addTallyJoint = useInnovaAxios({
    url: '/pipeTally/addJoint',
  })

  const addMultipleTallyJoint = useInnovaAxios({
    url: '/pipeTally/addMultipleJoints',
  })

  const deleteTallyJoint = useInnovaAxios({
    url: '/pipeTally/deleteJoint',
  })

  const updateTallyJoint = useInnovaAxios({
    url: '/pipeTally/updateJoint',
  })

  const updateTallyJointSequence = useInnovaAxios({
    url: '/pipeTally/updateJointSequence',
  })

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
    }
  }, [])

  useEffect(() => {
    currentWellRef.current = wellName ? wellName : currentWell
    fetchPipeTallys()
    fetchWellInfo()
  }, [currentWell]) // eslint-disable-line react-hooks/exhaustive-deps

  const deleteTally = async (data) => {
    if (!data) return
    if (!data.hasOwnProperty('tallyId')) return { error: true, message: 'no tallyId' }
    if (isDeleting.current) return
    if (isLoading) return
    if (!_isMounted.current) return
    setIsLoading(true)
    isDeleting.current = true
    let res = await deletePipeTally(data)
    isDeleting.current = false

    if (!_isMounted.current) return
    setIsLoading(false)

    if (res?.error) {
      return { error: true, message: `${res?.error?.response?.data?.error}` }
    }

    if (!Array.isArray(pipeTallyData.current)) return { error: true, message: `pipetallys not array` }
    let index = pipeTallyData.current.findIndex((tally) => tally.tallyId === data.tallyId)
    if (index < 0) return { error: true, message: `index < 0` }
    pipeTallyData.current.splice(index, 1)
    return { error: false, data: data }
  }

  const updateTally = async (data) => {
    if (!data) return
    if (!data.hasOwnProperty('tallyId')) return { error: true, message: 'no tallyId' }
    if (!currentWellRef.current) return { error: true, message: 'no wellname' }
    if (typeof currentWellRef.current !== 'string') return { error: true, message: 'no wellname' }
    if (currentWellRef.current === '') return { error: true, message: 'no wellname' }
    if (isLoading) return
    if (isUpdating.current) return
    if (!_isMounted.current) return

    setIsLoading(true)
    isUpdating.current = true
    let res = await updatePipeTally({ ...data, actualWell: currentWellRef.current })
    isUpdating.current = false

    if (!_isMounted.current) return
    setIsLoading(false)

    if (res?.error) {
      return { error: true, message: `${res?.error?.response?.data?.error}` }
    }

    if (!Array.isArray(pipeTallyData.current)) return { error: true, message: `pipetallys not array` }
    let index = pipeTallyData.current.findIndex((tally) => tally.tallyId === data.tallyId)
    if (index < 0) return { error: true, message: `index < 0` }
    pipeTallyData.current[index] = cloneDeep(data)

    return { error: false, data: cloneDeep(data) }
  }

  const addNewTally = async () => {
    if (!currentWellRef.current) return { error: true, message: 'no wellname' }
    if (typeof currentWellRef.current !== 'string') return { error: true, message: 'no wellname' }
    if (currentWellRef.current === '') return { error: true, message: 'no wellname' }
    if (isAdding.current) return
    if (isLoading) return
    if (!_isMounted.current) return

    setIsLoading(true)
    isAdding.current = true
    let res = await addPipeTally({ actualWell: currentWellRef.current })
    isAdding.current = false

    if (!_isMounted.current) return
    setIsLoading(false)

    if (res?.error) {
      return { error: true, message: `${res?.error?.response?.data?.error}` }
    }

    if (!res.data) return { error: true, message: `res.data not valid` }

    pipeTallyData.current.push(res.data)
    return { error: false, data: res.data }
  }

  const addNewJoint = async (data) => {
    if (!data) return
    if (!data.hasOwnProperty('tallyId')) return { error: true, message: 'no tallyId' }
    if (!currentWellRef.current) return { error: true, message: 'no wellname' }
    if (typeof currentWellRef.current !== 'string') return { error: true, message: 'no wellname' }
    if (currentWellRef.current === '') return { error: true, message: 'no wellname' }
    if (isAdding.current) return { error: true, message: 'currently adding joint' }
    if (isLoading) return { error: true, message: 'currently loading' }
    if (!_isMounted.current) return { error: true, message: 'not mounted' }

    setIsLoading(true)
    isAdding.current = true
    let res = await addTallyJoint({ ...data, actualWell: currentWellRef.current })
    isAdding.current = false

    if (!_isMounted.current) return { error: true, message: 'not mounted' }
    setIsLoading(false)

    if (res?.error) {
      return { error: true, message: `${res?.error?.response?.data?.error}` }
    }

    if (!res.data) return { error: true, message: `res.data not valid` }
    let actionRes = updateJointData(data, 'add')
    if (actionRes.error) return actionRes
    return { error: false, data: res.data }
  }

  const addMultipleJoints = async (tallyId, data) => {
    if (!Array.isArray(data)) return { error: true, message: 'data not an array' }
    if (tallyId === undefined || tallyId === null) return { error: true, message: 'no tallyId' }
    if (!currentWellRef.current) return { error: true, message: 'no wellname' }
    if (typeof currentWellRef.current !== 'string') return { error: true, message: 'no wellname' }
    if (currentWellRef.current === '') return { error: true, message: 'no wellname' }
    if (isAdding.current) return { error: true, message: 'currently adding joint' }
    if (isLoading) return { error: true, message: 'currently loading' }
    if (!_isMounted.current) return { error: true, message: 'not mounted' }

    setIsLoading(true)
    isAdding.current = true
    let res = await addMultipleTallyJoint({
      joints: JSON.stringify(data),
      tallyId: tallyId,
      actualWell: currentWellRef.current,
    })
    isAdding.current = false

    if (!_isMounted.current) return { error: true, message: 'not mounted' }
    setIsLoading(false)

    if (res?.error) {
      return { error: true, message: `${res?.error?.response?.data?.error}` }
    }

    if (!Array.isArray(res.data)) return { error: true, message: `res.data not array` }

    for (let i = 0; i < res.data.length; i++) {
      res.data[i].tallyId = tallyId
      let actionRes = updateJointData(res.data[i], 'add')
      if (actionRes.error) return actionRes
    }

    return { error: false, data: res.data }
  }

  const deleteJoint = async (data) => {
    if (!data) return
    if (!data.hasOwnProperty('uid')) return { error: true, message: 'no uid' }
    if (!currentWellRef.current) return { error: true, message: 'no wellname' }
    if (typeof currentWellRef.current !== 'string') return { error: true, message: 'no wellname' }
    if (currentWellRef.current === '') return { error: true, message: 'no wellname' }
    if (isDeleting.current) return
    if (isLoading) return
    if (!_isMounted.current) return
    setIsLoading(true)
    isDeleting.current = true
    let res = await deleteTallyJoint({ ...data, actualWell: currentWellRef.current })
    isDeleting.current = false

    if (!_isMounted.current) return
    setIsLoading(false)

    if (res?.error) {
      return { error: true, message: `${res?.error?.response?.data?.error}` }
    }

    let actionRes = updateJointData(data, 'delete')
    if (actionRes.error) return actionRes

    return { error: false, data: data }
  }

  const updateJointData = (joint, action) => {
    if (!Array.isArray(pipeTallyData.current)) return { error: true, message: `pipeTallyData not array` }
    let tallyIndex = pipeTallyData.current.findIndex((tally) => tally.tallyId === joint.tallyId)
    if (tallyIndex < 0) return { error: true, message: `tallyIndex < 0` }
    if (!Array.isArray(pipeTallyData.current[tallyIndex].joints)) return { error: true, message: `joints not array` }

    if (action === 'add') {
      pipeTallyData.current[tallyIndex].joints.push(joint)
      return { error: false, message: `` }
    }

    let jointIndex = pipeTallyData.current[tallyIndex].joints.findIndex((jt) => jt.uid === joint.uid)
    if (jointIndex < 0) return { error: true, message: `jointIndex < 0` }

    if (action === 'update') {
      pipeTallyData.current[tallyIndex].joints[jointIndex] = cloneDeep(joint)
    }

    if (action === 'delete') {
      pipeTallyData.current[tallyIndex].joints.splice(jointIndex, 1)
    }

    return { error: false, message: `` }
  }

  const updateJoint = async (data) => {
    if (!data) return
    if (!data.hasOwnProperty('uid')) return { error: true, message: 'no uid' }
    if (!currentWellRef.current) return { error: true, message: 'no wellname' }
    if (typeof currentWellRef.current !== 'string') return { error: true, message: 'no wellname' }
    if (currentWellRef.current === '') return { error: true, message: 'no wellname' }
    if (isLoading) return
    if (isUpdating.current) return
    if (!_isMounted.current) return

    setIsLoading(true)
    isUpdating.current = true
    let res = await updateTallyJoint({ ...data, actualWell: currentWellRef.current })

    isUpdating.current = false

    if (!_isMounted.current) return
    setIsLoading(false)

    if (res?.error) {
      return { error: true, message: `${res?.error?.response?.data?.error}` }
    }

    let actionRes = updateJointData(data, 'update')
    if (actionRes.error) return actionRes

    return { error: false, data: cloneDeep(data) }
  }

  const fetchWellInfo = async () => {
    if (currentWell?.length < 1) return
    const payload = {
      wellName: currentWellRef.current,
    }
    const response = await getWellInfo(payload)
    if (_isMounted.current && response?.data) {
      wellInfo.current = response.data
    }
  }

  const fetchPipeTallys = async () => {
    if (!currentWellRef.current) return
    if (typeof currentWellRef.current !== 'string') return
    if (currentWellRef.current === '') return
    if (isLoading) return
    if (!_isMounted.current) return

    setIsLoading(true)
    let res = await getPipeTallys({ actualWell: currentWellRef.current })

    if (!_isMounted.current) return
    setIsLoading(false)

    if (res?.error) {
      return
    }

    pipeTallyData.current = Array.isArray(res.data) ? res.data.sort((a, b) => a.tallyId - b.tallyId).reverse() : []
  }

  const getPipeTallyData = (tallyId) => {
    if (!Array.isArray(pipeTallyData.current)) return []

    if (tallyId < 0) return pipeTallyData.current
    let tally = pipeTallyData.current.find((tally) => tally.tallyId === tallyId)
    if (tally) return [tally]
    return []
  }

  const addJoints = (tallyData, isPdf = true) => {
    if (!tallyData?.joints) return []
    if (!Array.isArray(tallyData?.joints)) return []

    let joints = []
    if (isPdf) joints.push([{ text: 'Pipe Tally', isHeader: true, columnSpan: 7 }])

    const headerRow = isPdf
      ? [
        { text: '#', isHeader: true },
        { text: 'Length', isHeader: true },
        { text: 'Stand', isHeader: true },
        { text: 'Depth', isHeader: true },
        { text: 'Svy Depth', isHeader: true },
        { text: 'Inc', isHeader: true },
        { text: 'Azi', isHeader: true },
      ]
      : [
        getStyledXlsCellObject('#', true),
        getStyledXlsCellObject(`Length (${getUnitsText(UNITS_FOR.Depth)})`, true),
        getStyledXlsCellObject('Stand', true),
        getStyledXlsCellObject(`Depth (${getUnitsText(UNITS_FOR.Depth)})`, true),
        getStyledXlsCellObject(`Svy Depth (${getUnitsText(UNITS_FOR.Depth)})`, true),
        getStyledXlsCellObject('Inc (°)', true),
        getStyledXlsCellObject('Azi (°)', true),
      ]

    joints.push(headerRow)

    let standCounter = 1
    let jointCounterText = 'X'
    let cumLength = tallyData?.bhaLength

    for (let i = 0; i < tallyData?.joints.length; i++) {
      let standText = jointCounterText

      //This is a deliberate check to of the length of the string as the string length = num joints
      if (jointCounterText.length === tallyData?.numJointsPerStand) {
        standText = standCounter
        standCounter++
        jointCounterText = ''
      }

      jointCounterText += 'X'
      cumLength += tallyData?.joints[i].jointLength

      let jointCount = i + 1
      let jointLength = numberWithCommasDecimals(tallyData?.joints[i].jointLength, 2)
      let depth = numberWithCommasDecimals(cumLength, 2)
      let svyDepth = numberWithCommasDecimals(cumLength - tallyData?.surveyOffset - tallyData?.stickUp, 2)
      let inclination = tallyData?.joints[i].inc > 0 ? numberToString(tallyData.joints[i].inc, 2) : ''
      let azimuth = tallyData?.joints[i].inc > 0 ? numberToString(tallyData.joints[i].azi, 2) : ''
      let highlightCell = i % 6 < 3 ? false : true

      const rowData = isPdf
        ? [
          { text: jointCount },
          { text: jointLength },
          { text: standText },
          { text: depth },
          { text: svyDepth },
          { text: inclination },
          { text: azimuth },
        ]
        : [
          getStyledXlsCellObject(`${jointCount}`, true, 'center'),
          getStyledXlsCellObject(jointLength, false, 'center', highlightCell),
          getStyledXlsCellObject(`${standText}`, false, 'center', highlightCell),
          getStyledXlsCellObject(depth, false, 'center', highlightCell),
          getStyledXlsCellObject(svyDepth, false, 'center', highlightCell),
          getStyledXlsCellObject(inclination, false, 'center', highlightCell),
          getStyledXlsCellObject(azimuth, false, 'center', highlightCell),
        ]

      joints.push(rowData)
    }

    return joints
  }

  const generatePdfDocument = (tallyId) => {
    let index = pipeTallyData.current.findIndex((tally) => tally.tallyId === tallyId)
    if (index < 0) return null

    let tallyData = pipeTallyData.current[index]

    let docData = [
      {
        tableType: 'header',
        showTitle: true,
        title: `${tallyData.actualWell} Pipe Tally #${tallyData.tallyId}`,
        showLogo: true,
        logo: getCurrentOrgIcon(),
        logoWidth: '30%',
        columnWidths: ['15%', '25%', '15%', '55%'],
        fontSize: 6,
        data: [
          [
            { text: 'Job#', isHeader: true },
            { text: wellInfo.current.jobNum, textAlign: 'left' },
            { text: 'Operator', isHeader: true },
            { text: wellInfo.current.operator, textAlign: 'left' },
          ],
          [
            { text: 'API Job#', isHeader: true },
            { text: wellInfo.current.apiNum, textAlign: 'left' },
            { text: 'Field', isHeader: true },
            { text: wellInfo.current.field, textAlign: 'left' },
          ],
          [
            { text: 'Rig', isHeader: true },
            { text: wellInfo.current.rig, textAlign: 'left' },
            { text: 'Well', isHeader: true },
            { text: wellInfo.current.wellName, textAlign: 'left' },
          ],
          [
            { text: 'County:', isHeader: true },
            { text: wellInfo.current.county, textAlign: 'left' },
            { text: 'State:', isHeader: true },
            { text: wellInfo.current.state, textAlign: 'left' },
          ],
        ],
      },
      {
        fontSize: 6,
        sectionAfter: 5,
        manualWidth: true,
        columnWidths: ['20%', '16%', '16%', '16%', '16%', '16%'],
        data: [
          [{ text: 'Description', isHeader: true, columnSpan: 6 }],
          [{ text: tallyData.description, columnSpan: 6 }],
          [
            { text: 'BHA Length', isHeader: true },
            { text: 'Joints Per Stand', isHeader: true },
            { text: 'Stick Up', isHeader: true },
            { text: 'Survey MidJoint', isHeader: true },
            { text: 'Survey Offset', isHeader: true },
            { text: 'Gamma Offset', isHeader: true },
          ],
          [
            { text: tallyData?.bhaLength },
            { text: tallyData.numJointsPerStand },
            { text: tallyData.stickUp },
            { text: tallyData.surveyMidJoint ? 'true' : 'false' },
            { text: tallyData.surveyOffset },
            { text: tallyData.gammaOffset },
          ],
        ],
      },
      {
        fontSize: 6,
        sectionAfter: 5,
        data: [[{ text: 'Comments', isHeader: true }], [{ text: unescapeHtml(tallyData.comments), textAlign: 'left' }]],
      },
      {
        fontSize: 6,
        fixedHeaders: 1,
        manualWidth: true,
        columnWidths: ['10%', '12.5%', '12.5%', '22.5%', '22.5%', '10%', '10%'],
        data: addJoints(tallyData),
      },
    ]

    return docData
  }

  const getPipeTallyPdf = (tallyId) => {
    if (!Array.isArray(pipeTallyData.current)) return null
    if (typeof tallyId !== 'number') return null
    if (!wellInfo) return null

    return {
      fileName: `Pipe Tally - ${removeSpecialSymbols(currentWell)}`,
      data: (
        <PdfDocument
          data={generatePdfDocument(tallyId)}
          multiDocument={false}
          pageOrientation={'portrait'}
          reportSettings={userRole?.userPrefs?.reportSettings}
        />
      ),
    }
  }

  const updateJointSequence = async (data) => {
    if (!Array.isArray(data)) return
    if (data.length === 0) return
    if (!currentWellRef.current) return { error: true, message: 'no wellname' }
    if (typeof currentWellRef.current !== 'string') return { error: true, message: 'no wellname' }
    if (currentWellRef.current === '') return { error: true, message: 'no wellname' }
    if (isLoading) return
    if (isUpdating.current) return
    if (!_isMounted.current) return

    setIsLoading(true)
    isUpdating.current = true

    let res = await updateTallyJointSequence({
      joints: JSON.stringify(
        data.map((item) => {
          return { uid: item.uid, sequenceNo: item.sequenceNo }
        }),
      ),
      actualWell: currentWellRef.current,
    })

    isUpdating.current = false

    if (!_isMounted.current) return
    setIsLoading(false)

    if (res?.error) {
      return { error: true, message: `${res?.error?.response?.data?.error}` }
    }
  }

  const getCellStyle = (
    fgColor = '#FFFFFF',
    horizontalAlign = 'center',
    verticalAlign = 'center',
    bold = false,
    top = '',
    right = '',
    bottom = '',
    left = '',
    fontSize = 12,
    wrap = false,
  ) => {
    return {
      fgColor: { rgb: fgColor },
      alignment: {
        horizontal: horizontalAlign,
        vertical: verticalAlign,
        wrapText: wrap,
      },
      bold: bold,
      top: { style: top },
      right: { style: right },
      bottom: { style: bottom },
      left: { style: left },
      sz: fontSize,
    }
  }

  const getStyledXlsCellObject = (cellValue, isHeader = false, hAlign = 'center', highlightedCell) => {
    if (typeof cellValue !== 'string') return ''

    let backgroundColor = isHeader ? userRole?.userPrefs?.reportSettings.primaryColor : highlightedCell ? '#FFFF99' : '#FFFFFF'
    let bold = isHeader
    let borderLine = isHeader ? 'thin' : 'thin'

    return {
      v: cellValue,
      t: 's',
      s: getCellStyle(backgroundColor, hAlign, 'center', bold, borderLine, borderLine, borderLine, borderLine),
    }
  }

  const makeEmptyArray = (size) => {
    if (typeof size !== 'number') return
    return Array(size).fill('')
  }

  const generateXlsHeaderRow = (firstHeader, firstVal, secondHeader, secondVal) => {
    return [
      getStyledXlsCellObject(firstHeader, true, 'right'),
      firstVal,
      ...makeEmptyArray(2),
      getStyledXlsCellObject(secondHeader, true, 'right'),
      secondVal,
      ...makeEmptyArray(1),
    ]
  }

  const handleCreatePipeTallyXlsWorksheet = (tallyData) => {
    if (!tallyData || typeof tallyData !== 'object') return null
    let pipeTallyDetails = addJoints(tallyData, false)

    const styles = getStyles(userRole?.userPrefs?.reportSettings?.primaryColor)

    let ws = XLSX.utils.aoa_to_sheet(
      [
        [`Pipe Tally - BHA ${tallyData?.tallyId}`],
        generateXlsHeaderRow('Well Name:', tallyData?.well, 'Wellbore Name:', tallyData?.actualWell),
        generateXlsHeaderRow('BHA Length:', `${tallyData?.bhaLength}`, 'Survey Offset:', `${tallyData?.surveyOffset}`),
        generateXlsHeaderRow('Gamma Offset:', `${tallyData?.gammaOffset}`, 'Stick Up:', `${tallyData?.stickUp}`),
        ...makeEmptyArray(1),
        ['Comments'],
        [tallyData?.comments],
        ...makeEmptyArray(1),
        ...pipeTallyDetails,
        ['icpwebportal'],
      ],
      { origin: 'B2' },
    )

    ws['!merges'] = [
      // title box
      {
        s: { r: 1, c: 1 },
        e: { r: 1, c: 7 },
        style: styles.centerBoldtttt,
      },
      // Well Name data
      {
        s: { r: 2, c: 2 },
        e: { r: 2, c: 4 },
        style: styles.leftttt,
      },
      // Wellbore name data
      {
        s: { r: 2, c: 6 },
        e: { r: 2, c: 7 },
        style: styles.leftttt,
      },
      // BHA length data
      {
        s: { r: 3, c: 2 },
        e: { r: 3, c: 4 },
        style: styles.leftttt,
      },
      // survey offset data
      {
        s: { r: 3, c: 6 },
        e: { r: 3, c: 7 },
        style: styles.leftttt,
      },
      // Gamma offset data
      {
        s: { r: 4, c: 2 },
        e: { r: 4, c: 4 },
        style: styles.leftttt,
      },
      // stick up data
      {
        s: { r: 4, c: 6 },
        e: { r: 4, c: 7 },
        style: styles.leftttt,
      },
      // comments header
      {
        s: { r: 6, c: 1 },
        e: { r: 6, c: 7 },
        style: styles.fillCenterBoldtttt,
      },
      // comments data
      {
        s: { r: 7, c: 1 },
        e: { r: 7, c: 7 },
        style: styles.leftttt,
      },
      // icpwebportal footnote
      {
        s: { r: 9 + pipeTallyDetails?.length, c: 1 },
        e: { r: 9 + pipeTallyDetails?.length, c: 7 },
        style: styles.righttttt,
      },
    ]

    //set cell width (default wch: 8)
    ws['!sheetFormat'] = {
      col: {
        wch: 18,
      },
    }

    return ws
  }

  const getXlsFileName = (tallyId) => {
    if (!tallyId || typeof tallyId !== 'number') return 'Pipe Tally.xlsx'
    let exportDate = new Date(Date.now()).toISOString().substring(0, 10).split('-')
    let xlsLabel = tallyId > 0 ? `${tallyId}` : 'All'

    return `${exportDate[1]}_${exportDate[2]}_${exportDate[0]}_${wellInfo.current?.operator ? wellInfo.current.operator : ''
      }_${currentWellRef.current}_Pipe_Tally_${xlsLabel}.xlsx`
  }

  const getXlsCellRange = (start, end) => {
    if (!start || !end) return 'A1:A2'
    let startAddress = XLSX.utils.encode_cell(start)
    let endAddress = XLSX.utils.encode_cell(end)
    return `${startAddress}:${endAddress}`
  }

  const onXlsExport = (tallyId) => {
    if (!tallyId || typeof tallyId !== 'number') tallyId = -1
    if (!Array.isArray(pipeTallyData.current)) return null
    if (!currentWellRef.current) return null
    if (currentWellRef.current?.length < 1) return null

    let tallyData = getPipeTallyData(tallyId).reverse()

    let wb = XLSX.utils.book_new()

    for (let i = 0; i < tallyData.length; i++) {
      let ws = handleCreatePipeTallyXlsWorksheet(tallyData[i])

      //add border around entire sheet
      let cellAddresses = ws['!ref'].split(':')
      XLSX.utils.sheet_set_range_style(ws, `B2:${cellAddresses[1]}`, {
        top: { style: 'thin' },
        right: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
      })

      if (ws.hasOwnProperty('!merges')) {
        ws['!merges'].forEach((cellAddresses) => {
          const { s, e, style } = cellAddresses

          let cellRange = getXlsCellRange(s, e)

          if (style) XLSX.utils.sheet_set_range_style(ws, cellRange, style)
        })
      }

      XLSX.utils.book_append_sheet(wb, ws, `Pipe Tally ${tallyData[i].tallyId}`)
    }

    XLSX.writeFile(wb, getXlsFileName(tallyId), {
      cellStyles: true,
    })
  }

  return {
    isLoading,
    getPipeTallyPdf,
    getPipeTallyData,
    fetchPipeTallys,
    addNewTally,
    deleteTally,
    updateTally,
    addNewJoint,
    deleteJoint,
    updateJoint,
    updateJointSequence,
    addMultipleJoints,
    onXlsExport,
  }
}

export default usePipeTallys
