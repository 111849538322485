import React, { useMemo, useRef, useEffect, useState } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import { styled } from '@mui/styles'
import { Icon as Iconify } from '@iconify/react'
import { Tooltip, Box, IconButton, Snackbar } from '@mui/material'
import Alert from '@mui/material/Alert'
import { AgGridReact } from 'ag-grid-react'
import useUnits, { UNITS_FOR } from 'components/common/hooks/useUnits'
import { round } from 'utils/numberFunctions'
import { numberWithCommasDecimals } from 'utils/stringFunctions'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import { currentWellSelector } from 'atoms'
import { useRecoilValue } from 'recoil'
import { threeDeeScan } from 'utils/threeDeeScan'
import { getStringId, CustomHeader } from 'components/common/AgGridUtils'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const StyledDeleteIcon = styled(Iconify)({
  color: '#C00000',
  fontSize: '16px',
})

const StyledAddIcon = styled(Iconify)({
  color: '#00C000',
  fontSize: '16px',
})

const StyledIconHeader = styled(IconButton)({
  padding: '5px',
  alignItems: 'center',
  justifyContent: 'center',
})

const InterpolateModal = ({ open, onClose, crsUnits, wellPlan = [] }) => {
  const uidCounter = useRef(0)
  const _isMounted = useRef(false)
  const interpData = useRef([])
  const gridApi = useRef(null)
  const isCalculating = useRef(false)
  const inputRow = useRef({})
  const { getUnitsText } = useUnits()
  const { getAgGridTheme } = useInnovaTheme()
  const currentWell = useRecoilValue(currentWellSelector)
  const [status, setStatus] = useState({ show: false, severity: 'info', message: '' })

  const interpMd = useInnovaAxios({
    url: '/survey/interpMd',
  })

  const interpTvd = useInnovaAxios({
    url: '/survey/interpTvd',
  })

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
    }
  }, [])

  const interpolateMd = async (md) => {
    if (isCalculating.current) return null
    if (typeof currentWell !== 'string') return null
    if (typeof md !== 'number') return null
    if (currentWell === '') return null
    isCalculating.current = true

    const response = await interpMd({ wellName: currentWell, md: md })
    isCalculating.current = false

    if (_isMounted.current) {
      if (response.error) {
        setStatus({ show: true, severity: 'error', message: `${response.error?.response?.data?.error}` })
        return null
      }

      return response.data
    }

    return null
  }

  const interpolateTvd = async (tvd) => {
    if (isCalculating.current) return null
    if (typeof currentWell !== 'string') return null
    if (typeof tvd !== 'number') return null
    if (currentWell === '') return null
    isCalculating.current = true

    const response = await interpTvd({ wellName: currentWell, tvd: tvd })
    isCalculating.current = false

    if (_isMounted.current) {
      if (response.error) {
        setStatus({ show: true, severity: 'error', message: `${response?.error?.response?.data?.error}` })
        return null
      }

      return response.data
    }

    return null
  }

  const defaultColDef = useMemo(
    () => ({
      sortable: null,
      editable: false,
      resizable: true,
      maxWidth: 120,
      valueFormatter: (params) =>
        isEmptyPinnedCell(params) ? createPinnedCellPlaceholder(params) : decimalValueFormatter(params, 2),
    }),
    [], // eslint-disable-line react-hooks/exhaustive-deps
  )

  function isPinnedRowDataCompleted() {
    return inputRow.current?.md || inputRow.current?.tvd
  }

  function createPinnedCellPlaceholder({ colDef }) {
    if (colDef.field !== 'md' && colDef.field !== 'tvd') return ''
    return colDef.field[0].toUpperCase() + colDef.field.slice(1) + '...'
  }

  function isEmptyPinnedCell({ node, value }) {
    return (node?.rowPinned === 'bottom' && value == null) || (node?.rowPinned === 'bottom' && value === '')
  }

  const handleDelete = async (uid) => {
    if (!Array.isArray(uid)) return
    if (uid.length === 0) return
    if (!gridApi.current) return

    gridApi.current.applyTransaction({ remove: uid })
    interpData.current = interpData.current.filter((element) => element.uid !== uid[0].uid)
  }

  const components = useMemo(() => {
    return {
      agColumnHeader: CustomHeader,
    }
  }, [])

  const handleAddRow = async () => {
    if (!isPinnedRowDataCompleted()) return

    let svyResp = null
    if (inputRow.current.md !== undefined && inputRow.current.md !== null) {
      svyResp = await interpolateMd(parseFloat(inputRow.current.md))
    }

    if (inputRow.current.tvd !== undefined && inputRow.current.tvd !== null) {
      svyResp = await interpolateTvd(parseFloat(inputRow.current.tvd))
    }

    if (svyResp !== null) {
      svyResp.uid = uidCounter.current
      uidCounter.current++

      let acsvy = { ...svyResp }
      acsvy = threeDeeScan(acsvy, Array.isArray(wellPlan) ? wellPlan : [])
      svyResp.ud = acsvy.acScan?.UD
      svyResp.lr = acsvy.acScan?.LR
      svyResp.dist = acsvy.acScan?.dist

      if (svyResp.dist === -9999.25) {
        svyResp.ud = 0
        svyResp.lr = 0
        svyResp.dist = 0
      }

      interpData.current.push({ ...svyResp })

      gridApi.current.applyTransaction({
        add: [svyResp],
      })
    }

    inputRow.current = {}
    if (gridApi.current) gridApi.current.setGridOption('pinnedBottomRowData', [inputRow.current])
  }

  const handleUpdate = async (data, col) => {
    if (!data) return

    let svyResp = null
    if (col === 'md') {
      svyResp = await interpolateMd(parseFloat(data.md))
    }

    if (col === 'tvd') {
      svyResp = await interpolateTvd(parseFloat(data.tvd))
    }

    if (svyResp !== null) {
      svyResp.uid = data.uid
      let acsvy = { ...svyResp }
      acsvy = threeDeeScan(acsvy, Array.isArray(wellPlan) ? wellPlan : [])
      svyResp.ud = acsvy.acScan?.UD
      svyResp.lr = acsvy.acScan?.LR
      svyResp.dist = acsvy.acScan?.dist

      if (svyResp.dist === -9999.25) {
        svyResp.ud = 0
        svyResp.lr = 0
        svyResp.dist = 0
      }

      gridApi.current.applyTransaction({ update: [svyResp] })
    }
  }

  const actionIconRenderer = (params) => {
    return (
      <React.Fragment>
        <Box style={{ display: 'flex', flexDirection: 'row' }}>
          <Tooltip
            title={params.node?.rowPinned !== 'bottom' ? 'Delete' : 'Add'}
            placement='left'
            componentsProps={{
              tooltip: {
                sx: {
                  backgroundColor: 'rgb(19,62,96)',
                  fontSize: '12px',
                  fontFamily: 'Roboto',
                },
              },
            }}>
            <StyledIconHeader
              onClick={() =>
                params.node?.rowPinned !== 'bottom' ? handleDelete([{ uid: params.data?.uid }]) : handleAddRow()
              }
              size='large'>
              {params.node?.rowPinned !== 'bottom' ? (
                <StyledDeleteIcon icon='fa-regular:trash-alt' />
              ) : (
                <StyledAddIcon icon='fluent:add-12-filled' />
              )}
            </StyledIconHeader>
          </Tooltip>
          {params.node?.rowPinned !== 'bottom' ? (
            <Box style={{ textAlign: 'right', paddingLeft: '8px' }}>{params.node.rowIndex + 1}</Box>
          ) : null}
        </Box>
      </React.Fragment>
    )
  }

  const decimalValueFormatter = (params, decPlaces) => {
    if (params?.value === undefined) return ''
    return numberWithCommasDecimals(round(params.value, decPlaces), decPlaces)
  }

  const columnDefs = [
    {
      field: 'actions',
      width: 86,
      headerName: '',
      cellRendererSelector: (params) => {
        return {
          component: actionIconRenderer,
        }
      },
      pinned: 'left',
      lockPosition: 'left',
    },
    {
      field: 'md',
      headerName: 'MD',
      editable: true,
      pinned: 'left',
      headerComponentParams: { units: getUnitsText(UNITS_FOR.Depth) },
      lockPosition: 'left',
    },
    {
      field: 'inc',
      headerName: 'INC',
      headerComponentParams: { units: '°' },
      pinned: 'left',
      lockPosition: 'left',
    },
    {
      field: 'azi',
      headerName: 'AZI',
      headerComponentParams: { units: '°' },
      pinned: 'left',
      lockPosition: 'left',
    },
    {
      field: 'tvd',
      headerName: 'TVD',
      editable: true,
      headerComponentParams: { units: getUnitsText(UNITS_FOR.Depth) },
    },
    {
      field: 'tvdSs',
      headerName: 'TVD SS',
      headerComponentParams: { units: getUnitsText(UNITS_FOR.Depth) },
    },
    {
      field: 'ns',
      headerName: 'NS',
      headerComponentParams: { units: getUnitsText(UNITS_FOR.Depth) },
    },
    {
      field: 'ew',
      headerName: 'EW',
      headerComponentParams: { units: getUnitsText(UNITS_FOR.Depth) },
    },
    {
      field: 'vs',
      headerName: 'VS',
      headerComponentParams: { units: getUnitsText(UNITS_FOR.Depth) },
    },
    {
      field: 'gridNorth',
      headerName: 'Grid North',
      headerComponentParams: { units: crsUnits },
    },
    {
      field: 'gridEast',
      headerName: 'Grid East',
      headerComponentParams: { units: crsUnits },
    },
    {
      field: 'latDeg',
      headerName: 'LAT',
      headerComponentParams: { units: getUnitsText(UNITS_FOR.LatLong) },
      valueFormatter: (params) => decimalValueFormatter(params, 6),
    },
    {
      field: 'longDeg',
      headerName: 'LONG',
      headerComponentParams: { units: getUnitsText(UNITS_FOR.LatLong) },
      valueFormatter: (params) => decimalValueFormatter(params, 6),
    },
    {
      field: 'ud',
      headerName: 'UD',
      headerComponentParams: { units: getUnitsText(UNITS_FOR.Depth) },
    },
    {
      field: 'lr',
      headerName: 'LR',
      headerComponentParams: { units: getUnitsText(UNITS_FOR.Depth) },
    },
    {
      field: 'dist',
      headerName: 'DIST',
      headerComponentParams: { units: getUnitsText(UNITS_FOR.Depth) },
    },
  ]

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const gridOptions = {
    pinnedBottomRowData: [inputRow.current],
    onCellEditingStopped: (event) => {
      if (event.node?.rowPinned === 'bottom') {
        handleAddRow()
      }
      if (event.node?.rowPinned !== 'bottom') handleUpdate(event.data, event.colDef.field)
    },
    getRowStyle: ({ node }) => (node?.rowPinned ? { fontWeight: 'bold', fontStyle: 'italic' } : 0),
  }

  const handleCloseStatus = (event, reason) => {
    setStatus({ show: false, severity: 'info', message: '' })
  }

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.uid)
    }
  }, [])

  return (
    <Dialog
      maxWidth='xl'
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: '70vw',
          height: '60vh',
          backgroundColor: 'itemBackground',
        },
      }}>
      <DialogTitle>Interpolate Surveys</DialogTitle>
      <DialogContent style={{ overflow: 'auto' }}>
        {' '}
        <Box className={getAgGridTheme()} style={{ width: '100%', height: '100%' }}>
          <AgGridReact
            rowData={interpData.current}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            animateRows={true}
            suppressColumnVirtualisation={true}
            suppressScrollOnNewData={true}
            components={components}
            onGridReady={onGridReady}
            maintainColumnOrder={true}
            enableRangeSelection={true}
            suppressMultiRangeSelection={true}
            gridOptions={gridOptions}
            headerHeight={30}
            getRowId={getRowId}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant='contained' type='button' color='primary' onClick={() => onClose()}>
          Close
        </Button>
      </DialogActions>
      {status?.show ? (
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={status?.show}
          autoHideDuration={5000}
          onClose={handleCloseStatus}>
          <Alert onClose={handleCloseStatus} severity={status.severity} elevation={4} variant='filled'>
            {status.message}
          </Alert>
        </Snackbar>
      ) : null}
    </Dialog>
  )
}

export default InterpolateModal
