import React, { forwardRef, useImperativeHandle, useMemo, useRef } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { LABEL_CATEGORIES as labelCategories } from '../ChartElements/labelDefinitions'
import ColorPickerCellEditor from 'components/common/CellEditors/ColorPickerCellEditor'
import { getStringId } from 'components/common/AgGridUtils'
import { CHART_TYPES as chartTypes } from 'components/WellPages/WallplotComposer/Elements/elementDefs'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'
import DepthLabelCellEditor from '../../../../common/CellEditors/DepthLabelCellEditor'
import useUnits, { UNITS_FOR } from 'components/common/hooks/useUnits'

const getDepthLabelDefault = () => {
  return {
    mdTvd: 'MD',
    inc: true,
    azi: true,
    interval: 100,
    atSurvey: true,
    range: false,
    startDepth: 0,
    endDepth: 0,
  }
}

const ChartLabelsGrid = forwardRef(({ chartData, chartType }, ref) => {
  const gridApi = useRef(null)
  const { getAgGridTheme } = useInnovaTheme()
  const { getUnitsText } = useUnits()

  useImperativeHandle(ref, () => ({
    stopEditing: () => {
      if (gridApi.current) gridApi.current.stopEditing()
    },
    getData: () => {
      let categoryData = []
      let categoryOptions = []

      const rows = gridApi.current?.getSelectedRows()
      rows.forEach((row) => {
        categoryData.push({
          category: row.category,
          enabled: true,
          color: row.color,
          size: row.size,
          bold: row.bold,
          hasTether: row.hasTether,
        })
        if (row.category === labelCategories.surveyPts) {
          categoryOptions.push({
            category: row.category,
            options: row.options,
          })
        }
      })

      return {
        labels: {
          categories: categoryData,
          categoryOptions: categoryOptions,
          labelData: [], // probably need to populate this here eventually,
          // if a separate labels style grid is implemented. (so we can switch between grids)
        },
      }
    },
  }))

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.id)
    }
  }, [])

  const onGridReady = (params) => {
    gridApi.current = params.api
    gridApi.current.setGridOption('rowData', getRowData())
    // // set rows as selected if enabled
    gridApi.current.forEachNode((node) => {
      node.setSelected(node.data.enabled)
    })
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const onFirstDataRendered = (params) => {
    if (gridApi.current) autoSizeColumns()
  }

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: false,
      autoHeight: true,
      editable: false,
      suppressHeaderMenuButton: false,
      headerClass: 'header-no-padding',
    }
  }, [])

  const gridOptions = {
    suppressRowClickSelection: true,
    popupParent: document.querySelector('chartLabelDiv'),
    onCellEditingStopped: (params) => {
      if (params.node.data?.category === labelCategories.surveyPts && params.colDef?.colId === 'options') {
        params.api.applyTransaction({ update: [params.node.data] })
      }
    },
  }

  const centerAlignCell = useMemo(
    () => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }),
    [],
  )

  const columnDefs = useMemo(
    () => [
      {
        headerName: 'Category',
        headerCheckboxSelection: false,
        checkboxSelection: true,
        field: 'category',
        colId: 'category',
        minWidth: 100,
        width: 200,
        maxWidth: 250,
        editable: false,
        cellStyle: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'start',
        },
        pinned: 'left',
        lockPosition: 'left',
      },
      {
        headerName: 'Color',
        field: 'color',
        colId: 'color',
        minWidth: 90,
        width: 90,
        maxWidth: 90,
        flex: 1,
        editable: true,
        cellDataType: false,
        cellStyle: (params) => {
          return {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'start',
            backgroundColor: params.value,
            color: params.value,
          }
        },
        cellEditorSelector: (params) => {
          return {
            component: ColorPickerCellEditor,
            params: {
              color: params.value,
              defaultColor: params.data?.defaultValue,
            },
            popup: true,
          }
        },
      },
      {
        headerName: 'Size',
        field: 'size',
        colId: 'size',
        flex: 1,
        editable: true,
        cellStyle: centerAlignCell,
      },
      {
        headerName: 'Bold',
        field: 'bold',
        colId: 'bold',
        flex: 1,
        editable: true,
        cellStyle: centerAlignCell,
        cellEditorSelector: (params) => {
          return {
            component: 'agCheckboxCellEditor',
          }
        },
      },
      {
        headerName: 'Has Tether',
        field: 'hasTether',
        colId: 'hasTether',
        flex: 1,
        editable: true,
        cellStyle: centerAlignCell,
        cellEditorSelector: (params) => {
          return {
            component: 'agCheckboxCellEditor',
          }
        },
      },
      {
        headerName: 'Options',
        field: 'options',
        colId: 'options',
        flex: 1,
        editable: (params) => {
          let surveysSel = false
          gridApi.current.forEachNode((node) => {
            if (node.data.category === labelCategories.surveyPts) {
              surveysSel = node.selected
            }
          })
          return params.data.category === labelCategories.surveyPts && surveysSel
        },
        cellStyle: centerAlignCell,
        cellEditorPopup: true,
        cellEditorSelector: (params) => {
          if (params.data.category !== labelCategories.surveyPts) {
            return undefined
          }
          const option = chartData.labels.categoryOptions.find((opt) => opt.category === labelCategories.surveyPts)
          if (!option) return undefined

          return {
            component: DepthLabelCellEditor,
            params: { ...params.value, unit: getUnitsText(UNITS_FOR.Depth) },
          }
        },
        valueFormatter: (params) => {
          if (params.data.category !== labelCategories.surveyPts) {
            return undefined
          }

          if (typeof params.value !== 'object') return ''
          if (!params.value.hasOwnProperty('mdTvd')) return ''

          return `${params.value.mdTvd ? params.value.mdTvd : ''}${params.value.inc ? ' Inc' : ''}${
            params.value.azi ? ' Azi' : ''
          }`
        },
        valueGetter: (params) => {
          if (params.data.category !== labelCategories.surveyPts) {
            return undefined
          }

          if (!params.data.options || !params.data.options.hasOwnProperty('mdTvd')) {
            return {
              ...getDepthLabelDefault(),
              unit: getUnitsText(UNITS_FOR.Depth),
            }
          }

          return params.data.options
        },
      },
    ],
    [centerAlignCell, chartData.labels, getUnitsText],
  )

  const getRowData = (data) => {
    let rowData = []
    // if (!data) return rowData // enable when data is attached to layout template

    rowData.push({
      category: labelCategories.surveyPts,
      color: '#0000AA',
      size: 9,
      bold: false,
      hasTether: false,
      options: getDepthLabelDefault(),
    })
    rowData.push({
      category: labelCategories.wellNames,
      color: '#AA0000',
      size: 12,
      bold: true,
      hasTether: true,
    })
    rowData.push({
      category: labelCategories.casing,
      color: '#00AA00',
      size: 10,
      bold: false,
      hasTether: false,
    })
    rowData.push({
      category: labelCategories.annotations,
      color: '#0000AA',
      size: 9,
      bold: false,
      hasTether: false,
    })
    if (chartData.showTargets) {
      rowData.push({
        category: labelCategories.targets,
        color: '#000000',
        size: 10,
        bold: true,
        hasTether: false,
      })
    }
    if (chartType === chartTypes.sectionView) {
      rowData.push({
        category: labelCategories.lithologies,
        color: '#000000',
        size: 12,
        bold: true,
        hasTether: false,
      })
    }
    if (chartData.showOffsets) {
      rowData.push({
        category: labelCategories.offsetWellNames,
        color: '#000000',
        size: 12,
        bold: true,
        hasTether: false,
      })
      rowData.push({
        category: labelCategories.offsetSurveyPts,
        color: '#000000',
        size: 12,
        bold: true,
        hasTether: false,
      })
    }

    rowData.forEach((row, index) => {
      row.enabled = false
      const idx = chartData.labels.categories.findIndex((cat) => cat.category === row.category)
      if (idx >= 0) {
        row.enabled = chartData.labels.categories[idx].enabled
        row.color = chartData.labels.categories[idx].color
        row.size = chartData.labels.categories[idx].size
        row.bold = chartData.labels.categories[idx].bold
        row.hasTether = chartData.labels.categories[idx].hasTether
        if (row.category === labelCategories.surveyPts) {
          row.options = chartData.labels.categoryOptions.find(
            (opt) => opt.category === labelCategories.surveyPts,
          )?.options
        }
      }
      row.id = index
    })

    return rowData
  }

  return (
    <div style={{ height: '100%', width: '100%' }} className={getAgGridTheme()} id='chartLabelDiv'>
      <AgGridReact
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        gridOptions={gridOptions}
        onGridReady={onGridReady}
        headerHeight={30}
        onFirstDataRendered={onFirstDataRendered}
        getRowId={getRowId}
        rowSelection='multiple'
      />
    </div>
  )
})

export default ChartLabelsGrid
