import React, { useMemo, useEffect, useRef, useState, useCallback, forwardRef, useImperativeHandle } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { appColors } from 'utils'
import 'components/common/agGridStyles.css'
import { Box, Tooltip, Avatar, Alert, Snackbar } from '@mui/material'
import useUnits, { UNITS_FOR } from 'components/common/hooks/useUnits'
import { numberWithCommas } from 'utils/stringFunctions'
import { saveItemToLS } from 'utils/localStorage'
import cloneDeep from 'lodash/cloneDeep'
import SlideSheetDetailsGrid from './SlideSheetDetailsGrid'
import motorIcon from 'assets/bhaIcons/motor.png'
import BhaDetailsModal from './BhaDetailsModal'
import { round } from 'utils/numberFunctions'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import { Icon as Iconify } from '@iconify/react'
import { currentWellAtom } from 'atoms'
import { useRecoilValue } from 'recoil'
import { sortColDefs, htmlSymbolHandling, showHideDetailGrid, getStringId } from 'components/common/AgGridUtils'
import { unescapeHtml } from 'utils/htmlSymbolHandling'
import PopupCellRenderer from 'components/WellPages/DailyReportsPages/PopupCellRenderer'
import { styled } from '@mui/styles'
import ErrorCellRenderer from 'components/WellPages/DrillStringPages/ErrorCellRenderer'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const StyledIconContainer = styled(Box)({
  color: appColors.itemTextColor,
  width: '30px',
  height: '30px',
  margin: '5px',
  marginRight: '10px',
  textAlign: 'center',
  '&:hover': {
    cursor: 'pointer',
  },
})

const StyledMenuIcon = styled(Iconify)({
  color: appColors.itemTextColor,
  width: '30px',
  height: '30px',
})

const SlideSheetGrid = ({
  setShowChart,
  showChart,
  slideSheetData,
  isLoading,
  fetchSlideSheet,
  onPdfExport,
  onXlsExport,
  wellName,
  setSelectedBha,
}) => {
  const _isMounted = useRef(false)
  const gridApi = useRef(null)
  const [resetCols, setResetCols] = useState(false)
  const { getUnitsText } = useUnits()
  const [showBhaModal, setShowBhaModal] = useState(false)
  const containerRef = useRef(null)
  const selectedSlidesheet = useRef(null)
  const slideSheetId = useRef(-1)
  const fetchingQc = useRef(false)
  const currentWell = useRecoilValue(currentWellAtom)
  const [status, setStatus] = useState({ show: false, severity: 'info', message: '' })
  const [slideSheetQc, setSlideSheetQc] = useState(false)
  const { getAgGridTheme, theme } = useInnovaTheme()

  const addSlideSheet = useInnovaAxios({
    url: '/well/slideSheets/addSlideSheet',
  })

  const updateSlideSheet = useInnovaAxios({
    url: '/well/slideSheets/updateMultiSlideSheet',
  })

  const getSlideSheetQc = useInnovaAxios({
    url: '/well/slideSheets/QcSlideSheets',
  })

  const fetchSlideSheetQc = useCallback(async () => {
    if (!wellName) return
    if (typeof wellName !== 'string') return
    if (wellName === '') return
    if (fetchingQc.current) return

    fetchingQc.current = true
    let res = await getSlideSheetQc({ wellName: wellName })

    fetchingQc.current = false
    if (!_isMounted.current) return
    if (res.error) return

    setSlideSheetQc(Array.isArray(res.data) ? res.data : [])
  }, [getSlideSheetQc, wellName])

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
    }
  }, [])

  useEffect(() => {
    fetchSlideSheetQc()
  }, [wellName]) // eslint-disable-line react-hooks/exhaustive-deps

  const columnFormatter = (params) => {
    const { first } = params

    return {
      fill: first ? appColors.slideColor : appColors.rotateColor,
    }
  }

  const ropColumnFormatter = (params) => {
    const { first, last } = params

    return {
      fill: first ? appColors.slideColor : last ? appColors.avgColor : appColors.rotateColor,
    }
  }

  const tooltipRenderer = (params) => {
    const { yValue, xValue } = params
    return `<div class='grid-sparkline-tooltip'>
                <div class='grid-sparkline-tooltip-title'>
                    <div>${xValue}: ${yValue.toFixed(2)}</div>
                </div>
            </div>`
  }

  const getHighestValue = useCallback(
    (first, second) => {
      if (!Array.isArray(slideSheetData)) return
      if (slideSheetData?.length <= 0) return
      let largestAmount = 0
      if (slideSheetData?.length <= 0) return 1
      slideSheetData.forEach((item) => {
        if (item[first] > largestAmount) {
          largestAmount = item[first]
        }
        if (item[second] > largestAmount) {
          largestAmount = item[second]
        }
      })

      return largestAmount
    },
    [slideSheetData],
  )

  const isValidDate = (value) => {
    return value instanceof Date || !isNaN(Date.parse(value))
  }

  const dateSortComparator = useCallback((date1, date2) => {
    if (!isValidDate(date1) || !isValidDate(date2)) return 0
    const parsedDate1 = new Date(Date.parse(date1))
    const parsedDate2 = new Date(Date.parse(date2))

    return parsedDate1.getTime() - parsedDate2.getTime()
  }, [])

  const handleMenuClick = useCallback(
    async (action, data) => {
      if (typeof action !== 'string') return
      if (!data) return

      selectedSlidesheet.current = data

      if (action === 'print') {
        onPdfExport([data?.bhaNum])
      }
    },
    [onPdfExport],
  )

  const centerAlignCell = () => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  })

  const expandIconRenderer = (params) => {
    return (
      <Box
        onClick={() => showHideDetailGrid(params, 'bhaNum', false, setShowChart, showChart?.current)}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          '&:hover': {
            cursor: 'pointer',
          },
        }}>
        <Iconify
          icon={!params?.node?.expanded ? 'material-symbols:chevron-right-rounded' : 'mdi:chevron-down'}
          style={{ color: theme === 'dark' ? '#FFF' : '#000', height: '30px', width: '30px' }}
        />
      </Box>
    )
  }

  const columnDefs = [
    {
      field: 'slideSheetDetail',
      colId: 'slideSheetDetail',
      headerName: '',
      suppressHeaderMenuButton: true,
      suppressHeaderFilterButton: true,
      sortable: false,
      resizable: false,
      pinned: 'left',
      lockPosition: 'left',
      cellRenderer: expandIconRenderer,
      cellStyle: centerAlignCell,
      onCellClicked: (params) => {
        const bhaNum = params.data?.bhaNumRep
        setSelectedBha(bhaNum)
      },
    },
    {
      field: 'warnings',
      colId: 'warnings',
      headerName: '',
      suppressHeaderMenuButton: true,
      suppressHeaderFilterButton: true,
      sortable: false,
      resizable: false,
      width: 30,
      pinned: 'left',
      lockPosition: 'left',
      cellRenderer: (params) => {
        return (
          <ErrorCellRenderer
            errors={Array.isArray(slideSheetQc) ? slideSheetQc.filter((err) => err.bhaNum === params.data?.bhaNum) : []}
          />
        )
      },
      cellStyle: centerAlignCell,
      onCellClicked: (params) => {
        const bhaNum = params.data?.bhaNumRep
        setSelectedBha(bhaNum)
      },
    },
    {
      headerName: '',
      field: 'menu',
      colId: 'menu',
      cellStyle: centerAlignCell,
      cellRenderer: PopupCellRenderer,
      cellRendererParams: (params) => {
        let options = {
          showBottomRow: false,
          menuItems: [
            {
              label: 'Print slide sheet',
              action: 'print',
              onClick: handleMenuClick,
              icon: () => (
                <StyledIconContainer>
                  <StyledMenuIcon icon='ic:baseline-print' />
                </StyledIconContainer>
              ),
            },
          ],
        }
        return options
      },
      onCellClicked: (params) => {
        const bhaNum = params.data?.bhaNumRep
        setSelectedBha(bhaNum)
      },
      pinned: 'left',
      lockPosition: 'left',
      editable: false,
      suppressHeaderMenuButton: true,
      suppressHeaderFilterButton: true,
      resizable: false,
      filter: null,
      width: 70,
    },
    {
      headerName: 'BHA #',
      colId: 'rptNo',
      field: 'bhaNumRep',
      sortable: true,
      cellStyle: centerAlignCell,
      onCellClicked: (params) => {
        const bhaNum = params.data?.bhaNumRep
        setSelectedBha(bhaNum)
      },
    },
    {
      headerName: 'Description',
      colId: 'bhaDesc',
      field: 'bhaDescription',
      cellStyle: (params) => {
        return { ...centerAlignCell, justifyContent: 'start' }
      },
      valueGetter: (params) => unescapeHtml(params.data?.bhaDescription),
      onCellClicked: (params) => {
        const bhaNum = params.data?.bhaNumRep
        setSelectedBha(bhaNum)
      },
    },
    {
      headerName: `Total Drilled (${getUnitsText(UNITS_FOR.Depth)})`,
      colId: 'totalDrilled',
      field: 'totalDrilled',
      sortable: true,
      cellStyle: centerAlignCell,
      valueFormatter: (params) => {
        return `${numberWithCommas(round(params.data?.totalDrilled, 2))}`
      },
      onCellClicked: (params) => {
        const bhaNum = params.data?.bhaNumRep
        setSelectedBha(bhaNum)
      },
    },
    {
      headerName: `Footage (${getUnitsText(UNITS_FOR.Depth)})`,
      colId: 'footage',
      cellRenderer: 'agSparklineCellRenderer',
      suppressHeaderMenuButton: true,
      suppressHeaderFilterButton: true,
      cellRendererParams: {
        sparklineOptions: {
          type: 'bar',
          paddingOuter: 0.25,
          tooltip: {
            xOffset: 20,
            yOffset: -20,
            renderer: tooltipRenderer,
          },
          formatter: columnFormatter,
          label: {
            enabled: true,
            placement: 'outsideEnd',
            color: 'white',
            fontSize: 9,
          },
          stroke: '#91cc75',
          highlightStyle: {
            fill: '#fac858',
          },
          valueAxisDomain: [0, getHighestValue('slideDrilled', 'rotateDrilled')],
          padding: {
            top: 0,
            bottom: 0,
            right: 30,
          },
          axis: {
            strokeWidth: 0,
          },
        },
      },
      valueGetter: (params) => {
        return [
          ['Slide', params.data?.slideDrilled],
          ['Rotate', params.data?.rotateDrilled],
        ]
      },
      onCellClicked: (params) => {
        const bhaNum = params.data?.bhaNumRep
        setSelectedBha(bhaNum)
      },
    },
    {
      headerName: 'Drilling Hours',
      colId: 'drillingHours',
      field: 'totalDrillingHours',
      sortable: true,
      cellStyle: centerAlignCell,
      valueGetter: (params) => {
        return round(params.data?.totalDrillingHours, 2)
      },
      onCellClicked: (params) => {
        const bhaNum = params.data?.bhaNumRep
        setSelectedBha(bhaNum)
      },
    },
    {
      headerName: 'Hours',
      colId: 'hours',
      cellRenderer: 'agSparklineCellRenderer',
      suppressHeaderMenuButton: true,
      suppressHeaderFilterButton: true,
      cellRendererParams: {
        sparklineOptions: {
          paddingOuter: 0.5,
          type: 'bar',
          tooltip: {
            xOffset: 20,
            yOffset: -20,
            renderer: tooltipRenderer,
          },
          formatter: columnFormatter,
          label: {
            enabled: true,
            placement: 'outsideEnd',
            color: 'white',
            fontSize: 9,
          },
          stroke: '#91cc75',
          highlightStyle: {
            fill: '#fac858',
          },
          valueAxisDomain: [0, getHighestValue('slideHours', 'rotateHours')],
          padding: {
            top: 0,
            bottom: 0,
            right: 30,
          },
          axis: {
            strokeWidth: 0,
          },
        },
      },
      valueGetter: (params) => {
        return [
          ['Slide Hrs', params.data?.slideHours],
          ['Rotate Hrs', params.data?.rotateHours],
        ]
      },
      onCellClicked: (params) => {
        const bhaNum = params.data?.bhaNumRep
        setSelectedBha(bhaNum)
      },
    },
    {
      headerName: `ROP (${getUnitsText(UNITS_FOR.Depth)}/hr)`,
      colId: 'rop',
      cellRenderer: 'agSparklineCellRenderer',
      suppressHeaderMenuButton: true,
      suppressHeaderFilterButton: true,
      cellRendererParams: {
        sparklineOptions: {
          paddingOuter: 0.5,
          type: 'bar',
          tooltip: {
            xOffset: 20,
            yOffset: -20,
            renderer: tooltipRenderer,
          },
          formatter: ropColumnFormatter,
          label: {
            enabled: true,
            placement: 'outsideEnd',
            color: 'white',
            fontSize: 9,
          },
          stroke: '#91cc75',
          highlightStyle: {
            fill: '#fac858',
          },
          valueAxisDomain: [0, getHighestValue('slideRop', 'rotateRop')],
          padding: {
            top: 0,
            bottom: 0,
            right: 30,
          },
          axis: {
            strokeWidth: 0,
          },
        },
      },
      valueGetter: (params) => {
        return [
          ['Slide ROP', params.data?.slideRop],
          ['Rotate ROP', params.data?.rotateRop],
          ['Avg ROP', params.data?.avgRop],
        ]
      },
      onCellClicked: (params) => {
        const bhaNum = params.data?.bhaNumRep
        setSelectedBha(bhaNum)
      },
    },
    {
      headerName: 'BHA Details',
      colId: 'bhaDetails',
      suppressHeaderMenuButton: true,
      suppressHeaderFilterButton: true,
      cellRenderer: (params) => {
        const { data } = params
        return (
          <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center', marginTop: '8px' }}>
            <Tooltip
              title='BHA Details'
              placement='bottom'
              componentsProps={{
                tooltip: {
                  sx: {
                    backgroundColor: 'rgb(19,62,96)',
                    fontSize: '12px',
                    fontFamily: 'Roboto',
                  },
                },
              }}>
              <Box
                sx={{
                  width: '25px',
                  height: '25px',
                  '&:hover': {
                    cursor: 'pointer',
                  },
                  background: 'lightGrey',
                  borderRadius: '50%',
                  justifyContent: 'center',
                }}
                onClick={(event) => {
                  event.stopPropagation()
                  slideSheetId.current = data?.bhaNumRep
                  setShowBhaModal(true)
                }}>
                <Avatar sx={{ marginLeft: '-7px', marginTop: '-3px' }} alt='BHA Details' src={motorIcon} />
              </Box>
            </Tooltip>
          </Box>
        )
      },
      onCellClicked: (params) => {
        const bhaNum = params.data?.bhaNumRep
        setSelectedBha(bhaNum)
      },
    },
    {
      headerName: 'Date In',
      colId: 'dateIn',
      field: 'dateIn',
      sortable: true,
      comparator: dateSortComparator,
      valueFormatter: (params) => {
        if (!params.data?.dateIn) return ''
        if (typeof params.data?.dateIn !== 'string') return ''
        if (params.data?.dateIn === '') return ''
        if (isDateLessThan(params.data?.dateIn, '1990-01-01')) return ''
        return new Date(Date.parse(params.data?.dateIn.replace('Z', ''))).toLocaleDateString('default', {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
        })
      },
      onCellClicked: (params) => {
        const bhaNum = params.data?.bhaNumRep
        setSelectedBha(bhaNum)
      },
    },
    {
      headerName: 'Date Out',
      colId: 'dateOut',
      field: 'dateOut',
      sortable: true,
      comparator: dateSortComparator,
      valueFormatter: (params) => {
        if (!params.data?.dateOut) return ''
        if (typeof params.data?.dateOut !== 'string') return ''
        if (params.data?.dateOut === '') return ''
        if (isDateLessThan(params.data?.dateOut, '1990-01-01')) return ''
        return new Date(Date.parse(params.data?.dateOut.replace('Z', ''))).toLocaleDateString('default', {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
        })
      },
      onCellClicked: (params) => {
        const bhaNum = params.data?.bhaNumRep
        setSelectedBha(bhaNum)
      },
    },
    {
      headerName: `Depth In (${getUnitsText(UNITS_FOR.Depth)})`,
      colId: 'depthIn',
      field: 'depthIn',
      sortable: true,
      cellStyle: centerAlignCell,
      valueFormatter: (params) => {
        return `${numberWithCommas(round(params.data?.depthIn, 2))}`
      },
      onCellClicked: (params) => {
        const bhaNum = params.data?.bhaNumRep
        setSelectedBha(bhaNum)
      },
    },
    {
      headerName: `Depth Out (${getUnitsText(UNITS_FOR.Depth)})`,
      colId: 'depthOut',
      field: 'depthOut',
      sortable: true,
      cellStyle: centerAlignCell,
      valueFormatter: (params) => {
        return `${numberWithCommas(round(params.data?.depthOut, 2))}`
      },
      onCellClicked: (params) => {
        const bhaNum = params.data?.bhaNumRep
        setSelectedBha(bhaNum)
      },
    },
  ]

  function isDateLessThan(date1, date2) {
    const time1 = new Date(date1).getTime()
    const time2 = new Date(date2).getTime()

    return time1 < time2
  }

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: false,
      autoHeight: true,
      editable: false,
      suppressHeaderMenuButton: false,
      cellStyle: { textAlign: 'right', padding: 0 },
      headerClass: 'header-no-padding',
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
    }
  }, [])

  const saveColumnState = () => {
    if (gridApi.current) {
      const colLayout = gridApi.current.getColumnState()
      if (colLayout) saveItemToLS('slideSheetGrid', 'colLayout', colLayout)
    }
  }

  const gridOptions = {
    suppressRowClickSelection: true,
    detailRowAutoHeight: true,
    sideBar: {
      toolPanels: [
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        },
      ],
      defaultToolPanel: '',
      position: 'left',
    },
    onDragStopped: (event) => {
      saveColumnState()
    },
    onColumnVisible: (event) => {
      saveColumnState()
    },
  }

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const onFirstDataRendered = (params) => {
    if (gridApi.current) gridApi.current.onFilterChanged()
    autoSizeColumns()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    setTimeout(() => {
      gridApi.current?.autoSizeAllColumns()
    }, 100)
  }

  const getContextMenuItems = (params) => {
    return [
      {
        name: 'Reset columns',
        disabled: false,
        action: () => {
          gridApi.current.resetColumnState()
          saveItemToLS('slideSheetGrid', 'colLayout', null)
          setResetCols(!resetCols)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Reset filters',
        disabled: false,
        action: () => {
          gridApi.current.setFilterModel(null)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      'copy',
      {
        name: 'Export',
        disabled: false,
        action: () => {
          gridApi.current.exportDataAsExcel({
            fileName: 'SlideSheetHeadersData.xlsx',
            sheetName: 'SlideSheet',
          })
        },
        icon: '<span class="iconify" data-icon="icomoon-free:file-excel" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
    ]
  }

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.bhaNum)
    }
  }, [])

  const handleUpdates = async (data, newRowData) => {
    if (!newRowData) return false
    if (!Array.isArray(newRowData.slideRecords)) return false
    if (!data) return false
    if (!Array.isArray(data)) return false
    if (data.length === 0) return true
    for (let i = 0; i < data.length; i++) {
      let index = newRowData.slideRecords.findIndex((slideRow) => slideRow.uid === data[i].uid)
      if (index < 0) return false
      newRowData.slideRecords[index] = { ...data[i] }
      data[i].startDepth = parseFloat(data[i].startDepth)
      data[i].endDepth = parseFloat(data[i].endDepth)
      data[i].bhaNum = parseInt(data[i].bhaNum)
      data[i].uid = parseInt(data[i].uid)
      data[i] = htmlSymbolHandling(data[i])
    }

    if (gridApi.current) {
      gridApi.current.applyTransaction({
        update: [cloneDeep(newRowData)],
      })
    }

    let response = await updateSlideSheet({
      slideData: JSON.stringify(data),
      wellName: currentWell,
    })
    if (response?.error) {
      setStatus({ show: true, severity: 'error', message: response?.error?.response?.data?.error })
      return false
    }
    return true
  }

  const updateSlideSheetGridCallback = async (params) => {
    if (!params) return
    if (!params.hasOwnProperty('action')) return
    if (!gridApi.current) return
    if (!Array.isArray(params.data)) return
    if (params.data?.length === 0) return
    if (params.data?.length > 1 && (params.action === 'delete' || params.action === 'add')) return

    const currentRowData = gridApi.current.getRowNode(params.data[0].bhaNum)
    if (!currentRowData) return
    let newRowData = cloneDeep(currentRowData.data)

    if (params.action === 'delete') {
      let index = newRowData.slideRecords.findIndex((slideRow) => slideRow.uid === params.data[0].uid)
      if (index < 0) return
      newRowData.slideRecords.splice(index, 1)
    }

    //Updates need to run before the adds to ensure the sequence number on the add is correct
    if (params.action === 'update') {
      let res = await handleUpdates(params.data, newRowData)
      if (!res) return
    }

    if (params.action === 'add' && params.hasOwnProperty('dataToUpdate') && Array.isArray(params.dataToUpdate)) {
      let res = await handleUpdates(params.dataToUpdate, newRowData)
      if (!res) return
    }

    if (params.action === 'add') {
      let payload = {
        wellName: currentWell,
        startTime: params.data[0].startTime,
        endTime: params.data[0].endTime,
        startDepth: params.data[0].startDepth,
        endDepth: params.data[0].endDepth,
        bhaNum: params.data[0].bhaNum,
        state: params.data[0].state,
        date: params.data[0].date,
        wob: params.data[0].wob,
        onBtmPress: params.data[0].onBtmPress,
        offBtmPress: params.data[0].offBtmPress,
        rpm: params.data[0].rpm,
        torque: params.data[0].torque,
        temperature: params.data[0].temperature,
        puWeight: params.data[0].puWeight,
        soWeight: params.data[0].soWeight,
        rotWeight: params.data[0].rotWeight,
      }

      let response = await addSlideSheet(htmlSymbolHandling(payload))
      if (response?.error) {
        setStatus({ show: true, severity: 'error', message: response?.error?.response?.data?.error })
        return
      }
    }

    let updatedSlideSheetData = await fetchSlideSheet(params.data[0].bhaNum)
    if (!updatedSlideSheetData) return

    newRowData.slideRecords = cloneDeep(updatedSlideSheetData)

    const detailApi = gridApi.current.getDetailGridInfo(`SLIDE_DETAIL_GRID-${params?.data[0]?.bhaNum}`)
    if (!detailApi) return true
    detailApi.api.setGridOption('rowData', Array.isArray(newRowData?.slideRecords) ? newRowData?.slideRecords : [])

    if (gridApi.current) {
      gridApi.current.applyTransaction({
        update: [newRowData],
      })
    }
  }

  const DetailCellRenderer = forwardRef((params, ref) => {
    useImperativeHandle(ref, () => {
      return {
        refresh() {
          return true
        },
      }
    })

    let height = 200
    if (!params?.data.hasOwnProperty('slideRecords') || params?.data?.slideRecords?.length <= 0) return height
    if (containerRef.current) {
      height = containerRef.current.clientHeight * 0.8 - 70 // 70 px for pinned row
      let heightByRowCount = params?.data?.slideRecords?.length * 41.6 + 100
      if (heightByRowCount < height) height = heightByRowCount
    }

    let prevSlideSheetEndDepth = -1
    let nextSlideSheetStartDepth = 9999999

    if (params?.data.hasOwnProperty('bhaNum') && Array.isArray(slideSheetData)) {
      let slideSheetIdx = slideSheetData.findIndex((sheet) => sheet.bhaNum === params?.data.bhaNum)
      if (slideSheetIdx >= 0 && slideSheetIdx < slideSheetData.length - 1) {
        if (
          Array.isArray(slideSheetData[slideSheetIdx + 1].slideRecords) &&
          slideSheetData[slideSheetIdx + 1].slideRecords.length > 0
        ) {
          prevSlideSheetEndDepth =
            slideSheetData[slideSheetIdx + 1].slideRecords[slideSheetData[slideSheetIdx + 1].slideRecords.length - 1]
              .endDepth
        }
      }

      if (slideSheetIdx >= 1) {
        if (
          Array.isArray(slideSheetData[slideSheetIdx - 1].slideRecords) &&
          slideSheetData[slideSheetIdx - 1].slideRecords.length > 0
        ) {
          nextSlideSheetStartDepth = slideSheetData[slideSheetIdx - 1].slideRecords[0].startDepth
        }
      }
    }

    return (
      <Box sx={{ height: height }}>
        <SlideSheetDetailsGrid
          masterGridApi={gridApi.current}
          parentRowData={params?.data}
          refreshCallback={updateSlideSheetGridCallback}
          setStatus={setStatus}
          prevSlideSheetEndDepth={prevSlideSheetEndDepth}
          nextSlideSheetStartDepth={nextSlideSheetStartDepth}
          onXlsExport={onXlsExport}
        />
      </Box>
    )
  })

  const handleCloseStatus = () => {
    setStatus({ show: false, severity: 'info', message: '' })
  }

  const getBhaData = () => {
    if (slideSheetData.current <= 0) return []
    let foundBha = slideSheetData.find((item) => {
      return item?.bhaNumRep === slideSheetId.current
    })
    if (foundBha.hasOwnProperty('bitData') && foundBha.hasOwnProperty('motorData')) {
      const { bitData, motorData } = foundBha
      return [bitData, motorData]
    }
    return []
  }

  return (
    <React.Fragment>
      {showBhaModal ? <BhaDetailsModal bhaData={getBhaData()} setOpen={() => setShowBhaModal(false)} /> : null}
      <div ref={containerRef} style={{ height: '100%', width: '100%' }} className={getAgGridTheme()}>
        <AgGridReact
          loading={isLoading}
          rowData={slideSheetData}
          columnDefs={sortColDefs(columnDefs, 'slideSheetGrid')}
          headerHeight={30}
          defaultColDef={defaultColDef}
          gridOptions={gridOptions}
          onGridReady={onGridReady}
          masterDetail={true}
          detailCellRenderer={DetailCellRenderer}
          getContextMenuItems={getContextMenuItems}
          onFirstDataRendered={onFirstDataRendered}
          animateRows={true}
          getRowId={getRowId}
        />
      </div>
      {status?.show ? (
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={status?.show}
          autoHideDuration={2000}
          onClose={handleCloseStatus}>
          <Alert onClose={handleCloseStatus} severity={status.severity} elevation={4} variant='filled'>
            {status.message}
          </Alert>
        </Snackbar>
      ) : null}
    </React.Fragment>
  )
}

export default SlideSheetGrid
