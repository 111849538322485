import React, { useEffect, useRef, useState } from 'react'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import {
  currentPageAtom,
  currentInfrastructureAcDashSelector,
  currentInfrastructureEc2Selector,
  currentInfrastructureRdsSelector,
  currentInfrastructureIcdsSelector,
  currentInfrastructureIcdsVersionSelector,
  currentInfrastructureLogSelector,
  currentInfrastructureChartRangeAtom,
  currentInfrastructurePendingLogsSelector,
  actionBarWidthAtom,
  currentInfrastructureAcqCacheSelector,
} from 'atoms'
import { PAGE_KEYS } from 'components/ActionBar/pageDefs'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import * as jszip from 'jszip'
import { currentAwsRegionAtom } from 'atoms'
import InputModal from 'components/common/InputModal'
import * as yup from 'yup'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'
import { cloneDeep } from 'lodash'
import { CircularProgress, Box } from '@mui/material'

import InfrastructureDashboard from './InfrastructureDashboard'
import Ec2UtilizationChart from './Cards/EC2Utilization/Ec2UtilizationChart'
import RdsUtilizationChart from './Cards/RdsUtilization/RdsUtilizationChart'
import ApiLast24HrsChart from './Cards/ApiErrorsLast24Hrs/ApiErrorsLast24HrsChart'
import IcdsUtilizationChart from './Cards/IcdsUtilization/IcdsUtilizationChart'
import DatabaseConnectionsDonut from './Cards/DatabaseConnectionsChart/DatabaseConnectionsDonut'
import DatabaseConnectionsCard from './Cards/DatabaseConnections/DatabaseConnectionsCard'
import PendingApiCallsCard from './Cards/PendingApiCalls/PendingApiCallsCard'
import MemoryUtilizationCard from './Cards/MemoryUtilization/MemoryUtilizationCard'
import TopQuerysCard from './Cards/TopQuerys/TopQuerysCard'
import DataCacheChart from './Cards/DataAcqCache/DataCacheChart'
import AcDashOrgBarChart from './Cards/AcDashboard/AcDashOrgBarChart'
import AcDashRedisGrid from './Cards/AcDashboard/AcDashRedisGrid'

const wellDashLayout = {
  lg: [
    { i: 'a', x: 0, y: 0, w: 4, h: 3 },
    { i: 'b', x: 4, y: 0, w: 4, h: 3 },
    { i: 'c', x: 0, y: 1, w: 4, h: 3 },
    { i: 'd', x: 4, y: 1, w: 4, h: 3 },
    { i: 'e', x: 0, y: 3, w: 2, h: 2 },
    { i: 'f', x: 4, y: 3, w: 4, h: 2 },
    { i: 'g', x: 2, y: 4, w: 2, h: 2 },
    { i: 'h', x: 4, y: 5, w: 4, h: 2 },
    { i: 'i', x: 0, y: 7, w: 4, h: 2 },
    { i: 'j', x: 4, y: 7, w: 4, h: 2 },
    { i: 'k', x: 0, y: 9, w: 4, h: 2 },
    { i: 'l', x: 4, y: 9, w: 4, h: 2 },
    { i: 'm', x: 0, y: 11, w: 4, h: 2 },
    { i: 'n', x: 4, y: 11, w: 4, h: 2 },
    { i: 'o', x: 0, y: 13, w: 4, h: 2 },
  ],
  md: [
    { i: 'a', x: 0, y: 0, w: 2, h: 1 },
    { i: 'b', x: 2, y: 0, w: 2, h: 1 },
    { i: 'c', x: 0, y: 1, w: 2, h: 2 },
    { i: 'd', x: 2, y: 1, w: 2, h: 2 },
    { i: 'e', x: 0, y: 3, w: 2, h: 2 },
    { i: 'f', x: 2, y: 3, w: 2, h: 2 },
    { i: 'g', x: 0, y: 5, w: 2, h: 2 },
    { i: 'h', x: 2, y: 5, w: 2, h: 2 },
    { i: 'i', x: 0, y: 7, w: 2, h: 2 },
    { i: 'j', x: 2, y: 7, w: 2, h: 2 },
    { i: 'k', x: 0, y: 9, w: 2, h: 2 },
    { i: 'l', x: 4, y: 9, w: 4, h: 2 },
    { i: 'm', x: 0, y: 11, w: 4, h: 2 },
    { i: 'n', x: 4, y: 11, w: 4, h: 2 },
    { i: 'o', x: 0, y: 13, w: 4, h: 2 },
  ],
  sm: [
    { i: 'a', x: 0, y: 0, w: 2, h: 1 },
    { i: 'b', x: 2, y: 0, w: 2, h: 1 },
    { i: 'c', x: 0, y: 1, w: 2, h: 2 },
    { i: 'd', x: 2, y: 1, w: 2, h: 2 },
    { i: 'e', x: 0, y: 3, w: 2, h: 2 },
    { i: 'f', x: 2, y: 3, w: 2, h: 2 },
    { i: 'g', x: 0, y: 5, w: 2, h: 2 },
    { i: 'h', x: 2, y: 5, w: 2, h: 2 },
    { i: 'i', x: 0, y: 7, w: 2, h: 2 },
    { i: 'j', x: 2, y: 7, w: 2, h: 2 },
    { i: 'k', x: 0, y: 9, w: 2, h: 2 },
    { i: 'l', x: 4, y: 9, w: 4, h: 2 },
    { i: 'm', x: 0, y: 11, w: 4, h: 2 },
    { i: 'n', x: 4, y: 11, w: 4, h: 2 },
    { i: 'o', x: 0, y: 13, w: 4, h: 2 },
  ],
}

const dashboardId = 'serverDash'
const WELL_READ_CACHE = 'wellReadCache'
const WELL_DATA_CACHE = 'wellDataCache'

const getDashboardCards = () => {
  const dashCards = [
    {
      key: 'a',
      Component: Ec2UtilizationChart,
      title: 'EC2 CPU Usage',
      visible: true,
    },
    {
      key: 'b',
      Component: RdsUtilizationChart,
      title: 'RDS CPU Usage',
      visible: true,
    },
    {
      key: 'c',
      Component: IcdsUtilizationChart,
      title: 'ICDS CPU & Memory Usage',
      visible: true,
    },
    {
      key: 'd',
      Component: ApiLast24HrsChart,
      title: 'API Errors & Warnings',
      visible: true,
    },
    {
      key: 'e',
      Component: DatabaseConnectionsDonut,
      title: 'Database Connections',
      visible: true,
    },
    {
      key: 'f',
      Component: DatabaseConnectionsCard,
      title: 'Database Connection Details',
      visible: true,
    },
    {
      key: 'g',
      Component: PendingApiCallsCard,
      title: 'Pending Api Calls',
      visible: true,
    },
    {
      key: 'h',
      Component: TopQuerysCard,
      title: 'Top Querys',
      visible: true,
    },
    {
      key: 'i',
      Component: MemoryUtilizationCard,
      title: 'Database Memory Utilization',
      visible: true,
    },
    {
      key: 'j',
      Component: () => <DataCacheChart id={WELL_READ_CACHE} />,
      title: 'Data Acq Cache (Read)',
      visible: true,
    },
    {
      key: 'k',
      Component: () => <DataCacheChart id={WELL_DATA_CACHE} />,
      title: 'Data Acq Cache (Write)',
      visible: true,
    },
    {
      key: 'l',
      Component: AcDashOrgBarChart,
      title: 'AC Dashboard Cache',
      visible: true,
    },
    {
      key: 'm',
      Component: AcDashRedisGrid,
      title: 'Redis Status',
      visible: true,
    },
  ]
  return dashCards
}

const DashboardAdminPage = () => {
  const _isMounted = useRef(false)
  const setActivePage = useSetRecoilState(currentPageAtom)
  const setInfrastructureAcDashData = useSetRecoilState(currentInfrastructureAcDashSelector)
  const setInfrastructureEc2Data = useSetRecoilState(currentInfrastructureEc2Selector)
  const setInfrastructureRdsData = useSetRecoilState(currentInfrastructureRdsSelector)
  const setInfrastructureIcdsData = useSetRecoilState(currentInfrastructureIcdsSelector)
  const setInfrastructureIcdsVersionData = useSetRecoilState(currentInfrastructureIcdsVersionSelector)
  const setInfrastructureLogData = useSetRecoilState(currentInfrastructureLogSelector)
  const setInfrastructurePendingLogsData = useSetRecoilState(currentInfrastructurePendingLogsSelector)
  const setInfrastructureAcqCache = useSetRecoilState(currentInfrastructureAcqCacheSelector)
  const [dashboardCards, setDashboardCards] = useState()
  const [currentAwsRegion, setCurrentAwsRegion] = useRecoilState(currentAwsRegionAtom)
  const [awsRegionsList, setAwsRegionsList] = useState([])
  const [showEditor, setShowEditor] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [isLoadingLogs, setLoadingLogs] = useState(false)
  const [status, setStatus] = useState({ show: false, severity: 'info', message: '' })
  const timeScaleSetting = useRecoilValue(currentInfrastructureChartRangeAtom)
  const leftPos = useRecoilValue(actionBarWidthAtom)
  const [isLoadingAcqCache, setLoadingAcqCache] = useState()

  useEffect(() => {
    if (_isMounted.current && currentAwsRegion?.length > 0) {
      fetchHealthData()
    }
  }, [currentAwsRegion]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (_isMounted.current) {
      fetchHealthData()
    }
  }, [timeScaleSetting]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    _isMounted.current = true
    setActivePage(PAGE_KEYS.adminInnovaDashboardKey)
    fetchHealthData()
    fetchApiLogData()
    fetchAcqCache()
    fetchAcDashData()
    setDashboardCards(getDashboardCards())

    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const getInfraStructureHealth = useInnovaAxios({
    url: '/admin/getInfrastructureHealth',
  })

  const getCacheStatus = useInnovaAxios({
    url: '/antiCollision/cache/getStatus',
  })

  const getWrpStatus = useInnovaAxios({
    url: '/admin/wrp/status',
  })

  const getApiLogData = useInnovaAxios({
    url: '/admin/logs/detail',
    responseType: 'blob', // critically important to properly retrieve the zip file
  })

  const getAcqCacheData = useInnovaAxios({
    url: '/dataAcq/getCache',
  })

  const fetchAcDashData = async () => {
    if (isLoading) return
    if (!_isMounted.current) return
    setLoading(true)

    const res = await getCacheStatus({ awsRegion: currentAwsRegion })
    if (!_isMounted.current) return
    setLoading(false)

    if (res?.error) {
      setStatus({ show: true, severity: 'error', message: `${res?.error?.response?.data?.error}` })
      return
    }

    setInfrastructureAcDashData(res?.data)
  }

  const fetchApiLogData = async () => {
    if (isLoadingLogs) return

    setLoadingLogs(true)
    const res = await getApiLogData({
      param: 'all',
    })

    if (!_isMounted.current) return

    if (res.error) {
      setLoadingLogs(false)
      setStatus({ show: true, severity: 'error', message: `${res?.error?.response?.data?.error}` })
      return
    }

    if (!res.data) {
      setLoadingLogs(false)
      return
    }

    let blobData = new Blob([res.data])
    let zip = await jszip.loadAsync(blobData, { base64: true })
    let jsonData = await zip.files['jsonLog.json'].async('string')
    let sortedData = JSON.parse(jsonData.replace(/\0/g, ''))
    if (!Array.isArray(sortedData) || sortedData.length === 0) return
    setInfrastructureLogData(sortedData)
    setLoadingLogs(false)
  }

  const fetchAcqCache = async () => {
    if (isLoadingAcqCache) return

    setLoadingAcqCache(true)
    const res = await getAcqCacheData({
      option: 'statsOnly',
    })

    if (!_isMounted.current) return

    if (res.error) {
      setLoadingAcqCache(false)
      setStatus({ show: true, severity: 'error', message: `${res?.error?.response?.data?.error}` })
      return
    }

    if (!res.data) {
      setLoadingAcqCache(false)
      return
    }

    if (res.data.hasOwnProperty('wellReadCache') && res.data.hasOwnProperty('wellDataCache')) {
      setInfrastructureAcqCache(res.data)
    }

    setLoadingAcqCache(false)
  }

  const fetchHealthData = async () => {
    if (!currentAwsRegion) return
    if (typeof currentAwsRegion !== 'string') return
    if (currentAwsRegion === '') return
    if (isLoading) return
    if (!_isMounted.current) return

    setLoading(true)
    const res = await getInfraStructureHealth({
      awsRegion: currentAwsRegion,
      timeScale: timeScaleSetting ? '30DAY' : '24HR',
      params: 'all',
    })

    if (!_isMounted.current) return
    if (res.error) {
      setStatus({ show: true, severity: 'success', message: `${res.data?.wrpStatus} ` })
    }

    if (res.data.hasOwnProperty('awsRegions') && Array.isArray(res.data.awsRegions)) {
      let awsList = []
      res.data.awsRegions.forEach((region) => {
        awsList.push({ label: region, value: region })
      })

      setAwsRegionsList(awsList)
    }

    if (res.data.hasOwnProperty('ec2Data') && Array.isArray(res.data.ec2Data)) {
      setInfrastructureEc2Data(res.data.ec2Data)
    }

    if (res.data.hasOwnProperty('icdsVersion') && typeof res.data.icdsVersion === 'string') {
      setInfrastructureIcdsVersionData(res.data.icdsVersion)
    }

    if (res.data.hasOwnProperty('rdsData') && Array.isArray(res.data.rdsData?.cpuUtilization)) {
      setInfrastructureRdsData(res.data.rdsData)
    }

    if (res.data.hasOwnProperty('icdsProcessList') && Array.isArray(res.data.icdsProcessList)) {
      setInfrastructureIcdsData(res.data.icdsProcessList)
    }

    if (res.data.hasOwnProperty('pendingApiCalls') && Array.isArray(res.data.pendingApiCalls)) {
      let pendingApiCalls = cloneDeep(res.data.pendingApiCalls)
      pendingApiCalls.reverse()
      setInfrastructurePendingLogsData(pendingApiCalls)
    }

    setLoading(false)
  }

  const fetchWrpStatus = async () => {
    const response = await getWrpStatus()

    if (response?.data) {
      if (response.data?.wrpStatus === 'running') {
        setStatus({ show: true, severity: 'success', message: `wrpServer status: ${response.data?.wrpStatus} ` })
      } else {
        setStatus({ show: true, severity: 'error', message: 'wrpServer is unavailable' })
      }
    } else {
      setStatus({ show: true, severity: 'error', message: 'api failed to retrieve wrpStatus' })
    }
  }

  const toggleAwsRegion = async (data) => {
    setShowEditor(false)
    setCurrentAwsRegion(data.awsRegion)
    return true
  }

  const SelectAwsRegionModal = ({ data, submitFunction, cancelFunction }) => {
    const formValidationSchema = yup.object({})

    let formData = [
      {
        tag: 'awsRegion',
        value: currentAwsRegion,
        text: 'AWS Region',
        inputType: 'dropdown',
        dropDownValues: awsRegionsList,
        useLabel: true,
      },
    ]

    return (
      <InputModal
        open={showEditor}
        onClose={cancelFunction}
        title={'Select AWS Region'}
        formData={formData}
        submitForm={submitFunction}
        cancelFunction={cancelFunction}
        validationSchema={formValidationSchema}
      />
    )
  }

  const toggleAwsRegionModal = () => {
    setShowEditor(true)
  }

  const handleCloseStatus = () => {
    setStatus({ show: false, severity: 'info', message: '' })
  }

  return (
    <Box
      sx={{
        height: 'calc(100vh - 64px)',
        marginLeft: `${leftPos}px`,
        width: `calc(100%-${leftPos}px)`,
        maxWidth: `calc(100%-${leftPos}px)`,
        maxHeight: 'calc(100vh - 64px)',
      }}>
      {isLoading || isLoadingLogs ? (
        <CircularProgress
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        />
      ) : null}
      {showEditor ? (
        <SelectAwsRegionModal
          data={null}
          submitFunction={toggleAwsRegion}
          cancelFunction={() => setShowEditor(false)}
        />
      ) : null}
      <Box style={{ backgroundColor: '#111' }}>
        <Box style={{ display: 'flex' }}>
          {dashboardCards ? (
            <InfrastructureDashboard
              uid={dashboardId}
              defaultLayout={wellDashLayout}
              components={dashboardCards}
              refreshChartData={() => {
                fetchHealthData()
                fetchApiLogData()
                fetchAcqCache()
                fetchAcDashData()
              }}
              fetchWrpStatus={fetchWrpStatus}
              toggleAwsRegionModal={toggleAwsRegionModal}
            />
          ) : null}
        </Box>
      </Box>
      {status?.show ? (
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={status?.show}
          autoHideDuration={2000}
          onClose={handleCloseStatus}>
          <Alert onClose={handleCloseStatus} severity={status.severity} elevation={4} variant='filled'>
            {status.message}
          </Alert>
        </Snackbar>
      ) : null}
    </Box>
  )
}

export default DashboardAdminPage
