import { useEffect, useRef, useState } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import * as XLSX from '@sheet/core'
import { array2pipestr } from 'utils'
import { analyticsSelectedWells, currentPageAtom } from 'atoms'
import { removeSpecialSymbols } from 'utils/stringFunctions'
import StripChart from './StripChart'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import { PAGE_KEYS } from 'components/ActionBar/pageDefs'
import CircularProgress from '@mui/material/CircularProgress'
import { Box } from '@mui/material'
import { AnalyticsChartButton } from '../AnalyticsBarChart'

const MotorYieldChart = () => {
  const _isMounted = useRef(false)
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState([])
  const selectedWells = useRecoilValue(analyticsSelectedWells)
  const setActivePage = useSetRecoilState(currentPageAtom)

  const getKpiData = useInnovaAxios({
    url: '/kpi/motorYieldKpi',
  })

  useEffect(() => {
    _isMounted.current = true
    setActivePage(PAGE_KEYS.motorYieldKey)

    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetchData()
  }, [selectedWells]) // eslint-disable-line react-hooks/exhaustive-deps

  const fetchData = async () => {
    if (isLoading) return
    if (!_isMounted.current) return

    if (!Array.isArray(selectedWells)) {
      setData([])
      return
    }

    if (selectedWells.length < 1) {
      setData([])
      return
    }

    setIsLoading(true)
    const res = await getKpiData({ wellList: array2pipestr(selectedWells) })
    if (!_isMounted.current) return
    if (res?.error) return

    setIsLoading(false)
    setData(Array.isArray(res?.data) ? res.data : [])
  }

  const onXlsxExport = () => {
    if (!data) return
    if (!Array.isArray(data)) return

    let headers = []
    headers.push('MD')
    headers.push('Inc')
    headers.push('Azi')
    headers.push('Slide Seen')
    headers.push('DLS')
    headers.push('Cum DLS')
    headers.push('Motor Yield')

    const wb = XLSX.utils.book_new()

    let sheetNames = []
    for (let i = 0; i < data.length; i++) {
      if (!data[i].surveys) return
      if (!Array.isArray(data[i].surveys)) return

      let ws = XLSX.utils.aoa_to_sheet([headers], { origin: 'A1' })
      let xlExport = []
      for (let j = 0; j < data[i].surveys.length; j++) {
        let newRow = []
        newRow.push(data[i].surveys[j].md)
        newRow.push(data[i].surveys[j].inc)
        newRow.push(data[i].surveys[j].azi)
        newRow.push(data[i].surveys[j].slideSeen)
        newRow.push(data[i].surveys[j].dls)
        newRow.push(data[i].surveys[j].cumDls)
        newRow.push(data[i].surveys[j].motorYield)
        xlExport.push(newRow)
      }

      let sheetName = removeSpecialSymbols(data[i].wellName).slice(0, 30)
      let index = sheetNames.findIndex((element) => element === sheetName)
      if (index < 0) sheetNames.push(sheetName)
      if (index >= 0) continue

      XLSX.utils.sheet_add_aoa(ws, xlExport, { origin: 'A2' })
      XLSX.utils.book_append_sheet(wb, ws, sheetName)
    }

    XLSX.writeFile(wb, 'motorYield.xlsx')
  }

  return (
    <Box
      id={'div1'}
      sx={{
        width: '100%',
        height: '100%',
        overflowY: 'auto',
        overflowX: 'hidden',
      }}>
      {isLoading ? (
        <CircularProgress
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        />
      ) : null}
      <AnalyticsChartButton
        onClick={onXlsxExport}
        icon={'file-icons:microsoft-excel'}
        toolTipText={'Export to Excel'}
        color={'green'}
      />
      <StripChart wellData={data} parameter={'inc'} />
      <StripChart wellData={data} parameter={'azi'} />
      <StripChart wellData={data} parameter={'dls'} />
      <StripChart wellData={data} parameter={'cumDls'} />
      <StripChart wellData={data} parameter={'motorYield'} />
      <StripChart wellData={data} parameter={'slideSeen'} />
    </Box>
  )
}

export default MotorYieldChart
