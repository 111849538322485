import React, { useRef, useMemo, useEffect, useState } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { AgGridReact } from 'ag-grid-react'
import { cloneDeep } from 'lodash'
import { currentWellSelector } from 'atoms'
import { useRecoilValue } from 'recoil'
import { Snackbar, Alert } from '@mui/material'
import useMud from 'components/common/hooks/useMud'
import { CustomLoadingOverlay, getStringId } from 'components/common/AgGridUtils'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const MudGridModal = ({ setOpen, dailyReportId, updateParentGrid }) => {
  const _isMounted = useRef(false)
  const gridApi = useRef(null)
  const { getAgGridTheme } = useInnovaTheme()
  const currentWell = useRecoilValue(currentWellSelector)
  const currentWellRef = useRef(currentWell)
  const [status, setStatus] = useState({ show: false, severity: 'info', message: '' })
  const { handleUpdateMud, fetchMudData, isLoading } = useMud()
  const [mudData, setMudData] = useState([])

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
    }
  }, [])

  useEffect(() => {
    currentWellRef.current = currentWell
    getMudData()
  }, [dailyReportId, currentWell]) // eslint-disable-line react-hooks/exhaustive-deps

  const getMudData = async () => {
    let mudData = await fetchMudData(dailyReportId)
    if (_isMounted.current) setMudData(mudData)
  }

  const columnDefs = [
    { headerName: 'Category', field: 'label', editable: false, cellStyle: { paddingRight: '10px' } },
    {
      field: 'value',
      cellStyle: { textAlign: 'end', paddingRight: '5px' },
      valueSetter: (params) => {
        let paramsDataCopy = { ...params.data }

        let integerFields = ['pv', 'yp', 'sixHun', 'threeHun', 'twoHun', 'oneHun', 'sixDial', 'threeDial']
        let stringFields = ['fluidType', 'oilWaterRatio']
        let stringIndex = stringFields.findIndex((tag) => tag === params.data?.tag)
        let intIndex = integerFields.findIndex((tag) => tag === params.data?.tag)
        let isFloat = stringIndex < 0 && intIndex < 0

        let validationPassed = false
        if (stringIndex >= 0) {
          paramsDataCopy.value = params.newValue
          validationPassed = true
        }

        if (intIndex >= 0 && /^[0-9]+$/.test(params.newValue)) {
          paramsDataCopy.value = params.newValue
          validationPassed = true
        }

        if (isFloat && /^[0-9]+(\.[0-9]+)?$/.test(params.newValue)) {
          paramsDataCopy.value = params.newValue
          validationPassed = true
        }

        if (validationPassed) params.api.applyTransaction({ update: [paramsDataCopy] })
        return validationPassed
      },
    },
  ]

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const onFirstDataRendered = (params) => {
    autoSizeColumns()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) {
      return
    }

    gridApi.current?.autoSizeAllColumns()
  }

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.tag)
    }
  }, [])

  function clearCells(start, end, columns, gridApi) {
    let itemsToUpdate = []
    for (let i = start; i <= end; i++) {
      let data = gridApi.rowModel.rowsToDisplay[i].data
      columns.forEach((column) => {
        data[column] = ''
      })

      itemsToUpdate.push(data)
    }

    gridApi.applyTransaction({ update: itemsToUpdate })
  }

  const defaultColDef = useMemo(() => {
    return {
      suppressKeyboardEvent: (params) => {
        if (!params.editing) {
          let isBackspaceKey = params.event.keyCode === 8
          let isDeleteKey = params.event.keyCode === 46

          if (isBackspaceKey || isDeleteKey) {
            params.api.getCellRanges().forEach((range) => {
              let colIds = range.columns.map((col) => col.colId)
              let startRowIndex = Math.min(range.startRow.rowIndex, range.endRow.rowIndex)
              let endRowIndex = Math.max(range.startRow.rowIndex, range.endRow.rowIndex)
              clearCells(startRowIndex, endRowIndex, colIds, params.api)
            })
          }
        }

        return false
      },
      resizable: true,
      sortable: true,
      autoHeight: true,
      editable: true,
      cellStyle: { textAlign: 'right', padding: 0 },
      headerClass: 'header-no-padding',
    }
  }, [])

  const gridOptions = {
    onCellEditingStopped: (params) => {
      if (!params?.valueChanged) return
      handleGridUpdate(cloneDeep(params.data))
    },
    headerHeight: 0,
    loadingOverlayComponent: CustomLoadingOverlay,
  }

  const handleGridUpdate = async (data) => {
    let response = await handleUpdateMud(data)
    if (response.isError) {
      setStatus({ show: true, severity: 'error', message: response.message })
      return
    }

    gridApi.current.applyTransaction({
      update: [cloneDeep(data)],
    })

    if (data.tag === 'fluidType' || data.tag === 'mudWeight') updateParentGrid(data, 'mudData')
  }

  const handleCloseStatus = () => {
    setStatus({ show: false, severity: 'info', message: '' })
  }

  return (
    <Dialog
      fullWidth={true}
      maxWidth={'sm'}
      PaperProps={{
        sx: {
          height: '50vh',
          backgroundColor: 'itemBackground',
        },
      }}
      open={true}
      onClose={() => setOpen(false)}>
      <DialogTitle>Mud Data</DialogTitle>
      <DialogContent>
        <div style={{ height: '100%', width: '100%' }} className={getAgGridTheme()}>
          <AgGridReact
            loading={isLoading}
            rowData={mudData}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            gridOptions={gridOptions}
            onGridReady={onGridReady}
            onFirstDataRendered={onFirstDataRendered}
            animateRows={true}
            getRowId={getRowId}
            headerHeight={30}
          />
        </div>
        {status?.show ? (
          <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            open={status?.show}
            autoHideDuration={2000}
            onClose={handleCloseStatus}>
            <Alert onClose={handleCloseStatus} severity={status.severity} elevation={4} variant='filled'>
              {status.message}
            </Alert>
          </Snackbar>
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' onClick={() => setOpen(false)} color='secondary'>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}
export default MudGridModal
