import { useEffect, useRef, useState } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import * as XLSX from '@sheet/core'
import { array2pipestr } from 'utils'
import { analyticsFilterDefsAtom, analyticsSelectedWells, currentPageAtom } from 'atoms'
import { removeSpecialSymbols } from 'utils/stringFunctions'
import StripChart from './StripChart'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import { PAGE_KEYS } from 'components/ActionBar/pageDefs'
import { getParameter, getParameterActive } from '../../AnalyticsPage/FilterDefs'
import CircularProgress from '@mui/material/CircularProgress'
import { Box } from '@mui/material'
import { AnalyticsChartButton } from '../AnalyticsBarChart'

const ParameterComparisonChart = () => {
  const _isMounted = useRef(false)
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState([])
  const selectedWells = useRecoilValue(analyticsSelectedWells)
  const searchParams = useRecoilValue(analyticsFilterDefsAtom)
  const setActivePage = useSetRecoilState(currentPageAtom)

  const getKpiData = useInnovaAxios({
    url: '/kpi/getKpis',
  })

  useEffect(() => {
    _isMounted.current = true
    setActivePage(PAGE_KEYS.parameterComparisonKey)

    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetchData()
  }, [selectedWells]) // eslint-disable-line react-hooks/exhaustive-deps

  const fetchData = async () => {
    if (isLoading) return
    if (!_isMounted.current) return

    if (!Array.isArray(selectedWells)) {
      setData([])
      return
    }

    if (selectedWells.length < 1) {
      setData([])
      return
    }

    let payload = {
      wellList: array2pipestr(selectedWells),
      params: 'true',
    }

    let dateFrom = getParameter('Start Date', searchParams)
    let dateTo = getParameter('End Date', searchParams)

    if (!getParameterActive('Start Date', searchParams)) dateFrom = ''
    if (!getParameterActive('End Date', searchParams)) dateTo = ''

    if (dateFrom === '' || dateTo === '') {
      dateTo = new Date(Date.now()).toISOString()
      dateFrom = '1990-01-01T07:00:00.000Z'
    }

    payload.dateTo = dateTo
    payload.dateFrom = dateFrom
    setIsLoading(true)
    const dataResponse = await getKpiData(payload)
    if (_isMounted.current) {
      setIsLoading(false)
      setData(dataResponse?.data ? dataResponse.data : [])
    }
  }

  const onXlsxExport = () => {
    if (!data) return
    if (!Array.isArray(data.params)) return

    let headers = []
    headers.push('MD')
    headers.push('Phase')
    headers.push('ROP')
    headers.push('WOB')
    headers.push('RPM')
    headers.push('Flow Rate')
    headers.push('Torque')
    headers.push('On Btm Press')
    headers.push('Off Btm Press')
    headers.push('Diff P')
    headers.push('Temp')

    const wb = XLSX.utils.book_new()

    let sheetNames = []
    for (let i = 0; i < data.params.length; i++) {
      if (!data.params[i].parameters) return
      if (!Array.isArray(data.params[i].parameters)) return

      let ws = XLSX.utils.aoa_to_sheet([headers], { origin: 'A1' })
      let xlExport = []
      for (let j = 0; j < data.params[i].parameters.length; j++) {
        let newRow = []
        newRow.push(data.params[i].parameters[j].md)
        newRow.push(data.params[i].parameters[j].phase)
        newRow.push(data.params[i].parameters[j].rop)
        newRow.push(data.params[i].parameters[j].wob)
        newRow.push(data.params[i].parameters[j].rpm)
        newRow.push(data.params[i].parameters[j].flow)
        newRow.push(data.params[i].parameters[j].tq)
        newRow.push(data.params[i].parameters[j].onBtmPress)
        newRow.push(data.params[i].parameters[j].offBtmPress)
        newRow.push(data.params[i].parameters[j].diffPress)
        newRow.push(data.params[i].parameters[j].temp)
        xlExport.push(newRow)
      }

      let sheetName = removeSpecialSymbols(data.params[i].actualWell).slice(0, 30)
      let index = sheetNames.findIndex((element) => element === sheetName)
      if (index < 0) sheetNames.push(sheetName)
      if (index >= 0) continue

      XLSX.utils.sheet_add_aoa(ws, xlExport, { origin: 'A2' })
      XLSX.utils.book_append_sheet(wb, ws, sheetName)
    }

    XLSX.writeFile(wb, 'parameterComparison.xlsx')
  }

  return (
    <Box
      id={'div1'}
      sx={{
        width: '100%',
        height: '100%',
        overflowY: 'auto',
        overflowX: 'hidden',
      }}>
      {isLoading ? (
        <CircularProgress
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        />
      ) : null}
      <AnalyticsChartButton
        onClick={onXlsxExport}
        icon={'file-icons:microsoft-excel'}
        toolTipText={'Export to Excel'}
        color={'green'}
      />
      <StripChart wellData={data.params} parameter={'rop'} />
      <StripChart wellData={data.params} parameter={'wob'} />
      <StripChart wellData={data.params} parameter={'rpm'} />
      <StripChart wellData={data.params} parameter={'diffPress'} />
      <StripChart wellData={data.params} parameter={'flow'} />
    </Box>
  )
}

export default ParameterComparisonChart
