import React, { useRef, useEffect, useState, useMemo, useCallback } from 'react'
import { currentPageAtom, actionBarWidthAtom } from 'atoms'
import { useSetRecoilState, useRecoilValue } from 'recoil'
import { PAGE_KEYS } from 'components/ActionBar/pageDefs'
import { Box, Snackbar, Alert, IconButton } from '@mui/material'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import { debounce } from 'lodash'
import { saveItemToLS } from 'utils/localStorage'
import SearchBar from 'components/common/SearchBar'
import { AgGridReact } from 'ag-grid-react'
import {
  sortColDefs,
  CustomLoadingOverlay,
  dateComparator,
  isDateLessThan,
  getStringId,
} from 'components/common/AgGridUtils'
import ConfirmDialog from 'components/common/ConfirmDialog'
import { getUserNameFromEmail } from 'utils/chatFunctions'
import { Icon as Iconify } from '@iconify/react'
import MenuButton from 'components/common/MenuButton'
import RefreshIcon from '@mui/icons-material/Refresh'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const UserDatabaseAccessPage = () => {
  const setActivePage = useSetRecoilState(currentPageAtom)
  const leftPos = useRecoilValue(actionBarWidthAtom)
  const _isMounted = useRef(false)
  const isFetchingOrgs = useRef(false)
  const isFetchingRoles = useRef(false)
  const gridApi = useRef(null)
  const isDeleteing = useRef(false)
  const isAdding = useRef(false)
  const isUpdating = useRef(false)
  const inputRow = useRef({})
  const [resetCols, setResetCols] = useState(false)
  const roleList = useRef([])
  const [orgList, setOrgList] = useState([])
  const [searchText, setSearchText] = useState('')
  const [status, setStatus] = useState({ show: false, severity: 'info', message: '' })
  const [confirm, setConfirm] = useState({ show: false, title: '' })
  const [isLoading, setLoading] = useState(false)
  const selectedUserAccess = useRef(null)
  const { getAgGridTheme, searchBarStyle } = useInnovaTheme()

  const getOrgs = useInnovaAxios({
    url: '/admin/getOrgListByUserAuthType',
  })

  const getRoles = useInnovaAxios({
    url: '/admin/user/roles',
  })

  const deleteUserAccess = useInnovaAxios({
    url: '/admin/user/databaseAccess/deleteUserDatabaseAccess',
  })

  const getUserAccess = useInnovaAxios({
    url: '/admin/user/databaseAccess/getUserDatabaseAccess',
  })

  const addUserAccess = useInnovaAxios({
    url: '/admin/user/databaseAccess/addUserDatabaseAccess',
  })

  const updateUserAccess = useInnovaAxios({
    url: '/admin/user/databaseAccess/updateUserDatabaseAccess',
  })

  const fetchUserAccessData = async () => {
    if (isLoading) return
    setLoading(true)

    const res = await getUserAccess()

    if (!_isMounted.current) return
    setLoading(false)

    if (res?.error) {
      setStatus({ show: true, severity: 'error', message: res?.error?.response?.data?.error })
    }

    if (!Array.isArray(res.data)) return
    res.data.sort((a, b) => a.userName.localeCompare(b.userName))

    gridApi.current.setGridOption('rowData', res.data)
    autoSizeColumns()
  }

  const fetchOrganizations = async () => {
    if (isFetchingOrgs.current) return
    isFetchingOrgs.current = true
    const res = await getOrgs()
    isFetchingOrgs.current = false

    if (!_isMounted.current) return

    if (res?.error) {
      console.error('Error fetching organizations list:', res?.error?.response?.data?.error)
      return
    }

    if (!Array.isArray(res?.data)) return
    res.data.sort((a, b) => a.organization.localeCompare(b.organization))
    setOrgList(res.data)
  }

  const fetchRoles = async () => {
    if (isFetchingRoles.current) return
    isFetchingRoles.current = true
    const res = await getRoles()
    isFetchingRoles.current = false

    if (!_isMounted.current) return
    if (res?.error) {
      console.error('Error fetching roles:', res?.error?.response?.data?.error)
      return
    }

    roleList.current = Array.isArray(res.data) ? res.data : []
  }

  useEffect(() => {
    _isMounted.current = true
    setActivePage(PAGE_KEYS.userDatabaseAccessPageKey)
    fetchOrganizations()
    fetchRoles()
    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const debounceSetQuickFilter = debounce((text) => {
    if (gridApi.current) {
      gridApi.current.setGridOption('quickFilterText', text)
    }
  }, 300)

  useEffect(() => {
    debounceSetQuickFilter(searchText)
  }, [searchText]) // eslint-disable-line react-hooks/exhaustive-deps

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.Uid)
    }
  }, [])

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return

    setTimeout(() => {
      gridApi.current?.autoSizeAllColumns()
    }, 100)
  }

  const saveColumnState = () => {
    if (!gridApi.current) return
    const colLayout = gridApi.current.getColumnState()
    if (colLayout) saveItemToLS('userDatabaseAccessGrid', 'colLayout', colLayout)
  }

  const getContextMenuItems = (params) => {
    return [
      {
        name: 'Reset columns',
        disabled: false,
        action: () => {
          gridApi.current.resetColumnState()
          saveItemToLS('userDatabaseAccessGrid', 'colLayout', null)
          setResetCols(!resetCols)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Reset filters',
        disabled: false,
        action: () => {
          gridApi.current.setFilterModel(null)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      'copy',
      {
        name: 'Export',
        disabled: false,
        action: () => {
          gridApi.current.exportDataAsExcel({
            fileName: 'UsersDatabaseAccess.xlsx',
            sheetName: 'Users',
          })
        },
        icon: '<span class="iconify" data-icon="icomoon-free:file-excel" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
    ]
  }

  let reqFields = useMemo(() => ['userName', 'organization', 'roleId'], [])

  const isPinnedRowDataCompleted = useCallback(() => {
    for (let i = 0; i < reqFields.length; i++) {
      if (!inputRow.current.hasOwnProperty(reqFields[i])) return false
    }

    return true
  }, [reqFields])

  const handleDelete = useCallback(async () => {
    if (!selectedUserAccess.current) return
    if (isDeleteing.current) return

    let payLoad = {
      userName: selectedUserAccess.current.userName,
      databaseAccess: [{ organization: selectedUserAccess.current.organization }],
    }

    isDeleteing.current = true
    let res = await deleteUserAccess({ userData: JSON.stringify(payLoad) })
    isDeleteing.current = false

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })

      selectedUserAccess.current = null
      return
    }

    if (res.data?.userName === '') {
      gridApi.current.applyTransaction({
        remove: [res.data],
      })
    }

    if (res.data?.userName !== '') {
      gridApi.current.applyTransaction({
        update: [res.data],
      })
    }

    setStatus({
      show: true,
      severity: 'success',
      message: `${selectedUserAccess.current.userName} deleted`,
    })

    selectedUserAccess.current = null
  }, [deleteUserAccess])

  const handleUpdate = useCallback(
    async (data) => {
      if (!data) return
      if (isUpdating.current) return

      let payLoad = {
        userName: data.userName,
        databaseAccess: [{ organization: data.organization, roleId: data.roleId }],
      }

      isUpdating.current = true
      let res = await updateUserAccess({ userData: JSON.stringify(payLoad) })
      isUpdating.current = false

      if (res?.error) {
        setStatus({
          show: true,
          severity: 'error',
          message: `${res?.error?.response?.data?.error}`,
        })
        return
      }

      gridApi.current.applyTransaction({
        update: [res.data],
      })

      setStatus({
        show: true,
        severity: 'success',
        message: `${data.userName} updated`,
      })
    },
    [updateUserAccess],
  )

  const handleAddRow = useCallback(async () => {
    if (!gridApi.current) return
    if (isAdding.current) return
    if (!isPinnedRowDataCompleted()) return

    if (!Array.isArray(roleList.current)) return
    let role = roleList.current.find(
      (role) => role.name === inputRow.current.roleId && role.organization === inputRow.current.organization,
    )

    if (!role) {
      setStatus({
        show: true,
        severity: 'error',
        message: `Role ${inputRow.current.roleId} not found for organization ${inputRow.current.organization}`,
      })
      return
    }

    let payLoad = {
      userName: inputRow.current.userName,
      databaseAccess: [{ organization: inputRow.current.organization, roleId: role.id }],
    }

    isAdding.current = true
    let res = await addUserAccess({ userData: JSON.stringify(payLoad) })
    isAdding.current = false

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })
      return
    }

    let userFound = false
    gridApi.current.forEachNode((node) => {
      if (node.data.userName === inputRow.current.userName) {
        userFound = true
        return
      }
    })

    if (!userFound) {
      gridApi.current.applyTransaction({
        add: [res.data],
      })
    }

    if (userFound) {
      gridApi.current.applyTransaction({
        update: [res.data],
      })
    }

    setStatus({
      show: true,
      severity: 'success',
      message: `${inputRow.current.userName} added`,
    })

    inputRow.current = {}
    gridApi.current.setGridOption('pinnedBottomRowData', [inputRow.current])
  }, [isPinnedRowDataCompleted, addUserAccess])

  const onClickDelete = useCallback(
    (data) => {
      selectedUserAccess.current = data
      setConfirm({
        show: true,
        title: 'Delete User Access',
        text: `Are you sure you want to delete access for ${data.userName}?`,
        onConfirm: handleDelete,
      })
    },
    [handleDelete],
  )

  const actionIconRenderer = useCallback(
    (params) => {
      return (
        <Box style={{ display: 'flex', flexDirection: 'row' }}>
          <IconButton
            style={{ padding: '5px', alignItems: 'center', justifyContent: 'center' }}
            onClick={() => onClickDelete(params.data)}
            size='large'>
            <Iconify color={'red'} icon={'fa-regular:trash-alt'} fontSize={16} />
          </IconButton>
          <Box style={{ textAlign: 'right', paddingLeft: '8px' }}>{params.node.rowIndex + 1}</Box>
        </Box>
      )
    },
    [onClickDelete],
  )

  const gridOptions = useMemo(() => {
    return {
      pinnedBottomRowData: [inputRow.current],
      onCellEditingStopped: (event) => {
        if (event.node?.rowPinned === 'bottom') {
          handleAddRow()
        }
      },
      getRowStyle: ({ node }) => (node?.rowPinned ? { fontWeight: 'bold', fontStyle: 'italic' } : 0),
      suppressRowClickSelection: true,
      sideBar: {
        toolPanels: [
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel',
          },
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
          },
        ],
        defaultToolPanel: '',
        position: 'left',
      },
      onDragStopped: () => {
        saveColumnState()
      },
      onColumnVisible: () => {
        saveColumnState()
      },
      loadingOverlayComponent: CustomLoadingOverlay,
    }
  }, [handleAddRow])

  const onFirstDataRendered = (params) => {
    autoSizeColumns()
  }

  const onGridReady = (params) => {
    gridApi.current = params.api

    fetchUserAccessData()
  }

  const leftAlignCell = useMemo(
    () => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'start',
      overflow: 'hidden',
    }),
    [],
  )

  const isEmptyPinnedCell = useCallback(({ node, value }) => {
    return (node?.rowPinned === 'bottom' && value == null) || (node?.rowPinned === 'bottom' && value === '')
  }, [])

  const createPinnedCellPlaceholder = useCallback(
    ({ colDef }) => {
      if (reqFields.findIndex((field) => field === colDef.field) < 0) return ''
      return colDef.field[0].toUpperCase() + colDef.field.slice(1) + '...'
    },
    [reqFields],
  )

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: true,
      editable: false,
      autoHeight: true,
      cellStyle: leftAlignCell,
      headerClass: 'header-no-padding',
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
      valueFormatter: (params) => (isEmptyPinnedCell(params) ? createPinnedCellPlaceholder(params) : undefined),
    }
  }, [isEmptyPinnedCell, createPinnedCellPlaceholder, leftAlignCell])

  let columnDefs = useMemo(
    () => [
      {
        headerName: '',
        colId: 'databaseAccess',
        pinned: 'left',
        lockPosition: 'left',
        sortable: false,
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
        cellRenderer: 'agGroupCellRenderer',
        width: 50,
        cellStyle: {
          justifyContent: 'center',
        },
      },
      {
        field: 'userName',
        colId: 'userName',
        headerName: 'User Name',
        editable: (params) => isEmptyPinnedCell(params),
      },

      {
        field: 'organization',
        colId: 'organization',
        headerName: 'Organization',

        editable: (params) => isEmptyPinnedCell(params),
        cellEditor: 'agRichSelectCellEditor',
        cellEditorPopup: true,
        cellEditorParams: (params) => {
          return {
            values: Array.isArray(orgList) ? orgList.map((org) => org.organization) : [],
          }
        },
      },
      {
        field: 'roleId',
        colId: 'roleId',
        headerName: '',
        minWidth: 200,
        editable: (params) => {
          if (!params?.node?.rowPinned === 'bottom') return false
          return orgList.findIndex((org) => org.organization === inputRow.current.organization) >= 0
        },
        cellStyle: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'start',
        },
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: (params) => {
          let roles = Array.isArray(roleList.current)
            ? roleList.current.filter((role) => role.organization === inputRow.current.organization)
            : []

          return {
            values: roles.map((role) => role.name),
          }
        },
      },
    ],
    [orgList, isEmptyPinnedCell],
  )

  const handleCloseStatus = () => {
    setStatus({ show: false, severity: 'info', message: '' })
  }

  const dateTimeFormatter = useCallback((value) => {
    if (!value) return ''
    if (typeof value !== 'string') return ''
    if (value === '') return ''
    if (isDateLessThan(value, '1990-01-01')) return ''
    value = value.replace(/Z/g, '')
    return new Date(Date.parse(value)).toLocaleDateString('default', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    })
  }, [])

  const dateFilterComparator = useCallback(dateComparator, [])

  const detailCellRendererParams = useMemo(() => {
    return {
      detailGridOptions: {
        columnDefs: [
          {
            field: 'actions',
            colId: 'actions',
            width: 86,
            headerName: '',
            editable: false,
            filter: null,
            sortable: false,
            suppressHeaderMenuButton: true,
            suppressHeaderFilterButton: true,
            pinned: 'left',
            lockPosition: 'left',
            cellRenderer: actionIconRenderer,
          },
          {
            headerName: 'Organization',
            field: 'organization',
            colId: 'organization',
            cellStyle: leftAlignCell,
          },
          {
            headerName: 'Role',
            field: 'roleId',
            colId: 'roleId',
            editable: true,
            cellStyle: {
              justifyContent: 'center',
            },
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: (params) => {
              let roles = Array.isArray(roleList.current)
                ? roleList.current.filter((role) => role.organization === params.data?.organization)
                : []

              return {
                values: roles.map((role) => role.name),
              }
            },
            valueGetter: (params) => {
              if (!Array.isArray(roleList.current)) return ''
              let role = roleList.current.find((role) => role.id === params.data?.roleId)
              return role?.name
            },
            valueSetter: (params) => {
              if (params.newValue === params.oldValue) return false
              if (typeof params.newValue !== 'string') return false

              let role = roleList.current.find(
                (role) => role.name === params.newValue && role.organization === params.data?.organization,
              )
              if (!role) return false

              params.data.roleId = role.id
              handleUpdate(params.data)
              return true
            },
          },
          {
            headerName: 'Access Granted By',
            field: 'accessGrantedBy',
            colId: 'accessGrantedBy',
            valueFormatter: (params) => getUserNameFromEmail(params.value),
          },
          {
            headerName: 'Access Granted Date',
            field: 'accessGrantedDate',
            colId: 'accessGrantedDate',
            filter: 'agDateColumnFilter',
            filterParams: {
              comparator: dateFilterComparator,
            },
            valueFormatter: (params) => dateTimeFormatter(params.value),
          },
        ],
        defaultColDef: {
          suppressHeaderMenuButton: true,
          suppressHeaderFilterButton: true,
          sortable: false,
          resizable: true,
          editable: false,
          cellStyle: leftAlignCell,
          filter: 'agSetColumnFilter',
          filterParams: {
            excelMode: 'windows',
          },
        },
      },
      getDetailRowData: (params) => {
        if (!Array.isArray(params.data?.databaseAccess)) return
        params.successCallback(
          params.data?.databaseAccess.map((access) => {
            return { ...access, userName: params.data?.userName }
          }),
        )
      },
    }
  }, [leftAlignCell, dateTimeFormatter, dateFilterComparator, handleUpdate, actionIconRenderer])

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItmes: 'center',
        flexDirection: 'column',
        height: 'calc(100vh - 64px)',
        marginLeft: `${leftPos}px`,
        width: `calc(100%-${leftPos}px`,
        maxWidth: `calc(100%-${leftPos}px)`,
        maxHeight: `calc(100vh - 64px)`,
      }}>
      {confirm.show ? (
        <ConfirmDialog
          title={confirm?.title}
          open={confirm?.show}
          setOpen={() => setConfirm({ show: false })}
          onConfirm={confirm.onConfirm}>
          {confirm?.text}
        </ConfirmDialog>
      ) : null}
      <Box
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
          width: '100%',
        }}>
        <SearchBar
          value={searchText}
          onChange={(newSearchTerm) => setSearchText(newSearchTerm)}
          onCancelSearch={() => setSearchText('')}
          style={searchBarStyle()}
        />
      </Box>
      <Box className={getAgGridTheme()} sx={{ width: '100%', height: '100%' }}>
        <AgGridReact
          loading={isLoading}
          columnDefs={sortColDefs(columnDefs, 'userDatabaseAccessGrid')}
          defaultColDef={defaultColDef}
          getRowId={getRowId}
          animateRows={true}
          gridOptions={gridOptions}
          onGridReady={onGridReady}
          onFirstDataRendered={onFirstDataRendered}
          getContextMenuItems={getContextMenuItems}
          masterDetail={true}
          detailCellRendererParams={detailCellRendererParams}
          headerHeight={30}
        />
      </Box>
      {status?.show ? (
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={status?.show}
          autoHideDuration={2000}
          onClose={handleCloseStatus}>
          <Alert onClose={handleCloseStatus} severity={status.severity} elevation={4} variant='filled'>
            {status.message}
          </Alert>
        </Snackbar>
      ) : null}
      <Box
        sx={{
          backgroundColor: 'transparent',
          margin: '4px',
          padding: '12px',
          position: 'fixed',
          bottom: (theme) => theme.spacing(2),
          right: (theme) => theme.spacing(2),
          zIndex: 2,
        }}>
        <MenuButton
          actions={[
            {
              icon: <RefreshIcon />,
              name: 'Refresh',
              onClick: () => {
                fetchUserAccessData()
              },
            },
          ]}
        />
      </Box>
    </Box>
  )
}

export default UserDatabaseAccessPage
