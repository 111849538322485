import React, { useEffect, useRef, useState, useMemo } from 'react'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import { useRecoilValue } from 'recoil'
import { currentWellSelector } from 'atoms'
import { AgGridReact } from 'ag-grid-react'
import { Tooltip, Box, IconButton, Snackbar, Alert } from '@mui/material'
import { Icon as Iconify } from '@iconify/react'
import ConfirmDialog from 'components/common/ConfirmDialog'
import { numberWithCommasDecimals } from 'utils/stringFunctions'
import useUnits, { UNITS_FOR } from 'components/common/hooks/useUnits'
import { saveItemToLS } from 'utils/localStorage'
import { cloneDeep } from 'lodash'
import { sortColDefs, getStringId } from 'components/common/AgGridUtils'
import { unescapeHtml } from 'utils/htmlSymbolHandling'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const DailyCostDetailsGrid = ({ data, refreshCallback, getDropDowns, getSerialNumbers }) => {
  const _isMounted = useRef(false)
  const gridApi = useRef(null)
  const { getAgGridTheme } = useInnovaTheme()
  const currentWell = useRecoilValue(currentWellSelector)
  const currentWellRef = useRef(currentWell)
  const [resetCols, setResetCols] = useState(false)
  const inputRow = useRef({})
  const [confirm, setConfirm] = useState({ show: false, title: '' })
  const [status, setStatus] = useState({ show: false, severity: 'info', message: '' })
  const { getUnitsText } = useUnits()

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
    }
  }, [])

  useEffect(() => {
    currentWellRef.current = currentWell
  }, [currentWell]) // eslint-disable-line react-hooks/exhaustive-deps

  const deleteCostData = useInnovaAxios({
    url: '/well/wellCost/deleteCost',
  })

  const actionIconRenderer = (params) => {
    return (
      <Box style={{ display: 'flex', flexDirection: 'row' }}>
        <Tooltip
          title={params.node?.rowPinned !== 'bottom' ? 'Delete' : 'Add'}
          placement='left'
          componentsProps={{
            tooltip: {
              sx: {
                backgroundColor: 'rgb(19,62,96)',
                fontSize: '12px',
                fontFamily: 'Roboto',
              },
            },
          }}>
          <IconButton
            sx={{ padding: '5px', alignItems: 'center', justifyContent: 'center' }}
            onClick={() => (params.node?.rowPinned !== 'bottom' ? handleDelete(params) : handleAddRow(params))}
            size='large'>
            {params.node?.rowPinned !== 'bottom' ? (
              <Iconify icon='fa-regular:trash-alt' color={'#C00000'} fontSize={'16px'} />
            ) : (
              <Iconify icon='fluent:add-12-filled' color={'green'} fontSize={'16px'} />
            )}
          </IconButton>
        </Tooltip>
        {params.node?.rowPinned !== 'bottom' ? (
          <Box style={{ textAlign: 'right', paddingLeft: '8px' }}>{params.node.rowIndex + 1}</Box>
        ) : null}
      </Box>
    )
  }

  const handleDelete = (params) => {
    setConfirm({
      show: true,
      title: 'Delete Cost Item',
      text: `Are you sure you want to delete ${params.data?.description}?`,
      params: params,
      isDelete: true,
    })
  }

  const confirmDelete = async () => {
    if (confirm.params.data?.uid < 0) return
    const deleteResponse = await deleteCostData({
      wellName: confirm.params.data?.actualwell,
      uid: confirm.params.data?.uid,
    })

    if (deleteResponse?.error) {
      setStatus({ show: true, severity: 'error', message: deleteResponse?.error?.response?.data?.error })
      return
    }

    gridApi.current.applyTransaction({ remove: [confirm.params.data] })
    refreshCallback({ action: 'delete', data: [cloneDeep(confirm.params.data)] })
  }

  const isPinnedRowDataCompleted = () => {
    return inputRow.current.hasOwnProperty('costCode') || inputRow.current.hasOwnProperty('description')
  }

  const handleAddRowComplete = () => {
    inputRow.current = {}
    if (gridApi.current) gridApi.current.setGridOption('pinnedBottomRowData', [inputRow.current])
  }

  const handleAddRow = async (params) => {
    if (!isPinnedRowDataCompleted()) return

    const { costCodes, descriptions } = getDropDowns()
    if (inputRow.current.hasOwnProperty('description')) {
      let code = costCodes.find((code) => code.value === params.newValue?.value)
      if (!code) {
        handleAddRowComplete()
        return
      }
      inputRow.current.costCode = code.value
      inputRow.current.value = code.costValue
      inputRow.current.totalValue = code.costValue
    }

    if (inputRow.current.hasOwnProperty('costCode')) {
      let desc = descriptions.find((desc) => desc.value === inputRow.current.costCode)
      let code = costCodes.find((code) => code.value === inputRow.current.costCode)
      if (!code || !desc) {
        handleAddRowComplete()
        return
      }

      inputRow.current.description = desc.label
      inputRow.current.value = code.costValue
      inputRow.current.totalValue = code.costValue
    }

    let res = await refreshCallback({
      action: 'add',
      data: [
        {
          ...inputRow.current,
          quantity: 1,
          actualwell: currentWellRef.current,
          dailyReportId: data?.dailyReportId,
          serialNum: '',
          bhaNum: '',
          uid: -1,
          sequenceNo: -1,
        },
      ],
    })

    if (res?.hasOwnProperty('uid')) {
      gridApi.current.applyTransaction({ add: [res] })
    }

    handleAddRowComplete()
  }

  const centerAlignCell = () => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  })

  const getRowHeight = () => {
    // header + pinned row + (42.14 * rows) + padding for drop down menus
    let height = 93.43 + 42.14 * data.costs.length + 100
    return height
  }

  const saveColumnState = () => {
    if (gridApi.current) {
      const colLayout = gridApi.current.getColumnState()
      if (colLayout) saveItemToLS('dcDetailGrid', 'colLayout', colLayout)
    }
  }

  const getContextMenuItems = (params) => {
    return [
      {
        name: 'Reset columns',
        disabled: false,
        action: () => {
          gridApi.current.resetColumnState()
          saveItemToLS('dcDetailGrid', 'colLayout', null)
          setResetCols(!resetCols)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Reset filters',
        disabled: false,
        action: () => {
          gridApi.current.setFilterModel(null)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      'copy',
      {
        name: 'Export',
        disabled: false,
        action: () => {
          gridApi.current.exportDataAsExcel({
            fileName: 'DailyCostData.xlsx',
            sheetName: 'Costs',
          })
        },
        icon: '<span class="iconify" data-icon="icomoon-free:file-excel" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
    ]
  }

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.uid)
    }
  }, [])

  function createPinnedCellPlaceholder({ colDef }) {
    if (colDef.field === 'description' || colDef.field === 'costCode') {
      return colDef.field[0].toUpperCase() + colDef.field.slice(1) + '...'
    }

    return ''
  }

  function isEmptyPinnedCell({ node, value }) {
    return (node?.rowPinned === 'bottom' && value == null) || (node?.rowPinned === 'bottom' && value === '')
  }

  function clearCells(start, end, columns, gridApi) {
    let itemsToUpdate = []

    for (let i = start; i <= end; i++) {
      let data = gridApi.rowModel.rowsToDisplay[i].data
      columns.forEach((column) => {
        data[column] = ''
      })
      itemsToUpdate.push(data)
    }

    gridApi.applyTransaction({ update: itemsToUpdate })
    refreshCallback({ action: 'update', data: itemsToUpdate })
  }

  const defaultColDef = useMemo(() => {
    return {
      suppressKeyboardEvent: (params) => {
        if (!params.editing) {
          let isBackspaceKey = params.event.keyCode === 8
          let isDeleteKey = params.event.keyCode === 46

          if (isBackspaceKey || isDeleteKey) {
            params.api.getCellRanges().forEach((range) => {
              let colIds = range.columns.map((col) => col.colId)
              let startRowIndex = Math.min(range.startRow.rowIndex, range.endRow.rowIndex)
              let endRowIndex = Math.max(range.startRow.rowIndex, range.endRow.rowIndex)
              clearCells(startRowIndex, endRowIndex, colIds, params.api)
            })
          }
        }
        return false
      },
      editable: true,
      resizable: true,
      sortable: true,
      autoHeight: true,
      valueFormatter: (params) => (isEmptyPinnedCell(params) ? createPinnedCellPlaceholder(params) : undefined),
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const getDescriptionsDropDown = () => {
    const { descriptions } = getDropDowns()
    return descriptions
  }

  const getBhaDropDown = () => {
    const { bhas } = getDropDowns()
    return bhas
  }

  const columnDefs = [
    {
      field: 'actions',
      colId: 'actions',
      width: 86,
      headerName: '',
      editable: false,
      cellRendererSelector: () => {
        return {
          component: actionIconRenderer,
        }
      },
      cellStyle: {
        display: 'flex',
        justifyContent: 'center',
        paddingLeft: '4px !important',
        paddingRight: '4px !important',
        borderRight: '1px solid gray !important',
      },
      pinned: 'left',
      lockPosition: 'left',
      suppressHeaderMenuButton: true,
      suppressHeaderFilterButton: true,
    },
    {
      headerName: '',
      colId: 'costCode',
      field: 'costCode',
      width: 115,
      cellStyle: centerAlignCell,
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: (params) => {
        const { costCodes } = getDropDowns()
        return { values: costCodes.map((item) => item.value).sort() }
      },
    },
    {
      headerName: 'Description',
      field: 'description',
      colId: 'description',
      minWidth: 250,
      cellStyle: { textAlign: 'start' },
      cellEditor: 'agRichSelectCellEditor',
      cellEditorPopup: true,
      cellEditorParams: {
        values: [...getDescriptionsDropDown()],
        cellRenderer: (params) => params?.value?.label,
        searchDebounceDelay: 500,
      },
      valueSetter: (params) => {
        let data = { ...params.data }
        data.description = params.newValue?.label
        if (params.node?.rowPinned === 'bottom') {
          inputRow.current[params.colDef.field] = params.newValue?.label
        }

        if (params.node?.rowPinned !== 'bottom') params.api.applyTransaction({ update: [data] })
        return true
      },
      valueGetter: (params) => unescapeHtml(params.data?.description),
    },
    {
      headerName: 'Quantity',
      colId: 'quantity',
      field: 'quantity',
      width: 130,
      cellStyle: centerAlignCell,
      cellEditor: 'agNumberCellEditor',
      cellEditorParams: {
        min: -100000,
        max: 100000,
        precision: 2,
      },
    },
    {
      headerName: 'Value',
      colId: 'value',
      field: 'value',
      width: 140,
      editable: false,
      cellStyle: centerAlignCell,
      valueFormatter: (params) => {
        if (params.node?.rowPinned === 'bottom') return ''
        return `${getUnitsText(UNITS_FOR.Cost)}${numberWithCommasDecimals(params.data?.value, 2)}`
      },
    },
    {
      headerName: 'Total',
      colId: 'totalVals',
      field: 'totalValue',
      width: 130,
      editable: false,
      cellStyle: centerAlignCell,
      valueFormatter: (params) => {
        if (params.node?.rowPinned === 'bottom') return ''
        return `${getUnitsText(UNITS_FOR.Cost)}${numberWithCommasDecimals(params.data?.totalValue, 2)}`
      },
    },
    {
      headerName: 'BHA #',
      colId: 'bhaNum',
      field: 'bhaNum',
      width: 140,
      cellStyle: { textAlign: 'start' },
      cellEditor: 'agRichSelectCellEditor',
      cellEditorPopup: true,
      cellEditorParams: {
        values: [{ label: 'none', value: '-1' }, ...getBhaDropDown()],
        cellRenderer: (params) => params?.value?.label,
        searchDebounceDelay: 500,
      },
      valueFormatter: (params) => {
        if (params.node?.rowPinned === 'bottom') return ''
        let bhaDropDown = getBhaDropDown()
        let foundBha = bhaDropDown.find((bha) => bha.value === params.value)
        if (!foundBha) return ''
        return foundBha.label
      },
      valueSetter: (params) => {
        let data = { ...params.data }
        data.bhaNum = params.newValue?.value
        if (params.node?.rowPinned !== 'bottom') params.api.applyTransaction({ update: [data] })
        return true
      },
      editable: true,
    },
    {
      headerName: 'SN',
      colId: 'serialNumber',
      field: 'serialNum',
      width: 140,
      editable: (params) => {
        if (typeof params.data?.bhaNum === 'number' && params.data?.bhaNum < 0) return false
        if (params.data?.bhaNum === null || params.data?.bhaNum === undefined) return false
        if (typeof params.data?.bhaNum === 'string' && params.data?.bhaNum === '') return false
        if (typeof params.data?.bhaNum === 'string' && params.data?.bhaNum === '-1') return false

        let bhaDropDown = getBhaDropDown()
        let foundBha = bhaDropDown.findIndex((bha) => bha.value === params.data?.bhaNum)
        return foundBha >= 0
      },
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: (params) => {
        const serialNums = getSerialNumbers(params.data?.bhaNum)
        return { values: serialNums.map((item) => item) }
      },
      valueFormatter: (params) => {
        if (params.node?.rowPinned === 'bottom') return ''
      },
      valueSetter: (params) => {
        let data = { ...params.data }
        data.serialNum = params.newValue
        if (data.serialNum === 'none') data.serialNum = ''
        if (params.node?.rowPinned !== 'bottom') params.api.applyTransaction({ update: [data] })
        return true
      },
      valueGetter: (params) => unescapeHtml(params.data?.serialNum),
    },
  ]

  const gridOptions = {
    pinnedBottomRowData: [inputRow.current],
    onDragStopped: (event) => {
      saveColumnState()
    },
    onColumnVisible: (event) => {
      saveColumnState()
    },
    onCellEditingStopped: (params) => {
      if (params.node?.rowPinned === 'bottom') {
        if (gridApi.current) gridApi.current.setGridOption('pinnedBottomRowData', [inputRow.current])
        handleAddRow(params)
        return
      }

      let newData = cloneDeep(params.data)
      const { costCodes, descriptions } = getDropDowns()

      if (params.colDef.colId === 'costCode') {
        let desc = descriptions.find((description) => description.value === params.data?.costCode)
        if (!desc) return
        newData.description = desc.label
      }

      if (params.colDef.colId === 'description') {
        let desc = descriptions.find((description) => description.label === params.data?.description)
        if (!desc) return
        newData.costCode = desc.value
      }

      if (params.colDef.colId === 'bhaNum') {
        newData.bhaNum = params.newValue.value
      }

      let code = costCodes.find((code) => code.value === newData.costCode)
      if (!code) return

      newData.value = code.costValue
      newData.totalValue = parseFloat(newData.value) * parseFloat(newData.quantity)

      gridApi.current.applyTransaction({ update: [newData] })

      refreshCallback({ action: 'update', data: [newData] })
    },
    getRowStyle: ({ node }) => (node?.rowPinned ? { fontWeight: 'bold', fontStyle: 'italic' } : 0),
  }

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const handleCloseStatus = () => {
    setStatus({ show: false, severity: 'info', message: '' })
  }

  return (
    <Box sx={{ height: getRowHeight(), overflowY: 'hidden' }}>
      {status?.show ? (
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={status?.show}
          autoHideDuration={2000}
          onClose={handleCloseStatus}>
          <Alert onClose={handleCloseStatus} severity={status.severity} elevation={4} variant='filled'>
            {status.message}
          </Alert>
        </Snackbar>
      ) : null}
      {confirm.show ? (
        <ConfirmDialog
          title={confirm?.title}
          open={confirm?.show}
          setOpen={() => setConfirm({ show: false })}
          onConfirm={() => confirmDelete()}>
          {confirm?.text}
        </ConfirmDialog>
      ) : null}
      <div className={getAgGridTheme()} style={{ width: '100%', height: '100%' }}>
        <AgGridReact
          rowData={data.costs}
          columnDefs={sortColDefs(columnDefs, 'dcDetailGrid')}
          defaultColDef={defaultColDef}
          getRowId={getRowId}
          animateRows={true}
          rowSelection='multiple'
          enableRangeSelection='true'
          enableBrowserTooltips={true}
          groupDisplayType={'groupRows'}
          onGridReady={onGridReady}
          groupRowsSticky={true}
          gridOptions={gridOptions}
          getContextMenuItems={getContextMenuItems}
          headerHeight={30}
        />
      </div>
    </Box>
  )
}

export default DailyCostDetailsGrid
