import React, { useMemo, useRef, useState, useEffect, forwardRef, useImperativeHandle } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { Box, Tooltip, Snackbar, Alert, Avatar } from '@mui/material'
import 'components/common/agGridStyles.css'
import { appColors } from 'utils'
import useUnits, { UNITS_FOR } from 'components/common/hooks/useUnits'
import { numberWithCommasDecimals, numberWithCommas } from 'utils/stringFunctions'
import { getItemFromLS, saveItemToLS } from 'utils/localStorage'
import cloneDeep from 'lodash/cloneDeep'
import DailyActivityDetailsGrid from './DailyActivityDetailsGrid'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import { round } from 'utils/numberFunctions'
import { checkPermission } from 'components/userPermissions'
import { userUserRoleAtom } from 'atoms'
import { useRecoilValue } from 'recoil'
import { Icon as Iconify } from '@iconify/react'
import ConfirmDialog from 'components/common/ConfirmDialog'
import { styled } from '@mui/styles'
import PumpDataGridModal from './Modals/PumpDataGridModal'
import pumpIcon from 'assets/mudPumpBlue.png'
import PersonnelGridModal from './Modals/PersonnelGridModal'
import MudGridModal from './Modals/MudGridModal'
import PopupCellRenderer from './PopupCellRenderer'
import {
  sortColDefs,
  htmlSymbolHandling,
  showHideDetailGrid,
  CustomLoadingOverlay,
  getStringId,
} from 'components/common/AgGridUtils'
import { unescapeHtml } from 'utils/htmlSymbolHandling'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const StyledIconContainer = styled(Box)({
  color: appColors.itemTextColor,
  width: '30px',
  height: '30px',
  margin: '5px',
  marginRight: '10px',
  textAlign: 'center',
  '&:hover': {
    cursor: 'pointer',
  },
})

const StyledReportIcon = styled(Iconify)({
  color: appColors.itemTextColor,
  width: '30px',
  height: '30px',
})

const DailyReportGrid = ({
  gridData,
  isLoading,
  getActivityDropDowns,
  setFilteredData,
  setPhaseFilter = null,
  onPdfExport,
  fetchDailyReport,
  setShowChart,
  showChart,
}) => {
  const _isMounted = useRef(false)
  const gridApi = useRef(null)
  const [resetCols, setResetCols] = useState(false)
  const { getUnitsText } = useUnits()
  const [status, setStatus] = useState({ show: false, severity: 'info', message: '' })
  const userRole = useRecoilValue(userUserRoleAtom)
  const [confirm, setConfirm] = useState({ show: false, title: '' })
  const [showPumpData, setShowPumpData] = useState(false)
  const [showPersonnelData, setShowPersonnelData] = useState(false)
  const [showMudData, setShowMudData] = useState(false)
  const dailyReportId = useRef(-1)
  const containerRef = useRef(null)
  const { getAgGridTheme, theme } = useInnovaTheme()

  const addDailyActivity = useInnovaAxios({
    url: '/well/dailyReports/addDailyActivity',
  })

  const updateDailyActivity = useInnovaAxios({
    url: '/well/dailyReports/updateMultiDailyActivity',
  })

  const deleteDailyReport = useInnovaAxios({
    url: '/well/dailyReports/deleteReport',
  })

  const updateDailyReport = useInnovaAxios({
    url: '/well/dailyReports/updateReport',
  })

  const updateDdrFromSlideSheet = useInnovaAxios({
    url: '/well/dailyReports/getDdrFromSlideSheet',
  })

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
    }
  }, [])

  useEffect(() => {
    if (!gridApi.current) return
    gridApi.current.onFilterChanged()
  }, [gridData]) // eslint-disable-line react-hooks/exhaustive-deps

  const columnFormatter = (params) => {
    const { first } = params

    return {
      fill: first ? appColors.slideColor : appColors.rotateColor,
    }
  }

  const tooltipRenderer = (params) => {
    const { yValue, xValue } = params
    return `<div class='grid-sparkline-tooltip'>
                <div class='grid-sparkline-tooltip-title'>
                    <div>${xValue}: ${round(yValue, 2)}</div>
                </div>
            </div>`
  }

  const getHighestValue = (first, second) => {
    let largestAmount = 0
    if (gridData?.length <= 0) return 1

    gridData.forEach((item) => {
      if (item.dailyStats[first] > largestAmount) largestAmount = item.dailyStats[first]
      if (item.dailyStats[second] > largestAmount) largestAmount = item.dailyStats[second]
    })

    return largestAmount
  }

  const isValidDate = (value) => {
    return value instanceof Date || !isNaN(Date.parse(value))
  }

  const dateSortComparator = (date1, date2) => {
    if (!isValidDate(date1) || !isValidDate(date2)) return 0
    const parsedDate1 = new Date(Date.parse(date1))
    const parsedDate2 = new Date(Date.parse(date2))

    return parsedDate1.getTime() - parsedDate2.getTime()
  }

  const centerAlignCell = () => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  })

  function isDateLessThan(date1, date2) {
    if (!isValidDate(date1) || !isValidDate(date2)) return false
    const time1 = new Date(date1).getTime()
    const time2 = new Date(date2).getTime()
    return time1 < time2
  }

  const onClickDelete = (params) => {
    setConfirm({
      show: true,
      title: 'Delete Report',
      text: `Are you sure you want to delete Report# ${params?.data.recordId}?`,
      params: params,
    })
  }

  const confirmDelete = async () => {
    if (confirm?.params?.data?.recordId < 0) return
    const deleteResponse = await deleteDailyReport({
      dailyReportId: confirm?.params?.data?.recordId,
      wellName: confirm?.params?.data?.actualwell,
    })

    if (!deleteResponse.error) {
      if (gridApi.current) gridApi.current.applyTransaction({ remove: [confirm.params.data] })
      return
    }

    setStatus({ show: true, severity: 'error', message: 'Delete Report failed' })
  }

  const handleMenuClick = (action, data) => {
    if (typeof action !== 'string') return
    if (!data) return

    if (action === 'delete') {
      if (!checkPermission('canDelete', userRole.roleAttributes?.permissions)) return
      onClickDelete({ data })
      return
    }

    if (action === 'print') {
      onPdfExport([data?.recordId])
      return
    }

    if (action === 'pumps') {
      dailyReportId.current = data?.recordId
      setShowPumpData(true)
    }

    if (action === 'personnel') {
      dailyReportId.current = data?.recordId
      setShowPersonnelData(true)
    }

    if (action === 'mud') {
      dailyReportId.current = data?.recordId
      setShowMudData(true)
    }

    if (action === 'slideSheet') {
      handleGetFromSlideSheet(data)
    }
  }

  const expandIconRenderer = (params) => {
    return (
      <Box
        onClick={() => showHideDetailGrid(params, 'recordId', false, setShowChart, showChart?.current)}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          '&:hover': {
            cursor: 'pointer',
          },
        }}>
        <Iconify
          icon={!params?.node?.expanded ? 'material-symbols:chevron-right-rounded' : 'mdi:chevron-down'}
          style={{ color: theme === 'dark' ? '#fff' : '#000', height: '30px', width: '30px' }}
        />
      </Box>
    )
  }

  const columnDefs = [
    {
      field: 'activityDetail',
      colId: 'activityDetail',
      headerName: '',
      suppressHeaderMenuButton: true,
      suppressHeaderFilterButton: true,
      sortable: false,
      resizable: false,
      width: 30,
      pinned: 'left',
      lockPosition: 'left',
      cellRenderer: expandIconRenderer,
      cellStyle: centerAlignCell,
    },
    {
      headerName: '',
      colId: 'actions',
      cellStyle: {
        borderRight: '1px solid #45474B',
      },
      cellRenderer: PopupCellRenderer,
      cellRendererParams: {
        menuItems: [
          {
            label: 'Delete',
            action: 'delete',
            onClick: handleMenuClick,
            icon: () => (
              <StyledIconContainer>
                <Iconify
                  icon='fa-regular:trash-alt'
                  style={{ color: 'red', height: '21px', width: '21px', padding: '0px' }}
                />
              </StyledIconContainer>
            ),
          },
          {
            label: 'Print report',
            action: 'print',
            onClick: handleMenuClick,
            icon: () => (
              <StyledIconContainer>
                <StyledReportIcon icon='ic:baseline-print' />
              </StyledIconContainer>
            ),
          },
          {
            label: 'Edit pump data',
            action: 'pumps',
            onClick: handleMenuClick,
            icon: () => (
              <StyledIconContainer>
                <Avatar sx={{ marginTop: '-6px', marginLeft: '-5px' }} alt='pump image' src={pumpIcon}></Avatar>
              </StyledIconContainer>
            ),
          },
          {
            label: 'Edit personnel data',
            action: 'personnel',
            onClick: handleMenuClick,
            icon: () => (
              <StyledIconContainer>
                <StyledReportIcon icon='eva:person-fill' />
              </StyledIconContainer>
            ),
          },
          {
            label: 'Edit mud data',
            action: 'mud',
            onClick: handleMenuClick,
            icon: () => (
              <StyledIconContainer>
                <StyledReportIcon icon='ic:sharp-oil-barrel' />
              </StyledIconContainer>
            ),
          },
          {
            label: 'Get from slide sheet',
            action: 'slideSheet',
            onClick: handleMenuClick,
            icon: () => (
              <StyledIconContainer>
                <StyledReportIcon icon='mdi:table-import' />
              </StyledIconContainer>
            ),
          },
        ],
      },
      pinned: 'left',
      editable: false,
      suppressHeaderMenuButton: true,
      suppressHeaderFilterButton: true,
      filter: null,
      width: 50,
    },
    {
      headerName: '#',
      colId: 'rptNo',
      field: 'repNum',
      editable: true,
      cellStyle: centerAlignCell,
      valueSetter: (params) => {
        if (/^(0|[1-9]\d*)$/.test(params.newValue)) {
          return true
        } else {
          return false
        }
      },
    },
    {
      headerName: 'Date',
      colId: 'date',
      field: 'date',
      sortable: true,
      editable: true,
      cellStyle: centerAlignCell,
      comparator: dateSortComparator,
      valueGetter: (params) => {
        if (!params.data?.date) return ''
        if (typeof params.data?.date !== 'string') return ''
        if (params.data?.date === '') return ''
        let dateTimeStr = params.data?.date + 'T00:00:00'
        if (isDateLessThan(dateTimeStr, '1990-01-01')) return ''
        return new Date(Date.parse(dateTimeStr)).toLocaleDateString('default', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        })
      },
      valueSetter: (params) => {
        if (/^\d{4}-\d{2}-\d{2}$/.test(params.newValue)) {
          let data = { ...params.data }
          data.date = params.newValue
          params.api.applyTransaction({ update: [data] })
          return true
        }
        setStatus({ show: true, severity: 'error', message: 'Format: YYYY-MM-DD' })
        return false
      },
    },
    {
      headerName: 'Start Depth',
      colId: 'startDepth',
      sortable: false,
      cellStyle: centerAlignCell,
      valueFormatter: (params) =>
        numberWithCommas(params.data?.dailyStats?.startDepth === 99999 ? 0 : params.data?.dailyStats?.startDepth),
    },
    {
      headerName: 'End Depth',
      colId: 'endDepth',
      sortable: false,
      cellStyle: centerAlignCell,
      valueFormatter: (params) => numberWithCommas(params.data?.dailyStats?.endDepth),
    },
    {
      headerName: 'Total D&C Hrs',
      colId: 'totalDandC',
      field: 'dailyStats.totalDrillingAndCircHours',
      cellStyle: centerAlignCell,
      valueFormatter: (params) => round(params?.data?.dailyStats?.totalDrillingAndCircHours, 2),
    },
    {
      headerName: 'Off Btm Circ',
      colId: 'circHrs',
      field: 'dailyStats.circHours',
      cellStyle: centerAlignCell,
      valueFormatter: (params) => round(params?.data?.dailyStats?.circHours, 2),
    },
    {
      headerName: 'Mud Type / Wt',
      colId: 'mudTypeWt',
      field: 'mudTypeWt',
      cellStyle: centerAlignCell,
      valueGetter: (params) => {
        if (!params?.data?.mudData?.fluidType) return `${parseFloat(params?.data?.mudData?.mudWeight)}`

        return `${params?.data?.mudData?.fluidType} ${params?.data?.mudData?.fluidType?.length > 0 ? '/' : ''} ${
          params?.data?.mudData?.mudWeight
        }`
      },
    },
    {
      headerName: 'Hole Size',
      colId: 'holeSize',
      field: 'holeSize',
      cellStyle: centerAlignCell,
      editable: false,
      valueFormatter: (params) => {
        return Array.isArray(params.data?.holeSizes) ? params.data?.holeSizes.join(',') : ''
      },
    },
    {
      headerName: 'MWD Type',
      colId: 'mwdType',
      field: 'mwdType',
      width: 110,
      cellStyle: centerAlignCell,
      editable: true,
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: (params) => {
        const { mwdType } = getActivityDropDowns()
        return { values: mwdType.map((item) => item) }
      },
    },
    {
      headerName: 'Drive Type',
      colId: 'driveType',
      field: 'driveType',
      width: 110,
      cellStyle: centerAlignCell,
      editable: true,
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: (params) => {
        const { driveType } = getActivityDropDowns()
        return { values: driveType.map((item) => item) }
      },
    },
    {
      headerName: 'Phase',
      colId: 'phase',
      field: 'phases',
      cellStyle: centerAlignCell,
      valueFormatter: (params) => (Array.isArray(params?.data?.phases) ? params?.data?.phases.join(',') : ''),
    },
    {
      headerName: 'Day DD',
      colId: 'dayDd',
      field: 'personnelData.dayDd',
      cellStyle: centerAlignCell,
      valueGetter: (params) => unescapeHtml(params.data?.personnelData?.dayDd),
    },
    {
      headerName: 'Night DD',
      colId: 'nightDd',
      field: 'personnelData.nightDd',
      cellStyle: centerAlignCell,
      valueGetter: (params) => unescapeHtml(params.data?.personnelData?.nightDd),
    },
    {
      headerName: 'Co Man',
      colId: 'coMan',
      field: 'personnelData.dayCoMan',
      cellStyle: centerAlignCell,
      valueGetter: (params) => unescapeHtml(params.data?.personnelData?.dayCoMan),
    },
    {
      headerName: 'Daily Cost',
      colId: 'dailyCost',
      cellStyle: { paddingRight: '5px' },
      valueFormatter: (params) =>
        `${getUnitsText(UNITS_FOR.Cost)}${numberWithCommasDecimals(params?.data?.dailyCost, 2)}`,
    },
    {
      headerName: 'Cost Details',
      colId: 'costDetails',
      suppressHeaderMenuButton: true,
      suppressHeaderFilterButton: true,
      filter: null,
      cellRenderer: (params) => {
        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              marginTop: '7px',
              width: params.column.actualWidth - 10,
              height: 25,
              borderTopLeftRadius: '3px',
              borderBottomLeftRadius: '3px',
              border: '1px solid rgba(255,255,255,0.2)',
            }}>
            {params.data?.costDetail.map((val, idx) => {
              let widthPercentage = (val.value / params.data?.maxDailyCost) * 100
              let widthPixelCount = (widthPercentage / 100) * params.column.actualWidth
              return (
                <Tooltip
                  key={`${idx}-${params.data?.recordId}`}
                  title={`${val.label}: ${getUnitsText(UNITS_FOR.Cost)}${numberWithCommasDecimals(val.value, 2)}`}
                  placement='bottom'
                  componentsProps={{
                    tooltip: {
                      sx: {
                        backgroundColor: 'rgb(19,62,96)',
                        fontSize: '12px',
                        fontFamily: 'Roboto',
                      },
                    },
                  }}>
                  <Box
                    sx={{
                      width: widthPixelCount,
                      height: '100%',
                      backgroundColor: `${val.color}`,
                      borderTopLeftRadius: idx === 0 ? '1px' : '0px',
                      borderBottomLeftRadius: idx === 0 ? '1px' : '0px',
                    }}></Box>
                </Tooltip>
              )
            })}
          </Box>
        )
      },
    },
    {
      headerName: 'Cumulative Cost',
      colId: 'cumCost',
      field: 'runningTotal',
      cellStyle: { paddingRight: '5px' },
      valueFormatter: (params) =>
        `${getUnitsText(UNITS_FOR.Cost)}${numberWithCommasDecimals(params?.data?.runningTotal, 2)}`,
    },
    {
      headerName: `Footage(${getUnitsText(UNITS_FOR.Depth)})`,
      colId: 'footage',
      suppressHeaderMenuButton: true,
      suppressHeaderFilterButton: true,
      filter: null,
      cellRenderer: 'agSparklineCellRenderer',
      cellRendererParams: {
        sparklineOptions: {
          type: 'bar',
          paddingOuter: 0.25,
          tooltip: {
            xOffset: 20,
            yOffset: -20,
            renderer: tooltipRenderer,
          },
          formatter: columnFormatter,
          label: {
            enabled: true,
            placement: 'outsideEnd',
            color: 'white',
            fontSize: 9,
          },
          stroke: '#91cc75',
          highlightStyle: {
            fill: '#fac858',
          },
          valueAxisDomain: [0, getHighestValue('slideDrilled', 'rotateDrilled')],

          padding: {
            top: 0,
            bottom: 0,
            right: 30,
          },
          axis: {
            strokeWidth: 0,
          },
        },
      },
      valueGetter: (params) => {
        return [
          ['Slide', params?.data?.dailyStats?.slideDrilled],
          ['Rotate', params?.data?.dailyStats?.rotateDrilled],
        ]
      },
    },
    {
      field: 'Hours',
      colId: 'hours',
      width: 140,
      suppressHeaderMenuButton: true,
      suppressHeaderFilterButton: true,
      filter: null,
      cellRenderer: 'agSparklineCellRenderer',
      cellRendererParams: {
        sparklineOptions: {
          paddingOuter: 0.5,
          type: 'bar',
          tooltip: {
            xOffset: 20,
            yOffset: -20,
            renderer: tooltipRenderer,
          },
          formatter: columnFormatter,
          label: {
            enabled: true,
            placement: 'outsideEnd',
            color: 'white',
            fontSize: 9,
          },
          stroke: '#91cc75',
          highlightStyle: {
            fill: '#fac858',
          },
          valueAxisDomain: [0, getHighestValue('slideHours', 'rotateHours')],
          padding: {
            top: 0,
            bottom: 0,
            right: 30,
          },
          axis: {
            strokeWidth: 0,
          },
        },
      },
      valueGetter: (params) => {
        return [
          ['Slide', params?.data?.dailyStats?.slideHours],
          ['Rotate', params?.data?.dailyStats?.rotateHours],
        ]
      },
    },
    {
      field: 'Activity (Hrs)',
      colId: 'activity',
      suppressHeaderMenuButton: true,
      suppressHeaderFilterButton: true,
      filter: null,
      cellRenderer: (params) => {
        if (!Array.isArray(params.data?.activitySummary)) return null
        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              marginTop: '7px',
              width: params.column.actualWidth - 10,
              height: 25,
              borderTopLeftRadius: '3px',
              borderBottomLeftRadius: '3px',
              border: '1px solid rgba(255,255,255,0.2)',
            }}>
            {params.data?.activitySummary.map((val, idx) => {
              let widthPercentage = (val.hours / 24) * 100
              let widthPixelCount = (widthPercentage / 100) * params.column.actualWidth
              return (
                <Tooltip
                  key={`${idx}-${params.data?.recordId}`}
                  title={`${val.activityCode}:${val.activityCodeDesc}- ${round(val.hours, 2)}hrs`}
                  placement='bottom'
                  componentsProps={{
                    tooltip: {
                      sx: {
                        backgroundColor: 'rgb(19,62,96)',
                        fontSize: '12px',
                        fontFamily: 'Roboto',
                      },
                    },
                  }}>
                  <Box
                    sx={{
                      width: widthPixelCount,
                      height: '100%',
                      backgroundColor: `${val.color}`,
                      borderTopLeftRadius: idx === 0 ? '1px' : '0px',
                      borderBottomLeftRadius: idx === 0 ? '1px' : '0px',
                    }}></Box>
                </Tooltip>
              )
            })}
          </Box>
        )
      },
    },
    {
      field: 'comments',
      colId: 'comments',
      cellStyle: { textAlign: 'start', paddingLeft: '5px' },
      editable: true,
      cellEditor: 'agLargeTextCellEditor',
      cellEditorPopup: true,
      cellEditorParams: {
        maxLength: 1000,
        rows: 10,
        cols: 50,
      },
    },
    {
      field: 'lookahead',
      colId: 'lookahead',
      cellStyle: { textAlign: 'start', paddingLeft: '5px' },
      editable: true,
      cellEditor: 'agLargeTextCellEditor',
      cellEditorPopup: true,
      cellEditorParams: {
        maxLength: 1000,
        rows: 10,
        cols: 50,
      },
    },
    {
      field: 'summary',
      colId: 'summary',
      cellStyle: { textAlign: 'start', paddingLeft: '5px' },
      editable: true,
      cellEditor: 'agLargeTextCellEditor',
      cellEditorPopup: true,
      cellEditorParams: {
        maxLength: 1000,
        rows: 10,
        cols: 50,
      },
    },
    {
      headerName: 'Casing Depth',
      colId: 'casingMd',
      cellStyle: centerAlignCell,
      editable: true,
      valueSetter: (params) => {
        if (/^[0-9]+(\.[0-9]+)?$/.test(params.newValue)) {
          let paramsDataCopy = { ...params.data }
          params.api.applyTransaction({ update: [paramsDataCopy] })
          return true
        } else {
          return false
        }
      },
      valueFormatter: (params) => {
        if (params?.data?.casingData?.casingMd === 0) return ''
        return numberWithCommasDecimals(parseFloat(params?.data?.casingData?.casingMd), 2)
      },
    },
    {
      headerName: 'Casing OD',
      colId: 'casingOd',
      cellStyle: centerAlignCell,
      editable: true,
      valueSetter: (params) => {
        if (/^[0-9]+(\.[0-9]+)?$/.test(params.newValue)) {
          let paramsDataCopy = { ...params.data }
          params.api.applyTransaction({ update: [paramsDataCopy] })
          return true
        } else {
          return false
        }
      },
      valueFormatter: (params) => {
        if (params?.data?.casingData?.casingOd === 0) return ''
        return numberWithCommasDecimals(parseFloat(params?.data?.casingData?.casingOd), 2)
      },
    },
    {
      headerName: 'Casing Wt',
      colId: 'casingWt',
      cellStyle: centerAlignCell,
      editable: true,
      valueSetter: (params) => {
        if (/^[0-9]+(\.[0-9]+)?$/.test(params.newValue)) {
          let paramsDataCopy = { ...params.data }
          params.api.applyTransaction({ update: [paramsDataCopy] })
          return true
        } else {
          return false
        }
      },
      valueFormatter: (params) => {
        if (params?.data?.casingData?.casingWt === 0) return ''
        return numberWithCommasDecimals(parseFloat(params?.data?.casingData?.casingWt), 2)
      },
    },
  ]

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: false,
      autoHeight: true,
      editable: false,
      suppressHeaderMenuButton: false,
      cellStyle: { textAlign: 'right', padding: 0 },
      headerClass: 'header-no-padding',
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
    }
  }, [])

  const saveColumnState = () => {
    if (gridApi.current) {
      const colLayout = gridApi.current.getColumnState()
      if (colLayout) saveItemToLS('dailyReportGrid', 'colLayout', colLayout)
    }
  }

  const handleDailyReportUpdate = async (payload, newRowData) => {
    if (!payload || !gridApi.current) return

    let response = await updateDailyReport(payload)
    if (response?.error) {
      setStatus({ show: true, severity: 'error', message: response?.error?.response?.data?.error })
      return
    }

    const { casingOd, casingWt, casingMd } = payload
    newRowData.casingData = { casingOd, casingWt, casingMd }
    gridApi.current.applyTransaction({
      update: [cloneDeep(newRowData)],
    })
  }

  const gridOptions = {
    suppressRowClickSelection: true,
    sideBar: {
      toolPanels: [
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        },
      ],
      defaultToolPanel: '',
      position: 'left',
    },
    onDragStopped: () => {
      saveColumnState()
    },
    onColumnVisible: () => {
      saveColumnState()
    },
    onCellEditingStopped: (params) => {
      if (!gridApi.current || !params.valueChanged) return

      let newRowData = cloneDeep(params.data)

      //casingData must be destructured for payload
      const { casingOd, casingWt, casingMd } = params.data?.casingData

      let payload = {
        wellName: params.data?.actualwell,
        dailyReportId: params.data?.recordId,
        repNum: params.colDef.field === 'repNum' ? parseInt(params.newValue) : params.data?.repNum,
        casingOd: params.colDef.colId === 'casingOd' ? parseFloat(params.newValue) : casingOd,
        casingWt: params.colDef.colId === 'casingWt' ? parseFloat(params.newValue) : casingWt,
        casingMd: params.colDef.colId === 'casingMd' ? parseFloat(params.newValue) : casingMd,
        summary: params.data?.summary,
        lookahead: params.data?.lookahead,
        comments: params.data?.comments,
        mwdType: params.data?.mwdType,
        driveType: params.data?.driveType,
        date: params.colDef.colId === 'date' ? params.newValue : params.data?.date,
      }

      handleDailyReportUpdate(payload, newRowData)
    },
    loadingOverlayComponent: CustomLoadingOverlay,
  }

  const onGridReady = (params) => {
    gridApi.current = params.api

    const colLayout = getItemFromLS('dailyReportGrid', 'colLayout')
    if (colLayout && gridApi.current) {
      gridApi.current.applyColumnState({ state: colLayout, applyOrder: true })
    }
  }

  const onFilterChanged = (params) => {
    if (!gridApi.current) return

    let filteredNodes = []
    params.api.forEachNodeAfterFilter((node) => {
      filteredNodes.push(node.data)
    })

    let filterModel = gridApi.current.getFilterModel()
    if (setPhaseFilter) {
      setPhaseFilter(
        filterModel?.hasOwnProperty('phase') && Array.isArray(filterModel.phase.values) ? filterModel.phase.values : [],
      )
    }

    if (_isMounted.current) setFilteredData(filteredNodes)
  }

  const onFirstDataRendered = (params) => {
    if (gridApi.current) gridApi.current.onFilterChanged()
    autoSizeColumns()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const getContextMenuItems = (params) => {
    return [
      {
        name: 'Reset columns',
        disabled: false,
        action: () => {
          gridApi.current.resetColumnState()
          saveItemToLS('dailyReportGrid', 'colLayout', null)
          setResetCols(!resetCols)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Reset filters',
        disabled: false,
        action: () => {
          gridApi.current.setFilterModel(null)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      'copy',
      {
        name: 'Export',
        disabled: false,
        action: () => {
          gridApi.current.exportDataAsExcel({
            fileName: 'DailyReportData.xlsx',
            sheetName: 'DailyReports',
          })
        },
        icon: '<span class="iconify" data-icon="icomoon-free:file-excel" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
    ]
  }

  const handleGetFromSlideSheet = async (data) => {
    if (!data) return
    if (!data.hasOwnProperty('recordId')) return

    let response = await updateDdrFromSlideSheet({ wellName: data.actualwell, dailyReportId: data.recordId })
    if (response.error) {
      setStatus({ show: true, severity: 'error', message: response?.error?.response?.data?.error })
      return false
    }

    let updatedReportData = await fetchDailyReport(data.recordId)
    if (!updatedReportData) return

    const currentRowData = gridApi.current.getRowNode(data.recordId)
    if (!currentRowData) return
    let newRowData = cloneDeep(currentRowData.data)

    newRowData.dailyStats = cloneDeep(updatedReportData.dailyStats)
    newRowData.phases = cloneDeep(updatedReportData.phases)
    newRowData.dailyActivity = cloneDeep(updatedReportData.dailyActivity)

    if (gridApi.current) {
      gridApi.current.applyTransaction({
        update: [newRowData],
      })
    }

    let filteredNodes = []
    gridApi.current.forEachNodeAfterFilter((node) => {
      filteredNodes.push(node.data)
    })

    if (_isMounted.current) setFilteredData(filteredNodes)
  }

  const handleUpdates = async (data, newRowData) => {
    if (!newRowData) return false
    if (!Array.isArray(newRowData.dailyActivity)) return false
    if (!data) return false
    if (!Array.isArray(data)) return false
    if (data.length === 0) return true

    for (let i = 0; i < data.length; i++) {
      let index = newRowData.dailyActivity.findIndex((activity) => activity.uid === data[i].uid)
      if (index < 0) return false

      newRowData.dailyActivity[index] = { ...data[i] }
      data[i].startDepth = parseFloat(data[i].startDepth)
      data[i].endDepth = parseFloat(data[i].endDepth)
      data[i].bha = parseInt(data[i].bha)
      data[i].recordId = parseInt(data[i].recordId)
      data[i] = htmlSymbolHandling(data[i])
    }

    let response = await updateDailyActivity({
      activityData: JSON.stringify(data),
      wellName: newRowData.actualwell,
    })

    if (response?.error) {
      setStatus({ show: true, severity: 'error', message: response?.error?.response?.data?.error })
      return false
    }

    return true
  }

  const updateDailyReportGridCallback = async (params) => {
    if (!params) return
    if (!params.hasOwnProperty('action')) return
    if (!gridApi.current) return
    if (!Array.isArray(params.data)) return
    if (params.data?.length === 0) return
    if (params.data?.length > 1 && (params.action === 'delete' || params.action === 'add')) return

    const currentRowData = gridApi.current.getRowNode(params.data[0].recordId)
    if (!currentRowData) return
    let newRowData = cloneDeep(currentRowData.data)

    if (params.action === 'delete') {
      let index = newRowData.dailyActivity.findIndex((activity) => activity.uid === params.data[0].uid)
      if (index < 0) return
      newRowData.dailyActivity.splice(index, 1)
    }

    //Updates need to run before the adds to ensure the sqeuence number on the add is correct
    if (params.action === 'update') {
      let res = await handleUpdates(params.data, newRowData)
      if (!res) return
    }

    if (
      params.action === 'add' &&
      params.hasOwnProperty('dataToUpdate') &&
      Array.isArray(params.dataToUpdate) &&
      params.dataToUpdate.length > 0
    ) {
      let res = await handleUpdates(params.dataToUpdate, newRowData)
      if (!res) return
    }

    if (params.action === 'add') {
      let payload = {
        wellName: newRowData.actualwell,
        dailyReportId: params.data[0].recordId,
        activity: params.data[0].activity,
        description: params.data[0].activityCodeDesc,
        endDepth: params.data[0].endDepth,
        endTime: params.data[0].endTime,
        phase: params.data[0].phase,
        startDepth: params.data[0].startDepth,
        startTime: params.data[0].startTime,
        bha: params.data[0].bha,
      }

      let escapedData = htmlSymbolHandling(payload)
      escapedData.wellName = newRowData.actualwell

      let response = await addDailyActivity(escapedData)

      if (response?.error) {
        setStatus({ show: true, severity: 'error', message: response?.error?.response?.data?.error })
        return
      }
    }

    let updatedReportData = await fetchDailyReport(params.data[0].recordId)
    if (!updatedReportData) return

    newRowData.dailyStats = cloneDeep(updatedReportData.dailyStats)
    newRowData.phases = cloneDeep(updatedReportData.phases)
    newRowData.dailyActivity = cloneDeep(updatedReportData.dailyActivity)

    if (gridApi.current) {
      gridApi.current.applyTransaction({
        update: [newRowData],
      })
    }

    let filteredNodes = []
    gridApi.current.forEachNodeAfterFilter((node) => {
      filteredNodes.push(node.data)
    })

    if (_isMounted.current) setFilteredData(filteredNodes)
    return newRowData
  }

  const DetailCellRenderer = forwardRef((params, ref) => {
    useImperativeHandle(ref, () => {
      return {
        refresh() {
          return true
        },
      }
    })

    let height = 200
    if (containerRef.current) {
      height = containerRef.current.clientHeight * 0.65
    }

    let prevReportEndDepth = -1
    let nextReportStartDepth = 9999999

    if (params?.data.hasOwnProperty('recordId') && Array.isArray(gridData)) {
      let dailyReportIndex = gridData.findIndex((day) => day.recordId === params.data?.recordId)
      if (dailyReportIndex >= 0 && dailyReportIndex < gridData.length - 1) {
        if (
          Array.isArray(gridData[dailyReportIndex + 1].dailyActivity) &&
          gridData[dailyReportIndex + 1].dailyActivity.length > 0
        ) {
          prevReportEndDepth =
            gridData[dailyReportIndex + 1].dailyActivity[gridData[dailyReportIndex + 1].dailyActivity.length - 1]
              .endDepth
        }
      }

      if (dailyReportIndex >= 1) {
        if (
          Array.isArray(gridData[dailyReportIndex - 1].dailyActivity) &&
          gridData[dailyReportIndex - 1].dailyActivity.length > 0
        ) {
          nextReportStartDepth = gridData[dailyReportIndex - 1].dailyActivity[0].startDepth
        }
      }
    }

    return (
      <Box sx={{ height: height }}>
        <DailyActivityDetailsGrid
          parentRowData={params.data}
          refreshCallback={updateDailyReportGridCallback}
          getActivityDropDowns={getActivityDropDowns}
          prevDailyReportEndDepth={prevReportEndDepth}
          nextDailyReportStartDepth={nextReportStartDepth}
          setStatus={setStatus}
        />
      </Box>
    )
  })

  const handleCloseStatus = () => {
    setStatus({ show: false, severity: 'info', message: '' })
  }

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.recordId)
    }
  }, [])

  const updateParentGrid = (newData, field) => {
    if (!gridApi.current) return
    const currentRowData = gridApi.current.getRowNode(newData.dailyReportId)
    if (!currentRowData) return

    let newRowData = cloneDeep(currentRowData.data)

    if (!newRowData.hasOwnProperty(field)) return
    let propertyName = field === 'mudData' ? 'value' : 'name'
    newRowData[field][newData.tag] = newData[propertyName]

    gridApi.current.applyTransaction({
      update: [cloneDeep(newRowData)],
    })
  }

  return (
    <React.Fragment>
      {confirm.show ? (
        <ConfirmDialog
          title={confirm?.title}
          open={confirm?.show}
          setOpen={() => setConfirm({ show: false })}
          onConfirm={() => confirmDelete()}>
          {confirm?.text}
        </ConfirmDialog>
      ) : null}
      {showPumpData ? (
        <PumpDataGridModal setOpen={() => setShowPumpData(false)} dailyReportId={dailyReportId.current} />
      ) : null}
      {showPersonnelData ? (
        <PersonnelGridModal
          setOpen={() => {
            dailyReportId.current = -1
            setShowPersonnelData(false)
          }}
          dailyReportId={dailyReportId.current}
          updateParentGrid={updateParentGrid}
        />
      ) : null}
      {showMudData ? (
        <MudGridModal
          setOpen={() => {
            dailyReportId.current = -1
            setShowMudData(false)
          }}
          dailyReportId={dailyReportId.current}
          updateParentGrid={updateParentGrid}
        />
      ) : null}
      <div ref={containerRef} style={{ height: '100%', width: '100%' }} className={getAgGridTheme()}>
        <AgGridReact
          loading={isLoading}
          rowData={gridData}
          columnDefs={sortColDefs(columnDefs, 'dailyReportGrid')}
          headerHeight={30}
          defaultColDef={defaultColDef}
          gridOptions={gridOptions}
          onGridReady={onGridReady}
          onFirstDataRendered={onFirstDataRendered}
          onFilterChanged={onFilterChanged}
          getContextMenuItems={getContextMenuItems}
          animateRows={true}
          masterDetail={true}
          detailRowAutoHeight={true}
          detailCellRenderer={DetailCellRenderer}
          getRowId={getRowId}
        />
      </div>
      {status?.show ? (
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={status?.show}
          autoHideDuration={2000}
          onClose={handleCloseStatus}>
          <Alert onClose={handleCloseStatus} severity={status.severity} elevation={4} variant='filled'>
            {status.message}
          </Alert>
        </Snackbar>
      ) : null}
    </React.Fragment>
  )
}

export default DailyReportGrid
