import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react'
import { Box } from '@mui/material'
import { v4 as uuidv4 } from 'uuid'
import { appColors } from 'utils'
import ElementPropertyModal from 'components/WellPages/WallplotComposer/Elements/ElementPropertyModal/ElementPropertyModal'
import { ELEMENT_TYPES as elementType } from 'components/WellPages/WallplotComposer/Elements/elementDefs'
import WallPlotChart from 'components/WellPages/WallplotComposer/Elements/ChartElements/WallPlotChart'
import { cloneDeep } from 'lodash'
import {
  LABEL_CATEGORIES as labelCategories,
  hasLabelCategory,
  getLabelPosByMd,
  getLastVisibleSurveyPt,
  generateDepthLabels,
  shouldRegenerateLabels,
} from 'components/WellPages/WallplotComposer/Elements/ChartElements/labelDefinitions'
import { getTargets2D } from 'components/WellPages/PlanView/targets2D'

export const getChartElementDefaults = ({ pageLayout, zIndex }) => {
  return {
    uid: uuidv4(),
    type: elementType.table,
    chartSettings: {
      majorTicks: 10,
      minorTicks: 0,
      gridBackgroundColor: '#FFF',
      majorGridLineColor: '#404040',
      minorGridLineColor: '#404040',
      showXAxisMajorGridLines: true,
      showXAxisMinorGridLines: false,
      showYAxisMajorGridLines: true,
      showYAxisMinorGridLines: false,
      showXAxisBottom: true,
      showXAxisTop: false,
      showYAxisLeft: true,
      showYAxisRight: false,
      showScales: true,
      fixedScaleCm: false,
      fixedScaleEnabled: false,
      fixedScaleValue: 1,
      xAxisUserDefined: false,
      xMin: 0,
      xMax: 100,
      xAxisScale: -1,
      yAxisUserDefined: false,
      yMin: 0,
      yMax: 100,
      yAxisScale: -1,
      labels: {
        categories: [],
        categoryOptions: [
          {
            category: labelCategories.surveyPts,
            options: {
              mdTvd: 'MD',
              inc: false,
              azi: false,
              interval: 100,
              atSurvey: true,
              range: false,
              startDepth: 0,
              endDepth: 99999,
            },
          },
        ],
        labelData: [
          //uniqueness...
          // type: wellname, rownum, (depth)
          // position
          // stles
        ],
      },
    },
    left: pageLayout.marginLeft,
    top: pageLayout.marginTop,
    width: 3,
    height: 3,
    zIndex: zIndex,
    style: {
      borderStyle: 'solid',
      borderWidth: '1px',
      borderColor: '#333',
      backgroundColor: '#fff',
      opacity: 1,
      cursor: 'pointer',
    },
  }
}

const copyCategoryProps = (label, labelCategory) => {
  // saved props
  label.color = labelCategory.color
  label.size = labelCategory.size
  label.bold = labelCategory.bold
  label.hasTether = labelCategory.hasTether
  // annotation props
  label.style.font.size = labelCategory.size
  label.font.size = labelCategory.size
  label.font.weight = labelCategory.bold ? 'bold' : 'normal'
  label.callout.display = labelCategory.hasTether
}

const ChartElement = forwardRef(({ id, element, wellData, actions, updateContainer, scale }, ref) => {
  const [showProperties, setShowProperties] = useState(false)
  const [rerender, setRerender] = useState(false) // eslint-disable-line
  const currentContentRef = useRef({ style: element.style, chartSettings: element.chartSettings })
  const wallPlotChartRef = useRef(null)

  useImperativeHandle(ref, () => {
    return {
      showProperties() {
        setShowProperties(true)
      },
      getSettings() {
        return currentContentRef.current
      },
    }
  })

  const handleModalChange = (newData) => {
    let newContent = {
      style: { ...currentContentRef.current.style, ...newData.style },
      chartSettings: { ...currentContentRef.current.chartSettings, ...newData.context },
      // compile error // need to merge labelData in above chartSettings assignement. the above statement replaces the
      // the label data in currentContentRef.current.chartSettings with the empty labelData array from newData, which
      // we don't want to do. We want to merge the labelData arrays.
    }
    // currentContentRef.current = newContent
    // process label categories into chart data here...

    if (newContent.chartSettings.hasOwnProperty('labels')) {
      // remove offset label categories if showOffsets is false
      if (!newContent.chartSettings.showOffsets) {
        newContent.chartSettings.labels.categories = newContent.chartSettings.labels.categories.filter(
          (cat) => cat.category !== labelCategories.offsetWellNames && cat.category !== labelCategories.offsetSurveyPts,
        )
      }
      if (!newContent.chartSettings.showTargets) {
        newContent.chartSettings.labels.categories = newContent.chartSettings.labels.categories.filter(
          (cat) => cat.category !== labelCategories.targets,
        )
      }

      const labels = newContent.chartSettings.labels
      const labelData = []
      labels.categories.forEach((labelCat) => {
        switch (labelCat.category) {
          case labelCategories.wellNames:
            if (!hasLabelCategory(labelCategories.wellNames, currentContentRef.current.chartSettings.labels)) {
              // object format the api will store (in dev, not complete):
              const lastVisibleSurvey = getLastVisibleSurveyPt(
                wellData.surveys,
                {
                  xMin: newContent.chartSettings.xMin,
                  xMax: newContent.chartSettings.xMax,
                  yMin: newContent.chartSettings.yMin,
                  yMax: newContent.chartSettings.yMax,
                },
                currentContentRef.current.chartSettings.chartType,
              )
              labelData.push({
                catType: labelCategories.wellNames,
                color: labelCat.color,
                size: labelCat.size,
                bold: labelCat.bold,
                hasTether: labelCat.hasTether,
                id: wellData.actualWellData.actualWellName,
                uid: wellData.actualWellData.actualWellName,
                style: {
                  borderColor: '#AAA',
                  font: {
                    size: labelCat.size,
                    weight: labelCat.bold ? 'bold' : 'normal',
                  },
                },
                position: {
                  x: 'end',
                  y: 'center',
                },
                // object properties the anno plugin expects:
                type: 'label',
                drawTime: 'afterDatasetsDraw',
                callout: {
                  display: labelCat.hasTether,
                  borderColor: '#AAA',
                },
                content: wellData.actualWellData.actualWellName,
                font: {
                  size: labelCat.size,
                  weight: labelCat.bold ? 'bold' : 'normal',
                },
                xValue:
                  currentContentRef.current.chartSettings.chartType === 'plan'
                    ? lastVisibleSurvey.ew
                    : lastVisibleSurvey.vs,
                yValue:
                  currentContentRef.current.chartSettings.chartType === 'plan'
                    ? lastVisibleSurvey.ns
                    : lastVisibleSurvey.tvd,
                xAdjust: 0,
                yAdjust: -100,
              })
            }
            // if labels for this category exist, walk through and update them
            if (hasLabelCategory(labelCategories.wellNames, currentContentRef.current.chartSettings.labels)) {
              currentContentRef.current.chartSettings.labels.labelData.forEach((label) => {
                if (label.catType === labelCategories.wellNames) {
                  copyCategoryProps(label, labelCat)
                  labelData.push(label)
                }
              })
            }
            break
          case labelCategories.surveyPts:
            // check the new options vs old options
            let generateLabels = false
            if (hasLabelCategory(labelCategories.surveyPts, currentContentRef.current.chartSettings.labels)) {
              const priorOptions = currentContentRef.current.chartSettings.labels.categoryOptions.find(
                (opts) => opts.category === labelCategories.surveyPts,
              )
              const newOptions = labels.categoryOptions.find((opts) => opts.category === labelCategories.surveyPts)
              generateLabels = shouldRegenerateLabels(priorOptions.options, newOptions.options)
              if (!generateLabels) {
                currentContentRef.current.chartSettings.labels.labelData.forEach((label) => {
                  if (label.catType === labelCategories.surveyPts) {
                    copyCategoryProps(label, labelCat)
                    labelData.push(label)
                  }
                })
              }
            }

            if (
              !hasLabelCategory(labelCategories.surveyPts, currentContentRef.current.chartSettings.labels) ||
              generateLabels
            ) {
              // if index/interval options have changed, regenerate the labels
              const labels = generateDepthLabels(
                labelCategories.surveyPts,
                wellData,
                newContent.chartSettings?.labels?.categoryOptions,
              )

              labels.forEach((label, i) => {
                labelData.push({
                  catType: labelCategories.surveyPts,
                  color: labelCat.color,
                  size: labelCat.size,
                  bold: labelCat.bold,
                  hasTether: labelCat.hasTether,
                  id: `${wellData.actualWellData.actualWellName}-${label.md}`,
                  uid: `${wellData.actualWellData.actualWellName}-${label.md}`,
                  style: {
                    borderColor: '#AAA',
                    font: {
                      size: labelCat.size,
                      weight: labelCat.bold ? 'bold' : 'normal',
                    },
                  },
                  position: {
                    x: 'end',
                    y: 'center',
                  },
                  type: 'label',
                  drawTime: 'afterDatasetsDraw',
                  callout: {
                    display: labelCat.hasTether,
                    borderColor: '#AAA',
                  },
                  content: label.label,
                  font: {
                    size: labelCat.size,
                    weight: labelCat.bold ? 'bold' : 'normal',
                  },
                  xValue: currentContentRef.current.chartSettings.chartType === 'plan' ? label.ew : label.vs,
                  yValue: currentContentRef.current.chartSettings.chartType === 'plan' ? label.ns : label.tvd,
                  xAdjust: -100,
                  yAdjust: 0,
                })
              })
            }
            break
          case labelCategories.casing:
            if (!hasLabelCategory(labelCategories.casing, currentContentRef.current.chartSettings.labels)) {
              // object format the api will store (in dev, not complete):
              wellData.actualWellData.casing.forEach((casingItem) => {
                const labelPos = getLabelPosByMd(
                  casingItem.MD,
                  currentContentRef.current.chartSettings.chartType,
                  wellData.surveys,
                  wellData.actualWellData.verticalSection,
                )
                labelData.push({
                  catType: labelCategories.casing,
                  color: labelCat.color,
                  size: labelCat.size,
                  bold: labelCat.bold,
                  hasTether: labelCat.hasTether,
                  id: `${casingItem.Type}-${casingItem.MD}`,
                  uid: `${casingItem.Type}-${casingItem.MD}`,
                  style: {
                    borderColor: '#AAA',
                    font: {
                      size: labelCat.size,
                      weight: labelCat.bold ? 'bold' : 'normal',
                    },
                  },
                  position: {
                    x: 'end',
                    y: 'center',
                  },
                  type: 'label',
                  drawTime: 'afterDatasetsDraw',
                  callout: {
                    display: labelCat.hasTether,
                    borderColor: '#AAA',
                  },
                  content: `${casingItem.OD.toFixed(3)} ${casingItem.Type}`,
                  font: {
                    size: labelCat.size,
                    weight: labelCat.bold ? 'bold' : 'normal',
                  },
                  xValue: labelPos.x,
                  yValue: labelPos.y,
                  xAdjust: 10,
                  yAdjust: 0,
                })
              })
            }
            // if labels for this categoryh exist, walk through and update them
            if (hasLabelCategory(labelCategories.casing, currentContentRef.current.chartSettings.labels)) {
              currentContentRef.current.chartSettings.labels.labelData.forEach((label) => {
                if (label.catType === labelCategories.casing) {
                  copyCategoryProps(label, labelCat)
                  labelData.push(label)
                }
              })
            }
            break
          case labelCategories.annotations:
            if (!hasLabelCategory(labelCategories.annotations, currentContentRef.current.chartSettings.labels)) {
              wellData.actualWellData.annotations?.forEach((anno) => {
                const labelPos = getLabelPosByMd(
                  anno.md,
                  currentContentRef.current.chartSettings.chartType,
                  wellData.surveys,
                  wellData.actualWellData.verticalSection,
                )
                labelData.push({
                  catType: labelCategories.annotations,
                  color: labelCat.color,
                  size: labelCat.size,
                  bold: labelCat.bold,
                  hasTether: labelCat.hasTether,
                  id: `${wellData.actualWellData.actualWellName}-${anno.md}`,
                  uid: `${wellData.actualWellData.actualWellName}-${anno.md}`,
                  style: {
                    borderColor: '#AAA',
                    font: {
                      size: labelCat.size,
                      weight: labelCat.bold ? 'bold' : 'normal',
                    },
                  },
                  position: {
                    x: 'end',
                    y: 'center',
                  },
                  type: 'label',
                  drawTime: 'afterDatasetsDraw',
                  callout: {
                    display: labelCat.hasTether,
                    borderColor: '#AAA',
                  },
                  content: `${anno.annotation}`,
                  font: {
                    size: labelCat.size,
                    weight: labelCat.bold ? 'bold' : 'normal',
                  },
                  xValue: labelPos.x,
                  yValue: labelPos.y,
                  xAdjust: 10,
                  yAdjust: 0,
                })
              })
            }
            // if labels for this category exist, walk through and update them
            if (hasLabelCategory(labelCategories.annotations, currentContentRef.current.chartSettings.labels)) {
              currentContentRef.current.chartSettings.labels.labelData.forEach((label) => {
                if (label.catType === labelCategories.annotations) {
                  copyCategoryProps(label, labelCat)
                  labelData.push(label)
                }
              })
            }
            break
          case labelCategories.targets:
            if (!hasLabelCategory(labelCategories.targets, currentContentRef.current.chartSettings.labels)) {
              const targetData = []
              getTargets2D(
                wellData.targets,
                targetData,
                currentContentRef.current.chartSettings.chartType,
                wellData?.actualWellData?.verticalSection,
                true,
              )
              targetData.forEach((target) => {
                labelData.push({
                  catType: labelCategories.targets,
                  color: labelCat.color,
                  size: labelCat.size,
                  bold: labelCat.bold,
                  hasTether: labelCat.hasTether,
                  id: `${wellData.actualWellData.actualWellName}|${target.text}`,
                  uid: `${wellData.actualWellData.actualWellName}|${target.text}`,
                  style: {
                    borderColor: '#AAA',
                    font: {
                      size: labelCat.size,
                      weight: labelCat.bold ? 'bold' : 'normal',
                    },
                  },
                  position: {
                    x: 'end',
                    y: 'center',
                  },
                  type: 'label',
                  drawTime: 'afterDatasetsDraw',
                  callout: {
                    display: labelCat.hasTether,
                    borderColor: '#AAA',
                  },
                  content: `${target.text}`,
                  font: {
                    size: labelCat.size,
                    weight: labelCat.bold ? 'bold' : 'normal',
                  },
                  xValue: target.x,
                  yValue: target.y,
                  xAdjust: 0,
                  yAdjust: 0,
                })
              })
            }
            if (hasLabelCategory(labelCategories.targets, currentContentRef.current.chartSettings.labels)) {
              currentContentRef.current.chartSettings.labels.labelData.forEach((label) => {
                if (label.catType === labelCategories.targets) {
                  copyCategoryProps(label, labelCat)
                  labelData.push(label)
                }
              })
            }
            break
          case labelCategories.lithologies:
            if (!hasLabelCategory(labelCategories.lithologies, currentContentRef.current.chartSettings.labels)) {
              wellData.actualWellData.lithologies.forEach((lith) => {
                const labelPos = { x: currentContentRef.current.chartSettings?.xMin || 0, y: lith.tvd }
                labelData.push({
                  catType: labelCategories.lithologies,
                  color: labelCat.color,
                  size: labelCat.size,
                  bold: labelCat.bold,
                  hasTether: labelCat.hasTether,
                  id: `${wellData.actualWellData.actualWellName}-${lith.tvd}`,
                  uid: `${wellData.actualWellData.actualWellName}-${lith.tvd}`,
                  style: {
                    borderColor: '#AAA',
                    font: {
                      size: labelCat.size,
                      weight: labelCat.bold ? 'bold' : 'normal',
                    },
                  },
                  position: {
                    x: 'start',
                    y: 'end',
                  },
                  type: 'label',
                  drawTime: 'afterDatasetsDraw',
                  callout: {
                    display: labelCat.hasTether,
                    borderColor: '#AAA',
                  },
                  content: `${lith.formation}`,
                  font: {
                    size: labelCat.size,
                    weight: labelCat.bold ? 'bold' : 'normal',
                  },
                  xValue: labelPos.x,
                  yValue: labelPos.y,
                  xAdjust: 10,
                  yAdjust: 0,
                })
              })
            }
            // if labels for this categoryh exist, walk through and update them
            if (hasLabelCategory(labelCategories.lithologies, currentContentRef.current.chartSettings.labels)) {
              currentContentRef.current.chartSettings.labels.labelData.forEach((label) => {
                if (label.catType === labelCategories.lithologies) {
                  copyCategoryProps(label, labelCat)
                  labelData.push(label)
                }
              })
            }
            break
          case labelCategories.offsetWellNames:
            if (!hasLabelCategory(labelCategories.offsetWellNames, currentContentRef.current.chartSettings.labels)) {
              wellData.offsetWells?.forEach((offsetWell) => {
                if (offsetWell.offsetWellbore !== wellData.actualWellData.actualWellName)
                  labelData.push({
                    catType: labelCategories.offsetWellNames,
                    color: labelCat.color,
                    size: labelCat.size,
                    bold: labelCat.bold,
                    hasTether: labelCat.hasTether,
                    id: offsetWell.offsetWellbore,
                    uid: offsetWell.offsetWellbore,
                    style: {
                      borderColor: '#AAA',
                      font: {
                        size: labelCat.size,
                        weight: labelCat.bold ? 'bold' : 'normal',
                      },
                    },
                    position: {
                      x: 'end',
                      y: 'center',
                    },
                    type: 'label',
                    drawTime: 'afterDatasetsDraw',
                    callout: {
                      display: labelCat.hasTether,
                      borderColor: '#AAA',
                    },
                    content: offsetWell.offsetWellbore,
                    font: {
                      size: labelCat.size,
                      weight: labelCat.bold ? 'bold' : 'normal',
                    },
                    xValue:
                      currentContentRef.current.chartSettings.chartType === 'plan'
                        ? offsetWell.surveyData[offsetWell.surveyData.length - 1].ew
                        : offsetWell.surveyData[offsetWell.surveyData.length - 1].vs,
                    yValue:
                      currentContentRef.current.chartSettings.chartType === 'plan'
                        ? offsetWell.surveyData[offsetWell.surveyData.length - 1].ns
                        : offsetWell.surveyData[offsetWell.surveyData.length - 1].tvd,
                    xAdjust: 0,
                    yAdjust: -100,
                  })
              })
            }
            // if labels for this category exist, walk through and update them
            if (hasLabelCategory(labelCategories.offsetWellNames, currentContentRef.current.chartSettings.labels)) {
              currentContentRef.current.chartSettings.labels.labelData.forEach((label) => {
                if (label.catType === labelCategories.offsetWellNames) {
                  copyCategoryProps(label, labelCat)
                  labelData.push(label)
                }
              })
            }
            break
          case labelCategories.offsetSurveyPts:
            if (!hasLabelCategory(labelCategories.offsetSurveyPts, currentContentRef.current.chartSettings.labels)) {
              // object format the api will store (in dev, not complete):
              wellData.offsetWells?.forEach((offsetWell) => {
                if (offsetWell.offsetWellbore !== wellData.actualWellData.actualWellName)
                  offsetWell.surveyData.forEach((survey, i) => {
                    labelData.push({
                      catType: labelCategories.offsetSurveyPts,
                      color: labelCat.color,
                      size: labelCat.size,
                      bold: labelCat.bold,
                      hasTether: labelCat.hasTether,
                      id: `${offsetWell.offsetWellbore}-${survey.md}`,
                      uid: `${offsetWell.offsetWellbore}-${survey.md}`,
                      style: {
                        borderColor: '#AAA',
                        font: {
                          size: labelCat.size,
                          weight: labelCat.bold ? 'bold' : 'normal',
                        },
                      },
                      position: {
                        x: 'end',
                        y: 'center',
                      },
                      type: 'label',
                      drawTime: 'afterDatasetsDraw',
                      callout: {
                        display: labelCat.hasTether,
                        borderColor: '#AAA',
                      },
                      content: `MD:${survey.md}, INC:${survey.inc}, AZI:${survey.azi}`,
                      font: {
                        size: labelCat.size,
                        weight: labelCat.bold ? 'bold' : 'normal',
                      },
                      xValue: currentContentRef.current.chartSettings.chartType === 'plan' ? survey.ew : survey.vs,
                      yValue: currentContentRef.current.chartSettings.chartType === 'plan' ? survey.ns : survey.tvd,
                      xAdjust: -100,
                      yAdjust: 0,
                    })
                  })
              })
            }
            // if labels for this categoryh exist, walk through and update them
            if (hasLabelCategory(labelCategories.offsetSurveyPts, currentContentRef.current.chartSettings.labels)) {
              currentContentRef.current.chartSettings.labels.labelData.forEach((label) => {
                if (label.catType === labelCategories.offsetSurveyPts) {
                  copyCategoryProps(label, labelCat)
                  labelData.push(label)
                }
              })
            }
            break
          default:
            break
        }
      })

      newContent.chartSettings.labels.labelData = labelData // ...plus any new ones due to the current modal change
    }
    currentContentRef.current = newContent

    updateContainer({ ...newContent.style })
    actions.update(id, 'update', newContent)
    setShowProperties(false)
    setRerender((prev) => !prev)
  }

  const handleChartUpdate = (changeData) => {
    let newContent = {
      style: { ...currentContentRef.current.style },
      chartSettings: { ...currentContentRef.current.chartSettings, ...changeData },
    }
    actions.update(id, 'update', newContent)
    currentContentRef.current = newContent
    setRerender((prev) => !prev)
  }

  const handleChartUpdateNoRender = (changeData) => {
    let newContent = {
      style: { ...currentContentRef.current.style },
      chartSettings: { ...currentContentRef.current.chartSettings, ...cloneDeep(changeData) },
    }
    currentContentRef.current = newContent // significant functionality change!!!
    actions.update(id, 'updateSettings', newContent)
  }

  const getChartSettings = () => {
    let chartSettings = {
      ...(wallPlotChartRef.current?.getSettings() || {}),
      ...currentContentRef.current.chartSettings,
    }

    if (!wellData) return chartSettings

    // if there are no settings predefined, add the main well (0th offset well)
    if (!chartSettings.hasOwnProperty('series') || !chartSettings.series) {
      chartSettings.series = [
        {
          wellName: wellData.actualWellData.actualWellName,
          color:
            wellData.actualWellData.isPlan === false ? appColors.surveyTrajectoryColor : appColors.planTrajectoryColor,
          lineStyle: 'solid',
          lineThickness: 2,
          type: wellData.actualWellData.isPlan === false ? 'Actual' : 'Plan',
        },
      ]

      // add the offset wells
      if (wellData.offsetWells?.length > 2) {
        for (let i = 1; i < wellData.offsetWells.length; i++) {
          chartSettings.series.push({
            wellName: wellData.offsetWells[i].offsetWellbore,
            color:
              wellData.offsetWells[i].wellStatus === 'Actual'
                ? appColors.surveyTrajectoryColor
                : appColors.planTrajectoryColor,
            lineStyle: 'solid',
            lineThickness: 1,
            type: wellData.offsetWells[i].wellStatus,
          })
        }
      }
    }

    return cloneDeep(chartSettings)
  }

  return (
    <>
      {showProperties && (
        <ElementPropertyModal
          onClose={() => setShowProperties(false)}
          elementStyle={currentContentRef.current.style}
          contextData={getChartSettings()}
          onApply={handleModalChange}
          elemType={elementType.chart}
          elemSubType={element.chartSettings.chartType}
        />
      )}
      <div
        style={{
          display: 'flex',
          height: '100%',
          width: '100%',
          position: 'absolute',
        }}
        onDoubleClick={(e) => {
          e.stopPropagation()
          setShowProperties(true)
        }}>
        <Box
          style={{
            display: 'flex',
            height: '100%',
            width: '100%',
            color: currentContentRef.current.style.color,
            padding: '0 8px',
            opacity: 1,
            cursor: currentContentRef.current.style.cursor,
          }}>
          <Box sx={{ width: '100%', height: '100%' }}>
            <WallPlotChart
              ref={wallPlotChartRef}
              data={wellData}
              refWellSurveys={wellData?.surveys}
              isPlan={false}
              chartType={element.chartSettings.chartType}
              onUpdate={handleChartUpdate}
              onUpdateNoRender={handleChartUpdateNoRender}
              chartSettings={getChartSettings()}
            />
          </Box>
        </Box>
      </div>
    </>
  )
})

export default ChartElement
