import React, { useMemo, useRef, useCallback } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { Box, Tooltip, IconButton } from '@mui/material'
import { uuidv4 } from 'utils/stringFunctions'
import { Icon as Iconify } from '@iconify/react'
import { getStringId } from 'components/common/AgGridUtils'
import { cloneDeep } from 'lodash'
import useUnits, { UNITS_FOR } from 'components/common/hooks/useUnits'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const JetsEditor = (props) => {
  const gridApi = useRef(null)
  const { getAgGridTheme } = useInnovaTheme()
  const inputRow = useRef({})
  const { getUnitConversion } = useUnits()

  const parseJetsData = (jets) => {
    if (!jets) return []
    if (!Array.isArray(jets)) return []
    for (let i = 0; i < jets.length; i++) {
      jets[i].uid = uuidv4()
    }

    return jets
  }

  function getValue() {
    if (!gridApi.current) return ''
    let rowData = []
    gridApi.current.forEachNodeAfterFilter((node) => {
      if (node.data) {
        if (node.data.qty && node.data.size && parseInt(node.data.qty) > 0 && parseInt(node.data.size) > 0) {
          rowData.push({ qty: node.data.qty, size: node.data.size })
        }
      }
    })

    if (rowData.length === 0) return []
    return rowData
  }

  const onFirstDataRendered = (params) => {
    if (gridApi.current) {
      gridApi.current.onFilterChanged()
    }

    autoSizeColumns()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const onGridReady = useCallback((params) => {
    gridApi.current = params.api
  }, [])

  const onGridPreDestroyed = useCallback(() => {
    props.data.bitJets = cloneDeep(getValue())
    props.onValueChange(props.data.bitJets)

    if (!Array.isArray(props.data.bitJets)) props.data.bitJets = []

    if (props?.data?.bitJets.length > 0) {
      let tfa = 0
      for (let i = 0; i < props.data?.bitJets.length; i++) {
        let sizeSquared = props.data?.bitJets[i].size * props.data?.bitJets[i].size
        let flowArea = sizeSquared * props.data?.bitJets[i].qty
        tfa += flowArea
      }

      tfa /= 1303.8
      props.data.tfa = tfa * getUnitConversion(UNITS_FOR.Area, true)
    }
  }, [props, getUnitConversion])

  const centerAlignCell = useMemo(
    () => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }),
    [],
  )

  const isPinnedRowDataCompleted = () => {
    return inputRow.current.hasOwnProperty('qty') && inputRow.current.hasOwnProperty('size')
  }

  const handleAddRow = (params) => {
    if (!isPinnedRowDataCompleted()) return

    params.api.applyTransaction({
      add: [inputRow.current],
    })

    inputRow.current = {}
    if (gridApi.current) gridApi.current.setGridOption('pinnedBottomRowData', [inputRow.current])
  }

  const handleDelete = (params) => {
    params.api.applyTransaction({
      remove: [params.data],
    })
  }

  function createPinnedCellPlaceholder({ colDef }) {
    return colDef.field[0].toUpperCase() + colDef.field.slice(1) + '...'
  }

  function isEmptyPinnedCell({ node, value }) {
    return (
      (node?.rowPinned === 'bottom' && value == null) ||
      (node?.rowPinned === 'bottom' && value === '') ||
      (node?.rowPinned === 'bottom' && value === undefined)
    )
  }

  const defaultColDef = useMemo(() => {
    return {
      suppressKeyboardEvent: (params) => {
        if (!params.editing) {
          let isBackspaceKey = params.event.keyCode === 8
          let isDeleteKey = params.event.keyCode === 46

          if (isBackspaceKey || isDeleteKey) {
            const focusedCell = params.api.getFocusedCell()
            if (!focusedCell?.column?.colDef.editable) return false
            params.data[focusedCell?.column?.colDef?.field] = ''

            params.api.applyTransaction({
              update: [params.data],
            })
          }
        }
        return false
      },
      resizable: true,
      sortable: false,
      editable: true,
      autoHeight: true,
      suppressHeaderMenuButton: true,
      suppressHeaderFilterButton: true,
      suppressMovable: true,
      headerClass: 'header-no-padding',
      filter: null,
      cellStyle: centerAlignCell,
      valueFormatter: (params) => (isEmptyPinnedCell(params) ? createPinnedCellPlaceholder(params) : undefined),
    }
  }, [centerAlignCell])

  const actionIconRenderer = (params) => {
    return (
      <React.Fragment>
        <Box style={{ display: 'flex', flexDirection: 'row' }}>
          <Tooltip
            title={params.node?.rowPinned !== 'bottom' ? 'Delete' : 'Add'}
            placement='left'
            componentsProps={{
              tooltip: {
                sx: {
                  backgroundColor: 'rgb(19,62,96)',
                  fontSize: '12px',
                  fontFamily: 'Roboto',
                },
              },
            }}>
            <IconButton
              style={{ padding: '5px', alignItems: 'center', justifyContent: 'center' }}
              onClick={() => (params.node?.rowPinned !== 'bottom' ? handleDelete(params) : handleAddRow())}
              size='large'>
              {params.node?.rowPinned !== 'bottom' ? (
                <Iconify color='red' icon='fa-regular:trash-alt' fontSize={16} />
              ) : (
                <Iconify color='green' icon='fluent:add-12-filled' fontSize={16} />
              )}
            </IconButton>
          </Tooltip>
          {params.node?.rowPinned !== 'bottom' ? (
            <Box style={{ textAlign: 'right', paddingLeft: '8px' }}>{params.node.rowIndex + 1}</Box>
          ) : null}
        </Box>
      </React.Fragment>
    )
  }

  let columnDefs = [
    {
      field: 'actions',
      colId: 'actions',
      width: 86,
      headerName: '',
      editable: false,
      filter: null,
      sortable: false,
      suppressHeaderMenuButton: true,
      suppressHeaderFilterButton: true,
      pinned: 'left',
      lockPosition: 'left',
      cellRenderer: actionIconRenderer,
    },
    {
      field: 'qty',
      colId: 'qty',
      headerName: 'Qty',
      maxWidth: 100,
      cellEditor: 'agNumberCellEditor',
      cellEditorParams: {
        min: 1,
        max: 20,
        precision: 0,
      },
      valueFormatter: (params) => {
        if (isEmptyPinnedCell(params)) return 'Qty...'
        return params?.value
      },
    },
    {
      field: 'size',
      colId: 'size',
      headerName: 'Size',
      maxWidth: 100,
      cellEditor: 'agNumberCellEditor',
      cellEditorParams: {
        min: 1,
        max: 32,
        precision: 0,
      },
      valueFormatter: (params) => {
        if (isEmptyPinnedCell(params)) return 'Size...'
        return params?.value
      },
    },
  ]

  const gridOptions = {
    rowHeight: 30,
    pinnedBottomRowData: [inputRow.current],
    onCellEditingStopped: (params) => {
      if (!params?.valueChanged) return
      if (params?.node?.rowPinned === 'bottom') {
        if (gridApi.current) gridApi.current.setGridOption('pinnedBottomRowData', [inputRow.current])
        handleAddRow(params)
        return
      }
    },
    suppressRowClickSelection: true,
    getRowStyle: ({ node }) => (node?.rowPinned ? { fontWeight: 'bold', fontStyle: 'italic' } : 0),
  }

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.uid)
    }
  }, [])

  return (
    <Box sx={{ height: '150px', width: '15vw', maxWidth: '600px', maxHeight: '190px' }}>
      <div className={getAgGridTheme()} style={{ width: '100%', height: '100%' }}>
        <AgGridReact
          rowData={parseJetsData(props?.value)}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          animateRows={false}
          enableBrowserTooltips={true}
          gridOptions={gridOptions}
          headerHeight={30}
          onGridReady={onGridReady}
          onFirstDataRendered={onFirstDataRendered}
          getContextMenuItems={(params) => []}
          getRowId={getRowId}
          onGridPreDestroyed={onGridPreDestroyed}
        />
      </div>
    </Box>
  )
}

export default JetsEditor
