import { useEffect, useRef, useState } from 'react'
import useInnovaAxios from './useInnovaAxios'
import { useRecoilValue } from 'recoil'
import { currentWellAtom } from 'atoms'
import { getDeltaIsoDays } from 'utils'
import { htmlSymbolHandling } from 'components/common/AgGridUtils'

function useMotorReport(wellName) {
  const _isMounted = useRef(true)
  const motorPerformanceData = useRef([])
  const isLoading = useRef(false)
  const isUpdating = useRef(false)
  const [loading, setLoading] = useState(false)
  const currentWell = useRecoilValue(currentWellAtom)
  const currentWellRef = useRef(wellName ? wellName : currentWell)

  const getMotorRptData = useInnovaAxios({
    url: '/well/motorReport',
  })

  const updateMotorRptData = useInnovaAxios({
    url: '/well/updateMotorReport',
  })

  useEffect(() => {
    _isMounted.current = true
    return () => {
      _isMounted.current = false
    }
  }, [])

  useEffect(() => {
    currentWellRef.current = wellName ? wellName : currentWell
    fetchMotorReport()
  }, [currentWell, wellName]) // eslint-disable-line react-hooks/exhaustive-deps

  const fetchMotorReport = async () => {
    if (typeof currentWellRef.current !== 'string') return
    if (currentWellRef.current === '') return
    if (isLoading.current) return
    isLoading.current = true
    if (_isMounted.current) setLoading(true)

    const response = await getMotorRptData({ wellName: currentWellRef.current })

    if (_isMounted.current) {
      setLoading(false)
      motorPerformanceData.current = response?.data ? response.data : null
    }

    isLoading.current = false
  }

  const extractTimeString = (dateIsoStr) => {
    const date = new Date(dateIsoStr)
    if (isNaN(date)) return ''

    const hours = date.getHours().toString().padStart(2, '0')
    const minutes = date.getMinutes().toString().padStart(2, '0')
    return `${hours}:${minutes}`
  }

  const getRunSummary = (item) => {
    if (!item || typeof item !== 'object') return []

    let reasonPoohList = [
      'MWD Failure',
      'Motor Failure',
      'BHA Twist Off',
      'BHA Back Off',
      'Section TD',
      'Well TD',
      'ROP',
      'Change BHA',
      'Low build rates',
      'Sidetrack',
      'Hole problems',
      'Well control',
      'Drill Pipe Twist Off',
      'Drill Pipe Back Off',
      'Rig Failure',
      'Coring Point',
      'BOP Test',
      'BOP Failure',
      'Wash Out',
      'Run Logs',
      'Plugged String',
      'Stuck Pipe',
      'RSS Failure',
      'Bit Trip',
      'Personnel Error',
    ]

    return [
      {
        tag: 'dateIn',
        label: 'Date In',
        value: item?.dateTimeInIso,
        type: 'date',
        editable: false,
      },
      {
        tag: 'dateOut',
        label: 'Date Out',
        value: item?.dateTimeOutIso,
        type: 'date',
        editable: false,
      },
      {
        tag: 'timeIn',
        label: 'Time In',
        value: extractTimeString(item?.dateTimeInIso),
        type: 'time',
        payloadTag: 'TimeIn',
      },
      {
        tag: 'timeOut',
        label: 'Time Out',
        value: extractTimeString(item?.dateTimeOutIso),
        type: 'time',
        payloadTag: 'TimeOut',
      },
      {
        tag: 'deltaHrs',
        label: 'BRT Hrs',
        value: getDeltaIsoDays(item?.dateTimeInIso, item?.dateTimeOutIso) * 24,
        type: 'float',
        editable: false,
      },
      {
        tag: 'drillingHours',
        label: 'Drlg Hrs',
        value: item?.footageAndHours?.drillingHours,
        type: 'float',
        editable: false,
      },
      {
        tag: 'rotateHours',
        label: 'Rotate Hrs',
        value: item?.footageAndHours?.rotateHours,
        type: 'float',
        editable: false,
      },
      {
        tag: 'slideHours',
        label: 'Slide Hrs',
        value: item?.footageAndHours.slideHours,
        type: 'float',
        payloadTag: 'SlideHours',
      },
      {
        tag: 'depthIn',
        label: 'Depth In',
        value: item?.depthIn,
        type: 'float',
        maxVal: 100000,
        minVal: 0,
        precision: 2,
        editable: false,
      },
      {
        tag: 'depthOut',
        label: 'Depth Out',
        value: item?.depthOut,
        type: 'float',
        maxVal: 100000,
        minVal: 0,
        precision: 2,
        editable: false,
      },
      {
        tag: 'totalDrilled',
        label: 'Total Drill',
        value: item?.footageAndHours?.totalDrilled,
        type: 'float',
        maxVal: 100000,
        minVal: 0,
        precision: 2,
        editable: false,
      },
      {
        tag: 'rotateDrilled',
        label: 'Rotate Drill',
        value: item?.footageAndHours?.rotateDrilled,
        type: 'float',
        maxVal: 100000,
        minVal: 0,
        precision: 2,
        editable: false,
      },
      {
        tag: 'slideDrilled',
        label: 'Slide Drill',
        value: item?.footageAndHours?.slideDrilled,
        type: 'float',
        payloadTag: 'SlideDrilled',
        maxVal: 100000,
        minVal: 0,
        precision: 2,
      },
      {
        tag: 'avgRop',
        label: 'Avg ROP',
        value: item?.footageAndHours?.avgRop,
        type: 'float',
        maxVal: 100000,
        minVal: 0,
        precision: 2,
        editable: false,
      },
      {
        tag: 'rotateRop',
        label: 'Rotate ROP',
        value: item?.footageAndHours?.rotateRop,
        type: 'float',
        maxVal: 100000,
        minVal: 0,
        precision: 2,
        editable: false,
      },
      {
        tag: 'slideRop',
        label: 'Slide ROP',
        value: item?.footageAndHours?.slideRop,
        type: 'float',
        maxVal: 100000,
        minVal: 0,
        precision: 2,
        editable: false,
      },
      {
        tag: 'plannedDls',
        label: 'Plan DLS',
        value: item?.plannedDls,
        type: 'float',
        payloadTag: 'PlannedDls',
        maxVal: 200,
        minVal: 0,
        precision: 3,
      },
      {
        tag: 'maxDls',
        label: 'Max DLS',
        value: item?.maxDls,
        type: 'float',
        payloadTag: 'MaxDls',
        maxVal: 200,
        minVal: 0,
        precision: 3,
      },
      {
        tag: 'reasonPooh',
        label: 'POOH',
        value: item?.reasonPooh,
        type: 'dropDown',
        dropDownValues: reasonPoohList,
        payloadTag: 'ReasonPooh',
      },
    ]
  }

  const getDrillingParams = (item) => {
    if (!item || typeof item !== 'object') return []
    return [
      {
        tag: 'wob',
        label: 'WOB',
        dropDownFilter: '',
        value: item?.drillingParameters?.wob,
        payloadTag: 'Wob',
        type: 'float',
        maxVal: 10000000,
        minVal: 0,
        precision: 2,
      },
      {
        tag: 'wobSlide',
        label: 'WOB Slide',
        dropDownFilter: '',
        value: item?.drillingParameters?.wobSlide,
        payloadTag: 'WobSlide',
        type: 'float',
        maxVal: 10000000,
        minVal: 0,
        precision: 2,
      },
      {
        tag: 'onBtmTq',
        label: 'On Btm Tq',
        dropDownFilter: '',
        value: item?.drillingParameters?.onBtmTq,
        payloadTag: 'OnBtmTq',
        type: 'float',
        maxVal: 10000000,
        minVal: 0,
        precision: 2,
      },
      {
        tag: 'offBtmTq',
        label: 'Off Btm Tq',
        dropDownFilter: '',
        value: item?.drillingParameters?.offBtmTq,
        payloadTag: 'OffBtmTq',
        type: 'float',
        maxVal: 10000000,
        minVal: 0,
        precision: 2,
      },
      { tag: 'rpm', label: 'RPM', dropDownFilter: '', value: item?.drillingParameters?.rpm, payloadTag: 'Rpm' },
      {
        tag: 'slackOffWeight',
        label: 'SO Weight',
        dropDownFilter: '',
        value: item?.drillingParameters?.slackOffWeight,
        payloadTag: 'SlackOffWeight',
        type: 'float',
        maxVal: 10000000,
        minVal: -1000000,
        precision: 2,
      },
      {
        tag: 'onBtmPress',
        label: 'On Btm Press',
        dropDownFilter: '',
        value: item?.drillingParameters?.onBtmPress,
        payloadTag: 'OnBtmPress',
        type: 'float',
        maxVal: 100000,
        minVal: 0,
        precision: 2,
      },
      {
        tag: 'offBtmPress',
        label: 'Off Btm Press',
        dropDownFilter: '',
        value: item?.drillingParameters?.offBtmPress,
        payloadTag: 'OffBtmPress',
        type: 'float',
        maxVal: 100000,
        minVal: 0,
        precision: 2,
      },
      {
        tag: 'pressureDiff',
        label: 'Pressure Diff',
        dropDownFilter: '',
        value: item?.drillingParameters?.onBtmPress - item.drillingParameters?.offBtmPress,
        type: 'float',
        maxVal: 10000,
        minVal: 0,
        precision: 2,
      },
      {
        tag: 'bhtMin',
        label: 'BHT Min',
        dropDownFilter: '',
        value: item?.drillingParameters?.bhtMin,
        payloadTag: 'BhtMin',
        type: 'float',
        maxVal: 500,
        minVal: 0,
        precision: 2,
      },
      {
        tag: 'bhtMax',
        label: 'BHT Max',
        dropDownFilter: '',
        value: item?.drillingParameters?.bhtMax,
        payloadTag: 'BhtMax',
        type: 'float',
        maxVal: 500,
        minVal: 0,
        precision: 2,
      },
      {
        tag: 'bhtAvg',
        label: 'BHT Avg',
        dropDownFilter: '',
        value: item?.drillingParameters?.bhtAvg,
        payloadTag: 'BhtAvg',
        type: 'float',
        maxVal: 500,
        minVal: 0,
        precision: 2,
      },
    ]
  }

  const getMudProperties = (item) => {
    if (!item || typeof item !== 'object') return []
    return [
      {
        tag: 'description',
        label: 'Type',
        dropDownFilter: '',
        value: item?.mudProperties?.description,
        payloadTag: 'Description',
        type: 'string',
      },
      {
        tag: 'mudWeight',
        label: 'Weight',
        dropDownFilter: '',
        value: item?.mudProperties?.mudWeight,
        payloadTag: 'MudWeight',
        type: 'float',
        maxVal: 50,
        minVal: 0,
        precision: 2,
      },
      {
        tag: 'pv',
        label: 'PV',
        dropDownFilter: '',
        value: item?.mudProperties?.pv,
        payloadTag: 'Pv',
        type: 'float',
        maxVal: 500,
        minVal: 0,
        precision: 0,
      },
      {
        tag: 'yp',
        label: 'YP',
        dropDownFilter: '',
        value: item?.mudProperties?.yp,
        payloadTag: 'Yp',
        type: 'float',
        maxVal: 500,
        minVal: 0,
        precision: 0,
      },
      {
        tag: 'sand',
        label: 'Sand',
        dropDownFilter: '',
        value: item?.mudProperties?.sand,
        payloadTag: 'Sand',
        type: 'float',
        maxVal: 100,
        minVal: 0,
        precision: 4,
      },
      {
        tag: 'solids',
        label: 'Solids',
        dropDownFilter: '',
        value: item?.mudProperties?.solids,
        payloadTag: 'Solids',
        type: 'float',
        maxVal: 500,
        minVal: 0,
        precision: 2,
      },
      {
        tag: 'chlorides',
        label: 'Chlorides',
        dropDownFilter: '',
        value: item?.mudProperties?.chlorides,
        payloadTag: 'Chlorides',
        type: 'float',
        maxVal: 1000000,
        minVal: 0,
        precision: 2,
      },
      {
        tag: 'fluidTemperature',
        label: 'Temp',
        dropDownFilter: '',
        value: item?.mudProperties?.fluidTemperature,
        payloadTag: 'Temperature',
        type: 'float',
        maxVal: 500,
        minVal: 0,
        precision: 2,
      },
      {
        tag: 'sixHundred',
        label: '600',
        dropDownFilter: '',
        value: item?.mudProperties?.dialReadings?.sixHundred,
        payloadTag: 'SixHundred',
        type: 'float',
        maxVal: 500,
        minVal: 0,
        precision: 0,
      },
      {
        tag: 'threeHundred',
        label: '300',
        dropDownFilter: '',
        value: item?.mudProperties?.dialReadings?.threeHundred,
        payloadTag: 'ThreeHundred',
        type: 'float',
        maxVal: 500,
        minVal: 0,
        precision: 0,
      },
      {
        tag: 'twoHundred',
        label: '200',
        dropDownFilter: '',
        value: item?.mudProperties?.dialReadings?.twoHundred,
        payloadTag: 'TwoHundred',
        type: 'float',
        maxVal: 500,
        minVal: 0,
        precision: 0,
      },
      {
        tag: 'onehundred',
        label: '100',
        dropDownFilter: '',
        value: item?.mudProperties?.dialReadings?.onehundred,
        payloadTag: 'OneHundred',
        type: 'float',
        maxVal: 500,
        minVal: 0,
        precision: 0,
      },
      {
        tag: 'six',
        label: '6',
        dropDownFilter: '',
        value: item?.mudProperties?.dialReadings?.six,
        payloadTag: 'Six',
        type: 'float',
        maxVal: 500,
        minVal: 0,
        precision: 0,
      },
      {
        tag: 'three',
        label: '3',
        dropDownFilter: '',
        value: item?.mudProperties?.dialReadings?.three,
        payloadTag: 'Three',
        type: 'float',
        maxVal: 500,
        minVal: 0,
        precision: 0,
      },
      {
        tag: 'oilwaterRatio',
        label: 'Oil/Water',
        dropDownFilter: '',
        value: item?.mudProperties?.oilwaterRatio,
        payloadTag: 'OilWaterRatio',
        type: 'string',
      },
    ]
  }

  const getPersonnelData = (item) => {
    if (!item || typeof item !== 'object') return []
    return [
      {
        tag: 'leadDD',
        label: 'Lead DD',
        dropDownFilter: '',
        value: item?.leadDD,
        payloadTag: 'LeadDD',
        type: 'string',
      },
      {
        tag: 'companyMan',
        label: 'Co. Man',
        dropDownFilter: '',
        value: item?.companyMan,
        payloadTag: 'CompanyMan',
        type: 'string',
      },
      {
        tag: 'dayMwd',
        label: 'Day MWD',
        dropDownFilter: '',
        value: item?.MwdData?.dayMwd,
        payloadTag: 'DayMwd',
        type: 'string',
      },
    ]
  }

  const getMwdData = (item) => {
    if (!item || typeof item !== 'object') return []
    return [
      {
        tag: 'rigFloorDisplaySn',
        label: 'RFD',
        dropDownFilter: '',
        value: item?.MwdData?.rigFloorDisplaySn,
        payloadTag: 'RigFloorDisplaySn',
        type: 'string',
      },
      {
        tag: 'barrierBoxSn',
        label: 'Barrier Box',
        dropDownFilter: '',
        value: item?.MwdData?.barrierBoxSn,
        payloadTag: 'BarrierBoxSn',
        type: 'string',
      },
      {
        tag: 'aggregatorSn',
        label: 'Aggregator',
        dropDownFilter: '',
        value: item?.MwdData?.aggregatorSn,
        payloadTag: 'AggregatorSn',
        type: 'string',
      },
      {
        tag: 'haspSn',
        label: 'HASP',
        dropDownFilter: '',
        value: item?.MwdData?.haspSn,
        payloadTag: 'HaspSn',
        type: 'string',
      },
      {
        tag: 'edrType',
        label: 'EDR',
        dropDownFilter: '',
        value: item?.MwdData?.edrType,
        payloadTag: 'EdrType',
        type: 'string',
      },
      {
        tag: 'recieverSn',
        label: 'Receiver',
        dropDownFilter: '',
        value: item?.MwdData?.recieverSn,
        payloadTag: 'RecieverSn',
        type: 'string',
      },
      {
        tag: 'laptop1Sn',
        label: 'Laptop 1',
        dropDownFilter: '',
        value: item?.MwdData?.laptop1Sn,
        payloadTag: 'Laptop1Sn',
        type: 'string',
      },
      {
        tag: 'laptop2Sn',
        label: 'Laptop 2',
        dropDownFilter: '',
        value: item?.MwdData?.laptop2Sn,
        payloadTag: 'Laptop2Sn',
        type: 'string',
      },
      {
        tag: 'laptop3Sn',
        label: 'Laptop 3',
        dropDownFilter: '',
        value: item?.MwdData?.laptop3Sn,
        payloadTag: 'Laptop3Sn',
        type: 'string',
      },
      {
        tag: 'laptop4Sn',
        label: 'Laptop 4',
        dropDownFilter: '',
        value: item?.MwdData?.laptop4Sn,
        payloadTag: 'Laptop4Sn',
        type: 'string',
      },
      {
        tag: 'pressureTansducerSn',
        label: 'Transducer',
        dropDownFilter: '',
        value: item?.MwdData?.pressureTansducerSn,
        payloadTag: 'PressureTansducerSn',
        type: 'string',
      },
      {
        tag: 'depthTrackingSn',
        label: 'Depth Track',
        dropDownFilter: '',
        value: item?.MwdData?.depthTrackingSn,
        payloadTag: 'DepthTrackingSn',
        type: 'string',
      },
      {
        tag: 'mwdKitSn',
        label: 'MWD Kit',
        dropDownFilter: '',
        value: item?.MwdData?.mwdKitSn,
        payloadTag: 'MwdKitSn',
        type: 'string',
      },
      {
        tag: 'gammaKitSn',
        label: 'Gamma Kit',
        dropDownFilter: '',
        value: item?.MwdData?.gammaKitSn,
        payloadTag: 'GammaKitSn',
        type: 'string',
      },
      {
        tag: 'prePulse',
        label: 'Pre Pulse',
        dropDownFilter: '',
        value: item?.MwdData?.prePulse,
        payloadTag: 'PrePulse',
        type: 'dropDown',
        dropDownValues: ['PASS', 'FAIL'],
      },
      {
        tag: 'postPulse',
        label: 'Post Pulse',
        dropDownFilter: '',
        value: item?.MwdData?.postPulse,
        payloadTag: 'PostPulse',
        type: 'dropDown',
        dropDownValues: ['PASS', 'FAIL'],
      },
      {
        tag: 'preComms',
        label: 'Pre Comms',
        dropDownFilter: '',
        value: item?.MwdData?.preComms,
        payloadTag: 'PreComms',
        type: 'dropDown',
        dropDownValues: ['PASS', 'FAIL'],
      },
      {
        tag: 'postComms',
        label: 'Post Comms',
        dropDownFilter: '',
        value: item?.MwdData?.postComms,
        payloadTag: 'PostComms',
        type: 'dropDown',
        dropDownValues: ['PASS', 'FAIL'],
      },
      {
        tag: 'preFlow',
        label: 'Pre Flow',
        dropDownFilter: '',
        value: item?.MwdData?.preFlow,
        payloadTag: 'PreFlow',
        type: 'dropDown',
        dropDownValues: ['PASS', 'FAIL'],
      },
      {
        tag: 'postFlow',
        label: 'Post Flow',
        dropDownFilter: '',
        value: item?.MwdData?.postFlow,
        payloadTag: 'PostFlow',
        type: 'dropDown',
        dropDownValues: ['PASS', 'FAIL'],
      },
      {
        tag: 'preTap',
        label: 'Pre Tap',
        dropDownFilter: '',
        value: item?.MwdData?.preTap,
        payloadTag: 'PreTap',
        type: 'dropDown',
        dropDownValues: ['PASS', 'FAIL'],
      },
      {
        tag: 'postTap',
        label: 'Post Tap',
        dropDownFilter: '',
        value: item?.MwdData?.postTap,
        payloadTag: 'PostTap',
        type: 'dropDown',
        dropDownValues: ['PASS', 'FAIL'],
      },
      {
        tag: 'detection',
        label: 'Detection',
        dropDownFilter: '',
        value: item?.MwdData?.detection,
        payloadTag: 'Detection',
        type: 'dropDown',
        dropDownValues: ['PASS', 'FAIL'],
      },
      {
        tag: 'mwdOutOfSpec',
        label: 'OOS',
        dropDownFilter: '',
        value: item?.MwdData?.mwdOutOfSpec,
        payloadTag: 'MwdOutOfSpec',
        type: 'boolean',
      },
      {
        tag: 'internalOffset',
        label: 'Int Offset',
        dropDownFilter: '',
        value: item?.MwdData?.internalOffset,
        payloadTag: 'InternalOffset',
        type: 'float',
        maxVal: 360,
        minVal: -360,
        precision: 2,
      },
      {
        tag: 'externalOffset',
        label: 'Ext Offset',
        dropDownFilter: '',
        value: item?.MwdData?.externalOffset,
        payloadTag: 'ExternalOffset',
        type: 'float',
        maxVal: 360,
        minVal: -360,
        precision: 2,
      },
      {
        tag: 'tfSequence',
        label: 'TF Seq',
        dropDownFilter: '',
        value: item?.MwdData?.tfSequence,
        payloadTag: 'TfSeq',
        type: 'string',
      },
      {
        tag: 'surveySequence',
        label: 'Svy Seq',
        dropDownFilter: '',
        value: item?.MwdData?.surveySequence,
        payloadTag: 'SurveySeq',
        type: 'string',
      },
      {
        tag: 'surfaceSystemType',
        label: 'Sur Sys',
        dropDownFilter: '',
        value: item?.MwdData?.surfaceSystemType,
        payloadTag: 'SurfaceSystemType',
        type: 'string',
      },
      {
        tag: 'backupSurfaceSystemType',
        label: 'BU Sur Sys',
        dropDownFilter: '',
        value: item?.MwdData?.backupSurfaceSystemType,
        payloadTag: 'BackupSurfaceSystemType',
        type: 'string',
      },
      {
        tag: 'surfaceSystemVersion',
        label: 'System Version',
        dropDownFilter: '',
        value: item?.MwdData?.surfaceSystemVersion,
        payloadTag: 'SurfaceSystemVersion',
        type: 'string',
      },
      {
        tag: 'hookloadSensorType',
        label: 'HLKD',
        dropDownFilter: '',
        value: item?.MwdData?.hookloadSensorType,
        payloadTag: 'HookloadSensorType',
        type: 'string',
      },
      {
        tag: 'recieverType',
        label: 'Recvr Type',
        dropDownFilter: '',
        value: item?.MwdData?.recieverType,
        payloadTag: 'RecieverType',
        type: 'string',
      },
      {
        tag: 'backupRecieverSn',
        label: 'BU Recvr',
        dropDownFilter: '',
        value: item?.MwdData?.backupRecieverSn,
        payloadTag: 'BackupRecieverSn',
        type: 'string',
      },
      {
        tag: 'transducerType',
        label: 'Trans Type',
        dropDownFilter: '',
        value: item?.MwdData?.transducerType,
        payloadTag: 'TransducerType',
        type: 'string',
      },
      {
        tag: 'nonMagAbove',
        label: 'NM Above',
        dropDownFilter: '',
        value: item?.MwdData?.nonMagAbove,
        payloadTag: 'NonMagAbove',
        type: 'float',
        maxVal: 1000,
        minVal: 0,
        precision: 2,
      },
      {
        tag: 'nonMagBelow',
        label: 'NM Below',
        dropDownFilter: '',
        value: item?.MwdData?.nonMagBelow,
        payloadTag: 'NonMagBelow',
        type: 'float',
        maxVal: 1000,
        minVal: 0,
        precision: 2,
      },
      {
        tag: 'onBtmPulseHeight',
        label: 'On Btm Pulse',
        dropDownFilter: '',
        value: item?.MwdData?.onBtmPulseHeight,
        payloadTag: 'OnBtmPulseHeight',
        type: 'float',
        maxVal: 100000,
        minVal: 0,
        precision: 2,
      },
      {
        tag: 'offBtmPulseHeight',
        label: 'Off Btm Pulse',
        dropDownFilter: '',
        value: item?.MwdData?.offBtmPulseHeight,
        payloadTag: 'OffBtmPulseHeight',
        type: 'float',
        maxVal: 100000,
        minVal: 0,
        precision: 2,
      },
      {
        tag: 'azimuthError',
        label: 'Azi Error',
        dropDownFilter: '',
        value: item?.MwdData?.azimuthError,
        payloadTag: 'AzimuthError',
        type: 'float',
        maxVal: 360,
        minVal: 0,
        precision: 2,
      },
      {
        tag: 'crossOverAngle',
        label: 'X/O Angle',
        dropDownFilter: '',
        value: item?.MwdData?.crossOverAngle,
        payloadTag: 'CrossOverAngle',
        type: 'float',
        maxVal: 180,
        minVal: 0,
        precision: 2,
      },
      {
        tag: 'mwdTempMin',
        label: 'Min Temp',
        dropDownFilter: '',
        value: item?.MwdData?.mwdTempMin,
        payloadTag: 'MwdTempMin',
        type: 'float',
        maxVal: 100000,
        minVal: 0,
        precision: 2,
      },
      {
        tag: 'mwdTempMax',
        label: 'Max Temp',
        dropDownFilter: '',
        value: item?.MwdData?.mwdTempMax,
        payloadTag: 'MwdTempMax',
        type: 'float',
        maxVal: 100000,
        minVal: 0,
        precision: 2,
      },
      {
        tag: 'mwdAuth1',
        label: 'MWD Auth 1',
        dropDownFilter: '',
        value: item?.MwdData?.mwdAuth1,
        payloadTag: 'MwdAuth1',
        type: 'string',
      },
      {
        tag: 'mwdAuth2',
        label: 'MWD Auth 2',
        dropDownFilter: '',
        value: item?.MwdData?.mwdAuth2,
        payloadTag: 'MwdAuth2',
        type: 'string',
      },
      {
        tag: 'ddAuth',
        label: 'DD Auth',
        dropDownFilter: '',
        value: item?.MwdData?.ddAuth,
        payloadTag: 'DdAuth',
        type: 'string',
      },
    ]
  }

  const getComments = (item) => {
    if (!item || typeof item !== 'object') return []

    return [
      {
        tag: 'additionalComments',
        label: 'Comments',
        dropDownFilter: '',
        value: item?.additionalComments,
        payloadTag: 'AdditionalComments',
        type: 'longstring',
      },
      {
        tag: 'failureInfo',
        label: 'Failure Info',
        value: item?.failureInfo,
        payloadTag: 'FailureInfo',
        type: 'longstring',
      },
      {
        tag: 'troubleshooting',
        label: 'Troubleshooting',
        value: item?.troubleshooting,
        payloadTag: 'Troubleshooting',
        type: 'longstring',
      },
      {
        tag: 'surfaceFindings',
        label: 'Surface Findings',
        value: item?.surfaceFindings,
        payloadTag: 'SurfaceFindings',
        type: 'longstring',
      },
      {
        tag: 'shopFindings',
        label: 'Shop Findings',
        value: item?.shopFindings ? item?.shopFindings : '',
        payloadTag: 'ShopFindings',
        type: 'longstring',
      },
      {
        tag: 'evaluation',
        label: 'Evaluation',
        value: item?.evaluation,
        payloadTag: 'Evaluation',
        type: 'longstring',
      },
      {
        tag: 'chargeback',
        label: 'Chargeback',
        value: item?.chargeback,
        payloadTag: 'ChargeBack',
        type: 'longstring',
      },
      {
        tag: 'troubleshooting',
        label: 'MWD Troubleshooting',
        dropDownFilter: '',
        value: item?.MwdData?.troubleshooting,
        payloadTag: 'MwdTroubleshooting',
        type: 'longstring',
      },
      {
        tag: 'shopFindings',
        label: 'MWD Shop Findings',
        dropDownFilter: '',
        value: item?.MwdData?.shopFindings,
        payloadTag: 'MwdShopFindings',
        type: 'longstring',
      },
    ]
  }

  const getFailureData = (item) => {
    if (!item || typeof item !== 'object') return []
    return [
      {
        tag: 'failed',
        label: 'Failed',
        dropDownFilter: '',
        value: item?.failed,
        payloadTag: 'Failed',
        type: 'boolean',
      },
      {
        tag: 'catastrophicFailure',
        label: 'Catastrophic',
        dropDownFilter: '',
        value: item?.catastrophicFailure,
        payloadTag: 'CatastrophicFailure',
        type: 'boolean',
      },
      {
        tag: 'motorFailed',
        label: 'Motor Failure',
        dropDownFilter: '',
        value: item?.motorFailed,
        payloadTag: 'MotorFailed',
        type: 'boolean',
      },
      {
        tag: 'mwdFailed',
        label: 'MWD Failure',
        dropDownFilter: '',
        value: item?.mwdFailed,
        payloadTag: 'MwdFailed',
        type: 'boolean',
      },
      {
        tag: 'lih',
        label: 'LIH',
        dropDownFilter: '',
        value: item?.lih,
        payloadTag: 'Lih',
        type: 'boolean',
      },
      {
        tag: 'tripForFailure',
        label: 'TOOH',
        dropDownFilter: '',
        value: item?.tripForFailure,
        payloadTag: 'TripForFailure',
        type: 'boolean',
      },
      {
        tag: 'unplannedTrip',
        label: 'Unpl. Trip',
        dropDownFilter: '',
        value: item?.unplannedTrip,
        payloadTag: 'UnplannedTrip',
        type: 'boolean',
      },
      {
        tag: 'hrsToFail',
        label: 'Hrs to fail',
        dropDownFilter: '',
        value: parseFloat(item?.hrsToFail),
        payloadTag: 'HrsToFail',
        type: 'float',
        maxVal: 100000,
        minVal: 0,
        precision: 2,
      },
      {
        tag: 'npt',
        label: 'NPT Hrs',
        dropDownFilter: '',
        value: parseFloat(item?.npt),
        payloadTag: 'Npt',
        type: 'float',
        maxVal: 100000,
        minVal: 0,
        precision: 2,
      },
      {
        tag: 'numstalls',
        label: '# Stalls',
        dropDownFilter: '',
        value: parseFloat(item?.numstalls),
        payloadTag: 'Numstalls',
        type: 'float',
        maxVal: 100000,
        minVal: 0,
        precision: 0,
      },
      {
        tag: 'stallPressure',
        label: 'Stall Pressure',
        dropDownFilter: '',
        value: parseFloat(item?.stallPressure),
        maxVal: 100000,
        minVal: 0,
        precision: 0,
        payloadTag: 'StallPressure',
      },
      {
        tag: 'dryReamTime',
        label: 'Dry Ream',
        dropDownFilter: '',
        value: item?.dryReamTime,
        payloadTag: 'DryReamTime',
        type: 'float',
        maxVal: 100000,
        minVal: 0,
        precision: 2,
      },
      {
        tag: 'washReamTime',
        label: 'Wash Ream',
        dropDownFilter: '',
        value: item?.washReamTime,
        payloadTag: 'WashReamTime',
        type: 'float',
        maxVal: 100000,
        minVal: 0,
        precision: 2,
      },
    ]
  }

  const addFieldIds = (data) => {
    //adds unique id field to nested objects for ag grid getRowId functionality
    let counter = 1
    for (const field in data) {
      if (data.hasOwnProperty(field) && Array.isArray(data[field])) {
        for (let i = 0; i < data[field].length; i++) {
          data[field][i].id = counter
          counter++
        }
      }
    }

    return data
  }

  const fetchMotorReportData = (bhaNum) => {
    if (
      !motorPerformanceData.current ||
      !motorPerformanceData.current.hasOwnProperty('motorReports') ||
      !Array.isArray(motorPerformanceData.current?.motorReports)
    )
      return null

    if (bhaNum === null || bhaNum === undefined) return null
    let motorIndex = motorPerformanceData.current.motorReports.findIndex(
      (mr) => parseInt(mr.bhaNum) === parseInt(bhaNum),
    )
    if (motorIndex < 0) return null

    let mr = motorPerformanceData.current.motorReports[motorIndex]

    let output = {
      deltaHrs: getDeltaIsoDays(mr.dateTimeInIso, mr.dateTimeOutIso) * 24,
      runSummary: getRunSummary(mr),
      drillingParams: getDrillingParams(mr),
      mudProperties: getMudProperties(mr),
      personnelData: getPersonnelData(mr),
      mwdData: getMwdData(mr),
      comments: getComments(mr),
      failureInfo: getFailureData(mr),
    }

    return addFieldIds(output)
  }

  const getFlattenedMotorReport = (bhaNum) => {
    let mr = fetchMotorReportData(bhaNum)
    if (!mr) return null

    let output = {
      wellName: currentWellRef.current,
      BhaNum: bhaNum,
    }

    // eslint-disable-next-line no-unused-vars
    for (const [key, value] of Object.entries(mr)) {
      if (Array.isArray(value)) {
        for (let i = 0; i < value.length; i++) {
          if (!value[i].hasOwnProperty('payloadTag')) continue
          output[value[i].payloadTag] = value[i].value
        }
      }
    }

    return output
  }

  const updateMotorReport = async (bhaNum, data) => {
    if (!Array.isArray(data)) {
      return { isError: true, message: 'data not an array' }
    }

    if (bhaNum === null || bhaNum === undefined) return { isError: true, message: 'invalid bhaNum' }

    let flattenedMotorReport = getFlattenedMotorReport(bhaNum)
    if (!flattenedMotorReport) return { isError: true, message: 'could not get flattened mr' }

    if (!flattenedMotorReport.Failed) {
      flattenedMotorReport.MotorFailed = false
      flattenedMotorReport.MwdFailed = false
      flattenedMotorReport.CatastrophicFailure = false
      flattenedMotorReport.TripForFailure = false
      flattenedMotorReport.Npt = 0
      flattenedMotorReport.HrsToFail = 0
    }

    if (isUpdating.current) return { isError: true, message: 'update in progress' }
    isUpdating.current = true

    for (let i = 0; i < data.length; i++) {
      if (!data[i].hasOwnProperty('tag') || !data[i].hasOwnProperty('value')) {
        return { isError: true, message: 'invalid motor report data' }
      }

      if (!flattenedMotorReport.hasOwnProperty(data[i].payloadTag) && data[i].editable) {
        return { isError: true, message: `cannot find tag ${data[i].payloadTag}` }
      }

      flattenedMotorReport[data[i].payloadTag] = data[i].value
    }

    flattenedMotorReport = htmlSymbolHandling(flattenedMotorReport)

    let res = await updateMotorRptData(flattenedMotorReport)
    isUpdating.current = false

    if (res?.error) {
      return { isError: true, message: `${res?.error?.response?.data?.error}` }
    }

    return { isError: false, message: '' }
  }

  return {
    loading,
    fetchMotorReport,
    fetchMotorReportData,
    updateMotorReport,
    currentWellRef
  }
}

export default useMotorReport
