import { currentPageAtom, currentWellAtom } from 'atoms'
import { PAGE_KEYS } from 'components/ActionBar/pageDefs'
import React, { useEffect, useState, useRef } from 'react'
import { Box } from '@mui/material'
import { useSetRecoilState, useRecoilValue } from 'recoil'
import Carousel from 'react-material-ui-carousel'
import { actionBarWidthAtom } from 'atoms'
import ThreeDeeView from 'components/ThreeDeeView/ThreeDeeView'
import PlanViewChart from 'components/WellPages/PlanView/PlanViewChart'
import SectionViewChart from 'components/WellPages/SectionView/SectionViewChart'
import SurveysGrid from './SurveysGrid'
import SplitPane from 'components/common/SplitPane'
import useAnimatePane from 'components/common/hooks/useAnimatePane'
import useWellData from 'components/common/hooks/useWellData'
import ToolsPane from './ToolsPane/ToolsPane'
import { getItemFromLS, saveItemToLS } from 'utils/localStorage'
import { cloneDeep } from 'lodash'
import useGeosteering from 'components/common/hooks/useGeosteering'
import { getFullGeoSteeringData } from 'utils/GeoSteeringFunctions'
import useSoloCloud from 'components/common/hooks/useSoloCloud'
import ConfirmTargetLineModal from './ConfirmTargetLineModal'
import { Icon as Iconify } from '@iconify/react'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const SurveysPage = () => {
  const setActivePage = useSetRecoilState(currentPageAtom)
  const leftPos = useRecoilValue(actionBarWidthAtom)
  const [surveys, setSurveys] = useState([])
  const { setPaneVisible: setShowChart, paneVisible: showChart, paneSize } = useAnimatePane()
  const [projections, setProjections] = useState([])
  const currentWell = useRecoilValue(currentWellAtom)
  const [shouldUpdate, setShouldUpdate] = useState(false)
  const geoZones = useRef([])
  const [geoSteeringDataRaw, setGeoSteeringDataRaw] = useState([])
  const [showCreateConfirmTargetLine, setShowCreateConfirmTargetLine] = useState(false)
  const [showGrid, setShowGrid] = useState(true)
  const { getChartBackColor, getWindowBarColor } = useInnovaTheme()
  const [filteredData, setFilteredData] = useState([])

  const {
    wellData,
    isLoading,
    getPrincipalPlanName,
    getWellPlanSurveys,
    getVerticalSectionParams,
    getTargets,
    getCriticalPoints,
    toggleErrorEllipses,
  } = useWellData(currentWell)
  const [showToolsPane, setShowToolsPane] = useState(false)
  const { fetchGeoSteeringData } = useGeosteering()

  const { handleAcceptTargetLine, targetLineChanged, getNewTargetLine } = useSoloCloud()

  useEffect(() => {
    setActivePage(PAGE_KEYS.surveyKey)
    getGeoSteeringData()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (targetLineChanged) setShowCreateConfirmTargetLine(true)
  }, [targetLineChanged])


  useEffect(() => {
    getGeoSteeringData()
  }, [currentWell]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    calcGeoZones()
  }, [surveys, geoSteeringDataRaw, wellData]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (filteredData.length > 0) {
      saveItemToLS('surveysFilteredData', 'filteredData', cloneDeep(filteredData))
    }
  }, [filteredData])

  const handleShowChart = () => {
    setShowChart((prev) => !prev)
  }

  const getGeoSteeringData = async () => {
    let res = await fetchGeoSteeringData()
    if (res?.error) return

    setGeoSteeringDataRaw(Array.isArray(res.data) ? res.data : [])
  }

  const calcGeoZones = () => {
    geoZones.current = getFullGeoSteeringData(
      geoSteeringDataRaw,
      wellData?.actualWellData?.verticalSection,
      cloneDeep(surveys),
      wellData?.wellPlan?.surveyData,
    )
    setShouldUpdate(!shouldUpdate)
  }

  const getInitialPaneSize = (index) => {
    let pageLayout = getItemFromLS('surveysPageLayout', 'pageLayout')

    if (!Array.isArray(pageLayout)) return '70%'
    if (index >= pageLayout.length) return '70%'
    return pageLayout[index].size
  }

  const onDragFinished = (newSize, index) => {
    let newLayout = [{ size: '70%', visible: false }]

    let pageLayout = getItemFromLS('surveysPageLayout', 'pageLayout')
    if (Array.isArray(pageLayout) && pageLayout.length >= 0) newLayout = cloneDeep(pageLayout)
    newLayout[index].size = newSize
    saveItemToLS('surveysPageLayout', 'pageLayout', cloneDeep(newLayout))
  }

  const handleCloseConfirmTargetLine = () => {
    setShowCreateConfirmTargetLine(false)
  }

  return (
    <React.Fragment>
      {showCreateConfirmTargetLine ? (
        <ConfirmTargetLineModal
          open={showCreateConfirmTargetLine}
          onClose={handleCloseConfirmTargetLine}
          handleAcceptTargetLine={handleAcceptTargetLine}
          targetLineData={getNewTargetLine()}
          planName={getPrincipalPlanName()}
          wellName={currentWell}
        />
      ) : null}
      <SplitPane
        split='horizontal'
        defaultSize={'50%'}
        size={showGrid ? paneSize : 'calc(100% - 25px)'}
        style={{
          marginTop: '64px',
          marginLeft: `${leftPos}px`,
          height: 'calc(100vh - 64px)',
          maxHeight: 'calc(100vh - 64px)',
          minHeight: 'calc(100vh - 64px)',
          width: `calc(100% - ${leftPos}px)`,
          maxWidth: `calc(100% - ${leftPos}px)`,
          minWidth: `calc(100% - ${leftPos}px)`,
        }}
        allowResize={true}>
        <Carousel autoPlay={false} height={'100%'} fullHeightHover={false} swipe={false} sx={{ width: '100%' }}>
          <ThreeDeeView
            refWellSurveys={surveys}
            wellData={wellData}
            isLoading={isLoading}
            wellName={currentWell}
            projections={projections}
            refGeosteering={geoZones}
            toggleErrorEllipses={toggleErrorEllipses}
          />
          <Box sx={{ backgroundColor: getChartBackColor(), height: '100%', width: '100%' }}>
            <PlanViewChart
              data={wellData}
              refWellSurveys={surveys}
              isLoading={isLoading}
              projections={projections}
              toggleErrorEllipses={toggleErrorEllipses}
            />
          </Box>
          <Box sx={{ backgroundColor: getChartBackColor(), height: '100%', width: '100%' }}>
            <SectionViewChart
              data={wellData}
              refWellSurveys={surveys}
              isLoading={isLoading}
              projections={projections}
              refGeosteering={geoZones.current}
              toggleErrorEllipses={toggleErrorEllipses}
            />
          </Box>
        </Carousel>
        <Box sx={{ width: '100%', height: '100%' }}>
          <Box
            sx={{
              width: '100%',
              height: '25px',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: getWindowBarColor(),
            }}>
            <Box
              onClick={() => setShowGrid(!showGrid)}
              sx={{
                width: '25px',
                height: '25px',
                backgroundColor: '#222628',
                cursor: 'pointer',
                flexDirection: 'column',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <Iconify icon={'zondicons:view-hide'} width={'20px'} height={'20px'} color={'#808080'} />
            </Box>
            <Box
              onClick={() => setShowGrid(!showGrid)}
              sx={{
                width: 'calc(100% - 25px)',
                height: '25px',
                color: '#f0f0f0',
                cursor: 'pointer',
                '&:hover': {
                  color: '#429ceb',
                },
                display: 'flex',
                justifyContent: 'center',
                fontWeight: 'bold',
              }}>
              Surveys
            </Box>
          </Box>
          <SplitPane
            split='vertical'
            onDragFinished={(params) => onDragFinished(params, 0)}
            defaultSize={'calc(100% - 25px)'}
            size={showToolsPane ? getInitialPaneSize(0) : 'calc(100% - 25px)'}
            allowResize={showToolsPane}
            style={{
              height: 'calc(100% - 25px)',
              width: `100%`,
              maxWidth: `100%`,
            }}>
            <SurveysGrid
              wellName={currentWell}
              handleShowChart={handleShowChart}
              showChart={showChart}
              setSurveys={setSurveys}
              isEditable={true}
              setFilteredData={setFilteredData}
            />
            <ToolsPane
              wellName={currentWell}
              principalPlanName={getPrincipalPlanName()}
              showHidePane={() => setShowToolsPane(!showToolsPane)}
              paneVisible={showToolsPane}
              surveys={surveys}
              wellPlan={getWellPlanSurveys()}
              vsParams={getVerticalSectionParams()}
              projections={projections}
              setProjections={setProjections}
              targets={getTargets(true)}
              criticalPoints={getCriticalPoints()}
              getGeoSteeringData={getGeoSteeringData}
            />
          </SplitPane>
        </Box>
      </SplitPane>
    </React.Fragment>
  )
}

export default SurveysPage
