import React, { useMemo, useRef, useState, useEffect, useCallback } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { Box, Avatar } from '@mui/material'
import { styled } from '@mui/styles'
import { Icon as Iconify } from '@iconify/react'
import { appColors } from 'utils'
import { useRecoilValue } from 'recoil'
import { checkPermission } from 'components/userPermissions'
import { userUserRoleAtom } from 'atoms'
import useUnits, { UNITS_FOR } from 'components/common/hooks/useUnits'
import { numberWithCommasDecimals } from 'utils/stringFunctions'
import { saveItemToLS } from 'utils/localStorage'
import cloneDeep from 'lodash/cloneDeep'
import PopupCellRenderer from '../DailyReportsPages/PopupCellRenderer'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import ConfirmDialog from 'components/common/ConfirmDialog'
import BhaImagesModal from 'components/WellPages/DrillStringPages/MotorReportModal/BhaImagesModal'
import MotorReportModal from './MotorReportModal/MotorReportModal'
import motorIcon from 'assets/EngineeringIcons/Torque1.png'
import {
  sortColDefs,
  htmlSymbolHandling,
  showHideDetailGrid,
  CustomLoadingOverlay,
  dateComparator,
  isDateLessThan,
  getStringId,
} from 'components/common/AgGridUtils'
import { unescapeHtml } from 'utils/htmlSymbolHandling'
import DrillStringComponentsGrid from './DrillStringComponentsGrid'
import DrillStringImportModal from './DrillStringImportModal'
import { checkFeature } from 'components/userPermissions'
import ErrorCellRenderer from './ErrorCellRenderer'
import useObjectQc from 'components/common/hooks/useObjectQc'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const StyledIconContainer = styled(Box)({
  color: appColors.itemTextColor,
  width: '30px',
  height: '30px',
  margin: '5px',
  marginRight: '10px',
  textAlign: 'center',
  '&:hover': {
    cursor: 'pointer',
  },
})

const StyledReportIcon = styled(Iconify)({
  color: appColors.itemTextColor,
  width: '30px',
  height: '30px',
})

const DrillStringGrid = ({
  gridData,
  isLoading,
  onBhaPdfExport,
  onMotorReportPdfExport,
  setFilteredData,
  setStatus,
  setShowChart,
  showChart,
  wellName,
  onXlsExport,
  onIaddExport,
  onIaddXlsExport,
  refresh,
}) => {
  const _isMounted = useRef(false)
  const gridApi = useRef(null)
  const fetchingQc = useRef(false)
  const [resetCols, setResetCols] = useState(false)
  const userRole = useRecoilValue(userUserRoleAtom)
  const { getUnitsText } = useUnits()
  const selectedBha = useRef(null)
  const [confirm, setConfirm] = useState({ show: false, title: '' })
  const [showImportBhaModal, setShowImportBhaModal] = useState(false)
  const [showMotorImagesModal, setShowMotorImagesModal] = useState(false)
  const bhaNumRef = useRef(-1)
  const [showMotorRptModal, setShowMotorRptModal] = useState(false)
  const [bhaQc, setBhaQc] = useState(false)
  const containerRef = useRef(null)
  const bitGradeUpdated = useRef(null)
  const { getDropDownValues } = useObjectQc()
  const { getAgGridTheme, getTextColor } = useInnovaTheme()

  const deleteBha = useInnovaAxios({
    url: '/well/drillString/deleteBha',
  })

  const updateBha = useInnovaAxios({
    url: '/well/drillString/updateBha',
  })

  const duplicateBha = useInnovaAxios({
    url: '/well/drillString/duplicateBha',
  })

  const updateSureShop = useInnovaAxios({
    url: '/sureShop/updateRunInfo',
  })

  const getBhaQc = useInnovaAxios({
    url: '/well/drillString/QcBha',
  })

  const fetchBhaQc = useCallback(async () => {
    if (!wellName) return
    if (typeof wellName !== 'string') return
    if (wellName === '') return
    if (fetchingQc.current) return

    fetchingQc.current = true
    let res = await getBhaQc({ wellName: wellName })

    fetchingQc.current = false
    if (!_isMounted.current) return
    if (res.error) return

    setBhaQc(Array.isArray(res.data) ? res.data : [])
  }, [getBhaQc, wellName])

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
    }
  }, [])

  useEffect(() => {
    fetchBhaQc()
  }, [wellName, refresh]) // eslint-disable-line react-hooks/exhaustive-deps

  const columnFormatter = (params) => {
    const { first } = params

    return {
      fill: first ? appColors.slideColor : appColors.rotateColor,
    }
  }

  const ropColumnFormatter = (params) => {
    const { first, last } = params

    return {
      fill: first ? appColors.slideColor : last ? appColors.avgColor : appColors.rotateColor,
    }
  }

  const tooltipRenderer = (params) => {
    const { yValue, xValue } = params
    return `<div class='grid-sparkline-tooltip'>
                <div class='grid-sparkline-tooltip-title'>
                    <div>${xValue}: ${yValue.toFixed(2)}</div>
                </div>
            </div>`
  }

  const getHighestValue = useCallback(
    (first, second) => {
      let largestAmount = 0
      if (gridData?.length <= 0) return 1

      gridData.forEach((item) => {
        if (item[first] > largestAmount) largestAmount = item[first]
        if (item[second] > largestAmount) largestAmount = item[second]
      })

      return largestAmount
    },
    [gridData],
  )

  const onClickDelete = (data) => {
    setConfirm({
      show: true,
      title: 'Delete BHA',
      text: `Are you sure you want to delete BHA ${data.bhaNumRep}?`,
    })
  }

  const updateSureShopRunInfo = async () => {
    const res = await updateSureShop({
      wellName: wellName,
    })

    if (!_isMounted.current) return

    if (res.error) {
      setStatus({ show: true, severity: 'error', message: 'Delete bha failed' })
      return
    }

    setStatus({ show: true, severity: 'success', message: 'Run info updated' })
  }

  const confirmDelete = async () => {
    if (!selectedBha.current) return

    const res = await deleteBha({
      bhaNum: selectedBha.current.bhaNum,
      wellName: selectedBha.current.actualWell,
    })

    if (!_isMounted.current) return

    if (!res.error) {
      if (gridApi.current) gridApi.current.applyTransaction({ remove: [selectedBha.current] })
      return
    }

    setStatus({ show: true, severity: 'error', message: 'Delete bha failed' })
  }

  const handleDuplicateBha = useCallback(
    async (data) => {
      if (!data) return
      data = htmlSymbolHandling(data)
      data.wellName = data.actualWell

      let res = await duplicateBha(data)
      if (res?.error) {
        setStatus({ show: true, severity: 'error', message: res?.error?.response?.data?.error })
        return
      }

      if (gridApi.current && res.data) {
        res.data.actualWell = wellName
        gridApi.current.applyTransaction({
          add: [cloneDeep(res.data)],
          addIndex: 0,
        })
      }
    },
    [duplicateBha, setStatus, wellName],
  )

  const handleMenuClick = useCallback(
    (action, data) => {
      if (typeof action !== 'string') return
      if (!data) return

      selectedBha.current = data
      if (action === 'delete') {
        if (!checkPermission('canDelete', userRole.roleAttributes?.permissions)) return
        onClickDelete(data)
        return
      }

      if (action === 'printBhaReport') {
        onBhaPdfExport(data?.bhaNum)
        return
      }

      if (action === 'printMotorReport') {
        onMotorReportPdfExport(data?.bhaNum)
        return
      }

      if (action === 'viewMotorReportImages') {
        bhaNumRef.current = data.bhaNum
        setShowMotorImagesModal(true)
      }

      if (action === 'editMotorReport') {
        bhaNumRef.current = data.bhaNum
        setShowMotorRptModal(true)
      }

      if (action === 'duplicate') {
        bhaNumRef.current = data.bhaNum
        handleDuplicateBha(data)
      }

      if (action === 'iaddExport') {
        onIaddExport(data.bhaNum)
      }

      if (action === 'iaddXlsExport') {
        onIaddXlsExport(data.bhaNum)
      }

      if (action === 'validate') {
        fetchBhaQc()
      }
    },
    [handleDuplicateBha, onBhaPdfExport, onIaddExport, onIaddXlsExport, onMotorReportPdfExport, userRole, fetchBhaQc],
  )

  const dateFilterComparator = useCallback(dateComparator, [])

  const centerAlignCell = useMemo(() => {
    return {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }
  }, [])

  const dateTimeFormatter = useCallback((value) => {
    if (!value) return ''
    if (typeof value !== 'string') return ''
    if (value === '') return ''
    if (isDateLessThan(value, '1990-01-01')) return ''
    value = value.replace(/Z/g, '')
    return new Date(Date.parse(value)).toLocaleDateString('default', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    })
  }, [])

  const expandIconRenderer = useCallback(
    (params) => {
      return (
        <Box
          onClick={() => showHideDetailGrid(params, 'bhaNum', false, setShowChart, showChart?.current)}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            '&:hover': {
              cursor: 'pointer',
            },
          }}>
          <Iconify
            icon={!params?.node?.expanded ? 'material-symbols:chevron-right-rounded' : 'mdi:chevron-down'}
            style={{ color: getTextColor(), height: '30px', width: '30px' }}
          />
        </Box>
      )
    },
    [setShowChart, showChart, getTextColor],
  )

  const leftAlignedCell = useMemo(() => {
    return {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'start',
      overflow: 'hidden',
    }
  }, [])

  const columnDefs = useMemo(
    () => [
      {
        field: 'bhaDetails',
        colId: 'bhaDetails',
        headerName: '',
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
        sortable: false,
        resizable: false,
        pinned: 'left',
        lockPosition: 'left',
        cellRenderer: expandIconRenderer,
        cellStyle: centerAlignCell,
      },
      {
        field: 'warnings',
        colId: 'warnings',
        headerName: '',
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
        sortable: false,
        resizable: false,
        width: 30,
        pinned: 'left',
        lockPosition: 'left',
        cellRenderer: (params) => {
          return (
            <ErrorCellRenderer
              errors={Array.isArray(bhaQc) ? bhaQc.filter((err) => err.bhaNum === params.data?.bhaNum) : []}
            />
          )
        },
        cellStyle: centerAlignCell,
      },
      {
        headerName: '',
        colId: 'actions',
        cellStyle: {
          borderRight: '1px solid #45474B',
        },
        pinned: 'left',
        editable: false,
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
        filter: null,
        width: 50,
        cellRenderer: PopupCellRenderer,
        cellRendererParams: {
          menuItems: [
            {
              label: 'Delete',
              action: 'delete',
              onClick: handleMenuClick,
              icon: () => (
                <StyledIconContainer>
                  <Iconify
                    icon='fa-regular:trash-alt'
                    style={{ color: 'red', height: '21px', width: '21px', padding: '0px' }}
                  />
                </StyledIconContainer>
              ),
            },
            {
              label: 'Print Analysis BHA report',
              action: 'printBhaReport',
              onClick: handleMenuClick,
              icon: () => (
                <StyledIconContainer>
                  <StyledReportIcon icon='ic:baseline-print' />
                </StyledIconContainer>
              ),
            },
            {
              label: 'Print Motor report',
              action: 'printMotorReport',
              onClick: handleMenuClick,
              icon: () => (
                <StyledIconContainer>
                  <StyledReportIcon icon='el:print' />
                </StyledIconContainer>
              ),
            },
            {
              label: 'BHA Images',
              action: 'viewMotorReportImages',
              onClick: handleMenuClick,
              icon: () => (
                <StyledIconContainer>
                  <StyledReportIcon icon='icon-park-outline:picture' />
                </StyledIconContainer>
              ),
            },
            {
              label: 'Edit Motor Report',
              action: 'editMotorReport',
              onClick: handleMenuClick,
              icon: () => (
                <Box
                  sx={{
                    width: '30px',
                    height: '30px',
                    margin: '5px',
                    marginRight: '10px',
                    border: `1px solid ${appColors.itemTextColor}`,
                    borderRadius: '5px',
                    justifyContent: 'center',
                  }}>
                  <Avatar
                    variant='square'
                    sx={{ width: '30px', height: '30px', borderRadius: '5px' }}
                    alt='BHA Details'
                    src={motorIcon}
                  />
                </Box>
              ),
            },
            {
              label: 'Copy BHA',
              action: 'duplicate',
              onClick: handleMenuClick,
              icon: () => (
                <StyledIconContainer>
                  <StyledReportIcon icon='grommet-icons:copy' />
                </StyledIconContainer>
              ),
            },
            {
              label: 'IADD BHA Export',
              action: 'iaddExport',
              onClick: handleMenuClick,
              icon: () => (
                <StyledIconContainer>
                  <StyledReportIcon icon='tabler:json' />
                </StyledIconContainer>
              ),
            },
            {
              label: 'IADD BHA XLS Export',
              action: 'iaddXlsExport',
              onClick: handleMenuClick,
              icon: () => (
                <StyledIconContainer>
                  <StyledReportIcon icon='icomoon-free:file-excel' />
                </StyledIconContainer>
              ),
            },
            {
              label: 'Refresh QC',
              action: 'validate',
              onClick: handleMenuClick,
              icon: () => (
                <StyledIconContainer>
                  <StyledReportIcon icon='grommet-icons:validate' />
                </StyledIconContainer>
              ),
            },
          ],
        },
      },
      {
        headerName: 'BHA#',
        colId: 'bhaNumRep',
        field: 'bhaNumRep',
        editable: true,
        cellStyle: centerAlignCell,
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 1,
          max: 100,
          precision: 0,
        },
      },
      {
        headerName: 'MWD Run#',
        colId: 'mwdRunNum',
        field: 'mwdRunNum',
        editable: true,
        cellStyle: centerAlignCell,
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 1,
          max: 100,
          precision: 0,
        },
        valueFormatter: (params) => {
          if (!params?.value) return ''
          return params.value
        },
      },
      {
        headerName: 'Formation',
        colId: 'formation',
        field: 'formation',
        editable: true,
        cellStyle: centerAlignCell,
        valueFormatter: (params) => unescapeHtml(params.value),
      },
      {
        headerName: 'Mud Weight',
        colId: 'mudWeight',
        field: 'mudWeight',
        editable: true,
        cellStyle: centerAlignCell,
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 1,
          max: 100000,
          precision: 3,
        },
      },
      {
        headerName: 'Description',
        colId: 'bhaDescription',
        field: 'bhaDescription',
        editable: true,
        cellStyle: leftAlignedCell,
        valueFormatter: (params) => unescapeHtml(params.value),
      },
      {
        headerName: 'Purpose',
        colId: 'bhaPurpose',
        field: 'bhaPurpose',
        editable: true,
        cellStyle: leftAlignedCell,
        valueFormatter: (params) => unescapeHtml(params.value),
        cellEditorSelector: (params) => {
          let dropDownValues = getDropDownValues(params.data.operator, 'bhaPurpose')
          if (dropDownValues?.length > 0) {
            return {
              component: 'agSelectCellEditor',
              params: {
                values: dropDownValues,
              },
            }
          }

          return {
            component: 'agTextCellEditor',
          }
        },
      },
      {
        headerName: 'Date In',
        colId: 'pickupDateIn',
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: dateFilterComparator,
        },
        sortable: false,
        valueFormatter: (params) => dateTimeFormatter(params?.data?.dateIn),
      },
      {
        headerName: 'Date Out',
        colId: 'laydownDateOut',
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: dateFilterComparator,
        },
        sortable: false,
        valueFormatter: (params) => dateTimeFormatter(params?.data?.dateOut),
      },
      {
        headerName: `Depth In (${getUnitsText(UNITS_FOR.Depth)})`,
        colId: 'depthIn',
        field: 'depthIn',
        sortable: true,
        valueFormatter: (params) => numberWithCommasDecimals(params.data?.depthIn, 2),
      },
      {
        headerName: `Depth Out (${getUnitsText(UNITS_FOR.Depth)})`,
        colId: 'depthOut',
        field: 'depthOut',
        sortable: true,
        valueFormatter: (params) => numberWithCommasDecimals(params.data?.depthOut, 2),
      },
      {
        headerName: `Total Drilled (${getUnitsText(UNITS_FOR.Depth)})`,
        colId: 'totalDrilled',
        field: 'totalDrilled',
        sortable: true,
        valueFormatter: (params) => numberWithCommasDecimals(params.data?.totalDrilled, 2),
      },
      {
        headerName: `Footage (${getUnitsText(UNITS_FOR.Depth)})`,
        colId: 'footage',
        autoHeight: true,
        width: 140,
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
        filter: null,
        cellRenderer: 'agSparklineCellRenderer',
        cellRendererParams: {
          sparklineOptions: {
            type: 'bar',
            paddingOuter: 0.25,
            tooltip: {
              xOffset: 20,
              yOffset: -20,
              renderer: tooltipRenderer,
            },
            formatter: columnFormatter,
            label: {
              enabled: true,
              placement: 'outsideEnd',
              color: 'white',
              fontSize: 9,
            },
            stroke: '#91cc75',
            highlightStyle: {
              fill: '#fac858',
            },
            valueAxisDomain: [0, getHighestValue('slideDrilled', 'rotateDrilled')],

            padding: {
              top: 0,
              bottom: 0,
              right: 30,
            },
            axis: {
              strokeWidth: 0,
            },
          },
        },
        valueGetter: (params) => {
          return [
            ['Slide', params.data?.slideDrilled],
            ['Rotate', params.data?.rotateDrilled],
          ]
        },
      },
      {
        headerName: 'Drilling Hours',
        colId: 'drillingHours',
        field: 'totalDrillingHours',
        width: 150,
        cellStyle: {
          padding: 0,
          textAlign: 'right',
          paddingRight: 5,
        },
        valueFormatter: (params) => {
          return numberWithCommasDecimals(params.data?.totalDrillingHours, 2)
        },
      },
      {
        headerName: 'Hours',
        colId: 'hours',
        autoHeight: true,
        width: 140,
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
        filter: null,
        sortable: false,
        cellRenderer: 'agSparklineCellRenderer',
        cellRendererParams: {
          sparklineOptions: {
            paddingOuter: 0.5,
            type: 'bar',
            tooltip: {
              xOffset: 20,
              yOffset: -20,
              renderer: tooltipRenderer,
            },
            formatter: columnFormatter,
            label: {
              enabled: true,
              placement: 'outsideEnd',
              color: 'white',
              fontSize: 9,
            },
            stroke: '#91cc75',
            highlightStyle: {
              fill: '#fac858',
            },
            valueAxisDomain: [0, getHighestValue('slideHours', 'rotateHours')],
            padding: {
              top: 0,
              bottom: 0,
              right: 30,
            },
            axis: {
              strokeWidth: 0,
            },
          },
        },
        valueGetter: (params) => {
          return [
            ['Slide Hrs', params.data?.slideHours],
            ['Rotate Hrs', params.data?.rotateHours],
          ]
        },
      },
      {
        headerName: `ROP (${getUnitsText(UNITS_FOR.Depth)}/hr)`,
        colId: 'rop',
        autoHeight: true,
        sortable: false,
        width: 140,
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
        filter: null,
        cellRenderer: 'agSparklineCellRenderer',
        cellRendererParams: {
          sparklineOptions: {
            paddingOuter: 0.5,
            type: 'bar',
            tooltip: {
              xOffset: 20,
              yOffset: -20,
              renderer: tooltipRenderer,
            },
            formatter: ropColumnFormatter,
            label: {
              enabled: true,
              placement: 'outsideEnd',
              color: 'white',
              fontSize: 9,
            },
            stroke: '#91cc75',
            highlightStyle: {
              fill: '#fac858',
            },
            valueAxisDomain: [0, getHighestValue('slideRop', 'rotateRop')],
            padding: {
              top: 0,
              bottom: 0,
              right: 30,
            },
            axis: {
              strokeWidth: 0,
            },
          },
        },
        valueGetter: (params) => {
          return [
            ['Slide ROP', params.data?.slideRop],
            ['Rotate ROP', params.data?.rotateRop],
            ['Avg ROP', params.data?.avgRop],
          ]
        },
      },
      {
        headerName: 'Comments',
        field: 'comments',
        colId: 'comments',
        cellStyle: { textAlign: 'start', paddingLeft: '5px', maxWidth: '800px' },
        editable: true,
        cellEditor: 'agLargeTextCellEditor',
        cellEditorPopup: true,
        cellEditorParams: {
          maxLength: 1000,
          rows: 10,
          cols: 50,
        },
        valueFormatter: (params) => unescapeHtml(params.value),
      },
      {
        headerName: 'Motor Failed',
        colId: 'motorFailed',
        field: 'motorFailed',
        editable: false,
        cellRenderer: 'agCheckboxCellRenderer',
        cellStyle: centerAlignCell,
      },
      {
        headerName: 'MWD Failed',
        colId: 'mwdFailed',
        field: 'mwdFailed',
        editable: false,
        cellRenderer: 'agCheckboxCellRenderer',
        cellStyle: centerAlignCell,
      },
      {
        headerName: 'RSS Failed',
        colId: 'rssFailed',
        field: 'rssFailed',
        editable: false,
        cellRenderer: 'agCheckboxCellRenderer',
        cellStyle: centerAlignCell,
      },
      {
        headerName: 'K Revs',
        colId: 'krevs',
        field: 'krevs',
        editable: false,
        cellStyle: centerAlignCell,
        valueFormatter: (params) => numberWithCommasDecimals(params.value, 0),
      },
    ],
    [
      dateFilterComparator,
      dateTimeFormatter,
      getUnitsText,
      leftAlignedCell,
      centerAlignCell,
      expandIconRenderer,
      handleMenuClick,
      getHighestValue,
      bhaQc,
      getDropDownValues,
    ],
  )

  const handleUpdate = useCallback(
    async (data, prevVal, field) => {
      if (!data) return
      data = htmlSymbolHandling(data)

      data.wellName = data.actualWell
      data.bhaDesc = data.bhaDescription

      let res = await updateBha(data)
      if (res?.error) {
        setStatus({ show: true, severity: 'error', message: res?.error?.response?.data?.error })
        if (prevVal && field && gridApi.current) {
          data[field] = prevVal
          gridApi.current.applyTransaction({
            update: [cloneDeep(data)],
          })
        }

        return
      }

      if (gridApi.current) {
        gridApi.current.applyTransaction({
          update: [cloneDeep(data)],
        })
      }
    },
    [updateBha, setStatus],
  )

  const handleImportBha = async (data) => {
    setShowImportBhaModal(false)
    if (!data) return

    let res = await duplicateBha({
      wellName: wellName,
      orgWellName: data.wellName,
      bhaNum: data.bhaNum,
    })
    if (res?.error) {
      setStatus({ show: true, severity: 'error', message: res?.error?.response?.data?.error })
      return
    }

    if (gridApi.current && res.data) {
      res.data.actualWell = wellName
      gridApi.current.applyTransaction({
        add: [cloneDeep(res.data)],
        addIndex: 0,
      })
    }
  }

  const defaultColDef = useMemo(() => {
    return {
      suppressKeyboardEvent: (params) => {
        if (!params.editing) {
          let isBackspaceKey = params.event.keyCode === 8
          let isDeleteKey = params.event.keyCode === 46

          if (isBackspaceKey || isDeleteKey) {
            const focusedCell = params.api.getFocusedCell()
            if (!focusedCell?.column?.colDef.editable) return false
            params.data[focusedCell?.column?.colDef?.field] = ''
            handleUpdate(cloneDeep(params.data))
          }
        }
        return false
      },
      resizable: true,
      sortable: true,
      autoHeight: true,
      editable: false,
      suppressHeaderMenuButton: false,
      cellStyle: { textAlign: 'right', padding: 0 },
      headerClass: 'header-no-padding',
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
    }
  }, [handleUpdate])

  const saveColumnState = () => {
    if (gridApi.current) {
      const colLayout = gridApi.current.getColumnState()
      if (colLayout) saveItemToLS('drillStringGrid', 'colLayout', colLayout)
    }
  }

  const gridOptions = {
    suppressRowClickSelection: true,
    detailRowHeight: 160,
    sideBar: {
      toolPanels: [
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        },
      ],
      defaultToolPanel: '',
      position: 'left',
    },
    onDragStopped: (event) => {
      saveColumnState()
    },
    onColumnVisible: (event) => {
      saveColumnState()
    },
    onCellEditingStopped: (params) => {
      handleUpdate(params.data, params.oldValue, params.colDef.field)
    },
    loadingOverlayComponent: CustomLoadingOverlay,
    popupParent: document.querySelector('body'),
  }

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const onFilterChanged = (params) => {
    if (!gridApi.current) return

    let filteredNodes = []
    params.api.forEachNodeAfterFilter((node) => {
      filteredNodes.push(node.data)
    })

    if (_isMounted.current) setFilteredData(filteredNodes)
  }

  const onFirstDataRendered = (params) => {
    if (gridApi.current) gridApi.current.onFilterChanged()
    autoSizeColumns()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    setTimeout(() => {
      gridApi.current?.autoSizeAllColumns()
    }, 100)
  }

  const getContextMenuItems = (params) => {
    let menuItems = [
      {
        name: 'Reset columns',
        action: () => {
          gridApi.current.resetColumnState()
          saveItemToLS('drillStringGrid', 'colLayout', null)
          setResetCols(!resetCols)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Reset filters',
        action: () => {
          gridApi.current.setFilterModel(null)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      'copy',
      {
        name: 'Export Performance Report',
        action: () => {
          if (params?.node) onXlsExport(params.node.data?.bhaNum, 'performanceExport')
        },
        icon: '<span class="iconify" data-icon="icomoon-free:file-excel" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Import BHA',
        action: () => {
          setShowImportBhaModal(true)
        },
        icon: '<span class="iconify" data-icon="clarity:import-solid" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Export BHA',
        action: () => {
          if (params?.node) onXlsExport(params.node.data?.bhaNum, 'bhaExport')
        },
        icon: '<span class="iconify" data-icon="icomoon-free:file-excel" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Export BHA IADD Json',
        action: () => {
          if (params?.node) onIaddExport(-1)
        },
        icon: '<span class="iconify" data-icon="tabler:json" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Export BHA IADD XLS',
        action: () => {
          if (params?.node) onIaddXlsExport(-1)
        },
        icon: '<span class="iconify" data-icon="icomoon-free:file-excel" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
    ]

    if (checkFeature(47, userRole?.roleAttributes?.featureId)) {
      menuItems.push({
        name: 'Update SureShop run info',
        disabled: false,
        action: () => {
          updateSureShopRunInfo()
        },
        icon: '<span class="iconify" data-icon="noto:wrench" data-width="20"></span>',
        cssClasses: ['leftAlign'],
      })
    }

    return menuItems
  }

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.bhaNum)
    }
  }, [])

  const handleCloseMotorImages = () => {
    setShowMotorImagesModal(false)

    if (bitGradeUpdated.current === null) return
    if (typeof bitGradeUpdated.current !== 'string') return
    if (!gridApi.current) return

    const compGrid = gridApi.current.getDetailGridInfo(`COMP_GRID-${bhaNumRef.current}`)
    if (!compGrid) return

    let newBitData = null
    compGrid.api.forEachNode((rowNode) => {
      if (rowNode.data && rowNode.data.type === 'Drill Bit') {
        newBitData = cloneDeep(rowNode.data)
        newBitData.gradeOut = bitGradeUpdated.current
      }
    })

    if (newBitData) {
      compGrid.api.applyTransaction({ update: [newBitData] })
      const propertiesGrid = compGrid.api.getDetailGridInfo(`PROP_GRID-${newBitData.uid}`)
      if (propertiesGrid) {
        propertiesGrid.api.applyTransaction({ update: [newBitData] })
      }
    }
  }

  const DetailCellRenderer = (params) => {
    let height = 200
    if (containerRef.current) {
      height = containerRef.current.clientHeight * 0.75
    }

    const CompGrid = useMemo(
      () => (
        <DrillStringComponentsGrid
          wellName={params?.data?.actualWell}
          bhaNum={params?.data?.bhaNum}
          setStatus={setStatus}
          masterGridApi={gridApi.current}
        />
      ),
      [], // eslint-disable-line react-hooks/exhaustive-deps
    )

    return <Box sx={{ height: height }}>{CompGrid}</Box>
  }

  return (
    <React.Fragment>
      {showMotorRptModal ? (
        <MotorReportModal
          bhaNum={bhaNumRef.current}
          setOpen={() => setShowMotorRptModal(false)}
          setStatus={setStatus}
          wellName={wellName}
        />
      ) : null}
      {confirm.show ? (
        <ConfirmDialog
          title={confirm?.title}
          open={confirm?.show}
          setOpen={() => setConfirm({ show: false })}
          onConfirm={() => confirmDelete()}>
          {confirm?.text}
        </ConfirmDialog>
      ) : null}
      {showMotorImagesModal ? (
        <BhaImagesModal
          setOpen={() => handleCloseMotorImages()}
          bhaNum={bhaNumRef.current}
          bitGradeUpdated={bitGradeUpdated}
        />
      ) : null}
      {showImportBhaModal ? (
        <DrillStringImportModal onClose={() => setShowImportBhaModal(false)} onApply={handleImportBha} />
      ) : null}
      <div
        ref={containerRef}
        style={{ height: '100%', width: '100%', overflowX: 'visible' }}
        className={getAgGridTheme()}>
        <AgGridReact
          loading={isLoading}
          rowData={gridData}
          columnDefs={sortColDefs(columnDefs, 'drillStringGrid')}
          headerHeight={30}
          defaultColDef={defaultColDef}
          gridOptions={gridOptions}
          onGridReady={onGridReady}
          getContextMenuItems={getContextMenuItems}
          onFirstDataRendered={onFirstDataRendered}
          onFilterChanged={onFilterChanged}
          animateRows={true}
          getRowId={getRowId}
          masterDetail={true}
          detailCellRenderer={DetailCellRenderer}
          detailRowAutoHeight={true}
        />
      </div>
    </React.Fragment>
  )
}

export default DrillStringGrid
