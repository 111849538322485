import React, { useEffect, useRef, useState, useMemo } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { AgGridReact } from 'ag-grid-react'
import { currentWellAtom, currentPageAtom, actionBarWidthAtom } from 'atoms'
import { PAGE_KEYS } from 'components/ActionBar/pageDefs'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import useUnits, { UNITS_FOR } from 'components/common/hooks/useUnits'
import { saveItemToLS } from 'utils/localStorage'
import { numberWithCommasDecimals } from 'utils/stringFunctions'
import { sortColDefs, CustomLoadingOverlay } from 'components/common/AgGridUtils'
import { Box } from '@mui/material'
import { styled } from '@mui/styles'
import MenuButton from 'components/common/MenuButton'
import RefreshIcon from '@mui/icons-material/Refresh'
import { Icon as Iconify } from '@iconify/react'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const StyledMenuIcon = styled(Box)(({ theme }) => ({
  backgroundColor: 'transparent',
  margin: '4px',
  padding: '12px',
  position: 'fixed',
  bottom: theme.spacing(2),
  right: theme.spacing(2),
  zIndex: 2,
}))

const WellParametersRoadmapPage = () => {
  const leftPos = useRecoilValue(actionBarWidthAtom)
  const gridApi = useRef(null)
  const _isMounted = useRef(false)
  const [isLoading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const setActivePage = useSetRecoilState(currentPageAtom)
  const { getUnitsText } = useUnits()
  const [resetCols, setResetCols] = useState(false)
  const currentWell = useRecoilValue(currentWellAtom)
  const { getAgGridTheme } = useInnovaTheme()

  const getParametersData = useInnovaAxios({
    url: '/kpi/parameterRoadmap',
    timeout: 60000,
  })

  const saveColumnState = () => {
    if (gridApi.current) {
      const colLayout = gridApi.current.getColumnState()
      if (colLayout) saveItemToLS('wellParametersRoadmapGrid', 'colLayout', colLayout)
    }
  }

  useEffect(() => {
    _isMounted.current = true
    setActivePage(PAGE_KEYS.wellParametersRoadmapKey)
    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (_isMounted.current) {
      fetchData()
    }
  }, [currentWell]) // eslint-disable-line react-hooks/exhaustive-deps

  const fetchData = async () => {
    if (isLoading) return
    if (!_isMounted.current) return
    if (!currentWell) return
    if (typeof currentWell !== 'string') return
    if (currentWell === '') return

    setLoading(true)
    const dataResponse = await getParametersData({
      wellName: currentWell,
      radius: 5,
    })

    if (_isMounted.current) {
      setLoading(false)
      setData(dataResponse?.data ? dataResponse.data : [])
    }

    setTimeout(() => {
      autoSizeColumns()
    }, 500)
  }

  const centerAlignCell = () => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    sortable: true,
  })

  const defaultColDef = {
    resizable: true,
    sortable: false,
    autoHeight: false,
    editable: false,
    filter: 'agSetColumnFilter',
    filterParams: {
      excelMode: 'windows',
    },
    cellStyle: centerAlignCell,
  }

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const onFirstDataRendered = (params) => {
    setTimeout(() => {
      autoSizeColumns()
    }, 200)
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const gridOptions = {
    sideBar: {
      toolPanels: [
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        },
      ],
      defaultToolPanel: '',
      position: 'left',
    },
    rowMultiSelectWithClick: true,
    onDragStopped: (event) => {
      saveColumnState()
    },
    onColumnVisible: (event) => {
      saveColumnState()
    },
    loadingOverlayComponent: CustomLoadingOverlay,
  }

  const compareValueCellRenderer = (props) => {
    let color = props.refValue >= props.value ? 'green' : 'red'
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <Box>{props.value ? numberWithCommasDecimals(props.value, 2) : '-'}</Box>
        <Box
          sx={{
            color: color,
            marginLeft: '5px',
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
            flexDirection: 'row',
          }}>
          <Iconify
            icon={color === 'green' ? 'mdi:arrow-up-thin' : 'mdi:arrow-down-thin'}
            width='25'
            height='25'
            color={color}
          />
          {`(${props.refValue ? numberWithCommasDecimals(props.refValue, 2) : '-'})`}
        </Box>
      </Box>
    )
  }

  const percValueCellRenderer = (props) => {
    let color = 'green'
    let diff = Math.abs(props.refValue - props.value)
    if (props.value > 0) {
      diff /= props.value
    }

    if (diff > 0.1 && diff <= 0.25) color = 'orange'
    if (diff > 0.25) color = 'red'

    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <Box>{props.value ? numberWithCommasDecimals(props.value, 2) : '-'}</Box>
        {props.refValue ? (
          <Box
            sx={{
              color: color,
              marginLeft: '5px',
            }}>{`(${props.refValue ? numberWithCommasDecimals(props.refValue, 2) : '-'})`}</Box>
        ) : null}
      </Box>
    )
  }

  const columnDefs = useMemo(
    () => [
      {
        headerName: `MD (${getUnitsText(UNITS_FOR.Depth)})`,
        colId: 'md',
        valueGetter: (params) => {
          return `${numberWithCommasDecimals(params.data?.md.min, 2)} - ${numberWithCommasDecimals(
            params.data?.md.max,
            2,
          )}`
        },
      },
      {
        headerName: `Offset`,
        colId: 'wellName',
        field: 'wellName',
      },
      {
        headerName: `Hole Size (${getUnitsText(UNITS_FOR.Diameter)})`,
        colId: 'holeSize',
        valueGetter: (params) => {
          if (!params.data?.holeSize.avg) return ''
          return numberWithCommasDecimals(params.data?.holeSize.avg, 3)
        },
      },
      {
        headerName: `Phase`,
        colId: 'phase',
        field: 'phase',
      },
      {
        headerName: `ROP (${getUnitsText(UNITS_FOR.Depth)}/hr)`,
        colId: 'rop',
        valueGetter: (params) => {
          if (!params.data?.rop.avg) return ''
          return numberWithCommasDecimals(params.data?.rop.avg, 2)
        },
        cellRenderer: compareValueCellRenderer,
        cellRendererParams: (params) => {
          return {
            value: params.data?.rop.avg,
            refValue: params.data?.rop.refAvg,
          }
        },
      },
      {
        headerName: `WOB (${getUnitsText(UNITS_FOR.Weight)})`,
        colId: 'wob',
        valueGetter: (params) => {
          if (!params.data?.wob.avg) return ''
          return numberWithCommasDecimals(params.data?.wob.avg, 2)
        },
        cellRenderer: percValueCellRenderer,
        cellRendererParams: (params) => {
          return {
            value: params.data?.wob.avg,
            refValue: params.data?.wob.refAvg,
          }
        },
      },
      {
        headerName: `TQ (${getUnitsText(UNITS_FOR.Torque)})`,
        colId: 'tq',
        valueGetter: (params) => {
          if (!params.data?.tq.avg) return ''
          return numberWithCommasDecimals(params.data?.tq.avg, 2)
        },
        cellRenderer: percValueCellRenderer,
        cellRendererParams: (params) => {
          return {
            value: params.data?.tq.avg,
            refValue: params.data?.tq.refAvg,
          }
        },
      },
      {
        headerName: `RPM`,
        colId: 'rpm',
        valueGetter: (params) => {
          if (!params.data?.rpm.avg) return ''
          return numberWithCommasDecimals(params.data?.rpm.avg, 2)
        },
        cellRenderer: percValueCellRenderer,
        cellRendererParams: (params) => {
          return {
            value: params.data?.rpm.avg,
            refValue: params.data?.rpm.refAvg,
          }
        },
      },
      {
        headerName: `Flow (${getUnitsText(UNITS_FOR.FlowRate)})`,
        colId: 'flowRate',
        valueGetter: (params) => {
          if (!params.data?.flowRate.avg) return ''
          return numberWithCommasDecimals(params.data?.flowRate.avg, 2)
        },
        cellRenderer: percValueCellRenderer,
        cellRendererParams: (params) => {
          return {
            value: params.data?.flowRate.avg,
            refValue: params.data?.flowRate.refAvg,
          }
        },
      },
      {
        headerName: `Diff P (${getUnitsText(UNITS_FOR.Pressure)})`,
        colId: 'diffPress',
        valueGetter: (params) => {
          if (!params.data?.diffPress.avg) return ''
          return numberWithCommasDecimals(params.data?.diffPress.avg, 2)
        },
        cellRenderer: percValueCellRenderer,
        cellRendererParams: (params) => {
          return {
            value: params.data?.diffPress.avg,
            refValue: params.data?.diffPress.refAvg,
          }
        },
      },
    ],
    [getUnitsText], // eslint-disable-line react-hooks/exhaustive-deps
  )

  const getContextMenuItems = (params) => {
    return [
      {
        name: 'Reset columns',
        disabled: false,
        action: () => {
          gridApi.current.resetColumnState()
          saveItemToLS('wellParametersRoadmapGrid', 'colLayout', null)
          setResetCols(!resetCols)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Reset filters',
        disabled: false,
        action: () => {
          gridApi.current.setFilterModel(null)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      'copy',
      {
        name: 'Export',
        disabled: false,
        action: () => {
          gridApi.current.exportDataAsExcel({
            fileName: 'ParametersRoadmap.xlsx',
            sheetName: 'ParametersRoadmap',
          })
        },
        icon: '<span class="iconify" data-icon="icomoon-free:file-excel" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
    ]
  }
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        height: 'calc(100vh - 64px)',
        marginLeft: `${leftPos}px`,
        width: `calc(100%-${leftPos}px)`,
        maxWidth: `calc(100%-${leftPos}px)`,
        maxHeight: 'calc(100vh - 64px)',
      }}>
      <div className={getAgGridTheme()} style={{ width: '100%', height: '100%' }}>
        <AgGridReact
          rowData={data}
          loading={isLoading}
          columnDefs={sortColDefs(columnDefs, 'wellParametersRoadmapGrid')}
          defaultColDef={defaultColDef}
          animateRows={true}
          enableBrowserTooltips={true}
          gridOptions={gridOptions}
          onGridReady={onGridReady}
          onFirstDataRendered={onFirstDataRendered}
          getContextMenuItems={getContextMenuItems}
          headerHeight={30}
        />
      </div>
      <StyledMenuIcon>
        <MenuButton
          actions={[
            {
              icon: <RefreshIcon />,
              name: 'Refresh',
              onClick: () => {
                setData([])
                fetchData()
              },
            },
          ]}
        />
      </StyledMenuIcon>
    </Box>
  )
}

export default WellParametersRoadmapPage
