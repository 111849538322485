import React, { useEffect, useRef, useState, useMemo, useCallback } from 'react'
import { currentPageAtom } from 'atoms'
import { useSetRecoilState } from 'recoil'
import { PAGE_KEYS } from 'components/ActionBar/pageDefs'
import { actionBarWidthAtom } from 'atoms'
import { useRecoilValue } from 'recoil'
import { Box, Snackbar, Button } from '@mui/material'
import { AgGridReact } from 'ag-grid-react'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import SearchBar from 'components/common/SearchBar'
import { saveItemToLS } from 'utils/localStorage'
import Alert from '@mui/material/Alert'
import MenuButton from 'components/common/MenuButton'
import RefreshIcon from '@mui/icons-material/Refresh'
import { sortColDefs, relativeTimeMins, CustomLoadingOverlay, getStringId } from 'components/common/AgGridUtils'
import { unescapeHtml } from 'utils/htmlSymbolHandling'
import { debounce } from 'lodash'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import TextField from '@mui/material/TextField'
import DataQcErrorsGrid from 'components/DataQc/DataQcErrorsGrid'
import { uuidv4 } from 'utils/stringFunctions'
import Avatar from '@mui/material/Avatar'
import * as XLSX from '@sheet/core'
import { styled } from '@mui/styles'
import { Icon as Iconify } from '@iconify/react'
import { appColors } from 'utils'
import { GetActivityColor, getStatusAvatar } from 'components/common/activitySelector'
import DrillStringEditorModal from './DrillStringEditorModal'
import DataQcSettings from './DataQcSettings'
import PopupCellRenderer from 'components/WellPages/DailyReportsPages/PopupCellRenderer'
import useUpdateWellInfo from 'components/common/hooks/useUpdateWellInfo'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const getTimeDurationColor = (timeSinceMins) => {
  if (timeSinceMins === null || timeSinceMins === undefined) return 'tomato'
  if (typeof timeSinceMins === 'string' && timeSinceMins === '') return 'tomato'
  if (typeof timeSinceMins === 'string') timeSinceMins = parseFloat(timeSinceMins)

  if (timeSinceMins > 240) return 'tomato'
  if (timeSinceMins > 60) return 'orange'
  return 'green'
}

const StyledIconContainer = styled(Box)({
  color: appColors.itemTextColor,
  width: '30px',
  height: '30px',
  margin: '5px',
  marginRight: '10px',
  textAlign: 'center',
  '&:hover': {
    cursor: 'pointer',
  },
})

const StyledPopupMenuIcon = styled(Iconify)({
  color: appColors.itemTextColor,
  width: '30px',
  height: '30px',
})

const DataQcPage = () => {
  const setActivePage = useSetRecoilState(currentPageAtom)
  const leftPos = useRecoilValue(actionBarWidthAtom)
  const _isMounted = useRef(false)
  const { getAgGridTheme, searchBarStyle, getWindowBarColor, getTextColor } = useInnovaTheme()
  const gridApi = useRef(null)
  const selectedWellRef = useRef(null)
  const [showDrillStringEditor, setShowDrillStringEditor] = useState(false)
  const [showSettingsModal, setShowSettingsModal] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [wellData, setWellData] = useState([])
  const [searchText, setSearchText] = useState('')
  const [resetCols, setResetCols] = useState(false)
  const [status, setStatus] = useState({ show: false, severity: 'info', message: '' })
  const [dateRange, setDateRange] = useState({
    dateTo: new Date().toISOString(),
    dateFrom: new Date(Date.now() - 28 * 24 * 60 * 60 * 1000).toISOString(),
  })
  const containerRef = useRef(null)
  const { getDropDownList } = useUpdateWellInfo()

  const getData = useInnovaAxios({
    url: '/admin/dataQc/wellQc',
  })

  const updateWellInfo = useInnovaAxios({
    url: '/admin/dataQc/updateWellInfo',
  })

  function isValidDateTime(dateTime) {
    if (dateTime === null) return false
    const dateObj = new Date(dateTime)
    return dateObj instanceof Date && !isNaN(dateObj)
  }

  useEffect(() => {
    _isMounted.current = true
    setActivePage(PAGE_KEYS.dataQcPageKey)
    fetchData()
    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const debounceSetQuickFilter = debounce((text) => {
    if (gridApi.current) {
      gridApi.current.setGridOption('quickFilterText', text)
    }
  }, 300)

  useEffect(() => {
    debounceSetQuickFilter(searchText)
  }, [searchText]) // eslint-disable-line react-hooks/exhaustive-deps

  function validateDates(dates) {
    const { dateTo, dateFrom } = dates

    let newDates = { ...dates }
    if (new Date(Date.parse(dateFrom)).getTime() > new Date(Date.parse(dateTo)).getTime()) {
      newDates.dateFrom = dateTo
      newDates.dateTo = dateFrom
    }

    return newDates
  }

  const fetchData = async () => {
    if (isLoading) return
    setLoading(true)

    let dates = validateDates(dateRange)

    const response = await getData({
      dateTo: dates.dateTo ? dates.dateTo : null,
      dateFrom: dates.dateFrom ? dates.dateFrom : null,
    })

    if (!_isMounted.current) return
    setLoading(false)

    if (response.error) {
      setStatus({ show: true, severity: 'error', message: response?.error?.response?.data?.error })
      return
    }

    setWellData(Array.isArray(response.data) ? response.data : [])
  }

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const handleUpdate = async (data) => {
    if (!data) return
    if (isLoading) return

    setLoading(true)
    let res = await updateWellInfo(data)
    if (!_isMounted.current) return
    setLoading(false)

    if (res?.error) {
      setStatus({ show: true, severity: 'error', message: `${res?.error?.response?.data?.error}` })
      return
    }
  }

  const gridOptions = {
    getRowStyle: ({ node }) => (node?.rowPinned ? { fontWeight: 'bold', fontStyle: 'italic' } : 0),
    suppressRowClickSelection: true,
    sideBar: {
      toolPanels: [
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        },
      ],
      defaultToolPanel: '',
      position: 'left',
    },
    onDragStopped: () => {
      saveColumnState()
    },
    onColumnVisible: () => {
      saveColumnState()
    },
    loadingOverlayComponent: CustomLoadingOverlay,
    onCellEditingStopped: (event) => {
      handleUpdate(event.data)
    },
  }

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: true,
      editable: false,
      autoHeight: true,
      headerClass: 'header-no-padding',
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
    }
  }, [])

  const onFirstDataRendered = (params) => {
    if (gridApi.current) {
      gridApi.current.onFilterChanged()
    }

    autoSizeColumns()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const saveColumnState = () => {
    if (gridApi.current) {
      const colLayout = gridApi.current.getColumnState()
      if (colLayout) saveItemToLS('dataQcGrid', 'colLayout', colLayout)
    }
  }

  const relativeTimeFormatter = useCallback(relativeTimeMins, [])

  const onXlsxExport = () => {
    if (!wellData) return

    let headers = [
      'Job#',
      'Operator',
      'Wellname',
      'Rig',
      'DD Coordinator',
      'Well status',
      'Type',
      'Description',
      'Well Error',
      'Run Error',
    ]

    let qcItems = []
    if (Array.isArray(wellData)) {
      for (let i = 0; i < wellData.length; i++) {
        if (Array.isArray(wellData[i].qcChecks)) {
          for (let j = 0; j < wellData[i].qcChecks.length; j++) {
            let subItem = wellData[i].qcChecks[j]
            qcItems.push([
              wellData[i].jobNum,
              wellData[i].operator,
              wellData[i].wellName,
              wellData[i].rig,
              wellData[i].ddCoordinator,
              wellData[i].wellStatus,
              subItem.errorType,
              subItem.description,
              '1',
              '0',
            ])
          }
        }

        if (Array.isArray(wellData[i].runs)) {
          wellData[i].runs.forEach((run) => {
            if (!Array.isArray(run?.qcChecks)) return

            run.qcChecks.forEach((check) => {
              qcItems.push([
                wellData[i].jobNum,
                wellData[i].operator,
                wellData[i].wellName,
                wellData[i].rig,
                wellData[i].ddCoordinator,
                wellData[i].wellStatus,
                `BHA: ${run.bhaNumRep}`,
                check.description,
                '0',
                '1',
              ])
            })
          })
        }
      }
    }

    const wb = XLSX.utils.book_new()
    let ws = XLSX.utils.aoa_to_sheet([[new Date(Date.now()).toISOString()], headers], {
      origin: 'A1',
    })

    XLSX.utils.sheet_add_aoa(ws, qcItems, { origin: 'A3' })
    XLSX.utils.book_append_sheet(wb, ws, 'Data QC')
    XLSX.writeFile(wb, 'dataQc.xlsx')
  }

  const getContextMenuItems = (params) => {
    return [
      {
        name: 'Reset columns',
        disabled: false,
        action: () => {
          gridApi.current.resetColumnState()
          saveItemToLS('dataQcGrid', 'colLayout', null)
          setResetCols(!resetCols)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Reset filters',
        disabled: false,
        action: () => {
          gridApi.current.setFilterModel(null)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      'copy',
      {
        name: 'Export',
        disabled: false,
        action: () => {
          onXlsxExport()
        },
        icon: '<span class="iconify" data-icon="icomoon-free:file-excel" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
    ]
  }

  const centerAlignCell = useMemo(
    () => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }),
    [],
  )

  const handleMenuClick = useCallback((action, data) => {
    if (typeof action !== 'string') return
    if (!data) return

    if (action === 'drillStringEditor') {
      selectedWellRef.current = data.wellName
      setShowDrillStringEditor(true)
    }
  }, [])

  let columnDefs = useMemo(
    () => [
      {
        headerName: '',
        cellRenderer: 'agGroupCellRenderer',
        width: 35,
        cellStyle: centerAlignCell,
        cellClass: 'grid-detail-icon',
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
        filter: null,
        pinned: 'left',
        lockPosition: 'left',
      },
      {
        headerName: '',
        colId: 'actions',
        cellStyle: centerAlignCell,
        cellRenderer: PopupCellRenderer,
        cellRendererParams: (params) => {
          return {
            menuItems: [
              {
                label: 'Drill String Editor',
                action: 'drillStringEditor',
                onClick: handleMenuClick,
                icon: () => (
                  <StyledIconContainer>
                    <StyledPopupMenuIcon icon='dashicons:editor-table' />
                  </StyledIconContainer>
                ),
              },
            ],
          }
        },
        pinned: 'left',
        lockPosition: 'left',
        editable: false,
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
        resizable: false,
        filter: null,
        width: 70,
      },
      {
        headerName: '',
        width: 60,
        colId: 'avatar',
        pinned: 'left',
        lockPosition: 'left',
        cellStyle: centerAlignCell,
        cellRenderer: (params) => {
          return (
            <Box
              sx={{
                width: 35,
                height: 35,
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: GetActivityColor(params?.data?.wellStatus),
                borderRadius: '50%',
                '&:hover': { cursor: 'pointer' },
              }}>
              {params?.data.hasOwnProperty('wellStatus') ? (
                <Avatar
                  alt={params?.data?.wellStatus}
                  src={getStatusAvatar(params?.data?.wellStatus)}
                  style={{ width: 35, height: 35 }}
                />
              ) : null}
            </Box>
          )
        },
      },
      {
        field: 'jobNum',
        colId: 'jobNum',
        headerName: 'Job #',
        valueGetter: (params) => unescapeHtml(params.data?.jobNum),
        editable: true,
      },
      {
        field: 'operator',
        colId: 'operator',
        headerName: 'Operator',
        valueGetter: (params) => unescapeHtml(params.data?.operator),
      },
      {
        field: 'wellName',
        colId: 'wellName',
        headerName: 'Well Name',
        valueGetter: (params) => unescapeHtml(params.data?.wellName),
      },
      {
        field: 'rig',
        colId: 'rig',
        headerName: 'Rig',
        valueGetter: (params) => unescapeHtml(params.data?.rig),
        editable: true,
        cellEditor: 'agRichSelectCellEditor',
        cellEditorPopup: true,
        cellEditorParams: (params) => {
          return { values: getDropDownList('rigNames').sort() }
        },
      },
      {
        field: 'ddCoordinator',
        colId: 'ddCoordinator',
        headerName: 'DD Coordinator',
        valueGetter: (params) => unescapeHtml(params.data?.ddCoordinator),
        editable: true,
        cellEditor: 'agRichSelectCellEditor',
        cellEditorPopup: true,
        cellEditorParams: (params) => {
          return { values: getDropDownList('ddCoordinators').sort() }
        },
      },
      {
        field: 'wellStatus',
        colId: 'wellStatus',
        headerName: 'Well Status',
        editable: true,
        cellEditor: 'agRichSelectCellEditor',
        cellEditorPopup: true,
        cellEditorParams: (params) => {
          return { values: getDropDownList('wellStatus').sort() }
        },
      },
      {
        headerName: 'DEX',
        field: 'icdsMinsSinceUpdate',
        colId: 'icdsMinsSinceUpdate',
        cellStyle: (params) => {
          return {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: getTimeDurationColor(params.data?.icdsMinsSinceUpdate),
          }
        },
        valueGetter: (params) => {
          if (!params.data) return 99999999
          if (!params.data?.hasOwnProperty('icdsMinsSinceUpdate')) return 99999999
          if (params.data?.icdsMinsSinceUpdate === null || params.data?.icdsMinsSinceUpdate === undefined) {
            return 99999999
          }
          if (typeof params.data?.icdsMinsSinceUpdate === 'string' && params.data?.icdsMinsSinceUpdate === '') {
            return 99999999
          }

          return parseFloat(params.data?.icdsMinsSinceUpdate)
        },
        valueFormatter: (params) => relativeTimeFormatter(params.data?.icdsMinsSinceUpdate),
      },
      {
        field: 'totalErrors',
        colId: 'totalErrors',
        headerName: 'Total Errors',
        cellStyle: centerAlignCell,
      },
      {
        field: 'numWellErrors',
        colId: 'numWellErrors',
        headerName: 'Well Errors',
        cellStyle: centerAlignCell,
      },
      {
        field: 'numRunErrors',
        colId: 'numRunErrors',
        headerName: 'Run Errors',
        cellStyle: centerAlignCell,
      },
    ],
    [centerAlignCell, handleMenuClick, getDropDownList, relativeTimeFormatter],
  )

  const handleCloseStatus = (event, reason) => {
    setStatus({ show: false, severity: 'info', message: '' })
  }

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.uid)
    }
  }, [])

  const DetailCellRenderer = (params) => {
    let height = 200
    if (containerRef.current) {
      height = containerRef.current.clientHeight * 0.7
    }

    const getErrors = (data) => {
      if (!data) return []
      if (!Array.isArray(data?.qcChecks)) return []

      let errors = []
      data.qcChecks.forEach((check) => {
        errors.push({ uid: check.uid, errorType: 'Well', description: check.description })
      })

      if (!Array.isArray(data?.runs)) return errors

      data.runs.sort((a, b) => a.bhaNumRep - b.bhaNumRep)
      data.runs.forEach((run) => {
        if (!Array.isArray(run?.qcChecks)) return errors

        run.qcChecks.forEach((check) => {
          errors.push({ uid: uuidv4(), errorType: `BHA: ${run.bhaNumRep}`, description: check.description })
        })
      })

      return errors
    }

    return (
      <Box sx={{ height: height }}>
        <DataQcErrorsGrid data={getErrors(params.data)} />
      </Box>
    )
  }

  const updateDateRange = (value, newDate) => {
    let dateFrom = dateRange.dateFrom
    let dateTo = dateRange.dateTo

    if (value === 'dateFrom') {
      dateFrom = newDate
    }
    if (value === 'dateTo') {
      dateTo = newDate
    }

    const fromDate = new Date(dateFrom)
    const toDate = new Date(dateTo)
    const diffDays = Math.ceil((toDate - fromDate) / (1000 * 60 * 60 * 24))

    if (diffDays > 90 || diffDays < 0) {
      if (value === 'dateFrom') {
        toDate.setTime(fromDate.getTime() + 90 * 24 * 60 * 60 * 1000)
        dateTo = toDate.toISOString().split('T')[0]
      } else {
        fromDate.setTime(toDate.getTime() - 90 * 24 * 60 * 60 * 1000)
        dateFrom = fromDate.toISOString().split('T')[0]
      }
    }

    setDateRange({ dateTo, dateFrom })
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        height: 'calc(100vh - 64px)',
        marginLeft: `${leftPos}px`,
        width: `calc(100%-${leftPos}px)`,
        maxWidth: `calc(100%-${leftPos}px)`,
        maxHeight: 'calc(100vh - 64px)',
      }}>
      {showDrillStringEditor ? (
        <DrillStringEditorModal wellName={selectedWellRef.current} onClose={() => setShowDrillStringEditor(false)} />
      ) : null}
      {showSettingsModal ? <DataQcSettings onClose={() => setShowSettingsModal(false)} /> : null}
      <Box
        sx={{
          width: '100%',
          height: '40px',
          paddingTop: '5px',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: getWindowBarColor(),
          display: 'flex',
        }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Box sx={{ flex: 0.5, marginRight: '8px' }}>
            <DatePicker
              views={['year', 'month', 'day']}
              disableMaskedInput
              value={dateRange.dateFrom}
              onChange={(date) => {
                if (!isValidDateTime(date)) {
                  setDateRange({ dateFrom: '', dateTo: dateRange.dateTo })
                  return
                }

                let dt = new Date(Date.parse(date))
                updateDateRange('dateFrom', dt.toISOString().substring(0, 10))
              }}
              renderInput={(params) => (
                <TextField variant='standard' {...params} helperText={null} sx={{ svg: { color: getTextColor() } }} />
              )}
            />
          </Box>
          <Box sx={{ flex: 0.5, marginRight: '8px' }}>
            <DatePicker
              views={['year', 'month', 'day']}
              disableMaskedInput
              value={dateRange.dateTo}
              onChange={(date) => {
                if (!isValidDateTime(date)) {
                  setDateRange({ dateFrom: dateRange.dateFrom, dateTo: '' })
                  return
                }

                let dt = new Date(Date.parse(date))
                updateDateRange('dateTo', dt.toISOString().substring(0, 10))
              }}
              renderInput={(params) => (
                <TextField variant='standard' {...params} helperText={null} sx={{ svg: { color: getTextColor() } }} />
              )}
              sx={{ width: '150px' }}
            />
          </Box>
        </LocalizationProvider>
        <Button
          variant='contained'
          color='primary'
          onClick={() => {
            fetchData()
          }}>
          Update
        </Button>
        <SearchBar
          value={searchText}
          onChange={(newSearchTerm) => setSearchText(newSearchTerm)}
          onCancelSearch={() => setSearchText('')}
          style={searchBarStyle(3)}
        />
      </Box>
      <Box ref={containerRef} sx={{ display: 'flex', width: '100%', height: '100%' }}>
        <div className={getAgGridTheme()} style={{ width: '100%', height: '100%' }}>
          <AgGridReact
            rowData={wellData}
            loading={isLoading}
            columnDefs={sortColDefs(columnDefs, 'dataQcGrid')}
            defaultColDef={defaultColDef}
            animateRows={true}
            rowSelection='multiple'
            enableRangeSelection='true'
            enableBrowserTooltips={true}
            gridOptions={gridOptions}
            headerHeight={30}
            getRowId={getRowId}
            onGridReady={onGridReady}
            onFirstDataRendered={onFirstDataRendered}
            getContextMenuItems={getContextMenuItems}
            masterDetail={true}
            detailCellRenderer={DetailCellRenderer}
            detailRowAutoHeight={true}
          />
        </div>
      </Box>
      <Box
        sx={{
          backgroundColor: 'transparent',
          margin: '4px',
          padding: '12px',
          position: 'fixed',
          bottom: '20px',
          right: '20px',
          zIndex: 2,
        }}>
        <MenuButton
          actions={[
            {
              icon: <RefreshIcon />,
              name: 'Refresh',
              onClick: () => {
                fetchData()
              },
            },
            {
              icon: (
                <Iconify
                  icon={'fluent:settings-24-filled'}
                  style={{ color: '#2e6da4', height: '35px', width: '35px' }}
                />
              ),
              name: 'Settings',
              onClick: () => {
                setShowSettingsModal(true)
              },
            },
          ]}
        />
      </Box>
      {status?.show ? (
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={status?.show}
          autoHideDuration={2000}
          onClose={handleCloseStatus}>
          <Alert onClose={handleCloseStatus} severity={status.severity} elevation={4} variant='filled'>
            {status.message}
          </Alert>
        </Snackbar>
      ) : null}
    </Box>
  )
}

export default DataQcPage
