import React from 'react'
import { Bar } from 'react-chartjs-2'
import { merge as _merge } from 'lodash'
import { appColors } from 'utils'
import { Box, Tooltip as TooltipMui } from '@mui/material'
import { Icon as Iconify } from '@iconify/react'
import CircularProgress from '@mui/material/CircularProgress'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { numberWithCommasDecimals } from 'utils/stringFunctions'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const AnalyticsBarChart = ({
  wellData,
  title,
  xAxisTitle,
  yAxisTitle,
  chartOptions,
  isLoading,
  onXlsxExport,
  showDataLabels = true,
  showLegend = true,
}) => {
  const { theme, getChartBackColor } = useInnovaTheme()

  const defaultOptions = {
    layout: {
      padding: {
        right: 20,
      },
    },
    plugins: {
      GradientPlugIn: {
        showGradient: true,
        theme: theme,
      },
      legend: {
        position: 'top',
        align: 'end',
        display: showLegend,
      },
      title: {
        display: true,
        text: title,
        color: appColors.itemTextColor,
        font: {
          size: 20,
        },
      },
      datalabels: {
        display: showDataLabels,
        formatter: function (value, context) {
          return parseFloat(value) > 0 ? numberWithCommasDecimals(value, 0) : ''
        },
        labels: {
          value: {
            color: '#FFF',
            rotation: 90,
            anchor: 'center',
          },
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        title: {
          display: true,
          text: xAxisTitle,
          color: appColors.headerTextColor,
        },
        stacked: true,
      },
      y: {
        title: {
          display: true,
          text: yAxisTitle,
          color: appColors.headerTextColor,
        },
        grid: {
          color: '#404040',
        },
        stacked: true,
      },
    },
  }

  const options = _merge(defaultOptions, chartOptions)

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: getChartBackColor(),
      }}>
      <Bar options={options} data={wellData} plugins={[ChartDataLabels]} />
      {isLoading ? (
        <CircularProgress
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        />
      ) : null}
      {onXlsxExport ? (
        <AnalyticsChartButton
          onClick={onXlsxExport}
          icon={'file-icons:microsoft-excel'}
          toolTipText={'Export to Excel'}
          color={'green'}
        />
      ) : null}
    </Box>
  )
}

export default AnalyticsBarChart

export function AnalyticsChartButton({ onClick, icon, toolTipText, color, width, height, group }) {
  return onClick ? (
    <Box
      style={{
        position: group ? 'relative' : 'absolute',
        top: 0,
        left: 0,
        marginTop: group ? '0px' : '10px',
        marginLeft: group ? '5px' : '10px',
        padding: '5px',
        borderRadius: '5px',
        backgroundColor: 'black',
        border: '1px solid gray',
      }}>
      <TooltipMui
        title={toolTipText}
        placement='right'
        componentsProps={{
          tooltip: {
            sx: {
              backgroundColor: 'rgb(19,62,96)',
              fontSize: '12px',
              fontFamily: 'Roboto',
            },
          },
        }}>
        <Iconify
          icon={icon}
          color={color || 'gray'}
          onClick={() => onClick()}
          style={{
            width: width || '28px',
            height: height || '28px',
            cursor: 'pointer',
          }}
        />
      </TooltipMui>
    </Box>
  ) : null
}
