import React, { useEffect, useRef, useState, useMemo, useCallback } from 'react'
import { Box, Tooltip, IconButton, Snackbar } from '@mui/material'
import { AgGridReact } from 'ag-grid-react'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import SearchBar from 'components/common/SearchBar'
import { saveItemToLS } from 'utils/localStorage'
import cloneDeep from 'lodash/cloneDeep'
import { Icon as Iconify } from '@iconify/react'
import Alert from '@mui/material/Alert'
import MenuButton from 'components/common/MenuButton'
import RefreshIcon from '@mui/icons-material/Refresh'
import { sortColDefs, htmlSymbolHandling, CustomLoadingOverlay, getStringId } from 'components/common/AgGridUtils'
import { unescapeHtml } from 'utils/htmlSymbolHandling'
import { debounce } from 'lodash'
import { numberWithCommasDecimals } from 'utils/stringFunctions'
import { checkFeature } from '../../userPermissions'
import { useRecoilValue } from 'recoil'
import { userUserRoleAtom } from 'atoms'
import OasisCostImportModal from './OasisCostsImportModal'
import SapCostImportModal from './SapCostsImportModal'
import OracleCostImportModal from './OracleCostsImportModal'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const CostCodesGrid = ({ parentWell = null, wellName = null, refreshCosts }) => {
  const _isMounted = useRef(false)
  const gridApi = useRef(null)
  const { getAgGridTheme, searchBarStyle } = useInnovaTheme()
  const isAdding = useRef(false)
  const isUpdating = useRef(false)
  const isDeleting = useRef(false)
  const [isLoading, setLoading] = useState(false)
  const [costCodeData, setCostCodeData] = useState([])
  const [searchText, setSearchText] = useState('')
  const inputRow = useRef({})
  const [resetCols, setResetCols] = useState(false)
  const [showOasisModal, setShowOasisModal] = useState(false)
  const [showSapModal, setShowSapModal] = useState(false)
  const [showOracleModal, setShowOracleModal] = useState(false)
  const [linkedBid, setLinkedBid] = useState('')
  const [status, setStatus] = useState({ show: false, severity: 'info', message: '' })
  const userRole = useRecoilValue(userUserRoleAtom)

  const getData = useInnovaAxios({
    url: '/well/costCodes/getCostCodes',
  })

  const getLinkedBid = useInnovaAxios({
    url: '/well/costCodes/getLinkedBid',
  })

  const addData = useInnovaAxios({
    url: '/well/costCodes/addMultiCostCodes',
  })

  const updateData = useInnovaAxios({
    url: '/well/costCodes/updateMultiCostCodes',
  })

  const deleteData = useInnovaAxios({
    url: '/well/costCodes/deleteMultiCostCodes',
  })

  useEffect(() => {
    _isMounted.current = true
    fetchData()
    fetchLinkedBid()
    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetchData()
    fetchLinkedBid()
  }, [wellName]) // eslint-disable-line react-hooks/exhaustive-deps

  const debounceSetQuickFilter = debounce((text) => {
    if (gridApi.current) {
      gridApi.current.setGridOption('quickFilterText', text)
    }
  }, 300)

  useEffect(() => {
    debounceSetQuickFilter(searchText)
  }, [searchText]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (gridApi.current) autoSizeColumns()
  }, [isLoading]) // eslint-disable-line react-hooks/exhaustive-deps

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.uid)
    }
  }, [])

  const fetchData = async () => {
    if (isLoading) return
    setLoading(true)
    const response = await getData({ wellName: wellName ? wellName : null, well: parentWell ? parentWell : null })
    if (!_isMounted.current) return
    setCostCodeData(Array.isArray(response.data) ? response.data : [])
    setLoading(false)
  }

  const fetchLinkedBid = async () => {
    const response = await getLinkedBid({
      wellName: wellName ? wellName : null,
      well: parentWell ? parentWell : null,
    })
    if (!_isMounted.current) return
    if (!response.data) return
    if (typeof response.data !== 'string') return

    setLinkedBid(response.data)
  }

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const gridOptions = {
    pinnedBottomRowData: [inputRow.current],
    onCellEditingStopped: (event) => {
      if (event.node?.rowPinned === 'bottom') {
        handleAddRow()
      }
      if (event.node?.rowPinned !== 'bottom') {
        handleUpdate([event.data])
      }
    },
    getRowStyle: ({ node }) => (node?.rowPinned ? { fontWeight: 'bold', fontStyle: 'italic' } : 0),
    suppressRowClickSelection: true,
    sideBar: {
      toolPanels: [
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        },
      ],
      defaultToolPanel: '',
      position: 'left',
    },
    onDragStopped: () => {
      saveColumnState()
    },
    onColumnVisible: () => {
      saveColumnState()
    },
    loadingOverlayComponent: CustomLoadingOverlay,
  }

  function clearCells(start, end, columns, gridApi) {
    let itemsToUpdate = []

    for (let i = start; i <= end; i++) {
      let data = gridApi.rowModel.rowsToDisplay[i].data
      columns.forEach((column) => {
        data[column] = ''
      })
      itemsToUpdate.push(data)
    }

    handleUpdate(itemsToUpdate)
  }

  let reqFields = useMemo(() => ['costCode', 'description', 'costType', 'value'], [])

  const createPinnedCellPlaceholder = useCallback(
    ({ colDef }) => {
      if (reqFields.findIndex((field) => field === colDef.field) < 0) return ''
      return colDef.field[0].toUpperCase() + colDef.field.slice(1) + '...'
    },
    [reqFields],
  )

  function isEmptyPinnedCell({ node, value }) {
    return (node?.rowPinned === 'bottom' && value == null) || (node?.rowPinned === 'bottom' && value === '')
  }

  const defaultColDef = useMemo(() => {
    return {
      suppressKeyboardEvent: (params) => {
        if (!params.editing) {
          let isBackspaceKey = params.event.keyCode === 8
          let isDeleteKey = params.event.keyCode === 46

          if (isBackspaceKey || isDeleteKey) {
            params.api.getCellRanges().forEach((range) => {
              let colIds = range.columns.map((col) => col.colId)
              let startRowIndex = Math.min(range.startRow.rowIndex, range.endRow.rowIndex)
              let endRowIndex = Math.max(range.startRow.rowIndex, range.endRow.rowIndex)
              clearCells(startRowIndex, endRowIndex, colIds, params.api)
            })
          }
        }
        return false
      },
      resizable: true,
      sortable: true,
      editable: true,
      autoHeight: true,
      headerClass: 'header-no-padding', //This style removes the 15px horizontal padding ag grid provides
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
      valueFormatter: (params) => (isEmptyPinnedCell(params) ? createPinnedCellPlaceholder(params) : undefined),
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const onFirstDataRendered = (params) => {
    if (gridApi.current) {
      gridApi.current.onFilterChanged()
    }

    autoSizeColumns()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const saveColumnState = () => {
    if (gridApi.current) {
      const colLayout = gridApi.current.getColumnState()
      if (colLayout) saveItemToLS('costCodesGrid', 'colLayout', colLayout)
    }
  }

  const getContextMenuItems = (params) => {
    let contextMenuItems = [
      {
        name: 'Reset columns',
        disabled: false,
        action: () => {
          gridApi.current.resetColumnState()
          saveItemToLS('costCodesGrid', 'colLayout', null)
          setResetCols(!resetCols)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Reset filters',
        disabled: false,
        action: () => {
          gridApi.current.setFilterModel(null)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      'copy',
      {
        name: 'Export',
        disabled: false,
        action: () => {
          gridApi.current.exportDataAsExcel({
            fileName: 'CostCodes.xlsx',
            sheetName: 'CostCodes',
          })
        },
        icon: '<span class="iconify" data-icon="icomoon-free:file-excel" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Delete All',
        disabled: false,
        action: () => {
          let existingCodes = []
          gridApi.current.forEachNode((node) => {
            if (node.data) {
              existingCodes.push(node.data)
            }
          })

          handleDelete(existingCodes)
        },
        icon: '<span class="iconify" data-icon="material-symbols:delete" data-width="20" style="color:#FF0000"></span>',
        cssClasses: ['leftAlign'],
      },
    ]

    if (checkFeature(12, userRole?.roleAttributes?.featureId)) {
      contextMenuItems.push({
        name: 'Get Oasis cost codes',
        disabled: false,
        action: () => {
          setShowOasisModal(true)
        },
        icon: '<span class="iconify" data-icon="fa6-solid:o" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      })
    }

    if (checkFeature(14, userRole?.roleAttributes?.featureId)) {
      contextMenuItems.push({
        name: 'Get SAP cost codes',
        disabled: false,
        action: () => {
          setShowSapModal(true)
        },
        icon: '<span class="iconify" data-icon="logos:sap" data-width="20"></span>',
        cssClasses: ['leftAlign'],
      })
    }

    if (checkFeature(49, userRole?.roleAttributes?.featureId)) {
      contextMenuItems.push({
        name: 'Get Oracle cost codes',
        disabled: false,
        action: () => {
          setShowOracleModal(true)
        },
        icon: '<span class="iconify" data-icon="logos:oracle" data-width="20"></span>',
        cssClasses: ['leftAlign'],
      })
    }

    return contextMenuItems
  }

  const isPinnedRowDataCompleted = useCallback(() => {
    for (let i = 0; i < reqFields.length; i++) {
      if (!inputRow.current.hasOwnProperty(reqFields[i])) return false
    }

    return true
  }, [reqFields])

  const handleDelete = useCallback(
    async (data) => {
      if (!Array.isArray(data)) return
      if (data.length === 0) return

      if (isDeleting.current) return
      isDeleting.current = true
      let res = await deleteData({ costCodes: JSON.stringify(data) })
      isDeleting.current = false

      if (res?.error) {
        setStatus({
          show: true,
          severity: 'error',
          message: `${res?.error?.response?.data?.error}`,
        })
        return
      }

      if (!res?.error) {
        gridApi.current.applyTransaction({
          remove: data,
        })

        if (refreshCosts) refreshCosts()
      }
    },
    [deleteData, refreshCosts],
  )

  const handleAddRow = useCallback(async () => {
    if (isAdding.current) return
    if (!isPinnedRowDataCompleted()) return
    isAdding.current = true
    inputRow.current = htmlSymbolHandling(inputRow.current)
    if (wellName) inputRow.current.actualWell = wellName
    if (parentWell) inputRow.current.well = parentWell

    let codeAlreadyExists = false
    gridApi.current.forEachNode((node) => {
      if (node.data?.costCode === inputRow.current.costCode) {
        codeAlreadyExists = true
      }
    })

    if (codeAlreadyExists) {
      inputRow.current = {}
      if (gridApi.current) gridApi.current.setGridOption('pinnedBottomRowData', [inputRow.current])

      setStatus({
        show: true,
        severity: 'error',
        message: `Cost code must be unique`,
      })
      return
    }

    let res = await addData({ costCodes: JSON.stringify([inputRow.current]) })
    isAdding.current = false

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })
      return
    }

    if (!res?.error) {
      if (Array.isArray(res.data) && res.data.length > 0) {
        inputRow.current.uid = res.data[0].uid
        gridApi.current.applyTransaction({
          add: [inputRow.current],
        })
      }
    }

    inputRow.current = {}
    if (gridApi.current) gridApi.current.setGridOption('pinnedBottomRowData', [inputRow.current])
  }, [addData, parentWell, wellName, isPinnedRowDataCompleted])

  const handleAddMultipleRows = async (data) => {
    if (isAdding.current) return
    if (!Array.isArray(data)) return
    if (data.length === 0) return

    for (let i = 0; i < data.length; i++) {
      data[i] = htmlSymbolHandling(data[i])
      if (wellName) data[i].actualWell = wellName
      if (parentWell) data[i].well = parentWell
    }

    isAdding.current = true

    let res = await addData({ costCodes: JSON.stringify(data) })
    isAdding.current = false

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })
      return
    }

    if (!res?.error) {
      if (Array.isArray(res.data) && res.data.length > 0) {
        gridApi.current.applyTransaction({
          add: res.data,
        })
      }
    }
  }

  const handleUpdate = async (data) => {
    if (!Array.isArray(data)) return
    if (data.length === 0) return

    for (let i = 0; i < data.length; i++) {
      data[i] = htmlSymbolHandling(data[i])
    }

    if (isUpdating.current) return
    isUpdating.current = true
    let res = await updateData({ costCodes: JSON.stringify(data) })
    isUpdating.current = false

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })
      return
    }

    if (!res?.error) {
      gridApi.current.applyTransaction({
        update: data,
      })

      setTimeout(() => {
        autoSizeColumns()
      }, 100)

      if (refreshCosts) refreshCosts()
    }
  }

  const actionIconRenderer = useCallback(
    (params) => {
      return (
        <React.Fragment>
          <Box style={{ display: 'flex', flexDirection: 'row' }}>
            <Tooltip
              title={params.node?.rowPinned !== 'bottom' ? 'Delete' : 'Add'}
              placement='left'
              componentsProps={{
                tooltip: {
                  sx: {
                    backgroundColor: 'rgb(19,62,96)',
                    fontSize: '12px',
                    fontFamily: 'Roboto',
                  },
                },
              }}>
              <IconButton
                style={{ padding: '5px', alignItems: 'center', justifyContent: 'center' }}
                onClick={() => (params.node?.rowPinned !== 'bottom' ? handleDelete([params.data]) : handleAddRow())}
                size='large'>
                {params.node?.rowPinned !== 'bottom' ? (
                  <Iconify color='red' icon='fa-regular:trash-alt' fontSize={16} />
                ) : (
                  <Iconify color='green' icon='fluent:add-12-filled' fontSize={16} />
                )}
              </IconButton>
            </Tooltip>
            {params.node?.rowPinned !== 'bottom' ? (
              <Box style={{ textAlign: 'right', paddingLeft: '8px' }}>{params.node.rowIndex + 1}</Box>
            ) : null}
          </Box>
        </React.Fragment>
      )
    },
    [handleDelete, handleAddRow],
  )

  const centerAlignCell = useMemo(
    () => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }),
    [],
  )

  let columnDefs = useMemo(
    () => [
      {
        field: 'actions',
        colId: 'actions',
        width: 86,
        headerName: '',
        editable: false,
        filter: null,
        sortable: false,
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
        pinned: 'left',
        lockPosition: 'left',
        cellStyle: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
        cellRenderer: actionIconRenderer,
      },
      {
        field: 'costCode',
        colId: 'costCode',
        headerName: 'Code',
        valueGetter: (params) => unescapeHtml(params.data?.costCode),
        cellStyle: centerAlignCell,
      },
      {
        field: 'description',
        colId: 'description',
        headerName: 'Description',
        valueGetter: (params) => unescapeHtml(params.data?.description),
      },
      {
        field: 'value',
        colId: 'value',
        headerName: 'Value',
        valueFormatter: (params) => {
          if (params.node?.rowPinned === 'bottom' && isEmptyPinnedCell(params)) return 'Value...'
          if (params.node?.rowPinned === 'bottom') return numberWithCommasDecimals(inputRow.current.value, 2)

          return numberWithCommasDecimals(params.data?.value, 2)
        },
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: -10000000,
          max: 100000000,
          precision: 2,
        },
        cellStyle: centerAlignCell,
      },
      {
        field: 'costType',
        colId: 'costType',
        headerName: 'Schedule',
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: (params) => {
          return {
            values: ['FIXED', 'DAILY', 'HOURLY', 'VARIABLE', 'FOOTAGE', 'MILES'],
          }
        },
        cellStyle: centerAlignCell,
      },
      {
        field: 'costType2',
        colId: 'costType2',
        headerName: 'Type',
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: (params) => {
          return {
            values: ['TANGIBLE', 'INTANGIBLE', 'CONSUMABLE'],
          }
        },
        cellStyle: centerAlignCell,
      },
      {
        field: 'vendor',
        colId: 'vendor',
        headerName: 'Vendor',
        valueGetter: (params) => unescapeHtml(params.data?.vendor),
      },
      {
        field: 'costGroup',
        colId: 'costGroup',
        headerName: 'Group',
        valueGetter: (params) => unescapeHtml(params.data?.costGroup),
      },
      {
        field: 'qty',
        colId: 'qty',
        headerName: 'Qty',
        editable: false,
        valueFormatter: (params) => numberWithCommasDecimals(params.data?.qty, 2),
      },
    ],
    [centerAlignCell, actionIconRenderer],
  )

  const handleCloseStatus = (event, reason) => {
    setStatus({ show: false, severity: 'info', message: '' })
  }

  const processDataFromClipboard = (params) => {
    const data = cloneDeep(params.data)
    if (!Array.isArray(data)) return null
    if (data.length === 0) return null

    //Remove last row from pasted data if empty
    //Excel has a bug where regardless of the selected range there will be a blank row at the bottom
    const emptyLastRow = data[data.length - 1][0] === '' && data[data.length - 1].length === 1
    if (emptyLastRow) {
      data.splice(data.length - 1, 1)
    }

    //Remove commas from pasted data
    for (let i = 0; i < data.length; i++) {
      for (let j = 0; j < data[i].length; j++) {
        if (typeof data[i][j] !== 'string') continue
        data[i][j] = data[i][j].replace(',', '')
      }
    }

    const lastIndex = gridApi.current.getDisplayedRowCount() - 1
    const focusedCell = gridApi.current.getFocusedCell()

    //Handle updates
    const rowsToUpdate = []
    let curRowIndex = focusedCell.rowIndex
    if (focusedCell?.rowPinned !== 'bottom') {
      for (let i = 0; i < data.length; i++) {
        if (curRowIndex > lastIndex) break
        if (!Array.isArray(data[i])) continue

        let costCode = cloneDeep(gridApi.current.getDisplayedRowAtIndex(curRowIndex))
        let currentColumn = focusedCell.column
        if (!currentColumn) continue

        for (let j = 0; j < data[i].length; j++) {
          costCode.data[currentColumn.colDef.field] = data[i][j]
          currentColumn = gridApi.current.getDisplayedColAfter(currentColumn)
        }

        costCode.data.value = parseFloat(costCode.data.value)
        rowsToUpdate.push(cloneDeep(costCode.data))
        curRowIndex++
      }
    }

    //Handle add new rows
    const rowsToAdd = []
    let startPasteIndex = focusedCell?.rowPinned === 'bottom' ? lastIndex + 1 : focusedCell.rowIndex
    if (startPasteIndex + data.length - 1 > lastIndex) {
      const resultLastIndex = startPasteIndex + (data.length - 1)
      const numRowsToAdd = resultLastIndex - lastIndex

      let index = data.length - 1
      for (let i = 0; i < numRowsToAdd; i++) {
        const row = data.slice(index, index + 1)[0]
        index--

        if (!Array.isArray(row)) continue
        const newCostCode = {}
        let currentColumn = focusedCell.column
        if (!currentColumn) continue

        for (let j = 0; j < row.length; j++) {
          newCostCode[currentColumn.colDef.field] = row[j]
          currentColumn = gridApi.current.getDisplayedColAfter(currentColumn)
        }

        newCostCode.value = parseFloat(newCostCode.value)
        rowsToAdd.push(newCostCode)
      }
    }

    handleUpdate(rowsToUpdate)
    handleAddMultipleRows(rowsToAdd.reverse())

    if (focusedCell?.rowPinned === 'bottom') {
      inputRow.current = {}
      if (gridApi.current) gridApi.current.setGridOption('pinnedBottomRowData', [inputRow.current])
    }

    return null
  }

  const handleImportOasisCostCodes = async (costCodes) => {
    if (!Array.isArray(costCodes)) return
    if (costCodes.length === 0) return

    let existingCodes = []
    gridApi.current.forEachNode((node) => {
      if (node.data) {
        existingCodes.push(node.data)
      }
    })

    let codesToUpdate = []
    let codesToAdd = []

    for (let i = 0; i < costCodes.length; i++) {
      if (costCodes[i].data.costCode === '') continue

      let codeExists = false
      for (let j = 0; j < existingCodes.length; j++) {
        if (costCodes[i].data.costCode === existingCodes[j].costCode) {
          codeExists = true

          existingCodes[j].value = costCodes[i].data.listPrice
          existingCodes[j].description = costCodes[i].data.description
          codesToUpdate.push(htmlSymbolHandling(existingCodes[j]))
          break
        }
      }

      if (!codeExists) {
        let newCode = {
          costCode: costCodes[i].data.costCode,
          description: costCodes[i].data.description,
          value: costCodes[i].data.listPrice,
          actualWell: wellName,
        }

        codesToAdd.push(htmlSymbolHandling(newCode))
      }
    }

    await handleUpdate(codesToUpdate)
    await handleAddMultipleRows(codesToAdd)

    if (refreshCosts) refreshCosts()
  }

  const handleImportSapCostCodes = async (costCodes) => {
    if (!Array.isArray(costCodes)) return
    if (costCodes.length === 0) return

    let existingCodes = []
    gridApi.current.forEachNode((node) => {
      if (node.data) {
        existingCodes.push(node.data)
      }
    })

    let codesToUpdate = []
    let codesToAdd = []

    for (let i = 0; i < costCodes.length; i++) {
      if (costCodes[i].data.LineItem === '') continue

      let codeExists = false
      for (let j = 0; j < existingCodes.length; j++) {
        if (costCodes[i].data.LineItem === existingCodes[j].costCode) {
          codeExists = true

          existingCodes[j].value = parseFloat(costCodes[i].data.UnitPrice)
          existingCodes[j].description = costCodes[i].data.MaterialDescription
          codesToUpdate.push(htmlSymbolHandling(existingCodes[j]))
          break
        }
      }

      if (!codeExists) {
        let newCode = {
          costCode: costCodes[i].data.LineItem,
          description: costCodes[i].data.MaterialDescription,
          value: parseFloat(costCodes[i].data.UnitPrice),
          actualWell: wellName,
        }

        codesToAdd.push(htmlSymbolHandling(newCode))
      }
    }

    await handleUpdate(codesToUpdate)
    await handleAddMultipleRows(codesToAdd)

    if (refreshCosts) refreshCosts()
  }

  const handleImportOracleCostCodes = async (costCodes) => {
    if (!Array.isArray(costCodes)) return
    if (costCodes.length === 0) return

    let existingCodes = []
    gridApi.current.forEachNode((node) => {
      if (node.data) {
        existingCodes.push(node.data)
      }
    })

    let codesToUpdate = []
    let codesToAdd = []

    for (let i = 0; i < costCodes.length; i++) {
      if (costCodes[i].data.E_TYPE === '') continue

      let codeExists = false
      for (let j = 0; j < existingCodes.length; j++) {
        if (costCodes[i].data.E_TYPE === existingCodes[j].costCode) {
          codeExists = true

          existingCodes[j].value = parseFloat(costCodes[i].data.RATE)
          existingCodes[j].description = costCodes[i].data.E_TYPE_DESCRIPTION
          codesToUpdate.push(htmlSymbolHandling(existingCodes[j]))
          break
        }
      }

      if (!codeExists) {
        let newCode = {
          costCode: costCodes[i].data.E_TYPE,
          description: costCodes[i].data.E_TYPE_DESCRIPTION,
          value: parseFloat(costCodes[i].data.RATE),
          actualWell: wellName,
        }

        codesToAdd.push(htmlSymbolHandling(newCode))
      }
    }

    await handleUpdate(codesToUpdate)
    await handleAddMultipleRows(codesToAdd)

    if (refreshCosts) refreshCosts()
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        height: '100%',
        width: `100%`,
      }}>
      <Box
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
          width: '100%',
        }}>
        <SearchBar
          value={searchText}
          onChange={(newSearchTerm) => setSearchText(newSearchTerm)}
          onCancelSearch={() => setSearchText('')}
          style={searchBarStyle()}
        />
      </Box>
      {showOasisModal ? (
        <OasisCostImportModal
          wellName={wellName}
          onClose={(data) => {
            setShowOasisModal(false)
            handleImportOasisCostCodes(data)
          }}
        />
      ) : null}
      {showSapModal ? (
        <SapCostImportModal
          wellName={wellName}
          onClose={(data) => {
            setShowSapModal(false)
            handleImportSapCostCodes(data)
          }}
        />
      ) : null}
      {showOracleModal ? (
        <OracleCostImportModal
          wellName={wellName}
          onClose={(data) => {
            setShowOracleModal(false)
            handleImportOracleCostCodes(data)
          }}
        />
      ) : null}
      <Box sx={{ display: 'flex', width: '100%', height: 'calc(100% - 25px)' }}>
        <div className={getAgGridTheme()} style={{ width: '100%', height: '100%' }}>
          <AgGridReact
            loading={isLoading}
            rowData={costCodeData}
            columnDefs={sortColDefs(columnDefs, 'costCodesGrid')}
            defaultColDef={defaultColDef}
            getRowId={getRowId}
            animateRows={true}
            rowSelection='multiple'
            enableRangeSelection='true'
            enableBrowserTooltips={true}
            gridOptions={gridOptions}
            headerHeight={30}
            onGridReady={onGridReady}
            onFirstDataRendered={onFirstDataRendered}
            getContextMenuItems={getContextMenuItems}
            processDataFromClipboard={processDataFromClipboard}
          />
        </div>
      </Box>
      <Box
        sx={{
          width: '100%',
          height: '25px',
          borderLeft: '1px solid gray',
          borderRight: '1px solid gray',
          backgroundColor: '#222628',
          color: 'white',
          paddingLeft: '10px',
        }}>
        {linkedBid ? 'Linked Bid: ' + linkedBid : null}
      </Box>
      <Box
        sx={{
          backgroundColor: 'transparent',
          margin: '4px',
          padding: '12px',
          position: 'fixed',
          bottom: '20px',
          right: '20px',
          zIndex: 2,
        }}>
        <MenuButton
          actions={[
            {
              icon: <RefreshIcon />,
              name: 'Refresh',
              onClick: () => {
                fetchData()
              },
            },
          ]}
        />
      </Box>
      {status?.show ? (
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={status?.show}
          autoHideDuration={2000}
          onClose={handleCloseStatus}>
          <Alert onClose={handleCloseStatus} severity={status.severity} elevation={4} variant='filled'>
            {status.message}
          </Alert>
        </Snackbar>
      ) : null}
    </Box>
  )
}

export default CostCodesGrid
