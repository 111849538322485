import React, { useRef, useEffect, useState, useMemo, useCallback, forwardRef, useImperativeHandle } from 'react'
import { currentPageAtom, actionBarWidthAtom, userUserRoleAtom, currentWellSelector } from 'atoms'
import { useSetRecoilState, useRecoilValue } from 'recoil'
import { PAGE_KEYS } from 'components/ActionBar/pageDefs'
import { Box, Snackbar } from '@mui/material'
import { AgGridReact } from 'ag-grid-react'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import SearchBar from 'components/common/SearchBar'
import { saveItemToLS } from 'utils/localStorage'
import { Icon as Iconify } from '@iconify/react'
import { appColors } from 'utils'
import Alert from '@mui/material/Alert'
import MenuButton from 'components/common/MenuButton'
import RefreshIcon from '@mui/icons-material/Refresh'
import { sortColDefs, htmlSymbolHandling, CustomLoadingOverlay, getStringId } from 'components/common/AgGridUtils'
import { unescapeHtml } from 'utils/htmlSymbolHandling'
import ConfirmDialog from 'components/common/ConfirmDialog'
import { checkPermission } from 'components/userPermissions'
import { cloneDeep } from 'lodash'
import { debounce } from 'lodash'
import PopupCellRenderer from 'components/WellPages/DailyReportsPages/PopupCellRenderer'
import { styled } from '@mui/styles'
import { getUserNameFromEmail } from 'utils/chatFunctions'
import useInnovaAuth from 'components/common/hooks/useInnovaAuth'
import WellSelectorEditor from 'components/common/CellEditors/WellSelectorEditor'
import RigStateThresholdsGrid from './RigStateThresholdsGrid'
import WitsmlConfigPane from './WITSML/WitsmlConfigPane'
import DataAckFileImportModal from './DataAckFileImportModal'
import useUnits from 'components/common/hooks/useUnits'
import { useNavigate } from 'react-router-dom'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const StyledIconContainer = styled(Box)({
  color: appColors.itemTextColor,
  width: '30px',
  height: '30px',
  margin: '5px',
  marginRight: '10px',
  textAlign: 'center',
  '&:hover': {
    cursor: 'pointer',
  },
})

const StyledMenuIcon = styled(Iconify)({
  color: appColors.itemTextColor,
  width: '30px',
  height: '30px',
})

const DataAcquisitionPage = () => {
  const setActivePage = useSetRecoilState(currentPageAtom)
  const leftPos = useRecoilValue(actionBarWidthAtom)
  const _isMounted = useRef(false)
  const gridApi = useRef(null)
  const isAdding = useRef(false)
  const isUpdating = useRef(false)
  const isTesting = useRef(false)
  const isExporting = useRef(false)
  const isDeleting = useRef(false)
  const isClearing = useRef(false)
  const isReProcessing = useRef(false)
  const [showFileUpload, setShowFileUpload] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [dataConfigs, setDataConfigs] = useState([])
  const [searchText, setSearchText] = useState('')
  const inputRow = useRef({})
  const selectedConfig = useRef(null)
  const detailGridTypeChanged = useRef(false)
  const userRole = useRecoilValue(userUserRoleAtom)
  const [dropDownLists, setDropDownLists] = useState({})
  const [resetCols, setResetCols] = useState(false)
  const { user } = useInnovaAuth()
  const [confirm, setConfirm] = useState({ show: false, title: '' })
  const [status, setStatus] = useState({ show: false, severity: 'info', message: '' })
  const containerRef = useRef(null)
  const [detailGridType, setDetailGridType] = useState({ data: null, type: null })
  const { unitList } = useUnits()
  const navigate = useNavigate()
  const setCurrentWell = useSetRecoilState(currentWellSelector)
  const { getAgGridTheme, searchBarStyle } = useInnovaTheme()

  const getDataConfigs = useInnovaAxios({
    url: '/dataAcq/getConfigs',
  })

  const deleteDataConfig = useInnovaAxios({
    url: '/dataAcq/deleteConfig',
  })

  const addDataConfig = useInnovaAxios({
    url: '/dataAcq/addConfigs',
  })

  const duplicateDataConfig = useInnovaAxios({
    url: '/dataAcq/duplicateConfig',
  })

  const updateDataConfig = useInnovaAxios({
    url: '/dataAcq/updateConfigs',
  })

  const stopRead = useInnovaAxios({
    url: '/dataAcq/stopRead',
  })

  const startRead = useInnovaAxios({
    url: '/dataAcq/startRead',
  })

  const exportData = useInnovaAxios({
    url: '/dataAcq/downloadData',
  })

  const getDropDownData = useInnovaAxios({
    url: '/dataAcq/getDropDownLists',
  })

  const updateCrvMap = useInnovaAxios({
    url: '/dataAcq/witsml/updateWitsmlCurveMapping',
  })

  const updateWitsmlSelection = useInnovaAxios({
    url: '/dataAcq/witsml/updateWitsmlSelection',
  })

  const testWitsmlConnection = useInnovaAxios({
    url: '/dataAcq/witsml/testConnection',
  })

  const testDataStoreConnection = useInnovaAxios({
    url: '/dataAcq/dataStore/testConnection',
  })

  const deleteAllData = useInnovaAxios({
    url: '/dataAcq/clearDataTables',
  })

  const reprocessData = useInnovaAxios({
    url: '/dataAcq/proc/reprocessWell',
  })

  const getWitsmlServerParams = useCallback(
    (data) => {
      if (!data) return null
      if (data.dataInput !== 2) return null
      if (!Array.isArray(dropDownLists.witsmlServers)) return null
      let server = dropDownLists.witsmlServers.find((s) => s.uid === data.witsmlServer)
      if (!server) return null
      return server
    },
    [dropDownLists],
  )

  const handleTestWitsmlConnection = useCallback(
    async (data, showMessage) => {
      if (isTesting.current) return false
      if (!data) return false

      isTesting.current = true
      let res = await testWitsmlConnection(getWitsmlServerParams(data))
      isTesting.current = false

      if (res?.error) {
        if (showMessage) {
          setStatus({
            show: true,
            severity: 'error',
            message: `${res?.error?.response?.data?.error}`,
          })
        }

        return false
      }

      if (!res?.data?.data?.success || res?.data?.data?.witsmlVer === '') {
        if (showMessage) {
          setStatus({
            show: true,
            severity: 'error',
            message: `Server failed to respond`,
          })
        }

        return false
      }

      if (showMessage) {
        setStatus({
          show: true,
          severity: 'success',
          message: `Success - server responded v${res?.data?.data?.witsmlVer}`,
        })
      }

      return true
    },
    [testWitsmlConnection, getWitsmlServerParams],
  )

  const handleTestDataStoreConnection = useCallback(
    async (data, showMessage) => {
      if (data?.dataStore < 0) return true
      isTesting.current = true
      const res = await testDataStoreConnection({
        uid: data?.dataStore,
      })
      isTesting.current = false

      if (!res.error) {
        if (showMessage) setStatus({ show: true, severity: 'success', message: 'Data store connection OK' })
        return true
      }

      if (showMessage) {
        setStatus({
          show: true,
          severity: 'error',
          message: `Data store connection failed - ${res?.error?.response?.data?.error}`,
        })
      }

      return false
    },
    [testDataStoreConnection],
  )

  useEffect(() => {
    _isMounted.current = true
    setActivePage(PAGE_KEYS.dataAcquisitionKey)
    fetchConfigs()
    fetchDropDowns()
    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const debounceSetQuickFilter = debounce((text) => {
    if (gridApi.current) {
      gridApi.current.setGridOption('quickFilterText', text)
    }
  }, 300)

  useEffect(() => {
    debounceSetQuickFilter(searchText)
  }, [searchText]) // eslint-disable-line react-hooks/exhaustive-deps

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.uid)
    }
  }, [])

  const fetchConfigs = async () => {
    if (isLoading) return
    setLoading(true)
    const response = await getDataConfigs()

    if (response?.error) return
    if (!_isMounted.current) return

    setDataConfigs(Array.isArray(response.data) ? response.data : [])
    setLoading(false)
  }

  const fetchDropDowns = async () => {
    const response = await getDropDownData()

    if (response?.error) return
    if (!_isMounted.current) return

    setDropDownLists(response.data ? response.data : {})
  }

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const handleUpdateWitsmlSelection = async (newSelection) => {
    if (!newSelection) return
    if (isUpdating.current) return
    if (!_isMounted.current) return

    isUpdating.current = true
    let res = await updateWitsmlSelection(newSelection)
    isUpdating.current = false

    if (!_isMounted.current) return

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })
      return
    }

    selectedConfig.current = cloneDeep(res.data)

    if (gridApi.current) {
      gridApi.current.applyTransaction({
        update: [res.data],
      })
    }
  }

  const handleUpdateWitsmlCurveMapping = async (newMapping) => {
    if (!newMapping) return
    if (isUpdating.current) return
    if (!_isMounted.current) return

    isUpdating.current = true
    let res = await updateCrvMap(newMapping)
    isUpdating.current = false

    if (!_isMounted.current) return

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })
      return
    }

    selectedConfig.current = cloneDeep(res.data)

    if (gridApi.current) {
      gridApi.current.applyTransaction({
        update: [res.data],
      })
    }
  }

  const gridOptions = {
    pinnedBottomRowData: [inputRow.current],
    onCellEditingStopped: (event) => {
      if (event.node?.rowPinned === 'bottom') {
        handleAddRow()
        return
      }

      handleUpdate(event.data)
      autoSizeColumns()
    },
    getRowStyle: ({ node }) => (node?.rowPinned ? { fontWeight: 'bold', fontStyle: 'italic' } : 0),
    suppressRowClickSelection: true,
    sideBar: {
      toolPanels: [
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        },
      ],
      defaultToolPanel: '',
      position: 'left',
    },
    onDragStopped: () => {
      saveColumnState()
    },
    onColumnVisible: () => {
      saveColumnState()
    },
    loadingOverlayComponent: CustomLoadingOverlay,
  }

  let reqFields = useMemo(() => ['actualWell'], [])

  const createPinnedCellPlaceholder = useCallback(
    ({ colDef }) => {
      if (reqFields.findIndex((field) => field === colDef.field) < 0) return ''
      return colDef.field[0].toUpperCase() + colDef.field.slice(1) + '...'
    },
    [reqFields],
  )

  function isEmptyPinnedCell({ node, value }) {
    return (node?.rowPinned === 'bottom' && value == null) || (node?.rowPinned === 'bottom' && value === '')
  }

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: true,
      editable: true,
      autoHeight: true,
      headerClass: 'header-no-padding',
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
      valueFormatter: (params) => (isEmptyPinnedCell(params) ? createPinnedCellPlaceholder(params) : undefined),
    }
  }, [createPinnedCellPlaceholder])

  const onFirstDataRendered = (params) => {
    if (gridApi.current) {
      gridApi.current.onFilterChanged()
    }

    autoSizeColumns()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const saveColumnState = () => {
    if (!gridApi.current) return

    const colLayout = gridApi.current.getColumnState()
    if (colLayout) saveItemToLS('dataAckConfigGrid', 'colLayout', colLayout)
  }

  const getContextMenuItems = (params) => {
    return [
      {
        name: 'Reset columns',
        disabled: false,
        action: () => {
          gridApi.current.resetColumnState()
          saveItemToLS('dataAckConfigGrid', 'colLayout', null)
          setResetCols(!resetCols)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style = "color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Reset filters',
        disabled: false,
        action: () => {
          gridApi.current.setFilterModel(null)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style = "color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      'copy',
      {
        name: 'Export',
        disabled: false,
        action: () => {
          gridApi.current.exportDataAsExcel({
            fileName: 'DataAckConfigs.xlsx',
            sheetName: 'Configs',
          })
        },
        icon: '<span class="iconify" data-icon="icomoon-free:file-excel" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
    ]
  }

  const isPinnedRowDataCompleted = useCallback(() => {
    for (let i = 0; i < reqFields.length; i++) {
      if (!inputRow.current.hasOwnProperty(reqFields[i])) return false
    }

    return true
  }, [reqFields])

  const handleAddRow = async () => {
    if (isAdding.current) return
    if (!isPinnedRowDataCompleted()) return
    isAdding.current = true
    inputRow.current = htmlSymbolHandling(inputRow.current)

    let res = await addDataConfig(inputRow.current)
    isAdding.current = false

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })
      return
    }

    if (!res?.error) {
      inputRow.current = res.data
      gridApi.current.applyTransaction({
        add: [inputRow.current],
      })
    }

    inputRow.current = {}
    if (gridApi.current) {
      gridApi.current.setGridOption('pinnedBottomRowData', [inputRow.current])
    }
  }

  const handleUpdate = useCallback(
    async (data) => {
      if (!data) return
      if (!data.hasOwnProperty('uid')) return

      if (isUpdating.current) return
      data = htmlSymbolHandling(data)

      isUpdating.current = true
      let res = await updateDataConfig({
        uid: data.uid,
        actualWell: data.actualWell,
        dataInput: data.dataInput,
        dataStore: data.dataStore,
        witsmlServer: data.witsmlServer,
        readInterval: data?.readInterval,
        rpm: data.drillingThresholds.rpm,
        pressure: data.drillingThresholds.pressure,
        flow: data.drillingThresholds.flow,
        hkld: data.drillingThresholds.hkld,
        onBtmDelta: data.drillingThresholds.onBtmDelta,
        wob: data.drillingThresholds.wob,
        rpmBufferSize: data.drillingThresholds.rpmBufferSize,
        rpmBufferThreshold: data.drillingThresholds.rpmBufferThreshold,
        dynamicThresholds: data.drillingThresholds.dynamicThreshold,
        autoSlideSheet: data.autoSlideSheet,
        unitPrefs: data.unitPrefs,
      })

      isUpdating.current = false

      if (res?.error) {
        setStatus({
          show: true,
          severity: 'error',
          message: `${res?.error?.response?.data?.error}`,
        })
        return
      }

      if (!res?.error) {
        gridApi.current.applyTransaction({
          update: [data],
        })
      }
    },
    [updateDataConfig],
  )

  const handleDuplicateConfig = useCallback(
    async (data) => {
      if (!data) return
      if (!data.hasOwnProperty('uid')) return

      if (isUpdating.current) return
      isUpdating.current = true

      let res = await duplicateDataConfig(data)
      isUpdating.current = false

      if (res?.error) {
        setStatus({
          show: true,
          severity: 'error',
          message: `${res?.error?.response?.data?.error}`,
        })
        return
      }

      if (!res?.error) {
        gridApi.current.applyTransaction({
          add: [res.data],
        })
      }
    },
    [duplicateDataConfig],
  )

  const handleDelete = useCallback(async () => {
    if (!selectedConfig.current) return
    if (!selectedConfig.current.hasOwnProperty('uid')) return

    if (isDeleting.current) return
    isDeleting.current = true

    let res = await deleteDataConfig(selectedConfig.current)
    isDeleting.current = false

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })
      return
    }

    if (!res?.error) {
      gridApi.current.applyTransaction({
        remove: [selectedConfig.current],
      })
    }

    selectedConfig.current = null
  }, [deleteDataConfig])

  const handleClearData = useCallback(async () => {
    if (!selectedConfig.current) return
    if (!selectedConfig.current.hasOwnProperty('uid')) return

    if (isClearing.current) return
    isClearing.current = true

    let res = await deleteAllData({
      orgName: selectedConfig.current.organization,
      wellName: selectedConfig.current.actualWell,
    })
    isClearing.current = false

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })
      return
    }

    setStatus({
      show: true,
      severity: 'success',
      message: `Data cleared`,
    })

    selectedConfig.current = null
  }, [deleteAllData])

  const onClickDelete = useCallback(
    (data) => {
      if (!checkPermission('canDelete', userRole.roleAttributes?.permissions)) return
      selectedConfig.current = cloneDeep(data)

      setConfirm({
        show: true,
        title: 'Delete data config',
        text: `Are you sure you want to delete config for ${data.actualWell}?`,
        onConfirm: handleDelete,
      })
    },
    [handleDelete, userRole],
  )

  const onClickClearData = useCallback(
    (data) => {
      if (!checkPermission('canDelete', userRole.roleAttributes?.permissions)) return
      selectedConfig.current = cloneDeep(data)

      setConfirm({
        show: true,
        title: 'Clear Data',
        text: `Are you sure you want to delete all data for ${data.actualWell}?`,
        onConfirm: handleClearData,
      })
    },
    [handleClearData, userRole],
  )

  const centerAlignCell = useMemo(
    () => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }),
    [],
  )

  const handleToggleRead = useCallback(
    async (data) => {
      if (!data) return
      if (!_isMounted.current) return
      if (isUpdating.current) return

      if (data?.readStatus === 'STOPPED') {
        let dataStoreOk = await handleTestDataStoreConnection(data, false)
        if (!dataStoreOk) {
          setStatus({
            show: true,
            severity: 'error',
            message: `Data store connection failed`,
          })
          return
        }

        if (data?.dataInput === 2) {
          let witsmlOk = await handleTestWitsmlConnection(data, false)
          if (!witsmlOk) {
            setStatus({
              show: true,
              severity: 'error',
              message: `WITSML connection failed`,
            })
            return
          }
        }
      }

      setLoading(true)
      isUpdating.current = true
      let res = data?.readStatus === 'STOPPED' ? await startRead(data) : await stopRead(data)
      isUpdating.current = false

      if (!_isMounted.current) return
      setLoading(false)

      if (res?.error) {
        setStatus({
          show: true,
          severity: 'error',
          message: `${res?.error?.response?.data?.error}`,
        })
        return
      }

      setStatus({
        show: true,
        severity: 'success',
        message: `Data config for ${data.actualWell} ${data?.readStatus === 'STOPPED' ? 'started' : 'stopped'}`,
      })

      data.readStatus = data?.readStatus === 'STOPPED' ? 'RUNNING' : 'STOPPED'
      data.startedBy = user.name
      if (gridApi.current) {
        gridApi.current.applyTransaction({
          update: [data],
        })
      }
    },
    [stopRead, startRead, user, handleTestWitsmlConnection, handleTestDataStoreConnection],
  )

  const showDetailGrid = useCallback(
    (data, type) => {
      if (!gridApi.current) return

      let shouldRefresh = false
      gridApi.current.forEachNode((node) => {
        if (node.data.uid !== data.uid) gridApi.current.setRowNodeExpanded(node, false)

        if (node.data.uid === data.uid) {
          let shouldExpand = detailGridType.data?.uid !== data.uid || detailGridType.type !== type

          selectedConfig.current = cloneDeep(data)
          if (node.expanded && detailGridType.type !== type) {
            detailGridTypeChanged.current = true
          }

          setDetailGridType({ data: shouldExpand ? data : null, type: shouldExpand ? type : null })

          gridApi.current.setRowNodeExpanded(node, shouldExpand)
          if (shouldExpand) shouldRefresh = true
        }
      })

      if (shouldRefresh) gridApi.current.refreshCells()
    },
    [detailGridType],
  )

  const handleExport = useCallback(
    async (data) => {
      if (isExporting.current) return false
      if (!data) return false

      isExporting.current = true
      let res = await exportData(data)
      isExporting.current = false

      if (res?.error) {
        setStatus({
          show: true,
          severity: 'error',
          message: `${res?.error?.response?.data?.error}`,
        })

        return
      }

      if (res?.data) {
        if (res?.data.status === 'OK') {
          setStatus({
            show: true,
            severity: 'success',
            message: res.data.message,
          })
        } else {
          setStatus({
            show: true,
            severity: 'error',
            message: res.data.message,
          })
        }

        return false
      }

      return true
    },
    [exportData],
  )

  const handleNavigateToWell = useCallback(
    (data) => {
      if (!data) return
      setCurrentWell(data.actualWell)
      navigate('/edr')
    },
    [setCurrentWell, navigate],
  )

  const handleReProcess = useCallback(
    async (data) => {
      if (isReProcessing.current) return
      if (!data) return

      isReProcessing.current = true
      let res = await reprocessData({ orgName: data.organization, wellName: data.actualWell })
      isReProcessing.current = false

      if (res?.error) {
        setStatus({
          show: true,
          severity: 'error',
          message: `${res?.error?.response?.data?.error}`,
        })

        return
      }

      setStatus({
        show: true,
        severity: 'success',
        message: `Reprocessing started an email will be sent when complete`,
      })
    },
    [reprocessData],
  )

  const handleMenuClick = useCallback(
    (action, data) => {
      if (typeof action !== 'string') return
      if (!data) return

      if (action === 'delete') {
        onClickDelete(data)
      }

      if (action === 'stopStart') {
        handleToggleRead(data)
      }

      if (action === 'thresholds') {
        showDetailGrid(data, 'thresholds')
      }

      if (action === 'witsml') {
        showDetailGrid(data, 'witsml')
      }

      if (action === 'testWitsml') {
        handleTestWitsmlConnection(data, true)
      }

      if (action === 'testDataStore') {
        handleTestDataStoreConnection(data, true)
      }

      if (action === 'duplicate') {
        handleDuplicateConfig(data)
      }

      if (action === 'upload') {
        selectedConfig.current = cloneDeep(data)
        setShowFileUpload(true)
      }

      if (action === 'export') {
        selectedConfig.current = cloneDeep(data)
        handleExport(data)
      }

      if (action === 'clearData') {
        onClickClearData(data)
      }

      if (action === 'reProcess') {
        handleReProcess(data)
      }

      if (action === 'gotoWell') {
        handleNavigateToWell(data)
      }
    },
    [
      handleReProcess,
      handleToggleRead,
      onClickDelete,
      onClickClearData,
      showDetailGrid,
      handleTestWitsmlConnection,
      handleTestDataStoreConnection,
      setShowFileUpload,
      handleDuplicateConfig,
      handleExport,
      handleNavigateToWell,
    ],
  )

  let columnDefs = useMemo(
    () => [
      {
        headerName: '',
        colId: 'actions',
        cellStyle: centerAlignCell,
        cellRenderer: PopupCellRenderer,
        cellRendererParams: (params) => {
          let menuItems = [
            {
              label: 'Duplicate',
              action: 'duplicate',
              onClick: handleMenuClick,
              icon: () => (
                <StyledIconContainer>
                  <StyledMenuIcon icon='grommet-icons:copy' />
                </StyledIconContainer>
              ),
            },
            {
              label: params?.data?.readStatus === 'STOPPED' ? 'Start reading' : 'Stop reading',
              action: 'stopStart',
              onClick: handleMenuClick,
              icon: () => (
                <StyledIconContainer>
                  <StyledMenuIcon
                    icon={
                      params?.data?.readStatus === 'STOPPED' ? 'ant-design:play-circle-filled' : 'bi:stop-circle-fill'
                    }
                    color={params?.data?.readStatus === 'STOPPED' ? '#00C000' : '#C00000'}
                  />
                </StyledIconContainer>
              ),
            },
            {
              label: 'Rig state thresholds',
              action: 'thresholds',
              onClick: handleMenuClick,
              icon: () => (
                <StyledIconContainer>
                  <StyledMenuIcon icon={'game-icons:oil-rig'} />
                </StyledIconContainer>
              ),
            },
            {
              label: 'Export raw data',
              action: 'export',
              onClick: handleMenuClick,
              icon: () => (
                <StyledIconContainer>
                  <StyledMenuIcon icon={'uil:file-export'} />
                </StyledIconContainer>
              ),
            },
            {
              label: 'Upload file',
              action: 'upload',
              onClick: handleMenuClick,
              icon: () => (
                <StyledIconContainer>
                  <StyledMenuIcon icon={'material-symbols:upload-file-outline'} />
                </StyledIconContainer>
              ),
            },
            {
              label: 'Re-Process Data',
              action: 'reProcess',
              onClick: handleMenuClick,
              icon: () => (
                <StyledIconContainer>
                  <StyledMenuIcon icon={'uil:process'} />
                </StyledIconContainer>
              ),
            },
            {
              label: 'Goto EDR',
              action: 'gotoWell',
              onClick: handleMenuClick,
              icon: () => (
                <StyledIconContainer>
                  <StyledMenuIcon icon={'uil:chart'} />
                </StyledIconContainer>
              ),
            },
          ]

          if (userRole?.innovaAdmin) {
            menuItems.push({
              label: 'Clear Data',
              action: 'clearData',
              onClick: handleMenuClick,
              icon: () => (
                <StyledIconContainer>
                  <StyledMenuIcon icon={'ic:outline-warning'} color={'red'} />
                </StyledIconContainer>
              ),
            })
          }

          if (params?.data.readStatus === 'STOPPED') {
            menuItems.push({
              label: 'Delete & Archive',
              action: 'delete',
              onClick: handleMenuClick,
              icon: () => (
                <StyledIconContainer>
                  <StyledMenuIcon icon='fa-regular:trash-alt' color={'red'} />
                </StyledIconContainer>
              ),
            })
          }

          if (params?.data?.dataStore >= 0) {
            menuItems.push({
              label: 'Test data store connection',
              action: 'testDataStore',
              onClick: handleMenuClick,
              icon: () => (
                <StyledIconContainer>
                  <StyledMenuIcon icon={'carbon:cics-db2-connection'} />
                </StyledIconContainer>
              ),
            })
          }

          if (params?.data.dataInput === 2) {
            menuItems.push({
              label: 'WITSML Config',
              action: 'witsml',
              onClick: handleMenuClick,
              icon: () => (
                <StyledIconContainer>
                  <StyledMenuIcon icon={'tabler:network'} />
                </StyledIconContainer>
              ),
            })

            menuItems.push({
              label: 'Test WITSML Connection',
              action: 'testWitsml',
              onClick: handleMenuClick,
              icon: () => (
                <StyledIconContainer>
                  <StyledMenuIcon icon={'icomoon-free:connection'} />
                </StyledIconContainer>
              ),
            })
          }

          return {
            menuItems: menuItems,
          }
        },
        pinned: 'left',
        lockPosition: 'left',
        editable: false,
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
        resizable: false,
        filter: null,
        width: 70,
      },
      {
        field: 'organization',
        colId: 'organization',
        headerName: 'Organization',
        pinned: 'left',
        lockPosition: 'left',
        editable: false,
      },
      {
        field: 'actualWell',
        colId: 'actualWell',
        headerName: 'Well',
        editable: (params) => params?.data?.readStatus !== 'RUNNING',
        pinned: 'left',
        lockPosition: 'left',
        cellEditor: WellSelectorEditor,
        cellEditorPopup: true,
        cellEditorParams: (params) => {
          let existingWells = []
          gridApi.current.forEachNode((rowNode) => {
            if (rowNode?.data?.actualWell && rowNode?.data?.uid !== params?.data?.uid) {
              existingWells.push(rowNode.data.actualWell)
            }
          })

          let wellList = Array.isArray(dropDownLists.wellNames) ? dropDownLists.wellNames : []
          return {
            wellList: wellList.filter((well) => !existingWells.includes(well.actualWell)),
          }
        },
      },
      {
        field: 'readStatus',
        colId: 'readStatus',
        headerName: 'Status',
        pinned: 'left',
        lockPosition: 'left',
        editable: false,
        cellStyle: (params) => {
          return { ...centerAlignCell, color: params?.data?.readStatus === 'STOPPED' ? 'red' : 'green' }
        },
      },
      {
        field: 'readInterval',
        colId: 'readInterval',
        headerName: 'Update Rate(s)',
        editable: (params) => params?.node?.rowPinned !== 'bottom',
        cellStyle: centerAlignCell,
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 1,
          max: 300,
          precision: 0,
        },
      },
      {
        field: 'dataInput',
        colId: 'dataInput',
        headerName: 'Data Source',
        editable: (params) => params?.node?.rowPinned !== 'bottom',
        cellStyle: centerAlignCell,
        valueGetter: (params) => {
          if (params?.node?.rowPinned === 'bottom') return ''
          if (!dropDownLists) return ''
          if (!Array.isArray(dropDownLists?.dataSources)) return ''
          if (params?.data?.dataInput < 0) return ''
          if (params?.data?.dataInput >= dropDownLists.dataSources.length) return ''
          return dropDownLists.dataSources[params?.data?.dataInput]
        },
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: (params) => {
          return {
            values: Array.isArray(dropDownLists?.dataSources) ? dropDownLists.dataSources : [],
          }
        },
        valueSetter: (params) => {
          if (!params.newValue) return false
          if (typeof params.newValue !== 'string') return false
          if (!Array.isArray(dropDownLists?.dataSources)) return false
          let index = dropDownLists.dataSources.findIndex((v) => v === params.newValue)
          if (index < 0) return false

          let data = cloneDeep(params.data)
          data.dataInput = index
          handleUpdate(data)
          return true
        },
      },
      {
        field: 'dataStore',
        colId: 'dataStore',
        headerName: 'Data Store',
        editable: (params) => params?.node?.rowPinned !== 'bottom',
        cellStyle: centerAlignCell,
        valueGetter: (params) => {
          if (params?.node?.rowPinned === 'bottom') return ''
          if (params?.data?.dataStore === -1) return 'Default'
          if (!dropDownLists) return ''
          if (!Array.isArray(dropDownLists?.dataStores)) return ''
          let index = dropDownLists.dataStores.findIndex((v) => v.uid === params?.data?.dataStore)
          if (index < 0) return ''
          return unescapeHtml(dropDownLists.dataStores[index].storeName)
        },
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: (params) => {
          let dropDowns = ['Default']
          if (Array.isArray(dropDownLists?.dataStores)) {
            for (let i = 0; i < dropDownLists.dataStores.length; i++) {
              dropDowns.push(
                `#${dropDownLists.dataStores[i].uid} - ${unescapeHtml(dropDownLists.dataStores[i].storeName)}`,
              )
            }
          }

          return {
            values: dropDowns,
          }
        },
        valueSetter: (params) => {
          if (!params.newValue) return false
          if (typeof params.newValue !== 'string') return false

          let data = cloneDeep(params.data)
          if (params.newValue === 'Default') {
            data.dataStore = -1
            handleUpdate(data)
            return true
          }

          if (!Array.isArray(dropDownLists?.dataStores)) return false
          let index = dropDownLists.dataStores.findIndex(
            (s) => `#${s.uid} - ${unescapeHtml(s.storeName)}` === params.newValue,
          )

          if (index < 0) return false
          data.dataStore = dropDownLists.dataStores[index].uid

          handleUpdate(data)
          return true
        },
      },
      {
        field: 'witsmlServer',
        colId: 'witsmlServer',
        headerName: 'WITSML Server',
        editable: (params) => params?.data?.dataInput === 2 && params?.node?.rowPinned !== 'bottom',
        valueGetter: (params) => {
          if (params?.node?.rowPinned === 'bottom') return ''
          if (params?.data?.dataInput !== 2) return ''
          if (!dropDownLists) return ''
          if (!Array.isArray(dropDownLists?.witsmlServers)) return ''
          let index = dropDownLists.witsmlServers.findIndex((v) => v.uid === params?.data?.witsmlServer)
          if (index < 0) return ''
          return unescapeHtml(dropDownLists.witsmlServers[index].serverName)
        },
        cellStyle: centerAlignCell,
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: (params) => {
          let dropDowns = []
          if (Array.isArray(dropDownLists?.witsmlServers)) {
            for (let i = 0; i < dropDownLists.witsmlServers.length; i++) {
              dropDowns.push(
                `#${dropDownLists.witsmlServers[i].uid} - ${unescapeHtml(dropDownLists.witsmlServers[i].serverName)}`,
              )
            }
          }

          return {
            values: dropDowns,
          }
        },
        valueSetter: (params) => {
          if (!params.newValue) return false
          if (typeof params.newValue !== 'string') return false

          let data = cloneDeep(params.data)

          if (!Array.isArray(dropDownLists?.witsmlServers)) return false
          let index = dropDownLists.witsmlServers.findIndex(
            (s) => `#${s.uid} - ${unescapeHtml(s.serverName)}` === params.newValue,
          )

          if (index < 0) return false
          data.witsmlServer = dropDownLists.witsmlServers[index].uid
          handleUpdate(data)
          return true
        },
      },
      {
        field: 'tableName',
        colId: 'tableName',
        headerName: 'Data Table',
        editable: false,
      },
      {
        field: 'startedBy',
        colId: 'startedBy',
        headerName: 'Started By',
        editable: false,
        cellStyle: centerAlignCell,
        valueFormatter: (params) => {
          if (params?.data?.readStatus === 'STOPPED') return ''
          return getUserNameFromEmail(params?.data?.startedBy)
        },
      },
      {
        field: 'idleCounter',
        colId: 'idleCounter',
        headerName: 'Idle (mins)',
        editable: false,
        cellStyle: centerAlignCell,
        valueFormatter: (params) => {
          if (!params?.data?.idleCounter) return '-'
          return Math.ceil(params?.data?.idleCounter / 60)
        },
      },
      {
        colId: 'dynamicThreshold',
        headerName: 'Dynamic Thresholds',
        editable: (params) => params?.node?.rowPinned !== 'bottom',
        cellStyle: centerAlignCell,
        cellEditor: 'agCheckboxCellEditor',
        cellRenderer: 'agCheckboxCellRenderer',
        valueGetter: (params) => {
          if (params?.node?.rowPinned === 'bottom') return ''
          if (!params?.data?.drillingThresholds) return false

          return params.data?.drillingThresholds?.dynamicThreshold
        },
        valueSetter: (params) => {
          let data = cloneDeep(params.data)
          data.drillingThresholds.dynamicThreshold = params.newValue

          handleUpdate(data)
          return true
        },
      },
      {
        colId: 'autoSlideSheet',
        headerName: 'Auto Slidesheets',
        editable: (params) => params?.node?.rowPinned !== 'bottom',
        cellStyle: centerAlignCell,
        cellEditor: 'agCheckboxCellEditor',
        cellRenderer: 'agCheckboxCellRenderer',
        valueGetter: (params) => {
          if (params?.node?.rowPinned === 'bottom') return ''
          if (!params?.data.autoSlideSheet) return false

          return params.data?.autoSlideSheet
        },
        valueSetter: (params) => {
          let data = cloneDeep(params.data)
          data.autoSlideSheet = params.newValue

          handleUpdate(data)
          return true
        },
      },
      {
        field: 'unitPrefs',
        colId: 'unitPrefs',
        headerName: 'Units',
        cellStyle: centerAlignCell,
        editable: true,
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: (params) => {
          let org = params?.node?.rowPinned === 'bottom' ? inputRow.current.organization : params.data?.organization
          let values = []
          if (Array.isArray(unitList)) {
            unitList.forEach((unit) => {
              if (unit.organization === org) values.push(unit.label)
            })
          }

          return {
            values: values,
          }
        },
      },
    ],
    [centerAlignCell, handleMenuClick, handleUpdate, userRole, dropDownLists, unitList],
  )

  const handleCloseStatus = () => {
    setStatus({ show: false, severity: 'info', message: '' })
  }

  const DetailCellRenderer = forwardRef((params, ref) => {
    useImperativeHandle(ref, () => {
      return {
        refresh() {
          if (!detailGridTypeChanged.current) return true
          detailGridTypeChanged.current = false
          return false
        },
      }
    })

    let height = 200
    if (containerRef.current && detailGridType.type === 'witsml') {
      height = containerRef.current.clientHeight * 0.65
    }

    return (
      <Box sx={{ height: height }}>
        {detailGridType.type === 'thresholds' ? (
          <RigStateThresholdsGrid data={selectedConfig.current} handleUpdateParentGrid={handleUpdate} />
        ) : null}
        {detailGridType.type === 'witsml' ? (
          <WitsmlConfigPane
            witsmlServer={getWitsmlServerParams(selectedConfig.current)}
            dataConfig={selectedConfig.current}
            updateCurveMapping={handleUpdateWitsmlCurveMapping}
            updateWitsmlSelection={handleUpdateWitsmlSelection}
          />
        ) : null}
      </Box>
    )
  })

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItmes: 'center',
        flexDirection: 'column',
        height: 'calc(100vh - 64px)',
        marginLeft: `${leftPos}px`,
        width: `calc(100%-${leftPos}px`,
        maxWidth: `calc(100%-${leftPos}px)`,
        maxHeight: `calc(100vh - 64px)`,
      }}>
      {showFileUpload ? (
        <DataAckFileImportModal selectedConfig={selectedConfig.current} onClose={() => setShowFileUpload(false)} />
      ) : null}
      {confirm.show ? (
        <ConfirmDialog
          title={confirm?.title}
          open={confirm?.show}
          setOpen={() => setConfirm({ show: false })}
          onConfirm={confirm.onConfirm}>
          {confirm?.text}
        </ConfirmDialog>
      ) : null}
      <Box
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
          width: '100%',
        }}>
        <SearchBar
          value={searchText}
          onChange={(newSearchTerm) => setSearchText(newSearchTerm)}
          onCancelSearch={() => setSearchText('')}
          style={searchBarStyle()}
        />
      </Box>
      <div ref={containerRef} className={getAgGridTheme()} style={{ width: '100%', height: '100%' }}>
        <AgGridReact
          loading={isLoading}
          rowData={dataConfigs}
          columnDefs={sortColDefs(columnDefs, 'dataAckConfigGrid')}
          defaultColDef={defaultColDef}
          getRowId={getRowId}
          animateRows={true}
          enableBrowserTooltips={true}
          gridOptions={gridOptions}
          headerHeight={30}
          onGridReady={onGridReady}
          onFirstDataRendered={onFirstDataRendered}
          getContextMenuItems={getContextMenuItems}
          masterDetail={true}
          detailCellRenderer={DetailCellRenderer}
          detailRowAutoHeight={true}
        />
      </div>
      <Box
        sx={{
          backgroundColor: 'transparent',
          margin: '4px',
          padding: '12px',
          position: 'fixed',
          bottom: '20px',
          right: '20px',
          zIndex: 2,
        }}>
        <MenuButton
          actions={[
            {
              icon: <RefreshIcon />,
              name: 'Refresh',
              onClick: () => {
                fetchConfigs()
              },
            },
          ]}
        />
      </Box>
      {status?.show ? (
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={status?.show}
          autoHideDuration={2000}
          onClose={handleCloseStatus}>
          <Alert onClose={handleCloseStatus} severity={status.severity} elevation={4} variant='filled'>
            {status.message}
          </Alert>
        </Snackbar>
      ) : null}
    </Box>
  )
}

export default DataAcquisitionPage
