import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef } from 'react'
import { Line } from 'react-chartjs-2'
import { cloneDeep, merge } from 'lodash'
import { useResizeDetector } from 'react-resize-detector'

import { getChartData } from 'components/WellPages/CommonChartDef/ChartDefs'
import useUnits, { UNITS_FOR } from 'components/common/hooks/useUnits'
import { CHART_TYPES as chartTypes } from '../elementDefs'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

// draggability for chartjs-plugin-annotation:
let element
let lastEvent

const drag = function (moveX, moveY) {
  element.x += moveX
  element.y += moveY
  element.x2 += moveX
  element.y2 += moveY
  element.centerX += moveX
  element.centerY += moveY
  if (element.elements && element.elements.length) {
    for (const subEl of element.elements) {
      subEl.x += moveX
      subEl.y += moveY
      subEl.x2 += moveX
      subEl.y2 += moveY
      subEl.centerX += moveX
      subEl.centerY += moveY
      subEl.bX += moveX
      subEl.bY += moveY
    }
  }
}

const handleElementDragging = function (event, eRef) {
  if (!lastEvent || !element /*|| !event.native.altKey*/) {
    return
  }
  const moveX = event.x - lastEvent.x
  const moveY = event.y - lastEvent.y
  eRef().current.xAdjust += moveX
  eRef().current.yAdjust += moveY
  drag(moveX, moveY)
  lastEvent = event
  return true
}

const handleDrag = function (event, eRef) {
  if (element) {
    switch (event.type) {
      case 'mousemove':
        return handleElementDragging(event, eRef)
      case 'mouseout':
      case 'mouseup':
        lastEvent = undefined
        break
      case 'mousedown':
        lastEvent = event
        break
      default:
    }
  }
}

const dragger = {
  id: 'dragger',
  beforeEvent(chart, args, options) {
    if (handleDrag(args.event, options.elementRef)) {
      args.changed = true
      return
    }
  },
}

const borderPlugIn = {
  id: 'borderPlugIn',
  beforeDatasetsDraw: (chart, args, options) => {
    // if (!options?.color) return
    const ctx = chart.ctx
    const chartArea = chart.chartArea
    if (chartArea) {
      ctx.save()
      ctx.strokeStyle = options.color || '#000000'
      ctx.beginPath()
      ctx.lineWidth = 2
      ctx.moveTo(chartArea.left, chartArea.top)
      ctx.lineTo(chartArea.right, chartArea.top)
      ctx.lineTo(chartArea.right, chartArea.bottom)
      ctx.lineTo(chartArea.left, chartArea.bottom)
      ctx.closePath()
      ctx.stroke()
    }
  },
}

const lithologyPlugIn = {
  id: 'lithologyPlugIn',
  afterDraw: (chart, args, options) => {
    if (!options?.lithologies) return
    const lithologies = options.lithologies()
    const ctx = chart.ctx
    const yScale = chart.scales.y
    if (chart.chartArea) {
      ctx.save()
      lithologies.forEach((lithology) => {
        ctx.strokeStyle = lithology.color
        ctx.beginPath()
        ctx.lineWidth = 2
        ctx.moveTo(chart.chartArea.left, yScale.getPixelForValue(lithology.yValue))
        ctx.lineTo(chart.chartArea.right, yScale.getPixelForValue(lithology.yValue))
        ctx.closePath()
        ctx.stroke()
      })
      ctx.restore()
    }
  },
}

const INITIAL_SCALE_LIMITS = {
  maxX: 50,
  minX: -50,
  maxY: 50,
  minY: -50,
  xLen: 100,
  yLen: 100,
}

const removeChar = (value, charToRemove) => {
  return value.split(charToRemove).join('')
}

const WallPlotChart = forwardRef(
  (
    {
      data,
      chartType,
      refWellSurveys,
      isLoading,
      projections = [],
      isPlan = false,
      onUpdate,
      chartSettings,
      onUpdateNoRender,
    },
    ref,
  ) => {
    const chartRef = useRef(null)
    const chartSizeRef = useRef({ width: 0, height: 0 })
    const { getUnitsText } = useUnits()
    const dataMinMax = useRef(cloneDeep(INITIAL_SCALE_LIMITS)) //Holds the min max of the well data
    const scaleMinMax = useRef(cloneDeep(INITIAL_SCALE_LIMITS)) //Holds the min max values of the chart scales preserving the aspect ratio
    const size = useRef({ width: 0, height: 0 })
    const axisLabels = useRef({ x: '', y: '' })
    const chartDataRef = useRef({ datasets: [] })
    const chartSettingsRef = useRef(chartSettings)
    const [rerender, setRerender] = React.useState(false) // eslint-disable-line no-unused-vars
    const elementRef = useRef(null)
    const { theme } = useInnovaTheme()

    useImperativeHandle(ref, () => {
      return {
        getSettings() {
          return chartSettingsRef.current
        },
      }
    })

    useEffect(() => {
      checkPanZoom()
      if (data) {
        const {
          chartData: newData,
          minMax,
          xAxisLabel,
          yAxisLabel,
        } = getChartData(chartType, data, data?.surveys, projections, isPlan, chartSettingsRef.current, data?.geoZones)
        dataMinMax.current = cloneDeep(minMax)
        axisLabels.current = { x: xAxisLabel, y: yAxisLabel }
        chartDataRef.current = cloneDeep(newData)
        adjustChartScale()
        // chartDataRef.current = cloneDeep(newData)
        setRerender((prev) => !prev)
      }
    }, [data, chartSettings]) // eslint-disable-line react-hooks/exhaustive-deps

    const onResize = useCallback((width, height) => {
      if (chartRef.current?.chartArea) {
        chartSizeRef.current = {
          width: chartRef.current.chartArea.right - chartRef.current.chartArea.left,
          height: chartRef.current.chartArea.bottom - chartRef.current.chartArea.top,
        }
      }
      size.current = { width, height }
      adjustChartScale()
      // need to update the element in wallplotComposer for saving, but without re-rendering the chart--remder loop
      if (onUpdateNoRender)
        onUpdateNoRender({
          xMin: chartSettingsRef.current.xMin,
          xMax: chartSettingsRef.current.xMax,
          yMin: chartSettingsRef.current.yMin,
          yMax: chartSettingsRef.current.yMax,
        })
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const { ref: rszRef } = useResizeDetector({ onResize: onResize }) // , refreshMode: 'debounce', refreshRate: 25 })

    useEffect(() => {
      updateAxisLabels()
    }, [getUnitsText]) // eslint-disable-line react-hooks/exhaustive-deps

    const getGridColor = (context, showMajor, showMinor) => {
      const defaultColor = '#404040'
      const isMajorGrid = context.index % (chartSettingsRef.current?.minorTicks + 1) === 0
      if (isMajorGrid) {
        if (!showMajor) return chartSettingsRef.current.gridBackgroundColor // 'hide' major gridline
        return chartSettingsRef.current?.majorGridLineColor ? chartSettingsRef.current.majorGridLineColor : defaultColor
      }
      if (!showMinor) return '' // chartSettingsRef.current.gridBackgroundColor // 'hide' minor gridline
      return chartSettingsRef.current?.minorGridLineColor ? chartSettingsRef.current.minorGridLineColor : defaultColor
    }

    const getLabelObj = (id) => {
      if (!chartSettingsRef.current.labels?.labelData) return null
      return chartSettingsRef.current.labels.labelData.find((label) => label.id === id)
    }

    const getAnnotations = () => {
      if (!chartSettingsRef.current?.labels?.labelData) return null
      let annotations = []
      chartSettingsRef.current.labels.labelData.forEach((label) => {
        annotations.push(label)
      })
      return annotations
    }

    const getLithologies = () => {
      if (!chartSettingsRef.current?.labels?.labelData) return null
      let lithologies = []
      chartSettingsRef.current.labels.labelData.forEach((label) => {
        if (label.catType === 'Lithologies') {
          lithologies.push(label)
        }
      })
      return lithologies
    }

    const X_SCALE_DEFN = {
      type: 'linear',
      position: 'bottom',
      title: {
        display: true,
        text: `EW`,
        color: `#000000`, // appColors.headerTextColor,
      },
      ticks: {
        color: '#111', //'#FFF',
        autoSkip: false,
        enabled: true,
        // count: 10,
        count: () => {
          if (!chartSettingsRef.current?.majorTicks) return 10
          const ticks =
            chartSettingsRef.current?.majorTicks +
            (chartSettingsRef.current?.majorTicks - 1) * chartSettingsRef.current?.minorTicks
          return ticks > 0 ? ticks : 10
        },
        callback: (value, index, values) => {
          let tickText = value.toFixed(0)
          const formatter = new Intl.NumberFormat('en-US', {
            style: 'decimal',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          })

          let roundedNumber = Math.round(value / 10) * 10
          if (Math.abs(value) < 10) {
            roundedNumber = Math.round(value)
          }
          tickText = formatter.format(roundedNumber)
          if (!chartSettingsRef.current?.minorTicks) return tickText

          let isMajorTick = index % (chartSettingsRef.current?.minorTicks + 1) === 0
          if (isMajorTick) {
            return tickText
          }
          return ''
        },
      },
      grid: {
        display: true,
        borderColor: chartSettingsRef.current?.majorGridLineColor
          ? chartSettingsRef.current.majorGridLineColor
          : '#000',
        color: (context) => {
          if (!context?.tick || !context?.index) return '#404040'
          return getGridColor(
            context,
            chartSettingsRef.current?.showXAxisMajorGridLines,
            chartSettingsRef.current?.showXAxisMinorGridLines,
          )
        },
      },
    }

    const Y_SCALE_DEFN = {
      type: 'linear',
      position: 'left',
      title: {
        display: true,
        text: `NS`,
        color: `#000000`, // appColors.headerTextColor,
      },
      ticks: {
        color: '#111', //'#FFF',
        autoSkip: false,
        enabled: true,
        // count: 10,
        count: () => {
          if (!chartSettingsRef.current?.majorTicks) return 10
          const ticks =
            chartSettingsRef.current?.majorTicks +
            (chartSettingsRef.current?.majorTicks - 1) * chartSettingsRef.current?.minorTicks
          return ticks > 0 ? ticks : 10
        },
        callback: (value, index, values) => {
          let tickText = value.toFixed(0)
          const formatter = new Intl.NumberFormat('en-US', {
            style: 'decimal',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          })

          let roundedNumber = Math.round(value / 10) * 10
          if (Math.abs(value) < 10) {
            roundedNumber = Math.round(value)
          }
          tickText = formatter.format(roundedNumber)

          if (!chartSettingsRef.current?.minorTicks) return tickText

          if (index % (chartSettingsRef.current?.minorTicks + 1) === 0) {
            return tickText
          }
          return ''
        },
      },
      grid: {
        display: true,
        borderColor: chartSettingsRef.current?.majorGridLineColor
          ? chartSettingsRef.current.majorGridLineColor
          : '#000',
        color: (context) => {
          if (!context?.tick || !context?.index) return '#404040'
          return getGridColor(
            context,
            chartSettingsRef.current?.showYAxisMajorGridLines,
            chartSettingsRef.current?.showYAxisMinorGridLines,
          )
        },
      },
    }

    const CHART_OPTIONS = {
      maintainAspectRatio: false,
      scaleShowLabels: false,
      animation: {
        duration: 0,
      },
      layout: {
        padding: {
          right: 20,
          top: 20,
        },
      },
      events: ['mousedown', 'mouseup', 'mousemove', 'mouseout'],
      plugins: {
        dataLabelPlugin: {
          color: '#000000', // '#ffffff',
          visible: true,
        },
        title: {
          display: false,
        },
        tooltip: {
          mode: 'point',
          intersect: false,
          callbacks: {
            title: function (tooltipItem) {
              return tooltipItem[0].dataset.label
            },
            label: function (context) {
              return [` ${+context.parsed.x.toFixed(2)} EW`, ` ${context.parsed.y.toFixed(2)} NS`]
            },
          },
          filter: function (tooltipItem, index) {
            if (index > 0) return false
            return true
          },
        },
        hover: {
          mode: 'nearest',
          intersect: false,
        },
        legend: {
          display: false,
        },
        zoom: {
          animation: {
            duration: 0,
          },
          pan: {
            enabled: true,
            mode: 'xy',
            // modifierKey: 'alt',
            onPanStart: ({ chart }) => {
              if (element) {
                return false
              }
              return true
            },
            onPanComplete: ({ chart }) => {
              // normalize the calcs here too
              const xAxis = chart.scales.x
              const yAxis = chart.scales.y
              if (onUpdateNoRender)
                onUpdateNoRender({
                  xMin: xAxis.min,
                  xMax: xAxis.max,
                  yMin: yAxis.min,
                  yMax: yAxis.max,
                  width: size.current.width,
                  height: size.current.height,
                  xAxisScale: chartSettingsRef.current.xAxisScale,
                  yAxisScale: chartSettingsRef.current.yAxisScale,
                })
            },
          },
          limits: {
            x: {
              min: -50,
              max: 50,
            },
            y: {
              min: -50,
              max: 50,
            },
            xTop: {
              min: -50,
              max: 50,
            },
            yRight: {
              min: -50,
              max: 50,
            },
          },
          zoom: {
            wheel: {
              enabled: true,
            },
            pinch: {
              enabled: true,
            },
            mode: 'xy',
            onZoomComplete: ({ chart }) => {
              // normalize the calcs here too
              const xAxis = chart.scales.x
              const yAxis = chart.scales.y
              let xLen = xAxis.max - xAxis.min
              let yLen = yAxis.max - yAxis.min
              if (onUpdateNoRender)
                onUpdateNoRender({
                  xMin: xAxis.min,
                  xMax: xLen < 5.0 ? xAxis.max + 5.0 : xAxis.max, // clamp zoom-in, x-axis
                  yMin: yAxis.min,
                  yMax: yLen < 5.0 ? yAxis.max + 5.0 : yAxis.max, // clamp zoom-in y-axis
                  width: size.current.width,
                  height: size.current.height,
                  xAxisScale: chartSettingsRef.current.xAxisScale,
                  yAxisScale: chartSettingsRef.current.yAxisScale,
                })
            },
          },
        },
        GradientPlugIn: {
          showGradient: false,
          theme: theme,
        },
        borderPlugIn: {
          color: '#000000',
        },
        ChartBackgroundPlugIn: {
          showChartBackground: true,
          // chartBackground can be a color string or a function that returns a color string
          chartBackground: () => {
            return chartSettingsRef.current?.gridBackgroundColor || '#FFF'
          },
        },
        annotation: {
          enter(ctx) {
            element = ctx.element
            elementRef.current = getLabelObj(ctx.id)
          },
          leave() {
            element = undefined
            lastEvent = undefined
            // need to set the xAdjust and yAdjust of the label in the ChartElement parent before elementRef reset.
            if (onUpdateNoRender) {
              onUpdateNoRender(chartSettingsRef.current)
            }
            elementRef.current = undefined
          },
          annotations: () => getAnnotations(),
        },
        dragger: {
          elementRef: () => {
            return elementRef
          },
        },
        lithologyPlugIn: {
          lithologies: () => getLithologies(),
        },
      },
      scales: {
        x: {
          ...X_SCALE_DEFN,
          display: () => {
            return chartSettingsRef.current?.showXAxisBottom
          },
        },
        xTop: {
          ...X_SCALE_DEFN,
          position: 'top',
          display: () => {
            return chartSettingsRef.current?.showXAxisTop
          },
        },
        y: {
          ...Y_SCALE_DEFN,
          display: () => {
            return chartSettingsRef.current?.showYAxisLeft
          },
        },
        yRight: {
          ...Y_SCALE_DEFN,
          position: 'right',
          display: () => {
            return chartSettingsRef.current?.showYAxisRight
          },
        },
      },
    }

    const optionsPlanView = (actions) => {
      return {
        plugins: {
          animation: {
            duration: 0,
          },
        },
        scales: {
          x: {
            text: 'EW',
          },
          y: {
            title: {
              text: 'NS',
            },
          },
        },
      }
    }

    const optionsSectionView = (actions) => {
      return {
        scales: {
          x: {
            text: 'VS',
          },
          y: {
            beginAtZero: true,
            reverse: true,
            title: {
              text: 'TVD',
            },
          },
          yRight: {
            beginAtZero: true,
            reverse: true,
            title: {
              text: 'TVD',
            },
          },
        },
      }
    }

    const getChartOptions = (type, onUpdate) => {
      let options = {}
      switch (type) {
        case chartTypes.planView:
          options = optionsPlanView({ onUpdate: onUpdate })
          break
        case chartTypes.sectionView:
          options = optionsSectionView({ onUpdate: onUpdate })
          break
        default:
          break
      }
      let baseOptions = cloneDeep(CHART_OPTIONS)
      let mergedOptions = merge(baseOptions, options)

      if (chartSettings?.fixedScaleEnabled) {
        mergedOptions.plugins.zoom.zoom.wheel.enabled = false
        mergedOptions.plugins.zoom.zoom.pinch.enabled = false
      }
      if (chartSettings && chartSettings.hasOwnProperty('xMin')) {
        mergedOptions.scales.x.min = chartSettings.xMin
        mergedOptions.scales.x.max = chartSettings.xMax
        mergedOptions.scales.y.min = chartSettings.yMin
        mergedOptions.scales.y.max = chartSettings.yMax
        mergedOptions.scales.xTop.min = chartSettings.xMin
        mergedOptions.scales.xTop.max = chartSettings.xMax
        mergedOptions.scales.yRight.min = chartSettings.yMin
        mergedOptions.scales.yRight.max = chartSettings.yMax
      }
      return mergedOptions
    }

    const options = useRef(getChartOptions(chartType, onUpdate))

    const updateAxisLabels = () => {
      if (!chartRef.current) return
      chartRef.current.config.options.scales.x.title.text = `${axisLabels.current.x} ${
        chartSettingsRef.current.showScales
          ? '(' +
            chartSettingsRef.current?.xAxisScale?.toFixed(0) +
            ' ' +
            getUnitsText(UNITS_FOR.Depth) +
            '/' +
            (chartSettingsRef.current.fixedScaleCm ? 'cm' : 'in') +
            ')'
          : ''
      }`
      chartRef.current.config.options.scales.y.title.text = `${axisLabels.current.y} ${
        chartSettingsRef.current.showScales
          ? '(' +
            chartSettingsRef.current?.yAxisScale?.toFixed(0) +
            ' ' +
            getUnitsText(UNITS_FOR.Depth) +
            '/' +
            (chartSettingsRef.current.fixedScaleCm ? 'cm' : 'in') +
            ')'
          : ''
      }`
      chartRef.current.config.options.scales.xTop.title.text = chartRef.current.config.options.scales.x.title.text
      chartRef.current.config.options.scales.yRight.title.text = chartRef.current.config.options.scales.y.title.text
      chartRef.current.update()
    }

    const checkPanZoom = () => {
      if (chartSettings) {
        chartSettingsRef.current = cloneDeep(chartSettings)
        if (chartRef.current) {
          let restrictPanOrZoom = false
          // seems cleaner to reinit pan & zoom modes here
          options.current.plugins.zoom.zoom.mode = 'xy'
          chartRef.current.config.options.plugins.zoom.zoom.mode = 'xy'
          options.current.plugins.zoom.pan.mode = 'xy'
          chartRef.current.config.options.plugins.zoom.pan.mode = 'xy'

          if (chartSettings.fixedScaleEnabled) {
            restrictPanOrZoom = true
            options.current.plugins.zoom.zoom.wheel.enabled = false
            options.current.plugins.zoom.zoom.pinch.enabled = false
            chartRef.current.config.options.plugins.zoom.zoom.enabled = false
            chartRef.current.config.options.plugins.zoom.pan.enabled = true
            // chartRef.current.update()
          }

          if (chartSettings.xAxisUserDefined) {
            restrictPanOrZoom = true
            // if pan mode and zoom mode are enabled, disable in the x-axis
            options.current.plugins.zoom.zoom.mode = removeChar(options.current.plugins.zoom.zoom.mode, 'x')
            chartRef.current.config.options.plugins.zoom.zoom.mode = options.current.plugins.zoom.zoom.mode
            options.current.plugins.zoom.pan.mode = removeChar(options.current.plugins.zoom.pan.mode, 'x')
            chartRef.current.config.options.plugins.zoom.pan.mode = options.current.plugins.zoom.pan.mode
          }

          if (chartSettings.yAxisUserDefined) {
            restrictPanOrZoom = true
            // if pan mode and zoom mode are enabled, disable in the y-axis
            options.current.plugins.zoom.zoom.mode = removeChar(options.current.plugins.zoom.zoom.mode, 'y')
            chartRef.current.config.options.plugins.zoom.zoom.mode = options.current.plugins.zoom.zoom.mode
            options.current.plugins.zoom.pan.mode = removeChar(options.current.plugins.zoom.pan.mode, 'y')
            chartRef.current.config.options.plugins.zoom.pan.mode = options.current.plugins.zoom.pan.mode
          }

          // if pan mode is disabled in both axis, disable the pan mode
          if (options.current.plugins.zoom.pan.mode === '') {
            options.current.plugins.zoom.pan.enabled = false
            chartRef.current.config.options.plugins.zoom.pan.enabled = false
          }

          // if zoom mode is disabled in both axis, disable the zoom mode
          if (options.current.plugins.zoom.zoom.mode === '') {
            options.current.plugins.zoom.zoom.enabled = false
            chartRef.current.config.options.plugins.zoom.zoom.enabled = false
          }

          // (reset to defaults) if no user-defined x- or y-axis, and no fixed scale,
          // enable both zoom and pan in both directions
          if (!restrictPanOrZoom) {
            options.current.plugins.zoom.zoom.wheel.enabled = true
            options.current.plugins.zoom.zoom.pinch.enabled = true
            chartRef.current.config.options.plugins.zoom.zoom.enabled = true
            chartRef.current.config.options.plugins.zoom.pan.enabled = true
            // chartRef.current.update()
          }
          chartRef.current.update()
        }
      }
    }

    const adjustChartScale = () => {
      let xScale = 1
      if (dataMinMax.current.xLen > 0 && chartSizeRef.current.width > 0)
        xScale = dataMinMax.current.xLen / chartSizeRef.current.width

      let yScale = 1
      if (dataMinMax.current.yLen > 0 && chartSizeRef.current.height > 0)
        yScale = dataMinMax.current.yLen / chartSizeRef.current.height

      let addXlen = xScale < yScale ? (yScale * chartSizeRef.current.width - dataMinMax.current.xLen) / 2.0 : 0
      let addYlen = xScale <= yScale ? 0 : (xScale * chartSizeRef.current.height - dataMinMax.current.yLen) / 2.0 // pretty sure <= instead of < is correct (no correction if scales are equal)

      scaleMinMax.current.maxX = Math.floor((dataMinMax.current.maxX + addXlen) * 1.1)
      scaleMinMax.current.minX = Math.floor((dataMinMax.current.minX - addXlen) * 1.1)
      scaleMinMax.current.maxY = Math.floor((dataMinMax.current.maxY + addYlen) * 1.1)
      scaleMinMax.current.minY = Math.floor((dataMinMax.current.minY - addYlen) * 1.1)

      if (!chartRef.current) return
      const { minX, maxX, minY, maxY } = scaleMinMax.current
      const { scales, plugins } = chartRef.current.config.options

      plugins.zoom.limits.x.min = minX
      plugins.zoom.limits.x.max = maxX
      plugins.zoom.limits.y.min = minY
      plugins.zoom.limits.y.max = maxY
      plugins.zoom.limits.xTop.min = minX
      plugins.zoom.limits.xTop.max = maxX
      plugins.zoom.limits.yRight.min = minY
      plugins.zoom.limits.yRight.max = maxY

      if (chartSettingsRef.current.fixedScaleEnabled && chartSettingsRef.current.fixedScaleValue) {
        let pixelsPerDisplayUnit = 72
        if (chartSettingsRef.current.fixedScaleCm) pixelsPerDisplayUnit = 28.3465
        let xDiff = (chartSizeRef.current.width / pixelsPerDisplayUnit) * chartSettingsRef.current.fixedScaleValue
        let yDiff = (chartSizeRef.current.height / pixelsPerDisplayUnit) * chartSettingsRef.current.fixedScaleValue

        scales.x.min = chartSettingsRef.current.xMin
        scales.x.max = chartSettingsRef.current.xMin + xDiff
        scales.y.min = chartSettingsRef.current.yMin
        scales.y.max = chartSettingsRef.current.yMin + yDiff
        scales.xTop.min = chartSettingsRef.current.xMin
        scales.xTop.max = chartSettingsRef.current.xMin + xDiff
        scales.yRight.min = chartSettingsRef.current.yMin
        scales.yRight.max = chartSettingsRef.current.yMin + yDiff

        if (scales.x.max > scaleMinMax.current.maxX) {
          let overflowX = scales.x.max - scaleMinMax.current.maxX
          scales.x.min -= overflowX
          scales.x.max -= overflowX
          scales.xTop.min -= overflowX
          scales.xTop.max -= overflowX
        }
        if (scales.y.max > scaleMinMax.current.maxY) {
          let overflowY = scales.y.max - scaleMinMax.current.maxY
          scales.y.min -= overflowY
          scales.y.max -= overflowY
          scales.yRight.min -= overflowY
          scales.yRight.max -= overflowY
        }

        chartSettingsRef.current = {
          ...chartSettingsRef.current,
          xMin: scales.x.min ? scales.x.min : chartSettingsRef.current.scales.x.min,
          xMax: scales.x.max ? scales.x.max : chartSettingsRef.current.scales.x.max,
          yMin: scales.y.min ? scales.y.min : chartSettingsRef.current.scales.y.min,
          yMax: scales.y.max ? scales.y.max : chartSettingsRef.current.scales.y.max,
          width: size.current.width,
          height: size.current.height,
        }
      } else {
        if (chartSettingsRef.current.xAxisScale === -1) {
          // apply initial scale limits
          scales.x.min = dataMinMax.current.minX
          scales.x.max = dataMinMax.current.maxX
          scales.y.min = dataMinMax.current.minY
          scales.y.max = dataMinMax.current.maxY
          scales.xTop.min = dataMinMax.current.minX
          scales.xTop.max = dataMinMax.current.maxX
          scales.yRight.min = dataMinMax.current.minY
          scales.yRight.max = dataMinMax.current.maxY
        }

        chartSettingsRef.current.width = size.current.width
        chartSettingsRef.current.height = size.current.height

        if (!chartSettingsRef.current.xAxisUserDefined) {
          chartSettingsRef.current.xMin = scales.x.min ? scales.x.min : chartRef.current.scales.x.min
          chartSettingsRef.current.xMax = scales.x.max ? scales.x.max : chartRef.current.scales.x.max
        }
        if (chartSettingsRef.current.xAxisUserDefined) {
          scales.x.min = chartSettingsRef.current.xMin
          scales.x.max = chartSettingsRef.current.xMax
          scales.xTop.min = chartSettingsRef.current.xMin
          scales.xTop.max = chartSettingsRef.current.xMax
        }
        if (!chartSettingsRef.current.yAxisUserDefined) {
          chartSettingsRef.current.yMin = scales.y.min ? scales.y.min : chartRef.current.scales.y.min
          chartSettingsRef.current.yMax = scales.y.max ? scales.y.max : chartRef.current.scales.y.max
        }
        if (chartSettingsRef.current.yAxisUserDefined) {
          scales.y.min = chartSettingsRef.current.yMin
          scales.y.max = chartSettingsRef.current.yMax
          scales.yRight.min = chartSettingsRef.current.yMin
          scales.yRight.max = chartSettingsRef.current.yMax
        }
        // show x and y scales
        let pixelsPerDisplayUnit = 72
        if (chartSettingsRef.current.fixedScaleCm) pixelsPerDisplayUnit = 28.3465
        let xDiff = chartSettingsRef.current.xMax - chartSettingsRef.current.xMin
        let yDiff = chartSettingsRef.current.yMax - chartSettingsRef.current.yMin
        let xAxisScale = xDiff / (chartSettingsRef.current.width / pixelsPerDisplayUnit)
        let yAxisScale = yDiff / (chartSettingsRef.current.height / pixelsPerDisplayUnit)

        chartSettingsRef.current.xAxisScale = xAxisScale
        chartSettingsRef.current.yAxisScale = yAxisScale
      }

      updateAxisLabels()
    }

    const getData = () => {
      if (dataMinMax.current) adjustChartScale()
      return chartDataRef.current
    }

    return (
      <div ref={rszRef} style={{ height: '100%', width: '100%' }}>
        <Line
          ref={chartRef}
          type='line'
          options={options.current}
          data={getData()}
          plugins={[borderPlugIn, dragger, lithologyPlugIn]}
        />
      </div>
    )
  },
)

export default WallPlotChart
