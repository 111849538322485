import React, { useRef, useEffect, useState } from 'react'

import Dialog from '@mui/material/Dialog'
import { DialogContent, DialogTitle, DialogActions, Button, Snackbar, Alert } from '@mui/material'
import { Box } from '@mui/material'
import useDrillString from 'components/common/hooks/useDrillString/useDrillString'
import DrillStringGrid from 'components/WellPages/DrillStringPages/DrillStringGrid'
import useMotorReportImages from 'components/common/hooks/useMotorReportImages'
import { pdf } from '@react-pdf/renderer'
import FileSaver from 'file-saver'

const DrillStringEditorModal = ({ wellName, onClose }) => {
  const _isMounted = useRef(false)
  const [drillStringData, setDrillStringData] = useState([])
  const [status, setStatus] = useState({ show: false, severity: 'info', message: '' })
  const { fetchImagesManual } = useMotorReportImages()
  
  const {
    loading: isLoading,
    getDrillStringHeader,
    getBhaReportPdfData,
    getMotorReportPdfData,
    getMotorPerformanceXlsExport,
    getBhaXlsExport,
    fetchBhaIaddExport,
  } = useDrillString(wellName)

  useEffect(() => {
    _isMounted.current = true
    return () => {
      _isMounted.current = false
    }
  }, [])

  const onXlsExport = (bhaNum, exportType) => {
    if (exportType === 'performanceExport') getMotorPerformanceXlsExport(bhaNum)
    if (exportType === 'bhaExport') getBhaXlsExport(bhaNum)
    return
  }

  useEffect(() => {
    if (_isMounted.current) {
      if (!isLoading) setDrillStringData(getDrillStringHeader(-1))
    }
  }, [isLoading, wellName]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleCloseStatus = () => {
    setStatus({ show: false, severity: 'info', message: '' })
  }

  const onBhaPdfExport = async (bhaNum) => {
    let pdfDoc = await getBhaReportPdfData(bhaNum)
    if (!pdfDoc) return
    const blob = await pdf(pdfDoc.data).toBlob()
    FileSaver.saveAs(blob, pdfDoc.fileName)
  }

  const onIaddExport = async (bhaNum) => {
    let bhaJson = await fetchBhaIaddExport(bhaNum)
    if (!bhaJson) return
    let blob = new Blob([JSON.stringify(bhaJson)], { type: 'text/plain;charset=utf-8' })
    FileSaver.saveAs(blob, `iaddBhaExport.txt`)
  }

  const onMotorReportPdfExport = async (bhaNum) => {
    if (bhaNum === null || bhaNum === undefined) return

    let motorImages = await fetchImagesManual(bhaNum, wellName)
    let pdfDoc = await getMotorReportPdfData(bhaNum, motorImages)
    if (!pdfDoc) return

    const blob = await pdf(pdfDoc.data).toBlob()
    FileSaver.saveAs(blob, pdfDoc.fileName)
  }

  return (
    <Dialog
      maxWidth='xl'
      open={true}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: '90vw',
          height: '80vh',
          backgroundColor: 'itemBackground',
        },
      }}>
      <DialogTitle>{`Drill String Editor - ${wellName}`}</DialogTitle>
      <DialogContent style={{ overflow: 'auto' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
            height: 'calc(100% - 15px)',
            maxHeight: '100%',
            border: '2px solid #000',
            borderRadius: '4px',
          }}>
          <DrillStringGrid
            gridData={drillStringData}
            isLoading={isLoading}
            setFilteredData={() => {}}
            onBhaPdfExport={onBhaPdfExport}
            onMotorReportPdfExport={onMotorReportPdfExport}
            setStatus={setStatus}
            setShowChart={() => {}}
            showChart={false}
            wellName={wellName}
            onXlsExport={onXlsExport}
            onIaddExport={onIaddExport}
          />
        </Box>
        {status?.show ? (
          <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            open={status?.show}
            autoHideDuration={2000}
            onClose={handleCloseStatus}>
            <Alert onClose={handleCloseStatus} severity={status.severity} elevation={4} variant='filled'>
              {status.message}
            </Alert>
          </Snackbar>
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' onClick={() => onClose()} color='secondary'>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DrillStringEditorModal
