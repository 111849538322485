import React, { useEffect, useRef, useState } from 'react'
import { useSetRecoilState, useRecoilValue } from 'recoil'
import { currentPageAtom, actionBarWidthAtom, currentWellSelector } from 'atoms'
import { PAGE_KEYS } from 'components/ActionBar/pageDefs'
import MenuButton from 'components/common/MenuButton'
import SplitPane from 'components/common/SplitPane'
import { Box, Alert, Snackbar } from '@mui/material'
import { appColors } from 'utils'
import { Icon as Iconify } from '@iconify/react'
import RefreshIcon from '@mui/icons-material/Refresh'
import ConnectionAnalysisGrid from './ConnectionAnalysisGrid'
import ConnectionAnalysisChart from './ConnectionAnalysisChart'
import useAnimatePane from 'components/common/hooks/useAnimatePane'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'

const ConnectionAnalysisPage = () => {
  const _isMounted = useRef(false)
  const setActivePage = useSetRecoilState(currentPageAtom)
  const leftPos = useRecoilValue(actionBarWidthAtom)
  const currentWell = useRecoilValue(currentWellSelector)
  const currentWellRef = useRef(currentWell)
  const [isLoading, setLoading] = useState(false)
  const [avgValues, setAvgValues] = useState({ wt2wt: 0, slp2slp: 0, connTime: 0, surveyTime: 0 })
  const [connectionData, setConnectionData] = useState([])
  const { setPaneVisible: setShowChart, paneVisible: showChart, paneSize } = useAnimatePane()
  const [status, setStatus] = useState({ show: false, severity: 'info', message: '' })
  const [filteredConnections, setFilteredConnections] = useState([])

  const getConnectionData = useInnovaAxios({
    url: '/kpi/connectionsAnalysis',
  })

  useEffect(() => {
    _isMounted.current = true
    setActivePage(PAGE_KEYS.wellConnectionAnalysisKey)
    fetchConnectionData()

    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    currentWellRef.current = currentWell
    fetchConnectionData()
  }, [currentWell]) // eslint-disable-line react-hooks/exhaustive-deps

  const fetchConnectionData = async () => {
    if (!currentWellRef.current) return
    if (typeof currentWellRef.current !== 'string') return
    if (currentWellRef.current === '') return
    if (isLoading) return
    if (!_isMounted.current) return
    setLoading(true)

    let res = await getConnectionData({ wellName: currentWellRef.current, radius: 10 })

    if (!_isMounted.current) return
    setLoading(false)

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })
      return
    }

    if (!Array.isArray(res?.data)) return
    calcAvgValues(res.data)

    setConnectionData(Array.isArray(res?.data) ? res.data : [])
  }

  const calcAvgValues = (data) => {
    let avgValues = { wt2wt: 0, slp2slp: 0, connTime: 0, surveyTime: 0 }
    let divisor = { wt2wt: 0, slp2slp: 0, connTime: 0, surveyTime: 0 }

    if (!Array.isArray(data) || data.length <= 1) return avgValues
    for (let i = 0; i < data.length; i++) {
      if (!Array.isArray(data[i].connections) || data[i].connections.length === 0) continue
      let conn = data[i].connections

      for (let j = 0; j < conn.length; j++) {
        if (avgValues.wt2wt > 0) {
          avgValues.wt2wt += conn[j].wt2wt
          divisor.wt2wt++
        }

        if (avgValues.slp2slp > 0) {
          avgValues.slp2slp += conn[j].slp2slp
          divisor.slp2slp++
        }

        if (avgValues.connTime > 0) {
          avgValues.connTime += conn[j].connTime
          divisor.connTime++
        }

        if (avgValues.surveyTime > 0) {
          avgValues.surveyTime += conn[j].surveyTime
          divisor.surveyTime++
        }
      }
    }

    avgValues.wt2wt = divisor.wt2wt > 0 ? avgValues.wt2wt / divisor.wt2wt : 0
    avgValues.slp2slp = divisor.slp2slp > 0 ? avgValues.slp2slp / divisor.slp2slp : 0
    avgValues.connTime = divisor.connTime > 0 ? avgValues.connTime / divisor.connTime : 0
    avgValues.surveyTime = divisor.surveyTime > 0 ? avgValues.surveyTime / divisor.surveyTime : 0
    setAvgValues(avgValues)
  }

  const getMenuItems = () => {
    let actions = [
      {
        icon: <RefreshIcon />,
        name: 'Refresh',
        onClick: () => {},
      },
    ]

    if (!showChart) {
      actions.push({
        icon: <Iconify icon='bxs:show' style={{ color: appColors.itemTextColor, width: 28, height: 28 }} />,
        name: 'Show Charts',
        onClick: () => handleShowChart(),
      })
    } else {
      actions.push({
        icon: <Iconify icon='bxs:hide' style={{ color: appColors.itemTextColor, width: 28, height: 28 }} />,
        name: 'Hide Charts',
        onClick: () => handleShowChart(),
      })
    }

    return actions
  }

  const handleShowChart = () => {
    setShowChart((prev) => !prev)
  }

  const handleClose = () => {
    setStatus({ show: false, severity: 'info', message: '' })
  }

  return (
    <React.Fragment>
      <SplitPane
        split='horizontal'
        defaultSize={'50%'}
        size={paneSize}
        style={{
          marginTop: '64px',
          marginLeft: `${leftPos}px`,
          height: 'calc(100vh - 64px)',
          maxHeight: 'calc(100vh - 64px)',
          minHeight: 'calc(100vh - 64px)',
          width: `calc(100% - ${leftPos}px)`,
          maxWidth: `calc(100% - ${leftPos}px)`,
          minWidth: `calc(100% - ${leftPos}px)`,
        }}
        allowResize={true}>
        <ConnectionAnalysisChart connectionData={filteredConnections} avgValues={avgValues} />
        <ConnectionAnalysisGrid
          isLoading={isLoading}
          connectionData={connectionData}
          avgValues={avgValues}
          setFilteredData={setFilteredConnections}
        />
      </SplitPane>
      <Box
        sx={{
          backgroundColor: 'transparent',
          margin: '4px',
          padding: '12px',
          position: 'fixed',
          bottom: (theme) => theme.spacing(2),
          right: (theme) => theme.spacing(2),
          zIndex: 2,
        }}>
        <MenuButton actions={getMenuItems()} />
      </Box>
      {status?.show ? (
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={status?.show}
          autoHideDuration={2000}
          onClose={handleClose}>
          <Alert onClose={handleClose} severity={status.severity} elevation={4} variant='filled'>
            {status.message}
          </Alert>
        </Snackbar>
      ) : null}
    </React.Fragment>
  )
}

export default ConnectionAnalysisPage
