import { useEffect, useState, useRef, useCallback } from 'react'

import useAxiosGzip from '../useAxiosGzip'
import { useRecoilValue } from 'recoil'
import { currentWellAtom, selectedEngineeringCaseAtom, userUserRoleAtom } from 'atoms'
import { normalize } from 'utils/numberFunctions.js'
import { lerpColor } from 'utils/colorFunctions'
import { appColors } from 'utils'
import html2canvas from 'html2canvas'
import PdfDocument from 'components/common/PDFGen/PdfDocument'
import { removeSpecialSymbols, replaceEscapeSymbols, numberWithCommasDecimals } from 'utils/stringFunctions'
import useUnits, { UNITS_FOR } from 'components/common/hooks/useUnits'
import { unescapeHtml } from 'utils/htmlSymbolHandling'
import useInnovaAxios from '../useInnovaAxios'
import { multiParse } from 'utils'
import { Buffer } from 'buffer'
import { getUserNameFromEmail } from 'utils/chatFunctions'
import useInnovaAuth from 'components/common/hooks/useInnovaAuth'
import useBhaImages from '../useBhaImages'

function useHydraulics() {
  const _isMounted = useRef(true)
  const hydraulicsData = useRef({})
  const isLoading = useRef(false)
  const [loading, setLoading] = useState(false)
  const currentWell = useRecoilValue(currentWellAtom)
  const currentWellRef = useRef(currentWell)
  const selectedEngCase = useRecoilValue(selectedEngineeringCaseAtom)
  const { getUnitsText } = useUnits()
  const userRole = useRecoilValue(userUserRoleAtom)
  const [logos, setLogos] = useState([])
  const { user } = useInnovaAuth()
  const bhaImages = useBhaImages()

  const getData = useAxiosGzip({
    url: '/engineering/getHydraulicsGz',
  })

  const getOperatorLogos = useInnovaAxios({
    url: '/operator/getLogos',
  })

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
    }
  }, [])

  useEffect(() => {
    currentWellRef.current = currentWell
  }, [currentWell])

  function isDateLessThan(date1, date2) {
    const time1 = new Date(date1).getTime()
    const time2 = new Date(date2).getTime()

    return time1 < time2
  }

  const dateTimeFormatter = useCallback((value) => {
    if (!value) return ''
    if (typeof value !== 'string') return ''
    if (value === '') return ''
    if (isDateLessThan(value, '1990-01-01')) return ''
    value = value.replace(/Z/g, '')
    return new Date(Date.parse(value)).toLocaleDateString('default', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    })
  }, [])

  const fetchHydraulics = async () => {
    if (typeof currentWellRef.current !== 'string') return
    if (currentWellRef.current === '') return
    if (selectedEngCase === null || selectedEngCase === undefined) return
    if (!selectedEngCase.hasOwnProperty('recordId')) return
    if (isLoading.current) return
    isLoading.current = true
    if (_isMounted.current) setLoading(true)

    const response = await getData({
      wellName: currentWellRef.current,
      engCase: selectedEngCase?.recordId,
      incDiagnostic: true,
    })
    if (_isMounted.current) {
      setLoading(false)
      hydraulicsData.current = response?.data ? response.data : null
    }

    isLoading.current = false

    if (isLoading.current) return
    isLoading.current = true
    if (_isMounted.current) setLoading(true)

    const responseLogos = await getOperatorLogos({ operator: hydraulicsData.current.jobDetails?.operator })

    if (!_isMounted.current) return
    setLoading(false)
    isLoading.current = false

    if (responseLogos?.error) return
    if (!responseLogos?.data) return

    const mfpBoundary = responseLogos.data.substring(2, responseLogos.data.search('Content')).trim()
    let parts = multiParse(Buffer.from(responseLogos.data), mfpBoundary)
    let logoData = parts.map((part) => {
      let logoObj = { ...part }
      logoObj.data = 'data:image/*;base64,' + part.data
      return logoObj
    })
    setLogos(logoData)
  }

  const interpChartColor = (pallete, index, maxSeries) => {
    let bluePalette = ['#006cd1', '#00deff']
    let redPalette = ['#fa6e7a', '#fa000c']
    let greenPalette = ['#93fab4', '#8ee800']

    let startColor = bluePalette[0]
    let endColor = bluePalette[1]
    if (pallete === 'green') {
      startColor = greenPalette[0]
      endColor = greenPalette[1]
    }

    if (pallete === 'red') {
      startColor = redPalette[0]
      endColor = redPalette[1]
    }

    let ratio = normalize(index, 0, maxSeries)
    return lerpColor(startColor, endColor, ratio)
  }

  const getSeriesValues = (data) => {
    if (!Array.isArray(data)) return []
    let output = []
    data.forEach((pt) => {
      output.push({
        x: pt.hasOwnProperty('data') ? pt.data : 0,
        y: pt.depth,
      })
    })

    return output
  }

  const getGeometryAnnotations = () => {
    if (!hydraulicsData.current) return null
    if (!hydraulicsData.current.hasOwnProperty('geometry')) return null
    if (!hydraulicsData.current.hasOwnProperty('sppOutput')) return null
    if (!Array.isArray(hydraulicsData.current.geometry?.geometry)) return null
    if (!Array.isArray(hydraulicsData.current.sppOutput)) return null

    let maxDepth = 0
    if (hydraulicsData.current.sppOutput.length > 0) {
      let curSeries = hydraulicsData.current.sppOutput[0]
      if (Array.isArray(curSeries.data)) {
        maxDepth = curSeries.data[curSeries.data.length - 1].depth
      }
    }

    const annotations = {}
    for (let i = 0; i < hydraulicsData.current.geometry?.geometry.length; i++) {
      if (hydraulicsData.current.geometry?.geometry[i].md > maxDepth) continue
      annotations[`geometry` + (i + 1)] = {
        type: 'line',
        value: hydraulicsData.current.geometry?.geometry[i].md,
        scaleID: 'y',
        borderColor: appColors.itemTextColor,
        borderDash: [10, 4],
        borderDashOffset: 5,
        borderWidth: 1,
        lineTension: 0.8,
        label: {
          content: `${hydraulicsData.current.geometry?.geometry[i].od} ${hydraulicsData.current.geometry?.geometry[i].compType}`,
          enabled: true,
          backgroundColor: 'transparent',
          position: 'start',
          color: appColors.itemTextColor,
          font: { weight: 'normal' },
          yAdjust: -5,
          display: true,
        },
      }
    }
    return annotations
  }

  const getPumpPressureData = () => {
    let chartData = {
      labels: ['x', 'y'],
      datasets: [],
    }

    if (!hydraulicsData.current) return chartData
    if (!hydraulicsData.current.hasOwnProperty('sppOutput')) return chartData
    if (!Array.isArray(hydraulicsData.current.sppOutput)) return chartData
    if (hydraulicsData.current.sppOutput.length === 0) return chartData

    for (let i = 0; i < hydraulicsData.current.sppOutput.length; i++) {
      let curSeries = hydraulicsData.current.sppOutput[i]

      chartData.datasets.push({
        label: curSeries.title,
        borderColor: interpChartColor('red', i, hydraulicsData.current.sppOutput.length),
        backgroundColor: interpChartColor('red', i, hydraulicsData.current.sppOutput.length),
        data: getSeriesValues(curSeries.data),
      })
    }

    return chartData
  }

  const getEcdSnapShotData = () => {
    let chartData = {
      labels: ['x', 'y'],
      datasets: [],
    }

    if (!hydraulicsData.current) return chartData
    if (!hydraulicsData.current.hasOwnProperty('ecdSnapshot')) return chartData
    if (!hydraulicsData.current.hasOwnProperty('mudWeight')) return chartData
    if (!Array.isArray(hydraulicsData.current.ecdSnapshot)) return chartData
    if (!Array.isArray(hydraulicsData.current.mudWeight)) return chartData
    if (hydraulicsData.current.ecdSnapshot.length === 0) return chartData

    for (let i = 0; i < hydraulicsData.current.ecdSnapshot.length; i++) {
      let curSeries = hydraulicsData.current.ecdSnapshot[i]

      chartData.datasets.push({
        label: curSeries.title,
        borderColor: interpChartColor('red', i, hydraulicsData.current.ecdSnapshot.length),
        backgroundColor: interpChartColor('red', i, hydraulicsData.current.ecdSnapshot.length),
        data: getSeriesValues(curSeries.data),
      })
    }

    for (let i = 0; i < hydraulicsData.current.mudWeight.length; i++) {
      let curSeries = hydraulicsData.current.mudWeight[i]

      chartData.datasets.push({
        label: curSeries.title,
        borderColor: interpChartColor('green', i, hydraulicsData.current.mudWeight.length),
        backgroundColor: interpChartColor('green', i, hydraulicsData.current.mudWeight.length),
        data: getSeriesValues(curSeries.data),
      })
    }

    return chartData
  }

  const getMinFlowData = () => {
    let chartData = {
      labels: ['x', 'y'],
      datasets: [],
    }

    if (!hydraulicsData.current) return chartData
    if (!hydraulicsData.current.hasOwnProperty('minFlowRates')) return chartData
    if (!Array.isArray(hydraulicsData.current.minFlowRates)) return chartData
    if (hydraulicsData.current.minFlowRates.length === 0) return chartData

    for (let i = 0; i < hydraulicsData.current.minFlowRates.length; i++) {
      let curSeries = hydraulicsData.current.minFlowRates[i]

      chartData.datasets.push({
        label: curSeries.title,
        borderColor: interpChartColor('red', i, hydraulicsData.current.minFlowRates.length),
        backgroundColor: interpChartColor('red', i, hydraulicsData.current.minFlowRates.length),
        data: getSeriesValues(curSeries.data),
      })
    }

    return chartData
  }

  const getEcdData = () => {
    let chartData = {
      labels: ['x', 'y'],
      datasets: [],
    }

    if (!hydraulicsData.current) return chartData
    if (!hydraulicsData.current.hasOwnProperty('dirtyEcd')) return chartData
    if (!hydraulicsData.current.hasOwnProperty('cleanEcd')) return chartData
    if (!hydraulicsData.current.hasOwnProperty('mudWeight')) return chartData
    if (!Array.isArray(hydraulicsData.current.dirtyEcd)) return chartData
    if (!Array.isArray(hydraulicsData.current.cleanEcd)) return chartData
    if (!Array.isArray(hydraulicsData.current.mudWeight)) return chartData
    if (hydraulicsData.current.dirtyEcd.length === 0) return chartData
    if (hydraulicsData.current.cleanEcd.length === 0) return chartData

    for (let i = 0; i < hydraulicsData.current.cleanEcd.length; i++) {
      let curSeries = hydraulicsData.current.cleanEcd[i]

      chartData.datasets.push({
        label: curSeries.title,
        borderColor: interpChartColor('blue', i, hydraulicsData.current.cleanEcd.length),
        backgroundColor: interpChartColor('blue', i, hydraulicsData.current.cleanEcd.length),
        data: getSeriesValues(curSeries.data),
      })
    }

    for (let i = 0; i < hydraulicsData.current.dirtyEcd.length; i++) {
      let curSeries = hydraulicsData.current.dirtyEcd[i]

      chartData.datasets.push({
        label: curSeries.title,
        borderColor: interpChartColor('red', i, hydraulicsData.current.dirtyEcd.length),
        backgroundColor: interpChartColor('red', i, hydraulicsData.current.dirtyEcd.length),
        data: getSeriesValues(curSeries.data),
      })
    }

    for (let i = 0; i < hydraulicsData.current.mudWeight.length; i++) {
      let curSeries = hydraulicsData.current.mudWeight[i]

      chartData.datasets.push({
        label: curSeries.title,
        borderColor: interpChartColor('green', i, hydraulicsData.current.mudWeight.length),
        backgroundColor: interpChartColor('green', i, hydraulicsData.current.mudWeight.length),
        data: getSeriesValues(curSeries.data),
      })
    }

    return chartData
  }

  const getSurgeAndSwabData = () => {
    let chartData = {
      labels: ['x', 'y'],
      datasets: [],
    }

    if (!hydraulicsData.current) return chartData
    if (!hydraulicsData.current.hasOwnProperty('surge')) return chartData
    if (!hydraulicsData.current.hasOwnProperty('swab')) return chartData
    if (!hydraulicsData.current.hasOwnProperty('mudWeight')) return chartData
    if (!Array.isArray(hydraulicsData.current.surge)) return chartData
    if (!Array.isArray(hydraulicsData.current.swab)) return chartData
    if (!Array.isArray(hydraulicsData.current.mudWeight)) return chartData
    if (hydraulicsData.current.surge.length === 0) return chartData
    if (hydraulicsData.current.swab.length === 0) return chartData

    for (let i = 0; i < hydraulicsData.current.surge.length; i++) {
      let curSeries = hydraulicsData.current.surge[i]

      chartData.datasets.push({
        label: curSeries.title,
        borderColor: interpChartColor('blue', i, hydraulicsData.current.surge.length),
        backgroundColor: interpChartColor('blue', i, hydraulicsData.current.surge.length),
        data: getSeriesValues(curSeries.data),
      })
    }

    for (let i = 0; i < hydraulicsData.current.mudWeight.length; i++) {
      let curSeries = hydraulicsData.current.mudWeight[i]

      chartData.datasets.push({
        label: curSeries.title,
        borderColor: interpChartColor('green', i, hydraulicsData.current.mudWeight.length),
        backgroundColor: interpChartColor('green', i, hydraulicsData.current.mudWeight.length),
        data: getSeriesValues(curSeries.data),
      })
    }

    for (let i = 0; i < hydraulicsData.current.swab.length; i++) {
      let curSeries = hydraulicsData.current.swab[i]

      chartData.datasets.push({
        label: curSeries.title,
        borderColor: interpChartColor('red', i, hydraulicsData.current.swab.length),
        backgroundColor: interpChartColor('red', i, hydraulicsData.current.swab.length),
        data: getSeriesValues(curSeries.data),
      })
    }

    return chartData
  }

  const extractTripSpeedFromTitle = (title) => {
    if (!title) return -1
    if (typeof title !== 'string') return -1

    title = title
      .toLowerCase()
      .replace('usft/min', '')
      .replace('ft/min', '')
      .replace('m/min', '')
      .replace('surge @', '')
      .replace('swab @', '')
      .trim()

    return parseFloat(title)
  }

  function interpExtrapTripSpeed(data, targetEmw) {
    if (!Array.isArray(data)) return 0
    if (data.length < 2) return 0
    // Sort the data array by emw in ascending order
    data.sort((a, b) => a.emw - b.emw)

    // Find the two data points that traddle the targetEmw
    let point1 = null
    let point2 = null

    for (let i = 0; i < data.length - 1; i++) {
      if (data[i].emw <= targetEmw && data[i + 1].emw >= targetEmw) {
        point1 = data[i]
        point2 = data[i + 1]
        break
      }
    }

    // If targetEmw is outside the range of the data, extrapolate
    if (!point1 || !point2) {
      if (targetEmw <= data[0].emw) {
        point1 = data[0]
        point2 = data[1]
      } else {
        point1 = data[data.length - 2]
        point2 = data[data.length - 1]
      }
    }

    // Perform linear interpolation
    const slope = (point2.tripSpeed - point1.tripSpeed) / (point2.emw - point1.emw)
    const interpolatedTripSpeed = point1.tripSpeed + slope * (targetEmw - point1.emw)
    return interpolatedTripSpeed
  }

  const getMinMaxTripSpeedData = (minEmw = 8.6, maxEmw = 10.5) => {
    let chartData = {
      labels: ['x', 'y'],
      datasets: [],
    }

    if (!hydraulicsData.current) return chartData
    if (!hydraulicsData.current.hasOwnProperty('surge')) return chartData
    if (!hydraulicsData.current.hasOwnProperty('swab')) return chartData

    if (!Array.isArray(hydraulicsData.current.surge)) return chartData
    if (!Array.isArray(hydraulicsData.current.swab)) return chartData

    if (hydraulicsData.current.surge.length === 0) return chartData
    if (hydraulicsData.current.swab.length === 0) return chartData

    if (!Array.isArray(hydraulicsData.current.surge[0].data)) return chartData
    if (hydraulicsData.current.surge[0].data.length === 0) return chartData

    let numDepthPoints = hydraulicsData.current.surge[0].data.length
    let tripSpeeds = []

    for (let i = 0; i < hydraulicsData.current.surge.length; i++) {
      let tripSpeed = extractTripSpeedFromTitle(hydraulicsData.current.surge[i].title)
      if (tripSpeed < 0) continue
      tripSpeeds.push(tripSpeed)
    }

    //Minimum
    chartData.datasets.push({
      label: 'Trip Out Speed',
      borderColor: interpChartColor('blue', 0, 1),
      backgroundColor: interpChartColor('blue', 0, 1),
      data: [],
    })

    chartData.datasets.push({
      label: 'Trip In Speed',
      borderColor: interpChartColor('red', 0, 1),
      backgroundColor: interpChartColor('red', 0, 1),
      data: [],
    })

    for (let i = 0; i < numDepthPoints; i++) {
      let tripOutData = tripSpeeds.map((tripSpeed, index) => {
        return { emw: hydraulicsData.current.swab[index].data[i].data, tripSpeed: tripSpeed }
      })
      let tripInData = tripSpeeds.map((tripSpeed, index) => {
        return { emw: hydraulicsData.current.surge[index].data[i].data, tripSpeed: tripSpeed }
      })

      chartData.datasets[0].data.push({
        x: interpExtrapTripSpeed(tripOutData, minEmw),
        y: hydraulicsData.current.surge[0].data[i].depth,
      })
      chartData.datasets[1].data.push({
        x: interpExtrapTripSpeed(tripInData, maxEmw),
        y: hydraulicsData.current.surge[0].data[i].depth,
      })
    }

    return chartData
  }

  const getShadingColor = (index) => {
    if (index === 0) return 'rgb(255, 0, 0)'
    if (index === 1) return 'rgb(255, 155, 0)'
    return 'rgb(0, 155, 0)'
  }

  const getKickToleranceData = (shadeTolerance) => {
    let chartData = {
      labels: ['x', 'y'],
      datasets: [],
    }

    if (!hydraulicsData.current) return chartData
    if (!hydraulicsData.current.hasOwnProperty('kickTolerance')) return chartData
    if (!hydraulicsData.current.hasOwnProperty('kickToleranceSwabbed')) return chartData
    if (!Array.isArray(hydraulicsData.current.kickTolerance)) return chartData
    if (!Array.isArray(hydraulicsData.current.kickToleranceSwabbed)) return chartData
    if (hydraulicsData.current.kickTolerance.length === 0) return chartData
    if (hydraulicsData.current.kickToleranceSwabbed.length === 0) return chartData

    for (let i = 0; i < hydraulicsData.current.kickTolerance.length; i++) {
      let curSeries = hydraulicsData.current.kickTolerance[i]

      chartData.datasets.push({
        label: curSeries.title,
        borderColor: interpChartColor('blue', i, hydraulicsData.current.kickTolerance.length),
        backgroundColor: interpChartColor('blue', i, hydraulicsData.current.kickTolerance.length),
        data: getSeriesValues(curSeries.data),
        showShading: false,
        color: null,
      })
    }

    for (let i = 0; i < hydraulicsData.current.kickToleranceSwabbed.length; i++) {
      let curSeries = hydraulicsData.current.kickToleranceSwabbed[i]

      chartData.datasets.push({
        label: curSeries.title,
        borderColor: interpChartColor('red', i, hydraulicsData.current.kickToleranceSwabbed.length),
        backgroundColor: interpChartColor('red', i, hydraulicsData.current.kickToleranceSwabbed.length),
        data: getSeriesValues(curSeries.data),
        showShading: false,
        color: null,
      })
    }

    if (!hydraulicsData.current.hasOwnProperty('kickToleranceLimits')) return chartData
    if (!Array.isArray(hydraulicsData.current.kickToleranceLimits)) return chartData
    if (hydraulicsData.current.kickToleranceLimits.length === 0) return chartData

    for (let i = 0; i < hydraulicsData.current.kickToleranceLimits.length; i++) {
      let curSeries = hydraulicsData.current.kickToleranceLimits[i]

      chartData.datasets.push({
        label: curSeries.title,
        borderColor: getShadingColor(i),
        backgroundColor: getShadingColor(i),
        data: getSeriesValues(curSeries.data),
        showShading: shadeTolerance,
        color: shadeTolerance ? getShadingColor(i) : null,
      })
    }

    return chartData
  }

  const getHydWarnings = () => {
    if (!hydraulicsData.current) return []
    if (!hydraulicsData.current.hasOwnProperty('warnings')) return []
    if (!Array.isArray(hydraulicsData.current.warnings)) return []
    if (hydraulicsData.current.warnings.length === 0) return []

    return hydraulicsData.current.warnings
  }

  const getHydErrors = () => {
    if (!hydraulicsData.current) return []
    if (!hydraulicsData.current.hasOwnProperty('errors')) return []
    if (!Array.isArray(hydraulicsData.current.errors)) return []
    if (hydraulicsData.current.errors.length === 0) return []

    return hydraulicsData.current.errors
  }

  const getLogo = (type) => {
    if (!Array.isArray(logos)) return null
    if (logos.length === 0) return null
    if (type === 'Primary') return logos[0].data
    if (type === 'Secondary' && logos.length > 1) return logos[1].data
    return null
  }

  const getLogoDimensions = (logo) => {
    return new Promise((resolve, reject) => {
      const img = new Image()
      img.onload = () => resolve({ width: img.width, height: img.height })
      img.onerror = (err) => reject(err)
      img.src = logo
    })
  }

  const calculateDimensions = async (logo) => {
    if (!logo) return null

    try {
      const { width, height } = await getLogoDimensions(logo)
      return width > height ? 'contain' : 'cover'
    } catch (err) {
      console.error('Error calculating dimensions:', err)
      return null
    }
  }

  const getLogoFill = async (type) => {
    if (!Array.isArray(logos) || logos.length === 0) return null

    const logo = type === 'Primary' ? logos[0]?.data : logos[1]?.data
    if (!logo) return null

    const fillType = await calculateDimensions(logo)
    return fillType
  }

  const generatePdfDocument = async (
    pumpPressureImage,
    ecdImage,
    ecdSnapShotImage,
    surgeAndSwabImage,
    trippingSpeedsImage,
    minimumFlowImage,
    kickToleranceImage,
    imageHeight,
    selectedChart,
  ) => {
    if (!hydraulicsData.current) return
    let logoFill = await getLogoFill('Primary')
    let logoSecondaryFill = await getLogoFill('Secondary')

    let docData = [
      {
        tableType: 'header',
        showTitle: true,
        title: 'Hydraulics Report',
        showLogo: true,
        logo: getLogo('Primary'),
        logoWidth: '25%',
        logoFill: logoFill,
        logoSecondary: getLogo('Secondary'),
        logoSecondaryWidth: '25%',
        logoSecondaryFill: logoSecondaryFill,
        fontSize: 6,
        sectionAfter: 5,
        manualWidth: true,
        columnWidths: ['50%', '50%'],
        data: [
          [
            { text: 'Well:', isHeader: true },
            { text: currentWellRef.current, textAlign: 'left' },
          ],
          [
            { text: 'Case:', isHeader: true },
            { text: unescapeHtml(selectedEngCase?.caseDesc.replace('&dq;', '"')), textAlign: 'left' },
          ],
        ],
      },
      {
        columnWidths: ['15%', '18%', '15%', '18%', '15%', '18%'],
        manualWidth: true,
        fontSize: 6,
        sectionAfter: 5,
        data: [
          [
            { text: 'Client:', isHeader: true },
            { text: hydraulicsData.current.jobDetails?.operator, textAlign: 'left' },
            { text: 'Field:', isHeader: true },
            { text: hydraulicsData.current.jobDetails?.field, textAlign: 'left' },
            { text: 'Well:', isHeader: true },
            { text: hydraulicsData.current.jobDetails?.wellName, textAlign: 'left' },
          ],
          [
            { text: 'Case:', isHeader: true },
            { text: unescapeHtml(selectedEngCase?.caseDesc.replace('&dq;', '"')), textAlign: 'left' },
            { text: 'Prepared By:', isHeader: true },
            { text: getUserNameFromEmail(user?.name), textAlign: 'left' },
            { text: 'Date:', isHeader: true },
            { text: dateTimeFormatter(new Date(Date.now()).toISOString()), textAlign: 'left' },
          ],
        ],
      },
      {
        fontSize: 6,
        manualWidth: true,
        sectionAfter: 5,
        columnWidths: ['18%', '16%', '16%', '18%', '16%', '16%'],
        wrap: false,
        data: addCalculationParameters(hydraulicsData.current.fluids, hydraulicsData.current.params),
      },
      {
        fontSize: 6,
        manualWidth: true,
        sectionAfter: 5,
        columnWidths: ['50%', '50%'],
        wrap: false,
        data: addStaticLosses(hydraulicsData.current.staticLosses),
      },
      {
        fontSize: 6,
        manualWidth: true,
        sectionAfter: 5,
        columnWidths: ['50%', '50%'],
        wrap: false,
        data: addMudRheology(hydraulicsData.current.fluids),
      },
      {
        fontSize: 6,
        manualWidth: true,
        sectionAfter: 5,
        columnWidths: ['20%', '20%', '20%', '20%', '20%'],
        wrap: false,
        data: addWellboreGeometry(hydraulicsData.current.wellGeometry),
      },
      {
        fontSize: 6,
        manualWidth: true,
        sectionAfter: 5,
        columnWidths: ['4%', '4%', '8%', '8%', '8%', '8%', '8%', '8%', '8%', '8%', '8%', '8%', '12%'],
        wrap: false,
        data: addDrillStrings(hydraulicsData.current.drillStrings, hydraulicsData.current.params?.calcDepth),
      },
    ]

    if (selectedChart === 'kickTolerance') {
      docData.push(
        {
          breakHeader: true,
          sectionAfter: 5,
          data: [[{ text: kickToleranceImage, isImage: true, width: '100%', height: imageHeight }]],
        },
      )

      return docData
    }

    for (let i = 0; i < hydraulicsData.current.diagnosticOutput.length; i++) {
      let title = ""
      if (hydraulicsData.current.diagnosticOutput.length === hydraulicsData.current.dirtyEcd.length) {
        const index = hydraulicsData.current.dirtyEcd[i].title.indexOf('@')
        if (index !== -1){
          title = hydraulicsData.current.dirtyEcd[i].title.substring(index)
        }
      }
      docData.push({
        breakHeader: true,
        fontSize: 6,
        manualWidth: true,
        sectionAfter: 5,
        multiPage: true,
        multiPageRow: 2,
        columnWidths: ['9%', '9%', '9%', '9%', '9%', '9%', '9%', '9%', '9%', '9%', '10%'],
        data: addDiagnosticOutput(hydraulicsData.current.diagnosticOutput[i], title),
      })
    }

    docData.push(
      {
        breakHeader: true,
        sectionAfter: 5,
        data: [[{ text: pumpPressureImage, isImage: true, width: '100%', height: imageHeight }]],
      },
      {
        sectionAfter: 5,
        data: [[{ text: ecdImage, isImage: true, width: '100%', height: imageHeight }]],
      },
      {
        breakHeader: true,
        sectionAfter: 5,
        data: [[{ text: ecdSnapShotImage, isImage: true, width: '100%', height: imageHeight }]],
      },
      {
        sectionAfter: 5,
        data: [[{ text: surgeAndSwabImage, isImage: true, width: '100%', height: imageHeight }]],
      },
      {
        breakHeader: true,
        sectionAfter: 5,
        data: [[{ text: trippingSpeedsImage, isImage: true, width: '100%', height: imageHeight }]],
      },
      {
        sectionAfter: 5,
        data: [[{ text: minimumFlowImage, isImage: true, width: '100%', height: imageHeight }]],
      },
    )

    return docData
  }

  const addDiagnosticOutput = (diagnosticOutput, title) => {
    if (!diagnosticOutput) return []
    if (!Array.isArray(diagnosticOutput)) return []

    let data = []
    data.push(
      [{ text: `Hydraulics Summary - ${title}`, columnSpan: 11 }],
      [
        { text: `Depth (${getUnitsText(UNITS_FOR.Depth)})`, isHeader: true },
        { text: `Flow Rate (${getUnitsText(UNITS_FOR.FlowRate)})`, isHeader: true },
        { text: `SPP (${getUnitsText(UNITS_FOR.Pressure)})`, isHeader: true },
        { text: `Clean ECD (${getUnitsText(UNITS_FOR.MudWeight)})`, isHeader: true },
        { text: `Dirty ECD (${getUnitsText(UNITS_FOR.MudWeight)})`, isHeader: true },
        { text: `Bit P. Loss (${getUnitsText(UNITS_FOR.Pressure)})`, isHeader: true },
        { text: `Impact Force (${getUnitsText(UNITS_FOR.Force)})`, isHeader: true },
        { text: 'HHP', isHeader: true },
        { text: 'HHP/sqin', isHeader: true },
        { text: `JV (${getUnitsText(UNITS_FOR.Depth)}/s)`, isHeader: true },
        { text: 'Description', isHeader: true },
      ],
    )

    for (let i = 0; i < diagnosticOutput.length; i++) {
      if (!diagnosticOutput[i]) continue
      if (!diagnosticOutput[i].hasOwnProperty('bitData')) continue
      if (!Array.isArray(diagnosticOutput[i].bitData)) continue
      data.push([
        { text: numberWithCommasDecimals(diagnosticOutput[i]?.depth, 2) },
        { text: numberWithCommasDecimals(diagnosticOutput[i]?.flowRate, 2) },
        { text: numberWithCommasDecimals(diagnosticOutput[i]?.spp, 2) },
        { text: numberWithCommasDecimals(diagnosticOutput[i]?.ecd, 2) },
        { text: numberWithCommasDecimals(diagnosticOutput[i]?.dirtyEcd, 2) },
        { text: numberWithCommasDecimals(diagnosticOutput[i].bitData[0]?.pressureDrop, 2) },
        { text: numberWithCommasDecimals(diagnosticOutput[i].bitData[0]?.impactForce, 2) },
        { text: numberWithCommasDecimals(diagnosticOutput[i].bitData[0]?.hhp, 2) },
        { text: numberWithCommasDecimals(diagnosticOutput[i].bitData[0]?.hhpSqin, 2) },
        { text: numberWithCommasDecimals(diagnosticOutput[i].bitData[0]?.jv, 2) },
        { text: unescapeHtml(diagnosticOutput[i].bitData[0]?.description) },
      ])
    }

    return data
  }

  const addWellboreGeometry = (wellGeometry) => {
    if (!wellGeometry) return []
    if (!Array.isArray(wellGeometry)) return []

    let data = []
    data.push(
      [{ text: 'Wellbore Geometry', columnSpan: 5 }],
      [
        { text: 'Component Type', isHeader: true },
        { text: `MD (${getUnitsText(UNITS_FOR.Depth)})`, isHeader: true },
        { text: `TVD (${getUnitsText(UNITS_FOR.Depth)})`, isHeader: true },
        { text: `OD (${getUnitsText(UNITS_FOR.Diameter)})`, isHeader: true },
        { text: `ID (${getUnitsText(UNITS_FOR.Diameter)})`, isHeader: true },
      ],
    )

    for (let i = 0; i < wellGeometry.length; i++) {
      data.push([
        { text: unescapeHtml(wellGeometry[i].compType) },
        { text: numberWithCommasDecimals(wellGeometry[i].md, 2) },
        { text: numberWithCommasDecimals(wellGeometry[i].tvd, 2) },
        { text: numberWithCommasDecimals(wellGeometry[i].od, 3) },
        { text: numberWithCommasDecimals(wellGeometry[i].id, 3) },
      ])
    }

    return data
  }

  const addCalculationParameters = (fluids, params) => {
    if (!fluids) return []
    if (!params) return []

    let data = []
    data.push(
      [{ text: 'Calculation Parameters', columnSpan: 6 }],
      [
        { text: `Calculation Depth (${getUnitsText(UNITS_FOR.Depth)})`, isHeader: true },
        { text: numberWithCommasDecimals(params?.calcDepth, 2) },
        { text: `Mud Weight (${getUnitsText(UNITS_FOR.UnitWeight)})`, isHeader: true },
        { text: numberWithCommasDecimals(params?.mudWeight, 2) },
        { text: `Torque on Bit (${getUnitsText(UNITS_FOR.Torque)})`, isHeader: true },
        { text: numberWithCommasDecimals(params?.torqueOnBit, 2) },
      ],
      [
        { text: `Density (${getUnitsText(UNITS_FOR.UnitWeight)})`, isHeader: true },
        { text: numberWithCommasDecimals(fluids.CuttingsWeight, 2) },
        { text: `Diameter (${getUnitsText(UNITS_FOR.Diameter)})`, isHeader: true },
        { text: numberWithCommasDecimals(fluids.CuttingsDia, 3) },
        { text: `Thickness (${getUnitsText(UNITS_FOR.Diameter)})`, isHeader: true },
        { text: numberWithCommasDecimals(fluids.cuttingsThick, 3) },
      ],
      [
        { text: `Weight on Bit (${getUnitsText(UNITS_FOR.Weight)})`, isHeader: true },
        { text: numberWithCommasDecimals(params?.wob, 2) },
        { text: `PV`, isHeader: true },
        { text: numberWithCommasDecimals(fluids.pv, 2) },
        { text: `YP`, isHeader: true },
        { text: numberWithCommasDecimals(fluids.yp, 2) },
      ],
    )

    return data
  }

  const addMudRheology = (fluids) => {
    if (!fluids) return []

    let data = []
    data.push(
      [{ text: 'Mud Rheology', columnSpan: 2 }],
      [{ text: '600', isHeader: true }, { text: numberWithCommasDecimals(fluids.sixhun, 0) }],
      [{ text: '300', isHeader: true }, { text: numberWithCommasDecimals(fluids.threehun, 0) }],
      [{ text: '200', isHeader: true }, { text: numberWithCommasDecimals(fluids.twohun, 0) }],
      [{ text: '100', isHeader: true }, { text: numberWithCommasDecimals(fluids.onehun, 0) }],
      [{ text: '6', isHeader: true }, { text: numberWithCommasDecimals(fluids.dialSix, 0) }],
      [{ text: '3', isHeader: true }, { text: numberWithCommasDecimals(fluids.dialThree, 0) }],
      [{ text: 'N', isHeader: true }, { text: numberWithCommasDecimals(fluids.n, 2) }],
      [{ text: 'K', isHeader: true }, { text: numberWithCommasDecimals(fluids.k, 2) }],
    )

    return data
  }

  const addStaticLosses = (staticLosses) => {
    if (!staticLosses) return []
    if (!Array.isArray(staticLosses)) return []

    let data = []

    data.push(
      [{ text: 'Static Losses', columnSpan: 2 }],
      [
        { text: 'Name', isHeader: true },
        { text: `Pressure Loss (${getUnitsText(UNITS_FOR.Pressure)})`, isHeader: true },
      ],
    )

    for (let i = 0; i < staticLosses.length; i++) {
      data.push([
        { text: unescapeHtml(staticLosses[i].name) },
        { text: numberWithCommasDecimals(staticLosses[i].pressureLoss, 2) },
      ])
    }

    return data
  }

  const addDrillStrings = (drillStrings, startDepth) => {
    if (!drillStrings) return []
    if (!Array.isArray(drillStrings)) return []

    let data = []
    data.push(
      [{ text: 'String Data', columnSpan: 13 }],

      [
        { text: '', isHeader: true },
        { text: '#', isHeader: true },
        { text: 'Description', isHeader: true },
        { text: 'Type', isHeader: true },
        { text: 'Material', isHeader: true },
        { text: `OD (${getUnitsText(UNITS_FOR.Diameter)})`, isHeader: true },
        { text: `ID (${getUnitsText(UNITS_FOR.Diameter)})`, isHeader: true },
        { text: `TJ OD (${getUnitsText(UNITS_FOR.Diameter)})`, isHeader: true },
        { text: `TJ ID (${getUnitsText(UNITS_FOR.Diameter)})`, isHeader: true },
        { text: `Unit Weight (${getUnitsText(UNITS_FOR.UnitWeight)})`, isHeader: true },
        { text: `Length (${getUnitsText(UNITS_FOR.Depth)})`, isHeader: true },
        { text: `Total Length (${getUnitsText(UNITS_FOR.Depth)})`, isHeader: true },
        { text: `MD Start/End (${getUnitsText(UNITS_FOR.Depth)})`, isHeader: true },
      ],
    )

    let totalCompWeight = 0
    let cumLength = 0

    for (let i = 0; i < drillStrings.length; i++) {
      totalCompWeight = totalCompWeight + drillStrings[i].casingWeight * drillStrings[i].length
      cumLength += drillStrings[i].length

      let compTop = startDepth - cumLength
      let compBottom = compTop + drillStrings[i].length
      let image = bhaImages.getPrintImage(drillStrings[i], false)

      data.push([
        { text: image, isImage: true, height: 31, rotate: false },
        { text: `${i + 1}` },
        { text: unescapeHtml(drillStrings[i].pipeName.replace('&dq;', '"')) },
        { text: unescapeHtml(drillStrings[i].type.replace('&dq;', '"')) },
        { text: unescapeHtml(drillStrings[i].material?.materialName.replace('&dq;', '"')) },
        { text: numberWithCommasDecimals(drillStrings[i].pipeOd, 3) },
        { text: numberWithCommasDecimals(drillStrings[i].pipeId, 3) },
        { text: drillStrings[i].tjod ? numberWithCommasDecimals(drillStrings[i].tjod, 3) : '-' },
        { text: drillStrings[i].tjid ? numberWithCommasDecimals(drillStrings[i].tjid, 3) : '-' },
        { text: numberWithCommasDecimals(drillStrings[i].casingWeight, 2) },
        { text: numberWithCommasDecimals(drillStrings[i].length, 2) },
        { text: numberWithCommasDecimals(cumLength, 2) },
        {
          text: `${numberWithCommasDecimals(compTop, 2)}/${numberWithCommasDecimals(compBottom, 2)}`,
        },
      ])
    }

    return data
  }

  const getHydPdfData = async (chartElement, wellElement, selectedChart) => {
    return new Promise(async (resolve) => {
      if (typeof currentWellRef.current !== 'string') return
      if (currentWellRef.current === '') return
      if (!selectedEngCase) return
      if (!selectedEngCase.hasOwnProperty('recordId')) return

      let title = `Hydraulics Report`

      if (selectedChart === 'kickTolerance') {
        title = `Kick Tolerance`
      }

      let pumpPressureElement = chartElement.getChartType('pumpPressure')
      const pumpPressureCanvas = await html2canvas(pumpPressureElement.current)
      const pumpPressureImage = pumpPressureCanvas.toDataURL('image/png', 1.0)

      let ecdElement = chartElement.getChartType('ecd')
      const ecdCanvas = await html2canvas(ecdElement.current)
      const ecdImage = ecdCanvas.toDataURL('image/png', 1.0)

      let ecdSnapShotElement = chartElement.getChartType('ecdSnapShot')
      const ecdSnapShotCanvas = await html2canvas(ecdSnapShotElement.current)
      const ecdSnapShotImage = ecdSnapShotCanvas.toDataURL('image/png', 1.0)

      let surgeAndSwabElement = chartElement.getChartType('surgeAndSwab')
      const surgeAndSwabCanvas = await html2canvas(surgeAndSwabElement.current)
      const surgeAndSwabImage = surgeAndSwabCanvas.toDataURL('image/png', 1.0)

      let trippingSpeedsElement = chartElement.getChartType('trippingSpeeds')
      const trippingSpeedsCanvas = await html2canvas(trippingSpeedsElement.current)
      const trippingSpeedsImage = trippingSpeedsCanvas.toDataURL('image/png', 1.0)

      let minimumFlowElement = chartElement.getChartType('minimumFlow')
      const minimumFlowCanvas = await html2canvas(minimumFlowElement.current)
      const minimumFlowImage = minimumFlowCanvas.toDataURL('image/png', 1.0)

      let kickToleranceElement = chartElement.getChartType('kickTolerance')
      const kickToleranceCanvas = await html2canvas(kickToleranceElement.current)
      const kickToleranceImage = kickToleranceCanvas.toDataURL('image/png', 1.0)

      const pdfDoc = await generatePdfDocument(
        pumpPressureImage,
        ecdImage,
        ecdSnapShotImage,
        surgeAndSwabImage,
        trippingSpeedsImage,
        minimumFlowImage,
        kickToleranceImage,
        300,
        selectedChart,
      )

      resolve({
        fileName: `${title} - ${replaceEscapeSymbols(removeSpecialSymbols(currentWellRef.current))}`,
        data: (
          <PdfDocument
            data={pdfDoc}
            multiDocument={false}
            pageOrientation={'portrait'}
            reportSettings={userRole?.userPrefs?.reportSettings}
            footerText={`${userRole.organization} powered by Innova ${new Date().getFullYear()}`}
          />
        ),
      })
    })
  }

  return {
    loading,
    fetchHydraulics,
    getPumpPressureData,
    getEcdData,
    getSurgeAndSwabData,
    getGeometryAnnotations,
    getHydWarnings,
    getHydErrors,
    getHydPdfData,
    getEcdSnapShotData,
    getMinFlowData,
    getKickToleranceData,
    getMinMaxTripSpeedData,
  }
}

export default useHydraulics
