import { pointInterp, pointInterpTVD, calcVS } from 'utils/surveyFunctions'
import { getTargets2D } from 'components/WellPages/PlanView/targets2D'
import { roundUp } from 'utils/numberFunctions'
import { numberWithCommasDecimals } from 'utils/stringFunctions'

// annotationPlugin properties:
export const DEFAULT_ANNO_LABEL = {
  type: 'label',
  xValue: 2.5,
  yValue: 60,
  backgroundColor: 'rgba(245,245,245)',
  content: ['This is my text', 'This is my text, second line'],
  font: {
    size: 18,
  },
}

export const DEFAULT_ANNO_PROPS = {
  anchor: {
    x: 0,
    y: 0,
  },
  currentPos: {
    x: 0,
    y: 0,
  },
  tether: {
    enabled: false,
    style: {
      color: 'rgba(0,0,0)',
      lineWidth: 1,
      lineDash: [],
    },
  },
  plugInProps: {
    ...DEFAULT_ANNO_LABEL,
  },
}

export const LABEL_CATEGORIES = {
  surveyPts: 'Depth, Inc, Azi',
  wellNames: 'Well Names',
  casing: 'Casing',
  annotations: 'Annotations',
  targets: 'Targets',
  lithologies: 'Lithologies',
  offsetWellNames: 'Offset Well Names',
  offsetSurveyPts: 'Offset Depth, Inc, Azi',
}

export const hasLabelCategory = (category, labels) => {
  if (!labels || !labels.categories) return false
  return labels.categories.some((cat) => cat.category === category)
}

export const getLabelAnnoDefaults = (content) => {
  return {
    type: 'label',
    callout: {
      display: true,
      borderColor: '#AAA',
    },
    content: content,
    font: {
      size: 10,
    },
  }
}

export const extractLabelUids = (input, labelSeparator) => {
  let separator = labelSeparator || '-'
  const lastDashIndex = input.lastIndexOf(separator)

  if (lastDashIndex === -1) {
    return [input, '']
  }
  const firstPart = input.slice(0, lastDashIndex)
  const secondPart = input.slice(lastDashIndex + 1)

  return [firstPart, secondPart]
}

export const getLabelPosByMd = (labelDepth, chartType, surveys, verticalSection) => {
  if (!surveys || surveys.length === 0) return { x: 0, y: 0 }
  const { VsAzi, VsOrgNorth, VsOrgEast } = verticalSection
  const { md, ew, ns, tvd } = pointInterp(surveys, labelDepth)
  if (md < 0) return { x: 0, y: 0 }
  const vs = calcVS(VsAzi, VsOrgNorth, VsOrgEast, ew, ns)

  return {
    x: chartType === 'plan' ? ew : vs,
    y: chartType === 'plan' ? ns : tvd,
  }
}

export const getLastVisibleSurveyPt = (surveys, visibleLimits, chartType) => {
  if (surveys?.length < 1) return { ew: 0, ns: 0, vs: 0, tvd: 0 }
  // visible limits should be {xMin, xMax, yMin, yMax}
  for (let i = surveys.length - 1; i >= 0; i--) {
    if (chartType === 'plan') {
      if (
        surveys[i].ew >= visibleLimits.xMin &&
        surveys[i].ew <= visibleLimits.xMax &&
        surveys[i].ns >= visibleLimits.yMin &&
        surveys[i].ns <= visibleLimits.yMax
      )
        return surveys[i]
    } else {
      if (
        surveys[i].vs >= visibleLimits.xMin &&
        surveys[i].vs <= visibleLimits.xMax &&
        surveys[i].tvd >= visibleLimits.yMin &&
        surveys[i].tvd <= visibleLimits.yMax
      )
        return surveys[i]
    }
  }
  return surveys[surveys.length - 1]
}

export const getPositionValues = (catType, id, wellData, settings) => {
  if (!wellData || !id || !catType) return 0
  let xValue = 0
  let yValue = 0
  let wellName = ''
  let md = 0
  let surveys = []
  let labelUidData = []
  let svyPt = null
  let offsetWell = null

  switch (catType) {
    case LABEL_CATEGORIES.surveyPts:
      labelUidData = extractLabelUids(id)
      wellName = labelUidData[0]
      md = parseFloat(labelUidData[1])
      if (wellName === wellData.actualWellData.actualWellName) {
        surveys = wellData.surveys
      }
      svyPt = surveys.find((survey) => survey.md === md)
      if (svyPt) {
        xValue = settings?.chartType === 'plan' ? svyPt.ew : svyPt.vs
        yValue = settings?.chartType === 'plan' ? svyPt.ns : svyPt.tvd
      }
      break
    case LABEL_CATEGORIES.wellNames:
      wellName = id
      if (wellName === wellData.actualWellData.actualWellName) {
        surveys = wellData.surveys
      }
      const lastVisibleSurvey = getLastVisibleSurveyPt(
        wellData.surveys,
        {
          xMin: settings?.xMin,
          xMax: settings?.xMax,
          yMin: settings?.yMin,
          yMax: settings?.yMax,
        },
        settings?.chartType,
      )

      xValue = settings?.chartType === 'plan' ? lastVisibleSurvey.ew : lastVisibleSurvey.vs
      yValue = settings?.chartType === 'plan' ? lastVisibleSurvey.ns : lastVisibleSurvey.tvd
      break
    case LABEL_CATEGORIES.casing:
      labelUidData = extractLabelUids(id)
      md = parseFloat(labelUidData[1])
      const casingPos = getLabelPosByMd(
        md,
        settings?.chartType,
        wellData.surveys,
        wellData.actualWellData.verticalSection,
      )
      xValue = casingPos.x
      yValue = casingPos.y
      break
    case LABEL_CATEGORIES.annotations:
      labelUidData = extractLabelUids(id)
      md = parseFloat(labelUidData[1])
      const annoPos = getLabelPosByMd(
        md,
        settings?.chartType,
        wellData.surveys,
        wellData.actualWellData.verticalSection,
      )
      xValue = annoPos.x
      yValue = annoPos.y
      break
    case LABEL_CATEGORIES.targets:
      labelUidData = extractLabelUids(id, '|')
      const targetName = labelUidData[1]
      const targetData = []
      getTargets2D(wellData.targets, targetData, settings?.chartType, wellData?.actualWellData?.verticalSection, true)
      const target = targetData.find((tgt) => tgt.text === targetName)
      if (target) {
        xValue = target.x
        yValue = target.y
      }
      break
    case LABEL_CATEGORIES.lithologies:
      labelUidData = extractLabelUids(id)
      let tvd = parseFloat(labelUidData[1])

      xValue = settings.xMin
      yValue = tvd
      break
    case LABEL_CATEGORIES.offsetWellNames:
      wellName = id
      offsetWell = wellData.offsetWells.find((well) => well.offsetWellbore === wellName)
      if (offsetWell) {
        xValue =
          settings?.chartType === 'plan'
            ? offsetWell.surveyData[offsetWell.surveyData.length - 1].ew
            : offsetWell.surveyData[offsetWell.surveyData.length - 1].vs
        yValue =
          settings?.chartType === 'plan'
            ? offsetWell.surveyData[offsetWell.surveyData.length - 1].ns
            : offsetWell.surveyData[offsetWell.surveyData.length - 1].tvd
      }
      break
    case LABEL_CATEGORIES.offsetSurveyPts:
      labelUidData = extractLabelUids(id)
      wellName = labelUidData[0]
      md = parseFloat(labelUidData[1])
      offsetWell = wellData.offsetWells.find((well) => well.offsetWellbore === wellName)
      if (offsetWell) {
        svyPt = offsetWell.surveyData.find((survey) => survey.md === md)
        if (svyPt) {
          xValue = settings?.chartType === 'plan' ? svyPt.ew : svyPt.vs
          yValue = settings?.chartType === 'plan' ? svyPt.ns : svyPt.tvd
        }
      }
      break
    default:
      console.warn('[Innova] unknown label id position', catType)
      break
  }

  return { xValue, yValue }
}

export const getLabelContent = (label, wellData, chartType) => {
  if (!label || !wellData) return []
  const { catType, uid } = label
  let content = []
  let wellName = ''
  let labelUidData = []
  let md = 0
  let offsetWell = null

  if (label.content && label.content.length > 0) {
    return label.content
  }

  switch (catType) {
    case LABEL_CATEGORIES.surveyPts:
      labelUidData = extractLabelUids(uid)
      wellName = labelUidData[0]
      md = parseFloat(labelUidData[1])
      if (wellName === wellData.actualWellData.actualWellName) {
        const surveys = wellData.surveys
        const svyPt = surveys.find((survey) => survey.md === md)
        console.warn('NEED NEW DEPTH LABEL ALGORITHM')
        if (svyPt) {
          content.push(`MD: ${svyPt.md}, INC: ${svyPt.inc}, AZI: ${svyPt.azi}`)
        }
      }
      break
    case LABEL_CATEGORIES.wellNames:
      wellName = uid
      if (wellName === wellData.actualWellData.actualWellName) {
        content.push(wellName)
      }
      break
    case LABEL_CATEGORIES.casing:
      labelUidData = extractLabelUids(uid)
      md = parseFloat(labelUidData[1])
      const casingItem = wellData.actualWellData.casing.find((casing) => casing.MD === md)
      if (casingItem) {
        content.push(`${casingItem.OD.toFixed(3)} ${casingItem.Type}`)
      }
      break
    case LABEL_CATEGORIES.annotations:
      labelUidData = extractLabelUids(uid)
      md = parseFloat(labelUidData[1])
      if (Array.isArray(wellData?.actualWellData?.annotations)) {
        const annoItem = wellData.actualWellData.annotations.find((anno) => anno.md === md)
        if (annoItem) {
          content.push(`${annoItem.annotation}`)
        }
      }
      break
    case LABEL_CATEGORIES.targets:
      labelUidData = extractLabelUids(uid, '|')
      const targetName = labelUidData[1]
      const targetData = []
      getTargets2D(wellData.targets, targetData, chartType, wellData?.actualWellData?.verticalSection, true)
      const target = targetData.find((tgt) => tgt.text === targetName)
      if (target) {
        content.push(`${target.text}`)
      }
      break
    case LABEL_CATEGORIES.lithologies:
      labelUidData = extractLabelUids(uid)
      let tvd = parseFloat(labelUidData[1])
      const lithItem = wellData.actualWellData.lithologies.find((lith) => lith.tvd === tvd)
      if (lithItem) {
        content.push(`${lithItem.formation}`)
      }
      break
    case LABEL_CATEGORIES.offsetWellNames:
      wellName = uid
      offsetWell = wellData.offsetWells.find((well) => well.offsetWellbore === wellName)
      if (offsetWell) {
        content.push(wellName)
      }
      break
    case LABEL_CATEGORIES.offsetSurveyPts:
      labelUidData = extractLabelUids(uid)
      wellName = labelUidData[0]
      md = parseFloat(labelUidData[1])
      offsetWell = wellData.offsetWells.find((well) => well.offsetWellbore === wellName)
      if (offsetWell) {
        const svyPt = offsetWell.surveyData.find((survey) => survey.md === md)
        if (svyPt) {
          content.push(`MD: ${svyPt.md}, INC: ${svyPt.inc}, AZI: ${svyPt.azi}`)
        }
      }
      break
    default:
      console.warn('[Innova] unknown label id content', catType)
      break
  }

  return content
}

export const getLabelColor = (categories, label) => {
  if (label.style?.color) return label.style.color // if the label is customized, return custom color
  const category = categories.find((cat) => cat.category === label.catType)
  return category?.color ? category.color : '#000000' // if the category has a color, return it, otherwise default
}

export const getHasTether = (categories, label) => {
  if (label.hasTether) return label.hasTether
  const category = categories.find((cat) => cat.category === label.catType)
  return category?.hasTether ? category.hasTether : false
}

export const getLabelSize = (categories, label) => {
  if (label.style?.font?.size) return label.style.font.size
  const category = categories.find((cat) => cat.category === label.catType)
  return category?.font?.size ? category.font.size : 10
}

export const getLabelBold = (categories, label) => {
  if (label.style?.font?.bold) return label.style.font.bold
  const category = categories.find((cat) => cat.category === label.catType)
  return category?.bold ? category.bold : false
}

const createSvyLabel = (svy, options) => {
  if (!svy) return ''
  let mdText = `MD: ${numberWithCommasDecimals(svy.md, 2)} `
  let tvdText = `TVD: ${numberWithCommasDecimals(svy.tvd, 2)} `
  let incText = `INC: ${numberWithCommasDecimals(svy.inc, 2)} `
  let aziText = `AZI: ${numberWithCommasDecimals(svy.azi, 2)} `

  if (!options) return mdText + incText + aziText
  let text = options.mdTvd === 'MD' ? mdText : tvdText
  if (options.inc) text += incText
  if (options.azi) text += aziText
  return text
}

export const generateDepthLabels = (labelCategory, wellData, catOptions) => {
  let options = {
    atSurvey: true,
    azi: true,
    inc: true,
    mdTvd: 'MD',
    range: false,
    startDepth: 0,
    endDepth: 0,
  }

  if (Array.isArray(catOptions) && catOptions.length > 0) {
    options = catOptions.find((opt) => opt.category === labelCategory)?.options
  }

  if (typeof options.startDepth !== 'number') options.startDepth = 0
  if (typeof options.endDepth !== 'number') options.endDepth = 0

  if (options.startDepth > options.endDepth) {
    let temp = options.startDepth
    options.startDepth = options.endDepth
    options.endDepth = temp
  }

  if (!Array.isArray(wellData.surveys)) {
    wellData.surveys = []
  }

  let labels = []
  for (let i = 0; i < wellData.surveys.length; i++) {
    let useSvy = true
    let val = options.mdTvd === 'MD' ? wellData.surveys[i].md : wellData.surveys[i].tvd

    if (options.range) {
      if (val < options.startDepth || val > options.endDepth) {
        useSvy = false
      }
    }

    if (!useSvy) continue
    labels.push({ ...wellData.surveys[i], label: createSvyLabel(wellData.surveys[i], options) })
  }

  if (!options?.atSurvey && options?.interval > 1 && wellData.surveys.length > 0) {
    let minVal = wellData.surveys[0].md
    let maxVal = wellData.surveys[wellData.surveys.length - 1].md

    if (options.mdTvd === 'TVD') {
      minVal = 99999999
      maxVal = 0

      for (let i = 0; i < wellData.surveys.length; i++) {
        if (wellData.surveys[i].tvd < minVal) {
          minVal = wellData.surveys[i].tvd
        }

        if (wellData.surveys[i].tvd > maxVal) {
          maxVal = wellData.surveys[i].tvd
        }
      }
    }

    if (options.range) {
      minVal = options.startDepth >= minVal ? options.startDepth : minVal
      maxVal = options.endDepth <= maxVal ? options.endDepth : maxVal
    }

    if (!options.range) {
      minVal = roundUp(minVal, options.interval)
      maxVal = roundUp(maxVal, options.interval)
    }

    const { VsAzi, VsOrgNorth, VsOrgEast } = wellData.actualWellData.verticalSection
    for (let i = minVal; i <= maxVal; i += options.interval) {
      let svy = options.mdTvd === 'TVD' ? pointInterpTVD(wellData.surveys, i) : pointInterp(wellData.surveys, i)
      if (svy.md < 0) continue

      svy.vs = calcVS(VsAzi, VsOrgNorth, VsOrgEast, svy.ew, svy.ns)
      labels.push({ ...svy, label: createSvyLabel(wellData.surveys[i], options) })
    }
  }

  return labels
}

function isEqual(obj1, obj2, ignoreList = []) {
  // Helper function to check if a property should be ignored
  function shouldIgnore(prop) {
    return ignoreList.includes(prop)
  }

  // Helper function to compare two values deeply
  function deepCompare(val1, val2) {
    if (typeof val1 !== typeof val2) return false

    if (typeof val1 === 'object' && val1 !== null && val2 !== null) {
      if (Array.isArray(val1) !== Array.isArray(val2)) return false

      if (Array.isArray(val1)) {
        if (val1.length !== val2.length) return false
        for (let i = 0; i < val1.length; i++) {
          if (!deepCompare(val1[i], val2[i])) return false
        }
        return true
      } else {
        const keys1 = Object.keys(val1).filter((key) => !shouldIgnore(key))
        const keys2 = Object.keys(val2).filter((key) => !shouldIgnore(key))

        if (keys1.length !== keys2.length) return false

        for (let key of keys1) {
          if (!keys2.includes(key) || !deepCompare(val1[key], val2[key])) return false
        }
        return true
      }
    } else {
      return val1 === val2
    }
  }

  return deepCompare(obj1, obj2)
}

export const shouldRegenerateLabels = (prevOptions, nextOptions) => {
  if (!prevOptions || !nextOptions) return true
  if (!isEqual(prevOptions, nextOptions)) return true

  // if label definitions are separated from the possibly-interpolated survey list, the below
  // checks would be useful to avoid rerenders. currently label is embedded in the survey array,
  // so array must be regenerated if any change is made to the label options.
  //
  // if (prevOptions.mdTvd !== nextOptions.mdTvd) return true
  // if (prevOptions.atSurvey !== nextOptions.atSurvey) return true
  // if (nextOptions.atSurvey && prevOptions.interval !== nextOptions.interval) return true
  // if (prevOptions.range !== nextOptions.range) return true
  // if (
  //   nextOptions.range &&
  //   (prevOptions.startDepth !== nextOptions.startDepth || prevOptions.endDepth !== nextOptions.endDepth)
  // )
  //   return true

  return false
}
