import { useEffect, useState, useRef } from 'react'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import { currentWellAtom, userUserRoleAtom } from 'atoms'
import { useRecoilValue } from 'recoil'
import { cloneDeep } from 'lodash'
import { removeSpecialSymbols } from 'utils/stringFunctions'
import useOrgIcons from './useOrgIcons'
import PdfDocument from 'components/common/PDFGen/PdfDocument'
import useUnits, { UNITS_FOR } from 'components/common/hooks/useUnits'
import { unescapeHtml } from 'utils/htmlSymbolHandling'

function useShippingTickets(wellName) {
    const _isMounted = useRef(false)
    const [isLoading, setIsLoading] = useState(false)
    const isDeleting = useRef(false)
    const isUpdating = useRef(false)
    const isAdding = useRef(false)
    const currentWell = useRecoilValue(currentWellAtom)
    const currentWellRef = useRef(wellName ? wellName : currentWell)
    const [wellInfo, setWellInfo] = useState()
    const shippingAddressesData = useRef([])
    const shippingTicketData = useRef(null)
    const [inventoryData, setInventoryData] = useState(null)
    const { getCurrentOrgIcon } = useOrgIcons()
    const { getUnitsText } = useUnits()
    const userRole = useRecoilValue(userUserRoleAtom)

    const getWellInfo = useInnovaAxios({
        url: '/well/wellInfo/getWellInfo',
    })

    const getShippingTicket = useInnovaAxios({
        url: '/shippingTickets/getShippingTickets',
    })

    const addShippingTicket = useInnovaAxios({
        url: '/shippingTickets/addShippingTicket',
    })

    const updateShippingTicket = useInnovaAxios({
        url: '/shippingTickets/updateShippingTicket'
    })

    const deleteShippingTicket = useInnovaAxios({
        url: '/shippingTickets/deleteShippingTicket'
    })

    const getWellInventory = useInnovaAxios({
        url: '/well/toolInventory/getInventory',
    })

    const updateShippingTicketTools = useInnovaAxios({
        url: '/shippingTickets/updateShippingTicketTools'
    })

    const getShippingAddresses = useInnovaAxios({
        url: '/shippingAddresses/getAddresses',
    })

    useEffect(() => {
        _isMounted.current = true
        fetchShippingAddresses()
        return () => {
            _isMounted.current = false
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        currentWellRef.current = wellName ? wellName : currentWell
        fetchShippingTickets()
        fetchInventory()
        fetchWellInfo()
    }, [currentWell]) // eslint-disable-line react-hooks/exhaustive-deps

    const deleteTicket = async (data) => {
        if (!data) return
        if (!data.hasOwnProperty('uid')) return { error: true, message: 'no uid' }
        if (isDeleting.current) return
        if (isLoading) return
        if (!_isMounted.current) return
        setIsLoading(true)
        isDeleting.current = true
        let res = await deleteShippingTicket(data)
        isDeleting.current = false

        if (!_isMounted.current) return
        setIsLoading(false)

        if (res?.error) {
            return { error: true, message: `${res?.error?.response?.data?.error}` }
        }

        if (!Array.isArray(shippingTicketData.current)) return { error: true, message: `shippingtickets not array` }
        let index = shippingTicketData.current.findIndex((ticket) => ticket.uid === data.uid)
        if (index < 0) return { error: true, message: `index < 0` }
        shippingTicketData.current.splice(index, 1)
        return { error: false, data: data }
    }

    const updateTicket = async (data) => {
        if (!data) return
        if (!data.hasOwnProperty('uid')) return { error: true, message: 'no uid' }
        if (!currentWellRef.current) return { error: true, message: 'no wellname' }
        if (typeof currentWellRef.current !== 'string') return { error: true, message: 'no wellname' }
        if (currentWellRef.current === '') return { error: true, message: 'no wellname' }
        if (isLoading) return
        if (isUpdating.current) return
        if (!_isMounted.current) return

        setIsLoading(true)
        isUpdating.current = true
        let res = await updateShippingTicket({ ...data, actualWell: currentWellRef.current })
        isUpdating.current = false

        if (!_isMounted.current) return
        setIsLoading(false)

        if (res?.error) {
            return { error: true, message: `${res?.error?.response?.data?.error}` }
        }

        if (!Array.isArray(shippingTicketData.current)) return { error: true, message: `shippingtickets not array` }
        let index = shippingTicketData.current.findIndex((ticket) => ticket.uid === data.uid)
        if (index < 0) return { error: true, message: `index < 0` }
        shippingTicketData.current[index] = cloneDeep(data)

        return { error: false, data: cloneDeep(data) }
    }

    const updateTicketTools = async (data) => {
        if (!data) return
        if (!data.hasOwnProperty('uid')) return { error: true, message: 'no uid' }
        if (!Array.isArray(data.tools)) data.tools = []
        if (!currentWellRef.current) return { error: true, message: 'no wellname' }
        if (typeof currentWellRef.current !== 'string') return { error: true, message: 'no wellname' }
        if (currentWellRef.current === '') return { error: true, message: 'no wellname' }
        if (isLoading) return
        if (isUpdating.current) return
        if (!_isMounted.current) return

        let toolId = ''
        for (let i = 0; i < data.tools.length; i++) {
            toolId += `${data.tools[i].recordId}|`
        }

        setIsLoading(true)
        isUpdating.current = true
        let res = await updateShippingTicketTools({ uid: data.uid, toolId: toolId, actualWell: currentWellRef.current })
        isUpdating.current = false

        if (!_isMounted.current) return
        setIsLoading(false)

        if (res?.error) {
            return { error: true, message: `${res?.error?.response?.data?.error}` }
        }

        if (!Array.isArray(shippingTicketData.current)) return { error: true, message: `shippingtickets not array` }
        let index = shippingTicketData.current.findIndex((ticket) => ticket.uid === data.uid)
        if (index < 0) return { error: true, message: `index < 0` }
        shippingTicketData.current[index].tools = cloneDeep(data.tools)

        return { error: false }
    }

    const addNewTicket = async () => {
        if (!currentWellRef.current) return { error: true, message: 'no wellname' }
        if (typeof currentWellRef.current !== 'string') return { error: true, message: 'no wellname' }
        if (currentWellRef.current === '') return { error: true, message: 'no wellname' }
        if (isAdding.current) return
        if (isLoading) return
        if (!_isMounted.current) return

        setIsLoading(true)
        isAdding.current = true
        let res = await addShippingTicket({ actualWell: currentWellRef.current })
        isAdding.current = false

        if (!_isMounted.current) return
        setIsLoading(false)

        if (res?.error) {
            return { error: true, message: `${res?.error?.response?.data?.error}` }
        }

        if (!res.data) return { error: true, message: `res.data not valid` }

        shippingTicketData.current.push(res.data)
        return { error: false, data: res.data }
    }

    const fetchWellInfo = async () => {
        if (currentWell?.length < 1) return
        const payload = {
            wellName: currentWell,
        }
        const response = await getWellInfo(payload)
        if (_isMounted.current && response?.data) {
            setWellInfo(response.data)
        }
    }

    const fetchShippingTickets = async () => {
        if (!currentWellRef.current) return
        if (typeof currentWellRef.current !== 'string') return
        if (currentWellRef.current === '') return
        if (isLoading) return
        if (!_isMounted.current) return

        setIsLoading(true)
        let res = await getShippingTicket({ wellName: currentWellRef.current })

        if (!_isMounted.current) return
        setIsLoading(false)

        if (res?.error) {
            return
        }

        shippingTicketData.current = Array.isArray(res.data) ? res.data.reverse() : []
    }

    const fetchShippingAddresses = async () => {
        const res = await getShippingAddresses()
        if (res?.error) return
        shippingAddressesData.current = Array.isArray(res.data) ? res.data : []
    }

    const getShippingAddressesData = () => {
        return Array.isArray(shippingAddressesData.current) ? shippingAddressesData.current : []
    }

    const getShippingTicketData = () => {
        return Array.isArray(shippingTicketData.current) ? shippingTicketData.current : []
    }

    const fetchInventory = async () => {
        if (!currentWellRef.current) return
        if (typeof currentWellRef.current !== 'string') return
        if (currentWellRef.current === '') return
        const res = await getWellInventory({ wellName: currentWellRef.current })
        if (res?.error) return
        setInventoryData(Array.isArray(res.data) ? res.data : [])
    }

    const addTools = (ticketTools) => {
        if (!ticketTools) return []
        if (!Array.isArray(ticketTools)) return []

        let tools = []
        tools.push(
            [{ text: 'Tool Details', isHeader: true, columnSpan: 6 }],
            [
                { text: 'Description', isHeader: true },
                { text: 'SN', isHeader: true },
                { text: `OD (${getUnitsText(UNITS_FOR.Diameter)})`, isHeader: true },
                { text: `ID (${getUnitsText(UNITS_FOR.Diameter)})`, isHeader: true },
                { text: `Length (${getUnitsText(UNITS_FOR.Depth)})`, isHeader: true },
                { text: `Weight (${getUnitsText(UNITS_FOR.Weight) === 'klbs' ? 'lbs' : 'kg'})`, isHeader: true },
            ],
        )

        for (let i = 0; i < ticketTools.length; i++) {
            tools.push([
                { text: unescapeHtml(ticketTools[i].description) },
                { text: unescapeHtml(ticketTools[i].serialNum) },
                { text: ticketTools[i].od },
                { text: ticketTools[i].id },
                { text: ticketTools[i].length },
                { text: ticketTools[i].weight },
            ])
        }

        return tools
    }

    const dateTimeFormatter = (value) => {
        if (!value) return ''
        if (typeof value !== 'string') return ''
        if (value === '') return ''
        value = value.replace(/Z/g, '')
        value += 'T00:00:01'
        return new Date(Date.parse(value)).toLocaleDateString('default', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        })
    }

    const generatePdfDocument = (uid) => {
        let index = shippingTicketData.current.findIndex((ticket) => ticket.uid === uid)
        if (index < 0) return null

        let selectedTicket = shippingTicketData.current[index]

        let docData = [
            {
                tableType: 'header',
                showTitle: true,
                title: `ShippingTicket #${selectedTicket.ticketNum} - ${dateTimeFormatter(selectedTicket.date)}`,
                showLogo: true,
                logo: getCurrentOrgIcon(),
                logoWidth: '25%',
                columnWidths: ['15%', '25%', '15%', '55%'],
                fontSize: 6,
                data: [
                    [
                        { text: 'Job#', isHeader: true },
                        { text: wellInfo.jobNum, textAlign: 'left' },
                        { text: 'Operator', isHeader: true },
                        { text: wellInfo.operator, textAlign: 'left' },
                    ],
                    [
                        { text: 'API Job#', isHeader: true },
                        { text: wellInfo.apiNum, textAlign: 'left' },
                        { text: 'Field', isHeader: true },
                        { text: wellInfo.field, textAlign: 'left' },
                    ],
                    [
                        { text: 'Rig', isHeader: true },
                        { text: wellInfo.rig, textAlign: 'left' },
                        { text: 'Well', isHeader: true },
                        { text: wellInfo.wellName, textAlign: 'left' },
                    ],
                    [
                        { text: 'County', isHeader: true },
                        { text: wellInfo.county, textAlign: 'left' },
                        { text: 'State', isHeader: true },
                        { text: wellInfo.state, textAlign: 'left' },
                    ],
                ],
            },
            {
                fontSize: 6,
                sectionAfter: 5,
                manualWidth: true,
                columnWidths: ['15%', '35%', '15%', '35%'],
                data: [
                    [
                        { text: 'Ship From', isHeader: true, columnSpan: 2 },
                        { text: 'Ship To', isHeader: true, columnSpan: 2 },
                    ],
                    [
                        { text: 'Company' },
                        { text: unescapeHtml(selectedTicket.companyFrom), textAlign: 'left' },
                        { text: 'Company' },
                        { text: unescapeHtml(selectedTicket.companyTo), textAlign: 'left' },
                    ],
                    [
                        { text: 'Address' },
                        { text: unescapeHtml(selectedTicket.addressFrom), textAlign: 'left' },
                        { text: 'Address' },
                        { text: unescapeHtml(selectedTicket.addressTo), textAlign: 'left' },
                    ],
                    [
                        { text: 'Contact' },
                        { text: unescapeHtml(selectedTicket.contactFrom), textAlign: 'left' },
                        { text: 'Contact' },
                        { text: unescapeHtml(selectedTicket.contantTo), textAlign: 'left' },
                    ],
                    [
                        { text: 'Phone' },
                        { text: selectedTicket.phoneFrom, textAlign: 'left' },
                        { text: 'Phone' },
                        { text: selectedTicket.phoneTo, textAlign: 'left' },
                    ],
                    [
                        { text: 'Email' },
                        { text: selectedTicket.emailFrom, textAlign: 'left' },
                        { text: 'Email' },
                        { text: selectedTicket.emailTo, textAlign: 'left' },
                    ],
                ],
            },
            {
                fontSize: 6,
                sectionAfter: 5,
                data: [
                    [{ text: 'Comments', isHeader: true }],
                    [{ text: unescapeHtml(selectedTicket.comments), textAlign: 'left' }],
                ],
            },
            {
                fontSize: 6,
                fixedHeaders: 1,
                manualWidth: true,
                columnWidths: ['20%', '15%', '15%', '15%', '20%', '15%'],
                data: addTools(selectedTicket.tools),
            },
        ]

        return docData
    }

    const getShippingTicketPdf = (uid) => {
        if (!Array.isArray(shippingTicketData.current)) return null
        if (typeof uid !== 'number') return null

        return ({
            fileName: `Shipping Ticket - ${removeSpecialSymbols(currentWell)}`,
            data: <PdfDocument data={generatePdfDocument(uid)} multiDocument={false} pageOrientation={'portrait'} reportSettings={userRole?.userPrefs?.reportSettings} />
        })
    }

    return { isLoading, isUpdating, isDeleting, getShippingTicketData, fetchShippingTickets, addNewTicket, deleteTicket, updateTicket, getShippingAddressesData, fetchInventory, inventoryData, updateTicketTools, getShippingTicketPdf }
}

export default useShippingTickets