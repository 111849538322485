import React, { useEffect, useRef, useState } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { actionBarWidthAtom, currentPageAtom, currentWellAtom } from 'atoms'
import { PAGE_KEYS } from 'components/ActionBar/pageDefs'
import { Backdrop, Box, CircularProgress } from '@mui/material'
import WallplotComposer from './WallplotComposer'
import { getFullGeoSteeringData } from 'utils/GeoSteeringFunctions'
import useAxiosGzip from 'components/common/hooks/useAxiosGzip'
import { cloneDeep } from 'lodash'

const WallplotComposerPage = () => {
  const _isMounted = useRef(false)
  const [wellData, setWellData] = useState(null)
  const setActivePage = useSetRecoilState(currentPageAtom)
  const leftPos = useRecoilValue(actionBarWidthAtom)
  const currentWell = useRecoilValue(currentWellAtom)
  const isLoadingRef = useRef(false)
  const [isLoading, setIsLoading] = useState(false)

  const getWellData = useAxiosGzip({
    url: '/well/getWellDataGz',
  })

  useEffect(() => {
    _isMounted.current = true
    setActivePage(PAGE_KEYS.wallplotComposerPageKey)
    fetchWellData(currentWell)
    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (_isMounted.current) {
      fetchWellData(currentWell)
    }
  }, [currentWell]) // eslint-disable-line react-hooks/exhaustive-deps

  const fetchWellData = async (well) => {
    if (typeof well !== 'string') return
    if (well === '') return
    if (isLoadingRef.current) return
    if (!_isMounted.current) return

    isLoadingRef.current = true
    setIsLoading(true)

    const res = await getWellData({
      wellName: well,
      wellPlan: true,
      surveys: true,
      offsetWells: true,
      targets: true,
      actualWellData: true,
      geosteering: true,
      calcErrorEllipses: true,
    })

    isLoadingRef.current = false

    if (!_isMounted.current) return
    if (res?.error || !res.data) {
      setIsLoading(false)
      return
    }

    let geoSteeringData = getFullGeoSteeringData(
      cloneDeep(res?.data?.geosteering),
      res?.data?.actualWellData?.verticalSection,
      cloneDeep(res?.data?.surveys),
      res?.data?.wellPlan?.surveyData,
    )
    setWellData({ ...res?.data, geoZones: geoSteeringData })
    setIsLoading(false)
  }

  return (
    <React.Fragment>
      <Backdrop style={{ color: '#fff', zIndex: 99999 }} open={isLoading}>
        <CircularProgress />
      </Backdrop>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          flexDirection: 'column',
          height: 'calc(100vh - 64px)',
          marginLeft: `${leftPos}px`,
          width: `calc(100%-${leftPos}px)`,
          maxWidth: `calc(100%-${leftPos}px)`,
          maxHeight: 'calc(100vh - 64px)',
          overflow: 'hidden',
          ':focus-visible': { outline: 'none' },
        }}>
        <WallplotComposer wellData={wellData} />
      </Box>
    </React.Fragment>
  )
}

export default WallplotComposerPage
