import React, { useEffect, useRef, forwardRef, useImperativeHandle, useState } from 'react'
import useUnits, { UNITS_FOR } from 'components/common/hooks/useUnits'
import Canvas from 'components/common/Canvas'
import { normalize } from 'utils/numberFunctions'
import { numberWithCommasDecimals } from 'utils/stringFunctions'
import { uuidv4 } from 'utils/stringFunctions'
import * as d3 from 'd3'
import debounce from 'lodash.debounce'
import { useRecoilValue } from 'recoil'
import { currentWellAtom } from 'atoms'
import { Box, Menu, MenuItem, ListItemIcon, ListItemText, Divider } from '@mui/material'
import { useResizeDetector } from 'react-resize-detector'
import AutoSizer from 'react-virtualized-auto-sizer'
import { cloneDeep } from 'lodash'
import { Icon as Iconify } from '@iconify/react'
import addTrackIcon from '../../assets/EdrIcons/addWellLogTrack.png'
import deleteTrackIcon from '../../assets/EdrIcons/deleteWellLogTrack.png'
import { GetRigStateColor, GetRigStateText } from 'components/common/activitySelector'
import {adjustHexOpacity} from 'utils/colorFunctions'
import useWellLogs from 'components/common/hooks/useWellLogs'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'
import useCurveDefinitions from 'components/common/hooks/useCurveDefinitions'

export const WL_ACTION_SCROLL_BCK = 'SCROLLBCK'
export const WL_ACTION_SCROLL_FWD = 'SCROLLFWD'
export const WL_ACTION_ZOOM = 'ZOOM'

export const WL_ACTION_DELETE_CURVE = 'DELETE_CURVE'
export const WL_ACTION_ADD_CURVE = 'ADD_CURVE'
export const WL_ACTION_MOVE_CURVE = 'MOVE_CURVE'
export const WL_ACTION_DELETE_TRACK = 'DELETE_TRACK'
export const WL_ACTION_ADD_TRACK = 'ADD_TRACK'
export const WL_ACTION_MOVE_TRACK = 'MOVE_TRACK'

const HITTEST_NONE = 1
const HITTEST_MINIMAP = 2
const HITTEST_TRACK_HEADER = 3
const HITTEST_TRACK_BODY = 4
const HITTEST_TRACK_BODY_DRAG_HANDLE = 5

const HITTEST_MM_SLIDER_TOP = 2
const HITTEST_MM_SLIDER_MAIN = 3
const HITTEST_MM_SLIDER_BTM = 4

const MOUSE_CLICK_SINGLE = 0
const MOUSE_CLICK_DOUBLE = 1

const WellLog = forwardRef(
  (
    {
      logData,
      miniMapData = null,
      numYTicks = 10,
      numXTicks = 10,
      outlineColor = '#A0A0A0',
      backGroundColor = 'rgba(32,32,32,0.5)',
      tickColor = '#606060',
      toolTipLineColor = '#A0A0A0',
      curveDefs = [],
      id = 'wellLog',
      handleChangeCurveProperties = null,
      handleLoadData = null,
      handleAddDeleteMoveCurve = null,
      handleAddDeleteMoveTrack = null,
    },
    ref,
  ) => {
    const [contextMenu, setContextMenu] = useState(null)
    const { getUnitsText } = useUnits()
    const trackAreaRect = useRef({ top: 0, bottom: 0, left: 0, right: 0, width: 0, height: 0 })
    const mousePosition = useRef({ x: null, y: null, valid: false })
    const mouseClick = useRef(null)
    const isDragging = useRef(null)
    const _isMounted = useRef(false)
    const dropTargetRef = useRef(null)
    const minimapRectRef = useRef(null)
    const crvHeaderRectRef = useRef([])
    const trackBodyRectRef = useRef([])
    const {getWindowBarColor} = useInnovaTheme()
    const currentWell = useRecoilValue(currentWellAtom)
    const nullValue = -999.25
    const chartMinMax = useRef({
      viewMaxY: 1000000000000000,
      viewMinY: 0,
      dataMaxY: 1000000000000000,
      dataMinY: 0,
    })
    const {curveMnemonics} = useCurveDefinitions()

    const {
      getCurveUnitsText,
      getRigStates,
      getCalculatedSeriesValues, 
      calcMiniMapRect, 
      getCurrentSeriesValue,
      formatMiniMapSliderTime,
      calcYpixelMinimap,
      calcMmSliderRect,
      validateText,
      getMinMaxValues,
      pointInRect,
      getMiniMapTimeDepth,
      getToolTipTimeDepth,
      getCalculatedToolTipTimeDepth,
      relativeTimeFormatter,
      getCurrentSeriesCalculatedValue,
      getCurveProperty,
      getNumCurves,
      getFontString,
      getCurveHeaderRect,
      calcYscaleRect,
      calcYpixel,
      calcXpixel,
      calcTrackRect,
    } = useWellLogs()

    useImperativeHandle(ref, () => {
      return {
        getMinMax() {
          return chartMinMax.current
        },
        getDragging() {
          return isDragging.current
        },
      }
    })

    const { width: wellLogWidth, height: wellLogHeight, ref: wellLogRef } = useResizeDetector()

    function getSeriesValues(mneomonic, trackIndex, curveIndex) {
      if (!Array.isArray(logData.current)) return []
      if (logData.current.length === 0) return []
      if (mneomonic === null || mneomonic === undefined) return []
      if (typeof mneomonic !== 'string') return []
      if (mneomonic === '') return []

      if (chartMinMax.current.viewMinY > chartMinMax.current.dataMaxY) return []
      if (chartMinMax.current.viewMaxY < chartMinMax.current.dataMinY) return []

      let seriesData = []
      let min = getCurveProperty(trackIndex, curveIndex, 'scaleMin', curveDefs?.current)
      let max = getCurveProperty(trackIndex, curveIndex, 'scaleMax', curveDefs?.current)

      let lastIndex = -1

      let straddleDataPointBottomIndex = -1
      let straddleDataPointTopIndex = -1

      for (let i = 0; i < logData.current.length; i++) {
        if (!logData.current[i].hasOwnProperty('avg')) continue
        if (!logData.current[i].avg.hasOwnProperty(mneomonic)) continue
        if (!logData.current[i].hasOwnProperty('convertedTimestamp')) continue
        if (typeof logData.current[i].avg[mneomonic] !== 'number') continue
        if (logData.current[i].avg[mneomonic] === nullValue) continue

        let serialtimeStamp = logData.current[i].convertedTimestamp
        if (serialtimeStamp === nullValue) continue

        if (serialtimeStamp < chartMinMax.current.viewMinY) straddleDataPointTopIndex = i
        if (serialtimeStamp > chartMinMax.current.viewMaxY) straddleDataPointBottomIndex = i

        if (serialtimeStamp > chartMinMax.current.viewMaxY) break
        if (serialtimeStamp < chartMinMax.current.viewMinY) continue

        let addData = true
        if (seriesData.length > 0) {
          if (serialtimeStamp < seriesData[seriesData.length - 1].y) addData = false
        }

        if (addData) {
          //Add first data point
          if (seriesData.length === 0 && i > 0) {
            let linearScale = d3
              .scaleLinear()
              .domain([logData.current[i - 1].convertedTimestamp, logData.current[i].convertedTimestamp])
              .range([logData.current[i - 1].avg[mneomonic], logData.current[i].avg[mneomonic]])

            let x = linearScale(chartMinMax.current.viewMinY)

            seriesData.push({
              x: normalize(x, min, max),
              y: chartMinMax.current.viewMinY,
            })
          }

          seriesData.push({
            x: normalize(logData.current[i].avg[mneomonic], min, max),
            y: serialtimeStamp,
          })

          //Decimated Data
          if (logData.current[i].min && logData.current[i].max) {
            seriesData.push({
              x: normalize(logData.current[i].min[mneomonic], min, max),
              y: serialtimeStamp,
            })

            seriesData.push({
              x: normalize(logData.current[i].max[mneomonic], min, max),
              y: serialtimeStamp,
            })

            seriesData.push({
              x: normalize(logData.current[i].avg[mneomonic], min, max),
              y: serialtimeStamp,
            })
          }

          lastIndex = i
        }
      }

      //Ensure there is no gap at the bottom
      if (lastIndex >= 0 && lastIndex < logData.current.length - 1) {
        let linearScale = d3
          .scaleLinear()
          .domain([logData.current[lastIndex].convertedTimestamp, logData.current[lastIndex + 1].convertedTimestamp])
          .range([logData.current[lastIndex].avg[mneomonic], logData.current[lastIndex + 1].avg[mneomonic]])

        let x = linearScale(chartMinMax.current.viewMaxY)

        seriesData.push({
          x: normalize(x, min, max),
          y: chartMinMax.current.viewMaxY,
        })
      }

      //No data points within range but data points straddle visible range
      if (seriesData.length === 0 && straddleDataPointTopIndex >= 0 && straddleDataPointBottomIndex >= 0) {
        let linearScale = d3
          .scaleLinear()
          .domain([
            logData.current[straddleDataPointTopIndex].convertedTimestamp,
            logData.current[straddleDataPointBottomIndex].convertedTimestamp,
          ])
          .range([
            logData.current[straddleDataPointTopIndex].avg[mneomonic],
            logData.current[straddleDataPointBottomIndex].avg[mneomonic],
          ])

        let xTop = linearScale(chartMinMax.current.viewMinY)
        seriesData.push({
          x: normalize(xTop, min, max),
          y: chartMinMax.current.viewMinY,
        })

        let xBottom = linearScale(chartMinMax.current.viewMaxY)
        seriesData.push({
          x: normalize(xBottom, min, max),
          y: chartMinMax.current.viewMaxY,
        })
      }

      return seriesData
    }

    const getChartMinMax = () => {
      if (!Array.isArray(logData.current)) return
      if (logData.current.length === 0) return

      let updateMax = chartMinMax.current.dataMaxY === chartMinMax.current.viewMaxY ? true : false

      chartMinMax.current.dataMinY = Math.round(logData.current[0].convertedTimestamp)
      chartMinMax.current.dataMaxY = Math.round(logData.current[logData.current.length - 1].convertedTimestamp)

      //Handle initial update
      if (chartMinMax.current.viewMaxY === 1000000000000000) {
        chartMinMax.current.viewMaxY = chartMinMax.current.dataMaxY
        let minDate = new Date(chartMinMax.current.dataMaxY)
        minDate.setMinutes(minDate.getMinutes() - 30)
        chartMinMax.current.viewMinY = Math.round(minDate)
      }

      if (updateMax) {
        let axisLength = chartMinMax.current.viewMaxY - chartMinMax.current.viewMinY
        chartMinMax.current.viewMaxY = chartMinMax.current.dataMaxY
        chartMinMax.current.viewMinY = chartMinMax.current.viewMaxY - axisLength
      }
    }

    const getTrackDragHandleRect = (trackRect) => {
      if (!trackRect) return null
      return {
        top: trackRect.top,
        bottom: trackRect.top + 20,
        left: trackRect.right - 20,
        right: trackRect.right,
      }
    }

    const calcHitTest = (point) => {
      if (minimapRectRef.current) {
        const { mmSliderRect, topSliderRect, btmSliderRect } = minimapRectRef.current

        if (pointInRect(mmSliderRect, point)) return { area: HITTEST_MINIMAP, type: HITTEST_MM_SLIDER_MAIN }
        if (pointInRect(topSliderRect, point)) return { area: HITTEST_MINIMAP, type: HITTEST_MM_SLIDER_TOP }
        if (pointInRect(btmSliderRect, point)) return { area: HITTEST_MINIMAP, type: HITTEST_MM_SLIDER_BTM }
      }

      let numTracks = getNumTracks()

      let rectIndex = -1
      for (let i = 0; i < numTracks; i++) {
        if (Array.isArray(trackBodyRectRef.current) && i < trackBodyRectRef.current.length) {
          if (pointInRect(trackBodyRectRef.current[i], point)) {
            let dragHandleRect = getTrackDragHandleRect(trackBodyRectRef.current[i])

            return {
              area: pointInRect(dragHandleRect, point) ? HITTEST_TRACK_BODY_DRAG_HANDLE : HITTEST_TRACK_BODY,
              type: null,
              trackIndex: i,
            }
          }
        }


        let numCurves = getNumCurves(i, curveDefs?.current)
        if (numCurves === 0) {
          numCurves = 1
        }


        for (let j = 0; j < numCurves; j++) {
          rectIndex++
          if (!Array.isArray(crvHeaderRectRef.current)) continue
          if (rectIndex < 0 || rectIndex >= crvHeaderRectRef.current.length) continue

          let crvHeaderRect = crvHeaderRectRef.current[rectIndex]
          if (!pointInRect(crvHeaderRect, point)) continue
          return {
            area: HITTEST_TRACK_HEADER,
            type: null,
            trackIndex: i,
            curveIndex: j,
            offsetX: crvHeaderRect.left - point.x,
            offsetY: crvHeaderRect.top - point.y,
          }
        }
      }

      return { area: HITTEST_NONE, type: null }
    }

    function handleMouseMove(event) {
      mousePosition.current = { x: event.offsetX, y: event.offsetY, valid: true }

      let res = calcHitTest(mousePosition.current)
      if (isDragging.current?.area === HITTEST_TRACK_HEADER && res.area === HITTEST_TRACK_BODY) {
        document.body.style.cursor = 'not-allowed'
        return
      }

      if (isDragging.current?.area === HITTEST_TRACK_HEADER && res.area === HITTEST_TRACK_BODY_DRAG_HANDLE) {
        document.body.style.cursor = 'not-allowed'
        return
      }

      if (isDragging.current?.area === HITTEST_TRACK_BODY_DRAG_HANDLE && res.area !== HITTEST_TRACK_BODY) {
        document.body.style.cursor = 'not-allowed'
        return
      }

      if (res.area === HITTEST_NONE || res.area === HITTEST_TRACK_BODY) {
        document.body.style.cursor = 'default'
        return
      }

      if (isDragging.current) {
        document.body.style.cursor = 'grabbing'
        return
      }

      if (res.type === HITTEST_MM_SLIDER_MAIN) document.body.style.cursor = 'pointer'
      if (res.type === HITTEST_MM_SLIDER_BTM) document.body.style.cursor = 'ns-resize'
      if (res.type === HITTEST_MM_SLIDER_TOP) document.body.style.cursor = 'ns-resize'
      if (res.area === HITTEST_TRACK_HEADER) document.body.style.cursor = 'pointer'
      if (res.area === HITTEST_TRACK_BODY_DRAG_HANDLE) document.body.style.cursor = 'pointer'
    }

    function handleMouseOut(event) {
      event.preventDefault()

      if (isDragging.current?.area === HITTEST_TRACK_HEADER) {
        dropTargetRef.current = isDragging.current
        dropTargetRef.current.dropPosition = calcHitTest({ x: event.offsetX, y: event.offsetY })
      }

      mousePosition.current = { x: null, y: null, valid: false }
      document.body.style.cursor = 'default'
      isDragging.current = null
      window.getSelection().removeAllRanges() //Required to stop other elements being selected
    }

    function handleMouseClick(event) {
      mouseClick.current = { x: event.offsetX, y: event.offsetY, type: MOUSE_CLICK_SINGLE }
    }

    function handleMouseDblClick(event) {
      isDragging.current = null
      document.body.style.cursor = 'default'
      mouseClick.current = { x: event.offsetX, y: event.offsetY, type: MOUSE_CLICK_DOUBLE }
    }

    function handleMouseLeftButtonDown(event) {
      if (event.button !== 0) return
      let res = calcHitTest({ x: event.offsetX, y: event.offsetY })
      if (res?.area === HITTEST_NONE) return
      isDragging.current = res
    }

    function handleMouseLeftButtonUp(event) {
      if (event.button === 0) {
        if (isDragging.current?.area === HITTEST_TRACK_HEADER) {
          dropTargetRef.current = isDragging.current
          dropTargetRef.current.dropPosition = calcHitTest({ x: event.offsetX, y: event.offsetY })
        }

        if (isDragging.current?.area === HITTEST_TRACK_BODY_DRAG_HANDLE) {
          dropTargetRef.current = isDragging.current
          dropTargetRef.current.dropPosition = calcHitTest({ x: event.offsetX, y: event.offsetY })
        }

        isDragging.current = null
      }
    }

    const drawMiniMapZoomText = (context) => {
      context.fillStyle = '#FFFFFF'
      context.strokeStyle = '#FFFFFF'
      const width = getWidth()
      context.font = `${getFontString(0.65, width)}em sans-serif`
      context.textAlign = 'center'

      const { mmSliderRect } = minimapRectRef.current
      let axisLength = chartMinMax.current.viewMaxY - chartMinMax.current.viewMinY

      context.beginPath()
      context.textBaseline = 'bottom'
      context.fillText(
        relativeTimeFormatter(axisLength),
        mmSliderRect.left + mmSliderRect.width / 2,
        mmSliderRect.top + mmSliderRect.height / 2,
      )
      context.stroke()
    }

    const handleMiniMapDrag = (context) => {
      if (!Array.isArray(miniMapData.current)) return
      if (miniMapData.current.length === 0) return
      const { width, height } = getWidthHeight()
      let { time: mousePosY } = getMiniMapTimeDepth(mousePosition.current.y, miniMapData.current, width, height)

      let minData = miniMapData.current[0].convertedTimestamp
      let maxData = miniMapData.current[miniMapData.current.length - 1].convertedTimestamp
      let axisLength = chartMinMax.current.viewMaxY - chartMinMax.current.viewMinY
      let middleAxis = chartMinMax.current.viewMinY + axisLength / 2

      //Scroll
      if (isDragging.current?.type === HITTEST_MM_SLIDER_MAIN) {
        let delta = middleAxis - mousePosY

        chartMinMax.current.viewMaxY -= delta
        chartMinMax.current.viewMinY -= delta

        if (chartMinMax.current.viewMaxY > maxData) {
          chartMinMax.current.viewMaxY = maxData
          chartMinMax.current.viewMinY = chartMinMax.current.viewMaxY - axisLength
        }

        if (chartMinMax.current.viewMinY < minData) {
          chartMinMax.current.viewMinY = minData
          chartMinMax.current.viewMaxY = chartMinMax.current.viewMinY + axisLength
        }

        if (chartMinMax.current.viewMinY < chartMinMax.current.dataMinY && handleLoadData !== null) {
          debouncedLoadPrevHandler(WL_ACTION_SCROLL_BCK, chartMinMax.current.viewMinY, chartMinMax.current.viewMaxY)
        }

        if (chartMinMax.current.viewMaxY > chartMinMax.current.dataMaxY && handleLoadData !== null) {
          debouncedLoadPrevHandler(WL_ACTION_SCROLL_FWD, chartMinMax.current.viewMinY, chartMinMax.current.viewMaxY)
        }

        return
      }

      //Zoom
      if (isDragging.current?.type === HITTEST_MM_SLIDER_TOP || isDragging.current?.type === HITTEST_MM_SLIDER_BTM) {
        if (isDragging.current?.type === HITTEST_MM_SLIDER_TOP && mousePosY >= chartMinMax.current.viewMaxY) {
          return
        }

        if (isDragging.current?.type === HITTEST_MM_SLIDER_BTM && mousePosY <= chartMinMax.current.viewMinY) {
          return
        }

        if (isDragging.current?.type === HITTEST_MM_SLIDER_TOP) {
          chartMinMax.current.viewMinY = mousePosY
        }

        if (isDragging.current?.type === HITTEST_MM_SLIDER_BTM) {
          chartMinMax.current.viewMaxY = mousePosY
        }

        if (chartMinMax.current.viewMaxY > maxData) {
          chartMinMax.current.viewMaxY = maxData
        }

        if (chartMinMax.current.viewMinY < minData) {
          chartMinMax.current.viewMinY = minData
        }

        drawMiniMapZoomText(context)

        if (handleLoadData !== null) {
          debouncedLoadPrevHandler(WL_ACTION_ZOOM, chartMinMax.current.viewMinY, chartMinMax.current.viewMaxY)
        }
      }
    }

    const handleDrop = () => {
      if (!dropTargetRef.current) return

      if (
        dropTargetRef.current.area === HITTEST_TRACK_BODY_DRAG_HANDLE &&
        dropTargetRef.current.dropPosition.area === HITTEST_TRACK_BODY
      ) {
        dropTargetRef.current.action = WL_ACTION_MOVE_TRACK
        handleAddDeleteMoveTrack(cloneDeep(dropTargetRef.current))
      }

      if (
        dropTargetRef.current.dropPosition.area === HITTEST_NONE ||
        dropTargetRef.current.dropPosition.area === HITTEST_TRACK_BODY
      ) {
        dropTargetRef.current.action = WL_ACTION_DELETE_CURVE
        handleAddDeleteMoveCurve(cloneDeep(dropTargetRef.current))
      }

      if (dropTargetRef.current.dropPosition.area === HITTEST_TRACK_HEADER) {
        dropTargetRef.current.action = WL_ACTION_MOVE_CURVE
        handleAddDeleteMoveCurve(cloneDeep(dropTargetRef.current))
      }

      dropTargetRef.current = null
    }

    const handleDragCurve = (context) => {
      let curveHeaderRect = getCurveHeaderRect(isDragging.current.trackIndex, isDragging.current.curveIndex, curveDefs?.current)

      context.fillStyle = 'rgba(255, 140, 0, 0.25)'
      context.beginPath()
      context.fillRect(
        mousePosition.current.x + isDragging.current.offsetX,
        mousePosition.current.y + isDragging.current.offsetY,
        curveHeaderRect.width,
        curveHeaderRect.height,
      )
    }

    const handleDragTrack = (context) => {
      let rect = trackBodyRectRef.current[isDragging.current.trackIndex]

      context.fillStyle = 'rgba(255, 140, 0, 0.25)'
      context.beginPath()
      context.fillRect(mousePosition.current.x - rect.width, mousePosition.current.y, rect.width, rect.height)
    }

    const handleDrag = (context) => {
      if (!isDragging.current) return
      if (isDragging.current?.area === HITTEST_MINIMAP) handleMiniMapDrag(context)
      if (isDragging.current?.area === HITTEST_TRACK_HEADER) handleDragCurve(context)
      if (isDragging.current?.area === HITTEST_TRACK_BODY_DRAG_HANDLE) handleDragTrack(context)
    }

    const getPrevData = (action, dateFrom, dateTo) => {
      if (isDragging.current) isDragging.current = null
      let dateFromStr = new Date(dateFrom).toISOString()
      let dateToStr = new Date(dateTo).toISOString()
      handleLoadData(action, dateFromStr, dateToStr)
    }

    const debouncedLoadPrevHandler = debounce((action, dateFrom, dateTo) => getPrevData(action, dateFrom, dateTo), 1000)

    function handleMouseWheel(event) {
      if (event.deltaY === 0) return
      if (!mousePosition.current.valid) return
      if (mousePosition.current.y < trackAreaRect.current.top) return

      if (!Array.isArray(miniMapData.current)) return
      if (miniMapData.current.length === 0) return

      let minData = miniMapData.current[0].convertedTimestamp
      let maxData = miniMapData.current[miniMapData.current.length - 1].convertedTimestamp

      //This is an error catch in the unlikely event the chart data is greater than the minimap data
      //Shouldnt be possible but has happened
      //The prevents a scroll lockup
      if (chartMinMax.current.dataMaxY > maxData) {
        maxData = chartMinMax.current.dataMaxY
      }

      if (!event.ctrlKey) {
        let sign = event.deltaY > 0 ? -1 : 1
        let axisLength = chartMinMax.current.viewMaxY - chartMinMax.current.viewMinY
        let delta = ((axisLength * 0.2) / 2) * sign

        if (
          chartMinMax.current.viewMaxY - delta < maxData + axisLength &&
          chartMinMax.current.viewMinY - delta > minData
        ) {
          chartMinMax.current.viewMaxY -= delta
          chartMinMax.current.viewMinY -= delta
        }
      }

      if (event.ctrlKey) {
        //Handle Zoom
        event.preventDefault()
        let percentage = event.deltaY > 0 ? 1.2 : 0.8
        let axisLength = chartMinMax.current.viewMaxY - chartMinMax.current.viewMinY
        let newAxisLength = axisLength * percentage
        let delta = (axisLength - newAxisLength) / 2

        if (newAxisLength <= 5 * 60 * 1000) delta = 0

        let linearScale = d3
          .scaleLinear()
          .domain([trackAreaRect.current.top, trackAreaRect.current.height / 2, trackAreaRect.current.bottom])
          .range([-1, 0, 1])

        let percBias = linearScale(mousePosition.current.y - trackAreaRect.current.top)
        chartMinMax.current.viewMaxY -= delta * (1 - percBias)
        chartMinMax.current.viewMinY += delta * (1 + percBias)

        if (chartMinMax.current.viewMaxY > maxData) {
          chartMinMax.current.viewMaxY = maxData
        }

        if (chartMinMax.current.viewMinY < minData) {
          chartMinMax.current.viewMinY = minData
        }

        if (handleLoadData !== null) {
          debouncedLoadPrevHandler(WL_ACTION_ZOOM, chartMinMax.current.viewMinY, chartMinMax.current.viewMaxY)
        }
      } else {
        if (chartMinMax.current.viewMinY < chartMinMax.current.dataMinY && handleLoadData !== null) {
          debouncedLoadPrevHandler(WL_ACTION_SCROLL_BCK, chartMinMax.current.viewMinY, chartMinMax.current.viewMaxY)
        }
        if (chartMinMax.current.viewMaxY > chartMinMax.current.dataMaxY && handleLoadData !== null) {
          debouncedLoadPrevHandler(WL_ACTION_SCROLL_FWD, chartMinMax.current.viewMinY, chartMinMax.current.viewMaxY)
        }
      }
    }

    useEffect(() => {
      _isMounted.current = true
      document.getElementById(id).addEventListener('mousemove', handleMouseMove)
      document.getElementById(id).addEventListener('mouseout', handleMouseOut)
      document.getElementById(id).addEventListener('click', handleMouseClick)
      document.getElementById(id).addEventListener('dblclick', handleMouseDblClick)
      document.getElementById(id).addEventListener('wheel', handleMouseWheel)
      document.getElementById(id).addEventListener('mousedown', handleMouseLeftButtonDown)
      document.getElementById(id).addEventListener('mouseup', handleMouseLeftButtonUp)
      document.getElementById(id).addEventListener('contextmenu', handleContextMenu)
      return () => {
        _isMounted.current = false
        if (document.getElementById(id)) {
          document.getElementById(id)?.removeEventListener('mousemove', handleMouseMove)
          document.getElementById(id)?.removeEventListener('mouseout', handleMouseOut)
          document.getElementById(id)?.removeEventListener('click', handleMouseClick)
          document.getElementById(id)?.removeEventListener('dblclick', handleMouseDblClick)
          document.getElementById(id)?.removeEventListener('wheel', handleMouseWheel)
          document.getElementById(id)?.removeEventListener('mousedown', handleMouseLeftButtonDown)
          document.getElementById(id)?.removeEventListener('mouseup', handleMouseLeftButtonUp)
          document.getElementById(id)?.removeEventListener('contextmenu', handleContextMenu)
        }
      }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
      if (_isMounted.current && currentWell?.length > 0) {
        resetChart()
      }
    }, [currentWell]) // eslint-disable-line react-hooks/exhaustive-deps

    const resetChart = () => {
      chartMinMax.current.viewMaxY = 1000000000000000
      getChartMinMax()
    }

    const getWidthHeight = () => {
      return {
        width: getWidth(),
        height: getHeight(),
      }
    }

    const drawBackGround = (context) => {
      context.fillStyle = backGroundColor
      context.fillRect(0, 0, context.canvas.width, context.canvas.height)
    }

    const drawYScale = (context) => {
      const { width, height } = getWidthHeight()
      const { header: headerRect, scale: scaleRect } = calcYscaleRect(width, height)

      //Draw Rig States
      let rigStates = getRigStates(logData.current, chartMinMax?.current?.viewMinY, chartMinMax?.current?.viewMaxY)

      for (let i = 0; i < rigStates.length; i++) {
        if (i === 0) continue

        let rigStateColor = GetRigStateColor(rigStates[i].x)
        context.fillStyle = rigStateColor

        let y1 = calcYpixel(rigStates[i - 1].y, height, chartMinMax.current)
        let y2 = calcYpixel(rigStates[i].y, height, chartMinMax.current)

        let heightRect = y2 - y1
        context.beginPath()
        context.fillRect(headerRect.left, y1, scaleRect.width, heightRect)
      }

      trackAreaRect.current = {
        top: headerRect.bottom,
        bottom: height,
        left: 0,
        right: width,
        width: width,
        height: height - headerRect.bottom,
      }

      context.strokeStyle = outlineColor
      context.lineWidth = 2
      context.fillStyle = backGroundColor

      context.beginPath()
      context.fillRect(headerRect.left, headerRect.top, headerRect.width, headerRect.height)

      context.beginPath()
      context.fillRect(scaleRect.left, scaleRect.top, scaleRect.width, scaleRect.height)

      context.beginPath()
      context.strokeRect(headerRect.left, headerRect.top, headerRect.width, headerRect.height)

      context.beginPath()
      context.strokeRect(scaleRect.left, scaleRect.top, scaleRect.width, scaleRect.height)

      context.strokeStyle = tickColor
      context.lineWidth = 1
      let yLineGap = scaleRect.height / (numYTicks - 1)

      context.font = `bold ${getFontString(0.75, width)}em sans-serif`
      context.fillStyle = outlineColor
      context.textAlign = 'center'
      context.textBaseline = 'middle'

      //Draw Ticks
      let prevTime = ''
      for (let i = 0; i < numYTicks; i++) {
        if (i === 0) continue
        if (i === numYTicks - 1) continue
        let yPos = scaleRect.top
        yPos += yLineGap * i
        yPos = Math.floor(yPos)
        yPos += 0.5

        context.beginPath()
        context.moveTo(scaleRect.left, yPos)
        context.lineTo(scaleRect.left + 5, yPos)
        context.stroke()

        context.beginPath()
        context.setLineDash([5, 3])
        context.moveTo(scaleRect.right - 5, yPos)
        context.lineTo(width, yPos)
        context.stroke()

        const { depth, time } = getToolTipTimeDepth(yPos, logData.current, chartMinMax.current, headerRect, scaleRect)
        let timeStr = new Date(Date.parse(time)).toLocaleTimeString('en-GB')

        context.beginPath()
        context.fillText(validateText(timeStr), scaleRect.left + scaleRect.width / 2, yPos)

        let oldTime = new Date(Date.parse(prevTime))
        let curTime = new Date(Date.parse(time))

        if (oldTime.getDay() !== curTime.getDay() || prevTime === '') {
          context.beginPath()
          context.fillText(validateText(curTime.toDateString()), scaleRect.left + scaleRect.width / 2, yPos - 15)
        }

        if (prevTime === '') {
          prevTime = time
        }

        context.beginPath()
        context.fillText(
          `${validateText(depth) !== '' ? numberWithCommasDecimals(depth, 2) : ''}${
            validateText(depth) !== '' ? getUnitsText(UNITS_FOR.Depth) : ''
          }`,
          scaleRect.left + scaleRect.width / 2,
          yPos + 15,
        )
      }

      context.setLineDash([])

      //Draw Header
      context.strokeStyle = outlineColor
      context.beginPath()
      context.moveTo(headerRect.left, headerRect.height / 2)
      context.lineTo(headerRect.right, headerRect.height / 2)
      context.stroke()

      let offset = 15
      context.beginPath()
      context.fillText(`Hole Depth (${getUnitsText(UNITS_FOR.Depth)})`, headerRect.left + headerRect.width / 2, offset)

      context.beginPath()
      context.fillText(
        mousePosition.current.valid ? `Date/Time` : `Bit Depth (${getUnitsText(UNITS_FOR.Depth)})`,
        headerRect.left + headerRect.width / 2,
        headerRect.height / 2 + offset,
      )

      context.font = `${getFontString(1, width)}em sans-serif`
      let rigStateStr = 'UNKNOWN'
      let depthStr = nullValue
      let timeStr = ''
      let dateStr = ''

      if (mousePosition.current.valid) {
        const { depth, time, rigState } = getToolTipTimeDepth(mousePosition.current.y, logData.current, chartMinMax.current, headerRect, scaleRect)
        timeStr = new Date(time).toLocaleTimeString('en-GB')
        dateStr = new Date(time).toLocaleDateString('default', {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
        })

        depthStr = depth
        rigStateStr = GetRigStateText(rigState)
      }

      if (!mousePosition.current.valid && Array.isArray(logData.current) && logData.current.length > 0) {
        timeStr = numberWithCommasDecimals(logData.current[logData.current.length - 1].bitDepth, 2)
        depthStr = logData.current[logData.current.length - 1].holeDepth
        rigStateStr = GetRigStateText(
          logData.current[logData.current.length - 1].rigState
            ? logData.current[logData.current.length - 1].rigState
            : 'UNKNOWN',
        )
      }

      context.beginPath()
      context.fillText(
        validateText(depthStr) !== '' ? numberWithCommasDecimals(depthStr, 2) : '',
        headerRect.left + headerRect.width / 2,
        headerRect.height / 4,
      )

      context.beginPath()
      context.fillText(rigStateStr, headerRect.left + headerRect.width / 2, headerRect.height * 0.25 + 25)

      context.beginPath()
      context.fillText(validateText(dateStr), headerRect.left + headerRect.width / 2, headerRect.height * 0.75)

      context.beginPath()
      context.fillText(validateText(timeStr), headerRect.left + headerRect.width / 2, headerRect.height * 0.9)
    }

    const getNumTracks = () => {
      const defNumTracks = 4
      if (!curveDefs.current) return defNumTracks
      if (!Array.isArray(curveDefs.current)) return defNumTracks
      if (curveDefs.current.length === 0) return 4
      return curveDefs.current.length
    }

    const drawTrack = (context, trackIndex) => {
      const { width, height } = getWidthHeight()
      const numTracks = getNumTracks()
      const { header: headerRect, track: trackRect } = calcTrackRect(trackIndex, width, height, numTracks)
      trackBodyRectRef.current.push(trackRect)

      //Draw track outline
      context.strokeStyle = outlineColor
      context.lineWidth = 2

      context.fillStyle = backGroundColor
      context.beginPath()
      context.fillRect(headerRect.left, headerRect.top, headerRect.width, headerRect.height)

      context.beginPath()
      context.fillRect(trackRect.left, trackRect.top, trackRect.width, trackRect.height)

      context.beginPath()
      context.strokeRect(headerRect.left, headerRect.top, headerRect.width, headerRect.height)

      context.beginPath()
      context.strokeRect(trackRect.left, trackRect.top, trackRect.width, trackRect.height)

      //Draw Drag Handle
      context.strokeStyle = tickColor
      context.fillStyle = tickColor
      context.lineWidth = 1

      context.beginPath()
      context.moveTo(trackRect.right - 20, trackRect.top + 1)
      context.lineTo(trackRect.right - 1, trackRect.top + 1)
      context.lineTo(trackRect.right - 1, trackRect.top + 20)
      context.lineTo(trackRect.right - 20, trackRect.top + 1)
      context.fill()

      //Draw Vertical grid lines
      let xLineGap = trackRect.width / (numXTicks - 1)

      for (let i = 0; i < numXTicks; i++) {
        if (i === 0) continue
        if (i === numXTicks - 1) continue
        let xPos = trackRect.left
        xPos += xLineGap * i
        xPos = Math.floor(xPos)
        xPos += 0.5

        context.beginPath()
        context.setLineDash([5, 3])
        context.moveTo(xPos, trackRect.top)
        context.lineTo(xPos, trackRect.bottom)
        context.stroke()
      }

      context.setLineDash([])

      //Draw header
      const numCurves = getNumCurves(trackIndex, curveDefs?.current)
      if (numCurves === 0) {
        crvHeaderRectRef.current.push(getCurveHeaderRect(trackIndex, 0, curveDefs?.current, width, height, numTracks))
        return
      }

      const headerTrackHeight = headerRect.height / numCurves
      for (let i = 0; i < numCurves; i++) {
        let curveOperations = getCurveProperty(trackIndex, i, 'operations', curveDefs?.current)
        let shaded = getCurveProperty(trackIndex, i, 'shaded', curveDefs?.current)
        let curveShading = getCurveProperty(trackIndex, i, 'shading', curveDefs?.current)
        let calculated = getCurveProperty(trackIndex, i, 'calculated', curveDefs?.current)
        let curveMnemonic = getCurveProperty(trackIndex, i, 'mnemonic', curveDefs?.current)
        let curveHeaderRect = getCurveHeaderRect(trackIndex, i, curveDefs?.current, width, height, numTracks)
        crvHeaderRectRef.current.push(curveHeaderRect)

        if (!isDragging.current && mousePosition.current.valid && pointInRect(curveHeaderRect, mousePosition.current)) {
          context.fillStyle = 'rgba(0, 255, 0, 0.25)'
          context.beginPath()
          context.fillRect(curveHeaderRect.left, curveHeaderRect.top, curveHeaderRect.width, curveHeaderRect.height)
        }

        if (
          mouseClick.current !== null &&
          pointInRect(curveHeaderRect, mouseClick.current) &&
          handleChangeCurveProperties !== null &&
          mouseClick.current.type === MOUSE_CLICK_DOUBLE
        ) {
          mouseClick.current = null
          handleChangeCurveProperties(getCurveProperty(trackIndex, i, 'uid', curveDefs?.current))
        }

        if (!calculated && curveMnemonic === 'none') continue

        let yPos = headerTrackHeight * i + headerTrackHeight / 2
        let curveColor = getCurveProperty(trackIndex, i, 'color', curveDefs?.current)

        context.strokeStyle = curveColor

        let fontSize = 1
        if (numCurves > 3) fontSize = 0.75

        //Horizontal line
        context.lineWidth = 2
        context.beginPath()
        context.moveTo(headerRect.left, yPos)
        context.lineTo(headerRect.right, yPos)
        context.stroke()
        context.lineWidth = 1

        context.font = `bold ${getFontString(fontSize, width)}em sans-serif`
        context.fillStyle = outlineColor
        context.textAlign = 'center'
        context.textBaseline = 'bottom'
        context.strokeStyle = outlineColor

        //Curve Name
        let scalePadding = 5
        if(calculated){
          context.beginPath()
          context.fillText(
            `${curveOperations[0].operationName}`,
            headerRect.left + headerRect.width / 2,
            yPos,
        )
        }else
        {
          let units = getCurveUnitsText(curveMnemonic)
          if (units !== '') units = `(${units})`

          context.beginPath()
          context.fillText(
            `${getCurveProperty(trackIndex, i, 'label', curveDefs?.current)} ${units}`,
            headerRect.left + headerRect.width / 2,
            yPos,
        )
        }

        //Curve Scale
        let min = getCurveProperty(trackIndex, i, 'scaleMin', curveDefs?.current)
        let max = getCurveProperty(trackIndex, i, 'scaleMax', curveDefs?.current)

        context.textBaseline = 'top'
        context.textAlign = 'left'
        context.beginPath()
        context.fillText(numberWithCommasDecimals(min, 2), headerRect.left + scalePadding, yPos + scalePadding)

        context.textAlign = 'right'
        context.beginPath()
        context.fillText(numberWithCommasDecimals(max, 2), headerRect.right - scalePadding, yPos + scalePadding)

        //Current value
        context.font = `${getFontString(fontSize, width)}em sans-serif`
        context.textAlign = 'center'

        if(calculated){
          const { header: headerRectCalc, scale: scaleRectCalc } = calcYscaleRect(width, height)
          const { value: textValue } = mousePosition.current.valid
            ? getCalculatedToolTipTimeDepth(mousePosition.current.y,logData.current, chartMinMax.current, headerRectCalc, scaleRectCalc, curveMnemonics, curveOperations)
            : getCurrentSeriesCalculatedValue(curveOperations, logData.current, curveMnemonics)
          
          context.beginPath()
          context.fillText(
            textValue === nullValue ? '' : numberWithCommasDecimals(textValue, 2),
            headerRect.left + headerRect.width / 2,
            yPos + scalePadding,
          )
        }else
        {
          const { header: headerRectCalc, scale: scaleRectCalc } = calcYscaleRect(width, height)
          const { value: textValue } = mousePosition.current.valid
            ? getToolTipTimeDepth(mousePosition.current.y, logData.current, chartMinMax.current, headerRectCalc, scaleRectCalc, curveMnemonic)
            : getCurrentSeriesValue(curveMnemonic, logData.current)
          
          context.beginPath()

          context.fillText(
            textValue === nullValue ? '' : numberWithCommasDecimals(textValue, 2),
            headerRect.left + headerRect.width / 2,
            yPos + scalePadding,
          )
        }

        //Draw curve data
        let curveData = getSeriesValues(curveMnemonic, trackIndex, i)
        if (calculated) curveData = getCalculatedSeriesValues(curveOperations, min, max, logData.current, curveMnemonics, chartMinMax.current)

        for (let j = 0; j < curveData.length; j++) {
          if (j === 0) continue
          context.strokeStyle = curveColor

          let x1 = headerRect.left + headerRect.width * curveData[j - 1].x
          let x2 = headerRect.left + headerRect.width * curveData[j].x

          let y1 = calcYpixel(curveData[j - 1].y, height, chartMinMax.current)
          let y2 = calcYpixel(curveData[j].y, height, chartMinMax.current)

          context.beginPath()
          context.moveTo(x1, y1)
          context.lineTo(x2, y2)
          context.stroke()
        }

        //Draw Shading
        if (shaded) {
          if (curveData.length > 1 && curveShading[0].shadeType.value === 'above' && curveShading[0].shadeColor !== nullValue){
            context.beginPath()
            let X = calcXpixel(curveShading[0].shadeValue, trackRect, min, max)
            context.moveTo(X, calcYpixel(curveData[0].y, height, chartMinMax.current))
            for (let j = 0; j < curveData.length; j++) {
              let x = headerRect.left + headerRect.width * curveData[j].x
              let y = calcYpixel(curveData[j].y, height, chartMinMax.current)
              if(x > X) {
                context.lineTo(X,y)
              }else{
                context.lineTo(x, y)
              }
            }
            context.lineTo(X, calcYpixel(curveData[curveData.length - 1].y, height, chartMinMax.current))
            context.save()
            context.clip()
            context.fillStyle = adjustHexOpacity(curveShading[0].shadeColor, 50)
            context.fillRect(trackRect.left, trackRect.top, trackRect.width, trackRect.height)
            context.restore()
          }   
          if (curveData.length > 1 && curveShading[0].shadeType.value === 'below' && curveShading[0].shadeColor !== nullValue){
            context.beginPath()
            context.beginPath()
            let X = calcXpixel(curveShading[0].shadeValue, trackRect, min, max)
            context.moveTo(X, calcYpixel(curveData[0].y, height, chartMinMax.current))
            for (let j = 0; j < curveData.length; j++) {
              let x = headerRect.left + headerRect.width * curveData[j].x
              let y = calcYpixel(curveData[j].y, height, chartMinMax.current)
              if(x < X) {
                context.lineTo(X,y)
              }else{
                context.lineTo(x, y)
              }
            }
            context.lineTo(X, calcYpixel(curveData[curveData.length - 1].y, height, chartMinMax.current))
            context.save()
            context.clip()
            context.fillStyle = adjustHexOpacity(curveShading[0].shadeColor, 50)
            context.fillRect(X, trackRect.top, trackRect.width, trackRect.height)
            context.restore()
          }
          if (curveData.length > 1 && curveShading[0].shadeType.value === 'betweenCurves' && curveShading[0].shadeColor !== nullValue){
            let curveMnemonic2 = curveMnemonics.find((curve) => curve.label === curveShading[0].shadeCurve)?.value
            if (curveMnemonic2 === null || curveMnemonic2 === undefined) continue
            if (typeof curveMnemonic2 !== 'string') continue
            if (curveMnemonic2 === '') continue
            let curveData2 = getSeriesValues(curveMnemonic2, trackIndex, i)
            if(Array.isArray(curveData2) && curveData2.length > 1 ) {
                context.beginPath()
                context.moveTo(headerRect.left + headerRect.width * curveData[0].x, calcYpixel(curveData[0].y, height, chartMinMax.current))
                for (let j = 0; j < curveData.length; j++) {
                  let x = headerRect.left + headerRect.width * curveData[j].x
                  let y = calcYpixel(curveData[j].y, height, chartMinMax.current)
                  context.lineTo(x, y)
                }
                context.lineTo(headerRect.left + headerRect.width * curveData[curveData.length - 1].x, calcYpixel(curveData[curveData.length - 1].y, height, chartMinMax.current))

                context.lineTo(headerRect.left + headerRect.width * curveData2[curveData2.length - 1].x, calcYpixel(curveData2[curveData2.length - 1].y, height, chartMinMax.current))
                for (let j = curveData2.length - 1; j >= 0; j--) {
                  let x = headerRect.left + headerRect.width * curveData2[j].x
                  let y = calcYpixel(curveData2[j].y, height, chartMinMax.current)
                  context.lineTo(x, y)
                }
                context.lineTo(headerRect.left + headerRect.width * curveData2[0].x, calcYpixel(curveData2[0].y, height, chartMinMax.current))

                context.save()
                context.clip()
                context.fillStyle = adjustHexOpacity(curveShading[0].shadeColor, 50)
                context.fillRect(trackRect.left, trackRect.top, trackRect.width, trackRect.height)
                context.restore()
            }
          }
        }
      }
    }

    const drawToolTip = (context) => {
      if (!mousePosition.current.valid) return
      const { width, height } = getWidthHeight()
      const { scale: scaleRect, header: headerRect } = calcYscaleRect(width, height)

      if (mousePosition.current.y < headerRect.bottom) return

      //Horizontal dashed line
      context.strokeStyle = toolTipLineColor
      context.lineWidth = 1
      context.setLineDash([3])

      context.beginPath()
      context.moveTo(scaleRect.left, mousePosition.current.y)
      context.lineTo(width, mousePosition.current.y)
      context.stroke()

      context.setLineDash([])
    }

    const drawMiniMap = (context) => {
      const { width, height } = getWidthHeight()
      const miniMapRect = calcMiniMapRect(width, height)

      context.strokeStyle = outlineColor
      context.lineWidth = 2
      context.fillStyle = backGroundColor

      context.beginPath()
      context.fillRect(miniMapRect.left, miniMapRect.top, miniMapRect.width, miniMapRect.height)

      context.beginPath()
      context.strokeRect(miniMapRect.left, miniMapRect.top, miniMapRect.width, miniMapRect.height)

      //Draw Ticks
      context.font = `${getFontString(0.75, width)}em sans-serif`
      context.fillStyle = outlineColor
      context.textAlign = 'center'
      context.strokeStyle = outlineColor

      context.lineWidth = 1
      context.textAlign = 'left'
      context.setLineDash([5, 3])

      let yLineGap = miniMapRect.height / (numYTicks - 1)

      for (let i = 0; i < numYTicks; i++) {
        if (i === 0) continue
        if (i === numYTicks - 1) continue
        let yPos = miniMapRect.top
        yPos += yLineGap * i
        yPos = Math.floor(yPos)
        yPos += 0.5

        context.beginPath()
        context.moveTo(miniMapRect.left, yPos)
        context.lineTo(miniMapRect.right, yPos)
        context.stroke()

        const { time, depth } = getMiniMapTimeDepth(yPos, miniMapData.current, width, height)

        let timeStr = new Date(time).toLocaleString('en-US', {
          day: 'numeric',
          month: 'short',
        })

        context.beginPath()
        context.textBaseline = 'bottom'
        context.fillText(timeStr, miniMapRect.left + 5, yPos)
        context.stroke()

        context.beginPath()
        context.textBaseline = 'top'
        context.fillText(numberWithCommasDecimals(depth, 0), miniMapRect.left + 5, yPos + 1)
        context.stroke()
      }

      context.setLineDash([])

      if (!miniMapData?.current) return
      if (!Array.isArray(miniMapData.current)) return

      const bitDepthMinMax = getMinMaxValues(miniMapData.current, 'bitDepth')

      //Hole Depth
      context.strokeStyle = backGroundColor

      for (let i = 0; i < miniMapData.current.length; i++) {
        if (i === 0) continue

        let normalizedPrevHoleDepth = normalize(
          miniMapData.current[i - 1].holeDepth,
          bitDepthMinMax.min,
          bitDepthMinMax.max,
        )

        let rigStateChange = miniMapData.current[i].rigState !== miniMapData.current[i - 1].rigState
        if (i === 1) {
          rigStateChange = false
          context.beginPath()
        }

        let normalizedHoleDepth = normalize(miniMapData.current[i].holeDepth, bitDepthMinMax.min, bitDepthMinMax.max)

        let x1 = miniMapRect.left + miniMapRect.width * normalizedPrevHoleDepth
        let x2 = miniMapRect.left + miniMapRect.width * normalizedHoleDepth

        let y1 = calcYpixelMinimap(miniMapData.current[i - 1].convertedTimestamp, miniMapData.current, height)
        let y2 = calcYpixelMinimap(miniMapData.current[i].convertedTimestamp, miniMapData.current, height)

        if (rigStateChange) {
          context.fillStyle = GetRigStateColor(miniMapData.current[i - 1].rigState)
          if (miniMapData.current[i - 1].rigState === 'UNKNOWN') {
            context.fillStyle = outlineColor + '60'
          }

          context.closePath()
          context.fill()
          context.beginPath()
        }

        context.moveTo(miniMapRect.left, y1)
        context.lineTo(x1, y1)
        context.lineTo(x2, y2)
        context.lineTo(miniMapRect.left, y2)

        if (i === miniMapData.current.length - 1) {
          context.fillStyle = GetRigStateColor(miniMapData.current[i].rigState)
          if (miniMapData.current[i].rigState === 'UNKNOWN') {
            context.fillStyle = outlineColor + '60'
          }

          context.closePath()
          context.fill()
        }
      }

      //Bit Depth
      for (let i = 0; i < miniMapData.current.length; i++) {
        if (i === 0) continue
        context.strokeStyle = outlineColor

        let normalizedPrevBitDepth = normalize(
          miniMapData.current[i - 1].bitDepth,
          bitDepthMinMax.min,
          bitDepthMinMax.max,
        )

        let normalizedBitDepth = normalize(miniMapData.current[i].bitDepth, bitDepthMinMax.min, bitDepthMinMax.max)

        let x1 = miniMapRect.left + miniMapRect.width * normalizedPrevBitDepth
        let x2 = miniMapRect.left + miniMapRect.width * normalizedBitDepth

        let y1 = calcYpixelMinimap(miniMapData.current[i - 1].convertedTimestamp, miniMapData.current, height)
        let y2 = calcYpixelMinimap(miniMapData.current[i].convertedTimestamp, miniMapData.current, height)

        context.beginPath()
        context.moveTo(x1, y1)
        context.lineTo(x2, y2)
        context.stroke()
      }
    }

    const drawMmSlider = (context) => {
      // draw rect
      const { width, height } = getWidthHeight()
      minimapRectRef.current = calcMmSliderRect(miniMapData?.current, width, height, chartMinMax.current)
      const { mmSliderRect, topSliderRect, btmSliderRect } = minimapRectRef.current

      context.strokeStyle = '#C0C000'
      context.lineWidth = 2
      context.fillStyle = '#FFFFFF30'
      context.beginPath()
      context.rect(mmSliderRect.left, mmSliderRect.top, mmSliderRect.width, mmSliderRect.height)
      context.stroke()
      context.fillRect(mmSliderRect.left, mmSliderRect.top, mmSliderRect.width, mmSliderRect.height)

      // draw top handle
      context.beginPath()
      context.rect(topSliderRect.left, topSliderRect.top, topSliderRect.width, topSliderRect.height)
      context.stroke()

      // draw bottom handle
      context.beginPath()
      context.rect(btmSliderRect.left, btmSliderRect.top, btmSliderRect.width, btmSliderRect.height)
      context.stroke()

      //Draw text
      context.fillStyle = '#FFFFFF'
      context.strokeStyle = '#FFFFFF'
      context.font = `${getFontString(0.65, width)}em sans-serif`
      context.textAlign = 'center'

      const { time: timeTop } = getMiniMapTimeDepth(topSliderRect.bottom, miniMapData.current, width, height)
      let timeStrTop = formatMiniMapSliderTime(timeTop)

      context.beginPath()
      context.textBaseline = 'bottom'
      context.fillText(timeStrTop, topSliderRect.left + topSliderRect.width / 2, topSliderRect.bottom - 1)
      context.stroke()

      const { time: timeBtm } = getMiniMapTimeDepth(btmSliderRect.top, miniMapData.current, width, height)
      let timeStrBtm = formatMiniMapSliderTime(timeBtm)

      context.beginPath()
      context.textBaseline = 'top'
      context.fillText(timeStrBtm, btmSliderRect.left + btmSliderRect.width / 2, btmSliderRect.top + 2)
      context.stroke()
    }

    const draw = (context, frameCount) => {
      //Frame count can be used for animations
      getChartMinMax()
      drawBackGround(context)
      drawMiniMap(context)
      drawYScale(context)

      crvHeaderRectRef.current = []
      trackBodyRectRef.current = []
      for (let i = 0; i < getNumTracks(); i++) {
        drawTrack(context, i)
      }
      drawMmSlider(context)
      drawToolTip(context)
      handleDrag(context)
      handleDrop()
    }

    const getWidth = () => {
      return wellLogWidth ? wellLogWidth : 1
    }

    const getHeight = () => {
      return wellLogHeight ? wellLogHeight - 2 : 1
    }

    const handleCloseContextMenu = () => {
      setContextMenu(null)
    }

    const handleContextMenu = (event) => {
      event.preventDefault()

      let res = calcHitTest({ x: event.offsetX, y: event.offsetY })
      if (res?.area !== HITTEST_TRACK_HEADER && res.area !== HITTEST_TRACK_BODY) {
        setContextMenu(null)
        return
      }

      setContextMenu(
        contextMenu === null
          ? {
              mouseX: event.clientX + 2,
              mouseY: event.clientY - 6,
              area: res?.area,
              curveIndex: res?.curveIndex ? res?.curveIndex : 0,
              trackIndex: res?.trackIndex,
            }
          : null,
      )
    }

    const ContextMenuItemIcon = ({ icon, color, isImage }) => {
      if (isImage) {
        return <img alt={'img'} src={icon} style={{ height: 15, width: 15 }} />
      }

      return <Iconify icon={icon} width={'15px'} height={'15px'} color={color || '#a9abac'} />
    }

    let contextMenuItems = [
      {
        icon: <ContextMenuItemIcon icon={addTrackIcon} isImage={true} />,
        label: `Add Track`,
        onClick: () => {
          if (!handleAddDeleteMoveTrack) return
          handleAddDeleteMoveTrack({ ...contextMenu, action: WL_ACTION_ADD_TRACK })
        },
        area: [HITTEST_TRACK_BODY],
      },
      {
        icon: <ContextMenuItemIcon icon={deleteTrackIcon} isImage={true} />,
        label: `Delete Track`,
        onClick: () => {
          if (!handleAddDeleteMoveTrack) return
          handleAddDeleteMoveTrack({ ...contextMenu, action: WL_ACTION_DELETE_TRACK })
        },
        area: [getNumTracks() > 1 ? HITTEST_TRACK_BODY : HITTEST_NONE],
      },
      {
        isDivider: true,
        area: [HITTEST_TRACK_BODY],
      },
      {
        icon: <ContextMenuItemIcon icon={'icon-park-solid:add'} color={'green'} />,
        label: `Add Curve`,
        onClick: () => {
          if (!handleAddDeleteMoveCurve) return
          handleAddDeleteMoveCurve({ ...contextMenu, action: WL_ACTION_ADD_CURVE })
        },
        area: [HITTEST_TRACK_HEADER, HITTEST_TRACK_BODY],
      },
      {
        icon: <ContextMenuItemIcon icon={'material-symbols:delete'} color={'red'} />,
        label: `Delete Curve`,
        onClick: () => {
          if (!handleAddDeleteMoveCurve) return
          handleAddDeleteMoveCurve({ ...contextMenu, action: WL_ACTION_DELETE_CURVE })
        },
        area: [HITTEST_TRACK_HEADER],
      },
    ]

    return (
      <Box
        sx={{
          overflow: 'hidden',
          border: '1px solid ',
          width: '100%',
          height: '100%',
        }}
        id={id ? id : uuidv4()}
        ref={wellLogRef}>
        <AutoSizer>
          {({ height, width }) => (
            <Canvas
              square={false}
              draw={draw}
              width={width}
              height={height}
              style={{
                width: width,
                height: height,
              }}
            />
          )}
        </AutoSizer>
        <Menu
          sx={{
            mt: '1px',
            '& .MuiMenu-paper': { backgroundColor: getWindowBarColor(), border: '1px solid gray' },
          }}
          open={contextMenu !== null}
          onClose={handleCloseContextMenu}
          anchorReference='anchorPosition'
          anchorPosition={contextMenu !== null ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined}>
          {contextMenuItems.map((item, index) => {
            if (!Array.isArray(item?.area)) return null
            if (!item.area.find((a) => a === contextMenu?.area)) return null

            if (item.isDivider) {
              return <Divider key={index} sx={{ bgcolor: 'secondary.light' }} />
            }

            return (
              <MenuItem
                key={index}
                onClick={() => {
                  if (item.onClick) item.onClick()
                  setContextMenu(null)
                }}>
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText sx={{ color: '#a9abac' }}>{item.label}</ListItemText>
              </MenuItem>
            )
          })}
        </Menu>
      </Box>
    )
  },
)

export default WellLog
