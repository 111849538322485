import React, { useEffect, useRef, useState } from 'react'
import { Box, Snackbar, Alert } from '@mui/material'
import { Icon as Iconify } from '@iconify/react'
import FlowRatesEditor from './FlowRatesEditor'
import TripSpeedsEditor from './TripSpeedsEditor'
import FrictionFactorEditor from './FrictionFactorEditor'
import WellBoreEditor from './WellboreEditor'
import SurveyGrid from 'components/WellPages/SurveyPage/SurveysGrid'
import DrillStringComponentsGrid from 'components/WellPages/DrillStringPages/DrillStringComponentsGrid'
import KickTolLimitsEditor from './KickTolLimitsEditor'
import BhaBurParamsEditorGrid from './BhaBurParamsEditorGrid'
import BhaCriticalRPMParamsEditorGrid from './BhaVibrationParamsEditorGrid'
import { currentBhaAtom, currentWellAtom, engineeringDashboardSelectedChartAtom } from 'atoms'
import { useRecoilValue } from 'recoil'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'
import { saveItemToLS } from 'utils/localStorage'
import { cloneDeep } from 'lodash'

const BOX_STYLE = {
  paddingLeft: '10px',
  paddingRight: '10px',
  borderLeft: '1px solid gray',
  borderRight: '1px solid gray',
  cursor: 'pointer',
  '&:hover': {
    color: '#429ceb',
  },
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  wordWrap: 'nowrap',
  height: '25px',
}

const EngineeringDashboardEditorPane = ({ showHideEditor, showEditor }) => {
  const _isMounted = useRef(false)
  const [selectedEditor, setSelectedEditor] = useState('flowRates')
  const currentBha = useRecoilValue(currentBhaAtom)
  const currentWell = useRecoilValue(currentWellAtom)
  const [status, setStatus] = useState({ show: false, severity: 'info', message: '' })
  const selectedChart = useRecoilValue(engineeringDashboardSelectedChartAtom)
  const { getWindowBarColor } = useInnovaTheme()
  const [filteredData, setFilteredData] = useState([])

  useEffect(() => {
    _isMounted.current = true
    return () => {
      _isMounted.current = false
    }
  }, [])

  useEffect(() => {
    if (filteredData.length > 0) {
      saveItemToLS('surveysFilteredData', 'filteredData', cloneDeep(filteredData))
    }
  }, [filteredData])

  const handleCloseStatus = () => {
    setStatus({ show: false, severity: 'info', message: '' })
  }

  const isChartBhaAnalysis = () => {
    return selectedChart === 'directionalTendencyChart'
  }

  const isChartBhaCriticalRPM = () => {
    return selectedChart === 'criticalRpmChart'
  }

  return (
    <Box sx={{ width: '100%', height: '100%', backgroundColor: getWindowBarColor() }}>
      <Box
        sx={{
          width: '100%',
          height: '25px',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: getWindowBarColor(),
        }}>
        <Box
          sx={BOX_STYLE}
          color={selectedEditor === 'flowRates' ? '#429ceb' : '#f0f0f0'}
          onClick={() => {
            setSelectedEditor('flowRates')
            if (!showEditor) showHideEditor()
          }}>
          Flow Rates
        </Box>
        <Box
          sx={BOX_STYLE}
          color={selectedEditor === 'tripSpeeds' ? '#429ceb' : '#f0f0f0'}
          onClick={() => {
            setSelectedEditor('tripSpeeds')
            if (!showEditor) showHideEditor()
          }}>
          Trip Speeds
        </Box>
        <Box
          sx={BOX_STYLE}
          color={selectedEditor === 'frictionFactors' ? '#429ceb' : '#f0f0f0'}
          onClick={() => {
            setSelectedEditor('frictionFactors')
            if (!showEditor) showHideEditor()
          }}>
          Friction Factors
        </Box>{' '}
        <Box
          sx={BOX_STYLE}
          color={selectedEditor === 'kickTolLimits' ? '#429ceb' : '#f0f0f0'}
          onClick={() => {
            setSelectedEditor('kickTolLimits')
            if (!showEditor) showHideEditor()
          }}>
          Kick Tol Limits
        </Box>
        <Box
          sx={BOX_STYLE}
          color={selectedEditor === 'wellbore' ? '#429ceb' : '#f0f0f0'}
          onClick={() => {
            setSelectedEditor('wellbore')
            if (!showEditor) showHideEditor()
          }}>
          Wellbore
        </Box>
        <Box
          sx={BOX_STYLE}
          color={selectedEditor === 'drillString' ? '#429ceb' : '#f0f0f0'}
          onClick={() => {
            setSelectedEditor('drillString')
            if (!showEditor) showHideEditor()
          }}>
          Drill String
        </Box>
        <Box
          sx={BOX_STYLE}
          color={selectedEditor === 'surveys' ? '#429ceb' : '#f0f0f0'}
          onClick={() => {
            setSelectedEditor('surveys')
            if (!showEditor) showHideEditor()
          }}>
          Surveys
        </Box>
        {isChartBhaAnalysis() ? (
          <Box
            sx={BOX_STYLE}
            color={selectedEditor === 'bhaBurParams' ? '#429ceb' : '#f0f0f0'}
            onClick={() => {
              setSelectedEditor('bhaBurParams')
              if (!showEditor) showHideEditor()
            }}>
            BHA BUR Params
          </Box>
        ) : null}
        {isChartBhaCriticalRPM() ? (
          <Box
            sx={BOX_STYLE}
            color={selectedEditor === 'bhaCriticalRPMParams' ? '#429ceb' : '#f0f0f0'}
            onClick={() => {
              setSelectedEditor('bhaCriticalRPMParams')
              if (!showEditor) showHideEditor()
            }}>
            BHA RPM Params
          </Box>
        ) : null}
        <Box
          onClick={showHideEditor}
          sx={{
            marginLeft: 'auto',
            width: '25px',
            height: '25px',
            backgroundColor: '#222628',
            cursor: 'pointer',
            flexDirection: 'column',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <Iconify icon={'zondicons:view-hide'} width={'20px'} height={'20px'} color={'#808080'} />
        </Box>
      </Box>
      {selectedEditor === 'flowRates' && <FlowRatesEditor />}
      {selectedEditor === 'tripSpeeds' && <TripSpeedsEditor />}
      {selectedEditor === 'frictionFactors' && <FrictionFactorEditor />}
      {selectedEditor === 'wellbore' && <WellBoreEditor />}
      {selectedEditor === 'kickTolLimits' && <KickTolLimitsEditor />}
      {selectedEditor === 'drillString' && (
        <DrillStringComponentsGrid
          wellName={currentWell}
          bhaNum={currentBha?.bhaNum}
          setStatus={setStatus}
          isEngineering={true}
          isVisible={showEditor}
        />
      )}
      {selectedEditor === 'surveys' && (
        <SurveyGrid
          handleShowChart={null}
          showChart={false}
          isEngineering={true}
          isVisible={showEditor}
          wellName={currentWell}
          setFilteredData={setFilteredData}
        />
      )}
      {isChartBhaAnalysis() && selectedEditor === 'bhaBurParams' && <BhaBurParamsEditorGrid />}
      {isChartBhaCriticalRPM() && selectedEditor === 'bhaCriticalRPMParams' && <BhaCriticalRPMParamsEditorGrid />}
      {status?.show ? (
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={status?.show}
          autoHideDuration={2000}
          onClose={handleCloseStatus}>
          <Alert onClose={handleCloseStatus} severity={status.severity} elevation={4} variant='filled'>
            {status.message}
          </Alert>
        </Snackbar>
      ) : null}
    </Box>
  )
}

export default EngineeringDashboardEditorPane
