import React, { useEffect, useState } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { styled } from '@mui/styles'
import { Box, Toolbar, Container, AppBar } from '@mui/material'
import useInnovaAuth from 'components/common/hooks/useInnovaAuth'
import InnovaLogo from 'images/Innova-black-white-logo.png'
import useUserRole from 'components/common/hooks/useUserRole'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

import {
  currentPageAtom,
  currentWellAtom,
  currentWellDetailsAtom,
  userPrefsAtom,
  currentAwsRegionAtom,
  selectedAssetSearchAtom,
} from 'atoms'
import { PAGE_KEYS } from 'components/ActionBar/pageDefs'

import NavMenu from './NavMenu'
import NavBarUserProfile from './NavBarUserProfile'
import { appColors } from 'utils'
import WellSelector from './WellSelector'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import useOrgIcons from 'components/common/hooks/useOrgIcons'

const StyledDetailsText = styled(Box)({
  display: 'flex',
  color: appColors.modalBackColor,
  fontSize: 14,
  fontWeight: '700',
  justifyContent: 'left',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
})

const StyledImage = styled('img')(({ theme }) => ({
  height: theme.spacing(6),
  borderRadius: '5px',
}))

const StyledRowContainer = styled(Container)(({ theme }) => ({
  zIndex: theme.zIndex.drawer,
  padding: '0px',
  margin: '0px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  maxWidth: '100%',
}))

const StyledContainer = styled(Container)({
  padding: '0px',
  margin: '0px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  maxWidth: '100%',
})

const NavBar = () => {
  const [data, setData] = useState(null)
  const [userPrefs, setUserPrefs] = useRecoilState(userPrefsAtom)
  const { isAuthenticated, logout } = useInnovaAuth()
  const currentWell = useRecoilValue(currentWellAtom)
  const currentWellDetails = useRecoilValue(currentWellDetailsAtom)
  const currentPage = useRecoilValue(currentPageAtom)
  const currentAwsRegion = useRecoilValue(currentAwsRegionAtom)
  const selectedAsset = useRecoilValue(selectedAssetSearchAtom)
  const { getCurrentOrgLogo } = useOrgIcons()
  const { getRole, user } = useUserRole()
  const { theme } = useInnovaTheme()

  const fetchUserPrefs = useInnovaAxios({
    url: '/user/getPrefs',
  })

  useEffect(() => {
    getRole()
  }, [user]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (data) {
      setUserPrefs(data)
    }
  }, [data]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isAuthenticated) return
    if (!userPrefs && !data) {
      getUserPrefs()
    }
  }, [isAuthenticated]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isAuthenticated) {
      getUserPrefs()
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const getUserPrefs = async () => {
    const response = await fetchUserPrefs()
    if (response?.data) {
      setData(response.data)
    }
  }

  const getCompanyLogo = getCurrentOrgLogo() || InnovaLogo

  const renderPageWithTitle = (title) => {
    return (
      <React.Fragment>
        <StyledRowContainer>
          <NavMenu isAuthenticated={isAuthenticated} />
          <StyledImage src={getCompanyLogo} alt='Innova Logo' />
          <Box
            sx={{
              display: 'inline-block', // 'flex',
              height: '64px',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}>
            <Box
              sx={{
                display: 'flex',
                color: appColors.modalBackColor,
                fontSize: 36,
                fontWeight: '700',
                height: '100%',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              {title}
            </Box>
          </Box>
          <NavBarUserProfile isAuthenticated={isAuthenticated} logout={logout} />
        </StyledRowContainer>
      </React.Fragment>
    )
  }

  const renderPageWithTitleDetails = (title, well) => {
    return (
      <React.Fragment>
        <StyledRowContainer>
          <NavMenu isAuthenticated={isAuthenticated} />
          <StyledImage src={getCompanyLogo} alt='Innova Logo' />
          <Box sx={{ display: 'flex', flexDirection: 'column', marginLeft: '16px', overflow: 'hidden' }}>
            <StyledDetailsText>{currentWellDetails.actualWell}</StyledDetailsText>
            <StyledDetailsText>{currentWellDetails.operator}</StyledDetailsText>
            <StyledDetailsText>
              {currentWellDetails.rig} - {currentWellDetails.jobNum}
            </StyledDetailsText>
          </Box>
          <Box
            sx={{
              display: 'flex',
              height: '64px',
              marginLeft: 'auto',
              marginRight: 'auto',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <Box
              sx={{
                display: 'flex',
                color: appColors.modalBackColor,
                fontSize: 36,
                fontWeight: '700',
                height: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                overflow: 'hidden',
              }}>
              {title}
            </Box>
          </Box>
          <Box sx={{ display: 'flex', flex: '1', maxWidth: '250px' }} />
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}>
            <WellSelector />
            <NavBarUserProfile isAuthenticated={isAuthenticated} logout={logout} />
          </Box>
        </StyledRowContainer>
      </React.Fragment>
    )
  }

  const RenderNavBar = ({ currentPage }) => {
    switch (currentPage) {
      case PAGE_KEYS.multiDbWellListKey:
        return renderPageWithTitle('Well List')
      case PAGE_KEYS.innovaAdminUsersKey:
        return renderPageWithTitle('Innova Admin Users')
      case PAGE_KEYS.shippingAddressesPageKey:
        return renderPageWithTitle('Shipping Addresses')
      case PAGE_KEYS.rigNamesPageKey:
        return renderPageWithTitle('Rig Names')
      case PAGE_KEYS.dataQcPageKey:
        return renderPageWithTitle('Data QC')
      case PAGE_KEYS.objectValidationKey:
        return renderPageWithTitle('Object Validation')
      case PAGE_KEYS.invoiceQcPageKey:
        return renderPageWithTitle('Invoice QC')
      case PAGE_KEYS.crsPageKey:
        return renderPageWithTitle('CRS')
      case PAGE_KEYS.personnelNamesPageKey:
        return renderPageWithTitle('Personnel Names')
      case PAGE_KEYS.personnelUtilizationPageKey:
        return renderPageWithTitle('Personnel Utilization')
      case PAGE_KEYS.auditDataPageKey:
        return renderPageWithTitle('Audit Data')
      case PAGE_KEYS.antiCollisionDashboardPageKey:
        return renderPageWithTitle('Anti-Collision Dashboard')
      case PAGE_KEYS.innovaCompanysPageKey:
        return renderPageWithTitle('Innova Company Details')
      case PAGE_KEYS.innovaContactsPageKey:
        return renderPageWithTitle('Company Contacts')
      case PAGE_KEYS.innovaQuotesPageKey:
        return renderPageWithTitle('Innova Quotes')
      case PAGE_KEYS.innovaInvoicesPageKey:
        return renderPageWithTitle('Innova Invoices')
      case PAGE_KEYS.innovaLicensesPageKey:
        return renderPageWithTitle('Innova Software Licenses')
      case PAGE_KEYS.innovaLicenseRequestKey:
        return renderPageWithTitle('License Requests')
      case PAGE_KEYS.innovaLicenseDeactivationsPageKey:
        return renderPageWithTitle('Innova Software License Deactivations')
      case PAGE_KEYS.innovaSoftwareDetailsPageKey:
        return renderPageWithTitle('Innova Software Details')
      case PAGE_KEYS.innovaVendorsPageKey:
        return renderPageWithTitle('Innova Vendors')
      case PAGE_KEYS.thirdPartyAppCredsPageKey:
        return renderPageWithTitle('Third Party App Credentials')
      case PAGE_KEYS.innovaSoftwareBillingPageKey:
        return renderPageWithTitle('Innova Software Billing')
      case PAGE_KEYS.witsmlServersKey:
        return renderPageWithTitle('WITSML Servers')
      case PAGE_KEYS.dataAckFileStatusKey:
        return renderPageWithTitle('File History')
      case PAGE_KEYS.dataStoreKey:
        return renderPageWithTitle('Data Stores')
      case PAGE_KEYS.dataAcquisitionKey:
        return renderPageWithTitle('Data Configurations')
      case PAGE_KEYS.wellConnectionAnalysisKey:
        return renderPageWithTitleDetails('Connection Analysis', currentWell)
      case PAGE_KEYS.connectionAnalysisRigKey:
        return renderPageWithTitleDetails('Connection Analysis', currentWell)
      case PAGE_KEYS.wellDetailsKey:
        return renderPageWithTitleDetails('Well Details', currentWell)
      case PAGE_KEYS.dailyReportKey:
        return renderPageWithTitleDetails('Daily Report', currentWell)
      case PAGE_KEYS.antiCollisionPageKey:
        return renderPageWithTitleDetails('Anti-Collision', currentWell)
      case PAGE_KEYS.inventoryKey:
        return renderPageWithTitleDetails('Inventory', currentWell)
      case PAGE_KEYS.surveyKey:
        return renderPageWithTitleDetails('Surveys', currentWell)
      case PAGE_KEYS.slideSheetKey:
        return renderPageWithTitleDetails('Slide Sheet', currentWell)
      case PAGE_KEYS.costsHeadersKey:
        return renderPageWithTitleDetails('Daily Costs', currentWell)
      case PAGE_KEYS.drillStringKey:
        return renderPageWithTitleDetails('Drill String', currentWell)
      case PAGE_KEYS.ticketPageKey:
        return renderPageWithTitleDetails('Shipping Tickets')
      case PAGE_KEYS.pipeTallysPageKey:
        return renderPageWithTitleDetails('Pipe Tallys')
      case PAGE_KEYS.multiWellDashboardPageKey:
        return renderPageWithTitle('Multi Well Dashboard')
      case PAGE_KEYS.logisticsKey:
        return renderPageWithTitle('Logistics')
      case PAGE_KEYS.footageRopByMotorPageKey:
      case PAGE_KEYS.footageRopByBitPageKey:
      case PAGE_KEYS.footageRopByFormationPageKey:
      case PAGE_KEYS.footageRopByRigPageKey:
      case PAGE_KEYS.footageRopByDirCoPageKey:
      case PAGE_KEYS.footageRopByHoleSizePageKey:
      case PAGE_KEYS.mapSearchKpiKey:
      case PAGE_KEYS.depthVDaysKey:
      case PAGE_KEYS.slideRotateFootageKey:
      case PAGE_KEYS.slideRotateRopKey:
      case PAGE_KEYS.slideRotateHoursKey:
      case PAGE_KEYS.activitySummary:
      case PAGE_KEYS.costPerWellKey:
      case PAGE_KEYS.costPerDayKey:
      case PAGE_KEYS.costPerOperatorKey:
      case PAGE_KEYS.rigDaysKey:
      case PAGE_KEYS.fastestSectionKpiKey:
      case PAGE_KEYS.longestSectionKpiKey:
      case PAGE_KEYS.maxDrilledPerDayKpiKey:
      case PAGE_KEYS.parameterComparisonKey:
      case PAGE_KEYS.motorYieldKey:
      case PAGE_KEYS.bhaResultsKpiKey:
      case PAGE_KEYS.directionalScoreCardKpiKey:
      case PAGE_KEYS.maxRopKpiKey:
        return renderPageWithTitle('Analytics')
      case PAGE_KEYS.phaseKpiKey:
        return renderPageWithTitle('Phase KPI')
      case PAGE_KEYS.wellKpiKey:
        return renderPageWithTitle('Well KPI')
      case PAGE_KEYS.motorKpiKey:
        return renderPageWithTitle('Motor KPI')
      case PAGE_KEYS.motorPerPhaseKpiKey:
        return renderPageWithTitle('BHA Grouped by phase KPI')
      case PAGE_KEYS.wellRankingKey:
        return renderPageWithTitle('Well Ranking')
      case PAGE_KEYS.holeSizeKpiKey:
        return renderPageWithTitle('Hole Size KPI')
      case PAGE_KEYS.inventoryKpiKey:
        return renderPageWithTitle('Inventory KPI')
      case PAGE_KEYS.towerComparisonKpiKey:
        return renderPageWithTitle('Tower Comparison KPI')
      case PAGE_KEYS.activityKpiKey:
        return renderPageWithTitle('Activity KPI')
      case PAGE_KEYS.daysKpiKey:
        return renderPageWithTitle('Days KPI')
      case PAGE_KEYS.parametersRoadmapKey:
        return renderPageWithTitle('Parameters Roadmap')
      case PAGE_KEYS.connectionAnalysisKey:
        return renderPageWithTitle('Connection Analysis')
      case PAGE_KEYS.wellParametersRoadmapKey:
        return renderPageWithTitleDetails('Parameters Roadmap', currentWell)
      case PAGE_KEYS.adminInnovaOrgsKey:
        return renderPageWithTitle('Organization Admin')
      case PAGE_KEYS.adminInnovaDashboardKey:
        return renderPageWithTitle('Infrastructure Dashboard')
      case PAGE_KEYS.adminInnovaIcdsKey:
        return renderPageWithTitle(`ICDS Admin - ${currentAwsRegion}`)
      case PAGE_KEYS.adminAcDashKey:
        return renderPageWithTitle(`AC Dashboard Status - ${currentAwsRegion}`)
      case PAGE_KEYS.adminInnovaDrillLinkKey:
        return renderPageWithTitle('DrillLink Admin')
      case PAGE_KEYS.adminInnovaApiHealthKey:
        return renderPageWithTitle('API Health')
      case PAGE_KEYS.adminInnovaDataAcqHealthKey:
        return renderPageWithTitle('Data Acq Health')
      case PAGE_KEYS.adminInnovaEndpointUsageKey:
        return renderPageWithTitle('Endpoint Usage')
      case PAGE_KEYS.adminWellSeekerKey:
        return renderPageWithTitle('WellSeeker Pro Admin')
      case PAGE_KEYS.adminWellSeekerUpdateFilesKey:
        return renderPageWithTitle('Upload Well Seeker Updates')
      case PAGE_KEYS.adminWellSeekerUpdateConfigKey:
        return renderPageWithTitle('Well Seeker Updates Config')
      case PAGE_KEYS.bidsPageKey:
        return renderPageWithTitle('Bids')
      case PAGE_KEYS.adminUserGroupsKey:
        return renderPageWithTitle('User Groups')
      case PAGE_KEYS.adminUnitsKey:
        return renderPageWithTitle('Unit Sets')
      case PAGE_KEYS.adminRolesKey:
        return renderPageWithTitle('User Roles')
      case PAGE_KEYS.userProfileKey:
        return renderPageWithTitle('User Profile')
      case PAGE_KEYS.edrPageKey:
        return renderPageWithTitleDetails('EDR')
      case PAGE_KEYS.errorModelsKey:
        return renderPageWithTitle('Error Models')
      case PAGE_KEYS.toolOrdersKey:
        return renderPageWithTitle('Tool Orders')
      case PAGE_KEYS.compCatalogPageKey:
        return renderPageWithTitle('Component Catalog')
      case PAGE_KEYS.centralizerCatalogPageKey:
        return renderPageWithTitle('Centralizers Catalog')
      case PAGE_KEYS.stopCollarCatalogPageKey:
        return renderPageWithTitle('Stop Collars Catalog')
      case PAGE_KEYS.userDatabaseAccessPageKey:
        return renderPageWithTitle('Database Access')
      case PAGE_KEYS.assetSearchKey:
        return renderPageWithTitleDetails(
          selectedAsset === null
            ? 'Asset Search'
            : `Asset Search - ${selectedAsset.toolType} ${selectedAsset.serialNum}`,
        )
      case PAGE_KEYS.dbTreePageKey:
        return renderPageWithTitle('Object Explorer')
      case PAGE_KEYS.engineeringDashboardKey:
        return renderPageWithTitleDetails('Engineering Dashboard', currentWell)
      case PAGE_KEYS.innovaAdminFileManagerPageKey:
        return renderPageWithTitle('File Manager')
      case PAGE_KEYS.innovaAdminBankAccountsPageKey:
        return renderPageWithTitle('Bank Accounts')
      case PAGE_KEYS.innovaAdminBankEmployeesPageKey:
        return renderPageWithTitle('Employees')
      case PAGE_KEYS.incidentDashboardPageKey:
        return renderPageWithTitle('Incident Dashboard')
      case PAGE_KEYS.wallplotComposerPageKey:
        return renderPageWithTitleDetails('Wallplot Composer')
      default:
        return (
          <StyledContainer>
            <NavMenu isAuthenticated={isAuthenticated} />
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              <StyledImage src={getCompanyLogo} alt='Innova Logo' />
            </Box>
            <NavBarUserProfile isAuthenticated={isAuthenticated} logout={logout} />
          </StyledContainer>
        )
    }
  }

  return (
    <React.Fragment>
      <AppBar
        sx={{
          height: '64px',
          zIndex: (theme) => theme.zIndex.drawer,
          background:
            theme === 'dark'
              ? 'rgb(19,62,108) linear-gradient(90deg, rgba(19,62,108,1) 0%, rgba(132,209,223,1) 50%, rgba(19,62,108,1) 100%)'
              : 'linear-gradient(90deg, rgba(240, 248, 255, 1) 0%, rgba(19, 62, 108, 1) 50%, rgba(240, 248, 255, 1) 100%)',
        }}
        position='fixed'>
        <Toolbar sx={{ zIndex: (theme) => theme.zIndex.drawer, justifyContent: 'flex-start', maxWidth: '100%' }}>
          <RenderNavBar currentPage={currentPage} />
        </Toolbar>
      </AppBar>
      <Toolbar />
    </React.Fragment>
  )
}

export default NavBar
