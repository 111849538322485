import React, { useRef, useEffect } from 'react'
import { Box } from '@mui/material'
import ThreeDeeView from 'components/ThreeDeeView/ThreeDeeView'
import useWellData from 'components/common/hooks/useWellData'
import { getFullGeoSteeringData } from 'utils/GeoSteeringFunctions'
import { cloneDeep } from 'lodash'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const ThreeDeeSummaryCard = () => {
  const { wellData, currentWell, isLoading, getGeoSteeringData, toggleErrorEllipses } = useWellData()
  const geoZones = useRef([])
  const {getChartBackColor} = useInnovaTheme()

  useEffect(() => {
    calcGeoZones()
  }, [wellData]) // eslint-disable-line react-hooks/exhaustive-deps

  const calcGeoZones = () => {
    geoZones.current = getFullGeoSteeringData(
      cloneDeep(getGeoSteeringData()),
      wellData?.actualWellData?.verticalSection,
      cloneDeep(wellData?.surveys),
      wellData?.wellPlan?.surveyData,
    )
  }

  return (
    <Box sx={{ height: '100%', width: '100%', backgroundColor: getChartBackColor() }}>
      <ThreeDeeView
        wellData={wellData}
        wellName={currentWell}
        isLoading={isLoading}
        refGeosteering={geoZones}
        projections={[]}
        refWellSurveys={[]}
        toggleErrorEllipses={toggleErrorEllipses}
      />
    </Box>
  )
}

export default ThreeDeeSummaryCard
