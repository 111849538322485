import { useEffect, useState, useRef } from 'react'

import useInnovaAxios from '../useInnovaAxios'
import { useRecoilValue } from 'recoil'
import { currentWellAtom, currentBhaAtom, currentSelEngBhaHeadersAtom } from 'atoms'
import { normalize } from 'utils/numberFunctions.js'
import { lerpColor } from 'utils/colorFunctions'

function useDrillingParams() {
  const _isMounted = useRef(true)
  const slideSheetParamsData = useRef({})
  const isLoading = useRef(false)
  const [loading, setLoading] = useState(false)
  const currentWell = useRecoilValue(currentWellAtom)
  const currentWellRef = useRef(currentWell)
  const currentBha = useRecoilValue(currentBhaAtom)
  const currentSelBha = useRecoilValue(currentSelEngBhaHeadersAtom)

  const getData = useInnovaAxios({
    url: '/engineering/getSlideSheetDrillingParams',
  })

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
    }
  }, [])

  useEffect(() => {
    currentWellRef.current = currentWell
    fetchSlideSheetData()
  }, [currentWell]) // eslint-disable-line react-hooks/exhaustive-deps

  const fetchSlideSheetData = async () => {
    if (typeof currentWellRef.current !== 'string') return
    if (currentWellRef.current === '') return
    if (isLoading.current) return
    isLoading.current = true
    if (_isMounted.current) setLoading(true)

    const response = await getData({ wellName: currentWellRef.current })

    if (_isMounted.current) {
      setLoading(false)
      slideSheetParamsData.current = response?.data ? response.data : null
    }

    isLoading.current = false
  }

  const interpChartColor = (pallete, index, maxSeries) => {
    let bluePalette = ['#006cd1', '#00deff']
    let redPalette = ['#fa6e7a', '#fa000c']
    let greenPalette = ['#93fab4', '#8ee800']

    let startColor = bluePalette[0]
    let endColor = bluePalette[1]
    if (pallete === 'green') {
      startColor = greenPalette[0]
      endColor = greenPalette[1]
    }

    if (pallete === 'red') {
      startColor = redPalette[0]
      endColor = redPalette[1]
    }

    let ratio = normalize(index, 0, maxSeries)
    return lerpColor(startColor, endColor, ratio)
  }

  function adjustHexOpacity(hexColor, alpha) {
    // Remove the leading '#' if present
    const sanitizedColor = hexColor.replace('#', '')

    // Convert the hex color to RGBA
    const red = parseInt(sanitizedColor.substring(0, 2), 16)
    const green = parseInt(sanitizedColor.substring(2, 4), 16)
    const blue = parseInt(sanitizedColor.substring(4, 6), 16)

    // Convert the alpha value from a range of 0-100 to a range of 0-255
    const alphaValue = Math.round((alpha / 100) * 255)

    // Convert RGBA values to a new hex string
    const updatedHex = `#${((1 << 24) + (red << 16) + (green << 8) + blue).toString(16).slice(1)}${alphaValue.toString(
      16,
    )}`

    return updatedHex
  }

  const getTagsForChart = (chartType) => {
    if (chartType === null || chartType === undefined) return []
    if (typeof chartType !== 'string') return []

    if (chartType === 'pumpPressure') return ['onBtmPress', 'offBtmPress']
    if (chartType === 'drillersTorques') return ['tq']
    if (chartType === 'drillersHookloads') return ['puWt', 'rotWt', 'soWt']
    if (chartType === 'minFlow') return ['flowRate']
    if (chartType === 'criticalRpmChart') return ['rpm', 'wob']
    if (chartType === 'heatMap') return ['rpm', 'wob', 'flowRate', 'tq', 'diffPress', 'rop']

    return []
  }

  const getSeriesDetails = (tag) => {
    if (tag === null || tag === undefined) return { title: 'unknown', color: 'red' }
    if (typeof tag !== 'string') return { title: 'unknown', color: 'red' }

    if (tag === 'diffPress') return { title: 'Diff Press', color: 'red' }
    if (tag === 'flowRate') return { title: 'Flow Rate', color: 'red' }
    if (tag === 'offBtmPress') return { title: 'Off Btm Press', color: 'red' }
    if (tag === 'onBtmPress') return { title: 'On Btm Press', color: 'blue' }
    if (tag === 'puWt') return { title: 'P/U Weight', color: 'blue' }
    if (tag === 'soWt') return { title: 'S/O Weight', color: 'red' }
    if (tag === 'rotWt') return { title: 'Rot Weight', color: 'green' }
    if (tag === 'rpm') return { title: 'RPM', color: 'red' }
    if (tag === 'tq') return { title: 'Torque', color: 'red' }
    if (tag === 'wob') return { title: 'WOB', color: 'red' }
    if (tag === 'rop') return { title: 'ROP', color: 'red' }
    if (tag === 'rpm') return { title: 'RPM', color: 'red' }
    return { title: 'unknown', color: 'red' }
  }

  const getSeriesData = (series, chartType) => {
    let output = []

    if (chartType === 'heatMap'){
      for (let i = 0; i < series.length; i++) {
        output.push({
          x: series[i].value,
          y: series[i].depth,
          bhaNum: series[i].bhaNumRep,
        })
      }
      return output
    }
    
    for (let i = 0; i < series.length; i++) {
      if (!isDataPtBhaSelected(series[i].bhaNum)) continue
      output.push({
        x: series[i].value,
        y: series[i].depth,
      })
    }

    return output
  }

  const isDataPtBhaSelected = (bhaNum) => {
    if (bhaNum === null || bhaNum === undefined) return false
    if (typeof bhaNum === 'string') bhaNum = parseInt(bhaNum)
    if (bhaNum === currentBha.bhaNum) return true
    if (Array.isArray(currentSelBha)) {
      if (currentSelBha.findIndex((bha) => bha === bhaNum) >= 0) return true
    }

    return false
  }

  const getSlideSheetData = (chartType) => {
    let chartData = []

    if (chartType === null || chartType === undefined) return chartData
    if (typeof chartType !== 'string') return chartData
    let tags = getTagsForChart(chartType)
    if (!Array.isArray(tags)) return chartData
    if (tags.length === 0) return chartData

    if (!slideSheetParamsData.current) return chartData

    for (let i = 0; i < tags.length; i++) {
      if (!slideSheetParamsData.current.hasOwnProperty(tags[i])) continue
      if (!Array.isArray(slideSheetParamsData.current[tags[i]])) continue

      const { title, color } = getSeriesDetails(tags[i])

      chartData.push({
        label: title,
        borderColor: interpChartColor(color, i, 5),
        backgroundColor: interpChartColor(color, i, 5),
        pointBorderColor: adjustHexOpacity(interpChartColor(color, i, 5), 50),
        pointBackgroundColor: adjustHexOpacity(interpChartColor(color, i, 5), 50),
        showLine: false,
        fill: false,
        pointRadius: 3,
        pointHoverRadius: 5,
        pointStyle: 'circle',
        data: getSeriesData(slideSheetParamsData.current[tags[i]], chartType),
      })
    }

    return chartData
  }

  return {
    loading,
    getSlideSheetData,
    fetchSlideSheetData,
  }
}

export default useDrillingParams
