import React, { useEffect, useRef, useState } from 'react'

import { Avatar, ListItem, Box } from '@mui/material'
import StyledBadge from './StyledBadge'
import { GetActivityColor, GetActivityText, getStatusAvatar } from 'components/common/activitySelector'
import { styled } from '@mui/styles'
import { appColors } from 'utils'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { currentWellSelector, currentWellDetailsSelector, wellListAtom } from 'atoms'
import { reduceVirtualizedWellData } from 'components/common/wellDataFunctions'
import SearchBar from 'components/common/SearchBar'
import { FixedSizeList } from 'react-window'
import AutoSizer from 'react-virtualized-auto-sizer'
import useObjectsOfInterest from 'components/common/hooks/useObjectsOfInterest'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const StyledAvatarContainer = styled(Box)({
  margin: '5px',
  marginRight: '15px',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'lightgrey',
  borderRadius: '50%',
})

const SimpleWellList = () => {
  const _isMounted = useRef(false)
  const wellList = useRecoilValue(wellListAtom)
  const setCurrentWell = useSetRecoilState(currentWellSelector)
  const setCurrentWellDetails = useSetRecoilState(currentWellDetailsSelector)
  const [searchText, setSearchText] = useState('')
  const [reducedWellList, setReducedWellList] = useState([])
  const { objectsOfInterest } = useObjectsOfInterest()
  const { searchBarStyle, theme, getLinearGradient } = useInnovaTheme()

  useEffect(() => {
    if (_isMounted.current && wellList?.length > 0) {
      setReducedWellList(reduceVirtualizedWellData(wellList, searchText, [], objectsOfInterest))
    }
  }, [wellList, searchText, objectsOfInterest])

  useEffect(() => {
    _isMounted.current = true
    if (wellList?.length > 0) {
      setReducedWellList(reduceVirtualizedWellData(wellList, searchText, [], objectsOfInterest))
    }

    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const selectWell = (event, item) => {
    event.stopPropagation()
    setCurrentWell(item.actualWell)
    setCurrentWellDetails({
      actualWell: item.actualWell,
      wellStatus: item.wellStatus,
      operator: item.operator,
      rig: item.rig,
      jobNum: item.jobNum,
      lat: item.latitude,
      lng: item.longitude,
    })
  }

  const renderVirtualWellSectionListItem = (item, index, style) => {
    return (
      <ListItem dense disableGutters={true} style={style}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            border: `1px solid`,
            width: 'calc(100% - 12px)',
            minHeight: 'calc(100% - 4px)',
            marginLeft: '3px',
            background: getLinearGradient(),
            paddingBottom: '8px',
            '&:hover': {
              borderColor: appColors.accentColor,
            },
            cursor: 'pointer',
          }}
          onClick={(event) => selectWell(event, item)}>
          <StyledAvatarContainer>
            <StyledBadge
              badgecolor={GetActivityColor(item.wellStatus)}
              badgeContent={GetActivityText(item.wellStatus)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}>
              <Avatar alt='Well Icon' src={getStatusAvatar(item.wellStatus)}></Avatar>
            </StyledBadge>
          </StyledAvatarContainer>
          <Box
            component='dl'
            sx={{
              color: appColors.itemTextColorMobile,
              fontSize: '12px',
              marginLeft: '5%',
              marginTop: '4px',
              marginBottom: '4px',
            }}>
            <Box
              component='dt'
              sx={{ fontWeight: 'bold', color: theme === 'dark' ? 'rgb(192,192,192)' : '#000', maxWidth: '200px' }}>
              {`${item.actualWell}`}
            </Box>
            <Box component='dt'>{`${item.operator}`}</Box>
            <Box component='dt'>{`${item.jobNum} - ${item.state}/${item.county}`}</Box>
          </Box>
        </Box>
      </ListItem>
    )
  }

  const renderVirtualWellListHeader = (operator, index, style) => {
    return (
      <ListItem dense disableGutters={true} style={style}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            marginBottom: '5px',
            width: '100%',
            height: '100%',
            background:
              'rgba(19,62,108,1) linear-gradient(90deg, rgba(19,62,108,1) 0%, rgba(19,62,108,1) 50%, rgba(132,209,223,1) 100%)',
          }}
          style={{
            background:
              'rgba(19,62,108,1) linear-gradient(90deg, rgba(19,62,108,1) 0%, rgba(19,62,108,1) 50%, rgba(132,209,223,1) 100%)',
          }}>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', margin: '4px 0px 4px 0px' }}>
            <Box
              sx={{
                fontSize: 16,
                fontWeight: '800',
                color: appColors.headerTextColor,
                display: 'flex',
                lineHeight: '24px',
                marginLeft: '16px',
              }}>
              {operator}
            </Box>
          </Box>
        </Box>
      </ListItem>
    )
  }

  const renderVirtualWellRow = (props) => {
    const { index, style } = props
    const item = reducedWellList[index]
    if (item.hasOwnProperty('header')) {
      return renderVirtualWellListHeader(item.operator, index, style)
    } else {
      return renderVirtualWellSectionListItem(item, index, style)
    }
  }

  return (
    <React.Fragment>
      <Box sx={{ height: '50px' }}>
        <SearchBar
          autoFocus={true}
          value={searchText}
          onChange={(newSearchTerm) => setSearchText(newSearchTerm)}
          onCancelSearch={() => setSearchText('')}
          style={searchBarStyle()}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          overflowY: 'hidden',
          overflowX: 'hidden',
          height: 'calc(100vh - 50px)',
          maxHeight: 'calc(100vh - 50px)',
          width: '340px',
        }}>
        <AutoSizer>
          {({ height, width }) => (
            <FixedSizeList
              className={theme === 'dark' ? 'customScrollBarDark' : ''}
              height={height}
              width={width}
              itemCount={reducedWellList.length}
              itemSize={82}>
              {renderVirtualWellRow}
            </FixedSizeList>
          )}
        </AutoSizer>
      </Box>
    </React.Fragment>
  )
}

export default SimpleWellList
