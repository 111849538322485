import { useEffect, useState, useRef } from 'react'

import useInnovaAxios from '../useInnovaAxios'
import { useRecoilValue } from 'recoil'
import { currentWellAtom, userUserRoleAtom } from 'atoms'
import { chartSeriesColors } from 'utils'
import { getRandomColor } from 'utils/colorFunctions'
import PdfDocument from 'components/common/PDFGen/PdfDocument'
import { numberToString } from 'utils/numberFunctions'
import useUnits, { UNITS_FOR } from 'components/common/hooks/useUnits'
import cloneDeep from 'lodash/cloneDeep'
import { removeSpecialSymbols, numberWithCommasDecimals } from 'utils/stringFunctions'
import useOrgIcons from 'components/common/hooks/useOrgIcons'
import { generateDailyCostXlsReport } from './dailyCostExcelReports'
import { generateCostTrackingXls } from './dailyCostTrackerExcelReport'

export const COST_TRACKING_REPORT = 'costTracking'
export const DAILY_COST_REPORT = 'dailyCost'
export const GROUPED_COST_REPORT = 'grouped'
export const DATE_RANGE_COST_REPORT = 'dateRange'
export const ALL_COSTS_REPORT = 'allCosts'

export const getGroupedByDateInvoiceData = (costDataRef, startDateStr, endDateStr, isPdf = true, costUnitsText) => {
  const { dailyCosts } = costDataRef
  if (!isValidDate(startDateStr) || !isValidDate(endDateStr)) return null
  if (!Array.isArray(dailyCosts)) return null
  let startDate = new Date(Date.parse(startDateStr + 'T00:01:00')).getTime()
  let endDate = new Date(Date.parse(endDateStr + 'T00:01:00')).getTime()

  let filtered = []
  for (let i = 0; i < dailyCosts.length; i++) {
    if (!isValidDate(dailyCosts[i]?.date)) continue
    const date = new Date(Date.parse(dailyCosts[i]?.date + 'T00:01:00')).getTime()

    if (date < startDate || date > endDate) continue
    filtered.push(dailyCosts[i])
  }

  return getGroupedInvoiceData(costDataRef.wellName, filtered, isPdf, costUnitsText)
}

export const getGroupedInvoiceData = (wellName, dailyCosts, isPdf, costUnitsText) => {
  if (!Array.isArray(dailyCosts)) return null

  let invoiceData = []

  if (isPdf) {
    invoiceData.push(
      [{ text: `${wellName} - Field Invoice`, isHeader: true, columnSpan: 8 }],
      [
        { text: 'Item#', isHeader: true },
        { text: 'Description', isHeader: true },
        { text: 'Start Date', isHeader: true },
        { text: 'End Date', isHeader: true },
        { text: 'SN', isHeader: true },
        { text: `Unit Cost (${costUnitsText})`, isHeader: true },
        { text: 'Qty', isHeader: true },
        { text: `Total Cost (${costUnitsText})`, isHeader: true },
      ],
    )
  }

  let flattenedCosts = []
  for (let i = 0; i < dailyCosts.length; i++) {
    if (!Array.isArray(dailyCosts[i].costs)) continue

    for (let j = 0; j < dailyCosts[i].costs.length; j++) {
      let cost = cloneDeep(dailyCosts[i].costs[j])
      cost.startDate = dailyCosts[i].date
      cost.endDate = dailyCosts[i].date
      flattenedCosts.push(cost)
    }
  }

  let groupedCosts = {}
  for (let i = 0; i < flattenedCosts.length; i++) {
    let key = 'wellCosts'
    if (flattenedCosts[i].bhaNum > 0) {
      key = `${flattenedCosts[i].bhaNum}`
    }

    if (!Array.isArray(groupedCosts[key])) {
      groupedCosts[key] = []
    }

    if (flattenedCosts[i].serialNum !== '') {
      groupedCosts[key].push(flattenedCosts[i])
    }

    if (flattenedCosts[i].serialNum === '') {
      let found = false
      for (let j = 0; j < groupedCosts[key].length; j++) {
        if (groupedCosts[key][j].costCode === flattenedCosts[i].costCode) {
          found = true
          groupedCosts[key][j].quantity += flattenedCosts[i].quantity

          if (
            new Date(Date.parse(flattenedCosts[i].startDate)) < new Date(Date.parse(groupedCosts[key][j].startDate))
          ) {
            groupedCosts[key][j].startDate = flattenedCosts[i].startDate
          }

          if (new Date(Date.parse(flattenedCosts[i].endDate)) > new Date(Date.parse(groupedCosts[key][j].endDate))) {
            groupedCosts[key][j].endDate = flattenedCosts[i].endDate
          }
        }
      }

      if (!found) {
        groupedCosts[key].push(flattenedCosts[i])
      }
    }
  }

  let itemCounter = 1
  let totalCost = 0
  for (const key in groupedCosts) {
    let costs = groupedCosts[key]
    let groupTotal = 0
    for (let i = 0; i < costs.length; i++) {
      costs[i].totalValue = costs[i].quantity * costs[i].value
      groupTotal += costs[i].totalValue
    }
    totalCost += groupTotal

    let groupTotalCostText = `${costUnitsText}${numberWithCommasDecimals(groupTotal, 2)}`
    let title = `Well Costs - ${groupTotalCostText}`
    if (key !== 'wellCosts') {
      title = `BHA #${costs[0].bhaNumRep}: ${costs[0].bhaDesc} - ${groupTotalCostText}`
    }

    invoiceData.push([
      { text: '' },
      { text: title },
      { text: '' },
      { text: '' },
      { text: '' },
      { text: '' },
      { text: '' },
      { text: '' },
    ])

    for (let i = 0; i < costs.length; i++) {
      invoiceData.push([
        { text: itemCounter },
        { text: costs[i].costCode + ': ' + costs[i].description },
        { text: new Date(Date.parse(costs[i].startDate + 'T00:00:01')).toLocaleDateString() },
        { text: new Date(Date.parse(costs[i].endDate + 'T00:00:01')).toLocaleDateString() },
        { text: costs[i].serialNumber },
        { text: numberWithCommasDecimals(costs[i].value, 2) },
        { text: costs[i].quantity },
        { text: numberWithCommasDecimals(costs[i].value * costs[i].quantity, 2) },
      ])

      itemCounter++
    }

    invoiceData.push([
      { text: '' },
      { text: '' },
      { text: '' },
      { text: '' },
      { text: '' },
      { text: '' },
      { text: '' },
      { text: '' },
    ])
  }

  invoiceData.push([
    { text: '' },
    { text: '' },
    { text: '' },
    { text: '' },
    { text: '' },
    { text: '' },
    { text: `Total Cost (${costUnitsText})` },
    { text: numberWithCommasDecimals(totalCost, 2) },
  ])

  return invoiceData
}

export const getFieldInvoiceData = (costDataRef, isPdf = true, costUnitsText) => {
  let invoiceData = []

  if (isPdf) {
    invoiceData.push(
      [{ text: `${costDataRef.wellName} - Field Invoice`, isHeader: true, columnSpan: 8, fontSize: 20 }],
      [
        { text: 'Description', isHeader: true },
        { text: 'Date', isHeader: true },
        { text: 'Serial #', isHeader: true },
        { text: 'Cost Code', isHeader: true },
        { text: `Unit Cost (${costUnitsText})`, isHeader: true },
        { text: 'Qty', isHeader: true },
        { text: `Total Cost (${costUnitsText})`, isHeader: true },
      ],
    )
  }

  if (!Array.isArray(costDataRef.dailyCosts)) return null

  for (let i = 0; i < costDataRef.dailyCosts.length; i++) {
    let dailyCost = costDataRef.dailyCosts[i]
    for (let j = 0; j < dailyCost.costs.length; j++) {
      let cost = dailyCost.costs[j]
      invoiceData.push([
        { text: cost.description },
        { text: new Date(Date.parse(dailyCost.date + 'T00:00:01')).toLocaleDateString() },
        { text: cost.serialNum },
        { text: cost.costCode },
        { text: numberWithCommasDecimals(cost.value, 2) },
        { text: cost.quantity },
        { text: numberWithCommasDecimals(cost.totalValue, 2) },
      ])
    }
  }

  invoiceData.push([
    { text: '' },
    { text: '' },
    { text: '' },
    { text: '' },
    { text: '' },
    { text: `Total Cost (${costUnitsText})` },
    { text: numberWithCommasDecimals(costDataRef.totalCost, 2) },
  ])

  return invoiceData
}

export const isValidDate = (value) => {
  return value instanceof Date || !isNaN(Date.parse(value))
}

function useDailyCosts() {
  const _isMounted = useRef(true)
  const costData = useRef({})
  const costCodeData = useRef([])
  const isLoading = useRef(false)
  const [loading, setLoading] = useState(false)
  const currentWell = useRecoilValue(currentWellAtom)
  const currentWellRef = useRef(currentWell)
  const { getUnitsText } = useUnits()
  const { getCurrentOrgIcon } = useOrgIcons()
  const userRole = useRecoilValue(userUserRoleAtom)

  const getWellCosts = useInnovaAxios({
    url: '/well/wellCost/getCosts',
  })

  const getCostCodes = useInnovaAxios({
    url: '/well/getCostCodes',
  })

  useEffect(() => {
    if (!_isMounted.current || !costData.current) return
    fetchCostCodes()
  }, [costData.current]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    _isMounted.current = true
    return () => {
      _isMounted.current = false
    }
  }, [])

  useEffect(() => {
    currentWellRef.current = currentWell
    fetchCostData()
  }, [currentWell]) // eslint-disable-line react-hooks/exhaustive-deps

  const fetchCostData = async () => {
    if (typeof currentWellRef.current !== 'string') return
    if (currentWellRef.current === '') return
    if (isLoading.current) return
    isLoading.current = true
    if (_isMounted.current) setLoading(true)

    const response = await getWellCosts({ wellName: currentWellRef.current })
    if (_isMounted.current) {
      setLoading(false)
      costData.current = response?.data ? response.data : null
    }

    isLoading.current = false
  }

  const sortDate = (a, b) => {
    if (typeof a?.date !== 'string') return -1
    if (typeof b?.date !== 'string') return -1
    let d1 = new Date(Date.parse(a.date + 'T00:00:01'))
    let d2 = new Date(Date.parse(b.date + 'T00:00:01'))
    return d1 < d2 ? 1 : d1 > d2 ? -1 : 0
  }

  const fetchWellCosts = () => {
    if (!costData.current) return {}
    if (!costData.current.hasOwnProperty('dailyCosts')) return {}
    if (!Array.isArray(costData.current.dailyCosts)) return {}

    let sortedData = cloneDeep(costData.current)
    sortedData.dailyCosts.sort(sortDate)

    const { costCodes } = getDropDowns()
    for (let i = 0; i < sortedData.dailyCosts.length; i++) {
      sortedData.dailyCosts[i].costs.sort((a, b) => a.sequenceNo - b.sequenceNo)
      for (let j = 0; j < sortedData.dailyCosts[i].costs.length; j++) {
        let costCode = costCodes.find((code) => code.value === sortedData.dailyCosts[i].costs[j].costCode)
        sortedData.dailyCosts[i].costs[j].color = costCode ? costCode.color : getRandomColor()
      }
    }
    return sortedData
  }

  const fetchCostCodes = async () => {
    if (!currentWellRef.current) return

    const response = await getCostCodes({ wellName: currentWellRef.current })

    if (!_isMounted.current) return
    if (response?.error) return
    if (!response?.data) return

    costCodeData.current = response.data
  }

  const getHeaderData = () => {
    let headerData = [
      [
        { text: 'Job #', isBold: true },
        { text: costData.current.jobNumber, textAlign: 'left' },
        { text: 'Operator', isBold: true },
        { text: costData.current.operator, textAlign: 'left' },
      ],
      [
        { text: 'API Job #', isBold: true },
        { text: costData.current.apiJobNum, textAlign: 'left' },
        { text: 'Field', isBold: true },
        { text: costData.current.field, textAlign: 'left' },
      ],
      [
        { text: 'Rig', isBold: true },
        { text: costData.current.rig, textAlign: 'left' },
        { text: 'Well', isBold: true },
        { text: costData.current.wellName, textAlign: 'left' },
      ],
      [
        { text: 'State', isBold: true },
        { text: costData.current.state, textAlign: 'left' },
        { text: 'Date', isBold: true },
        { text: new Date(Date.now()).toLocaleDateString(), textAlign: 'left' },
      ],
      [
        { text: 'County', isBold: true },
        { text: costData.current.county, textAlign: 'left' },
        { text: `Well Total ${getUnitsText(UNITS_FOR.Cost)}`, isBold: true },
        { text: numberWithCommasDecimals(costData.current.totalCost, 2), textAlign: 'left' },
      ],
    ]

    if (costData.current?.linkedBidDescription) {
      headerData.push([
        { text: 'Bid ID', isBold: true },
        { text: costData.current.linkedBidId, textAlign: 'left' },
        { text: 'Bid', isBold: true },
        { text: costData.current.linkedBidDescription, textAlign: 'left' },
      ])
    }

    return headerData
  }

  const getPersonnelNames = (personnelNames) => {
    if (!personnelNames || !Array.isArray(personnelNames)) return ''
    if (personnelNames.length === 0) return ''

    let joinedNamesStr = ''
    for (let i = 0; i < personnelNames.length; i++) {
      if (personnelNames[i] === '') continue
      if (joinedNamesStr !== '') joinedNamesStr += ' / '
      joinedNamesStr += personnelNames[i]
    }
    return joinedNamesStr
  }

  const validateStartEndDate = (startDate, endDate, dailyCosts) => {
    let startDateOut = ''
    let endDateOut = ''

    if (isValidDate(startDate)) startDateOut = new Date(Date.parse(startDate + 'T00:01:00')).toLocaleDateString()
    if (isValidDate(endDate)) endDateOut = new Date(Date.parse(endDate + 'T00:01:00')).toLocaleDateString()

    if (Array.isArray(dailyCosts) && dailyCosts.length > 0) {
      if (!isValidDate(startDate) && isValidDate(dailyCosts[0]?.date)) {
        startDateOut = new Date(Date.parse(dailyCosts[0]?.date + 'T00:01:00')).toLocaleDateString()
      }

      if (!isValidDate(endDate) && isValidDate(dailyCosts[dailyCosts.length - 1]?.date)) {
        endDateOut = new Date(Date.parse(dailyCosts[dailyCosts.length - 1].date + 'T00:01:00')).toLocaleDateString()
      }
    }

    return { startDateStr: startDateOut, endDateStr: endDateOut }
  }

  const getGroupedPdfHeaderData = (startDate, endDate) => {
    const { dailyCosts } = costData.current
    const { startDateStr, endDateStr } = validateStartEndDate(startDate, endDate, dailyCosts)

    let headerData = {
      columnWidths: ['15%', '30%', '15%', '40%'],
      manualWidth: true,
      sectionAfter: 5,
      data: [
        [
          { text: 'Job #', isBold: true },
          { text: costData.current.jobNumber, textAlign: 'left' },
          { text: 'Operator', isBold: true },
          { text: costData.current.operator, textAlign: 'left' },
        ],
        [
          { text: 'API Job #', isBold: true },
          { text: costData.current.apiJobNum, textAlign: 'left' },
          { text: 'Field', isBold: true },
          { text: costData.current.field, textAlign: 'left' },
        ],
        [
          { text: 'Rig', isBold: true },
          { text: costData.current.rig, textAlign: 'left' },
          { text: 'Well', isBold: true },
          { text: costData.current.wellName, textAlign: 'left' },
        ],
        [
          { text: 'Invoice Date', isBold: true },
          { text: new Date(Date.now()).toLocaleDateString(), textAlign: 'left' },
          { text: 'State', isBold: true },
          { text: costData.current.state, textAlign: 'left' },
        ],
        [
          { text: `Well Total ${getUnitsText(UNITS_FOR.Cost)}`, isBold: true },
          { text: numberWithCommasDecimals(costData.current.totalCost, 2), textAlign: 'left' },
          { text: 'County', isBold: true },
          { text: costData.current.county, textAlign: 'left' },
        ],
        [
          { text: `Start Hole Size (${getUnitsText(UNITS_FOR.Diameter)})`, isBold: true },
          { text: numberToString(costData.current.startHoleSize, 2), textAlign: 'left' },
          { text: `End Hole Size (${getUnitsText(UNITS_FOR.Diameter)})`, isBold: true },
          { text: numberToString(costData.current.endHoleSize, 2), textAlign: 'left' },
        ],
        [
          { text: `Start Depth (${getUnitsText(UNITS_FOR.Depth)})`, isBold: true },
          { text: numberWithCommasDecimals(costData.current.startDepth, 2), textAlign: 'left' },
          { text: `End Depth (${getUnitsText(UNITS_FOR.Depth)})`, isBold: true },
          { text: numberWithCommasDecimals(costData.current.endDepth, 2), textAlign: 'left' },
        ],
        [
          { text: 'Start Date', isBold: true },
          { text: startDateStr, textAlign: 'left' },
          { text: 'End Date', isBold: true },
          { text: endDateStr, textAlign: 'left' },
        ],
        [
          { text: 'Directional Supervisor', isBold: true },
          { text: getPersonnelNames(costData.current.directionalDrillers), textAlign: 'left' },
          { text: 'MWD Supervisor', isBold: true },
          { text: getPersonnelNames(costData.current.mwdEngineers), textAlign: 'left' },
        ],
        [
          { text: 'AFE #', isBold: true },
          { text: costData.current.afeNum, textAlign: 'left' },
          { text: 'PO #', isBold: true },
          { text: costData.current.poNum, textAlign: 'left' },
        ],
        [
          { text: 'Mud Type', isBold: true },
          { text: costData.current.mudType, textAlign: 'left' },
          { text: '' },
          { text: '' },
        ],
      ],
    }

    if (costData.current?.linkedBidDescription) {
      headerData.data.push([
        { text: 'Bid ID', isBold: true },
        { text: costData.current.linkedBidId },
        { text: 'Bid', isBold: true },
        { text: costData.current.linkedBidDescription },
      ])
    }

    return headerData
  }

  const generatePdfDocument = (grouped = false, startDate = null, endDate = null) => {
    return [
      {
        tableType: 'header',
        showTitle: true,
        title: `${costData.current.operator} / ${currentWell} - Field Invoice`,
        showLogo: true,
        logo: getCurrentOrgIcon(),
        logoWidth: '40%',
        height: grouped || startDate ? '70px' : null,
        columnWidths: grouped || startDate ? ['100%'] : ['15%', '20%', '15%', '50%'],
        data:
          grouped || startDate
            ? [[{ text: `${costData.current.wellName} - Field Invoice`, height: '80px', fontSize: 20 }]]
            : getHeaderData(),
      },
      grouped || startDate ? getGroupedPdfHeaderData(startDate, endDate) : null,
      {
        fixedHeaders: 2,
        manualWidth: true,
        columnWidths: ['10%', '30%', '10%', '10%', '10%', '10%', '10%', '10%'],
        sectionAfter: 5,
        data: grouped
          ? getGroupedInvoiceData(
            costData.current.wellName,
            costData.current.dailyCosts,
            true,
            getUnitsText(UNITS_FOR.Cost),
          )
          : startDate
            ? getGroupedByDateInvoiceData(costData.current, startDate, endDate, false, getUnitsText(UNITS_FOR.Cost))
            : getFieldInvoiceData(costData.current, true, getUnitsText(UNITS_FOR.Cost)),
      },
      {
        sectionAfter: 5,
        manualWidth: true,
        columnWidths: ['22%', '78%'],
        height: '80px',
        data: [[{ text: 'Customer Signature / Stamp', isHeader: true }, { text: ' ' }]],
      },
      {
        sectionAfter: 5,
        manualWidth: true,
        columnWidths: ['22%', '78%'],
        height: '80px',
        data: [[{ text: 'Address', isHeader: true }, { text: '' }]],
      },
      {
        sectionAfter: 5,
        manualWidth: true,
        columnWidths: ['22%', '78%'],
        height: '80px',
        data: [[{ text: 'Field Representative - Signature', isHeader: true }, { text: '' }]],
      },
    ]
  }

  const getPdfData = (grouped = false, startDate = null, endDate = null) => {
    if (!costData.current) return null
    if (!costData.current.hasOwnProperty('wellName')) return null
    if (!costData.current.hasOwnProperty('dailyCosts')) return null

    let pdfData = generatePdfDocument(grouped, startDate, endDate)
    if (!pdfData) return null

    return {
      fileName: `${grouped ? 'Grouped ' : ''}Cost Report - ${removeSpecialSymbols(currentWell)}`,
      data: <PdfDocument data={pdfData} multiDocument={false} pageOrientation={'portrait'} reportSettings={userRole?.userPrefs?.reportSettings} />,
    }
  }

  const getXlsData = (exportType = ALL_COSTS_REPORT, startDate = null, endDate = null) => {
    if (typeof exportType !== 'string' || !exportType) return null
    if (startDate && !isValidDate(startDate)) return null
    if (endDate && !isValidDate(endDate)) return null
    if (!costData.current) return null
    if (!costData.current.hasOwnProperty('dailyCosts')) return null
    if (!Array.isArray(costData.current.dailyCosts)) return null
    if (costData.current.dailyCosts.length <= 0) return null

    const costUnitsText = getUnitsText(UNITS_FOR.Cost)
    const diamUnitsText = getUnitsText(UNITS_FOR.Diameter)
    const deptUnitsText = getUnitsText(UNITS_FOR.Depth)

    switch (exportType) {
      case COST_TRACKING_REPORT:
        generateCostTrackingXls(
          costData.current,
          startDate,
          endDate,
          costUnitsText,
          getCurrentOrgIcon,
          currentWellRef.current,
          userRole?.userPrefs?.reportSettings,
        )
        break

      case DAILY_COST_REPORT:
      case GROUPED_COST_REPORT:
      case DATE_RANGE_COST_REPORT:
      case ALL_COSTS_REPORT:
      default:
        generateDailyCostXlsReport(
          costData.current,
          startDate,
          endDate,
          exportType,
          costUnitsText,
          diamUnitsText,
          deptUnitsText,
          getCurrentOrgIcon,
          currentWellRef.current,
          userRole?.userPrefs?.reportSettings,
        )
        break
    }
  }

  const getDropDowns = () => {
    let newDropDowns = {
      costCodes: [],
      descriptions: [],
      bhas: [],
    }

    if (!costData.current) return newDropDowns
    if (!costData.current.hasOwnProperty('costCodes')) return newDropDowns
    if (!costData.current.hasOwnProperty('bha')) return newDropDowns

    const { costCodes, bha } = costData.current
    if (Array.isArray(costCodes)) {
      for (let i = 0; i < costCodes.length; i++) {
        newDropDowns.costCodes.push({
          label: costCodes[i].costCode,
          value: costCodes[i].costCode,
          costValue: costCodes[i].value,
          color: i < chartSeriesColors.length ? chartSeriesColors[i] : getRandomColor(),
        })
        newDropDowns.descriptions.push({ label: costCodes[i].description, value: costCodes[i].costCode })
      }
    }

    if (Array.isArray(bha)) {
      for (let i = 0; i < bha.length; i++) {
        newDropDowns.bhas.push({ label: `${bha[i].bhaNumRep}: ${bha[i].description}`, value: bha[i].bhaNum })
      }
    }

    return newDropDowns
  }

  const getSerialNumbers = (bhaNum) => {
    if (typeof bhaNum !== 'number' && typeof bhaNum !== 'string') return []
    if (typeof bhaNum === 'string') bhaNum = parseInt(bhaNum)

    if (!costData.current) return []
    if (!costData.current.hasOwnProperty('serialNums')) return []
    if (!Array.isArray(costData.current.serialNums)) return []

    let output = ['none']
    const { serialNums } = costData.current

    for (let i = 0; i < serialNums.length; i++) {
      if (serialNums[i].bhaNum !== bhaNum) continue
      let serialNum = serialNums[i].serialNum
      serialNum = serialNum.replace(/^\s+|\s+$/g, '')

      if (serialNum === '') continue
      output.push(serialNum)
    }

    return output
  }

  return {
    loading,
    fetchWellCosts,
    fetchCostData,
    getPdfData,
    getXlsData,
    getDropDowns,
    getSerialNumbers,
    currentWell,
  }
}

export default useDailyCosts
