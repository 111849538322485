import React, { useRef, useEffect, useMemo, useState, useCallback } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { cloneDeep } from 'lodash'
import { saveItemToLS } from 'utils/localStorage'
import { appColors, getDeltaTime } from 'utils'
import { Tooltip, IconButton, Box } from '@mui/material'
import { Icon as Iconify } from '@iconify/react'
import ConfirmDialog from 'components/common/ConfirmDialog'
import { useRecoilValue } from 'recoil'
import { currentWellSelector } from 'atoms'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import { numberWithCommasDecimals } from 'utils/stringFunctions'
import { unescapeHtml } from 'utils/htmlSymbolHandling'
import ToolFaceIndicator from './ToolFaceIndicator'
import { sortColDefs, getStringId } from 'components/common/AgGridUtils'
import useObjectQc from 'components/common/hooks/useObjectQc'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const SlideSheetDetailsGrid = ({
  parentRowData,
  refreshCallback,
  prevSlideSheetEndDepth = -1,
  setStatus = null,
  onXlsExport,
  masterGridApi,
}) => {
  const _isMounted = useRef(false)
  const gridApi = useRef(null)
  const [resetCols, setResetCols] = useState(false)
  const inputRow = useRef({})
  const [confirm, setConfirm] = useState({ show: false, title: '' })
  const currentWell = useRecoilValue(currentWellSelector)
  const pastedRows = useRef([])
  const currentWellRef = useRef(currentWell)
  const { getChecks, getCellStyle } = useObjectQc()
  const { getAgGridTheme } = useInnovaTheme()

  const colsWithSetters = useMemo(() => ['state', 'startTime', 'endTime', 'startDepth', 'stopDepth', 'tfType'], [])

  let checks = useMemo(() => getChecks(parentRowData?.operator, 'SLIDE_SHEET'), [parentRowData, getChecks])

  const deleteSlideSheetRow = useInnovaAxios({
    url: '/well/slideSheets/deleteSlideSheet',
  })

  useEffect(() => {
    _isMounted.current = true
    if (masterGridApi && masterGridApi?.gridOptions?.api) {
      masterGridApi.removeDetailGridInfo(`SLIDE_DETAIL_GRID-${parentRowData?.bhaNum}`)
    }

    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    currentWellRef.current = currentWell
  }, [currentWell]) // eslint-disable-line react-hooks/exhaustive-deps

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.uid)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const updateParentGrid = useCallback(
    (payload) => {
      if (!payload) return
      if (gridApi.current) {
        for (let i = 0; i < payload.data.length; i++) {
          if (payload.data[i].state === 'Rotate') {
            payload.data[i].tfType = ''
          }
        }
        if (payload.action === 'delete') gridApi.current.applyTransaction({ remove: payload.data })
        if (payload.action === 'update') gridApi.current.applyTransaction({ update: payload.data })
      }

      refreshCallback(payload)
    },
    [refreshCallback],
  )

  const clearCells = useCallback(
    (start, end, columns, gridApi) => {
      let itemsToUpdate = []
      for (let i = start; i <= end; i++) {
        let data = gridApi.rowModel.rowsToDisplay[i].data
        columns.forEach((column) => {
          if (colsWithSetters.findIndex((col) => col === column) < 0) {
            data[column] = ''
          }
        })
        itemsToUpdate.push(data)
      }

      updateParentGrid({
        action: 'update',
        data: itemsToUpdate,
      })
    },
    [updateParentGrid, colsWithSetters],
  )

  const defaultColDef = useMemo(() => {
    return {
      suppressKeyboardEvent: (params) => {
        if (!params.editing) {
          let isBackspaceKey = params.event.keyCode === 8
          let isDeleteKey = params.event.keyCode === 46

          if (isBackspaceKey || isDeleteKey) {
            params.api.getCellRanges().forEach((range) => {
              let colIds = range.columns.map((col) => col.colId)
              let startRowIndex = Math.min(range.startRow.rowIndex, range.endRow.rowIndex)
              let endRowIndex = Math.max(range.startRow.rowIndex, range.endRow.rowIndex)
              clearCells(startRowIndex, endRowIndex, colIds, params.api)
            })
          }
        }
        return false
      },
      editable: true,
      resizable: false,
      sortable: false,
      filter: null,
      headerClass: 'header-no-padding',
      cellStyle: (params) => {
        return getCellStyle(params, checks, 'center')
      },
      valueFormatter: (params) => (isEmptyPinnedCell(params) ? createPinnedCellPlaceholder(params) : undefined),
    }
  }, [getCellStyle, checks, clearCells])

  function createPinnedCellPlaceholder(params) {
    const { colDef } = params
    if (colDef.field === 'date') return 'Date...'
    if (colDef.field === 'startTime') return 'Start Time...'
    if (colDef.field === 'endTime') return 'End Time...'

    return ''
  }

  function isEmptyPinnedCell({ node, value }) {
    return (node?.rowPinned === 'bottom' && value == null) || (node?.rowPinned === 'bottom' && value === '')
  }

  const actionIconRenderer = (params) => {
    return (
      <Box style={{ display: 'flex', flexDirection: 'row' }}>
        <Tooltip
          title={params.node?.rowPinned !== 'bottom' ? 'Delete' : 'Add'}
          placement='left'
          componentsProps={{
            tooltip: {
              sx: {
                backgroundColor: 'rgb(19,62,96)',
                fontSize: '12px',
                fontFamily: 'Roboto',
              },
            },
          }}>
          <IconButton
            sx={{ padding: '5px', alignItems: 'center', justifyContent: 'center' }}
            onClick={() => (params.node?.rowPinned !== 'bottom' ? handleDelete(params) : handleAddRow(params))}
            size='large'>
            {params.node?.rowPinned !== 'bottom' ? (
              <Iconify icon='fa-regular:trash-alt' color={'#C00000'} fontSize={'16px'} />
            ) : (
              <Iconify icon='fluent:add-12-filled' color={'green'} fontSize={'16px'} />
            )}
          </IconButton>
        </Tooltip>
        {params.node?.rowPinned !== 'bottom' ? (
          <Box style={{ textAlign: 'right', paddingLeft: '8px' }}>{params.node.rowIndex + 1}</Box>
        ) : null}
      </Box>
    )
  }

  const handleDelete = (params) => {
    setConfirm({
      show: true,
      title: 'Delete Slide Sheet Row',
      text: `Are you sure you want to delete row?`,
      params: params,
    })
  }

  const confirmDelete = async () => {
    if (currentWellRef.current?.length <= 0) return
    if (confirm.params.data?.uid < 0) return
    const deleteResponse = await deleteSlideSheetRow({
      wellName: currentWellRef.current,
      uid: confirm.params.data?.uid,
    })

    if (deleteResponse?.error) {
      showErrorMessage(deleteResponse?.error?.response?.data?.error)
      return
    }

    updateParentGrid({ action: 'delete', data: [cloneDeep(confirm.params.data)] })
  }

  const clearPinnedRow = () => {
    inputRow.current = {}
    if (gridApi.current) gridApi.current.setGridOption('pinnedBottomRowData', [inputRow.current])
  }

  const handleAddRow = async (params) => {
    //add in bhaNum date, wellName
    if (!isPinnedRowDataCompleted()) return

    let newData = cloneDeep(inputRow.current)
    let prevSlideRow = getPrevSlideRowFromTime(newData.date, newData.startTime)
    let nextSlideRow = getNextSlideRowFromTime(newData.date, newData.endTime)

    //No Data
    if (!prevSlideRow && !nextSlideRow) {
      newData.bhaNum = -1
      newData.startDepth = prevSlideSheetEndDepth >= 0 ? prevSlideSheetEndDepth : 0
      newData.endDepth = newData.startDepth + 1
    }

    //Check if overlapping the previous record completely
    if (
      prevSlideRow !== null &&
      dateTimeStrToDecimal(newData.date, newData.startTime) <=
        dateTimeStrToDecimal(prevSlideRow.date, prevSlideRow.startTime)
    ) {
      showErrorMessage('Start time must be greater than prev slide start time')
      return
    }

    //Check if overlapping the next record completely
    if (
      nextSlideRow !== null &&
      dateTimeStrToDecimal(newData.date, newData.endTime) >=
        dateTimeStrToDecimal(nextSlideRow.date, nextSlideRow.endTime)
    ) {
      showErrorMessage('Start time must be greater than prev slide start time')
      return
    }

    let recordsToUpdate = []

    //Will be first slide row
    if (prevSlideRow === null && nextSlideRow !== null) {
      newData.bhaNum = nextSlideRow.bhaNum
      newData.startDepth = nextSlideRow.startDepth - 1
      newData.endDepth = nextSlideRow.startDepth
      newData.state = nextSlideRow.state

      //Overlapping next slide row
      if (
        dateTimeStrToDecimal(newData.date, newData.endTime) >
        dateTimeStrToDecimal(nextSlideRow.date, nextSlideRow.startTime)
      ) {
        let newNextSlideRow = cloneDeep(nextSlideRow)
        newNextSlideRow.startTime = newData.endTime
        newNextSlideRow.hours = getHours(newNextSlideRow.startTime, newNextSlideRow.endTime)
        recordsToUpdate.push(newNextSlideRow)
      }
    }

    //Last slide row
    if (nextSlideRow === null && prevSlideRow !== null) {
      newData.bhaNum = prevSlideRow.bhaNum
      newData.startDepth = prevSlideRow.endDepth
      newData.endDepth = prevSlideRow.endDepth + 1
      newData.state = prevSlideRow.state

      //Overlapping prev slide row
      if (
        dateTimeStrToDecimal(newData.date, newData.startTime) <
        dateTimeStrToDecimal(prevSlideRow.date, prevSlideRow.endTime)
      ) {
        let newPrevSlideRow = cloneDeep(prevSlideRow)
        newPrevSlideRow.endTime = newData.startTime
        newPrevSlideRow.hours = getHours(newPrevSlideRow.startTime, newPrevSlideRow.endTime)
        recordsToUpdate.push(newPrevSlideRow)
      }
    }

    //Inserted in the middle
    if (nextSlideRow !== null && prevSlideRow !== null) {
      newData.bhaNum = prevSlideRow.bhaNum
      newData.startDepth = prevSlideRow.endDepth
      newData.endDepth = nextSlideRow.startDepth
      newData.state = prevSlideRow.state

      if (newData.startDepth === newData.endDepth) {
        showErrorMessage('No depth gap, start depth and end depth cannot be the same')
        return
      }

      //Overlapping prev slide row
      if (
        dateTimeStrToDecimal(newData.date, newData.startTime) <
        dateTimeStrToDecimal(prevSlideRow.date, prevSlideRow.endTime)
      ) {
        let newPrevSlideRow = cloneDeep(prevSlideRow)
        newPrevSlideRow.endTime = newData.startTime
        newPrevSlideRow.hours = getHours(newPrevSlideRow.startTime, newPrevSlideRow.endTime)
        recordsToUpdate.push(newPrevSlideRow)
      }

      //Overlapping next slide row
      if (
        dateTimeStrToDecimal(newData.date, newData.endTime) >
        dateTimeStrToDecimal(nextSlideRow.date, nextSlideRow.startTime)
      ) {
        let newNextSlideRow = cloneDeep(nextSlideRow)
        newNextSlideRow.startTime = newData.endTime
        newNextSlideRow.hours = getHours(newNextSlideRow.startTime, newNextSlideRow.endTime)
        recordsToUpdate.push(newNextSlideRow)
      }
    }

    newData.hours = getHours(newData.startTime, newData.endTime)
    newData.actualwell = currentWellRef.current
    newData.bhaNum = parentRowData?.bhaNum
    newData.date = newData.date + 'T00:00:01Z'

    if (prevSlideRow) {
      newData.wob = prevSlideRow.wob
      newData.onBtmPress = prevSlideRow.onBtmPress
      newData.offBtmPress = prevSlideRow.offBtmPress
      newData.rpm = prevSlideRow.rpm
      newData.torque = prevSlideRow.torque
      newData.temperature = prevSlideRow.temperature
      newData.puWeight = prevSlideRow.puWeight
      newData.soWeight = prevSlideRow.soWeight
      newData.rotWeight = prevSlideRow.rotWeight
    }

    updateParentGrid({
      action: 'add',
      data: [newData],
      dataToUpdate: recordsToUpdate,
    })

    clearPinnedRow()
  }

  const isPinnedRowDataCompleted = () => {
    let requiredData = ['date', 'startTime', 'endTime']
    for (let i = 0; i < requiredData.length; i++) {
      if (!inputRow.current.hasOwnProperty(requiredData[i])) return false
    }

    return true
  }

  function showErrorMessage(message) {
    if (!setStatus) return
    setStatus({ show: true, severity: 'error', message: message ? message : 'Uknown error' })
  }

  function isDateLessThan(date1, date2) {
    if (!isValidDate(date1) || !isValidDate(date2)) return false
    const time1 = new Date(date1).getTime()
    const time2 = new Date(date2).getTime()
    return time1 < time2
  }

  const isValidDate = (value) => {
    return value instanceof Date || !isNaN(Date.parse(value))
  }

  const calcRop = (dist, startTime, endTime) => {
    if (dist === null || startTime === null || endTime === null) return 0
    if (dist === undefined || startTime === undefined || endTime === undefined) return 0
    if (typeof dist === 'string') dist = parseFloat(dist)
    if (typeof startTime !== 'string') return 0
    if (typeof endTime !== 'string') return 0
    let deltaTime = getDeltaTime(startTime, endTime, true)
    if (deltaTime === 0 || dist === 0) return 0
    return dist / deltaTime
  }

  function getHours(startTime, endTime) {
    if (typeof startTime !== 'string') return 0
    if (typeof endTime !== 'string') return 0

    // Convert start time to minutes
    var startParts = startTime.split(':')
    var startHours = parseInt(startParts[0])
    var startMinutes = parseInt(startParts[1])
    var startTotalMinutes = startHours * 60 + startMinutes

    // Convert end time to minutes
    var endParts = endTime.split(':')
    var endHours = parseInt(endParts[0])
    var endMinutes = parseInt(endParts[1])
    var endTotalMinutes = endHours * 60 + endMinutes

    // Calculate the difference in minutes
    var minuteDifference = endTotalMinutes - startTotalMinutes

    // Convert the minute difference to hours as a floating-point number
    var hourDifference = minuteDifference / 60

    return hourDifference
  }

  const isTimeStrValid = (timeStr) => {
    if (typeof timeStr !== 'string') return false
    return /^([01]\d|2[0-4]):([0-5]\d)$/.test(timeStr)
  }

  const isStrValidFloat = (str) => {
    if (typeof str === 'number') {
      if (str < 0) return false
      if (str > 100000) return false
      return true
    }
    if (typeof str !== 'string') return false

    return /^(0|[1-9]\d{0,6})(\.\d{1,2})?$/.test(str)
  }

  const dateTimeStrToDecimal = (dateStr, timeStr) => {
    if (typeof dateStr !== 'string') return -1
    if (dateStr.length < 10) return -1
    let date = dateStr.substring(0, 10)

    if (!isTimeStrValid(timeStr)) return -1
    let dateTimeStr = `${date}T${timeStr}:00.000`

    if (!isValidDate(dateTimeStr)) return -1
    let decimalTimeMs = new Date(Date.parse(dateTimeStr)).getTime()
    return decimalTimeMs
  }

  const getNextSlideRowFromTime = (dateStr, timeStr) => {
    //Time passed in will usually be the end time of the activity
    if (typeof timeStr !== 'string') return null
    let decTime = dateTimeStrToDecimal(dateStr, timeStr)
    if (decTime < 0) return null
    if (!parentRowData) return null
    if (!parentRowData.hasOwnProperty('slideRecords')) return null
    if (!Array.isArray(parentRowData.slideRecords)) return null
    const { slideRecords } = parentRowData

    if (slideRecords.length === 0) return null
    if (decTime <= dateTimeStrToDecimal(slideRecords[0].date, slideRecords[0].startTime)) return slideRecords[0]

    for (let i = 0; i < slideRecords.length; i++) {
      if (decTime <= dateTimeStrToDecimal(slideRecords[i].date, slideRecords[i].startTime)) {
        return slideRecords[i]
      }

      if (
        decTime >= dateTimeStrToDecimal(slideRecords[i].date, slideRecords[i].startTime) &&
        decTime <= dateTimeStrToDecimal(slideRecords[i].date, slideRecords[i].endTime)
      ) {
        return slideRecords[i]
      }
    }

    return null
  }

  const getPrevSlideRowFromTime = (dateStr, timeStr) => {
    //Time passed in will usually be the start time of the activity
    if (typeof timeStr !== 'string') return null
    let decTime = dateTimeStrToDecimal(dateStr, timeStr)
    if (decTime < 0) return null
    if (!parentRowData) return null
    if (!parentRowData.hasOwnProperty('slideRecords')) return null
    if (!Array.isArray(parentRowData.slideRecords)) return null
    const { slideRecords } = parentRowData

    if (slideRecords.length === 0) return null
    if (
      decTime >=
      dateTimeStrToDecimal(slideRecords[slideRecords.length - 1].date, slideRecords[slideRecords.length - 1].endTime)
    )
      return slideRecords[slideRecords.length - 1]

    for (let i = slideRecords.length - 1; i >= 0; i--) {
      if (decTime >= dateTimeStrToDecimal(slideRecords[i].date, slideRecords[i].endTime)) {
        return slideRecords[i]
      }

      if (
        decTime >= dateTimeStrToDecimal(slideRecords[i].date, slideRecords[i].startTime) &&
        decTime <= dateTimeStrToDecimal(slideRecords[i].date, slideRecords[i].endTime)
      ) {
        return slideRecords[i]
      }
    }

    return null
  }

  const getSlideRowFromUid = (uid) => {
    if (typeof uid !== 'number') return null
    if (uid < 0) return null
    if (!parentRowData) return null
    if (!parentRowData.hasOwnProperty('slideRecords')) return null
    if (!Array.isArray(parentRowData.slideRecords)) return null

    for (let i = 0; i < parentRowData.slideRecords.length; i++) {
      if (uid === parentRowData.slideRecords[i].uid) {
        return parentRowData.slideRecords[i]
      }
    }

    return null
  }

  const getPrevSlideRow = (uid) => {
    if (typeof uid !== 'number') return null
    if (uid < 0) return null
    if (!parentRowData) return null
    if (!parentRowData.hasOwnProperty('slideRecords')) return null
    if (!Array.isArray(parentRowData.slideRecords)) return null

    let curSlideRow = getSlideRowFromUid(uid)
    if (!curSlideRow) return null

    let index = parentRowData.slideRecords.findIndex((slideRow) => slideRow.uid === curSlideRow.uid)
    if (index <= 0) return null
    return parentRowData.slideRecords[index - 1]
  }

  const getAllPrevSlides = (uid) => {
    if (typeof uid !== 'number') return []
    if (uid < 0) return []
    if (!parentRowData) return []
    if (!parentRowData.hasOwnProperty('slideRecords')) return []
    if (!Array.isArray(parentRowData.slideRecords)) return []

    let curSlideRow = getSlideRowFromUid(uid)
    if (!curSlideRow) return null

    let index = parentRowData.slideRecords.findIndex((slideRow) => slideRow.uid === curSlideRow.uid)
    if (index <= 0) return []

    let slideRows = []
    for (let i = index; i >= 0; i--) {
      slideRows.push(cloneDeep(parentRowData.slideRecords[i]))
    }

    return slideRows
  }

  const getNextSlideRow = (uid) => {
    if (typeof uid !== 'number') return null
    if (uid < 0) return null
    if (!parentRowData) return null
    if (!parentRowData.hasOwnProperty('slideRecords')) return null
    if (!Array.isArray(parentRowData.slideRecords)) return null

    let curSlideRow = getSlideRowFromUid(uid)
    if (!curSlideRow) return null

    let index = parentRowData.slideRecords.findIndex((sliderow) => sliderow.uid === curSlideRow.uid)
    if (index === parentRowData.slideRecords.length - 1) return null
    return parentRowData.slideRecords[index + 1]
  }

  const getAllNextSlideRows = (uid) => {
    if (typeof uid !== 'number') return []
    if (uid < 0) return []
    if (!parentRowData) return []
    if (!parentRowData.hasOwnProperty('slideRecords')) return []
    if (!Array.isArray(parentRowData.slideRecords)) return []

    let curSlideRow = getSlideRowFromUid(uid)
    if (!curSlideRow) return null

    let index = parentRowData.slideRecords.findIndex((slideRow) => slideRow.uid === curSlideRow.uid)
    if (index === parentRowData.slideRecords.length - 1) return []

    let slideRows = []
    for (let i = index; i < parentRowData.slideRecords.length; i++) {
      slideRows.push(cloneDeep(parentRowData.slideRecords[i]))
    }

    return slideRows
  }

  const columnDefs = [
    {
      headerName: '',
      field: 'actions',
      colId: 'actions',
      editable: false,
      width: 75,
      cellRendererSelector: () => {
        return { component: actionIconRenderer }
      },
      cellStyle: {
        display: 'flex',
        justifyContent: 'center',
        paddingLeft: '4px !important',
        paddingRight: '4px !important',
        borderRight: '1px solid gray !important',
      },
      cellClass: 'add-pin-overflow',
      pinned: 'left',
      lockPosition: 'left',
      suppressHeaderMenuButton: true,
      suppressHeaderFilterButton: true,
    },
    {
      headerName: 'Date',
      colId: 'date',
      field: 'date',
      pinned: 'left',
      lockPosition: 'left',
      valueGetter: (params) => {
        if (!params.value) return ''
      },
      valueFormatter: (params) => {
        if (params.node?.rowPinned === 'bottom' && !inputRow.current.hasOwnProperty('date')) return 'Date...'
        if (!params.data?.date) return ''
        if (typeof params.data?.date !== 'string') return ''
        if (params.data?.date === '') return ''
        if (isDateLessThan(params.data?.date, '1990-01-01')) return ''
        let dateTimeStr = params.data?.date.slice(0, 10) + 'T00:00:00'
        return new Date(Date.parse(dateTimeStr)).toLocaleDateString('default', {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
        })
      },
      cellEditor: 'agDateStringCellEditor',
      cellEditorParams: {
        min: '1990-01-01',
        max: '2200-12-31',
      },
    },
    {
      headerName: 'Start Time',
      colId: 'startTime',
      field: 'startTime',
      pinned: 'left',
      lockPosition: 'left',
      valueSetter: (params) => {
        if (params.newValue === params.oldValue) return

        if (!isTimeStrValid(params.newValue)) {
          showErrorMessage('Invalid time format should be hh:mm')
          return false
        }

        if (params.newValue === '24:00') {
          showErrorMessage('Start time cannot be 24:00')
          return false
        }

        //Handle input row
        if (params.node?.rowPinned === 'bottom') {
          if (
            dateTimeStrToDecimal(params?.data?.date, params.newValue) >=
              dateTimeStrToDecimal(params?.data?.date, params.data?.endTime) &&
            isTimeStrValid(params.data?.endTime)
          ) {
            showErrorMessage('Start Time must be less than end time')
            return false
          }

          inputRow.current[params.colDef.field] = params.newValue
          return true
        }

        //Handle non input row
        if (
          dateTimeStrToDecimal(params?.data?.date, params.newValue) >=
          dateTimeStrToDecimal(params?.data?.date, params.data?.endTime)
        ) {
          showErrorMessage('Start Time must be less than end time')
          return false
        }

        let newData = { ...params.data }
        newData.startTime = params.newValue
        newData.hours = getHours(newData.startTime, newData.endTime)

        //If cur row is first row and start time is valid then just update
        let prevSlideRow = getPrevSlideRow(params.data?.uid)
        if (!prevSlideRow) {
          updateParentGrid({
            action: 'update',
            data: [newData],
          })

          return true
        }

        //If cur row start time is less than or equal to prev slide start time then error
        if (
          dateTimeStrToDecimal(params?.data?.date, params.newValue) <=
          dateTimeStrToDecimal(prevSlideRow.date, prevSlideRow.startTime)
        ) {
          showErrorMessage('Must be greater than prev slide start time')
          return false
        }

        let rowsToUpdate = [newData]
        //Check to see if new start time overlaps previous row end time if so adjust previous row end time
        if (
          dateTimeStrToDecimal(params?.data?.date, params.newValue) <
          dateTimeStrToDecimal(prevSlideRow.date, prevSlideRow.endTime)
        ) {
          let newPrevSlideRow = cloneDeep(prevSlideRow)
          newPrevSlideRow.endTime = params.newValue
          newPrevSlideRow.hours = getHours(newPrevSlideRow.startTime, newPrevSlideRow.endTime)
          rowsToUpdate.push(newPrevSlideRow)
        }

        updateParentGrid({
          action: 'update',
          data: rowsToUpdate,
        })

        return true
      },
    },
    {
      headerName: 'End Time',
      colId: 'endTime',
      field: 'endTime',
      pinned: 'left',
      lockPosition: 'left',
      valueSetter: (params) => {
        if (params.newValue === params.oldValue) return

        if (!isTimeStrValid(params.newValue)) {
          showErrorMessage('Invalid time format should be hh:mm')
          return false
        }

        if (params.newValue === '00:00') {
          showErrorMessage('End time cannot be 00:00')
          return false
        }

        //Handle input row
        if (params.node?.rowPinned === 'bottom') {
          if (
            dateTimeStrToDecimal(params?.data?.date, params.newValue) <=
              dateTimeStrToDecimal(params?.data?.date, params.data?.startTime) &&
            isTimeStrValid(params.data?.startTime)
          ) {
            showErrorMessage('End Time must be greater than start time')
            return false
          }

          inputRow.current[params.colDef.field] = params.newValue
          return true
        }

        //Handle non input row
        if (
          dateTimeStrToDecimal(params?.data?.date, params.newValue) <=
          dateTimeStrToDecimal(params?.data?.date, params.data?.startTime)
        ) {
          showErrorMessage('End time must be greater than start time')
          return false
        }

        let newData = { ...params.data }
        newData.endTime = params.newValue
        newData.hours = getHours(newData.startTime, newData.endTime)

        //If cur row is last row and end time is valid then just update
        let nextSlideRow = getNextSlideRow(params.data?.uid)
        if (!nextSlideRow) {
          updateParentGrid({
            action: 'update',
            data: [newData],
          })

          return true
        }

        //If cur row end time is greater than or equal to next slide end time then error
        if (
          dateTimeStrToDecimal(params?.data?.date, params.newValue) >=
          dateTimeStrToDecimal(nextSlideRow.date, nextSlideRow.endTime)
        ) {
          showErrorMessage('Must be less than next slide end time')
          return false
        }

        let rowsToUpdate = [newData]
        //Check to see if new end time overlaps next row start time if so adjust next row start time
        if (
          dateTimeStrToDecimal(params?.data?.date, params.newValue) >
          dateTimeStrToDecimal(nextSlideRow.date, nextSlideRow.startTime)
        ) {
          let newNextSlideRow = cloneDeep(nextSlideRow)
          newNextSlideRow.startTime = params.newValue
          newNextSlideRow.hours = getHours(newNextSlideRow.startTime, newNextSlideRow.endTime)
          rowsToUpdate.push(newNextSlideRow)
        }

        updateParentGrid({
          action: 'update',
          data: rowsToUpdate,
        })

        return true
      },
    },
    {
      headerName: 'Hours',
      colId: 'hours',
      editable: false,
      valueFormatter: (params) => {
        if (params?.data?.startTime && params?.data?.endTime)
          return numberWithCommasDecimals(getHours(params?.data?.startTime, params?.data?.endTime), 2)
      },
    },
    {
      headerName: 'Depth From',
      colId: 'startDepth',
      field: 'startDepth',
      editable: (params) => {
        return params.node?.rowPinned !== 'bottom' && params.node?.firstChild
      },
      cellStyle: (params) => {
        let style = getCellStyle(params, checks, 'center')
        let prevSlideRow = getPrevSlideRow(params?.data?.uid)
        if (!prevSlideRow) return style
        if (parseFloat(prevSlideRow?.endDepth) === parseFloat(params?.data?.startDepth)) return style
        style.backgroundColor = 'red'

        return style
      },
      valueSetter: (params) => {
        if (parseFloat(params.newValue) === parseFloat(params.oldValue)) return

        if (!isStrValidFloat(params.newValue)) {
          showErrorMessage('Invalid number')
          return false
        }

        let newDepth = parseFloat(params.newValue)

        if (newDepth > parseFloat(params.data?.endDepth) && isStrValidFloat(params.data?.endDepth)) {
          showErrorMessage('Start depth cannot be greater than end depth')
          return false
        }

        //Handle input row
        if (params.node?.rowPinned === 'bottom') {
          return true
        }

        let newData = { ...params.data }
        newData.startDepth = newDepth
        newData.cl = newData.endDepth - newData.startDepth

        if (newData.cl === 0) {
          showErrorMessage('Start depth cannot equal end depth')
          return false
        }

        //If cur row is first row and start depth is valid then just update
        let prevDetail = getPrevSlideRow(params.data?.uid)
        if (!prevDetail) {
          updateParentGrid({
            action: 'update',
            data: [newData],
          })

          return true
        }

        //If cur row start depth is less than prev slide start depth then error
        if (newDepth < prevDetail.startDepth) {
          showErrorMessage('Must be greater than or equal to prev slide start depth')
          return false
        }

        let rowsToUpdate = [newData]
        //Check to see if new start depth overlaps previous row end depth if so adjust previous row end depth
        if (newDepth < prevDetail.endDepth) {
          let newPrevDetail = cloneDeep(prevDetail)

          let oldEndDepth = newPrevDetail.endDepth
          if (parseFloat(newPrevDetail.endDepth) === parseFloat(newPrevDetail.startDepth)) {
            newPrevDetail.startDepth = newDepth
          }

          newPrevDetail.endDepth = newDepth
          newPrevDetail.cl = newPrevDetail.endDepth - newPrevDetail.startDepth
          rowsToUpdate.push(newPrevDetail)

          //Need to iterate through all rows to see if the depths need updating
          let allPrevSlides = getAllPrevSlides(oldEndDepth.uid)
          for (let i = 0; i < allPrevSlides.length; i++) {
            let addToUpdate = false
            if (parseFloat(allPrevSlides[i].startDepth) === parseFloat(oldEndDepth)) {
              allPrevSlides[i].startDepth = newDepth
              addToUpdate = true
            }

            if (parseFloat(allPrevSlides[i].endDepth) === parseFloat(oldEndDepth)) {
              allPrevSlides[i].endDepth = newDepth
              addToUpdate = true
            }

            if (addToUpdate) {
              allPrevSlides[i].cl = allPrevSlides[i].endDepth - allPrevSlides[i].startDepth
              rowsToUpdate.push(allPrevSlides[i])
            }
          }
        }

        updateParentGrid({
          action: 'update',
          data: rowsToUpdate,
        })

        return true
      },
      valueFormatter: (params) => numberWithCommasDecimals(params.data?.startDepth, 2),
    },
    {
      headerName: 'Depth To',
      colId: 'endDepth',
      field: 'endDepth',
      cellStyle: (params) => {
        let style = getCellStyle(params, checks, 'center')
        let nextSlideRow = getNextSlideRow(params?.data?.uid)
        if (!nextSlideRow) return style
        if (parseFloat(nextSlideRow?.startDepth) === parseFloat(params?.data?.endDepth)) return style
        style.backgroundColor = 'red'

        return style
      },
      editable: (params) => {
        return params.node?.rowPinned !== 'bottom'
      },
      valueSetter: (params) => {
        if (!isStrValidFloat(params.newValue)) {
          showErrorMessage('Invalid number')
          return false
        }

        let newDepth = parseFloat(params.newValue)

        if (newDepth < parseFloat(params.data?.startDepth) && isStrValidFloat(params.data?.startDepth)) {
          showErrorMessage('End depth cannot be less than start depth')
          return false
        }

        //Handle input row
        if (params.node?.rowPinned === 'bottom') {
          return true
        }

        let newData = { ...params.data }
        newData.endDepth = newDepth
        newData.cl = newData.endDepth - newData.startDepth

        if (newData.cl === 0) {
          showErrorMessage('Start depth cannot equal end depth')
          return false
        }

        //If cur row is last row and end depth is valid then just update
        let nextSlideRow = getNextSlideRow(params.data?.uid)
        if (!nextSlideRow) {
          updateParentGrid({
            action: 'update',
            data: [newData],
          })

          return true
        }

        //If cur row end depth is greater than next slide end depth then error
        if (newDepth > nextSlideRow.endDepth) {
          showErrorMessage('Must be less than or equal to next slide end depth')
          return false
        }

        let rowsToUpdate = [newData]
        //Check to see if new end depth overlaps next row start depth if so adjust next row start depth
        if (newDepth > nextSlideRow.startDepth) {
          let newNextSlideRow = cloneDeep(nextSlideRow)
          let oldStartDepth = newNextSlideRow.startDepth
          if (parseFloat(newNextSlideRow.endDepth) === parseFloat(newNextSlideRow.startDepth)) {
            newNextSlideRow.endDepth = newDepth
          }

          newNextSlideRow.startDepth = newDepth
          newNextSlideRow.cl = newNextSlideRow.endDepth - newNextSlideRow.startDepth
          rowsToUpdate.push(newNextSlideRow)

          //Need to iterate through all rows to see if the depths need updating
          let allNextSlideRows = getAllNextSlideRows(newNextSlideRow.uid)
          for (let i = 0; i < allNextSlideRows.length; i++) {
            let addToUpdate = false
            if (parseFloat(allNextSlideRows[i].startDepth) === parseFloat(oldStartDepth)) {
              allNextSlideRows[i].startDepth = newDepth
              addToUpdate = true
            }

            if (parseFloat(allNextSlideRows[i].endDepth) === parseFloat(oldStartDepth)) {
              allNextSlideRows[i].endDepth = newDepth
              addToUpdate = true
            }

            if (addToUpdate) {
              allNextSlideRows[i].cl = allNextSlideRows[i].endDepth - allNextSlideRows[i].startDepth
              rowsToUpdate.push(allNextSlideRows[i])
            }
          }
        }

        updateParentGrid({
          action: 'update',
          data: rowsToUpdate,
        })

        return true
      },
      valueFormatter: (params) => numberWithCommasDecimals(params.data?.endDepth, 2),
    },
    {
      headerName: 'CL',
      colId: 'cl',
      field: 'cl',
      valueSetter: (params) => {
        if (!isStrValidFloat(params.newValue)) {
          showErrorMessage('Invalid number')
          return false
        }

        let newEndDepth = parseFloat(params.data?.startDepth + params.newValue)

        if (newEndDepth < parseFloat(params.data?.startDepth) && isStrValidFloat(params.data?.startDepth)) {
          showErrorMessage('End depth cannot be less than start depth')
          return false
        }

        //Handle input row
        if (params.node?.rowPinned === 'bottom') {
          return true
        }

        let newData = { ...params.data }
        newData.endDepth = newEndDepth
        newData.cl = newData.endDepth - newData.startDepth

        if (newData.cl === 0) {
          showErrorMessage('Start depth cannot equal end depth')
          return false
        }

        //If cur row is last row and end depth is valid then just update
        let nextSlideRow = getNextSlideRow(params.data?.uid)
        if (!nextSlideRow) {
          updateParentGrid({
            action: 'update',
            data: [newData],
          })

          return true
        }

        //If cur row end depth is greater than next slide end depth then error
        if (newEndDepth > nextSlideRow.endDepth) {
          showErrorMessage('End depth must be <= next slide end depth')
          return false
        }

        let rowsToUpdate = [newData]
        //Check to see if new end depth overlaps next row start depth if so adjust next row start depth
        if (newEndDepth > nextSlideRow.startDepth) {
          let newNextSlideRow = cloneDeep(nextSlideRow)
          let oldStartDepth = newNextSlideRow.startDepth
          if (parseFloat(newNextSlideRow.endDepth) === parseFloat(newNextSlideRow.startDepth)) {
            newNextSlideRow.endDepth = newEndDepth
          }

          newNextSlideRow.startDepth = newEndDepth
          newNextSlideRow.cl = newNextSlideRow.endDepth - newNextSlideRow.startDepth
          rowsToUpdate.push(newNextSlideRow)

          //Need to iterate through all rows to see if the depths need updating
          let allNextSlideRows = getAllNextSlideRows(newNextSlideRow.uid)
          for (let i = 0; i < allNextSlideRows.length; i++) {
            let addToUpdate = false
            if (parseFloat(allNextSlideRows[i].startDepth) === parseFloat(oldStartDepth)) {
              allNextSlideRows[i].startDepth = newEndDepth
              addToUpdate = true
            }

            if (parseFloat(allNextSlideRows[i].endDepth) === parseFloat(oldStartDepth)) {
              allNextSlideRows[i].endDepth = newEndDepth
              addToUpdate = true
            }

            if (addToUpdate) {
              allNextSlideRows[i].cl = allNextSlideRows[i].endDepth - allNextSlideRows[i].startDepth
              rowsToUpdate.push(allNextSlideRows[i])
            }
          }
        }

        updateParentGrid({
          action: 'update',
          data: rowsToUpdate,
        })

        return true
      },
      valueFormatter: (params) => numberWithCommasDecimals(params?.data?.cl, 2),
    },
    {
      headerName: 'State',
      colId: 'state',
      field: 'state',
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
      cellEditor: 'agSelectCellEditor',
      editable: (params) => {
        return params.node?.rowPinned !== 'bottom'
      },
      cellEditorParams: () => {
        return { values: ['Slide', 'Rotate'] }
      },
      valueSetter: (params) => {
        let data = { ...params.data }
        data.state = params.newValue
        if (data.state === 'Slide') data.rpm = 0
        if (params.node?.rowPinned !== 'bottom') {
          updateParentGrid({
            action: 'update',
            data: [data],
          })

          return true
        }
        inputRow.current.state = params.newValue
        return true
      },
      cellStyle: (params) => {
        let style = getCellStyle(params, checks, 'center')
        style.color = params?.value === 'Rotate' ? appColors.rotateColor : appColors.slideColor
        return style
      },
    },
    {
      headerName: 'TF Type',
      field: 'tfType',
      colId: 'tfType',
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
      cellEditor: 'agSelectCellEditor',
      cellStyle: (params) => {
        let style = getCellStyle(params, checks, 'left')
        if (params.data?.state === 'Rotate') style.border = ''
        return style
      },
      cellEditorParams: () => {
        return { values: ['MAG', 'GRAV'] }
      },
      editable: (params) => {
        if (params?.data?.state === 'Slide') return true
      },
      valueSetter: (params) => {
        let data = { ...params.data }
        data.tfType = params.newValue

        if (params.node?.rowPinned !== 'bottom') {
          updateParentGrid({
            action: 'update',
            data: [data],
          })

          return
        }
        return true
      },
    },
    {
      headerName: 'Effective TF',
      field: 'etf',
      colId: 'etf',
      editable: (params) => {
        if (params?.data?.state === 'Slide') return true
      },
      cellEditor: 'agNumberCellEditor',
      cellStyle: (params) => {
        let style = getCellStyle(params, checks, 'left')
        if (params.data?.state === 'Rotate') style.border = ''
        return style
      },
      cellEditorParams: (params) => {
        return {
          min: params?.data?.tfType === 'MAG' ? 0 : -180,
          max: params?.data?.tfType === 'MAG' ? 360 : 180,
          precision: 0,
        }
      },
      valueFormatter: (params) => {
        if (params?.data?.state === 'Rotate' || params?.node?.rowPinned === 'bottom') return ''
        return parseInt(params?.data?.etf)
      },
    },
    {
      headerName: 'Target TF',
      field: 'ttf',
      colId: 'ttf',
      cellStyle: (params) => {
        let style = getCellStyle(params, checks, 'left')
        if (params.data?.state === 'Rotate') style.border = ''
        return style
      },
      editable: (params) => {
        if (params?.data?.state === 'Slide') return true
      },
      cellEditor: 'agNumberCellEditor',
      cellEditorParams: {
        min: -180,
        max: 360,
        precision: 0,
      },
      cellRenderer: (params) => {
        if (params.node?.rowPinned === 'bottom' || params.data?.state === 'Rotate') return
        const { data } = params
        return <ToolFaceIndicator etf={data.ttf} tfType={data.tfType} slideGrade={data.slideGrade} radius={'20px'} />
      },
      valueFormatter: (params) => {
        if (params?.data?.state === 'Rotate' || params?.node?.rowPinned === 'bottom') return ''
        return parseInt(params?.data?.ttf)
      },
    },
    {
      headerName: 'Slide Grade',
      field: 'slideGrade',
      colId: 'slideGrade',
      editable: (params) => {
        if (params?.data?.state === 'Slide') return true
      },
      cellStyle: (params) => {
        let style = getCellStyle(params, checks, 'left')
        if (params.data?.state === 'Rotate') style.border = ''
        return style
      },
      cellEditor: 'agNumberCellEditor',
      cellEditorParams: {
        min: 0,
        max: 100,
        precision: 2,
      },
      valueFormatter: (params) => {
        if (params?.data?.slideGrade === 0) return ''
        return numberWithCommasDecimals(params?.data?.slideGrade, 2)
      },
    },
    {
      headerName: 'Temp',
      colId: 'temperature',
      field: 'temperature',
      editable: (params) => {
        return params.node?.rowPinned !== 'bottom'
      },
      cellEditor: 'agNumberCellEditor',
      cellEditorParams: {
        min: -50,
        max: 500,
        precision: 2,
      },
      valueFormatter: (params) => {
        if (params?.data?.temperature === 0) return ''
        return numberWithCommasDecimals(params?.data?.temperature, 2)
      },
    },
    {
      headerName: 'WOB',
      colId: 'wob',
      field: 'wob',
      editable: (params) => {
        return params.node?.rowPinned !== 'bottom'
      },
      cellEditor: 'agNumberCellEditor',
      cellEditorParams: {
        min: 0,
        max: 99999,
        precision: 2,
      },
      valueFormatter: (params) => {
        if (params?.data?.wob === 0) return ''
        return numberWithCommasDecimals(params?.data?.wob, 2)
      },
    },
    {
      headerName: 'Flow',
      colId: 'flow',
      field: 'flow',
      editable: (params) => {
        return params.node?.rowPinned !== 'bottom'
      },
      cellEditor: 'agNumberCellEditor',
      cellEditorParams: {
        min: 0,
        max: 99999,
        precision: 2,
      },
      valueFormatter: (params) => {
        if (params?.data?.flow === 0) return ''
        return numberWithCommasDecimals(params?.data?.flow, 2)
      },
    },
    {
      headerName: 'Off Btm Press',
      colId: ' offBtmPress',
      field: 'offBtmPress',
      editable: (params) => {
        return params.node?.rowPinned !== 'bottom'
      },
      cellEditor: 'agNumberCellEditor',
      cellEditorParams: {
        min: 0,
        max: 99999,
        precision: 2,
      },
      valueFormatter: (params) => {
        if (params?.data?.offBtmPress === 0) return ''
        return numberWithCommasDecimals(params?.data?.offBtmPress, 2)
      },
    },
    {
      headerName: 'On Btm Press',
      colId: 'onBtmPress',
      field: 'onBtmPress',
      editable: (params) => {
        return params.node?.rowPinned !== 'bottom'
      },
      cellEditor: 'agNumberCellEditor',
      cellEditorParams: {
        min: 0,
        max: 99999,
        precision: 2,
      },
      valueFormatter: (params) => {
        if (params?.data?.onBtmPress === 0) return ''
        return numberWithCommasDecimals(params?.data?.onBtmPress, 2)
      },
    },
    {
      headerName: 'Diff P',
      colId: 'diffPressure',
      editable: false,
      valueFormatter: (params) => {
        const { offBtmPress = 0, onBtmPress = 0 } = params.data
        let pressDiff = onBtmPress - offBtmPress
        return pressDiff > 0 ? numberWithCommasDecimals(pressDiff, 2) : ''
      },
    },
    {
      headerName: 'Off Btm Tq',
      colId: 'offBtmTq',
      field: 'offBtmTq',
      editable: (params) => {
        return params.node?.rowPinned !== 'bottom'
      },
      cellStyle: (params) => {
        let style = getCellStyle(params, checks, 'left')
        if (params.data?.state === 'Slide') style.border = ''
        return style
      },
      cellEditor: 'agNumberCellEditor',
      cellEditorParams: {
        min: 0,
        max: 99999,
        precision: 2,
      },
      valueFormatter: (params) => {
        if (params?.data?.offBtmTq === 0) return ''
        return numberWithCommasDecimals(params?.data?.offBtmTq, 2)
      },
    },
    {
      headerName: 'On Btm Tq',
      colId: 'torque',
      field: 'torque',
      editable: (params) => {
        return params.node?.rowPinned !== 'bottom'
      },
      cellStyle: (params) => {
        let style = getCellStyle(params, checks, 'left')
        if (params.data?.state === 'Slide') style.border = ''
        return style
      },
      cellEditor: 'agNumberCellEditor',
      cellEditorParams: {
        min: 0,
        max: 99999,
        precision: 2,
      },
      valueFormatter: (params) => {
        if (params?.data?.torque === 0) return ''
        return numberWithCommasDecimals(params?.data?.torque, 2)
      },
    },
    {
      headerName: 'RPM',
      colId: 'rpm',
      field: 'rpm',
      editable: (params) => {
        return params.node?.rowPinned !== 'bottom'
      },
      cellEditor: 'agNumberCellEditor',
      cellStyle: (params) => {
        let style = getCellStyle(params, checks, 'left')
        if (params.data?.state === 'Slide') style.border = ''
        return style
      },
      cellEditorParams: {
        min: 0,
        max: 1000,
        precision: 2,
      },
      valueFormatter: (params) => {
        if (params?.data?.rpm === 0 || params?.node?.rowPinned === 'bottom') return ''
        return parseInt(params?.data?.rpm)
      },
    },
    {
      headerName: 'ROP',
      colId: 'rop',
      editable: false,
      valueGetter: (params) => {
        if (!params?.data?.startTime || !params?.data?.endTime) return ''
        let rop = calcRop(parseFloat(params?.data?.cl), params?.data?.startTime, params?.data?.endTime)
        if (rop === 0) return ''
        return numberWithCommasDecimals(rop, 2)
      },
    },
    {
      headerName: 'PU',
      colId: 'puWeight',
      field: 'puWeight',
      editable: (params) => {
        return params.node?.rowPinned !== 'bottom'
      },
      cellEditor: 'agNumberCellEditor',
      cellEditorParams: {
        min: 0,
        max: 200000,
        precision: 2,
      },
      valueFormatter: (params) => {
        if (params?.data?.puWeight === 0) return ''
        return numberWithCommasDecimals(params?.data?.puWeight, 2)
      },
    },
    {
      headerName: 'ROT',
      colId: 'rotWeight',
      field: 'rotWeight',
      editable: (params) => {
        return params.node?.rowPinned !== 'bottom'
      },
      cellEditor: 'agNumberCellEditor',
      cellEditorParams: {
        min: 0,
        max: 200000,
        precision: 2,
      },
      valueFormatter: (params) => {
        if (params?.data?.rotWeight === 0) return ''
        return numberWithCommasDecimals(params?.data?.rotWeight, 2)
      },
    },
    {
      headerName: 'SO',
      colId: 'soWeight',
      field: 'soWeight',
      editable: (params) => {
        return params.node?.rowPinned !== 'bottom'
      },
      cellEditor: 'agNumberCellEditor',
      cellEditorParams: {
        min: 0,
        max: 200000,
        precision: 2,
      },
      valueFormatter: (params) => {
        if (params?.data?.soWeight === 0) return ''
        return numberWithCommasDecimals(params?.data?.soWeight, 2)
      },
    },
    {
      headerName: 'Motor Yield',
      colId: 'motorYield',
      field: 'motorYield',
      editable: (params) => {
        return params.node?.rowPinned !== 'bottom' && params.data?.state === 'Slide'
      },
      cellEditor: 'agNumberCellEditor',
      cellEditorParams: {
        min: 0,
        max: 99999,
        precision: 2,
      },
      valueFormatter: (params) => {
        if (params?.data?.motorYield === 0) return ''
        return numberWithCommasDecimals(params?.data?.motorYield, 2)
      },
    },
    {
      headerName: 'Formation',
      colId: ' formation',
      field: 'formation',
      resizable: true,
      cellStyle: (params) => getCellStyle(params, checks, 'left'),
      maxWidth: 600,
      cellEditor: 'agLargeTextCellEditor',
      cellEditorPopup: true,
      cellEditorParams: {
        maxLength: 1000,
        rows: 10,
        cols: 50,
      },
      editable: (params) => {
        return params.node?.rowPinned !== 'bottom'
      },
      valueFormatter: (params) => {
        if (params.node?.rowPinned === 'bottom') return ''
        return unescapeHtml(params.data?.formation)
      },
      valueGetter: (params) => unescapeHtml(params.data?.formation),
    },
    {
      headerName: 'Comments',
      colId: ' comments',
      field: 'comments',
      resizable: true,
      cellStyle: (params) => getCellStyle(params, checks, 'left'),
      maxWidth: 600,
      cellEditor: 'agLargeTextCellEditor',
      cellEditorPopup: true,
      cellEditorParams: {
        maxLength: 1000,
        rows: 10,
        cols: 50,
      },
      editable: (params) => {
        return params.node?.rowPinned !== 'bottom'
      },
      valueFormatter: (params) => {
        if (params.node?.rowPinned === 'bottom') return ''
        return unescapeHtml(params.data?.comments)
      },
      valueGetter: (params) => unescapeHtml(params.data?.comments),
    },
  ]

  const getContextMenuItems = (params) => {
    return [
      {
        name: 'Reset columns',
        disabled: false,
        action: () => {
          gridApi.current.resetColumnState()
          saveItemToLS('slideSheetDetailsGrid', 'colLayout', null)
          if (_isMounted.current) setResetCols(!resetCols)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Reset filters',
        disabled: false,
        action: () => {
          gridApi.current.setFilterModel(null)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      'copy',
      {
        name: 'Export',
        disabled: false,
        action: () => {
          onXlsExport([params?.node?.data?.bhaNum], parentRowData?.bhaNumRep)
        },
        icon: '<span class="iconify" data-icon="icomoon-free:file-excel" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
    ]
  }

  const scrollToBottom = () => {
    if (!gridApi.current) return
    const renderedRowCount = gridApi.current.getDisplayedRowCount() - 1
    if (renderedRowCount <= 0) return
    gridApi.current.ensureIndexVisible(renderedRowCount)
  }

  const onGridReady = (params) => {
    gridApi.current = params.api

    if (masterGridApi) {
      let detailId = `SLIDE_DETAIL_GRID-${parentRowData?.bhaNum}`
      const detailGridInfo = {
        id: detailId,
        api: params.api,
      }

      masterGridApi.addDetailGridInfo(detailId, detailGridInfo)
    }

    scrollToBottom()
  }

  const onFirstDataRendered = (params) => {
    if (gridApi.current) gridApi.current.onFilterChanged()
    autoSizeColumns()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const saveColumnState = () => {
    if (gridApi.current) {
      const colLayout = gridApi.current.getColumnState()
      if (colLayout) saveItemToLS('slideSheetDetailsGrid', 'colLayout', colLayout)
    }
  }

  const gridOptions = {
    pinnedBottomRowData: [inputRow.current],
    sideBar: {
      toolPanels: [
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        },
      ],
      defaultToolPanel: '',
      position: 'left',
    },
    onDragStopped: (event) => {
      saveColumnState()
    },
    onColumnVisible: (event) => {
      saveColumnState()
    },
    onCellEditingStopped: (params) => {
      if (params.node?.rowPinned === 'bottom') {
        if (gridApi.current) gridApi.current.setGridOption('pinnedBottomRowData', [inputRow.current])
        handleAddRow(params)
        return
      }

      if (params.node?.rowPinned !== 'bottom') {
        params.api.redrawRows({ rowNodes: [params.node] })
        if (colsWithSetters.findIndex((col) => col === params?.column?.colId) >= 0) return

        updateParentGrid({
          action: 'update',
          data: [params.data],
        })
      }
    },
    getRowStyle: ({ node }) => {
      if (node?.rowPinned) return { fontWeight: 'bold', fontStyle: 'italic' }
    },
  }

  const processDataFromClipboard = (params) => {
    if (!params.hasOwnProperty('data') && !Array.isArray(params.data) && params.data?.length < 1) {
      return null
    }
    let data = params.data?.map((dataRow) => {
      return dataRow.slice()
    })

    // delete the empty last row in the clipboard data, if one exists
    const emptyLastRow = data[data.length - 1][0] === '' && data[data.length - 1].length === 1
    if (emptyLastRow) {
      data.splice(data.length - 1, 1)
    }
    // make sure there's data to process after we remove the typical empty row
    if (data.length < 1) return null

    const focusedCell = params.api.getFocusedCell()
    const focusedIndex = focusedCell.rowIndex

    pastedRows.current = []
    gridApi.current?.forEachNodeAfterFilterAndSort((rowNode, index) => {
      if (index >= focusedIndex && index <= focusedIndex + data.length - 1) {
        pastedRows.current.push(rowNode.data)
      }
    })

    return data
  }

  function onPasteEnd() {
    if (!Array.isArray(pastedRows.current)) return
    if (pastedRows.current.length === 0) return
    updateParentGrid(cloneDeep(pastedRows.current))
    pastedRows.current = []
  }

  return (
    <div className={getAgGridTheme()} style={{ width: '100%', height: '100%' }}>
      {confirm.show ? (
        <ConfirmDialog
          title={confirm?.title}
          open={confirm?.show}
          setOpen={() => setConfirm({ show: false })}
          onConfirm={() => confirmDelete()}>
          {confirm?.text}
        </ConfirmDialog>
      ) : null}
      <AgGridReact
        rowData={Array.isArray(parentRowData.slideRecords) ? parentRowData.slideRecords : []}
        columnDefs={sortColDefs(columnDefs, 'slideSheetDetailsGrid')}
        defaultColDef={defaultColDef}
        headerHeight={30}
        getRowId={getRowId}
        animateRows={false}
        rowSelection='multiple'
        enableRangeSelection='true'
        enableBrowserTooltips={true}
        groupDisplayType={'groupRows'}
        groupRowsSticky={true}
        getContextMenuItems={getContextMenuItems}
        onFirstDataRendered={onFirstDataRendered}
        onGridReady={onGridReady}
        gridOptions={gridOptions}
        processDataFromClipboard={processDataFromClipboard}
        onPasteEnd={onPasteEnd}
      />
    </div>
  )
}

export default SlideSheetDetailsGrid
