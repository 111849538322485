import React, { useRef, useMemo, useEffect, useState } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { AgGridReact } from 'ag-grid-react'
import { cloneDeep } from 'lodash'
import { currentWellSelector } from 'atoms'
import { useRecoilValue } from 'recoil'
import { Box, IconButton, Tooltip, Snackbar, Alert, Backdrop, CircularProgress } from '@mui/material'
import { Icon as Iconify } from '@iconify/react'
import useInnovaAxios from '../../../common/hooks/useInnovaAxios'
import ConfirmDialog from 'components/common/ConfirmDialog'
import { numberWithCommas } from 'utils/stringFunctions'
import usePumpData from 'components/common/hooks/usePumpData'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'
import { htmlSymbolHandling, getStringId } from 'components/common/AgGridUtils'
import { unescapeHtml } from 'utils/htmlSymbolHandling'

const PumpDataGridModal = (props) => {
  const { setOpen, dailyReportId } = props
  const _isMounted = useRef(false)
  const gridApi = useRef(null)
  const { getAgGridTheme } = useInnovaTheme()
  const inputRow = useRef({})
  const currentWell = useRecoilValue(currentWellSelector)
  const currentWellRef = useRef(currentWell)
  const [status, setStatus] = useState({ show: false, severity: 'info', message: '' })
  const [confirm, setConfirm] = useState({ show: false, title: '' })
  const [showWait, setShowWait] = useState(false)
  const { fetchPumpData } = usePumpData()
  const [pumpData, setPumpData] = useState([])

  const addPumpData = useInnovaAxios({
    url: '/well/dailyReports/addPumpData',
  })

  const updatePumpData = useInnovaAxios({
    url: '/well/dailyReports/updatePumpData',
  })

  const deletePumpData = useInnovaAxios({
    url: '/well/dailyReports/deletePumpData',
  })

  useEffect(() => {
    if (!_isMounted.current && dailyReportId <= -1) return
    getPumpData()
  }, [dailyReportId]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
    }
  }, [])

  useEffect(() => {
    if (!_isMounted.current) return
    currentWellRef.current = currentWell
  }, [currentWell]) // eslint-disable-line react-hooks/exhaustive-deps

  const getPumpData = async () => {
    if (!dailyReportId || typeof dailyReportId !== 'number') return
    let pumpData = await fetchPumpData(dailyReportId)
    setPumpData(pumpData)
  }

  const handleDelete = (params) => {
    setConfirm({
      show: true,
      title: 'Delete Pump Data',
      text: `Are you sure you want to delete Pump #${params?.data.sequenceNo}: ${params?.data.type}?`,
      params: params,
    })
  }

  const confirmDelete = async () => {
    if (confirm?.params?.data?.recordId >= 0) {
      setShowWait(true)
      const deleteResponse = await deletePumpData({
        uid: confirm?.params?.data?.uid,
        wellName: confirm?.params?.data?.actualwell,
      })
      if (!deleteResponse.error) {
        setStatus({ show: true, severity: 'success', message: 'Pump data deleted successfully' })
        if (gridApi.current) gridApi.current.applyTransaction({ remove: [confirm.params.data] })
      } else {
        setStatus({ show: true, severity: 'error', message: 'Pump data delete failed' })
      }
      setShowWait(false)
    }
  }

  const actionIconRenderer = (params) => {
    return (
      <Box style={{ display: 'flex', flexDirection: 'row' }}>
        <Tooltip
          title={params.node?.rowPinned !== 'bottom' ? 'Delete' : 'Add'}
          placement='left'
          componentsProps={{
            tooltip: {
              sx: {
                backgroundColor: 'rgb(19,62,96)',
                fontSize: '12px',
                fontFamily: 'Roboto',
              },
            },
          }}>
          <IconButton
            sx={{ padding: '5px', alignItems: 'center', justifyContent: 'center' }}
            onClick={() => (params.node?.rowPinned !== 'bottom' ? handleDelete(params) : handleAddRow(params))}
            size='large'>
            {params.node?.rowPinned !== 'bottom' ? (
              <Iconify icon='fa-regular:trash-alt' color={'#C00000'} fontSize={'16px'} />
            ) : (
              <Iconify icon='fluent:add-12-filled' color={'green'} fontSize={'16px'} />
            )}
          </IconButton>
        </Tooltip>
        {params.node?.rowPinned !== 'bottom' ? (
          <Box style={{ textAlign: 'right', paddingLeft: '8px' }}>{params.node.rowIndex + 1}</Box>
        ) : null}
      </Box>
    )
  }

  const centerAlignCell = () => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  })

  const columnDefs = [
    {
      field: 'actions',
      colId: 'actions',
      headerName: '',
      width: 95,
      editable: false,
      cellRendererSelector: () => {
        return {
          component: actionIconRenderer,
        }
      },
      cellStyle: {
        display: 'flex',
        justifyContent: 'center',
        paddingLeft: '4px !important',
        paddingRight: '4px !important',
        borderRight: '1px solid gray !important',
      },
      pinned: 'left',
      lockPosition: 'left',
      suppressHeaderMenuButton: true,
      suppressHeaderFilterButton: true,
    },
    {
      headerName: 'Type',
      field: 'type',
      cellStyle: { textAlign: 'start', paddingLeft: '5px' },
      valueGetter: (params) => unescapeHtml(params.data?.type),
    },
    { headerName: 'Liner', field: 'liner', cellStyle: centerAlignCell },
    {
      headerName: 'Liner Rating',
      field: 'linerRating',
      cellStyle: centerAlignCell,
      valueFormatter: (params) => numberWithCommas(params.data?.linerRating, 2),
    },
    {
      headerName: 'Stroke',
      field: 'stroke',
      cellStyle: centerAlignCell,
      valueFormatter: (params) => {
        if (params.data?.stroke === 0) return ''
        return params.data?.stroke
      },
    },
    {
      headerName: 'Eff%',
      field: 'eff',
      cellStyle: centerAlignCell,
      valueFormatter: (params) => {
        if (params.data?.eff === 0) return ''
        return params.data?.eff
      },
    },
    {
      headerName: 'Pop Offs',
      field: 'popOffs',
      cellStyle: centerAlignCell,
      valueFormatter: (params) => {
        if (params.data?.popOffs === 0) return ''
        return numberWithCommas(params.data?.popOffs, 2)
      },
    },
    {
      headerName: 'SCR Pressure',
      field: 'scrPressure',
      cellStyle: centerAlignCell,
      valueFormatter: (params) => {
        if (params.data?.scrPressure === 0) return ''
        return params.data?.scrPressure
      },
    },
    {
      headerName: 'SCR Strokes',
      field: 'scrStrokes',
      cellStyle: centerAlignCell,
      valueFormatter: (params) => {
        if (params.data?.scrStrokes === 0) return ''
        return params.data?.scrStrokes
      },
    },
  ]

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const onFirstDataRendered = (params) => {
    autoSizeColumns()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) {
      return
    }

    gridApi.current?.autoSizeAllColumns()
  }

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.uid)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  function isEmptyPinnedCell({ node, value }) {
    return (node?.rowPinned === 'bottom' && value == null) || (node?.rowPinned === 'bottom' && value === '')
  }

  function createPinnedCellPlaceholder({ colDef }) {
    if (colDef.field === 'type') {
      return colDef.field[0].toUpperCase() + colDef.field.slice(1) + '...'
    }

    return ''
  }

  function clearCells(start, end, columns, gridApi) {
    let itemsToUpdate = []
    for (let i = start; i <= end; i++) {
      let data = gridApi.rowModel.rowsToDisplay[i].data
      columns.forEach((column) => {
        data[column] = ''
      })

      itemsToUpdate.push(data)
    }

    gridApi.applyTransaction({ update: itemsToUpdate })
  }

  const defaultColDef = useMemo(() => {
    return {
      suppressKeyboardEvent: (params) => {
        if (!params.editing) {
          let isBackspaceKey = params.event.keyCode === 8
          let isDeleteKey = params.event.keyCode === 46

          if (isBackspaceKey || isDeleteKey) {
            params.api.getCellRanges().forEach((range) => {
              let colIds = range.columns.map((col) => col.colId)
              let startRowIndex = Math.min(range.startRow.rowIndex, range.endRow.rowIndex)
              let endRowIndex = Math.max(range.startRow.rowIndex, range.endRow.rowIndex)
              clearCells(startRowIndex, endRowIndex, colIds, params.api)
            })
          }
        }

        return false
      },
      resizable: true,
      sortable: true,
      autoHeight: true,
      editable: true,
      cellStyle: { textAlign: 'right', padding: 0 },
      headerClass: 'header-no-padding',
      valueFormatter: (params) => (isEmptyPinnedCell(params) ? createPinnedCellPlaceholder(params) : undefined),
    }
  }, [])

  const gridOptions = {
    pinnedBottomRowData: [inputRow.current],
    onCellEditingStopped: (params) => {
      if (params.node?.rowPinned === 'bottom') {
        if (gridApi.current) gridApi.current.setGridOption('pinnedBottomRowData', [inputRow.current])
        handleAddRow(params)
        return
      }

      let newData = cloneDeep(params.data)
      if (params.colDef.field !== 'type') {
        newData[params.colDef.field] = parseFloat(params.newValue)
      }

      handleGridUpdate({ action: 'update', data: newData })
    },
    getRowStyle: ({ node }) => (node?.rowPinned ? { fontWeight: 'bold', fontStyle: 'italic' } : 0),
  }

  const handleAddRow = async (params) => {
    handleGridUpdate({
      action: 'add',
      data: {
        ...inputRow.current,
        actualwell: currentWellRef.current,
        dailyReportId: dailyReportId,
      },
    })

    handleAddRowComplete()
  }

  const handleAddRowComplete = () => {
    inputRow.current = {}
    if (gridApi.current) gridApi.current.setGridOption('pinnedBottomRowData', [inputRow.current])
  }

  const handleGridUpdate = async (params) => {
    if (!params) return
    if (!params.hasOwnProperty('action')) return
    if (!gridApi.current) return

    if (params.action === 'add') {
      let escapedData = htmlSymbolHandling(params.data)
      escapedData.wellName = params.data?.actualwell

      let response = await addPumpData(escapedData)
      if (response?.error) {
        setStatus({ show: true, severity: 'error', message: response?.error?.response?.data?.error })
        return
      }
      params.data.uid = parseInt(response.data?.uid)
      params.data.liner = 0
      params.data.linerRating = 0
      params.data.stroke = 0
      params.data.eff = 0
      params.data.popOffs = 0
      params.data.scrPressure = 0
      params.data.scrStrokes = 0

      gridApi.current.applyTransaction({
        add: [cloneDeep(params.data)],
      })
    }

    if (params.action === 'update') {
      let escapedData = htmlSymbolHandling(params.data)
      escapedData.wellName = params.data?.actualwell

      let response = await updatePumpData(escapedData)
      if (response?.error) {
        setStatus({ show: true, severity: 'error', message: response?.error?.response?.data?.error })
        return
      }
      gridApi.current.applyTransaction({
        update: [cloneDeep(params.data)],
      })
    }
  }

  const handleCloseStatus = (event, reason) => {
    setStatus({ show: false, severity: 'info', message: '' })
  }

  return (
    <React.Fragment>
      <Backdrop style={{ color: '#fff', zIndex: 99999 }} open={showWait}>
        <CircularProgress color='inherit' />
      </Backdrop>
      {confirm.show ? (
        <ConfirmDialog
          title={confirm?.title}
          open={confirm?.show}
          setOpen={() => setConfirm({ show: false })}
          onConfirm={() => confirmDelete()}>
          {confirm?.text}
        </ConfirmDialog>
      ) : null}
      <Dialog
        fullWidth={true}
        maxWidth={'lg'}
        PaperProps={{
          sx: {
            height: '50vh',
            backgroundColor: 'itemBackground',
          },
        }}
        open={true}
        onClose={() => setOpen(false)}>
        <DialogTitle>Pump Data</DialogTitle>
        <DialogContent>
          <div style={{ height: '100%', width: '100%' }} className={getAgGridTheme()}>
            <AgGridReact
              rowData={pumpData}
              columnDefs={columnDefs}
              headerHeight={30}
              defaultColDef={defaultColDef}
              gridOptions={gridOptions}
              onGridReady={onGridReady}
              onFirstDataRendered={onFirstDataRendered}
              animateRows={true}
              getRowId={getRowId}
            />
          </div>
          {status?.show ? (
            <Snackbar
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              open={status?.show}
              autoHideDuration={2000}
              onClose={handleCloseStatus}>
              <Alert onClose={handleCloseStatus} severity={status.severity} elevation={4} variant='filled'>
                {status.message}
              </Alert>
            </Snackbar>
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' onClick={() => setOpen(false)} color='secondary'>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}
export default PumpDataGridModal
