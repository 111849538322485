import React, { useState } from 'react'

import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'
import * as yup from 'yup'
import { appColors } from 'utils'
import InputForm from 'components/common/InputForm'
import useUploadEdm from 'components/common/hooks/useUploadEdm'
import { getWellstatusList } from 'components/common/activitySelector'
import fileDownload from 'js-file-download'
import { Backdrop, CircularProgress, Box } from '@mui/material'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

let importOptions = {
  importSurveyTools: true,
  importTargets: true,
  importAllPlans: false,
  importLeaseLines: true,
  assignOffsets: true,
  assignOffsetsRadius: 5,
  actualWellStatus: '',
}

const genActivityList = () => {
  let newList = []
  newList.push({ label: '', value: '' })
  const activityList = getWellstatusList()
  activityList.forEach((activity) => {
    newList.push({ label: activity, value: activity })
  })
  return newList
}

const EdmImportModal = ({ open, onClose, refresh }) => {
  const [files, setFiles] = useState([])
  const [status, setStatus] = useState({ show: false, severity: 'info', message: '' })
  const [activityList] = useState(genActivityList())
  const [showWait, setShowWait] = useState(false)
  const [importSucceeded, setImportSucceeded] = useState(false)
  const uploadEdmFile = useUploadEdm()
  const { getBackColor } = useInnovaTheme()

  const title = 'Import EDM Files'
  const formValidationSchema = yup.object({
    assignOffsetsRadius: yup
      .string()
      .matches(/^[+]?([0-9]+\.?[0-9]*|\.[0-9]+)$/, 'Only numeric values allowed')
      .required('offset Radius cannot be empty'),
  })

  let formData = [
    {
      tag: 'importSurveyTools',
      value: importOptions.importSurveyTools,
      text: 'Import Survey Tools',
      inputType: 'boolean',
    },
    {
      tag: 'importTargets',
      value: importOptions.importTargets,
      text: 'Import Targets',
      inputType: 'boolean',
    },
    {
      tag: 'importAllPlans',
      value: importOptions.importAllPlans,
      text: 'Import All Plans',
      inputType: 'boolean',
    },
    {
      tag: 'importLeaseLines',
      value: importOptions.importLeaseLines,
      text: 'Import Lease Lines',
      inputType: 'boolean',
    },
    {
      tag: 'assignOffsets',
      value: importOptions.assignOffsets,
      text: 'Assign Offsets',
      inputType: 'boolean',
    },
    {
      tag: 'assignOffsetsRadius',
      value: importOptions.assignOffsetsRadius,
      text: 'Assign Offsets Radius (miles)',
      inputType: 'text',
    },
    {
      tag: 'actualWellStatus',
      value: importOptions.actualWellStatus,
      text: 'Set Actual Well Status to',
      inputType: 'dropdown',
      dropDownValues: activityList,
      useLabel: true,
    },
  ]

  const submitInputForm = async (data) => {
    if (showWait) return

    setShowWait(true)
    const response = await uploadEdmFile(files, data)
    setShowWait(false)

    if (!response) {
      setStatus({
        show: true,
        severity: 'error',
        message: 'Error detected during import, no response',
      })

      return
    }

    // first, save the complete response
    let dateTime = new Date(Date.now()).toISOString().split('.')[0].replaceAll(':', '-')
    let filename = 'EdmImportResponse-' + dateTime + '.log'
    fileDownload(JSON.stringify(response), filename)

    // next, process response to show status alert, if needed
    if (response?.files && response.files.length > 0) {
      let errors = false
      response.files.forEach((file) => {
        if (!file.success) {
          errors = true
        }
      })

      setStatus({
        show: true,
        severity: errors ? 'error' : 'success',
        message: errors ? 'Errors detected during import, please check the log file' : 'Import completely successfully',
      })

      if (!errors) {
        setImportSucceeded(true)
        if (refresh) refresh(Date.now().toString())
      }
    }
  }

  const onSelectMultipleFiles = (event) => {
    let selFiles = []
    if (event.target.files && event.target.files.length > 0) {
      let fileCount = event.target.files.length
      if (fileCount > 5) {
        fileCount = 5
        setStatus({ show: true, severity: 'info', message: '5 file limit enforced' })
      }
      for (let i = 0; i < fileCount; i++) {
        selFiles.push(event.target.files[i])
      }
    }
    setFiles(selFiles)
  }

  const handleCloseStatus = () => {
    setStatus({ show: false, severity: 'info', message: '' })
  }

  const handleCloseEdmImport = async () => {
    onClose(importSucceeded)
  }

  return (
    <Dialog
      maxWidth='xl'
      PaperProps={{
        sx: {
          width: '600px',
          backgroundColor: 'itemBackground',
        },
      }}
      open={open}
      onClose={handleCloseEdmImport}>
      <Backdrop style={{ color: '#fff', zIndex: 99999 }} open={showWait}>
        <CircularProgress color='inherit' />
      </Backdrop>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent style={{ overflow: 'auto' }}>
        <Box sx={{ height: 'fit-content', margin: '6px' }}>
          <label htmlFor='btn-upload'>
            <input
              id='btn-upload'
              name='btn-upload'
              style={{ display: 'none' }}
              type='file'
              multiple={true}
              accept='.xml'
              onChange={onSelectMultipleFiles}
            />
            <Button
              sx={{
                marginRight: '10px !important',
                color: appColors.headerTextColor,
                border: '1px solid #C0C0C0',
                '&:hover': {
                  borderColor: '#FFF',
                },
              }}
              variant='outlined'
              component='span'
              type='button'>
              Select files
            </Button>
          </label>
          {files && files.length > 0 ? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: getBackColor(),
                border: '1px solid black',
                borderRadius: '4px',
                padding: '8px 8px 8px 8px',
                marginTop: '6px',
              }}>
              {files.map((file, index) => (
                <Box
                  sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    display: 'inline-block',
                    maxWidth: '100%',
                    verticalAlign: 'middle',
                  }}>{`${index + 1})  ${file.name}`}</Box>
              ))}
            </Box>
          ) : null}
        </Box>
        <InputForm
          formId='edmForm'
          inputData={formData}
          submitForm={submitInputForm}
          validationSchema={formValidationSchema}
        />
        <Button
          sx={{ marginTop: '16px', merginLeft: '5px' }}
          color='primary'
          variant='contained'
          type='submit'
          form='edmForm'>
          Import
        </Button>
      </DialogContent>
      <DialogActions>
        <Button variant='contained' type='button' onClick={handleCloseEdmImport} color='primary'>
          Close
        </Button>
      </DialogActions>
      {status?.show ? (
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={status?.show}
          autoHideDuration={2000}
          onClose={handleCloseStatus}>
          <Alert onClose={handleCloseStatus} severity={status.severity} elevation={4} variant='filled'>
            {status.message}
          </Alert>
        </Snackbar>
      ) : null}
    </Dialog>
  )
}

export default EdmImportModal
