import React, { useCallback, useMemo, useRef } from 'react'
import { AgGridReact } from 'ag-grid-react'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'
import { getStringId } from 'components/common/AgGridUtils'
import { cloneDeep } from 'lodash'

const DepthLabelCellEditor = (props) => {
  const gridApi = useRef(null)
  const { getAgGridTheme } = useInnovaTheme()

  const getData = () => {
    return [
      {
        uid: 0,
        mdTvd: props.mdTvd,
        inc: props.inc,
        azi: props.azi,
        interval: props.interval,
        atSurvey: props.atSurvey,
        range: props.range,
        startDepth: props.startDepth,
        endDepth: props.endDepth,
      },
    ]
  }

  const getValue = useCallback(() => {
    const propsData = {
      uid: 0,
      mdTvd: props.mdTvd,
      inc: props.inc,
      azi: props.azi,
      interval: props.interval,
      atSurvey: props.atSurvey,
      range: props.range,
      startDepth: props.startDepth,
      endDepth: props.endDepth,
    }

    if (!gridApi.current) return propsData

    let data = []
    gridApi.current.forEachNode((node) => {
      if (node.data) data.push(node.data)
    })

    if (data.length === 0) return propsData
    return data[0]
  }, [props])

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const onFirstDataRendered = (params) => {
    if (gridApi.current) autoSizeColumns()
  }

  const onGridReady = useCallback((params) => {
    gridApi.current = params.api
  }, [])

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: false,
      autoHeight: true,
      editable: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      suppressHeaderMenuButton: true,
      headerClass: 'header-no-padding',
    }
  }, [])

  const centerAlignCell = useMemo(
    () => ({
      // display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }),
    [],
  )

  const columnDefs = useMemo(() => {
    return [
      {
        headerName: 'MD/TVD',
        field: 'mdTvd',
        colId: 'mdTvd',
        cellStyle: centerAlignCell,
        minWidth: 70,
        width: 70,
        maxWidth: 70,
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: (params) => {
          return {
            values: ['MD', 'TVD'],
          }
        },
      },
      {
        headerName: 'Inc',
        field: 'inc',
        colId: 'inc',
        cellStyle: centerAlignCell,
        minWidth: 55,
        width: 55,
        maxWidth: 55,
        cellEditor: 'agCheckboxCellEditor',
      },
      {
        headerName: 'Azi',
        field: 'azi',
        colId: 'azi',
        cellStyle: centerAlignCell,
        minWidth: 55,
        width: 55,
        maxWidth: 55,
        cellEditor: 'agCheckboxCellEditor',
      },
      {
        headerName: `Interval (${props.unit})`,
        field: 'interval',
        colId: 'interval',
        cellStyle: centerAlignCell,
        minWidth: 80,
        width: 80,
        maxWidth: 80,
      },
      {
        headerName: '@ Svy',
        field: 'atSurvey',
        colId: 'asSurvey',
        cellStyle: centerAlignCell,
        minWidth: 55,
        width: 55,
        maxWidth: 55,
        cellEditor: 'agCheckboxCellEditor',
      },
      {
        headerName: 'Range',
        field: 'range',
        colId: 'range',
        cellStyle: centerAlignCell,
        minWidth: 60,
        width: 60,
        maxWidth: 60,
        cellEditor: 'agCheckboxCellEditor',
      },
      {
        headerName: `Start Depth (${props.unit})`,
        field: 'startDepth',
        colId: 'startDepth',
        cellStyle: centerAlignCell,
        minWidth: 90,
        width: 90,
        maxWidth: 90,
      },
      {
        headerName: `End Depth (${props.unit})`,
        field: 'endDepth',
        colId: 'endDepth',
        cellStyle: centerAlignCell,
        minWidth: 90,
        width: 90,
        maxWidth: 90,
      },
    ]
  }, [centerAlignCell, props.unit])

  const gridOptions = {
    suppressRowClickSelection: true,
  }

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.uid)
    }
  }, [])

  const onGridPreDestroyed = useCallback(
    (params) => {
      let newValue = cloneDeep(getValue())
      props.data.options = newValue
      props.onValueChange(newValue)
      props.stopEditing()
    },
    [props, getValue],
  )

  return (
    <div className={getAgGridTheme()} style={{ width: '600px', height: '100px', zIndex: 999999 }}>
      <AgGridReact
        rowData={getData()}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        animateRows={false}
        enableBrowserTooltips={true}
        gridOptions={gridOptions}
        headerHeight={48}
        onGridReady={onGridReady}
        rowSelection='single'
        onFirstDataRendered={onFirstDataRendered}
        getRowId={getRowId}
        onGridPreDestroyed={onGridPreDestroyed}
      />
    </div>
  )
}

export default DepthLabelCellEditor
