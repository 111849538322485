import React, { useEffect, useRef, useState } from 'react'
import { useSetRecoilState } from 'recoil'
import { Snackbar, Alert, Box } from '@mui/material'
import { Icon as Iconify } from '@iconify/react'
import { useRecoilValue } from 'recoil'
import { actionBarWidthAtom, currentPageAtom, currentWellSelector } from 'atoms'
import MenuButton from 'components/common/MenuButton'
import RefreshIcon from '@mui/icons-material/Refresh'
import { appColors } from 'utils'
import SplitPane from 'components/common/SplitPane'
import 'components/common/SplitPane/splitPaneResizer.css'
import { PAGE_KEYS } from '../../ActionBar/pageDefs'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import Carousel from 'react-material-ui-carousel'
import useAntiCollision from 'components/common/hooks/useAntiCollision'
import AntiCollisionGrid from './AntiCollisionGrid'
import SummaryGrid from './charts/SummaryGrid'
import TravellingCylinderChart from './charts/TravellingCylinderChart'
import LadderChart from './charts/LadderChart'
import SeparationFactorChart from './charts/SeparationFactorChart'
import rigIcon from 'assets/rigIcon.png'
import SelectOffsetsTreeModal from 'components/WellPages/SurveyPage/SelectOffsetsTreeModal'
import { cloneDeep } from 'lodash'
import useWellData from 'components/common/hooks/useWellData'
import { pdf } from '@react-pdf/renderer'
import FileSaver from 'file-saver'

const AntiCollisionPage = () => {
  const _isMounted = useRef(false)
  const [showChart, setShowChart] = useState(true)
  const leftPos = useRecoilValue(actionBarWidthAtom)
  const setActivePage = useSetRecoilState(currentPageAtom)
  const [antiCollisionData, setAntiCollisionData] = useState({})
  const currentWell = useRecoilValue(currentWellSelector)
  const [status, setStatus] = useState({ show: false, severity: 'info', message: '' })
  const { loading: isLoading, fetchAntiCollision, fetchAntiCollisionData, getPdfData, acError } = useAntiCollision()
  const [filteredData, setFilteredData] = useState()
  const [showOffsetsSelectModal, setShowOffsetsSelectModal] = useState(false)
  const currentWellRef = useRef(currentWell)
  const [carouselIdx, setCarouselIdx] = useState(0)
  const { refreshWellData } = useWellData()
  const [isPrinting, setPrinting] = useState(false)

  useEffect(() => {
    currentWellRef.current = currentWell
  }, [currentWell]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (_isMounted.current) {
      if (!isLoading) setAntiCollisionData(fetchAntiCollisionData())
    }
  }, [isLoading]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    _isMounted.current = true
    setActivePage(PAGE_KEYS.antiCollisionPageKey)

    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (_isMounted.current && acError) {
      setStatus({ show: true, severity: 'error', message: acError })
    }
  }, [acError]) // eslint-disable-line react-hooks/exhaustive-deps

  const onPdfExport = async () => {
    let pdfDoc = null

    let dataForPdf = cloneDeep(filteredData)
    if (typeof dataForPdf !== 'object' || dataForPdf?.length <= 0) return

    setPrinting(true)
    pdfDoc = await getPdfData(dataForPdf)

    if (_isMounted.current) setPrinting(false)

    if (!pdfDoc) return
    const blob = await pdf(pdfDoc.data).toBlob()
    FileSaver.saveAs(blob, pdfDoc.fileName)
  }

  const handleSelectOffsets = () => {
    setShowOffsetsSelectModal(true)
  }

  const handleCloseStatus = () => {
    setStatus({ show: false, severity: 'info', message: '' })
  }

  const getMenuItems = () => {
    let actions = [
      {
        icon: <RefreshIcon />,
        name: 'Refresh',
        onClick: () => {
          setAntiCollisionData({})
          fetchAntiCollision()
        },
      },
      {
        icon: <PictureAsPdfIcon />,
        name: 'PDF',
        onClick: onPdfExport,
      },
      {
        icon: <img className={'rigIconButton'} src={rigIcon} alt='rigGrey' style={{ height: 25, width: 25 }} />,
        name: 'Select Offsets',
        onClick: () => {
          handleSelectOffsets()
        },
      },
    ]
    if (!showChart) {
      actions.push({
        icon: <Iconify icon='bxs:show' style={{ color: appColors.itemTextColor, width: 28, height: 28 }} />,
        name: 'Show Chart',
        onClick: () => handleShowChart(),
      })
    } else {
      actions.push({
        icon: <Iconify icon='bxs:hide' style={{ color: appColors.itemTextColor, width: 28, height: 28 }} />,
        name: 'Hide Chart',
        onClick: () => handleShowChart(),
      })
    }

    return actions
  }

  const handleShowChart = () => {
    setShowChart((prev) => !prev)
  }

  return (
    <React.Fragment>
      {showOffsetsSelectModal ? (
        <SelectOffsetsTreeModal
          title='Select offsets'
          open={showOffsetsSelectModal}
          setOpen={setShowOffsetsSelectModal}
          showSearch={true}
          wellName={currentWellRef.current}
          useOnApplyCallback={true}
          onApply={() => {
            fetchAntiCollision()
            refreshWellData()
          }}
        />
      ) : null}
      <SplitPane
        split='horizontal'
        defaultSize={'50%'}
        size={showChart ? '50%' : 0}
        style={{
          marginTop: '64px',
          marginLeft: `${leftPos}px`,
          height: 'calc(100vh - 64px)',
          maxHeight: 'calc(100vh - 64px)',
          minHeight: 'calc(100vh - 64px)',
          width: `calc(100% - ${leftPos}px)`,
          maxWidth: `calc(100% - ${leftPos}px)`,
          minWidth: `calc(100% - ${leftPos}px)`,
        }}
        allowResize={true}>
        <Carousel
          onChange={(idx) => {
            //Nasty hack <SummaryChart> all carousel items render even if not visible with 0 width
            //This messes up the auto size columns function on the grid, cant autosize when container
            //width = 0
            setCarouselIdx(idx)
          }}
          autoPlay={false}
          height={'100%'}
          sx={{ width: '100%' }}
          fullHeightHover={false}
          swipe={false}>
          <LadderChart data={filteredData} isLoading={isLoading || isPrinting} />
          {carouselIdx === 1 ? (
            <SummaryGrid data={filteredData?.acResults} isLoading={isLoading || isPrinting} />
          ) : null}
          <TravellingCylinderChart antiCollisionData={filteredData} isLoading={isLoading} />
          <SeparationFactorChart data={filteredData} isLoading={isLoading || isPrinting} />
        </Carousel>
        <AntiCollisionGrid
          acData={
            Array.isArray(antiCollisionData?.acResults) && antiCollisionData?.acResults?.length > 0
              ? antiCollisionData
              : []
          }
          isLoading={isLoading || isPrinting}
          setFilteredData={setFilteredData}
        />
      </SplitPane>
      {status?.show ? (
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={status?.show}
          autoHideDuration={2000}
          onClose={handleCloseStatus}>
          <Alert onClose={handleCloseStatus} severity={status.severity} elevation={4} variant='filled'>
            {status.message}
          </Alert>
        </Snackbar>
      ) : null}
      <Box
        sx={{
          backgroundColor: 'transparent',
          margin: '4px',
          padding: '12px',
          position: 'fixed',
          bottom: (theme) => theme.spacing(2),
          right: (theme) => theme.spacing(2),
          zIndex: 2,
        }}>
        <MenuButton actions={getMenuItems()} />
      </Box>
    </React.Fragment>
  )
}

export default AntiCollisionPage
