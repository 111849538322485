import React, { useRef, useEffect, useState } from 'react'
import { currentPageAtom, actionBarWidthAtom, pipeTallyLayoutAtom, currentWellAtom } from 'atoms'
import { useSetRecoilState, useRecoilValue, useRecoilState } from 'recoil'
import { Snackbar } from '@mui/material'
import { PAGE_KEYS } from 'components/ActionBar/pageDefs'
import SplitPane from 'components/common/SplitPane'
import 'components/common/SplitPane/splitPaneResizer.css'
import cloneDeep from 'lodash/cloneDeep'
import Alert from '@mui/material/Alert'
import PipeTallyHeaderGrid from './PipeTallysHeaderGrid'
import JointGrid from './JointGrid'
import usePipeTallys from 'components/common/hooks/usePipeTallys'
import { pdf } from '@react-pdf/renderer'
import FileSaver from 'file-saver'

const PipeTallyPage = () => {
  const setActivePage = useSetRecoilState(currentPageAtom)
  const leftPos = useRecoilValue(actionBarWidthAtom)
  const _isMounted = useRef(false)
  const pipeTallyListGripApi = useRef(null)
  const [selectedTally, setSelectedTally] = useState(null)
  const [status, setStatus] = useState({ show: false, severity: 'info', message: '' })
  const [pageLayout, setPageLayout] = useRecoilState(pipeTallyLayoutAtom)
  const currentWell = useRecoilValue(currentWellAtom)
  const {
    isLoading,
    getPipeTallyData,
    fetchPipeTallys,
    addNewTally,
    updateTally,
    deleteTally,
    addNewJoint,
    updateJoint,
    deleteJoint,
    updateJointSequence,
    getPipeTallyPdf,
    addMultipleJoints,
    onXlsExport
  } = usePipeTallys()

  const getPaneVisible = (index) => {
    if (!Array.isArray(pageLayout)) return true
    if (index >= pageLayout.length) return true
    return pageLayout[index].visible
  }

  const [showTallysList, setShowTallysList] = useState(getPaneVisible(0))

  useEffect(() => {
    _isMounted.current = true
    setActivePage(PAGE_KEYS.pipeTallysPageKey)

    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handlePrintTally = async (data) => {
    let pdfDoc = getPipeTallyPdf(data.tallyId)
    if(!pdfDoc) return
    const blob = await pdf(pdfDoc.data).toBlob()
    FileSaver.saveAs(blob, pdfDoc.fileName)
  }

  useEffect(() => {
    if (_isMounted.current) setSelectedTally(null)
  }, [currentWell]) // eslint-disable-line react-hooks/exhaustive-deps

  const getInitialPaneSize = (index) => {
    if (!Array.isArray(pageLayout)) return index === 0 ? '50%' : '50%'
    if (index >= pageLayout.length) return index === 0 ? '50%' : '50%'
    return pageLayout[index].size
  }

  const onSetPaneVisible = (index, value) => {
    if (Array.isArray(pageLayout)) return
    if (index >= pageLayout.length) return
    let newPanes = cloneDeep(pageLayout)
    newPanes[index].visible = value
    setPageLayout(newPanes)
  }

  const showHidePane = () => {
    onSetPaneVisible(0, !showTallysList)
    setShowTallysList(!showTallysList)
  }

  const handleCloseStatus = () => {
    setStatus({ show: false, severity: 'info', message: '' })
  }

  const updateSelectedTally = () => {
    if(!selectedTally) return
    let updatedTally = getPipeTallyData(selectedTally.tallyId)
    if(!Array.isArray(updatedTally)) return
    if(updateTally.length === 0) return
    setSelectedTally(updatedTally[0])
  }

  return (
    <React.Fragment>
      <SplitPane
        split='vertical'
        defaultSize={getInitialPaneSize(0)}
        size={showTallysList ? getInitialPaneSize(0) : '25px'}
        allowResize={showTallysList}
        style={{
          height: 'calc(100vh - 64px)',
          marginLeft: leftPos,
          width: `calc(100% - ${leftPos}px)`,
          maxWidth: `calc(100% - ${leftPos}px)`,
        }}>
        <PipeTallyHeaderGrid
          isLoading={isLoading}
          pipeTallys={getPipeTallyData(-1)}
          isVisible={showTallysList}
          showHidePane={showHidePane}
          refreshData={fetchPipeTallys}
          addNewTally={addNewTally}
          updateTally={updateTally}
          deleteTally={deleteTally}
          setSelectedTally={setSelectedTally}
          gridApi={pipeTallyListGripApi}
          handlePrintTally={handlePrintTally}
          selectedTally={selectedTally}
          onXlsExport={onXlsExport}
        />
        <JointGrid
          isLoading={isLoading}
          selectedTally={selectedTally}
          addNewJoint={addNewJoint}
          addMultipleJoints={addMultipleJoints}
          updateJoint={updateJoint}
          deleteJoint={deleteJoint}
          updateSelectedTally={updateSelectedTally}
          updateJointSequence={updateJointSequence}
          onXlsExport={onXlsExport}
        />
      </SplitPane>
      {status?.show ? (
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={status?.show}
          autoHideDuration={2000}
          onClose={handleCloseStatus}>
          <Alert onClose={handleCloseStatus} severity={status.severity} elevation={4} variant='filled'>
            {status.message}
          </Alert>
        </Snackbar>
      ) : null}
    </React.Fragment>
  )
}

export default PipeTallyPage
