export const PAGE_KEYS = {
  multiDbWellListKey: 'multi-db-well-list-page-key',
  dbTreePageKey: 'dbtree-page-key',
  wellDetailsKey: 'well-details-key',
  dailyReportKey: 'daily-report-key',
  depthTimeKey: 'depth-time-key',
  wellConnectionAnalysisKey: 'well-connection-analysis-key',
  connectionAnalysisKey: 'connection-analysis-key',
  connectionAnalysisRigKey: 'connection-analysis-rig-key',
  inventoryKey: 'inventory-key',
  surveyKey: 'survey-key',
  slideSheetKey: 'slide-sheet-key',
  costsHeadersKey: 'costs-headers-key',
  drillStringKey: 'drill-string-key',
  wellSheetSummaryKey: 'well-sheet-summary-key',
  analyticsKey: 'analytics-key',
  logisticsKey: 'logistics-key',
  adminUsersKey: 'admin-users-key',
  adminRolesKey: 'admin-roles-key',
  adminInnovaOrgsKey: 'admin-innova-orgs-key',
  adminInnovaDashboardKey: 'admin-innova-dashboard-key',
  adminInnovaIcdsKey: 'admin-innova-icds-key',
  adminAcDashKey: 'admin-innova-ac-dash-key',
  adminInnovaDrillLinkKey: 'admin-innova-drilllink-key',
  adminInnovaApiHealthKey: 'innova-admin-api-health-key',
  adminInnovaDataAcqHealthKey: 'innova-admin-data-acq-health-key',
  adminInnovaEndpointUsageKey: 'innova-admin-endpoint-usage-key',
  adminWellSeekerKey: 'admin-wellseeker-key',
  adminWellSeekerUpdateFilesKey: 'admin-wellseeker-update-files-key',
  adminWellSeekerUpdateConfigKey: 'admin-wellseeker-update-config-key',
  adminUnitsKey: 'admin-units-key',
  depthVDaysKey: 'analytics-depthvdays-key',
  slideRotateFootageKey: 'analytics-slide-rotate-footage-key',
  slideRotateRopKey: 'analytics-slide-rotate-rop-key',
  slideRotateHoursKey: 'analytics-slide-rotate-hours-key',
  activitySummary: 'analytics-activitySummary-key',
  costPerWellKey: 'analytics-cost-per-well-key',
  costPerDayKey: 'analytics-cost-per-day-key',
  costPerOperatorKey: 'analytics-cost-per-operator-key',
  costPerFootKey: 'analytics-cost-per-foot-key',
  motorPerPhaseKpiKey: 'analytics-motor-per-phase-kpi-key',
  motorKpiKey: 'analytics-motor-kpi-key',
  holeSizeKpiKey: 'analytics-hole-size-kpi-key',
  daysKpiKey: 'analytics-days-kpi-key',
  activityKpiKey: 'activity-days-kpi-key',
  inventoryKpiKey: 'inventory-days-kpi-key',
  towerComparisonKpiKey: 'tower-comparison-kpi-key',
  phaseKpiKey: 'analytics-phase-kpi-key',
  wellKpiKey: 'analytics-well-kpi-key',
  parametersRoadmapKey: 'parameters-roadmap-page-key',
  wellParametersRoadmapKey: 'well-parameters-roadmap-page-key',
  mapSearchKpiKey: 'analytics-map-search-key',
  fastestSectionKpiKey: 'analytics-fastest-section-kpi-key',
  longestSectionKpiKey: 'analytics-longest-section-kpi-key',
  maxDrilledPerDayKpiKey: 'analytics-max-drilled-per-day-kpi-key',
  maxRopKpiKey: 'analytics-max-rop-kpi-key',
  bhaResultsKpiKey: 'analytics-bha-results-key',
  directionalScoreCardKpiKey: 'analytics-directional-score-card-key',
  motorYieldKey: 'analytics-motor-yield-key',
  parameterComparisonKey: 'analytics-parameter-comparison-key',
  rigDaysKey: 'analytics-rig-days-key',
  wellRankingKey: 'analytics-well-ranking-key',
  edrPageKey: 'edr-page-key',
  userProfileKey: 'user-profile-page-key',
  objectValidationKey: 'object-validation-page-key',
  errorModelsKey: 'error-models-page-key',
  toolOrdersKey: 'tool-orders-page-key',
  assetSearchKey: 'asset-search-page-key',
  dataAcquisitionKey: 'data-acquisition-page-key',
  dataStoreKey: 'data-store-page-key',
  dataAckFileStatusKey: 'data-ack-file-status-key',
  witsmlServersKey: 'witsml-server-page-key',
  multiWellDashboardPageKey: 'multi-well-dashboard-key',
  antiCollisionDashboardPageKey: 'anti-collision-dashboard-key',
  antiCollisionPageKey: 'anti-collision-key',
  crsPageKey: 'crs-page-key',
  dataQcPageKey: 'data-qc-page-key',
  invoiceQcPageKey: 'invoice-qc-page-key',
  rigNamesPageKey: 'rig-names-page-key',
  personnelNamesPageKey: 'personnel-names-page-key',
  auditDataPageKey: 'audit-data-page-key',
  innovaCompanysPageKey: 'innova-companys-page-key',
  innovaContactsPageKey: 'innova-contacts-page-key',
  innovaQuotesPageKey: 'innova-quotes-page-key',
  innovaVendorsPageKey: 'innova-vendors-key',
  thirdPartyAppCredsPageKey: 'third-party-app-creds-key',
  compCatalogPageKey: 'comp-catalog-page-key',
  centralizerCatalogPageKey: 'centralizer-catalog-page-key',
  userDatabaseAccessPageKey: 'user-database-access-page-key',
  stopCollarCatalogPageKey: 'stop-collar-catalog-page-key',
  crmSystemPageKey: 'crm-system-key',
  innovaInvoicesPageKey: 'innova-invoices-page-key',
  innovaLicensesPageKey: 'innova-licenses-page-key',
  innovaLicenseDeactivationsPageKey: 'innova-license-deactivations-page-key',
  innovaSoftwareDetailsPageKey: 'innova-software-details-page-key',
  innovaSoftwareBillingPageKey: 'innova-software-billing-page-key',
  innovaAdminUsersKey: 'innova-admin-users-key',
  innovaLicenseRequestKey: 'innova-license-request-key',
  engineeringDashboardKey: 'engineering-dashboard-key',
  incidentDashboardPageKey: 'incident-dashboard-page-key',
  innovaAdminBankAccountsPageKey: 'innova-admin-bank-accounts-key',
  innovaAdminBankEmployeesPageKey: 'innova-admin-bank-employees-key',
  footageRopByMotorPageKey: 'analytics-rop-footage-by-motor-key',
  footageRopByBitPageKey: 'analytics-rop-footage-by-bit-key',
  footageRopByFormationPageKey: 'analytics-rop-footage-by-formation-key',
  footageRopByRigPageKey: 'analytics-rop-footage-by-rig-key',
  footageRopByDirCoPageKey: 'analytics-rop-footage-by-dirco-key',
  footageRopByHoleSizePageKey: 'analytics-rop-footage-by-hole-size-key',
  innovaAdminFileManagerPageKey: 'innova-admin-file-manager-page-key',
  wellListKey: 'well-list-key',
  shippingAddressesPageKey: 'shipping-addresses-page-key',
  ticketPageKey: 'ticket-page-key',
  pipeTallysPageKey: 'pipe-tally-key',
  databaseRestoreStatusPageKey: 'database-restore-status-page-key',
  daqtabaseBackupFilesPageKey: 'database-backup-files-page-key',
  personnelUtilizationPageKey: 'personnel-utilization-page-key',
  bidsPageKey: 'bids-page-key',
  wallplotComposerPageKey: 'wallplot-composer-page-key',
}
