import React, { useRef, useMemo, useState, useEffect } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { AgGridReact } from 'ag-grid-react'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import { camelCaseToLabel } from 'utils/stringFunctions'
import { getWellstatusList } from 'components/common/activitySelector'
import { CustomLoadingOverlay, getStringId } from 'components/common/AgGridUtils'
import { cloneDeep, isEqual } from 'lodash'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const TABLES_TO_SYNC = [
  'GEOSTEERING',
  'DRILL_STRING',
  'SLIDE_RECORDS',
  'PIPE_TALLY',
  'DAILY_REPORTS',
  'DAILY_ACTIVITY',
  'FIELD_COST',
  'PUMP_DATA',
  'INVENTORY',
  'SHIPPING_TICKET',
  'MOTOR_REPORT',
  'COST_CODES',
  'DEPTH_DATUMS',
  'ACTUALWELL',
  'SURVEY',
  'EXTERNAL_FILES',
  'RIG_DATA',
  'PERSONNEL_DATA',
  'ACTIVITY_CODES',
  'PHASE_CODES',
]

const DEFAULT_WELLSEEKER_SETTINGS = {
  active: false,
  lockCostCodes: false,
  lockActivityCodes: false,
  lockPhaseCodes: false,
  lockAddresses: false,
  lockRigNames: false,
  lockPersonnelNames: false,
  pushPrincipalPlan: false,
  checkForNewPlans: false,
  allowedWellStatus: [],
  tablesToSync: [],
}

const WellSeekerSettingsModal = ({ organization, onClose }) => {
  const gridApi = useRef(null)
  const _isMounted = useRef(true)
  const orgSettings = useRef(null)
  const currentSettings = useRef(null)
  const [isLoading, setLoading] = useState(false)
  const [dataChanged, setDataChanged] = useState(false)
  const { getAgGridTheme } = useInnovaTheme()

  useEffect(() => {
    _isMounted.current = true
    return () => {
      _isMounted.current = false
    }
  }, [])

  const getSettings = useInnovaAxios({
    url: '/admin/wellSeekerSettings/getDataExchangeSettings',
  })

  const updateSettings = useInnovaAxios({
    url: '/admin/wellSeekerSettings/updateDataExchangeSettings',
  })

  const onFirstDataRendered = (params) => {
    autoSizeColumns()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) {
      return
    }

    gridApi.current?.autoSizeAllColumns()
  }

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: true,
      autoHeight: true,
      editable: true,
      headerClass: 'header-no-padding',
    }
  }, [])

  const fetchData = async () => {
    if (isLoading) return
    setLoading(true)
    const res = await getSettings({ organization: organization })
    if (!_isMounted.current) return
    setLoading(false)

    if (res?.error) {
      return
    }

    if (!res.data) return

    let rowData = []
    for (let key in res.data) {
      let defaultValue = DEFAULT_WELLSEEKER_SETTINGS[key]

      rowData.push({
        id: key,
        label: camelCaseToLabel(key),
        value: res.data[key] ? res.data[key] : DEFAULT_WELLSEEKER_SETTINGS[key],
        type: typeof DEFAULT_WELLSEEKER_SETTINGS[key],
        defaultValue: defaultValue,
      })
    }

    orgSettings.current = cloneDeep(res.data)
    currentSettings.current = cloneDeep(res.data)
    gridApi.current.setGridOption('rowData', rowData)
  }

  const gridOptions = useMemo(() => {
    return {
      onCellEditingStopped: (params) => {
        currentSettings.current[params.data.id] = params.newValue
        setDataChanged(!isEqual(currentSettings.current, orgSettings.current))
      },
      loadingOverlayComponent: CustomLoadingOverlay,
    }
  }, [])

  const onGridReady = (params) => {
    gridApi.current = params.api
    fetchData()
  }

  const getContextMenuItems = (params) => {
    return ['copy']
  }

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.id)
    }
  }, [])

  let columnDefs = useMemo(
    () => [
      {
        field: 'label',
        colId: 'label',
        headerName: 'Label',
        editable: false,
      },
      {
        headerName: '',
        field: 'value',
        colId: 'value',
        minWidth: 350,
        maxWidth: 600,
        editable: true,
        wrapText: true,
        cellStyle: (params) => {
          return { display: 'flex', alignItems: 'center', justifyContent: 'start' }
        },
        valueFormatter: (params) => {
          if (Array.isArray(params.value)) {
            return params.value.join(', ')
          }

          return params.value
        },
        valueParser: (params) => {
          const { newValue } = params
          if (newValue == null || newValue === '') {
            return null
          }

          if (params.data?.type === 'object') {
            return params.newValue.split(',')
          }

          return newValue
        },
        cellRendererSelector: (params) => {
          if (params.data?.type === 'boolean') {
            return {
              component: 'agCheckboxCellRenderer',
            }
          }
          return null
        },
        cellEditorSelector: (params) => {
          if (params.data?.type === 'boolean') {
            return {
              component: 'agCheckboxCellEditor',
            }
          }

          if (params.data?.type === 'object') {
            let dropDowns = []
            if (params.data?.id === 'allowedWellStatus') dropDowns = getWellstatusList()
            if (params.data?.id === 'tablesToSync') dropDowns = TABLES_TO_SYNC

            return {
              component: 'agRichSelectCellEditor',
              params: {
                values: dropDowns,
                multiSelect: true,
                searchType: 'matchAny',
                filterList: true,
                highlightMatch: true,
                valueListMaxHeight: 220,
              },
            }
          }

          if (params.data?.type === 'number') {
            return {
              component: 'agTextCellEditor',
              params: {
                precision: 2,
              },
            }
          }

          return {
            component: 'agTextCellEditor',
          }
        },
      },
    ],
    [],
  )

  const handleUpdate = async () => {
    if (!gridApi.current) return

    setLoading(true)
    let res = await updateSettings({ organization: organization, settings: JSON.stringify(currentSettings.current) })
    if (!_isMounted.current) return
    setLoading(false)

    if (res?.error) {
      return
    }

    orgSettings.current = cloneDeep(currentSettings.current)
    setDataChanged(false)
  }

  return (
    <React.Fragment>
      <Dialog
        fullWidth={true}
        maxWidth={'lg'}
        PaperProps={{
          sx: {
            height: '80vh',
            width: '80vw',
            backgroundColor: 'itemBackground',
          },
        }}
        open={true}
        onClose={() => onClose()}>
        <DialogTitle>Data Exchange Settings</DialogTitle>
        <DialogContent>
          <div className={getAgGridTheme()} style={{ height: '100%', width: '100%' }}>
            <AgGridReact
              loading={isLoading}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              headerHeight={30}
              onFirstDataRendered={onFirstDataRendered}
              onGridReady={onGridReady}
              getContextMenuItems={getContextMenuItems}
              gridOptions={gridOptions}
              getRowId={getRowId}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' onClick={() => onClose()} color='secondary'>
            Close
          </Button>
          {dataChanged ? (
            <Button variant='outlined' onClick={() => handleUpdate()} color='primary'>
              Apply
            </Button>
          ) : null}
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}
export default WellSeekerSettingsModal
