import { useEffect, useRef, useState } from 'react'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import { useRecoilValue } from 'recoil'
import { currentWellAtom, userUserRoleAtom } from 'atoms'
import { removeSpecialSymbols } from 'utils/stringFunctions'
import PdfDocument from 'components/common/PDFGen/PdfDocument'
import useUnits, { UNITS_FOR } from 'components/common/hooks/useUnits'
import useBhaImages from 'components/common/hooks/useBhaImages'
import useOrgIcons from 'components/common/hooks/useOrgIcons'
import { cloneDeep } from 'lodash'
import * as XLSX from '@sheet/image'
import { generateBhaPdfDocument, generateMotorReportPdfDocument } from './pdfReport'
import {
  handleCreateDrillStringXlsWorksheet,
  getXlsFileName,
  handleCreatePerformanceXlsWorksheet,
  getPerformanceXlsWorksheetStyles,
  makeEmptyArray,
} from './excelReport'
import { getStyles, mergeCells, emptyCells, makeMergedCells } from 'components/common/ExcelReports/ExcelReportFunctions'
import { DEFAULT_REPORT_SETTINGS } from 'components/Admin/Organizations/ReportSettingsModal'
import { numberWithCommasDecimals } from 'utils/stringFunctions'

function useDrillString(wellName = null) {
  const _isMounted = useRef(true)
  const drillStringData = useRef([])
  const isLoading = useRef(false)
  const [loading, setLoading] = useState(false)
  const currentWell = useRecoilValue(currentWellAtom)
  const currentWellRef = useRef(currentWell)
  const isDeleting = useRef(false)
  const isAdding = useRef(false)
  const isUpdating = useRef(false)
  const wellInfoRef = useRef(null)
  const motorReportData = useRef(null)
  const { getUnitsText } = useUnits()
  const { getCurrentOrgIcon } = useOrgIcons()
  const bhaImages = useBhaImages()
  const userRole = useRecoilValue(userUserRoleAtom)

  const getBhaHeaders = useInnovaAxios({
    url: '/well/drillString/getBhaHeaders',
  })

  const getWellInfo = useInnovaAxios({
    url: '/well/wellInfo/getWellInfo',
  })

  const getMotorReport = useInnovaAxios({
    url: '/well/motorReport',
  })

  const getBha = useInnovaAxios({
    url: '/well/drillString/getAllBha',
  })

  const addBhaHeader = useInnovaAxios({
    url: '/well/drillString/addBha',
  })

  const updateBhaHeader = useInnovaAxios({
    url: '/well/drillString/updateBhaDesc',
  })

  const deleteBhaHeader = useInnovaAxios({
    url: '/well/drillString/deleteBha',
  })

  const getIaddExport = useInnovaAxios({
    url: '/well/drillString/getBhaHeadersIadd',
  })

  const duplicateBha = useInnovaAxios({
    url: '/well/drillString/duplicateBha',
  })

  useEffect(() => {
    _isMounted.current = true
    return () => {
      _isMounted.current = false
    }
  }, [])

  useEffect(() => {
    currentWellRef.current = currentWell

    if (wellName) {
      currentWellRef.current = wellName
    }

    fetchBhaHeaders()
    fetchWellInfo()
    fetchMotorReportData()
  }, [currentWell, wellName]) // eslint-disable-line react-hooks/exhaustive-deps

  const setWell = async (wellName) => {
    if (!wellName) return
    if (wellName === currentWellRef.current) return
    currentWellRef.current = wellName
    await fetchBhaHeaders()
    await fetchWellInfo()
    await fetchMotorReportData()
  }

  const fetchBhaHeaders = async () => {
    if (typeof currentWellRef.current !== 'string') return
    if (currentWellRef.current === '') return
    if (isLoading.current) return
    isLoading.current = true
    if (_isMounted.current) setLoading(true)

    const res = await getBhaHeaders({ wellName: currentWellRef.current })
    isLoading.current = false
    if (!_isMounted.current) return
    if (res?.error) return
    if (!Array.isArray(res.data)) return
    drillStringData.current = res.data

    for (let i = 0; i < drillStringData.current.length; i++) {
      drillStringData.current[i].actualWell = currentWellRef.current
    }

    setLoading(false)
  }

  const getDrillStringHeader = (bhaNum) => {
    if (!drillStringData.current) return []
    if (!Array.isArray(drillStringData.current) || drillStringData.current?.length === 0) return []

    let sortedData = drillStringData.current.sort((firstEl, secondEl) =>
      firstEl.bhaNumRep > secondEl.bhaNumRep ? -1 : 1,
    )

    if (typeof bhaNum === 'number' && bhaNum >= 0) {
      for (let i = 0; i < sortedData.length; i++) {
        if (sortedData[i].bhaNum === bhaNum) return cloneDeep(sortedData[i])
      }
    }

    return sortedData
  }

  const fetchWellInfo = async () => {
    if (!currentWellRef.current) return
    if (typeof currentWellRef.current !== 'string') return
    if (currentWellRef.current === '') return

    const res = await getWellInfo({
      wellName: currentWellRef.current,
    })

    if (!_isMounted.current) return
    if (res?.error) return
    if (!res?.data) return

    wellInfoRef.current = cloneDeep(res.data)
  }

  const fetchMotorReportData = async () => {
    if (!currentWellRef.current) return
    if (typeof currentWellRef.current !== 'string') return
    if (currentWellRef.current === '') return

    const res = await getMotorReport({
      wellName: currentWellRef.current,
    })

    if (!_isMounted.current) return
    if (res?.error) return
    if (!res?.data) return
    motorReportData.current = res.data
  }

  const fetchBhas = async (wellName, bhaNum) => {
    if (!wellName) return []
    if (typeof wellName !== 'string') return []
    if (wellName === '') return []
    if (typeof bhaNum !== 'number') bhaNum = -1

    const res = await getBha({ wellName: wellName, bhaNum: bhaNum, getAll: bhaNum === -1 })
    if (res?.error) return []
    if (!res?.data) return []
    return res.data
  }

  const getBhaReportPdfData = async (bhaNum) => {
    if (bhaNum === null || bhaNum === undefined) {
      bhaNum = -1
    }

    let bhaData = await fetchBhas(currentWellRef.current, bhaNum)

    if (!Array.isArray(bhaData)) return null
    if (bhaData.length === 0) return null
    if (!wellInfoRef.current) return null

    let pdfData = []
    for (let i = 0; i < bhaData.length; i++) {
      if (!bhaData[i]?.hasOwnProperty('bhaComponents')) continue
      let bhaPdfData = generateBhaPdfDocument(
        bhaData[i]?.bhaNum,
        bhaData[i]?.bhaComponents,
        bhaImages,
        drillStringData,
        getCurrentOrgIcon,
        wellInfoRef,
        getUnitsText,
      )

      if (!bhaPdfData) continue
      pdfData.push(bhaPdfData)
    }

    return {
      fileName: `Drill String - ${removeSpecialSymbols(currentWellRef.current)}`,
      data: (
        <PdfDocument
          data={pdfData}
          multiDocument={true}
          pageOrientation={'portrait'}
          reportSettings={userRole?.userPrefs?.reportSettings}
        />
      ),
    }
  }

  const getMotorReportPdfData = async (bhaNum, motorImages) => {
    if (bhaNum === null || bhaNum === undefined) bhaNum = -1
    if (typeof bhaNum !== 'number') bhaNum = -1

    const bhaData = await fetchBhas(currentWellRef.current, bhaNum)

    if (!Array.isArray(bhaData)) return null
    if (bhaData.length === 0) return null

    let pdfData = []
    for (let i = 0; i < bhaData?.length; i++) {
      if (!bhaData[i]?.hasOwnProperty('bhaComponents')) continue
      let motorReportPdfData = generateMotorReportPdfDocument(
        bhaData[i],
        motorImages,
        motorReportData.current,
        wellInfoRef,
        currentWellRef,
        getCurrentOrgIcon,
        getUnitsText,
      )

      if (!motorReportPdfData) continue
      pdfData.push(motorReportPdfData)
    }

    return {
      fileName: `Motor Performance Report - ${removeSpecialSymbols(currentWellRef.current)}`,
      data: (
        <PdfDocument
          data={pdfData}
          multiDocument={true}
          pageOrientation={'portrait'}
          reportSettings={userRole?.userPrefs?.reportSettings}
        />
      ),
    }
  }

  async function deleteBha(bhaNum) {
    if (typeof bhaNum !== 'number') return null
    if (bhaNum < 0) return null
    if (isDeleting.current) return
    if (typeof currentWellRef.current !== 'string') return
    if (currentWellRef.current === '') return
    isDeleting.current = true

    const response = await deleteBhaHeader({
      bhaNum: bhaNum,
      wellName: currentWellRef.current,
    })
    if (_isMounted.current) isDeleting.current = false
    if (response.error) return { isError: true, message: response?.error?.response?.data?.error }
    return { isError: false, message: '' }
  }

  async function addBha(desc) {
    if (isAdding.current) return
    if (typeof currentWellRef.current !== 'string') return
    if (currentWellRef.current === '') return
    isAdding.current = true

    if (typeof desc !== 'string') desc = 'New BHA'

    const response = await addBhaHeader({ wellName: currentWellRef.current, description: desc })
    isAdding.current = false
    if (response.error) return { isError: true, message: response?.error?.response?.data?.error }
    return { isError: false, message: '' }
  }

  async function copyBha(data) {
    if (!data) return
    if (!data.hasOwnProperty('wellName')) return
    if (!data.hasOwnProperty('bhaNum')) return
    if (isAdding.current) return
    if (typeof currentWellRef.current !== 'string') return
    if (currentWellRef.current === '') return
    isAdding.current = true

    const response = await duplicateBha({
      wellName: currentWellRef.current,
      orgWellName: data.wellName,
      bhaNum: data.bhaNum,
    })

    isAdding.current = false
    if (response.error) return { isError: true, message: response?.error?.response?.data?.error }
    return { isError: false, message: '' }
  }

  const updateBhaDesc = async (data) => {
    if (!data) return null
    if (isUpdating.current) return null
    if (typeof currentWellRef.current !== 'string') return null
    if (currentWellRef.current === '') return null

    let payload = { wellName: currentWellRef.current, bhaDesc: data.bhaDesc, bhaNum: data.bhaNum }

    isUpdating.current = true
    let response = await updateBhaHeader(payload)
    isUpdating.current = false
    if (response.error) return { isError: true, message: response?.error?.response?.data?.error }
    return { isError: false, message: '' }
  }

  const fetchBhaIaddExport = async (bhaNum) => {
    if (!bhaNum || typeof bhaNum !== 'number') bhaNum = -1
    if (!currentWellRef.current) return null
    if (currentWellRef.current?.length < 1) return null

    const res = await getIaddExport({ wellName: currentWellRef.current })
    if (res.error) return
    if (!Array.isArray(res.data) || res.data.length === 0) return

    if (bhaNum < 0) return res.data
    for (let i = 0; i < res.data.length; i++) {
      if (res.data[i].uid === bhaNum) return res.data[i]
    }

    return null
  }

  const getBhaIaddXlsExport = async (bhaNum) => {
    if (!bhaNum || typeof bhaNum !== 'number') bhaNum = -1
    if (!currentWellRef.current) return null
    if (currentWellRef.current?.length < 1) return null

    const res = await getIaddExport({ wellName: currentWellRef.current })
    if (res.error) return
    if (!Array.isArray(res.data) || res.data.length === 0) return

    let fullBhaData = await fetchBhas(currentWellRef.current, bhaNum)

    if (!Array.isArray(fullBhaData)) return null
    if (fullBhaData.length === 0) return null

    let bhaData = []
    if (bhaNum < 0) bhaData = res.data
    else {
      for (let i = 0; i < res.data.length; i++) {
        if (res.data[i].uid === bhaNum) bhaData.push(res.data[i])
      }
    }

    if (!bhaData) return []
    if (!Array.isArray(bhaData)) return []
    if (bhaData.length === 0) return []

    let reportSettings = DEFAULT_REPORT_SETTINGS
    const styles = getStyles(reportSettings.primaryColor)

    let fileName = `IADD_Report_${currentWellRef.current}.xlsx`
    let wb = XLSX.utils.book_new()

    for (let b = 0; b < bhaData.length; b++) {
      let bha = bhaData[b]
      let worksheetName = `BHA ${bha.BHARunNumber}`
      let worksheet = XLSX.utils.aoa_to_sheet([[]], {
        origin: 'B1',
      })

      worksheet['!merges'] = []
      worksheet['!cols'] = []

      let startCol = 1
      let startRow = 2

      //--------------------------------------SUMMARY---------------------------------------
      let data = [['Summary']]

      XLSX.utils.sheet_add_aoa(worksheet, data, {
        origin: 'B1',
      })

      data = [['BHA \nReport']]

      worksheet['!merges'].push(
        mergeCells({
          row: startRow,
          col: startCol,
          rowEnd: startRow + 1,
          colEnd: startCol + 1,
          style: styles.centerBoldWraptttt,
        }),
      )

      XLSX.utils.sheet_add_aoa(worksheet, data, {
        origin: 'B3',
      })

      let RigInfo = [
        ['Operator', ...emptyCells(1), bha?.operator, ...emptyCells(2)],
        ['Rig', ...emptyCells(1), bha?.rig, ...emptyCells(2)],
        ['Well', ...emptyCells(1), bha?.well, ...emptyCells(2)],
        ['Api/UWI#', ...emptyCells(1), bha?.numAPI, ...emptyCells(2)],
        ['Directional Drilling', ...emptyCells(1), bha?.directionalDrilling, ...emptyCells(2)],
        ['MWD/LWD', ...emptyCells(1), bha?.MWDLWD, ...emptyCells(2)],
      ]

      let ColDef = [
        { colSpan: 2, headerStyle: styles.fillRightBoldtttt },
        { colSpan: 3, headerStyle: styles.centertttt },
      ]

      XLSX.utils.sheet_add_aoa(worksheet, RigInfo, {
        origin: 'D3',
      })

      worksheet['!merges'].push(...makeMergedCells(ColDef, startRow, 3, RigInfo.length))

      let BhaInfo = [
        ['BHA Run #', ...emptyCells(3), bha?.BHARunNumber],
        [`Bit (Hole) Size [${getUnitsText(UNITS_FOR.Diameter)}]`, ...emptyCells(3), bha?.bitSize?.value],
      ]

      ColDef = [
        { colSpan: 4, headerStyle: styles.fillRightBoldtttt },
        { colSpan: 1, headerStyle: styles.centertttt },
      ]

      XLSX.utils.sheet_add_aoa(worksheet, BhaInfo, {
        origin: 'J3',
      })

      worksheet['!merges'].push(...makeMergedCells(ColDef, startRow, 9, BhaInfo.length))

      let DrillDepths = [
        [
          `Plan Drill Depth [${getUnitsText(UNITS_FOR.Depth)}]`,
          ...emptyCells(2),
          'From',
          bha?.planDrillDepth?.from?.value,
        ],
        [`Plan Drill Depth [${getUnitsText(UNITS_FOR.Depth)}]`, ...emptyCells(2), 'To', bha?.planDrillDepth?.to?.value],
        [
          `Actual Drill Depth [${getUnitsText(UNITS_FOR.Depth)}]`,
          ...emptyCells(2),
          'From',
          bha?.actualDrillDepth?.from?.value,
        ],
        [
          `Actual Drill Depth [${getUnitsText(UNITS_FOR.Depth)}]`,
          ...emptyCells(2),
          'To',
          bha?.actualDrillDepth?.to?.value,
        ],
      ]

      ColDef = [
        { colSpan: 3, headerStyle: styles.fillRightBoldtttt },
        { colSpan: 1, headerStyle: styles.centertttt },
        { colSpan: 1, headerStyle: styles.centertttt },
      ]

      XLSX.utils.sheet_add_aoa(worksheet, DrillDepths, {
        origin: 'J5',
      })

      worksheet['!merges'].push(...makeMergedCells(ColDef, startRow + BhaInfo.length, 9, DrillDepths.length))

      let CasingData = [
        [`Last Casing', 'Size [${getUnitsText(UNITS_FOR.Diameter)}]`, bha?.lastCasing?.size?.value],
        [`Last Casing', 'Weight [${getUnitsText(UNITS_FOR.UnitWeight)}]`, bha?.lastCasing?.weight?.value],
      ]

      ColDef = [
        { colSpan: 1, headerStyle: styles.fillRightBoldtttt },
        { colSpan: 1, headerStyle: styles.fillRightBoldtttt },
        { colSpan: 1, headerStyle: styles.centertttt },
      ]

      XLSX.utils.sheet_add_aoa(worksheet, CasingData, {
        origin: 'P3',
      })

      worksheet['!merges'].push(...makeMergedCells(ColDef, startRow, 15, CasingData.length))

      let SetDepth = [[`Set Depth [${getUnitsText(UNITS_FOR.Depth)}]`, bha?.setDepth?.value]]

      ColDef = [
        { colSpan: 1, headerStyle: styles.fillRightBoldtttt },
        { colSpan: 1, headerStyle: styles.centertttt },
      ]

      worksheet['!merges'].push(
        mergeCells({
          row: startRow,
          col: 18,
          rowEnd: startRow + 1,
          style: styles.fillCenterBoldtttt,
        }),
        mergeCells({
          row: startRow,
          col: 19,
          rowEnd: startRow + 1,
          style: styles.centertttt,
        }),
      )

      XLSX.utils.sheet_add_aoa(worksheet, SetDepth, {
        origin: 'S3',
      })

      let BHADescription = [['BHA Description', bha?.description]]

      worksheet['!merges'].push(
        mergeCells({
          row: startRow + 2,
          col: 15,
          rowEnd: startRow + 5,
          style: styles.fillCenterBoldtttt,
        }),
        mergeCells({
          row: startRow + 2,
          col: 16,
          colEnd: 19,
          rowEnd: startRow + 5,
          style: styles.centertttt,
        }),
      )

      XLSX.utils.sheet_add_aoa(worksheet, BHADescription, {
        origin: 'P5',
      })

      let DateTime = [
        ['', 'Date Time', ...emptyCells(1)],
        ['Report', bha?.dateTime?.report, ...emptyCells(1)],
        ['BHA In', bha?.dateTime?.BHAIn, ...emptyCells(1)],
        ['BHA Out', bha?.dateTime?.BHAOut, ...emptyCells(1)],
      ]

      ColDef = [
        { colSpan: 1, headerStyle: styles.fillRightBoldtttt },
        { colSpan: 2, headerStyle: styles.centertttt },
      ]

      XLSX.utils.sheet_add_aoa(worksheet, DateTime, {
        origin: 'V3',
      })

      worksheet['!merges'].push(...makeMergedCells(ColDef, startRow, 21, DateTime.length))

      startRow += RigInfo.length + 2

      //--------------------------------------DETAILS---------------------------------------
      data = [['Details']]

      XLSX.utils.sheet_add_aoa(worksheet, data, {
        origin: 'B10',
      })

      let detailHeaders = [
        [
          '#',
          'Description',
          ...emptyCells(4),
          'Vendor (Manufacturer)',
          ...emptyCells(1),
          'Serial #',
          ...emptyCells(1),
          'Material',
          `Dimension [${getUnitsText(UNITS_FOR.Diameter)}]`,
          ...emptyCells(2),
          `Weight [${getUnitsText(UNITS_FOR.Weight)}]`,
          ...emptyCells(1),
          'Connection',
          ...emptyCells(3),
          'Quantity',
          `Length [${getUnitsText(UNITS_FOR.Depth)}]`,
          ...emptyCells(2),
        ],
        [
          ...emptyCells(11),
          'Body',
          ...emptyCells(1),
          'Max',
          ...emptyCells(2),
          'Down',
          ...emptyCells(1),
          'Up',
          ...emptyCells(2),
          'Unit',
          'Total',
          'Cumulative',
        ],
        [
          ...emptyCells(11),
          'OD',
          'ID',
          'OD',
          `[${getUnitsText(UNITS_FOR.Weight)}]`,
          `[${getUnitsText(UNITS_FOR.Weight)}/${getUnitsText(UNITS_FOR.Depth)}]`,
          'Type',
          'Box / Pin',
          'Type',
          'Box / Pin',
          ...emptyCells(4),
        ],
      ]

      worksheet['!merges'].push(
        //#
        mergeCells({
          row: startRow,
          col: startCol,
          rowEnd: startRow + 2,
          style: styles.fillCenterBoldtttt,
        }),
        //Description
        mergeCells({
          row: startRow,
          col: startCol + 1,
          rowEnd: startRow + 2,
          colEnd: startCol + 5,
          style: styles.fillCenterBoldtttt,
        }),
        //Vendor (Manufacturer)
        mergeCells({
          row: startRow,
          col: startCol + 6,
          rowEnd: startRow + 2,
          colEnd: startCol + 7,
          style: styles.fillCenterBoldtttt,
        }),
        //Serial No
        mergeCells({
          row: startRow,
          col: startCol + 8,
          rowEnd: startRow + 2,
          colEnd: startCol + 9,
          style: styles.fillCenterBoldtttt,
        }),
        //Material
        mergeCells({
          row: startRow,
          col: startCol + 10,
          rowEnd: startRow + 2,
          style: styles.fillCenterBoldtttt,
        }),
        //Dimension
        mergeCells({
          row: startRow,
          col: startCol + 11,
          colEnd: startCol + 13,
          style: styles.fillCenterBoldtttt,
        }),
        //Body
        mergeCells({
          row: startRow + 1,
          col: startCol + 11,
          colEnd: startCol + 12,
          style: styles.fillCenterBoldtttt,
        }),
        //Max
        mergeCells({
          row: startRow + 1,
          col: startCol + 13,
          style: styles.fillCenterBoldtttt,
        }),
        //OD
        mergeCells({
          row: startRow + 2,
          col: startCol + 11,
          style: styles.fillCenterBoldtttt,
        }),
        //ID
        mergeCells({
          row: startRow + 2,
          col: startCol + 12,
          style: styles.fillCenterBoldtttt,
        }),
        //OD
        mergeCells({
          row: startRow + 2,
          col: startCol + 13,
          style: styles.fillCenterBoldtttt,
        }),
        //Weight
        mergeCells({
          row: startRow,
          col: startCol + 14,
          rowEnd: startRow + 1,
          colEnd: startCol + 15,
          style: styles.fillCenterBoldtttt,
        }),
        //KLBS
        mergeCells({
          row: startRow + 2,
          col: startCol + 14,
          style: styles.fillCenterBoldtttt,
        }),
        //LBS/FT
        mergeCells({
          row: startRow + 2,
          col: startCol + 15,
          style: styles.fillCenterBoldtttt,
        }),
        //Connection
        mergeCells({
          row: startRow,
          col: startCol + 16,
          colEnd: startCol + 19,
          style: styles.fillCenterBoldtttt,
        }),
        //Down
        mergeCells({
          row: startRow + 1,
          col: startCol + 16,
          colEnd: startCol + 17,
          style: styles.fillCenterBoldtttt,
        }),
        //Type
        mergeCells({
          row: startRow + 2,
          col: startCol + 16,
          style: styles.fillCenterBoldtttt,
        }),
        //Box / Pin
        mergeCells({
          row: startRow + 2,
          col: startCol + 17,
          style: styles.fillCenterBoldtttt,
        }),
        //Up
        mergeCells({
          row: startRow + 1,
          col: startCol + 18,
          colEnd: startCol + 19,
          style: styles.fillCenterBoldtttt,
        }),
        //Type
        mergeCells({
          row: startRow + 2,
          col: startCol + 18,
          style: styles.fillCenterBoldtttt,
        }),
        //Box / Pin
        mergeCells({
          row: startRow + 2,
          col: startCol + 19,
          style: styles.fillCenterBoldtttt,
        }),
        //Quantity
        mergeCells({
          row: startRow,
          col: startCol + 20,
          rowEnd: startRow + 2,
          style: styles.fillCenterBoldtttt,
        }),
        //Length
        mergeCells({
          row: startRow,
          col: startCol + 21,
          colEnd: startCol + 23,
          style: styles.fillCenterBoldtttt,
        }),
        //Unit
        mergeCells({
          row: startRow + 1,
          col: startCol + 21,
          rowEnd: startRow + 2,
          style: styles.fillCenterBoldtttt,
        }),
        //Total
        mergeCells({
          row: startRow + 1,
          col: startCol + 22,
          rowEnd: startRow + 2,
          style: styles.fillCenterBoldtttt,
        }),
        //Cumulative
        mergeCells({
          row: startRow + 1,
          col: startCol + 23,
          rowEnd: startRow + 2,
          style: styles.fillCenterBoldtttt,
        }),
      )

      XLSX.utils.sheet_add_aoa(worksheet, detailHeaders, {
        origin: 'B11',
      })

      startRow += detailHeaders.length

      let components = bha?.components ? bha?.components : []

      let details = []

      for (let i = 0; i < components.length; i++) {
        let component = components[i]
        details.push([
          component?.sequence,
          component?.description,
          ...emptyCells(4),
          component?.vendor,
          ...emptyCells(1),
          component?.serialNo,
          ...emptyCells(1),
          component?.typeMaterial,
          `${numberWithCommasDecimals(component?.od, 3)}`,
          `${numberWithCommasDecimals(component?.id, 3)}`,
          `${numberWithCommasDecimals(component?.odMx, 3)}`,
          component?.weight?.value,
          component?.wtPerLen?.value,
          component?.connectionTypeDown,
          component?.connectionDown,
          component?.connectionTypeTop,
          component?.connectionTop,
          component?.quantity,
          component?.unitLength?.value,
          component?.totalLength?.value,
          component?.cumulativeLength?.value,
        ])
      }

      for (let i = details.length; i < 21; i++) {
        details.push([i + 1, emptyCells(23)])
      }
      ColDef = [
        { colSpan: 1, headerStyle: styles.fillRightBoldtttt },
        { colSpan: 5, headerStyle: styles.centertttt },
        { colSpan: 2, headerStyle: styles.centertttt },
        { colSpan: 2, headerStyle: styles.centertttt },
        { colSpan: 1, headerStyle: styles.centertttt },
        { colSpan: 1, headerStyle: styles.centertttt },
        { colSpan: 1, headerStyle: styles.centertttt },
        { colSpan: 1, headerStyle: styles.centertttt },
        { colSpan: 1, headerStyle: styles.centertttt },
        { colSpan: 1, headerStyle: styles.centertttt },
        { colSpan: 1, headerStyle: styles.centertttt },
        { colSpan: 1, headerStyle: styles.centertttt },
        { colSpan: 1, headerStyle: styles.centertttt },
        { colSpan: 1, headerStyle: styles.centertttt },
        { colSpan: 1, headerStyle: styles.centertttt },
        { colSpan: 1, headerStyle: styles.centertttt },
        { colSpan: 1, headerStyle: styles.centertttt },
        { colSpan: 1, headerStyle: styles.centertttt },
      ]

      XLSX.utils.sheet_add_aoa(worksheet, details, {
        origin: 'B14',
      })

      worksheet['!merges'].push(...makeMergedCells(ColDef, startRow, startCol, details.length))

      startRow += details.length + 2

      //--------------------------------------POSITIVE DISPLACEMENT MOTOR---------------------------------------
      data = [['Positive Displacement Motor']]

      ColDef = [{ colSpan: 7, headerStyle: styles.fillCenterBoldtttt }]

      XLSX.utils.sheet_add_aoa(worksheet, data, {
        origin: 'B37',
      })

      worksheet['!merges'].push(...makeMergedCells(ColDef, startRow, startCol, data.length))

      startRow += 1

      let PDMotor = bha?.positiveDisplacementMotor ? bha?.positiveDisplacementMotor : []

      let positiveDisplacementMotor = [
        [`Size (body OD) [${getUnitsText(UNITS_FOR.Diameter)}]`, ...emptyCells(4), PDMotor?.od?.value, ...emptyCells(1)],
        [
          'Lobe Configuration (rotor/stator)',
          ...emptyCells(4),
          `${PDMotor?.lobesRotor}`,
          ...emptyCells(1),
        ],
        ['Stages', ...emptyCells(4), PDMotor?.stages, ...emptyCells(1)],
        ['Speed Ratio [rev/gal]', ...emptyCells(4), PDMotor?.speedRatio?.value, ...emptyCells(1)],
        ['Flow Rate Range [gpm]', ...emptyCells(4), PDMotor?.flowrateMn?.value, PDMotor?.flowrateMx?.value],
        ['Speed Range [rpm]', ...emptyCells(4), PDMotor?.speedMn?.value, PDMotor?.speedMx?.value],
        [
          'Maximum',
          ...emptyCells(1),
          'Differential Pressure [psi]',
          ...emptyCells(2),
          PDMotor?.differentialPressureMx?.value,
          ...emptyCells(1),
        ],
        ['Maximum', ...emptyCells(1), `Torque [${getUnitsText(UNITS_FOR.Torque)}]`, ...emptyCells(2), PDMotor?.torqueMx?.value, ...emptyCells(1)],
        [
          'Stall',
          ...emptyCells(1),
          'Differential Pressure [psi]',
          ...emptyCells(2),
          PDMotor?.differentialPressureStall?.value,
          ...emptyCells(1),
        ],
        ['Stall', ...emptyCells(1), `Torque [${getUnitsText(UNITS_FOR.Torque)}]`, ...emptyCells(2), PDMotor?.torqueStall?.value, ...emptyCells(1)],
        ['Bend Angle [deg]', ...emptyCells(4), PDMotor?.bendAngle?.value, ...emptyCells(1)],
        [`Weight [${getUnitsText(UNITS_FOR.Weight)}]`, ...emptyCells(4), numberWithCommasDecimals(PDMotor?.weight?.value, 2), ...emptyCells(1)],
      ]

      ColDef = [
        { colSpan: 5, headerStyle: styles.fillRightBoldtttt },
        { colSpan: 2, headerStyle: styles.centertttt },
      ]

      worksheet['!merges'].push(...makeMergedCells(ColDef, startRow, startCol, 4))
      worksheet['!merges'].push(
        //Flow Rate Range
        mergeCells({
          row: startRow + 4,
          col: startCol,
          colEnd: startCol + 4,
          style: styles.fillCenterBoldtttt,
        }),
        //Flow Rate Mn
        mergeCells({
          row: startRow + 4,
          col: startCol + 5,
          style: styles.centertttt,
        }),
        //Flow Rate Mx
        mergeCells({
          row: startRow + 4,
          col: startCol + 6,
          style: styles.centertttt,
        }),
        //Speed Range
        mergeCells({
          row: startRow + 5,
          col: startCol,
          colEnd: startCol + 4,
          style: styles.fillCenterBoldtttt,
        }),
        //Speed Mn
        mergeCells({
          row: startRow + 5,
          col: startCol + 5,
          style: styles.centertttt,
        }),
        //Speed Mx
        mergeCells({
          row: startRow + 5,
          col: startCol + 6,
          style: styles.centertttt,
        }),
        //Maximum
        mergeCells({
          row: startRow + 6,
          col: startCol,
          colEnd: startCol + 1,
          style: styles.fillCenterBoldtttt,
        }),
        //Differential Pressure
        mergeCells({
          row: startRow + 6,
          col: startCol + 2,
          colEnd: startCol + 4,
          style: styles.fillCenterBoldtttt,
        }),
        //Differential Pressure Mx
        mergeCells({
          row: startRow + 6,
          col: startCol + 5,
          colEnd: startCol + 6,
          style: styles.centertttt,
        }),
        //Maximum
        mergeCells({
          row: startRow + 7,
          col: startCol,
          colEnd: startCol + 1,
          style: styles.fillCenterBoldtttt,
        }),
        //Torque
        mergeCells({
          row: startRow + 7,
          col: startCol + 2,
          colEnd: startCol + 4,
          style: styles.fillCenterBoldtttt,
        }),
        //Torque Mx
        mergeCells({
          row: startRow + 7,
          col: startCol + 5,
          colEnd: startCol + 6,
          style: styles.centertttt,
        }),
        //Stall
        mergeCells({
          row: startRow + 8,
          col: startCol,
          colEnd: startCol + 1,
          style: styles.fillCenterBoldtttt,
        }),
        //Differential Pressure
        mergeCells({
          row: startRow + 8,
          col: startCol + 2,
          colEnd: startCol + 4,
          style: styles.fillCenterBoldtttt,
        }),
        //Differential Pressure Stall
        mergeCells({
          row: startRow + 8,
          col: startCol + 5,
          colEnd: startCol + 6,
          style: styles.centertttt,
        }),
        //Stall
        mergeCells({
          row: startRow + 9,
          col: startCol,
          colEnd: startCol + 1,
          style: styles.fillCenterBoldtttt,
        }),
        //Torque
        mergeCells({
          row: startRow + 9,
          col: startCol + 2,
          colEnd: startCol + 4,
          style: styles.fillCenterBoldtttt,
        }),
        //Torque Stall
        mergeCells({
          row: startRow + 9,
          col: startCol + 5,
          colEnd: startCol + 6,
          style: styles.centertttt,
        }),
      )
      worksheet['!merges'].push(...makeMergedCells(ColDef, startRow + 10, startCol, 2))

      XLSX.utils.sheet_add_aoa(worksheet, positiveDisplacementMotor, {
        origin: 'B38',
      })

      //--------------------------------------PDM Rotor---------------------------------------
      startRow -= 1
      data = [['PDM Rotor']]

      ColDef = [{ colSpan: 6, headerStyle: styles.fillCenterBoldtttt }]

      XLSX.utils.sheet_add_aoa(worksheet, data, {
        origin: 'J37',
      })

      worksheet['!merges'].push(...makeMergedCells(ColDef, startRow, 9, data.length))

      startRow += 1

      let PDMRotor = bha?.PDMRotor ? bha?.PDMRotor : []

      let pdmRotor = [
        [
          `Length [${getUnitsText(UNITS_FOR.Diameter)}]`,
          ...emptyCells(1),
          'Overall',
          ...emptyCells(1),
          PDMRotor?.lengthOverall?.value,
          ...emptyCells(1),
        ],
        [
          `Length [${getUnitsText(UNITS_FOR.Diameter)}]`,
          ...emptyCells(1),
          'Contour',
          ...emptyCells(1),
          PDMRotor?.lengthContour?.value,
          ...emptyCells(1),
        ],
        [
          `Diameter [${getUnitsText(UNITS_FOR.Diameter)}]`,
          ...emptyCells(1),
          'Major',
          ...emptyCells(1),
          PDMRotor?.diameterMajor?.value,
          ...emptyCells(1),
        ],
        [
          `Diameter [${getUnitsText(UNITS_FOR.Diameter)}]`,
          ...emptyCells(1),
          'Minor',
          ...emptyCells(1),
          PDMRotor?.diameterMinor?.value,
          ...emptyCells(1),
        ],
        [
          `Diameter [${getUnitsText(UNITS_FOR.Diameter)}]`,
          ...emptyCells(1),
          'Bypass',
          ...emptyCells(1),
          PDMRotor?.diameterBypass?.value,
          ...emptyCells(1),
        ],
        [`Eccentricity [${getUnitsText(UNITS_FOR.Diameter)}]`, ...emptyCells(3), PDMRotor?.eccentricity?.value, ...emptyCells(1)],
        ['Weight [lbs]', ...emptyCells(3), PDMRotor?.weight?.value, ...emptyCells(1)],
        ['Base Material', ...emptyCells(3), PDMRotor?.baseMaterial, ...emptyCells(1)],
        ['Coating', ...emptyCells(3), PDMRotor?.coating, ...emptyCells(1)],
        ['Catcher', ...emptyCells(3), PDMRotor?.catcher, ...emptyCells(1)],
      ]

      ColDef = [
        { colSpan: 2, headerStyle: styles.fillRightBoldtttt },
        { colSpan: 2, headerStyle: styles.fillRightBoldtttt },
        { colSpan: 2, headerStyle: styles.centertttt },
      ]

      worksheet['!merges'].push(...makeMergedCells(ColDef, startRow, 9, 5))

      ColDef = [
        { colSpan: 4, headerStyle: styles.fillRightBoldtttt },
        { colSpan: 2, headerStyle: styles.centertttt },
      ]

      worksheet['!merges'].push(...makeMergedCells(ColDef, startRow + 5, 9, 5))

      XLSX.utils.sheet_add_aoa(worksheet, pdmRotor, {
        origin: 'J38',
      })

      //--------------------------------------OFFSET DISTANCE FROM BIT---------------------------------------
      startRow -= 1
      data = [['Offset Distance from Bit']]

      ColDef = [{ colSpan: 5, headerStyle: styles.fillCenterBoldtttt }]

      XLSX.utils.sheet_add_aoa(worksheet, data, {
        origin: 'Q37',
      })

      worksheet['!merges'].push(...makeMergedCells(ColDef, startRow, 16, data.length))

      startRow += 1

      let offsetDistanceFromBit = bha?.OffsetDistanceFromBit ? bha?.OffsetDistanceFromBit : []

      let bendStabilizerHeaders = [
        ['Bend, Stabilizer, Pad, ...', ...emptyCells(2), `Distance from Bit [${getUnitsText(UNITS_FOR.Depth)}]`, `Blade or Pad Length [${getUnitsText(UNITS_FOR.Diameter)}]`],
      ]

      worksheet['!merges'].push(
        //Bend, Stabilizer, Pad, ...
        mergeCells({
          row: startRow,
          col: 16,
          rowEnd: startRow + 1,
          colEnd: 18,
          style: styles.fillCenterBoldtttt,
        }),
        //Distance from Bit
        mergeCells({
          row: startRow,
          col: 19,
          rowEnd: startRow + 1,
          style: styles.fillCenterBoldtttt,
        }),
        //Blade or Pad Length
        mergeCells({
          row: startRow,
          col: 20,
          rowEnd: startRow + 1,
          style: styles.fillCenterBoldtttt,
        }),
      )

      XLSX.utils.sheet_add_aoa(worksheet, bendStabilizerHeaders, {
        origin: 'Q38',
      })

      let bendStabilizers = offsetDistanceFromBit?.bendStabilizerPad ? offsetDistanceFromBit?.bendStabilizerPad : []

      let stabilizerData = bha?.stabilizer ? bha?.stabilizer : []

      for (let i = stabilizerData.length; i < 5; i++) {
        stabilizerData.push({
          distanceFromBit: '',
          bladeOrPadLength: '',
        })
      }

      let bendStabs = [
        [
          'Motor Bend',
          ...emptyCells(2),
          numberWithCommasDecimals(bendStabilizers?.distanceFromBit?.motorBend?.value, 2),
          numberWithCommasDecimals(bendStabilizers?.bladeOrPadLength?.motorBend?.value, 2),
        ],
        [
          'Motor Wear Pad*',
          ...emptyCells(2),
          numberWithCommasDecimals(bendStabilizers?.distanceFromBit?.motorWearPad?.value),
          numberWithCommasDecimals(bendStabilizers?.bladeOrPadLength?.motorWearPad?.value),
        ],
        ['OD Stabilizer 1', ...emptyCells(2), numberWithCommasDecimals(getStabToBitDistance(bha.components, stabilizerData, 0)), numberWithCommasDecimals(stabilizerData[0]?.bladeOrPadLength?.value)],
        ['OD Stabilizer 2', ...emptyCells(2), numberWithCommasDecimals(getStabToBitDistance(bha.components, stabilizerData, 1)), numberWithCommasDecimals(stabilizerData[1]?.bladeOrPadLength?.value)],
        ['OD Stabilizer 3', ...emptyCells(2), numberWithCommasDecimals(getStabToBitDistance(bha.components, stabilizerData, 2)), numberWithCommasDecimals(stabilizerData[2]?.bladeOrPadLength?.value)],
        ['OD Stabilizer 4', ...emptyCells(2), numberWithCommasDecimals(getStabToBitDistance(bha.components, stabilizerData, 3)), numberWithCommasDecimals(stabilizerData[3]?.bladeOrPadLength?.value)],
        ['OD Stabilizer 5', ...emptyCells(2), numberWithCommasDecimals(getStabToBitDistance(bha.components, stabilizerData, 4)), numberWithCommasDecimals(stabilizerData[4]?.bladeOrPadLength?.value)],
        [
          'RSS Push pad (for Push the Bit)*',
          ...emptyCells(2),
          numberWithCommasDecimals(bendStabilizers?.distanceFromBit?.RSSPushpad?.value),
          numberWithCommasDecimals(bendStabilizers?.bladeOrPadLength?.RSSPushpad?.value),
        ],
        [
          'RSS Fulcrum (for Point the Bit)',
          ...emptyCells(2),
          numberWithCommasDecimals(bendStabilizers?.distanceFromBit?.RSSFulcrum?.value, 2),
          numberWithCommasDecimals(bendStabilizers?.bladeOrPadLength?.RSSFulcrum?.value, 2),
        ],
      ]

      ColDef = [
        { colSpan: 3, headerStyle: styles.fillRightBoldtttt },
        { colSpan: 1, headerStyle: styles.centertttt },
        { colSpan: 1, headerStyle: styles.centertttt },
      ]

      worksheet['!merges'].push(...makeMergedCells(ColDef, startRow + 2, 16, bendStabs.length))

      XLSX.utils.sheet_add_aoa(worksheet, bendStabs, {
        origin: 'Q40',
      })

      let SensorHeaders = [['Sensor', ...emptyCells(4)]]

      worksheet['!merges'].push(
        //Sensor
        mergeCells({
          row: startRow + 1 + bendStabs.length + 1,
          col: 16,
          colEnd: 18,
          style: styles.fillCenterBoldtttt,
        }),
        //Blank
        mergeCells({
          row: startRow + 1 + bendStabs.length + 1,
          col: 19,
          colEnd: 20,
          style: styles.fillCenterBoldtttt,
        }),
        //Blank
        mergeCells({
          row: startRow + 1 + bendStabs.length + 2,
          col: 20,
          rowEnd: startRow + 1 + bendStabs.length + 9,
          style: styles.fillCenterBoldtttt,
        }),
      )

      XLSX.utils.sheet_add_aoa(worksheet, SensorHeaders, {
        origin: 'Q49',
      })

      let bitSensors = offsetDistanceFromBit?.sensors ? offsetDistanceFromBit?.sensors : []

      let sensors = [
        [
          'Near Bit',
          'Directional & Inclination',
          ...emptyCells(1),
          numberWithCommasDecimals(bitSensors?.nearBit?.directionalInclination?.value, 2),
          ...emptyCells(1),
        ],
        ['Near Bit', 'Gamma Ray', ...emptyCells(1), bitSensors?.nearBit?.gammaRay?.value, ...emptyCells(1)],
        [
          'MWD/LWD',
          'Directional & Inclination',
          ...emptyCells(1),
          numberWithCommasDecimals(bitSensors?.MWDLWD?.directionalInclination?.value, 2),
          ...emptyCells(1),
        ],
        ['MWD/LWD', 'Dynamic (WOB, Tq, Accel)', ...emptyCells(1), numberWithCommasDecimals(bitSensors?.MWDLWD?.dynamic?.value), ...emptyCells(1)], //ask Dan
        ['MWD/LWD', 'Gamma Ray', ...emptyCells(1), numberWithCommasDecimals(bitSensors?.MWDLWD?.gammaRay?.value), ...emptyCells(1)],
        ['MWD/LWD', 'Resistivity', ...emptyCells(1), numberWithCommasDecimals(bitSensors?.MWDLWD?.resistivity?.value), ...emptyCells(1)],
        ['MWD/LWD', 'Neutron / Density', ...emptyCells(1), numberWithCommasDecimals(bitSensors?.MWDLWD?.neutronDensity?.value), ...emptyCells(1)],
        ['MWD/LWD', 'Acoustic', ...emptyCells(1), numberWithCommasDecimals(bitSensors?.MWDLWD?.acoustic?.value), ...emptyCells(1)],
      ]

      ColDef = [
        { colSpan: 1, headerStyle: styles.fillRightBoldtttt },
        { colSpan: 2, headerStyle: styles.fillRightBoldtttt },
        { colSpan: 1, headerStyle: styles.centertttt },
      ]

      worksheet['!merges'].push(...makeMergedCells(ColDef, startRow + 1 + bendStabs.length + 2, 16, sensors.length))

      XLSX.utils.sheet_add_aoa(worksheet, sensors, {
        origin: 'Q50',
      })

      //--------------------------------------FISHING INFORMATION---------------------------------------
      startRow -= 1

      data = [['Fishing Information']]
      ColDef = [{ colSpan: 3, headerStyle: styles.fillCenterBoldtttt }]

      XLSX.utils.sheet_add_aoa(worksheet, data, {
        origin: 'W37',
      })

      worksheet['!merges'].push(...makeMergedCells(ColDef, startRow, 22, data.length))

      startRow += 1

      let fishingInformation = bha?.fishingInformation ? bha?.fishingInformation : []

      let fishingHeaders = [['#', 'Dimensions & Descriptions', ...emptyCells(1)]]

      ColDef = [
        { colSpan: 1, headerStyle: styles.fillRightBoldtttt },
        { colSpan: 2, headerStyle: styles.fillRightBoldtttt },
      ]

      worksheet['!merges'].push(...makeMergedCells(ColDef, startRow, 22, fishingHeaders.length))

      XLSX.utils.sheet_add_aoa(worksheet, fishingHeaders, {
        origin: 'W38',
      })

      let fishing = []

      for (let i = 0; i < fishingInformation.length; i++) {
        let fish = fishingInformation[i]
        fishing.push([fish?.no, fish?.dimensionsDescriptions, ...emptyCells(1)])
      }

      for (let i = fishing.length; i < 7; i++) {
        fishing.push([emptyCells(3)])
      }

      for (let i = 0; i < fishing.length; i++) {
        worksheet['!merges'].push(
          //#
          mergeCells({
            row: startRow + 1 + i * 2,
            col: 22,
            rowEnd: startRow + 2 + i * 2,
            style: styles.centertttt,
          }),
          //Description & Description
          mergeCells({
            row: startRow + 1 + i * 2,
            col: 23,
            rowEnd: startRow + 2 + i * 2,
            colEnd: 24,
            style: styles.centertttt,
          }),
        )
      }

      XLSX.utils.sheet_add_aoa(worksheet, fishing, {
        origin: 'W39',
      })

      //--------------------------------------Fit Information---------------------------------------
      startRow = 50

      data = [[`Fit Information (minor diameter) [${getUnitsText(UNITS_FOR.Diameter)}]`]]

      worksheet['!merges'].push(
        //Fit Information
        mergeCells({
          row: startRow,
          col: 1,
          rowEnd: startRow + 1,
          colEnd: 3,
          style: styles.fillCenterBoldtttt,
        }),
      )

      XLSX.utils.sheet_add_aoa(worksheet, data, {
        origin: 'B51',
      })

      let fitInformation = bha?.fitInformation ? bha?.fitInformation : []

      let fits = [
        ['Standard', ...emptyCells(1), fitInformation?.standard?.value],
        ['Oversized', ...emptyCells(1), fitInformation?.oversized?.value],
        ['Undersized', ...emptyCells(1), fitInformation?.undersized?.value],
      ]

      ColDef = [
        { colSpan: 2, headerStyle: styles.fillRightBoldtttt },
        { colSpan: 1, headerStyle: styles.centertttt },
      ]

      worksheet['!merges'].push(...makeMergedCells(ColDef, startRow + 2, 1, fits.length))

      XLSX.utils.sheet_add_aoa(worksheet, fits, {
        origin: 'B53',
      })

      //--------------------------------------Nominal Fit---------------------------------------
      data = [[`Nominal Fit at ambient temperature(~70°F or ~25°C) [${getUnitsText(UNITS_FOR.Diameter)}]`]]

      worksheet['!merges'].push(
        //Nominal Fit
        mergeCells({
          row: startRow,
          col: 5,
          rowEnd: startRow + 1,
          colEnd: 7,
          style: styles.fillCenterBoldtttt,
        }),
      )

      XLSX.utils.sheet_add_aoa(worksheet, data, {
        origin: 'F51',
      })

      let nominalFit = bha?.nominalFitAtAmbientTemperature ? bha?.nominalFitAtAmbientTemperature : []

      fits = [
        ['Standard', ...emptyCells(1), nominalFit?.standard?.value],
        ['Oversized', ...emptyCells(1), nominalFit?.oversized?.value],
        ['Undersized', ...emptyCells(1), nominalFit?.undersized?.value],
      ]

      worksheet['!merges'].push(...makeMergedCells(ColDef, startRow + 2, 5, fits.length))

      XLSX.utils.sheet_add_aoa(worksheet, fits, {
        origin: 'F53',
      })

      //--------------------------------------PDM Stator---------------------------------------
      startRow = 48
      data = [['PDM Stator']]

      ColDef = [{ colSpan: 6, headerStyle: styles.fillCenterBoldtttt }]

      XLSX.utils.sheet_add_aoa(worksheet, data, {
        origin: 'J49',
      })

      worksheet['!merges'].push(...makeMergedCells(ColDef, startRow, 9, data.length))

      startRow += 1

      let PDMStator = bha?.PDMStator ? bha?.PDMStator : []

      let pdmStators = [
        ['Type (conv. or even walled)', ...emptyCells(3), PDMStator?.type, ...emptyCells(1)],
        [`Overall Length [${getUnitsText(UNITS_FOR.Diameter)}]`, ...emptyCells(3), PDMStator?.overallLength?.value, ...emptyCells(1)],
        [`Rubber Cut Back [${getUnitsText(UNITS_FOR.Diameter)}]`, ...emptyCells(3), PDMStator?.rubberCutBack?.value, ...emptyCells(1)],
        [`Tube OD [${getUnitsText(UNITS_FOR.Diameter)}]`, ...emptyCells(3), PDMStator?.tubeOD?.value, ...emptyCells(1)],
        [`Tube ID [${getUnitsText(UNITS_FOR.Diameter)}]`, ...emptyCells(3), PDMStator?.tubeID?.value, ...emptyCells(1)],
        ['Weight [lbs]', ...emptyCells(3), PDMStator?.weight?.value, ...emptyCells(1)],
        ['Elastomer Type', ...emptyCells(3), PDMStator?.elastomerType, ...emptyCells(1)],
        ['Tube Material', ...emptyCells(3), PDMStator?.tubeMaterial, ...emptyCells(1)],
        ['Hardness (Shore A)', ...emptyCells(3), PDMStator?.hardness, ...emptyCells(1)],
      ]

      ColDef = [
        { colSpan: 4, headerStyle: styles.fillRightBoldtttt },
        { colSpan: 2, headerStyle: styles.centertttt },
      ]

      worksheet['!merges'].push(...makeMergedCells(ColDef, startRow, 9, pdmStators.length))

      XLSX.utils.sheet_add_aoa(worksheet, pdmStators, {
        origin: 'J50',
      })

      //--------------------------------------RSS---------------------------------------
      startRow = 56
      data = [['Rotary Steerable System (RSS)']]

      ColDef = [{ colSpan: 7, headerStyle: styles.fillCenterBoldtttt }]

      XLSX.utils.sheet_add_aoa(worksheet, data, {
        origin: 'B57',
      })

      worksheet['!merges'].push(...makeMergedCells(ColDef, startRow, 1, data.length))

      startRow += 1

      let RSS = bha?.rotarySteerableSystem ? bha?.rotarySteerableSystem : []

      let rss = [
        ['Type (Point / Push / Hybrid)', ...emptyCells(4), RSS?.deflectionMethod, ...emptyCells(1)], //ask Dan
        [`Body OD [${getUnitsText(UNITS_FOR.Diameter)}]`, ...emptyCells(4), RSS?.od?.value, ...emptyCells(1)],
        ['Operating Flow Rate [gmp]', ...emptyCells(4), RSS?.operatingFlowRate?.value, ...emptyCells(1)],
        ['Downlink Flow Rate [gmp]', ...emptyCells(4), RSS?.downlinkFlowRateMx?.value, ...emptyCells(1)],
        [
          'Maximum',
          ...emptyCells(1),
          'Dogleg Serverity [deg/100ft]',
          ...emptyCells(2),
          RSS?.doglegServerityMx?.value,
          ...emptyCells(1),
        ],
        ['Maximum', ...emptyCells(1), 'Weight On Bit [klbf]', ...emptyCells(2), RSS?.wobMx?.value, ...emptyCells(1)],
        ['Maximum', ...emptyCells(1), 'Rotating Speed [rpm]', ...emptyCells(2), RSS?.speedMx?.value, ...emptyCells(1)],
        ['Motor assist [yes/no]', ...emptyCells(4), RSS?.motorAssist, ...emptyCells(1)],
      ]

      ColDef = [
        { colSpan: 5, headerStyle: styles.fillRightBoldtttt },
        { colSpan: 2, headerStyle: styles.centertttt },
      ]

      worksheet['!merges'].push(...makeMergedCells(ColDef, startRow, 1, rss.length - 4))

      worksheet['!merges'].push(
        //Maximum
        mergeCells({
          row: startRow + 4,
          col: 1,
          colEnd: 2,
          style: styles.fillCenterBoldtttt,
        }),
        //Dogleg Severity
        mergeCells({
          row: startRow + 4,
          col: 3,
          colEnd: 5,
          style: styles.fillCenterBoldtttt,
        }),
        //Dogleg Severity Value
        mergeCells({
          row: startRow + 4,
          col: 6,
          colEnd: 7,
          style: styles.centertttt,
        }),
        //Maximum
        mergeCells({
          row: startRow + 5,
          col: 1,
          colEnd: 2,
          style: styles.fillCenterBoldtttt,
        }),
        //Weight On Bit
        mergeCells({
          row: startRow + 5,
          col: 3,
          colEnd: 5,
          style: styles.fillCenterBoldtttt,
        }),
        //Weight On Bit Value
        mergeCells({
          row: startRow + 5,
          col: 6,
          colEnd: 7,
          style: styles.centertttt,
        }),
        //Maximum
        mergeCells({
          row: startRow + 6,
          col: 1,
          colEnd: 2,
          style: styles.fillCenterBoldtttt,
        }),
        //Rotating Speed
        mergeCells({
          row: startRow + 6,
          col: 3,
          colEnd: 5,
          style: styles.fillCenterBoldtttt,
        }),
        //Rotating Speed Value
        mergeCells({
          row: startRow + 6,
          col: 6,
          colEnd: 7,
          style: styles.centertttt,
        }),
      )

      worksheet['!merges'].push(...makeMergedCells(ColDef, startRow + 7, 1, 1))

      XLSX.utils.sheet_add_aoa(worksheet, rss, {
        origin: 'B58',
      })

      //--------------------------------------Drill Bit---------------------------------------
      startRow = 59
      data = [['Drill Bit']]

      ColDef = [{ colSpan: 6, headerStyle: styles.fillCenterBoldtttt }]

      XLSX.utils.sheet_add_aoa(worksheet, data, {
        origin: 'J60',
      })

      worksheet['!merges'].push(...makeMergedCells(ColDef, startRow, 9, data.length))

      startRow += 1

      let drillBit = bha?.drillBit ? bha?.drillBit : []

      let bit = [
        ['Type', ...emptyCells(3), drillBit?.typeBit, ...emptyCells(1)],
        ['# Blades', ...emptyCells(3), drillBit?.noBlades, ...emptyCells(1)],
        ['Cutter Size [mm]', ...emptyCells(3), drillBit?.cutterSizeID?.value, drillBit?.cutterSizeOD?.value],
        ['IADC Dull Grade', ...emptyCells(2), 'In', drillBit?.IADCDullGradeIn, ...emptyCells(1)],
        ['IADC Dull Grade', ...emptyCells(2), 'Out', drillBit?.IADCDullGradeOut, ...emptyCells(1)],
      ]

      ColDef = [
        { colSpan: 4, headerStyle: styles.fillRightBoldtttt },
        { colSpan: 2, headerStyle: styles.centertttt },
      ]

      worksheet['!merges'].push(...makeMergedCells(ColDef, startRow, 9, 2))

      worksheet['!merges'].push(
        //Cutter Size
        mergeCells({
          row: startRow + 2,
          col: 9,
          colEnd: 12,
          style: styles.fillCenterBoldtttt,
        }),
        //Cutter Size ID
        mergeCells({
          row: startRow + 2,
          col: 13,
          style: styles.centertttt,
        }),
        //Cutter Size OD
        mergeCells({
          row: startRow + 2,
          col: 14,
          style: styles.centertttt,
        }),
        //IADC Dull Grade
        mergeCells({
          row: startRow + 3,
          col: 9,
          colEnd: 11,
          style: styles.fillCenterBoldtttt,
        }),
        //IADC Dull Grade In
        mergeCells({
          row: startRow + 3,
          col: 12,
          style: styles.centertttt,
        }),
        //IADC Dull Grade In Value
        mergeCells({
          row: startRow + 3,
          col: 13,
          colEnd: 14,
          style: styles.centertttt,
        }),
        //IADC Dull Grade
        mergeCells({
          row: startRow + 4,
          col: 9,
          colEnd: 11,
          style: styles.fillCenterBoldtttt,
        }),
        //IADC Dull Grade Out
        mergeCells({
          row: startRow + 4,
          col: 12,
          style: styles.centertttt,
        }),
        //IADC Dull Grade Out Value
        mergeCells({
          row: startRow + 4,
          col: 13,
          colEnd: 14,
          style: styles.centertttt,
        }),
      )

      XLSX.utils.sheet_add_aoa(worksheet, bit, {
        origin: 'J61',
      })

      //--------------------------------------Nozzle---------------------------------------
      startRow = 59
      data = [
        ['Nozzle', ...emptyCells(8)],
        ['#', 'Item', ...emptyCells(1), 'Nozzles', ...emptyCells(4), 'TFA [in²]'],
      ]

      ColDef = [
        { colSpan: 1, headerStyle: styles.fillCenterBoldtttt },
        { colSpan: 1, headerStyle: styles.fillCenterBoldtttt },
        { colSpan: 1, headerStyle: styles.fillCenterBoldtttt },
        { colSpan: 1, headerStyle: styles.fillCenterBoldtttt },
        { colSpan: 1, headerStyle: styles.fillCenterBoldtttt },
        { colSpan: 1, headerStyle: styles.fillCenterBoldtttt },
        { colSpan: 1, headerStyle: styles.fillCenterBoldtttt },
        { colSpan: 1, headerStyle: styles.fillCenterBoldtttt },
        { colSpan: 1, headerStyle: styles.fillCenterBoldtttt },
      ]

      XLSX.utils.sheet_add_aoa(worksheet, data, {
        origin: 'Q60',
      })

      worksheet['!merges'].push(...makeMergedCells(ColDef, startRow, 16, data.length))

      startRow += 2

      let nozzle = bha?.Nozzle ? bha?.Nozzle : []

      let nozzles = []

      for (let i = 0; i < nozzle.length; i++) {
        let noz = nozzle[i]
        let nozData = noz?.nozzles ? noz?.nozzles : []
        nozzles.push(
          [noz?.No, noz?.item, 'Count', nozData[0]?.count, nozData[1]?.count, ...emptyCells(3), noz?.TFA?.value],
          [...emptyCells(2), 'Size[1/32 in]', nozData[0]?.size?.value, nozData[1]?.size?.value, ...emptyCells(4)],
        )
      }

      for (let i = nozzles.length; i < 6; i++) {
        nozzles.push([emptyCells(9)], [emptyCells(9)])
      }

      for (let i = 0; i < 3; i++) {
        worksheet['!merges'].push(
          //#
          mergeCells({
            row: startRow + i * 2,
            col: 16,
            rowEnd: startRow + 1 + i * 2,
            style: styles.centertttt,
          }),
          //Item
          mergeCells({
            row: startRow + i * 2,
            col: 17,
            style: styles.fillCenterBoldtttt,
          }),
          //Item Empty
          mergeCells({
            row: startRow + i * 2 + 1,
            col: 17,
            style: styles.fillCenterBoldtttt,
          }),
          //Count
          mergeCells({
            row: startRow + i * 2,
            col: 18,
            style: styles.fillCenterBoldtttt,
          }),
          //Count Value 1
          mergeCells({
            row: startRow + i * 2,
            col: 19,
            style: styles.centertttt,
          }),
          //Count Value 2
          mergeCells({
            row: startRow + i * 2,
            col: 20,
            style: styles.centertttt,
          }),
          //Size [1/32 in]
          mergeCells({
            row: startRow + 1 + i * 2,
            col: 18,
            style: styles.fillCenterBoldtttt,
          }),
          //Size Value 1
          mergeCells({
            row: startRow + 1 + i * 2,
            col: 19,
            style: styles.centertttt,
          }),
          //Size Value 2
          mergeCells({
            row: startRow + 1 + i * 2,
            col: 20,
            style: styles.centertttt,
          }),
          //TFA
          mergeCells({
            row: startRow + i * 2,
            col: 24,
            rowEnd: startRow + 1 + i * 2,
            style: styles.centertttt,
          }),
        )
      }

      XLSX.utils.sheet_add_aoa(worksheet, nozzles, {
        origin: 'Q62',
      })

      ColDef = [
        { colSpan: 1, headerStyle: styles.fillCenterBoldtttt },
        { colSpan: 1, headerStyle: styles.fillCenterBoldtttt },
        { colSpan: 1, headerStyle: styles.fillCenterBoldtttt },
      ]

      worksheet['!merges'].push(...makeMergedCells(ColDef, startRow, 21, 6))

      //--------------------------------------Stabilizer---------------------------------------
      startRow = 66
      data = [['Stabilizer']]

      ColDef = [{ colSpan: 15, headerStyle: styles.fillCenterBoldtttt }]

      XLSX.utils.sheet_add_aoa(worksheet, data, {
        origin: 'B67',
      })

      worksheet['!merges'].push(...makeMergedCells(ColDef, startRow, 1, data.length))

      startRow += 1

      let stabHeaders = [
        [
          '#',
          'End Taper Angle [deg]',
          ...emptyCells(1),
          'Wrap Angle',
          ...emptyCells(1),
          `Transition Radius [${getUnitsText(UNITS_FOR.Diameter)}]`,
          ...emptyCells(1),
          'Flow Bypass Area [%]',
          ...emptyCells(2),
          '# Blades',
          'Fabrication Type',
          ...emptyCells(1),
          'Hard Facing',
          ...emptyCells(1),
        ],
      ]

      ColDef = [
        { colSpan: 1, headerStyle: styles.fillCenterBoldtttt },
        { colSpan: 2, headerStyle: styles.fillCenterBoldtttt },
        { colSpan: 2, headerStyle: styles.fillCenterBoldtttt },
        { colSpan: 2, headerStyle: styles.fillCenterBoldtttt },
        { colSpan: 3, headerStyle: styles.fillCenterBoldtttt },
        { colSpan: 1, headerStyle: styles.fillCenterBoldtttt },
        { colSpan: 2, headerStyle: styles.fillCenterBoldtttt },
        { colSpan: 2, headerStyle: styles.fillCenterBoldtttt },
      ]

      worksheet['!merges'].push(...makeMergedCells(ColDef, startRow, 1, stabHeaders.length))

      XLSX.utils.sheet_add_aoa(worksheet, stabHeaders, {
        origin: 'B68',
      })

      let stabilizer = bha?.stabilizer ? bha?.stabilizer : []

      let stabs = []

      for (let i = 0; i < stabilizer.length; i++) {
        let stab = stabilizer[i]
        stabs.push([
          stab?.no,
          stab?.endTaperAngle?.value,
          ...emptyCells(1),
          stab?.wrapAngle?.value,
          ...emptyCells(1),
          stab?.transitionRadius?.value,
          ...emptyCells(1),
          stab?.flowBypassArea?.value,
          ...emptyCells(2),
          stab?.noBlades,
          stab?.fabricationType,
          ...emptyCells(1),
          stab?.hardfacing,
          ...emptyCells(1),
        ])
      }

      for (let i = stabs.length; i < 7; i++) {
        stabs.push([emptyCells(15)])
      }

      ColDef = [
        { colSpan: 1, headerStyle: styles.centertttt },
        { colSpan: 2, headerStyle: styles.centertttt },
        { colSpan: 2, headerStyle: styles.centertttt },
        { colSpan: 2, headerStyle: styles.centertttt },
        { colSpan: 3, headerStyle: styles.centertttt },
        { colSpan: 1, headerStyle: styles.centertttt },
        { colSpan: 2, headerStyle: styles.centertttt },
        { colSpan: 2, headerStyle: styles.centertttt },
      ]

      worksheet['!merges'].push(...makeMergedCells(ColDef, startRow + 1, 1, stabs.length))

      XLSX.utils.sheet_add_aoa(worksheet, stabs, {
        origin: 'B69',
      })

      //--------------------------------------Jar---------------------------------------
      startRow = 69
      data = [['Jar']]

      ColDef = [{ colSpan: 4, headerStyle: styles.fillCenterBoldtttt }]

      XLSX.utils.sheet_add_aoa(worksheet, data, {
        origin: 'V70',
      })

      worksheet['!merges'].push(...makeMergedCells(ColDef, startRow, 21, data.length))

      startRow += 1

      let jar = bha?.jar ? bha?.jar : []

      let jars = [
        ['Delays [s]', ...emptyCells(1), 'Up', jar?.delaysUp?.value],
        ['Delays [s]', ...emptyCells(1), 'Down', jar?.delaysDown?.value],
        ['Latch Settings [klbf]', ...emptyCells(1), 'Up', jar?.latchSettingUp?.value],
        ['Latch Settings [klbf]', ...emptyCells(1), 'Down', jar?.latchSettingDown?.value],
        ['Pump Open Force [klbf]', ...emptyCells(2), jar?.forPmpOpen?.value],
        ['Weight below jar @ inc.', ...emptyCells(1), '[klbf]', jar?.belowJar?.weight?.value],
        ['Weight below jar @ inc.', ...emptyCells(1), '[deg]', jar?.belowJar?.inc?.value],
        ['Weight above jar @ inc.', ...emptyCells(1), '[klbf]', jar?.aboveJar?.weight?.value],
        ['Weight above jar @ inc.', ...emptyCells(1), '[deg]', jar?.aboveJar?.inc?.value],
      ]

      ColDef = [
        { colSpan: 2, headerStyle: styles.fillRightBoldtttt },
        { colSpan: 1, headerStyle: styles.fillRightBoldtttt },
        { colSpan: 1, headerStyle: styles.centertttt },
      ]

      worksheet['!merges'].push(...makeMergedCells(ColDef, startRow, 21, 4))

      worksheet['!merges'].push(
        //Pump Open Force
        mergeCells({
          row: startRow + 4,
          col: 21,
          colEnd: 23,
          style: styles.fillCenterBoldtttt,
        }),
        //Pump Open Force Value
        mergeCells({
          row: startRow + 4,
          col: 24,
          style: styles.centertttt,
        }),
      )

      worksheet['!merges'].push(...makeMergedCells(ColDef, startRow + 5, 21, 4))

      XLSX.utils.sheet_add_aoa(worksheet, jars, {
        origin: 'V71',
      })

      addStylesToWorksheet(worksheet, true)
      XLSX.utils.book_append_sheet(wb, worksheet, worksheetName)
    }
    XLSX.writeFile(wb, fileName, { cellStyles: true, bookImages: true })
  }

  const getStabToBitDistance = (bha, stabilizer, index) => {
    let stabToBit = stabilizer[index]?.distanceFromBit?.value
    for (let i = 0; i < stabilizer[index]?.no; i++) {
      if (bha[i].sequence >= stabilizer[index]?.no) break
      stabToBit += bha[i]?.totalLength?.value
    }
    return stabToBit
  }


  const addStylesToWorksheet = (ws, mergedCellStyles = false) => {
    if (!ws) return
    if (mergedCellStyles && !ws.hasOwnProperty('!merges')) return

    if (!ws.hasOwnProperty('!cols')) ws['!cols'] = []
    if (mergedCellStyles) {
      ws['!merges'].forEach((cellAddresses) => {
        const { s, e, style } = cellAddresses
        let startAddress = XLSX.utils.encode_cell(s)
        let endAddress = XLSX.utils.encode_cell(e)
        let cellRange = `${startAddress}:${endAddress}`

        if (style) XLSX.utils.sheet_set_range_style(ws, cellRange, style)
      })
    }
  }

  const getBhaXlsExport = async (bhaNum) => {
    if (!bhaNum || typeof bhaNum !== 'number') bhaNum = -1
    if (!drillStringData.current) return null
    if (!Array.isArray(drillStringData.current)) return null
    if (!currentWellRef.current) return null
    if (currentWellRef.current?.length < 1) return null

    const bhaData = await fetchBhas(currentWellRef.current, bhaNum)
    if (!Array.isArray(bhaData)) return null
    if (bhaData.length === 0) return null

    let wb = XLSX.utils.book_new()

    for (let i = 0; i < bhaData.length; i++) {
      if (!bhaData[i]?.hasOwnProperty('bhaComponents')) continue
      let ws = handleCreateDrillStringXlsWorksheet(
        bhaData[i],
        drillStringData,
        wellInfoRef,
        bhaImages,
        getCurrentOrgIcon,
        getUnitsText,
        userRole?.userPrefs?.reportSettings,
      )

      //add border around entire sheet
      let cellAddresses = ws['!ref'].split(':')
      XLSX.utils.sheet_set_range_style(ws, `B2:${cellAddresses[1]}`, {
        top: { style: 'thick' },
        right: { style: 'thick' },
        bottom: { style: 'thick' },
        left: { style: 'thick' },
      })

      XLSX.utils.book_append_sheet(wb, ws, `BHA ${bhaData[i].bhaNumRep}`)
    }

    XLSX.writeFile(wb, getXlsFileName(bhaData, '_Drill_String_BHA_', wellInfoRef, currentWellRef), {
      cellStyles: true,
      bookImages: true,
    })
  }

  const getMotorPerformanceXlsExport = async (bhaNum) => {
    if (!bhaNum || typeof bhaNum !== 'number') bhaNum = -1
    if (!drillStringData.current) return null
    if (!Array.isArray(drillStringData.current)) return null
    if (!currentWellRef.current) return null
    if (currentWellRef.current?.length < 1) return null

    const bhaData = await fetchBhas(currentWellRef.current, bhaNum)
    if (!Array.isArray(bhaData)) return null
    if (bhaData.length === 0) return null

    let wb = XLSX.utils.book_new()

    for (let i = 0; i < bhaData.length; i++) {
      let ws = await handleCreatePerformanceXlsWorksheet(
        bhaData[i],
        motorReportData.current,
        wellInfoRef,
        getUnitsText,
        userRole?.userPrefs?.reportSettings,
      )

      getPerformanceXlsWorksheetStyles(ws, userRole?.userPrefs?.reportSettings)

      ws['!cols'] = [...makeEmptyArray(6), { wch: 20 }, { wch: 20 }, ...makeEmptyArray(5), { wch: 20 }, { wch: 20 }]

      ws['!images'] = [
        {
          '!pos': {
            c: 1,
            r: 1,
            x: 1,
            y: 1,
            w: 130,
            h: 130,
          },
          '!datatype': 'base64',
          '!data': getCurrentOrgIcon(),
        },
      ]

      let cellAddresses = ws['!ref'].split(':')
      XLSX.utils.sheet_set_range_style(ws, `B2:${cellAddresses[1]}`, {
        top: { style: 'thick' },
        right: { style: 'thick' },
        bottom: { style: 'thick' },
        left: { style: 'thick' },
      })

      XLSX.utils.book_append_sheet(wb, ws, `Perf Rep BHA ${bhaData[i].bhaNumRep}`)
    }

    XLSX.writeFile(wb, getXlsFileName(bhaData, '_Performance_Report_BHA_', wellInfoRef, currentWellRef), {
      cellStyles: true,
      bookImages: true,
    })
  }

  return {
    loading,
    fetchBhas,
    getDrillStringHeader,
    getBhaReportPdfData,
    getMotorReportPdfData,
    deleteBha,
    addBha,
    copyBha,
    updateBhaDesc,
    fetchBhaHeaders,
    getMotorPerformanceXlsExport,
    getBhaXlsExport,
    setWell,
    fetchBhaIaddExport,
    getBhaIaddXlsExport,
  }
}

export default useDrillString
