import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useSetRecoilState } from 'recoil'
import { Snackbar, Box } from '@mui/material'
import Alert from '@mui/material/Alert'
import AddIcon from '@mui/icons-material/Add'
import { Icon as Iconify } from '@iconify/react'
import { useRecoilValue } from 'recoil'
import { actionBarWidthAtom, currentPageAtom, currentWellSelector } from 'atoms'
import MenuButton from 'components/common/MenuButton'
import RefreshIcon from '@mui/icons-material/Refresh'
import { appColors } from 'utils'
import SplitPane from 'components/common/SplitPane'
import 'components/common/SplitPane/splitPaneResizer.css'
import { PAGE_KEYS } from '../../ActionBar/pageDefs'
import useDailyReports from 'components/common/hooks/useDailyReports'
import useDailyCosts from 'components/common/hooks/useDailyCosts/useDailyCosts'
import DailyReportGrid from './DailyReportGrid'
import cloneDeep from 'lodash/cloneDeep'
import moment from 'moment'
import * as yup from 'yup'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import InputModal from 'components/common/InputModal'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import DailyReportsChartsCarousel from './CarouselItems/DailyReportsChartsCarousel'
import DailyActivityEditor from './DailyActivityEditor'
import ConfirmDialog from 'components/common/ConfirmDialog'
import useAnimatePane from 'components/common/hooks/useAnimatePane'
import useAxiosGzip from 'components/common/hooks/useAxiosGzip'
import { pdf } from '@react-pdf/renderer'
import FileSaver from 'file-saver'

const DailyReportPage = () => {
  const _isMounted = useRef(false)
  const [status, setStatus] = useState({ show: false, severity: 'info', message: '' })
  const [showBarChart, setShowBarChart] = useState(true)
  const leftPos = useRecoilValue(actionBarWidthAtom)
  const setActivePage = useSetRecoilState(currentPageAtom)
  const {
    loading: dailyReportIsLoading,
    fetchDailyReportData,
    getDropDowns: getActivityDropDowns,
    fetchDailyReports,
    fetchDailyReport,
    getPdfData,
    getDailyReportHeaderIds,
    getDailyActivity,
  } = useDailyReports()
  const { loading: dailyCostIsLoading, fetchWellCosts, fetchCostData } = useDailyCosts()
  const [dailyReportData, setDailyReportData] = useState({})
  const [dailyCostData, setDailyCostData] = useState({})
  const [gridData, setGridData] = useState([])
  const [showAddDailyReport, setShowAddDailyReport] = useState(false)
  const [filteredDailyReportData, setFilteredData] = useState([])
  const [phaseFilter, setPhaseFilter] = useState([])
  const [showDailyActivityEditor, setShowDailyActivityEditor] = useState(false)
  const currentWell = useRecoilValue(currentWellSelector)
  const [confirm, setConfirm] = useState({ show: false, title: '' })
  const { setPaneVisible: setShowChart, paneVisible: showChart, paneSize } = useAnimatePane()
  const isLoadingWitsDataRef = useRef(false)
  const tenMinDecData = useRef([])
  const showChartRef = useRef(showChart)

  const addDailyReport = useInnovaAxios({
    url: '/well/dailyReports/addReport',
  })

  const deleteReportingData = useInnovaAxios({
    url: '/well/deleteReportingData',
  })

  const updateDdrFromSlideSheet = useInnovaAxios({
    url: '/well/dailyReports/getDdrFromSlideSheet',
  })

  const getWitsData = useAxiosGzip({
    url: '/dataAcq/getDec10DataGz',
  })

  const handleGetFromSlideSheet = async () => {
    let response = await updateDdrFromSlideSheet({ wellName: currentWell, dailyReportId: -1 })
    if (response.error) {
      setStatus({ show: true, severity: 'error', message: response?.error?.response?.data?.error })
      return false
    }

    setGridData([])
    fetchDailyReports()
  }

  useEffect(() => {
    if (_isMounted.current) {
      if (!dailyReportIsLoading) setDailyReportData(fetchDailyReportData())
    }
  }, [dailyReportIsLoading]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (_isMounted.current) showChartRef.current = showChart
  }, [showChart])

  useEffect(() => {
    if (_isMounted.current) {
      if (!dailyCostIsLoading) setDailyCostData(fetchWellCosts())
    }
  }, [dailyCostIsLoading]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (_isMounted.current) {
      if (dailyReportData.length <= 0 || !dailyCostData.hasOwnProperty('dailyCosts')) return
      mergeDailyReportDailyCost()
    }
  }, [dailyReportData, dailyCostData]) // eslint-disable-line react-hooks/exhaustive-deps

  const mergeDailyReportDailyCost = () => {
    if (!dailyReportData) return
    if (!dailyReportData.hasOwnProperty('dailyReports')) return
    if (!Array.isArray(dailyReportData.dailyReports)) return
    if (!dailyCostData.hasOwnProperty('dailyCosts')) return
    if (!Array.isArray(dailyCostData.dailyCosts)) return
    let costBreakdown = cloneDeep(dailyCostData.dailyCosts)
    let costDetailsArr = []
    let dailyReportsAndCostsMerged = []
    let maxDailyCost = 0

    for (let i = 0; i < dailyReportData.dailyReports.length; i++) {
      if (dailyReportData.dailyReports[i]?.dailyCost > maxDailyCost) {
        maxDailyCost = dailyReportData.dailyReports[i]?.dailyCost
      }
    }

    dailyReportData.dailyReports.forEach((item) => {
      let costIdx = costBreakdown.findIndex((cost) => cost.dailyReportId === item.recordId)
      if (costIdx >= 0) {
        let dailyCostsArr = costBreakdown[costIdx]?.costs

        for (let i = 0; i < dailyCostsArr?.length; i++) {
          costDetailsArr.push({
            recordId: dailyCostsArr[i]?.dailyReportId,
            value: dailyCostsArr[i]?.totalValue,
            color: dailyCostsArr[i]?.color,
            label: dailyCostsArr[i]?.description,
          })
        }
      }

      let dailyReport = cloneDeep(item)
      dailyReport.maxDailyCost = maxDailyCost
      dailyReportsAndCostsMerged.push(dailyReport)
    })

    dailyReportsAndCostsMerged.forEach((item) => {
      item.costDetail = []
      for (let i = 0; i < costDetailsArr.length; i++) {
        if (costDetailsArr[i].recordId === item.recordId) {
          item.costDetail.push(costDetailsArr[i])
        }
      }
    })

    setGridData(dailyReportsAndCostsMerged)
  }

  const fetchWitsData = async () => {
    if (isLoadingWitsDataRef.current) return
    if (currentWell.length < 1) return

    let payload = {
      wellName: currentWell,
      numRecs: 10000,
    }

    isLoadingWitsDataRef.current = true
    const response = await getWitsData(payload)
    isLoadingWitsDataRef.current = false

    if (!_isMounted.current) return
    tenMinDecData.current = []
    if (!Array.isArray(response?.data)) return
    tenMinDecData.current = response.data
  }

  useEffect(() => {
    if (_isMounted.current) {
      if (currentWell.length > 0) {
        fetchWitsData()
      }
    }
  }, [currentWell]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    _isMounted.current = true
    setActivePage(PAGE_KEYS.dailyReportKey)

    fetchWitsData()
    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleNewDailyReport = () => {
    setShowAddDailyReport(true)
  }

  const handleCloseAdd = () => {
    setShowAddDailyReport(false)
  }

  const submitAddFunction = async (data, formikActions) => {
    setShowAddDailyReport(false)
    if (!data) return false
    let payload = { ...data }

    const momentDate = moment(data.date)
    payload.date = momentDate.format().substring(0, 10)

    let res = await addDailyReport(payload)

    if (res.error) {
      setStatus({ show: true, severity: 'error', message: `${res?.error?.response?.data?.error}` })
      formikActions?.setSubmitting(false)
      return false
    }

    setStatus({ show: true, severity: 'success', message: 'Report added' })
    await fetchDailyReports()
    await fetchCostData()
    return true
  }

  const onPdfExport = async (recordIds) => {
    if (!Array.isArray(recordIds)) return
    let pdfDoc = await getPdfData(recordIds)
    if (!pdfDoc) return
    const blob = await pdf(pdfDoc.data).toBlob()
    FileSaver.saveAs(blob, pdfDoc.fileName)
  }

  const confirmDelete = async () => {
    let res = await deleteReportingData({ wellName: currentWell })

    if (res.error) {
      setStatus({ show: true, severity: 'error', message: `${res?.error?.response?.data?.error}` })
      return false
    }

    setStatus({ show: true, severity: 'success', message: 'Reporting data deleted' })
    await fetchDailyReports()
  }

  const onClickDeleteReportingData = () => {
    setConfirm({
      show: true,
      title: 'Delete All Reporting Data',
      text: `WARNING - THIS WILL DELETE ALL REPORTING DATA INCLUDING BHA'S AND SLIDE SHEETS DO YOU WANT TO CONTINUE`,
    })
  }

  const getMenuItems = () => {
    if (showDailyActivityEditor) {
      return [
        {
          icon: <RefreshIcon />,
          name: 'Refresh',
          onClick: () => {
            setGridData([])
            fetchDailyReports()
          },
        },
        {
          icon: <Iconify icon='bi:calendar-day-fill' />,
          name: showDailyActivityEditor ? 'Hide daily activity editor' : 'Show daily activity editor',
          onClick: () => {
            fetchDailyReports()
            setShowChart(true)
            setShowDailyActivityEditor(false)
          },
        },
        {
          icon: <Iconify icon='mdi:table-import' />,
          name: 'Get from slide sheet',
          onClick: handleGetFromSlideSheet,
        },
      ]
    }

    let actions = [
      {
        icon: <AddIcon />,
        name: 'Add new report',
        onClick: () => {
          handleNewDailyReport()
        },
      },
      {
        icon: <RefreshIcon />,
        name: 'Refresh',
        onClick: () => {
          setGridData([])
          fetchDailyReports()
          fetchCostData()
        },
      },
      {
        icon: <PictureAsPdfIcon />,
        name: 'Print all reports',
        onClick: () => {
          if (!gridData || !Array.isArray(gridData)) return
          let recordIds = []
          for (let i = gridData.length - 1; i >= 0; i--) {
            recordIds.push(gridData[i].recordId)
          }

          onPdfExport(recordIds)
        },
      },
      {
        icon: <Iconify icon='bi:calendar-day-fill' />,
        name: 'Show daily activity editor',
        onClick: () => {
          setShowChart(false)
          setShowDailyActivityEditor(true)
        },
      },
      {
        icon: <Iconify icon='fluent-mdl2:delete' />,
        name: 'Delete Reporting Data',
        onClick: () => {
          onClickDeleteReportingData()
        },
      },
    ]

    if (!showChart) {
      actions.push({
        icon: <Iconify icon='bxs:show' style={{ color: appColors.itemTextColor, width: 28, height: 28 }} />,
        name: 'Show Chart',
        onClick: () => handleShowChart(),
      })
    } else {
      actions.push({
        icon: <Iconify icon='bi:bar-chart' />,
        name: showBarChart ? 'Hide slide/rot on chart' : 'Show slide/rot on chart',
        onClick: () => setShowBarChart(!showBarChart),
      })
      actions.push({
        icon: <Iconify icon='bxs:hide' style={{ color: appColors.itemTextColor, width: 28, height: 28 }} />,
        name: 'Hide Chart',
        onClick: () => handleShowChart(),
      })
    }

    return actions
  }

  const handleShowChart = () => {
    setShowChart((prev) => !prev)
  }

  const handleCloseStatus = () => {
    setStatus({ show: false, severity: 'info', message: '' })
  }

  const AddDailyReportModal = ({ submitFunction, cancelFunction }) => {
    const formValidationSchema = yup.object({ date: yup.string().required() })

    let formData = [
      {
        tag: 'wellName',
        value: currentWell,
        inputType: '-',
      },
      {
        tag: 'date',
        value: moment(new Date(Date.now())).format(),
        inputType: 'date',
      },
    ]

    return (
      <InputModal
        open={showAddDailyReport}
        onClose={cancelFunction}
        title={'Add daily report'}
        formData={formData}
        submitForm={submitFunction}
        cancelFunction={cancelFunction}
        validationSchema={formValidationSchema}
      />
    )
  }

  const DailyGrid = useMemo(
    () => (
      <DailyReportGrid
        gridData={gridData}
        getActivityDropDowns={getActivityDropDowns}
        isLoading={dailyReportIsLoading || dailyCostIsLoading}
        setFilteredData={setFilteredData}
        setPhaseFilter={setPhaseFilter}
        onPdfExport={onPdfExport}
        fetchDailyReport={fetchDailyReport}
        setShowChart={setShowChart}
        showChart={showChartRef}
      />
    ),
    //Not using exhaustive deps because we don't want to unmount the detail grid
    [gridData, dailyReportIsLoading, dailyCostIsLoading, setFilteredData, setPhaseFilter], // eslint-disable-line react-hooks/exhaustive-deps
  )

  return (
    <React.Fragment>
      {confirm.show ? (
        <ConfirmDialog
          title={confirm?.title}
          open={confirm?.show}
          setOpen={() => setConfirm({ show: false })}
          onConfirm={() => confirmDelete()}>
          {confirm?.text}
        </ConfirmDialog>
      ) : null}
      {showAddDailyReport ? (
        <AddDailyReportModal submitFunction={submitAddFunction} cancelFunction={handleCloseAdd} />
      ) : null}
      <SplitPane
        split='horizontal'
        defaultSize={'50%'}
        size={paneSize}
        style={{
          marginTop: '64px',
          marginLeft: `${leftPos}px`,
          height: 'calc(100vh - 64px)',
          maxHeight: 'calc(100vh - 64px)',
          minHeight: 'calc(100vh - 64px)',
          width: `calc(100% - ${leftPos}px)`,
          maxWidth: `calc(100% - ${leftPos}px)`,
          minWidth: `calc(100% - ${leftPos}px)`,
        }}
        allowResize={true}>
        <DailyReportsChartsCarousel
          filteredDailyReportData={filteredDailyReportData}
          showBarChart={showBarChart}
          phaseFilter={phaseFilter}
          witsData={tenMinDecData.current}
        />
        {!showDailyActivityEditor ? (
          DailyGrid
        ) : (
          <DailyActivityEditor
            dailyReportHeaders={getDailyReportHeaderIds()}
            dailyActivityData={getDailyActivity()}
            getActivityDropDowns={getActivityDropDowns}
          />
        )}
      </SplitPane>
      <Box
        sx={{
          backgroundColor: 'transparent',
          margin: '4px',
          padding: '12px',
          position: 'fixed',
          bottom: (theme) => theme.spacing(2),
          right: (theme) => theme.spacing(2),
          zIndex: 2,
        }}>
        <MenuButton actions={getMenuItems()} />
      </Box>
      {status?.show ? (
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={status?.show}
          autoHideDuration={2000}
          onClose={handleCloseStatus}>
          <Alert onClose={handleCloseStatus} severity={status.severity} elevation={4} variant='filled'>
            {status.message}
          </Alert>
        </Snackbar>
      ) : null}
    </React.Fragment>
  )
}

export default DailyReportPage
