import React, { useRef, useEffect, useState } from 'react'
import { currentPageAtom, actionBarWidthAtom, shippingTicketLayoutAtom, currentWellAtom } from 'atoms'
import { useSetRecoilState, useRecoilValue, useRecoilState } from 'recoil'
import { Snackbar } from '@mui/material'
import { PAGE_KEYS } from 'components/ActionBar/pageDefs'
import SplitPane from 'components/common/SplitPane'
import 'components/common/SplitPane/splitPaneResizer.css'
import cloneDeep from 'lodash/cloneDeep'
import Alert from '@mui/material/Alert'
import ShippingTicketListGrid from './ShippingTicketsListGrid'
import ShippingTicketDetailsPane from './ShippingTicketDetails/ShippingTicketDetailsPane'
import ShippingInventoryGrid from './ShippingTicketDetails/ShippingInventoryGrid'
import useShippingTickets from 'components/common/hooks/useShippingTickets'
import { pdf } from '@react-pdf/renderer'
import FileSaver from 'file-saver'

const ShippingTicketPage = () => {
  const setActivePage = useSetRecoilState(currentPageAtom)
  const leftPos = useRecoilValue(actionBarWidthAtom)
  const _isMounted = useRef(false)
  const shippingTicketListGripApi = useRef(null)
  const [selectedTicket, setSelectedTicket] = useState(null)
  const [status, setStatus] = useState({ show: false, severity: 'info', message: '' })
  const [pageLayout, setPageLayout] = useRecoilState(shippingTicketLayoutAtom)
  const currentWell = useRecoilValue(currentWellAtom)

  const {
    isLoading,
    isUpdating,
    isDeleting,
    getShippingTicketData,
    fetchShippingTickets,
    addNewTicket,
    updateTicket,
    deleteTicket,
    getShippingAddressesData,
    inventoryData,
    updateTicketTools,
    getShippingTicketPdf,
  } = useShippingTickets()

  const getPaneVisible = (index) => {
    if (!Array.isArray(pageLayout)) return true
    if (index >= pageLayout.length) return true
    return pageLayout[index].visible
  }

  const [showTicketsList, setShowTicketsList] = useState(getPaneVisible(0))

  useEffect(() => {
    _isMounted.current = true
    setActivePage(PAGE_KEYS.ticketPageKey)

    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (_isMounted.current) setSelectedTicket(null)
  }, [currentWell]) // eslint-disable-line react-hooks/exhaustive-deps

  const getInitialPaneSize = (index) => {
    if (!Array.isArray(pageLayout)) return index === 0 ? '25%' : '50%'
    if (index >= pageLayout.length) return index === 0 ? '25%' : '50%'
    return pageLayout[index].size
  }

  const onDragFinished = (newSize, index) => {
    let newPanes = cloneDeep(pageLayout)
    if (Array.isArray(pageLayout) && index < pageLayout.length) {
      newPanes[index].size = newSize
    }
    setPageLayout(newPanes)
  }

  const onSetPaneVisible = (index, value) => {
    if (Array.isArray(pageLayout)) return
    if (index >= pageLayout.length) return
    let newPanes = cloneDeep(pageLayout)
    newPanes[index].visible = value
    setPageLayout(newPanes)
  }

  const showHidePane = () => {
    onSetPaneVisible(0, !showTicketsList)
    setShowTicketsList(!showTicketsList)
  }

  const handleUpdate = async (data) => {
    let res = await updateTicket(data)
    if (res.error) {
      setStatus({ show: true, severity: 'error', message: res.message })
      return
    }

    setSelectedTicket(data)

    if (shippingTicketListGripApi.current) {
      shippingTicketListGripApi.current.applyTransaction({
        update: [data],
      })
    }

    return res
  }

  const handleCloseStatus = () => {
    setStatus({ show: false, severity: 'info', message: '' })
  }

  const getFullInventoryData = () => {
    if (!Array.isArray(inventoryData)) return []
    if (!selectedTicket) return inventoryData
    if (!Array.isArray(selectedTicket.tools)) return inventoryData
    if (selectedTicket.tools.length === 0) return inventoryData

    return inventoryData.filter((invItem) => {
      let index = selectedTicket.tools.findIndex((selectedTool) => selectedTool.uid === invItem.uid)
      return index < 0
    })
  }

  const handlePrintTicket = async () => {
    let pdfDoc = getShippingTicketPdf(selectedTicket.uid)
    if (pdfDoc === null) return
    const blob = await pdf(pdfDoc.data).toBlob()
    FileSaver.saveAs(blob, pdfDoc.fileName)
  }

  return (
    <React.Fragment>
      <SplitPane
        split='vertical'
        onDragFinished={(params) => onDragFinished(params, 0)}
        defaultSize={getInitialPaneSize(0)}
        size={showTicketsList ? getInitialPaneSize(0) : '25px'}
        allowResize={showTicketsList}
        style={{
          height: 'calc(100vh - 64px)',
          marginLeft: leftPos,
          width: `calc(100% - ${leftPos}px)`,
          maxWidth: `calc(100% - ${leftPos}px)`,
        }}>
        <ShippingTicketListGrid
          isLoading={isLoading}
          isUpdating={isUpdating}
          isDeleting={isDeleting}
          shippingTickets={getShippingTicketData()}
          isVisible={showTicketsList}
          showHidePane={showHidePane}
          refreshData={fetchShippingTickets}
          addNewTicket={addNewTicket}
          updateTicket={updateTicket}
          deleteTicket={deleteTicket}
          setSelectedTicket={setSelectedTicket}
          gridApi={shippingTicketListGripApi}
          handlePrintTicket={handlePrintTicket}
        />
        <SplitPane
          split='horizontal'
          size={getInitialPaneSize(1)}
          allowResize={true}
          defaultSize={getInitialPaneSize(1)}
          onDragFinished={(params) => onDragFinished(params, 1)}>
          <ShippingTicketDetailsPane
            isLoading={isLoading}
            updateTicket={handleUpdate}
            selectedTicket={selectedTicket}
            addresses={getShippingAddressesData()}
            updateTicketTools={updateTicketTools}
            setStatus={setStatus}
            setSelectedTicket={setSelectedTicket}
          />
          <ShippingInventoryGrid
            inventoryList={getFullInventoryData()}
            selectedTicket={selectedTicket}
            isSelectedToolsGrid={false}
            updateTicketTools={updateTicketTools}
            setSelectedTicket={setSelectedTicket}
          />
        </SplitPane>
      </SplitPane>
      {status?.show ? (
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={status?.show}
          autoHideDuration={2000}
          onClose={handleCloseStatus}>
          <Alert onClose={handleCloseStatus} severity={status.severity} elevation={4} variant='filled'>
            {status.message}
          </Alert>
        </Snackbar>
      ) : null}
    </React.Fragment>
  )
}

export default ShippingTicketPage
