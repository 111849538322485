import React, { useEffect, useRef, useState, useMemo } from 'react'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import { useRecoilValue } from 'recoil'
import { currentWellSelector } from 'atoms'
import { AgGridReact } from 'ag-grid-react'
import { Tooltip, Box, IconButton } from '@mui/material'
import { Icon as Iconify } from '@iconify/react'
import ConfirmDialog from 'components/common/ConfirmDialog'
import { saveItemToLS } from 'utils/localStorage'
import { cloneDeep } from 'lodash'
import { unescapeHtml } from 'utils/htmlSymbolHandling'
import { numberWithCommasDecimals } from 'utils/stringFunctions'
import { appColors } from 'utils'
import { sortColDefs, getStringId } from 'components/common/AgGridUtils'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const DailyActivityDetailsGrid = ({
  parentRowData,
  refreshCallback,
  getActivityDropDowns,
  prevDailyReportEndDepth = -1,
  nextDailyReportStartDepth = 9999999,
  setStatus = null,
}) => {
  const _isMounted = useRef(false)
  const gridApi = useRef(null)
  const currentWell = useRecoilValue(currentWellSelector)
  const currentWellRef = useRef(currentWell)
  const [resetCols, setResetCols] = useState(false)
  const inputRow = useRef({})
  const parentData = useRef(parentRowData)
  const pastedRows = useRef([])
  const [confirm, setConfirm] = useState({ show: false, title: '' })
  const { getAgGridTheme } = useInnovaTheme()

  const deleteDailyActivity = useInnovaAxios({
    url: '/well/dailyReports/deleteDailyActivity',
  })

  const updateParentGrid = async (payload) => {
    if (!payload) return
    if (gridApi.current) {
      if (payload.action === 'delete') gridApi.current.applyTransaction({ remove: payload.data })
      if (payload.action === 'update') gridApi.current.applyTransaction({ update: payload.data })
    }

    let res = await refreshCallback(payload)

    if (!res) return
    parentData.current = cloneDeep(res)
    gridApi.current.setGridOption('rowData', Array.isArray(res.dailyActivity) ? res.dailyActivity : [])
  }

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
    }
  }, [])

  useEffect(() => {
    currentWellRef.current = currentWell
  }, [currentWell]) // eslint-disable-line react-hooks/exhaustive-deps

  const actionIconRenderer = (params) => {
    return (
      <Box style={{ display: 'flex', flexDirection: 'row' }}>
        <Tooltip
          title={params.node?.rowPinned !== 'bottom' ? 'Delete' : 'Add'}
          placement='left'
          componentsProps={{
            tooltip: {
              sx: {
                backgroundColor: 'rgb(19,62,96)',
                fontSize: '12px',
                fontFamily: 'Roboto',
              },
            },
          }}>
          <IconButton
            sx={{ padding: '5px', alignItems: 'center', justifyContent: 'center' }}
            onClick={() => (params.node?.rowPinned !== 'bottom' ? handleDelete(params) : handleAddRow(params))}
            size='large'>
            {params.node?.rowPinned !== 'bottom' ? (
              <Iconify icon='fa-regular:trash-alt' color={'#C00000'} fontSize={'16px'} />
            ) : (
              <Iconify icon='fluent:add-12-filled' color={'green'} fontSize={'16px'} />
            )}
          </IconButton>
        </Tooltip>
        {params.node?.rowPinned !== 'bottom' ? (
          <Box style={{ textAlign: 'right', paddingLeft: '8px' }}>{params.node.rowIndex + 1}</Box>
        ) : null}
      </Box>
    )
  }

  const handleDelete = (params) => {
    setConfirm({
      show: true,
      title: 'Delete Activity',
      text: `Are you sure you want to delete ${params.data?.activity}: ${params.data?.activityCodeDesc}?`,
      params: params,
    })
  }

  const confirmDelete = async () => {
    if (currentWellRef.current?.length <= 0) return
    if (confirm.params.data?.uid < 0) return
    const deleteResponse = await deleteDailyActivity({
      wellName: currentWellRef.current,
      uid: confirm.params.data?.uid,
    })

    if (deleteResponse?.error) {
      showErrorMessage(deleteResponse?.error?.response?.data?.error)
      return
    }

    updateParentGrid({ action: 'delete', data: [cloneDeep(confirm.params.data)] })
  }

  const isPinnedRowDataCompleted = () => {
    let requiredData = ['activity', 'activityCodeDesc', 'startTime', 'endTime']
    for (let i = 0; i < requiredData.length; i++) {
      if (!inputRow.current.hasOwnProperty(requiredData[i])) return false
    }

    return true
  }

  const clearPinnedRow = () => {
    inputRow.current = {}
    if (gridApi.current) gridApi.current.setGridOption('pinnedBottomRowData', [inputRow.current])
  }

  function showErrorMessage(message) {
    if (!setStatus) return
    setStatus({ show: true, severity: 'error', message: message ? message : 'Uknown error' })
  }

  function getActivityHours(startTime, endTime) {
    if (typeof startTime !== 'string') return 0
    if (typeof endTime !== 'string') return 0

    // Convert start time to minutes
    var startParts = startTime.split(':')
    var startHours = parseInt(startParts[0])
    var startMinutes = parseInt(startParts[1])
    var startTotalMinutes = startHours * 60 + startMinutes

    // Convert end time to minutes
    var endParts = endTime.split(':')
    var endHours = parseInt(endParts[0])
    var endMinutes = parseInt(endParts[1])
    var endTotalMinutes = endHours * 60 + endMinutes

    // Calculate the difference in minutes
    var minuteDifference = endTotalMinutes - startTotalMinutes

    // Convert the minute difference to hours as a floating-point number
    var hourDifference = minuteDifference / 60

    return hourDifference
  }

  const handleAddRow = async (params) => {
    if (!isPinnedRowDataCompleted()) return

    let newData = cloneDeep(inputRow.current)
    let prevActivity = getPrevActivityFromTime(newData.startTime)
    let nextActivity = getNextActivityFromTime(newData.endTime)

    //No Data
    if (!prevActivity && !nextActivity) {
      const { phaseCodes } = getActivityDropDowns()
      newData.phase = Array.isArray(phaseCodes) && phaseCodes.length > 0 ? phaseCodes[0].code : ''
      newData.bha = -1
      newData.startDepth = prevDailyReportEndDepth >= 0 ? prevDailyReportEndDepth : 0
      newData.endDepth = prevDailyReportEndDepth >= 0 ? prevDailyReportEndDepth : 0
    }

    //Check if overlapping the previous record completely
    if (prevActivity !== null && timeStrToDecDay(newData.startTime) <= timeStrToDecDay(prevActivity.startTime)) {
      showErrorMessage('Start time must be greater than prev activity start time')
      return
    }

    //Check if overlapping the next record completely
    if (nextActivity !== null && timeStrToDecDay(newData.endTime) >= timeStrToDecDay(nextActivity.endTime)) {
      showErrorMessage('Start time must be greater than prev activity start time')
      return
    }

    let recordsToUpdate = []

    //Will be first activity
    if (prevActivity === null && nextActivity !== null) {
      newData.phase = nextActivity.phase
      newData.bha = nextActivity.bha
      newData.startDepth = nextActivity.startDepth
      newData.endDepth = nextActivity.startDepth

      //Overlapping next activity
      if (timeStrToDecDay(newData.endTime) > timeStrToDecDay(nextActivity.startTime)) {
        let newNextActivity = cloneDeep(nextActivity)
        newNextActivity.startTime = newData.endTime
        newNextActivity.hours = getActivityHours(newNextActivity.startTime, newNextActivity.endTime)
        recordsToUpdate.push(newNextActivity)
      }
    }

    //Last Activity
    if (nextActivity === null && prevActivity !== null) {
      newData.phase = prevActivity.phase
      newData.bha = prevActivity.bha
      newData.startDepth = prevActivity.endDepth
      newData.endDepth = prevActivity.endDepth

      //Overlapping prev activity
      if (timeStrToDecDay(newData.startTime) < timeStrToDecDay(prevActivity.endTime)) {
        let newPrevActivity = cloneDeep(prevActivity)
        newPrevActivity.endTime = newData.startTime
        newPrevActivity.hours = getActivityHours(newPrevActivity.startTime, newPrevActivity.endTime)
        recordsToUpdate.push(newPrevActivity)
      }
    }

    //Inserted in the middle
    if (nextActivity !== null && prevActivity !== null) {
      newData.phase = prevActivity.phase
      newData.bha = prevActivity.bha
      newData.startDepth = prevActivity.endDepth
      newData.endDepth = prevActivity.endDepth

      //Overlapping prev activity
      if (timeStrToDecDay(newData.startTime) < timeStrToDecDay(prevActivity.endTime)) {
        let newPrevActivity = cloneDeep(prevActivity)
        newPrevActivity.endTime = newData.startTime
        newPrevActivity.hours = getActivityHours(newPrevActivity.startTime, newPrevActivity.endTime)
        recordsToUpdate.push(newPrevActivity)
      }

      //Overlapping next activity
      if (timeStrToDecDay(newData.endTime) > timeStrToDecDay(nextActivity.startTime)) {
        let newNextActivity = cloneDeep(nextActivity)
        newNextActivity.startTime = newData.endTime
        newNextActivity.hours = getActivityHours(newNextActivity.startTime, newNextActivity.endTime)
        recordsToUpdate.push(newNextActivity)
      }
    }

    newData.hours = getActivityHours(newData.startTime, newData.endTime)
    newData.cl = 0
    newData.actualwell = currentWellRef.current
    newData.recordId = parentData.current?.recordId

    updateParentGrid({
      action: 'add',
      data: [newData],
      dataToUpdate: recordsToUpdate,
    })

    clearPinnedRow()
  }

  const centerAlignCell = () => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  })

  const saveColumnState = () => {
    if (gridApi.current) {
      const colLayout = gridApi.current.getColumnState()
      if (colLayout) saveItemToLS('dailyActivityGrid', 'colLayout', colLayout)
    }
  }

  const getContextMenuItems = (params) => {
    return [
      {
        name: 'Reset columns',
        disabled: false,
        action: () => {
          gridApi.current.resetColumnState()
          saveItemToLS('dailyActivityGrid', 'colLayout', null)
          setResetCols(!resetCols)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Reset filters',
        disabled: false,
        action: () => {
          gridApi.current.setFilterModel(null)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      'copy',
      {
        name: 'Export',
        disabled: false,
        action: () => {
          gridApi.current.exportDataAsExcel({
            fileName: 'DailyActivityData.xlsx',
            sheetName: 'DailyActivity',
          })
        },
        icon: '<span class="iconify" data-icon="icomoon-free:file-excel" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
    ]
  }

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.uid)
    }
  }, [])

  function createPinnedCellPlaceholder({ colDef }) {
    if (
      colDef.field === 'activityCodeDesc' ||
      colDef.field === 'activity' ||
      colDef.field === 'startTime' ||
      colDef.field === 'endTime'
    ) {
      if (colDef.field === 'activity') return 'Activity Code...'
      if (colDef.field === 'activityCodeDesc') return 'Activity Description...'
      if (colDef.field === 'startTime') return 'Start Time...'
      if (colDef.field === 'endTime') return 'End Time...'
    }

    return ''
  }

  function isEmptyPinnedCell({ node, value }) {
    return (node?.rowPinned === 'bottom' && value == null) || (node?.rowPinned === 'bottom' && value === '')
  }

  const defaultColDef = useMemo(() => {
    return {
      editable: true,
      resizable: false,
      sortable: false,
      autoHeight: true,
      headerClass: 'header-no-padding', //This style removes the 15px horizontal padding ag grid provides
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
      valueFormatter: (params) => (isEmptyPinnedCell(params) ? createPinnedCellPlaceholder(params) : undefined),
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const sortActivityCodes = (array) => {
    if (!Array.isArray(array)) return []
    if (array.length <= 0) return []

    let sortedArray = array.sort((a, b) => {
      if (typeof a.code !== 'string') return -1
      if (typeof b.code !== 'string') return -1
      if (a.code === '') return -1
      if (b.code === '') return -1

      let aMatch = a.code.match(/\d+/)
      let bMatch = b.code.match(/\d+/)

      if (!aMatch || !bMatch) {
        return a.code.localeCompare(b.code)
      }

      let aNumber = parseInt(a.code.match(/\d+/)[0]) // Extract the number part from string 'a'
      let bNumber = parseInt(b.code.match(/\d+/)[0]) // Extract the number part from string 'b'
      let aLetter = a.code.slice(-1) // Extract the letter part from string 'a'
      let bLetter = b.code.slice(-1) // Extract the letter part from string 'b'

      if (aNumber < bNumber) return -1
      if (aNumber > bNumber) return 1

      if (aLetter < bLetter) return -1
      if (aLetter > bLetter) return 1

      return 0
    })

    return sortedArray
  }

  const getCodeDescFromDropDown = (combinedCodeDesc) => {
    if (typeof combinedCodeDesc !== 'string') return { code: '', desc: '' }

    const { activityCodes } = getActivityDropDowns()
    let index = activityCodes.findIndex((code) => `${code.code} - ${code.description}` === combinedCodeDesc)
    if (index < 0) return { code: '', desc: '' }

    return { code: activityCodes[index].code, desc: activityCodes[index].description }
  }

  const getActivityCodeDropDown = () => {
    const { activityCodes } = getActivityDropDowns()

    let sortedCodes = sortActivityCodes(activityCodes)

    return sortedCodes.map((code) => {
      return `${code.code} - ${code.description}`
    })
  }

  const isTimeStrValid = (timeStr) => {
    if (typeof timeStr !== 'string') return false
    return /^([01]\d|2[0-4]):([0-5]\d)$/.test(timeStr)
  }

  const isStrValidFloat = (str) => {
    if (typeof str === 'number') {
      if (str < 0) return false
      if (str > 100000) return false
      return true
    }
    if (typeof str !== 'string') return false

    return /^(0|[1-9]\d{0,6})(\.\d{1,2})?$/.test(str)
  }

  const timeStrToDecDay = (timeStr) => {
    if (!isTimeStrValid(timeStr)) return -1
    let [hours, mins] = timeStr.split(':')
    let totalMins = parseInt(hours) * 60 + parseInt(mins)
    return totalMins / 1440
  }

  const getNextActivityFromTime = (timeStr) => {
    //Time passed in will usually be the end time of the activity
    if (typeof timeStr !== 'string') return null
    let decTime = timeStrToDecDay(timeStr)
    if (decTime < 0) return null
    if (!parentData.current) return null
    if (!parentData.current.hasOwnProperty('dailyActivity')) return null
    if (!Array.isArray(parentData.current.dailyActivity)) return null
    const { dailyActivity } = parentData.current

    if (dailyActivity.length === 0) return null
    if (decTime <= timeStrToDecDay(dailyActivity[0].startTime)) return dailyActivity[0]

    for (let i = 0; i < dailyActivity.length; i++) {
      if (decTime <= timeStrToDecDay(dailyActivity[i].startTime)) {
        return dailyActivity[i]
      }

      if (
        decTime >= timeStrToDecDay(dailyActivity[i].startTime) &&
        decTime <= timeStrToDecDay(dailyActivity[i].endTime)
      ) {
        return dailyActivity[i]
      }
    }

    return null
  }

  const getPrevActivityFromTime = (timeStr) => {
    //Time passed in will usually be the start time of the activity
    if (typeof timeStr !== 'string') return null
    let decTime = timeStrToDecDay(timeStr)
    if (decTime < 0) return null
    if (!parentData.current) return null
    if (!parentData.current.hasOwnProperty('dailyActivity')) return null
    if (!Array.isArray(parentData.current.dailyActivity)) return null
    const { dailyActivity } = parentData.current

    if (dailyActivity.length === 0) return null
    if (decTime >= timeStrToDecDay(dailyActivity[dailyActivity.length - 1].endTime))
      return dailyActivity[dailyActivity.length - 1]

    for (let i = dailyActivity.length - 1; i >= 0; i--) {
      if (decTime >= timeStrToDecDay(dailyActivity[i].endTime)) {
        return dailyActivity[i]
      }

      if (
        decTime >= timeStrToDecDay(dailyActivity[i].startTime) &&
        decTime <= timeStrToDecDay(dailyActivity[i].endTime)
      ) {
        return dailyActivity[i]
      }
    }

    return null
  }

  const getActivityFromUid = (uid) => {
    if (typeof uid !== 'number') return null
    if (uid < 0) return null
    if (!parentData.current) return null
    if (!parentData.current.hasOwnProperty('dailyActivity')) return null
    if (!Array.isArray(parentData.current.dailyActivity)) return null

    for (let i = 0; i < parentData.current.dailyActivity.length; i++) {
      if (uid === parentData.current.dailyActivity[i].uid) {
        return parentData.current.dailyActivity[i]
      }
    }

    return null
  }

  const getPrevActivity = (uid) => {
    if (typeof uid !== 'number') return null
    if (uid < 0) return null
    if (!parentData.current) return null
    if (!parentData.current.hasOwnProperty('dailyActivity')) return null
    if (!Array.isArray(parentData.current.dailyActivity)) return null

    let curActivity = getActivityFromUid(uid)
    if (!curActivity) return null

    let index = parentData.current.dailyActivity.findIndex((activity) => activity.uid === curActivity.uid)
    if (index <= 0) return null
    return parentData.current.dailyActivity[index - 1]
  }

  const getNextActivity = (uid) => {
    if (typeof uid !== 'number') return null
    if (uid < 0) return null
    if (!parentData.current) return null
    if (!parentData.current.hasOwnProperty('dailyActivity')) return null
    if (!Array.isArray(parentData.current.dailyActivity)) return null

    let curActivity = getActivityFromUid(uid)
    if (!curActivity) return null

    let index = parentData.current.dailyActivity.findIndex((activity) => activity.uid === curActivity.uid)
    if (index === parentData.current.dailyActivity.length - 1) return null
    return parentData.current.dailyActivity[index + 1]
  }

  const getAllNextActivitys = (uid) => {
    if (typeof uid !== 'number') return []
    if (uid < 0) return []
    if (!parentData.current) return []
    if (!parentData.current.hasOwnProperty('dailyActivity')) return []
    if (!Array.isArray(parentData.current.dailyActivity)) return []

    let curActivity = getActivityFromUid(uid)
    if (!curActivity) return null

    let index = parentData.current.dailyActivity.findIndex((activity) => activity.uid === curActivity.uid)
    if (index === parentData.current.dailyActivity.length - 1) return []

    let activities = []
    for (let i = index; i < parentData.current.dailyActivity.length; i++) {
      activities.push(cloneDeep(parentData.current.dailyActivity[i]))
    }

    return activities
  }

  const getAllPrevActivitys = (uid) => {
    if (typeof uid !== 'number') return []
    if (uid < 0) return []
    if (!parentData.current) return []
    if (!parentData.current.hasOwnProperty('dailyActivity')) return []
    if (!Array.isArray(parentData.current.dailyActivity)) return []

    let curActivity = getActivityFromUid(uid)
    if (!curActivity) return null

    let index = parentData.current.dailyActivity.findIndex((activity) => activity.uid === curActivity.uid)
    if (index <= 0) return []

    let activities = []
    for (let i = index; i >= 0; i--) {
      activities.push(cloneDeep(parentData.current.dailyActivity[i]))
    }

    return activities
  }

  const isActivityCodeDepthEditable = (code) => {
    if (typeof code !== 'string') return false
    if (code[0] === '2' && code.length === 2) return true //Drilling code
    if (code === '3Z') return true
    return false
  }

  const columnDefs = [
    {
      field: 'actions',
      colId: 'actions',
      headerName: '',
      width: 75,
      editable: false,
      cellRendererSelector: () => {
        return {
          component: actionIconRenderer,
        }
      },
      cellStyle: {
        display: 'flex',
        justifyContent: 'center',
        paddingLeft: '4px !important',
        paddingRight: '4px !important',
        borderRight: '1px solid gray !important',
      },
      pinned: 'left',
      lockPosition: 'left',
      suppressHeaderMenuButton: true,
      suppressHeaderFilterButton: true,
    },
    {
      headerName: 'Activity',
      colId: 'activity',
      field: 'activity',
      cellStyle: (params) => {
        if (!getActivityStyles(params.data?.activity)) return centerAlignCell
        return {
          ...{ display: 'flex', alignItems: 'center', justifyContent: 'center' },
          ...getActivityStyles(params.data?.activity),
        }
      },
      cellEditor: 'agRichSelectCellEditor',
      cellEditorPopup: true,
      cellEditorParams: {
        values: [...getActivityCodeDropDown()],
        searchDebounceDelay: 500,
      },
      valueSetter: (params) => {
        if (params.newValue === params.oldValue) return
        let activityCode = getCodeDescFromDropDown(params.newValue)
        let data = { ...params.data }
        data.activity = activityCode.code
        data.activityCodeDesc = activityCode.desc

        if (params.node?.rowPinned !== 'bottom') {
          updateParentGrid({
            action: 'update',
            data: [data],
          })

          return
        }

        inputRow.current[params.colDef.field] = activityCode.code
        inputRow.current.activityCodeDesc = data.activityCodeDesc
        return true
      },
    },
    {
      headerName: 'Description',
      field: 'activityCodeDesc',
      colId: 'activityCodeDesc',
      cellStyle: { textAlign: 'start' },
      cellEditor: 'agRichSelectCellEditor',
      cellEditorPopup: true,
      cellEditorParams: {
        values: [...getActivityCodeDropDown()],
        searchDebounceDelay: 500,
      },
      valueSetter: (params) => {
        if (params.oldValue === params.newValue) return
        let activityCode = getCodeDescFromDropDown(params.newValue)
        let data = { ...params.data }
        data.activityCodeDesc = activityCode.desc
        data.activity = activityCode.code

        if (params.node?.rowPinned !== 'bottom') {
          updateParentGrid({
            action: 'update',
            data: [data],
          })

          return
        }

        inputRow.current[params.colDef.field] = params.newValue?.desc
        inputRow.current.activity = data.activity
        return true
      },
    },
    {
      headerName: 'Start Time',
      colId: 'startTime',
      field: 'startTime',
      cellStyle: (params) => {
        let prevActivity = getPrevActivity(params.data?.uid)
        if (!prevActivity) return centerAlignCell
        if (prevActivity.endTime === params.data?.startTime) return centerAlignCell

        return {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'red',
        }
      },
      valueSetter: (params) => {
        if (params.newValue === params.oldValue) return

        if (!isTimeStrValid(params.newValue)) {
          showErrorMessage('Invalid time format should be hh:mm')
          return false
        }

        if (params.newValue === '24:00') {
          showErrorMessage('Start time cannot be 24:00')
          return false
        }

        //Handle input row
        if (params.node?.rowPinned === 'bottom') {
          if (
            timeStrToDecDay(params.newValue) >= timeStrToDecDay(params.data?.endTime) &&
            isTimeStrValid(params.data?.endTime)
          ) {
            showErrorMessage('Start Time must be less than end time')
            return false
          }

          inputRow.current[params.colDef.field] = params.newValue
          return true
        }

        //Handle non input row
        if (timeStrToDecDay(params.newValue) >= timeStrToDecDay(params.data?.endTime)) {
          showErrorMessage('Start Time must be less than end time')
          return false
        }

        let newData = { ...params.data }
        newData.startTime = params.newValue
        newData.hours = getActivityHours(newData.startTime, newData.endTime)

        //If cur row is first row and start time is valid then just update
        let prevActivity = getPrevActivity(params.data?.uid)
        if (!prevActivity) {
          updateParentGrid({
            action: 'update',
            data: [newData],
          })

          return true
        }

        //If cur row start time is less than or equal to prev activity start time then error
        if (timeStrToDecDay(params.newValue) <= timeStrToDecDay(prevActivity.startTime)) {
          showErrorMessage('Must be greater than prev activity start time')
          return false
        }

        let rowsToUpdate = [newData]
        //Check to see if new start time overlaps previous row end time if so adjust previous row end time
        if (timeStrToDecDay(params.newValue) < timeStrToDecDay(prevActivity.endTime)) {
          let newPrevActivity = cloneDeep(prevActivity)
          newPrevActivity.endTime = params.newValue
          newPrevActivity.hours = getActivityHours(newPrevActivity.startTime, newPrevActivity.endTime)
          rowsToUpdate.push(newPrevActivity)
        }

        updateParentGrid({
          action: 'update',
          data: rowsToUpdate,
        })

        return true
      },
    },
    {
      headerName: 'End Time',
      colId: 'endTime',
      field: 'endTime',
      cellStyle: (params) => {
        let nextActivity = getNextActivity(params.data?.uid)
        if (!nextActivity) return centerAlignCell
        if (nextActivity.startTime === params.data?.endTime) return centerAlignCell

        return {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'red',
        }
      },
      valueSetter: (params) => {
        if (params.newValue === params.oldValue) return

        if (!isTimeStrValid(params.newValue)) {
          showErrorMessage('Invalid time format should be hh:mm')
          return false
        }

        if (params.newValue === '00:00') {
          showErrorMessage('End time cannot be 00:00')
          return false
        }

        //Handle input row
        if (params.node?.rowPinned === 'bottom') {
          if (
            timeStrToDecDay(params.newValue) <= timeStrToDecDay(params.data?.startTime) &&
            isTimeStrValid(params.data?.startTime)
          ) {
            showErrorMessage('End Time must be greater than start time')
            return false
          }

          inputRow.current[params.colDef.field] = params.newValue
          return true
        }

        //Handle non input row
        if (timeStrToDecDay(params.newValue) <= timeStrToDecDay(params.data?.startTime)) {
          showErrorMessage('End time must be greater than start time')
          return false
        }

        let newData = { ...params.data }
        newData.endTime = params.newValue
        newData.hours = getActivityHours(newData.startTime, newData.endTime)

        //If cur row is last row and end time is valid then just update
        let nextActivity = getNextActivity(params.data?.uid)
        if (!nextActivity) {
          updateParentGrid({
            action: 'update',
            data: [newData],
          })

          return true
        }

        //If cur row end time is greater than or equal to next activity end time then error
        if (timeStrToDecDay(params.newValue) >= timeStrToDecDay(nextActivity.endTime)) {
          showErrorMessage('Must be less than next activity end time')
          return false
        }

        let rowsToUpdate = [newData]
        //Check to see if new end time overlaps next row start time if so adjust next row start time
        if (timeStrToDecDay(params.newValue) > timeStrToDecDay(nextActivity.startTime)) {
          let newNextActivity = cloneDeep(nextActivity)
          newNextActivity.startTime = params.newValue
          newNextActivity.hours = getActivityHours(newNextActivity.startTime, newNextActivity.endTime)
          rowsToUpdate.push(newNextActivity)
        }

        updateParentGrid({
          action: 'update',
          data: rowsToUpdate,
        })

        return true
      },
    },
    {
      headerName: 'Hrs',
      colId: 'hours',
      field: 'hours',
      editable: false,
      cellStyle: centerAlignCell,
      valueFormatter: (params) => {
        if (params.node?.rowPinned === 'bottom') return ''
        return numberWithCommasDecimals(params.data?.hours, 2)
      },
    },
    {
      headerName: 'Depth From',
      colId: 'startDepth',
      field: 'startDepth',
      editable: (params) => {
        return params.node?.rowPinned !== 'bottom' && isActivityCodeDepthEditable(params.data?.activity)
      },
      cellStyle: (params) => {
        let prevActivity = getPrevActivity(params.data?.uid)
        if (!prevActivity) return centerAlignCell
        if (parseFloat(prevActivity.endDepth) === parseFloat(params.data?.startDepth)) return centerAlignCell

        return {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'red',
        }
      },
      valueSetter: (params) => {
        if (parseFloat(params.newValue) === parseFloat(params.oldValue)) return

        if (!isStrValidFloat(params.newValue)) {
          showErrorMessage('Invalid number')
          return false
        }

        if (!isActivityCodeDepthEditable(params.data?.activity)) {
          showErrorMessage('Activity code is not drilling or sidetrack')
          return false
        }

        let newDepth = parseFloat(params.newValue)

        if (newDepth < prevDailyReportEndDepth) {
          showErrorMessage('Depth less than previous daily report end depth')
          return false
        }

        if (newDepth > nextDailyReportStartDepth) {
          showErrorMessage('Depth greater than next daily report start depth')
          return false
        }

        if (newDepth > parseFloat(params.data?.endDepth) && isStrValidFloat(params.data?.endDepth)) {
          showErrorMessage('Start depth cannot be greater than end depth')
          return false
        }

        //Handle input row
        if (params.node?.rowPinned === 'bottom') {
          if (newDepth > parseFloat(params.data?.endDepth) && isStrValidFloat(params.data?.endDepth)) {
            showErrorMessage('Start depth cannot be greater than end depth')
            return false
          }
          inputRow.current[params.colDef.field] = params.newValue
          return true
        }

        let newData = { ...params.data }
        newData.startDepth = newDepth
        newData.cl = newData.endDepth - newData.startDepth

        //If cur row is first row and start depth is valid then just update
        let prevActivity = getPrevActivity(params.data?.uid)
        if (!prevActivity) {
          updateParentGrid({
            action: 'update',
            data: [newData],
          })

          return true
        }

        //If cur row start depth is less than prev activity start depth then error
        if (newDepth < prevActivity.startDepth) {
          showErrorMessage('Must be greater than or equal to prev activity start depth')
          return false
        }

        let rowsToUpdate = [newData]
        //Check to see if new start depth overlaps previous row end depth if so adjust previous row end depth
        if (newDepth < prevActivity.endDepth) {
          let newPrevActivity = cloneDeep(prevActivity)

          let oldEndDepth = newPrevActivity.endDepth
          if (parseFloat(newPrevActivity.endDepth) === parseFloat(newPrevActivity.startDepth)) {
            newPrevActivity.startDepth = newDepth
          }

          newPrevActivity.endDepth = newDepth
          newPrevActivity.cl = newPrevActivity.endDepth - newPrevActivity.startDepth
          rowsToUpdate.push(newPrevActivity)

          //Need to iterate through all rows to see if the depths need updating
          let allPrevActivitys = getAllPrevActivitys(oldEndDepth.uid)
          for (let i = 0; i < allPrevActivitys.length; i++) {
            let addToUpdate = false
            if (parseFloat(allPrevActivitys[i].startDepth) === parseFloat(oldEndDepth)) {
              allPrevActivitys[i].startDepth = newDepth
              addToUpdate = true
            }

            if (parseFloat(allPrevActivitys[i].endDepth) === parseFloat(oldEndDepth)) {
              allPrevActivitys[i].endDepth = newDepth
              addToUpdate = true
            }

            if (addToUpdate) {
              allPrevActivitys[i].cl = allPrevActivitys[i].endDepth - allPrevActivitys[i].startDepth
              rowsToUpdate.push(allPrevActivitys[i])
            }
          }
        }

        updateParentGrid({
          action: 'update',
          data: rowsToUpdate,
        })

        return true
      },
      valueFormatter: (params) => numberWithCommasDecimals(params.data?.startDepth, 2),
    },
    {
      headerName: 'Depth To',
      colId: 'endDepth',
      field: 'endDepth',
      cellStyle: (params) => {
        let nextActivity = getNextActivity(params.data?.uid)
        if (!nextActivity) return centerAlignCell
        if (parseFloat(nextActivity.startDepth) === parseFloat(params.data?.endDepth)) return centerAlignCell

        return {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'red',
        }
      },
      editable: (params) => {
        return params.node?.rowPinned !== 'bottom' && isActivityCodeDepthEditable(params.data?.activity)
      },
      valueSetter: (params) => {
        if (!isStrValidFloat(params.newValue)) {
          showErrorMessage('Invalid number')
          return false
        }

        if (!isActivityCodeDepthEditable(params.data?.activity)) {
          showErrorMessage('Activity code is not drilling or sidetrack')
          return false
        }

        let newDepth = parseFloat(params.newValue)
        if (newDepth < prevDailyReportEndDepth) {
          showErrorMessage('Depth less than previous report end depth')
          return false
        }

        if (newDepth > nextDailyReportStartDepth) {
          showErrorMessage('Depth greater than next report start depth')
          return false
        }

        if (newDepth < parseFloat(params.data?.startDepth) && isStrValidFloat(params.data?.startDepth)) {
          showErrorMessage('End depth cannot be less than end depth')
          return false
        }

        //Handle input row
        if (params.node?.rowPinned === 'bottom') {
          if (newDepth < parseFloat(params.data?.startDepth) && isStrValidFloat(params.data?.startDepth)) {
            showErrorMessage('End depth cannot be less than end depth')
            return false
          }

          inputRow.current[params.colDef.field] = params.newValue
          return true
        }

        let newData = { ...params.data }
        newData.endDepth = newDepth
        newData.cl = newData.endDepth - newData.startDepth

        //If cur row is last row and end depth is valid then just update
        let nextActivity = getNextActivity(params.data?.uid)
        if (!nextActivity) {
          updateParentGrid({
            action: 'update',
            data: [newData],
          })

          return true
        }

        //If cur row end depth is greater than next activity end depth then error
        if (newDepth > nextActivity.endDepth) {
          showErrorMessage('Must be less than or equal to next activity end depth')
          return false
        }

        let rowsToUpdate = [newData]
        //Check to see if new end depth overlaps next row start depth if so adjust next row start depth
        if (newDepth > nextActivity.startDepth) {
          let newNextActivity = cloneDeep(nextActivity)
          let oldStartDepth = newNextActivity.startDepth
          if (parseFloat(newNextActivity.endDepth) === parseFloat(newNextActivity.startDepth)) {
            newNextActivity.endDepth = newDepth
          }

          newNextActivity.startDepth = newDepth
          newNextActivity.cl = newNextActivity.endDepth - newNextActivity.startDepth
          rowsToUpdate.push(newNextActivity)

          //Need to iterate through all rows to see if the depths need updating
          let allNextActivitys = getAllNextActivitys(newNextActivity.uid)
          for (let i = 0; i < allNextActivitys.length; i++) {
            let addToUpdate = false
            if (parseFloat(allNextActivitys[i].startDepth) === parseFloat(oldStartDepth)) {
              allNextActivitys[i].startDepth = newDepth
              addToUpdate = true
            }

            if (parseFloat(allNextActivitys[i].endDepth) === parseFloat(oldStartDepth)) {
              allNextActivitys[i].endDepth = newDepth
              addToUpdate = true
            }

            if (addToUpdate) {
              allNextActivitys[i].cl = allNextActivitys[i].endDepth - allNextActivitys[i].startDepth
              rowsToUpdate.push(allNextActivitys[i])
            }
          }
        }

        updateParentGrid({
          action: 'update',
          data: rowsToUpdate,
        })

        return true
      },
      valueFormatter: (params) => numberWithCommasDecimals(params.data?.endDepth, 2),
    },
    {
      headerName: 'CL',
      colId: 'cl',
      field: 'cl',
      cellStyle: centerAlignCell,
      editable: false,
      valueFormatter: (params) => numberWithCommasDecimals(params.data?.cl, 2),
    },
    {
      headerName: 'Phase',
      colId: 'phase',
      field: 'phase',
      editable: (params) => {
        return params.node?.rowPinned !== 'bottom'
      },
      cellStyle: (params) => {
        let prevActivity = getPrevActivity(params.data?.uid)
        if (!prevActivity) return centerAlignCell
        let nextActivity = getNextActivity(params.data?.uid)
        if (!nextActivity) return centerAlignCell

        if (prevActivity.phase !== nextActivity.phase) return centerAlignCell
        if (nextActivity.phase === params.data?.phase) return centerAlignCell

        return {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'red',
        }
      },
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: (params) => {
        const { phaseCodes } = getActivityDropDowns()
        return { values: phaseCodes.map((item) => item.code) }
      },
      valueSetter: (params) => {
        let data = { ...params.data }
        data.phase = params.newValue

        if (params.node?.rowPinned !== 'bottom') {
          updateParentGrid({
            action: 'update',
            data: [data],
          })

          return
        }
        inputRow.current.phase = params.newValue
        return true
      },
    },
    {
      headerName: 'BHA #',
      colId: 'bha',
      field: 'bha',
      editable: (params) => {
        return params.node?.rowPinned !== 'bottom'
      },
      cellStyle: (params) => {
        let prevActivity = getPrevActivity(params.data?.uid)
        if (!prevActivity) return { textAlign: 'start' }
        let nextActivity = getNextActivity(params.data?.uid)
        if (!nextActivity) return { textAlign: 'start' }

        if (parseFloat(prevActivity.bha) !== parseFloat(nextActivity.bha)) return { textAlign: 'start' }
        if (parseFloat(nextActivity.bha) === parseFloat(params.data?.bha)) return { textAlign: 'start' }

        return {
          textAlign: 'start',
          backgroundColor: 'red',
        }
      },
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: (params) => {
        const { bhaCodes } = getActivityDropDowns()
        return {
          values: bhaCodes.map((bha) => {
            if (parseInt(bha.bhaNum) === -1) return 'none'
            return `${bha.bhaNumRep}: ${bha.description}`
          }),
        }
      },
      valueSetter: (params) => {
        let data = { ...params.data }
        if (parseInt(params.oldValue) === parseInt(params.newValue)) return

        if (params.newValue === 'none') {
          data.bha = -1
        } else {
          const { bhaCodes } = getActivityDropDowns()
          let selectedBha = bhaCodes.find((bha) => `${bha.bhaNumRep}: ${bha.description}` === params.newValue)
          if (!selectedBha) return
          data.bha = parseInt(selectedBha.bhaNum)
        }

        if (params.node?.rowPinned !== 'bottom') {
          updateParentGrid({
            action: 'update',
            data: [data],
          })

          return
        }
        inputRow.current.bha = data.bha
        return true
      },
      valueGetter: (params) => {
        if (params?.data?.bha === undefined || params?.data?.bha === -1) return ''
        const { bhaCodes } = getActivityDropDowns()
        let foundBha = bhaCodes.find((bha) => bha.bhaNum === params?.data?.bha)
        if (foundBha) return `${foundBha.bhaNumRep}: ${foundBha.description}`
        return ''
      },
    },
    {
      headerName: 'Comments',
      colId: 'comments',
      field: 'description',
      editable: (params) => {
        return params.node?.rowPinned !== 'bottom'
      },
      cellStyle: { textAlign: 'start' },
      cellEditor: 'agLargeTextCellEditor',
      cellEditorPopup: true,
      cellEditorParams: {
        maxLength: 1000,
        rows: 10,
        cols: 50,
      },
      valueFormatter: (params) => {
        if (params.node?.rowPinned === 'bottom') return ''
        return unescapeHtml(params.data?.description)
      },
      valueGetter: (params) => {
        return unescapeHtml(params.data?.description)
      },
      valueSetter: (params) => {
        let data = { ...params.data }
        data.description = params.newValue
        if (params.node?.rowPinned !== 'bottom') {
          updateParentGrid({
            action: 'update',
            data: [data],
          })
          return
        }

        inputRow.current[params.colDef.field] = params.newValue?.value
        inputRow.current.activityCodeDesc = data.activityCodeDesc
        return true
      },
    },
  ]

  const getActivityStyles = (code) => {
    if (typeof code !== 'string' || code?.length < 2) return
    if (code?.slice(0, 2) === '26') return { color: 'white', background: 'rgba(0,0,139)' }
    if (code === '2K') return { color: 'black', background: appColors.rotateColor }
    if (code === '2J') return { color: 'black', background: 'rgb(232,147,2)' }
    if (code === '6O') return { color: 'black', background: 'yellow' }
    if (code === '6P') return { color: 'black', background: 'yellow' }
    if (code[0] === '8') return { color: 'white', background: 'red' }
    return
  }

  const gridOptions = {
    pinnedBottomRowData: [inputRow.current],
    onDragStopped: (event) => {
      saveColumnState()
    },
    onColumnVisible: (event) => {
      saveColumnState()
    },
    onCellEditingStopped: (params) => {
      if (params.node?.rowPinned === 'bottom') {
        if (gridApi.current) gridApi.current.setGridOption('pinnedBottomRowData', [inputRow.current])
        handleAddRow(params)
        return
      }
    },
    getRowStyle: ({ node }) => {
      if (node?.rowPinned) return { fontWeight: 'bold', fontStyle: 'italic' }
    },
  }

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const onFirstDataRendered = (params) => {
    autoSizeColumns()
  }

  const processDataFromClipboard = (params) => {
    if (!params.hasOwnProperty('data') && !Array.isArray(params.data) && params.data?.length < 1) {
      return null
    }
    let data = params.data?.map((dataRow) => {
      return dataRow.slice()
    })

    // delete the empty last row in the clipboard data, if one exists
    const emptyLastRow = data[data.length - 1][0] === '' && data[data.length - 1].length === 1
    if (emptyLastRow) {
      data.splice(data.length - 1, 1)
    }
    // make sure there's data to process after we remove the typical empty row
    if (data.length < 1) return null

    const focusedCell = params.api.getFocusedCell()
    const focusedIndex = focusedCell.rowIndex

    pastedRows.current = []
    gridApi.current.forEachNodeAfterFilterAndSort((rowNode, index) => {
      if (index >= focusedIndex && index <= focusedIndex + data.length - 1) {
        pastedRows.current.push(rowNode.data)
      }
    })

    return data
  }

  function onPasteEnd() {
    if (!Array.isArray(pastedRows.current)) return
    if (pastedRows.current.length === 0) return
    updateParentGrid(cloneDeep(pastedRows.current))
    pastedRows.current = []
  }

  return (
    <Box sx={{ height: '100%' }}>
      {confirm.show ? (
        <ConfirmDialog
          title={confirm?.title}
          open={confirm?.show}
          setOpen={() => setConfirm({ show: false })}
          onConfirm={() => confirmDelete()}>
          {confirm?.text}
        </ConfirmDialog>
      ) : null}
      <div className={getAgGridTheme()} style={{ width: '100%', height: '100%' }}>
        <AgGridReact
          rowData={Array.isArray(parentData.current.dailyActivity) ? parentData.current.dailyActivity : []}
          columnDefs={sortColDefs(columnDefs, 'dailyActivityGrid')}
          defaultColDef={defaultColDef}
          headerHeight={30}
          getRowId={getRowId}
          animateRows={true}
          enableBrowserTooltips={true}
          groupDisplayType={'groupRows'}
          onGridReady={onGridReady}
          groupRowsSticky={true}
          gridOptions={gridOptions}
          getContextMenuItems={getContextMenuItems}
          onFirstDataRendered={onFirstDataRendered}
          processDataFromClipboard={processDataFromClipboard}
          onPasteEnd={onPasteEnd}
        />
      </div>
    </Box>
  )
}

export default DailyActivityDetailsGrid
